import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 2rem;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.button-custom {
		white-space: nowrap;
		min-width: 9.375rem;
		width: auto;
	}

	div {
		display: flex;
		gap: 1rem;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 2.5rem 1.5rem;
	color: ${colors.white};
`;

export const Section = styled.section`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2.5rem;

	h2 {
		font-size: 1rem;
		font-style: normal;
		font-family: 'IBM Plex Sans';
		font-weight: 400;
		letter-spacing: 0.04em;
		color: ${colors.white};
	}
`;

export const Label = styled.label`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	font-size: 0.9375rem;
	color: ${colors.white};
	font-weight: 400;
	text-align: left;

	h2 {
		font-size: 1.375rem;
		font-style: normal;
		font-family: 'Jost';
		font-weight: 500;
		letter-spacing: 0.04em;
		color: ${colors.white};
	}

	.custom-select-input {
		border-bottom: 1px solid ${colors.primaryBlue};
		border-radius: 0;
	}

	.wrapper-select-react {
		height: 3.25rem;
		align-items: end;
	}
`;

export const TituloParagrafo = styled.p`
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-size: 1rem;
	color: ${({ isInvalid }) => (isInvalid ? colors.error : colors.offWhite)};
	margin-bottom: 1rem;
`;

export const Linegroup = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 3rem;

	.column-bottom {
		padding-top: 1.5rem;
		color: ${colors.primaryBlue};
	}
`;

export const Linediv = styled.div`
	position: relative;
	opacity: 0.5;
	background: ${colors.darkGray};
	border: 1px solid #585d5d;
	margin: 1rem 0;
`;

export const P = styled.p`
	color: ${colors.lightGray};
	font-size: 1.25rem;
	font-weight: 400;
	font-family: 'IBM Plex Sans';
	text-align: left;

	&:first-letter {
		text-transform: uppercase;
	}
`;

export const SectionTitle = styled.h3`
	font-size: 1rem;
`;

export const SectionContent = styled.div`
	display: flex;
`;

export const ContentTitle = styled.h3`
	font-size: 1rem;
`;

export const ContentData = styled.h3`
	font-size: 1.25rem;
`;
