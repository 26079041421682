import styled from 'styled-components';

export const Container = styled.div`
	display: grid;
	align-items: center;
	gap: 1rem;
	padding: 0 16px 16px 16px;
	grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
	/* height: 100%; */
	grid-template-areas:
		'div1 div1 div1'
		'div2 div3 div4'
		'div5 div5 div5';

	#teste1 {
		grid-area: div1;
		background-color: white;
		border-radius: 4px;
		height: 100%;
		width: 100%;
	}
	#teste2 {
		grid-area: div2;
		background-color: white;
		border-radius: 4px;
		max-height: 247px;
		height: 100%;
		width: 100%;
	}
	#teste3 {
		grid-area: div3;
		background-color: white;
		border-radius: 4px;
		max-height: 247px;
		height: 100%;
		width: 100%;
	}
	#teste4 {
		grid-area: div4;
		background-color: white;
		border-radius: 4px;
		min-height: 247px;
		height: 100%;
		width: 100%;
	}
	#teste5 {
		grid-area: div5;
		background-color: white;
		border-radius: 4px;
		min-height: 247px;
		height: 100%;
		width: 100%;
	}
`;

export const InformationCard = styled.div`
	grid-area: ${(props) => (props.div ? props.div : 'none')};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	/* justify-content: center; */
	padding: 24px;
	background-color: white;
	border-radius: 4px;
	min-height: 95px;
	height: 100%;
	width: 100%;
	box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.07);

	div {
		display: flex !important;
		flex-direction: column;

		justify-content: space-between;
		margin-right: 24px;

		h1 {
			color: #454550;
			font-size: 14px;
		}
		h2 {
			color: #454550;
			font-size: 12px;
		}
		h3 {
			color: #595a63;
			font-size: 16px;
			font-weight: 700;
			line-height: 1;
		}
		h4 {
			color: #454550;
			font-size: 24px;
			font-weight: 600;
			margin: 0;
		}
	}
`;
