import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.25rem 3.44rem 3.44rem 3.44rem;

	p {
		font-size: 1.13rem;
		text-align: center;
		color: ${colors.primaryBlue};
		max-width: 27.5rem;
	}

	.button-custom-cancelar {
		background: ${colors.offWhite};
		color: ${colors.red};
		border: 1px solid ${colors.lightGray};
	}
`;

export const Imagem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Title = styled.span`
	width: 100%;
	font-size: 1.375rem;
	font-weight: 500;
	line-height: 2rem;
	justify-self: center;
	text-align: center;
	padding-bottom: 1rem;
	color: ${colors.offWhite};
	text-transform: uppercase;
`;

export const Subtitle = styled.span`
	padding-bottom: 3rem;
	color: ${colors.lightGray};
	font-size: 1.25rem;
	font-height: 400;
	text-align: center;
`;

export const ButtonGroup = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: center;
	align-items: center;
`;
