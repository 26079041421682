import styled from 'styled-components';
import { colors } from '../../../assets';

export const Header = styled.header`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 3.125rem 0 3.5rem 0;

	${({ pathName }) =>
		pathName === '/links-pagamento' &&
		`
		@media (max-width: 1024px) {
		width: 84%;
	}

	@media (max-width: 1366px) {
		width: 100%;
	}

	@media (max-width: 1368px) {
		width: 89%;
	}

	@media (max-width: 1440px) {
		width: 86%;
	}

	@media (max-width: 1920px) {
		width: 87%;
	}
	
	`}
`;

export const LeftHeader = styled.div`
	display: flex;
	text-align: center;
	align-items: center;
	color: ${colors.white};

	h1 {
		color: ${({ reverseColor }) => (reverseColor ? colors.gray : colors.white)};
		font-size: 2.25rem;
		font-weight: 600;
		margin-left: 15px;
	}

	svg.chevron-icon {
		width: 1.875rem;
		height: 1.875rem;
		margin: 0 5px -0.3125rem 5px;

		path {
			stroke-width: 70px;
			stroke: ${colors.gray};
		}
	}

	h2 {
		font-size: 2.25rem;
		font-weight: 600;
		color: ${({ reverseColor }) => (reverseColor ? colors.white : colors.gray)};
		white-space: nowrap;
		word-break: keep-all;
		text-overflow: ellipsis;
	}
`;

export const RightHeader = styled.div`
	display: flex;
	button {
		border: none;
		background-color: transparent;
	}

	.Menu {
		text-align: center;
		align-items: center;
		color: ${colors.white};
		font-weight: 500;
		cursor: pointer;
		svg {
			margin-right: 7px;
			transform: translateY(3px);
		}
		span {
			padding: 0 10px;
			align-self: center;
			font-size: 1.125rem;
		}
	}
`;

export const DropdownList = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	h5 {
		color: ${colors.lightGray};
		font-size: 1.13rem;
		line-height: 1rem;
		padding: 8px 0;
	}
`;

export const DropdownMenu = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	min-height: 17.8125rem;
	min-width: 20.5rem;
	z-index: 100;
	top: calc(7rem);
	right: 2.5rem;
	background-color: ${colors.secondaryDarkGray};
	border-radius: 0.25rem;
	padding: 1rem 1.5rem;
`;

export const MenuHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	h4 {
		font-family: 'Jost';
		font-style: normal;
		font-size: 1.5rem;
		color: ${colors.primaryBlue};
		font-weight: 500;
		line-height: 2rem;
	}
	p {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.5rem;
		margin-top: 0.5rem;
	}
`;

export const MenuDivider = styled.div`
	width: 100%;
	height: 2px;
	border-bottom: 1px solid ${colors.gray};
	margin-top: 0.8125rem;
	margin-bottom: 1rem;
`;

export const MenuList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const LinkStyled = styled.a`
	text-decoration: none;
	color: ${colors.white};
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 2.5rem;
`;

export const MenuFooter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const ButtonLogout = styled.button`
	background-color: transparent;
	border: none;
	color: ${colors.red};
`;

export const BackgroundClose = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
`;
