import React from 'react';
import { colors } from '../..';

const LogsIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		width="34"
		height="31"
		viewBox="0 0 34 31"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M33.0042 29.6906C33.6418 29.0581 34 28.2001 34 27.3055V8.75395C34 7.85937 33.6418 7.00143 33.0042 6.36887C32.3665 5.7363 31.5017 5.38093 30.6 5.38093H15.3L11.9 0.321411H3.4C2.49826 0.321411 1.63346 0.676781 0.995837 1.30934C0.358213 1.94191 0 2.79985 0 3.69443V27.3055C0 28.2001 0.358213 29.0581 0.995837 29.6906C1.63346 30.3232 2.49826 30.6786 3.4 30.6786H30.6C31.5017 30.6786 32.3665 30.3232 33.0042 29.6906ZM16.3928 24.2604C15.3868 24.2604 14.5713 25.0759 14.5713 26.0818C14.5713 27.0878 15.3868 27.9032 16.3928 27.9032H28.5356C29.5416 27.9032 30.3571 27.0878 30.3571 26.0818C30.3571 25.0759 29.5416 24.2604 28.5356 24.2604H16.3928Z"
			fill={fill || colors.offWhite}
		/>
	</svg>
);

export default LogsIcon;
