import styled from 'styled-components';
import { colors } from './assets/colors';

export const Container = styled.div`
	display: flex;
	background: ${colors.background};
	overflow: ${({ isLogged }) => (isLogged ? 'hidden' : 'visible')};
`;

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	padding-bottom: ${({ isLogged }) => (isLogged ? '5rem' : '0')};

	aside {
		height: 100vh;
		z-index: 99;
	}
	main {
		position: relative;
		width: 100%;
		margin-left: ${({ isSideMenuOpen, isLogged, isNotFound }) =>
			!isLogged || isNotFound ? '0' : isSideMenuOpen ? '25.25rem' : '11.25rem'};
		margin-right: ${({ isLogged, isNotFound }) =>
			isLogged && !isNotFound ? '3.5625rem' : '0'};
	}
`;

export const ContainerLoader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	height: 100vh;
`;

export const ContainerAlert = styled.div`
	margin-top: 2.5rem;
`;
