import styled from 'styled-components';
import { colors } from '../../../assets';

export const ContainerCalendario = styled.div`
	.calendario {
		background: ${colors.background};
		box-shadow: none;
		width: auto;
		min-height: auto !important;
		padding-top: 0;

		.Calendar__sectionWrapper {
			min-height: 17rem;
		}

		//container dos dois meses
		.Calendar__months {
			display: flex;
		}

		.Calendar__month:nth-child(odd) {
			${({ numberOfMonths }) =>
				numberOfMonths > 1 &&
				'border-right: 1px solid rgba(255, 255, 255, 0.3)'};
		}

		.Calendar__monthText,
		.Calendar__yearText {
			font-size: 1.13rem;

			:hover {
				color: ${colors.black};
			}
		}
		.Calendar__monthText {
			color: ${colors.white};
			font-family: Jost;
			${({ readOnly }) => readOnly && 'pointer-events: none'}
		}
		.Calendar__yearText {
			color: ${({ borderGreen }) =>
				borderGreen ? colors.green : colors.primaryBlue};

			font-family: Jost;
			${({ readOnly }) => readOnly && 'pointer-events: none'}
		}

		//seção com o cabeçalho de dias da semana
		.Calendar__weekDays {
			justify-content: center;
			padding: 0 1.2rem;
		}
		.Calendar__weekDay {
			font-size: 0.75rem;
			font-family: Jost;
			min-width: 2.6rem;
		}

		//seção com os dias numéricos
		.Calendar__section {
			padding: 0 1.2rem;
		}
		.Calendar__section.-hiddenPrevious,
		.Calendar__section.-hiddenNext {
			opacity: 0;
		}

		.Calendar__day {
			font-size: 0.875rem;
			font-family: Jost;
			color: ${colors.white};
			padding: calc(0.25rem - 1px) 0;
			min-width: 2.6rem;
			min-height: 2.6rem;
			${({ readOnly }) => readOnly && 'pointer-events: none'}
		}

		.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
			color: ${colors.primaryBlue};

			:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
				background: ${colors.primaryBlue};
			}
		}

		//seta
		.Calendar__monthArrow {
			background-image: url("data:image/svg+xml;utf8,<svg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' clipRule='evenodd' d='M0.397453 0.315796C-0.098774 0.766911 -0.135344 1.53488 0.315771 2.03111L5.64464 7.89287L0.315771 13.7546C-0.135344 14.2508 -0.098774 15.0188 0.397453 15.4699C0.893681 15.9211 1.66165 15.8845 2.11277 15.3883L8.92676 7.89287L2.11277 0.397477C1.66165 -0.0987498 0.893681 -0.13532 0.397453 0.315796Z' fill='%23FDFDFD'/></svg>");
			transform: rotate(270deg);
			width: 0.5625rem;
			height: 1rem;
		}

		//remover efeito de opacidade na seção de escolha de anos
		.Calendar__yearSelectorWrapper.-faded {
			::before,
			::after {
				opacity: 0;
			}
		}

		//container seção de ano e mês
		.Calendar__monthSelectorAnimationWrapper,
		.Calendar__yearSelectorAnimationWrapper {
			border-radius: 0.3125rem;
		}

		//seção de escolha de anos e meses
		.Calendar__monthSelector,
		.Calendar__yearSelector {
			background: ${colors.background};

			.Calendar__monthSelectorItemText,
			.Calendar__yearSelectorText {
				font-family: Jost;
				color: ${colors.white};

				:hover {
					color: ${colors.black};
				}
			}

			.Calendar__monthSelectorItemText:disabled,
			.Calendar__yearSelectorText:disabled {
				:hover {
					color: ${colors.white};
				}
			}
		}
	}
`;
