import styled from 'styled-components';
import { InputCustom } from '../text-field-custom/styled-text-field-custom';
import { colors } from '../../../assets';

export const IconButtonCustom = styled.div`
	padding: 0.15rem 0.9375rem 0 1.25rem;
	svg {
		width: 1.56rem;
		height: 1.56rem;
	}
`;

export const SearchField = styled.div`
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || '3.125rem'};
	display: ${(props) => props.display || 'flex'};
	margin: ${(props) => props.margin || '0'};
	flex-direction: row;
	border-radius: 0.9375rem;
	border: ${({ border }) => border || `2px solid ${colors.darkGray}`};
	align-items: center;

	@media (max-width: 1024px) {
		width: 76%;
	}

	@media (max-width: 1368px) {
		width: 78%;
	}

	@media (max-width: 1440px) {
		width: 97%;
	}

	${InputCustom} {
		border: 0px;
		align-self: center;
		outline: none;
		padding: 1.38rem 0;
		font-weight: normal;
		font-size: 1.5rem;
		line-height: 1.94rem;
		transition: all 0.1s linear;
		-webkit-transition: all 0.1s linear;
		-moz-transition: all 0.1s linear;
		-webkit-appearance: none;
	}
`;
