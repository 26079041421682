import React from 'react';
import { ButtonCustom, SearchField } from '../../../../components';
import { colors } from '../../../../assets/colors';

import * as s from './styled-filtersClient';

const FiltersClient = (props) => {
	const {
		clientes,
		clientesFiltrados,
		filtrarClientes,
		loading,
		page,
		searchValue,
		setSearchValue,
		setPage,
		setClientesFiltrados,
	} = props;
	const isCleanFiltersDisabled = loading || searchValue === '';
	const isSubmitDisabled =
		loading || searchValue === '' || page < 1 || page > 100;

	const handleSubmitFilters = () => {
		if (clientesFiltrados.length > 0) {
			setPage(1);
			filtrarClientes();
		}
	};

	return (
		<s.Container>
			<SearchField
				value={searchValue}
				placeholder="Busque por Nome do cliente, CNPJ/Código(somente números) ou E-mail"
				onChange={(e) => {
					setSearchValue(e.target.value);
					setPage(1);
				}}
				border={`1px solid ${colors.gray}`}
				onKeyPress={(e) => {
					if (e.key === 'Enter') {
						handleSubmitFilters();
					}
				}}
			/>
			<s.ButtonGroup>
				<ButtonCustom
					outlined
					theme={isCleanFiltersDisabled ? 'gray' : 'green'}
					width="12.5rem"
					disabled={isCleanFiltersDisabled}
					onClick={() => {
						setClientesFiltrados(clientes);
						setSearchValue('');
					}}
					borderRadius=".9375rem"
				>
					Limpar busca
				</ButtonCustom>
				<ButtonCustom
					theme={isSubmitDisabled ? 'gray' : 'green'}
					width="12.5rem"
					disabled={isSubmitDisabled}
					onClick={() => handleSubmitFilters()}
					borderRadius=".9375rem"
				>
					Buscar
				</ButtonCustom>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default FiltersClient;
