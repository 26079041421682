import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 13.125rem;
	min-height: 13.125rem;
	align-content: center;
	padding-top: 0.875rem;
	width: 100%;
	grid-column: span 4 / auto;
`;

export const Title = styled.h1`
	position: absolute;
	font-size: 1rem;
	font-weight: 400;
	padding-bottom: 0.625rem;
	width: auto;
	margin: 0;
	border: 0;
`;

export const RoundChart = styled.div`
	display: flex;
`;

export const GraphContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;
