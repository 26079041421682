import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	border-radius: 15px;
`;

export const Row = styled.div`
	display: flex;
`;

export const Column = styled.div`
	padding-right: 1rem;
	display: flex;
	flex-direction: column;
	color: ${colors.lightGray};
`;

export const ColumnTitle = styled.div`
	text-align: left;
	color: ${colors.lightestGray};
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.005em;
	width: 9.4375rem;
	margin-bottom: 1.25rem;
`;

export const Divisor = styled.div`
	width: 1px;
	height: 25.5rem;
	background-color: ${colors.darkGray};
	margin-right: 0.75rem;
	margin-top: 7px;
`;
