import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHistory } from 'react-router';
import { colors, Icons } from '../../../../assets';
import { useNewPaymentLink } from '../../../../contexts';
import { ButtonCustom } from '../../../atoms';

import * as s from './styled-new-payment-link-form-conclusion';

function ConclusionPayment() {
	const { paymentLinkCreated } = useNewPaymentLink();

	const history = useHistory();

	return (
		<s.Container>
			<Icons.SuccessIcon />
			<h1>LINK DE PAGAMENTO CRIADO COM SUCESSO!</h1>
			<p>
				Clique no link abaixo para copiá-lo e compartilhe agora com seus
				clientes
			</p>
			<s.Link>
				<div id="link-container">
					<CopyToClipboard text={paymentLinkCreated.model.link}>
						<button type="button" className="text">
							{paymentLinkCreated.model.link}{' '}
							<Icons.Copy stroke={colors.primaryBlue} />
						</button>
					</CopyToClipboard>
				</div>
			</s.Link>
			<div id="button-container">
				<ButtonCustom
					outlined
					onClick={() =>
						history.push(`/links-pagamento/${paymentLinkCreated.model.id}`)
					}
				>
					Detalhes do link
				</ButtonCustom>
				<ButtonCustom
					onClick={() => history.push('/links-pagamento/criar-link-pagamento')}
				>
					Criar novo link
				</ButtonCustom>
			</div>
		</s.Container>
	);
}

export default ConclusionPayment;
