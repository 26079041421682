import React from 'react';
import { formatarParaReais } from '../../../../utils';

import * as s from './styled-payment-details-itens';

const Items = ({ items, index }) => {
	const Id = items?.id;
	const titulo = items?.title;
	const quantidade = items?.quantity;
	const unitPrice = items?.unit_price;
	const fisico = items?.tangible;

	return (
		<s.ContainerItems>
			<s.CardPaymentItem>
				<s.Wrapper>
					<s.Title>{`${index + 1} -  ${titulo}`}</s.Title>
					<s.Content>
						<s.SubContent>
							Id do produto
							<span>{`${Id}`}</span>
						</s.SubContent>
						<s.SubContent>
							Valor Unitário
							<span>{`Valor: ${formatarParaReais(
								unitPrice / 100,
								true
							)}`}</span>
						</s.SubContent>
						<s.SubContent>
							Quantidade:
							<span> {quantidade}</span>
						</s.SubContent>
						<s.SubContent>
							Bem Físico
							<span>{fisico ? 'Sim' : 'Não'}</span>
						</s.SubContent>
					</s.Content>
				</s.Wrapper>
			</s.CardPaymentItem>
		</s.ContainerItems>
	);
};
const DetailsItens = ({ dados, loadingContent, setLoadingContent }) => {
	const { items } = dados;
	const Id = items?.id;
	const titulo = items?.title;
	const itemsReversed = items && [...items]?.reverse();

	return (
		<s.Container id={Id}>
			<p>Itens</p>
			<s.Title>{titulo}</s.Title>
			<s.Infos>
				{itemsReversed?.map((item, index) => (
					<Items
						loadingContent={loadingContent}
						setLoadingContent={setLoadingContent}
						index={index}
						items={item}
						key={index}
					/>
				))}
			</s.Infos>
		</s.Container>
	);
};

export default DetailsItens;
