import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from './App';
import { AuthProvider } from './contexts/auth-context';

export const history = createBrowserHistory();

ReactDOM.render(
	<React.StrictMode>
		<AuthProvider>
			<Router history={history}>
				<App />
			</Router>
		</AuthProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
