import AntecipacaoIcon from './antecipacao-icon';
import Chevron from './chevron-icon';
import ChevronLeft from './chevron-left-icon';
import ChevronThin from './chevron-thin-icon';
import TransferenciaIcon from './transferencia-icon';

const setasIcons = {
	AntecipacaoIcon,
	ChevronThin,
	Chevron,
	ChevronLeft,
	TransferenciaIcon,
};

export default setasIcons;
