import React, { useState } from 'react';
import { useParams } from 'react-router';
import {
	DadosAntecipacoes,
	ConfirmarAntecipacao,
	ButtonCustom,
	ModalConfirmacao,
} from '../../components';
import { Icons } from '../../assets';
import { useAntecipacao, useAuth } from '../../hooks';

import * as s from './styled-antecipacoes';

const Anticipations = () => {
	const { step, timerConfirmarAntecipacao, deletarAntecipacao } =
		useAntecipacao();
	const { idRecipient } = useParams();
	const { currentUser } = useAuth();
	const antecipacao = localStorage.getItem('anticipation');
	const anticipationTimer =
		antecipacao !== null ? JSON.parse(antecipacao)?.anticipationTimer : '';
	const [loadingDeletar, setLoadingDeletar] = useState(false);
	const [openModalDeletar, setOpenModalDeletar] = useState(false);

	const recipientId = idRecipient || currentUser;

	const Progresso = () => (
		<s.Steps step={step}>
			<s.Span selected={step >= 1}>1. DADOS</s.Span>
			<Icons.ChevronThin />
			<s.Span selected={step >= 2}>2. CONFIRMAÇÃO</s.Span>
			<Icons.ChevronThin />
			<s.Span selected={step === 3}>3. CONCLUSÃO</s.Span>
		</s.Steps>
	);
	return (
		<s.Container>
			<ModalConfirmacao
				isOpen={openModalDeletar}
				title="DELETAR ANTECIPAÇÃO"
				text="Atenção: esta antecipação será deletada."
				question="Confirmar a exclusão?"
				onPositivePress={() => {
					setLoadingDeletar(true);
					if (recipientId !== undefined) {
						return deletarAntecipacao(false);
					}
					return deletarAntecipacao(true);
				}}
				negativeButtonLabel="Não quero deletar"
				positiveButtonLabel="Sim, quero deletar"
				isLoading={loadingDeletar}
				closeModal={() => setOpenModalDeletar(false)}
			/>
			<Progresso />

			{timerConfirmarAntecipacao === 0 ? (
				<s.Expired>
					<Icons.AttentionOutlined />
					<h2>Sessão expirada</h2>
					<p>
						Expirou o tempo para conclusão da sua antecipação.
						<br />
						Faça outra tentativa clicando no botão abaixo.
					</p>

					<ButtonCustom onClick={() => window.location.reload()}>
						Fazer antecipação
					</ButtonCustom>
				</s.Expired>
			) : (
				<>
					{timerConfirmarAntecipacao > 0 && (
						<s.Alerta>
							<Icons.AttentionOutlined />
							<p>
								Para sua segurança, esta tentativa de antecipação vai expirar às{' '}
								{anticipationTimer}.
							</p>
						</s.Alerta>
					)}
					{step === 1 ? (
						<DadosAntecipacoes setOpenModalDeletar={setOpenModalDeletar} />
					) : (
						<ConfirmarAntecipacao setOpenModalDeletar={setOpenModalDeletar} />
					)}
				</>
			)}
		</s.Container>
	);
};

export default Anticipations;
