import React, { useState } from 'react';
import { Icons, colors } from '../../../assets';
import { FloaterReact } from '../../../components';

import * as s from './styled-underline-input';

const UnderlineInput = ({
	id,
	className,
	type,
	disabled,
	value,
	onChange,
	onFocus,
	onBlur,
	placeholder,
	pattern,
	required,
	isInvalid,
	readOnly,
	autoComplete,
	maxLength,
	max,
	min,
	label,
	info,
	floaterWidth,
	erro,
	register,
	name,
	width,
	ref,
}) => {
	const [verSenha, setVerSenha] = useState(false);

	const inputType = () => {
		if (type === 'password' && !verSenha) return 'password';
		if (type !== 'text' && type !== 'password') return type;

		return 'text';
	};

	const Icon = () => {
		if (verSenha) return <Icons.StripeEye fill={isInvalid && colors.red} />;
		return <Icons.VisibleEye fill={isInvalid && colors.red} />;
	};

	const InfoIcon = ({ text }) => {
		if (text) {
			return (
				<FloaterReact
					width={floaterWidth || '15.625rem'}
					fontSize="0.875rem"
					textAlign="left"
					placement="right"
					content={text}
					hover
				>
					<Icons.InfoIconOutlined />
				</FloaterReact>
			);
		}

		return null;
	};

	return (
		<s.Wrapper
			width={width}
			className="wrapper-underline-input"
			isInvalid={isInvalid}
			readOnly={readOnly}
		>
			{label && (
				<label htmlFor={id} className="label-underline-input">
					{label}
					<InfoIcon text={info} />
				</label>
			)}
			<s.Container
				className="container-underline-input"
				isInvalid={isInvalid}
				disabled={disabled}
				readOnly={readOnly}
				paddingRight={type === 'password'}
			>
				{type === 'password' && (
					<s.IconWrapper
						isInvalid={isInvalid}
						onClick={() => setVerSenha(!verSenha)}
					>
						<Icon />
					</s.IconWrapper>
				)}

				<input
					id={id}
					className={className || 'underline-input'}
					type={inputType()}
					readOnly={readOnly || false}
					disabled={disabled}
					name={name}
					value={value}
					onChange={onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					placeholder={placeholder}
					autoComplete={autoComplete || 'off'}
					required={required || false}
					isInvalid={isInvalid}
					maxLength={maxLength}
					max={max}
					min={min}
					pattern={pattern}
					{...(register && register(name))}
					{...(ref && ref)}
				/>
			</s.Container>

			<span id="error">{isInvalid && erro ? erro : <br />}</span>
		</s.Wrapper>
	);
};

export default UnderlineInput;
