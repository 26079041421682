import styled from 'styled-components';

import { colors } from '../../assets/colors';
import { images } from '../../assets/images';

export const Container = styled.div`
	min-height: 100vh;
	width: 100vw;
	background-image: url(${images.background});
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
`;

export const Card = styled.div`
	padding: 5rem;
	border: 0.125rem solid rgba(43, 66, 70, 0.5);
	display: flex;
	position: relative;
	border-radius: 0.9375rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: radial-gradient(
		90.95% 100% at 66.86% 0%,
		rgba(46, 50, 51, 0.4) 0%,
		rgba(25, 25, 25, 0.4) 100%
	);
	backdrop-filter: blur(100px);

	svg.logotipo {
		width: 12.5rem;
		height: 4.06rem;
		margin-bottom: 2.5rem;
	}

	.forgot-password {
		font-weight: 400;
		margin: 1.56rem 0 3.13rem 0;
		font-size: 1.125rem;
		line-height: 1.4625rem;
		text-align: center;
		text-decoration: ${colors.primaryBlue};

		a {
			color: ${colors.primaryBlue};
			font-size: 1.13rem;
			font-weight: 400;
			text-align: center;
			margin-top: 0.9375rem;
		}
	}
	h1 {
		color: ${colors.offWhite};
		font-size: 1.25rem;
		line-height: 1.625rem;
	}

	::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 15px; /* 1 */
		border: 2px solid transparent; /* 2 */
		z-index: -1;
		background: radial-gradient(
				90.95% 100% at 66.86% 0%,
				rgba(46, 50, 51, 0.4) 0%,
				rgba(25, 25, 25, 0.4) 100%
			)
			border-box; /* 3 */
		mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
		-webkit-mask-composite: destination-out; /*5'*/
		mask-composite: exclude; /*5*/
	}
`;

export const Form = styled.div`
	width: 28.13rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	button.button-custom {
		width: 100%;
		height: 3.13rem;
		border: none;
	}

	svg.attention-icon {
		width: 1.13rem;
		height: 1.25rem;
		margin-right: 0.625rem;
	}

	span {
		color: ${colors.primaryRed} !important;
		max-width: 28.13rem;
		font-size: 1.25rem;
		margin-top: 1.88rem;
		text-align: center;
	}
`;

export const FormGroup = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const TitleDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: space-between;
	align-self: flex-start;
`;

export const LoginError = styled.div`
	color: ${colors.primaryRed} !important;
	font-size: 1rem;
	line-height: 1.6875rem;
`;
export const FormLabel = styled.label`
	display: flex;
	align-items: center;
	line-height: 1.63rem;
	font-size: 1.25rem;
	font-weight: 600;
	color: ${colors.offWhite};
	margin: 1.88rem 0 0.625rem 0;
`;
