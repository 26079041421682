import React from 'react';
import { format } from 'date-fns';
import { formatValor } from '../../../../utils';

import * as s from './styled-payment-details-body';

const DetailsBody = ({
	amount,
	expires,
	dataCriada,
	creditCard,
	boletoLink,
	freeInstallments,
	maxInstallments,
	minInstallments,
	chargeTransaction,
}) => {
	return (
		expires !== undefined &&
		dataCriada !== undefined && (
			<s.Container>
				<s.Saldo>
					<s.Tittle>SALDO ATUAL</s.Tittle>
					<s.Content>
						<s.TextContainer>
							Valor total
							<p>{formatValor(amount / 100)}</p>
						</s.TextContainer>
						<s.TextContainer>
							Data de criação
							<p>{format(new Date(dataCriada), 'dd/MM/yyyy - H:mm')}</p>
						</s.TextContainer>
						<s.TextContainer>
							Disponível até
							<p>{format(new Date(expires), 'dd/MM/yyyy - H:mm')}</p>
						</s.TextContainer>
					</s.Content>
				</s.Saldo>
				<s.Row />
				<s.Method>
					<s.Tittle>MEIOS DE PAGAMENTO</s.Tittle>
					<s.Content>
						{creditCard && (
							<s.TextContainer>
								Cartão de Crédito
								<p>
									{freeInstallments ? `${freeInstallments}x sem juros` : ''}
								</p>
								<p>
									{chargeTransaction
										? `${chargeTransaction} taxa de transição`
										: ''}
								</p>
								<p>
									{maxInstallments !== minInstallments
										? `${minInstallments}x a ${maxInstallments}x de parcelas`
										: `${minInstallments} parcela`}
								</p>
							</s.TextContainer>
						)}
						{boletoLink && (
							<s.TextContainer>
								Boleto
								<p>Dias para o vencimento: {boletoLink.expires_in} dias</p>
							</s.TextContainer>
						)}
					</s.Content>
				</s.Method>
			</s.Container>
		)
	);
};

export default DetailsBody;
