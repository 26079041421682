import React from 'react';

const ArrowUpRight = () => (
	<svg
		width="30"
		height="30"
		viewBox="0 0 30 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect x="0.5" y="0.5" width="29" height="29" rx="4.5" fill="#2E3333" />
		<path
			d="M8.93572 19.65C8.54519 20.0405 8.54519 20.6737 8.93572 21.0642C9.32624 21.4547 9.9594 21.4547 10.3499 21.0642L8.93572 19.65ZM20.3571 9.64282H21.3571C21.3571 9.09054 20.9094 8.64282 20.3571 8.64282V9.64282ZM9.64282 8.64282C9.09054 8.64282 8.64282 9.09054 8.64282 9.64282C8.64282 10.1951 9.09054 10.6428 9.64282 10.6428V8.64282ZM19.3571 20.3571C19.3571 20.9094 19.8048 21.3571 20.3571 21.3571C20.9094 21.3571 21.3571 20.9094 21.3571 20.3571H19.3571ZM10.3499 21.0642L21.0642 10.3499L19.65 8.93572L8.93572 19.65L10.3499 21.0642ZM9.64282 10.6428H20.3571V8.64282H9.64282V10.6428ZM19.3571 9.64282V20.3571H21.3571V9.64282H19.3571Z"
			fill="#BDC2C2"
		/>
		<rect x="0.5" y="0.5" width="29" height="29" rx="4.5" stroke="#BDC2C2" />
	</svg>
);
export default ArrowUpRight;
