import styled from 'styled-components';
import { colors } from '../../../assets';

export const Label = styled.div``;

export const SelectDiv = styled.div`
	display: flex;
	justify-content: ${({ contractor }) =>
		contractor ? 'flex-start' : 'space-evenly'};
	padding: 5px;
	gap: ${({ contractor }) => contractor && '1rem'};

	.react-switch-bg {
		width: 4rem !important;
		height: 1.75rem !important;
	}

	.react-switch-handle {
		width: 1.5rem !important;
		height: 1.5rem !important;
	}

	@media (min-width: 768px) {
	}
`;

export const RightText = styled.span`
	color: ${({ checked }) => (checked ? colors.white : colors.gray)};
`;

export const LeftText = styled.span`
	color: ${({ checked }) => (!checked ? colors.white : colors.gray)};
`;
