/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { LoadingRectangle } from '../../../../components';

import * as S from './styled-metadata';

const CardMetadata = ({ id, div, title, metadata, loading }) => (
	<S.Container id={id} div={div} metadata={metadata}>
		<S.Title>{title}</S.Title>
		<S.Row>
			<S.Col>
				<S.ColTitle>
					<h5>Cliente</h5>
				</S.ColTitle>
				<span>
					{!loading ? metadata?.cliente || '-' : <LoadingRectangle />}
				</span>
			</S.Col>
			<S.Col>
				<S.ColTitle>
					<h5>Comprovante</h5>
				</S.ColTitle>
				<span>
					{!loading ? metadata?.comprovante || '-' : <LoadingRectangle />}
				</span>
			</S.Col>
			<S.Col>
				<S.ColTitle>
					<h5>Documento</h5>
				</S.ColTitle>
				<span>
					{!loading ? (
						metadata?.documentoContractor || '-'
					) : (
						<LoadingRectangle />
					)}
				</span>
			</S.Col>
		</S.Row>
		<S.Row>
			<S.Col>
				<S.ColTitle>
					<h5>Taxa</h5>
				</S.ColTitle>
				<span>{!loading ? metadata?.mdr || '-' : <LoadingRectangle />}</span>
			</S.Col>
			<S.Col>
				<S.ColTitle>
					<h5>Transação</h5>
				</S.ColTitle>
				<span>
					{!loading ? metadata?.transacao || '-' : <LoadingRectangle />}
				</span>
			</S.Col>
		</S.Row>
	</S.Container>
);

export default CardMetadata;
