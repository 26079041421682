/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { LoadingRectangle } from '../../../../../components';
import { useAuth, useExtrato } from '../../../../../hooks';

import {
	mascaraCPF,
	mascaraCNPJ,
	capitalizeFirstLetterSpace,
} from '../../../../../utils';
import {
	getBankAccountTypes,
	getBanks,
	getRecipientDetails,
} from '../../../../../services';

import * as s from './styled-recebedor-antecipacoes';

const RecebedorAntecipacoes = ({ step = 1 }) => {
	// const {
	// 	// 	bancos,
	// 	// 	tiposConta,
	// 	informacoesRecebedor: infos,
	// 	// 	loadingRecebedor,
	// } = useExtrato();

	const { currentUser } = useAuth();
	const idRecebedor = currentUser?.recipientId;
	const [bancos, setBancos] = useState([]);
	const [tiposConta, setTiposConta] = useState([]);
	const [informacoesRecebedor, setInformacoesRecebedor] = useState({});
	const [loadingRecebedor, setLoadingRecebedor] = useState(false);

	useEffect(() => {
		setLoadingRecebedor(true);
		getRecipientDetails(idRecebedor)
			.then((resp) => setInformacoesRecebedor(resp.bankAccount))
			.catch(() => toast.error('Erro ao buscar os dados do recebedor'))
			.finally(() => setLoadingRecebedor(false));

		getBanks()
			.then((resp) => {
				setBancos(resp.map((item) => ({ value: item.code, label: item.name })));
			})
			.catch(() => toast.error('Erro ao buscar bancos'));

		getBankAccountTypes()
			.then((resp) => {
				setTiposConta(
					resp.map((item) => ({
						value: item,
						label: capitalizeFirstLetterSpace(item),
					}))
				);
			})
			.catch(() => toast.error('Erro ao buscar tipos de conta'));
	}, []);

	const value = {
		bancos,
		tiposConta,
		informacoesRecebedor,
		loadingRecebedor,
		idRecebedor,
	};

	const nomeBanco = (bankCode) =>
		bancos?.find((bank) => bank.value === bankCode)?.label || '--';
	const tipoConta = (type) =>
		tiposConta?.find((bank) => bank.value === type)?.label || '--';

	const documento = () => {
		if (
			!informacoesRecebedor.documentType ||
			!informacoesRecebedor.documentNumber
		)
			return '---';
		if (informacoesRecebedor?.documentType === 'cpf')
			return mascaraCPF(informacoesRecebedor.documentNumber);
		return mascaraCNPJ(informacoesRecebedor.documentNumber);
	};

	return (
		<s.Header step={step}>
			<div id="recebedor">
				<h2>Nome/Razão social do favorecido</h2>
				{loadingRecebedor ? (
					<LoadingRectangle />
				) : (
					<p id="nome">
						{informacoesRecebedor?.legalName || 'Nome do recebedor'}
					</p>
				)}
			</div>

			<div id="banco">
				<h2>Banco</h2>
				{loadingRecebedor ? (
					<LoadingRectangle />
				) : (
					<p>
						{informacoesRecebedor?.bankCode
							? `${informacoesRecebedor?.bankCode} - ${nomeBanco(
									informacoesRecebedor?.bankCode
							  )}`
							: 'código - Nome do banco'}
					</p>
				)}
			</div>

			<div>
				<h2>Agência</h2>
				{loadingRecebedor ? (
					<LoadingRectangle />
				) : (
					<p>
						{informacoesRecebedor?.agencia
							? informacoesRecebedor?.agenciaDv
								? `${informacoesRecebedor.agencia} - ${informacoesRecebedor.agenciaDv}`
								: informacoesRecebedor.agencia
							: 'agência - dv'}
					</p>
				)}
			</div>

			<div>
				<h2>Conta</h2>
				{loadingRecebedor ? (
					<LoadingRectangle />
				) : (
					<p>
						{informacoesRecebedor?.conta
							? `${informacoesRecebedor.conta}-${informacoesRecebedor.contaDv}`
							: 'conta-dv'}
					</p>
				)}
			</div>

			<div>
				<h2>Tipo de conta</h2>
				{loadingRecebedor ? (
					<LoadingRectangle />
				) : (
					<p>
						{informacoesRecebedor?.type
							? tipoConta(informacoesRecebedor?.type)
							: 'Tipo de conta'}
					</p>
				)}
			</div>

			<div>
				<h2>CPF/CNPJ</h2>
				{loadingRecebedor ? (
					<LoadingRectangle />
				) : (
					<p>{informacoesRecebedor?.type ? documento() : 'CPF ou CNPJ'}</p>
				)}
			</div>
		</s.Header>
	);
};

export default RecebedorAntecipacoes;
