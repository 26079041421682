import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Label = styled.div``;

export const SelectDiv = styled.div`
	display: flex;
	justify-content: space-evenly;
	gap: ${({ contractor }) => contractor && '1rem'};

	.react-switch-bg {
		background-color: transparent;
		border-radius: 5px;

		box-shadow: 0px 0px 1px 1px ${colors.gray};
		border: 1px solid ${colors.darkGray};
		align-items: center;
	}
	.transactionSwitch {
		align-items: center;
		height: 2.25rem;
		width: 4.75rem;
	}
`;
