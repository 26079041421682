import styled from 'styled-components';
import { colors } from '../../assets';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 30px 23px 30px 60px;
`;

export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: right;
	padding: 0px 60px 30px 0px;
	button {
		:first-of-type {
			margin-right: auto;
		}
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0px 60px 0px 0px;
	background-color: ${colors.darkGray};
	border-radius: 15px;
	> div {
		margin: 7.5px;
	}
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	min-width: 820px;
	max-height: 95px;

	.selectContractor {
		border-bottom: 1px solid ${colors.primaryBlue};
	}
	:last-child {
		padding-bottom: 0px;
	}

	div {
		margin-right: 15px;
	}
	div:last-child {
		margin-right: 0px;
	}
	.custom-select-input {
		border-bottom: 1px solid
			${({ isInvalid }) => (isInvalid ? colors.red : colors.primaryBlue)};
		border-radius: 0;
		height: 100%;
		padding-top: 0.4375rem;
		width: 20rem;
	}
`;
export const CenterLoader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 300px;
`;

export const RowSelect = styled.div`
	display: flex;
	flex-direction: row;

	max-height: 95px;

	.selectContractor {
		border-bottom: 1px solid ${colors.primaryBlue};
	}
	:last-child {
		padding-bottom: 0px;
	}

	div {
		margin-right: 15px;
	}
	div:last-child {
		margin-right: 0px;
	}
	.custom-select-input {
		border-bottom: 1px solid
			${({ isInvalid }) => (isInvalid ? colors.red : colors.primaryBlue)};
		border-radius: 0;
		height: 100%;
		padding-top: 0.4375rem;
		width: 20rem;
	}
	.custom-select-input-type {
		border-bottom: 1px solid
			${({ isInvalid }) => (isInvalid ? colors.red : colors.primaryBlue)};
		border-radius: 0;
		height: 100%;
		padding-top: 0.4375rem;
		width: 14.5rem;
	}
`;

export const SaveButtonDiv = styled.div`
	display: flex;
	align-content: center;
	justify-content: center;
	padding: 22.5px 5px;
`;
