import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: ${({ isSideMenuOpen }) =>
		isSideMenuOpen ? 'row' : 'column'};
	border-radius: 0px 15px 15px 0px;
	margin: ${({ isSideMenuOpen }) =>
		isSideMenuOpen
			? '3.125rem 3.125rem 6.25rem 2.8125rem'
			: '3.125rem 1.1875rem 4rem 1.1875rem'};
	justify-content: space-between;
	align-items: center;
	transition: all 0.5s ease-in-out;
`;

export const Logo = styled.div`
	display: flex;
	padding: ${({ isSideMenuOpen }) =>
		isSideMenuOpen ? '0' : '0 1.5rem 1.1875rem 1.3125rem'};
	transition: all 0.5s ease-in-out;
	.logotipo {
		width: ${({ isSideMenuOpen }) => (isSideMenuOpen ? '10rem' : '4.25rem')};
		height: ${({ isSideMenuOpen }) => (isSideMenuOpen ? '4.5rem' : '2rem')};
		transition: all 0.5s ease-in-out;
	}
`;

export const ToggleIcon = styled.div`
	display: flex;
	transition: all 0.5s ease-in-out;
	div {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 15px;
		background-color: ${colors.darkGray};
		width: 1.75rem;
		height: 1.75rem;
		cursor: pointer;
`;

export const NavItem = styled.div`
	display: flex;
	flex-direction: column;
	transition: all 0.5s ease-in-out;
`;
