import React, { useState } from 'react';
import moment from 'moment';
import { Calendario } from '../../../../molecules';
import { adicionarDiasCalendario } from '../../../../../utils';
import * as S from './dropdown-filter-style-last-transaction';

function DropdownFilterLastTransaction({
	formatedRangeDate,
	selectedDayRange,
	setSelectedDayRange,
	minimumDate,
	maximumDate,
	dataInvalida,
	setDataInvalida,
	selected,
	borderBlue,
}) {
	const [filterSelected, setFilterSelected] = useState('7 dias');
	const anterior = selected === 'anterior';
	const opcoes = [
		{
			label: 'hoje',
			filtrar: () => setSelectedDayRange(adicionarDiasCalendario(0)),
		},
		{
			label: '7 dias',
			filtrar: () =>
				setSelectedDayRange(adicionarDiasCalendario(anterior ? -7 : 7)),
		},
		{
			label: '15 dias',
			filtrar: () =>
				setSelectedDayRange(adicionarDiasCalendario(anterior ? -15 : 15)),
		},
		{
			label: '30 dias',
			filtrar: () =>
				setSelectedDayRange(adicionarDiasCalendario(anterior ? -30 : 30)),
		},
		{
			label: '60 dias',
			filtrar: () =>
				setSelectedDayRange(adicionarDiasCalendario(anterior ? -60 : 60)),
		},
		{
			label: 'dia',
			filtrar: () => setSelectedDayRange(adicionarDiasCalendario(0).to),
		},
		{
			label: 'período',
			filtrar: () =>
				setSelectedDayRange(adicionarDiasCalendario(anterior ? -5 : 5)),
		},
	];

	function totalDiasSelecionados() {
		const { from, to } = formatedRangeDate() || { from: '_', to: '_' };

		let dias = 0;

		if (from?.includes('_') || to?.includes('_'))
			return `Nenhum dia selecionado`;

		if (['hoje', 'dia'].includes(filterSelected)) dias = 1;
		else {
			let inicio = from.split('/');
			inicio = `${inicio[2]}-${inicio[1]}-${inicio[0]}`;
			let final = to?.split('/');
			if (final) final = `${final[2]}-${final[1]}-${final[0]}`;

			const start = moment(inicio, 'YYYY-MM-DD');
			const end = moment(final, 'YYYY-MM-DD');

			dias = moment.duration(end.diff(start)).asDays() + 1;
		}

		if (dias === 1) return `1 dia selecionado`;
		return `${Number.isNaN(dias) ? 0 : dias.toFixed(0)} dias selecionados`;
	}

	const ColunaOpcoes = () => (
		<S.Column>
			{opcoes.map((opt, index) => (
				<div key={index}>
					<S.Label
						borderBlue={borderBlue}
						selected={opt.label === filterSelected}
						onClick={() => {
							setFilterSelected(opt.label);
							setDataInvalida(() => ({ inicio: false, final: false }));
							opt.filtrar();
						}}
					>
						{opt.label}
					</S.Label>
					{(index === 0 || index === 4) && (
						<h2>
							{index === 0
								? anterior
									? 'últimos'
									: 'próximos'
								: 'personalizado'}
						</h2>
					)}
				</div>
			))}
		</S.Column>
	);
	return (
		<S.ContainerFilters>
			<ColunaOpcoes />
			<S.Column>
				<Calendario
					borderBlue={borderBlue}
					readOnly={!['dia', 'período'].includes(filterSelected)}
					selectedDayRange={dataInvalida.inicio ? null : selectedDayRange}
					setSelectedDayRange={setSelectedDayRange}
					minimumDate={minimumDate}
					maximumDate={maximumDate}
					numberOfMonths={2}
				/>
				<p>{totalDiasSelecionados()}</p>
			</S.Column>
		</S.ContainerFilters>
	);
}

export default DropdownFilterLastTransaction;
