import React from 'react';
import { colors } from '../colors';

const CheckedIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="checked-icon"
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			d="M11.267 6.32508L7.692 9.90842L6.317 8.53341C6.24229 8.44618 6.15036 8.37533 6.04697 8.32531C5.94358 8.27529 5.83097 8.24718 5.71621 8.24275C5.60144 8.23832 5.487 8.25766 5.38006 8.29955C5.27312 8.34145 5.176 8.40499 5.09479 8.48621C5.01357 8.56742 4.95003 8.66454 4.90813 8.77148C4.86624 8.87842 4.8469 8.99286 4.85133 9.10763C4.85576 9.22239 4.88387 9.335 4.93389 9.43839C4.98391 9.54178 5.05476 9.63371 5.142 9.70842L7.10033 11.6751C7.1782 11.7523 7.27054 11.8134 7.37207 11.8549C7.4736 11.8964 7.58232 11.9174 7.692 11.9167C7.91061 11.9158 8.12011 11.829 8.27533 11.6751L12.442 7.50842C12.5201 7.43095 12.5821 7.33878 12.6244 7.23723C12.6667 7.13568 12.6885 7.02676 12.6885 6.91675C12.6885 6.80674 12.6667 6.69782 12.6244 6.59627C12.5821 6.49472 12.5201 6.40255 12.442 6.32508C12.2859 6.16987 12.0747 6.08275 11.8545 6.08275C11.6343 6.08275 11.4231 6.16987 11.267 6.32508V6.32508ZM9.00033 0.666748C7.35215 0.666748 5.74099 1.15549 4.37058 2.07117C3.00017 2.98685 1.93206 4.28834 1.30133 5.81105C0.670603 7.33377 0.505575 9.00933 0.827119 10.6258C1.14866 12.2423 1.94234 13.7272 3.10777 14.8926C4.27321 16.0581 5.75807 16.8518 7.37458 17.1733C8.99109 17.4948 10.6666 17.3298 12.1894 16.6991C13.7121 16.0683 15.0136 15.0002 15.9292 13.6298C16.8449 12.2594 17.3337 10.6483 17.3337 9.00008C17.3337 7.90573 17.1181 6.8221 16.6993 5.81105C16.2805 4.80001 15.6667 3.88135 14.8929 3.10752C14.1191 2.3337 13.2004 1.71987 12.1894 1.30109C11.1783 0.882296 10.0947 0.666748 9.00033 0.666748V0.666748ZM9.00033 15.6667C7.68179 15.6667 6.39286 15.2758 5.29653 14.5432C4.2002 13.8107 3.34572 12.7695 2.84113 11.5513C2.33655 10.3331 2.20453 8.99269 2.46176 7.69948C2.719 6.40627 3.35393 5.21839 4.28628 4.28604C5.21863 3.35369 6.40652 2.71875 7.69973 2.46151C8.99293 2.20428 10.3334 2.3363 11.5516 2.84088C12.7697 3.34547 13.8109 4.19995 14.5435 5.29628C15.276 6.39261 15.667 7.68154 15.667 9.00008C15.667 10.7682 14.9646 12.4639 13.7144 13.7141C12.4641 14.9644 10.7684 15.6667 9.00033 15.6667V15.6667Z"
			fill={fill || colors.white}
		/>
	</svg>
);
export default CheckedIcon;
