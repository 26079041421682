import React, { useEffect } from 'react';
import cep from 'cep-promise';
import {
	justNumbers,
	mascaraTelefone,
	maskCep,
	validateEmail,
} from '../../../../utils';

import { ButtonCustom, UnderlineInput, CheckboxCustom } from '../../../atoms';

import * as s from './styled-contractor-contatos';

const ContractorContatos = ({
	setSteps,
	zipcode,
	setZipcode,
	street,
	setStreet,
	streetNumber,
	setStreetNumber,
	complement,
	setComplement,
	neighborhood,
	setNeighborhood,
	city,
	setCity,
	state,
	setState,
	phone,
	setPhone,
	email,
	setEmail,
	sponsorName,
	setSponsorName,
	sendEmail,
	setSendEmail,
	disableContact,
	setDisableContact,
	setIsCancelarCriacaoOpen,
	contactError,
	setContactError,
	activatePhone,
	setActivatePhone,
}) => {
	const disabledContinuar = () => {
		const {
			errorCep,
			errorStreetNumber,
			errorEmail,
			errorPhone,
			errorSponsor,
			errorStreet,
			errorCity,
			errorState,
			errorNeighborhood,
		} = contactError;
		if (
			errorCep === '' &&
			errorStreetNumber === '' &&
			errorEmail === '' &&
			errorPhone === '' &&
			errorSponsor === '' &&
			(errorStreet === '' || errorStreet === undefined) &&
			(errorCity === '' || errorCity === undefined) &&
			(errorState === '' || errorState === undefined) &&
			(errorNeighborhood === '' || errorNeighborhood === undefined)
		) {
			return setDisableContact(false);
		}
		return setDisableContact(true);
	};

	const estados = [
		{ name: 'Acre', shortname: 'AC', slug: 'acre' },
		{ name: 'Alagoas', shortname: 'AL', slug: 'alagoas' },
		{ name: 'Amapá', shortname: 'AP', slug: 'amapa' },
		{ name: 'Amazonas', shortname: 'AM', slug: 'amazonas' },
		{ name: 'Bahia', shortname: 'BA', slug: 'bahia' },
		{ name: 'Ceará', shortname: 'CE', slug: 'ceara' },
		{ name: 'Distrito Federal', shortname: 'DF', slug: 'distrito-federal' },
		{ name: 'Espírito Santo', shortname: 'ES', slug: 'espirito-santo' },
		{ name: 'Goiás', shortname: 'GO', slug: 'goias' },
		{ name: 'Maranhão', shortname: 'MA', slug: 'maranhao' },
		{ name: 'Mato Grosso', shortname: 'MT', slug: 'mato-grosso' },
		{ name: 'Mato Grosso do Sul', shortname: 'MS', slug: 'mato-grosso-do-sul' },
		{ name: 'Minas Gerais', shortname: 'MG', slug: 'minas-gerais' },
		{ name: 'Pará', shortname: 'PA', slug: 'para' },
		{ name: 'Paraíba', shortname: 'PB', slug: 'paraiba' },
		{ name: 'Paraná', shortname: 'PR', slug: 'parana' },
		{ name: 'Pernambuco', shortname: 'PE', slug: 'pernambuco' },
		{ name: 'Piauí', shortname: 'PI', slug: 'piaui' },
		{ name: 'Rio de Janeiro', shortname: 'RJ', slug: 'rio-de-janeiro' },
		{
			name: 'Rio Grande do Norte',
			shortname: 'RN',
			slug: 'rio-grande-do-norte',
		},
		{ name: 'Rio Grande do Sul', shortname: 'RS', slug: 'rio-grande-do-sul' },
		{ name: 'Rondônia', shortname: 'RO', slug: 'rondonia' },
		{ name: 'Roraima', shortname: 'RR', slug: 'roraima' },
		{ name: 'Santa Catarina', shortname: 'SC', slug: 'santa-catarina' },
		{ name: 'São Paulo', shortname: 'SP', slug: 'sao-paulo' },
		{ name: 'Sergipe', shortname: 'SE', slug: 'sergipe' },
		{ name: 'Tocantins', shortname: 'TO', slug: 'tocantins' },
	];

	const validate = (e, type) => {
		if (type === 'street') {
			const documentExist = e.target.value.length;
			const doc = e.target.value;
			if (documentExist) {
				e.target.value = doc;
				setStreet(doc);
				setContactError({ ...contactError, errorStreet: '' });
				disabledContinuar();
			} else {
				setStreet(doc);
				setContactError({ ...contactError, errorStreet: 'Endereço inválido' });
			}
		}

		if (type === 'streetNumber') {
			const documentExist = e.target.value.length;
			const doc = e.target.value;
			if (documentExist) {
				e.target.value = doc;
				setStreetNumber(doc);
				setContactError({ ...contactError, errorStreetNumber: '' });
				disabledContinuar();
			} else {
				setStreetNumber(doc);
				setContactError({
					...contactError,
					errorStreetNumber: 'Número do endereço inválido',
				});
			}
		}
		if (type === 'email') {
			if (e.length) {
				if (!validateEmail(e.toLowerCase().trim())) {
					setContactError({ ...contactError, errorEmail: '' });
				} else {
					setContactError({ ...contactError, errorEmail: 'Email inválido' });
				}
			}
		}

		if (type === 'neighborhood') {
			const documentExist = e.target.value.length;
			const doc = e.target.value;
			if (documentExist) {
				e.target.value = doc;
				setNeighborhood(doc);
				setContactError({ ...contactError, errorNeighborhood: '' });
				disabledContinuar();
			} else {
				setNeighborhood(doc);
				setContactError({
					...contactError,
					errorNeighborhood: 'Bairro Inválido',
				});
			}
		}

		if (type === 'phone') {
			const documentExist = e.target.value.length;
			const doc = e.target.value;
			setPhone(doc);
			if (activatePhone) {
				if (documentExist !== 15) {
					setContactError({ ...contactError, errorPhone: 'Telefone Inválido' });
				} else {
					setContactError({ ...contactError, errorPhone: '' });
				}
			}
			if (documentExist === 15) {
				e.target.value = doc;
				setActivatePhone(true);
				setContactError({ ...contactError, errorPhone: '' });
				disabledContinuar();
			}
		}

		if (type === 'city') {
			const documentExist = e.target.value.length;
			const doc = e.target.value;
			if (documentExist) {
				e.target.value = doc;
				setCity(doc);
				setContactError({ ...contactError, errorCity: '' });
				disabledContinuar();
			} else {
				setCity(doc);
				setContactError({ ...contactError, errorCity: 'Cidade Inválida' });
			}
		}

		if (type === 'sponsor') {
			const documentExist = e.target.value.length;
			const doc = e.target.value;

			if (documentExist) {
				e.target.value = doc;
				setSponsorName(doc);
				setContactError({ ...contactError, errorSponsor: '' });
				disabledContinuar();
			} else {
				setSponsorName(doc);
				setContactError({ ...contactError, errorSponsor: 'Contato Inválido' });
			}
		}

		if (type === 'estado') {
			const documentExist = e.target.value.length;
			const doc = e.target.value.toUpperCase();
			setState(doc);
			if (documentExist === 2) {
				e.target.value = doc;
				const findState = estados.find(
					(estado) => estado.shortname === `${doc}`
				);
				if (findState) {
					setContactError({ ...contactError, errorState: '' });
					disabledContinuar();
				} else {
					setContactError({ ...contactError, errorState: 'Estado Inválido' });
				}
			} else {
				setContactError({ ...contactError, errorState: 'Estado Inválido' });
			}
		}
	};

	async function handleCep(query) {
		try {
			const resp = await cep(query, {
				providers: ['brasilapi', 'widenet'],
			});
			setStreet(resp.street);
			setNeighborhood(resp.neighborhood);
			setCity(resp.city);
			setState(resp.state.toUpperCase());

			if (resp.street !== '') {
				setContactError({ ...contactError, errorStreet: '' });
			}
			if (resp.neighborhood !== '') {
				setContactError({ ...contactError, errorNeighborhood: '' });
			}
			if (resp.city !== '') {
				setContactError({ ...contactError, errorCity: '' });
			}
			if (resp.state !== '') {
				setContactError({ ...contactError, errorState: '' });
			}
			setContactError({ ...contactError, errorCep: '' });
			disabledContinuar();
		} catch (err) {
			setContactError({ ...contactError, errorCep: 'CEP inválido' });
		}
	}

	useEffect(() => {
		disabledContinuar();
	}, [contactError]);

	return (
		<s.Container>
			<s.Tittle>
				CONTATOS
				<p>Preencha abaixo os dados de contato da empresa</p>
			</s.Tittle>
			<s.Form>
				<s.InputTextField>
					<s.Underlineinput isInvalid={contactError.errorCep}>
						CEP*
						<UnderlineInput
							type="text"
							id="razaosocial"
							width="20.5rem"
							placeholder="00000-000"
							value={maskCep(zipcode)}
							name="address.zipcode"
							erro={contactError.errorCep}
							isInvalid={contactError.errorCep}
							onBlur={(e) => {
								const cepSearch = e.target.value.replace('-', '');

								handleCep(cepSearch);

								setZipcode(cepSearch);
							}}
							onChange={(e) => {
								const cepSearch = e.target.value.replace('-', '');

								if (cepSearch.length > 7) {
									handleCep(cepSearch);
								}

								setZipcode(cepSearch);
							}}
							maxLength={9}
						/>
					</s.Underlineinput>
					<s.LinkCep>
						<a href="https://buscacepinter.correios.com.br/app/endereco/index.php">
							Não sei meu CEP
						</a>
						<br />
					</s.LinkCep>
				</s.InputTextField>
				<s.InputTextField>
					<s.Underlineinput isInvalid={contactError.errorStreet}>
						Endereço*
						<UnderlineInput
							type="text"
							width="43rem"
							placeholder="Digite o endereço completo"
							id="endereco"
							value={street}
							erro={contactError.errorStreet}
							isInvalid={contactError.errorStreet}
							name="address.street"
							onChange={(e) => validate(e, 'street')}
						/>
					</s.Underlineinput>
					<s.Underlineinput isInvalid={contactError.errorStreetNumber}>
						Número*
						<UnderlineInput
							type="text"
							id="numero"
							width="12.1rem"
							placeholder="Digite o número"
							value={justNumbers(streetNumber)}
							maxLength={8}
							erro={contactError.errorStreetNumber}
							isInvalid={contactError.errorStreetNumber}
							name="address.streetNumber"
							onChange={(e) => validate(e, 'streetNumber')}
						/>
					</s.Underlineinput>
					<s.Underlineinput>
						Complemento
						<UnderlineInput
							id="complement"
							placeholder="Digite o complemento"
							value={complement}
							name="address.complement"
							onChange={(e) => setComplement(e.target.value)}
							type="text"
							maxLength={100}
							width="34rem"
						/>
					</s.Underlineinput>
				</s.InputTextField>
				<s.InputTextField>
					<s.Underlineinput isInvalid={contactError.errorNeighborhood}>
						Bairro*
						<UnderlineInput
							id="bairro"
							placeholder="Digite o bairro"
							value={neighborhood}
							erro={contactError.errorNeighborhood}
							isInvalid={contactError.errorNeighborhood}
							name="address.neighborhood"
							width="20.5rem"
							onChange={(e) => validate(e, 'neighborhood')}
						/>
					</s.Underlineinput>
					<s.Underlineinput isInvalid={contactError.errorCity}>
						Cidade*
						<UnderlineInput
							id="cidade"
							placeholder="Digite a cidade"
							value={city}
							name="address.city"
							width="20.5rem"
							erro={contactError.errorCity}
							isInvalid={contactError.errorCity}
							onChange={(e) => validate(e, 'city')}
						/>
					</s.Underlineinput>
					<s.Underlineinput isInvalid={contactError.errorState}>
						Estado*
						<UnderlineInput
							id="estado"
							placeholder="Digite o estado"
							value={state.toUpperCase()}
							name="address.state"
							erro={contactError.errorState}
							onChange={(e) => validate(e, 'estado')}
							isInvalid={contactError.errorState}
							maxLength={2}
							width="20.5rem"
						/>
					</s.Underlineinput>
				</s.InputTextField>
				<s.InputTextField>
					<s.Underlineinput isInvalid={contactError.errorPhone}>
						Telefone*
						<UnderlineInput
							id="telefone"
							typeNumber
							value={mascaraTelefone(phone)}
							name="contact.phone"
							onChange={(e) => validate(e, 'phone')}
							width="20.5rem"
							erro={contactError.errorPhone}
							isInvalid={contactError.errorPhone}
							placeholder="Digite o telefone com DDD"
							maxLength={15}
						/>
					</s.Underlineinput>
					<s.Underlineinput isInvalid={contactError.errorEmail}>
						E-mail*
						<UnderlineInput
							id="email"
							placeholder="Digite o email da empresa"
							value={email}
							name="contact.email"
							onChange={(e) => {
								setEmail(e.target.value);
								validate(e.target.value, 'email');
							}}
							onBlur={(e) => validate(e.target.value, 'email')}
							isInvalid={contactError.errorEmail}
							erro={contactError.errorEmail}
							maxLength={50}
							width="20.5rem"
						/>
					</s.Underlineinput>
				</s.InputTextField>
				<s.InputTextField>
					<s.Underlineinput isInvalid={contactError.errorSponsor}>
						Responsável para contato*
						<UnderlineInput
							id="responsavel"
							placeholder="Digite o nome do responsável para contato"
							value={sponsorName}
							name="contact.sponsorName"
							erro={contactError.errorSponsor}
							isInvalid={contactError.errorSponsor}
							onChange={(e) => validate(e, 'sponsor')}
							maxLength={100}
							width="21.1rem"
						/>
					</s.Underlineinput>
				</s.InputTextField>
				<div id="convite-email">
					<CheckboxCustom
						id="convite-email"
						checked={sendEmail}
						onChange={() => setSendEmail(!sendEmail)}
						text="Enviar convite via e-mail"
					/>
				</div>
			</s.Form>
			<s.ButtonGroup>
				<ButtonCustom
					outlined
					theme="green"
					onClick={() => setIsCancelarCriacaoOpen(true)}
				>
					Cancelar
				</ButtonCustom>
				<div id="step">
					<ButtonCustom theme="green" onClick={() => setSteps(1)}>
						Voltar
					</ButtonCustom>

					<ButtonCustom
						theme={!disableContact ? 'green' : 'gray'}
						onClick={() => setSteps(3)}
						disabled={disableContact}
					>
						Continuar
					</ButtonCustom>
				</div>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default ContractorContatos;
