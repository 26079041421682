import React, { useState } from 'react';
import Floater from 'react-floater';
import { Icons } from '../../../assets';
import { ButtonCustom } from '../index';

import * as s from './styled-floater-overlay';

const FloaterOverlay = ({ 
  style, 
  content, 
  component, 
  placement, 
  background,
  width,
  simbolicIcon,
  transform,
  textAlign,
  padding,
  titleColor,
  contentColor,
  fontSize,
  disabled
}) => {
  const [isOpen, setOpen] = useState(false);

  const callback = (action) => {
    setOpen(action === 'open');
  };

  const handleClick = () => {
    setOpen((state) => !state);
  };

  return (
    <div>
      <Floater
        callback={callback}
        open={isOpen}
        content={content || null}
				component={component || null}
				placement={placement || 'top'}
        styles={
					style ||
					s.customStyles(
						background,
						width,
						simbolicIcon,
						transform,
						textAlign,
						padding,
						titleColor,
						contentColor,
						fontSize
					)
				}
      >
        <ButtonCustom
          textTransform="capitalize"
          startIcon={<Icons.Upload />}
          onClick={handleClick}
          disabled={disabled}
        >
          Exportar tabela
        </ButtonCustom>
      </Floater>
      {isOpen && <s.Overlay onClick={handleClick} />}
    </div>
  );
}

export default FloaterOverlay;