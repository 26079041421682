import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 0.875rem 0 0 0;
	max-height: 13.125rem;
	min-height: 13.125rem;
	width: ${({ id }) => id === 'transaction-chart' && '100%'};

	.loader {
		display: flex;
		justify-content: center;
		margin: auto;
		padding-bottom: 4rem;
	}
`;

export const Title = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	h2 {
		font-size: 1rem;
		font-weight: 400;
		/* 		padding-top: ${({ id }) =>
			id === 'installments-chart' ? '0.625rem' : 'none'}; */
		padding-bottom: 0.8125rem;
		color: ${colors.white};
	}
`;
