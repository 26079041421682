import React from 'react';

const TransactionTableSwitchIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		title={title}
		width="100%"
		height="100%"
		viewBox="-6 -8 30 30"
		fill={fill || 'none'}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.57129 6.00007H14.4284M1.57129 1.71436H14.4284M1.57129 10.2858H14.4284"
			stroke="#BDC2C2"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default TransactionTableSwitchIcon;
