import React from 'react';
import { colors } from '../colors';

const CloseIcon = ({ title, fill, style, width, height }) => (
	<svg
		style={style}
		className="close-icon"
		width={width || '9'}
		height={height || '9'}
		viewBox="0 0 9 9"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || ''}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.263604 0.263604C0.615076 -0.087868 1.18492 -0.087868 1.5364 0.263604L4.5 3.22721L7.4636 0.263604C7.81508 -0.087868 8.38492 -0.087868 8.7364 0.263604C9.08787 0.615076 9.08787 1.18492 8.7364 1.5364L5.77279 4.5L8.7364 7.4636C9.08787 7.81508 9.08787 8.38492 8.7364 8.7364C8.38492 9.08787 7.81508 9.08787 7.4636 8.7364L4.5 5.77279L1.5364 8.7364C1.18492 9.08787 0.615076 9.08787 0.263604 8.7364C-0.087868 8.38492 -0.087868 7.81508 0.263604 7.4636L3.22721 4.5L0.263604 1.5364C-0.087868 1.18492 -0.087868 0.615076 0.263604 0.263604Z"
			fill={fill || colors.gray}
		/>
	</svg>
);
export default CloseIcon;
