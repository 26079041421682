import React from 'react';
import * as s from './styled-button-custom';
import { Loader } from '../../../components';

const ButtonCustom = ({
	borderColor,
	theme,
	outlined,
	children,
	type,
	link,
	target,
	id,
	onClick,
	isLoading,
	disabled,
	startIcon,
	height,
	width,
	textTransform,
	sizeLoader,
	onKeyPress,
	className,
	backgroundColor,
	disableParsedHover,
	alignItems,
	justifyContent,
	fontFamily,
	fontSize,
	fontWeight,
}) => {
	if (link) {
		return (
			<s.Link
				className="button-custom"
				id={id}
				href={link}
				target={target}
				height={height}
				width={width}
				tema={theme}
				outlined={outlined}
				isLoading={isLoading}
				disabled={disabled}
				textTransform={textTransform}
			>
				{startIcon && (
					<s.ContainerIcon haveChildren={children}>{startIcon}</s.ContainerIcon>
				)}
				{children}
			</s.Link>
		);
	}

	return (
		<s.ButtonCustom
			className={className || 'button-custom'}
			id={id}
			type={type || 'button'}
			height={height}
			width={width}
			tema={theme}
			outlined={outlined}
			isLoading={isLoading}
			disabled={disabled}
			textTransform={textTransform}
			onClick={onClick}
			onKeyPress={onKeyPress}
			backgroundColor={backgroundColor}
			disableParsedHover={disableParsedHover}
			justifyContent={justifyContent}
			alignItems={alignItems}
			borderColor={borderColor}
			fontSize={fontSize}
			fontFamily={fontFamily}
			fontWeight={fontWeight}
		>
			{!isLoading && startIcon && (
				<s.ContainerIcon haveChildren={children}>{startIcon}</s.ContainerIcon>
			)}
			{isLoading ? (
				<Loader size={sizeLoader || '2.5rem'} type="ThreeDots" />
			) : (
				children
			)}
		</s.ButtonCustom>
	);
};

export default ButtonCustom;
