import styled from 'styled-components';
import { colors } from '../../../assets';

export const Paginacao = styled.div`
	display: flex;
	margin: 0 auto;

	${({ disabled }) =>
		disabled &&
		` 
		cursor: not-allowed;

		ul.pagination li a {
			pointer-events: none;
		}
	`}

	button#pagina-anterior svg.chevron-icon {
		transform: rotate(180deg);
	}
	svg.chevron-icon {
		width: 2rem;
		height: 2rem;

		path {
			stroke: ${colors.lightGray};
		}
	}

	ul {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	li {
		display: flex;
	}

	.pagination {
		display: flex;
		gap: 5px;
	}

	.page-link {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		border: none;
		color: ${colors.white};
		background: ${colors.darkGray};
		width: 2.2rem;
		height: 2.2rem;
		font-weight: 500;
		font-size: 1rem;
		font-family: Jost;
		box-shadow: 0.5px 0.5px 7px rgba(177, 182, 182, 0.85);
		cursor: pointer;
	}

	.break {
		pointer-events: none;
	}

	.page-link:hover,
	.active .page-link {
		color: ${colors.black};
		background: ${colors.primaryBlue};
		font-weight: bold;
		font-size: 1rem;
	}

	.active a {
		cursor: default;
	}

	.previous-link,
	.next-link,
	.break {
		background: none;
		box-shadow: none;

		:hover {
			background: none;
		}
	}
`;

export const BotaoIcone = styled.button`
	border: none;
	background: none;
	padding: 0 5px;
	font-size: 0;
	height: 100%;

	:hover:not(:disabled) {
		background: none;
	}
`;
