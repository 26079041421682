import React from 'react';

const DiscoverIcon = ({ title, style }) => (
	<svg
		width="26"
		height="17"
		viewBox="0 0 26 17"
		style={style}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="discover-icon"
	>
		<title>{title || 'Ícone Discover'}</title>
		<rect
			x="0.15"
			y="0.976172"
			width="25.7"
			height="15.7"
			rx="1.85"
			fill="#FAFAFA"
			stroke="#B1B6B6"
			strokeWidth="0.3"
		/>
		<path
			d="M3.10685 10.2908H2V7.04722H3.11647C3.60413 7.04722 4.00356 7.19801 4.31476 7.49958C4.62596 7.79795 4.78156 8.17011 4.78156 8.61605C4.78156 9.13579 4.62756 9.54484 4.31957 9.84321C4.01478 10.1416 3.61054 10.2908 3.10685 10.2908ZM3.09241 7.65839H2.76036V9.6844H3.09722C3.39559 9.6844 3.62498 9.59136 3.78539 9.40529C3.94902 9.21921 4.03083 8.95613 4.03083 8.61605C4.03083 8.32731 3.94902 8.09632 3.78539 7.92307C3.62177 7.74662 3.39078 7.65839 3.09241 7.65839Z"
			fill="black"
		/>
		<path d="M6.07425 10.2908H5.33314V7.04722H6.07425V10.2908Z" fill="black" />
		<path
			d="M8.76298 9.34754C8.76298 9.63949 8.6555 9.8769 8.44055 10.0598C8.22559 10.2394 7.94006 10.3293 7.58394 10.3293C7.20216 10.3293 6.89096 10.2619 6.65034 10.1271V9.38122C6.94229 9.59297 7.25349 9.69884 7.58394 9.69884C7.69623 9.69884 7.78927 9.66997 7.86306 9.61222C7.93685 9.55126 7.97375 9.47266 7.97375 9.37641C7.97375 9.34433 7.96894 9.31385 7.95931 9.28498C7.94969 9.2561 7.93204 9.22883 7.90637 9.20317C7.88071 9.1775 7.85504 9.15665 7.82938 9.1406C7.80692 9.12136 7.77163 9.1005 7.7235 9.07804C7.67859 9.05238 7.64009 9.03152 7.60801 9.01548C7.57592 8.99944 7.5278 8.97698 7.46363 8.94811C7.40268 8.91923 7.35295 8.89678 7.31445 8.88074C7.10591 8.78449 6.93748 8.66578 6.80915 8.52462C6.68403 8.38346 6.62147 8.19577 6.62147 7.96157C6.62147 7.68245 6.72413 7.45787 6.92946 7.28784C7.13479 7.11459 7.41872 7.02797 7.78125 7.02797C8.10529 7.02797 8.3844 7.08251 8.61861 7.19159V7.88457C8.35874 7.73378 8.08924 7.65839 7.81013 7.65839C7.685 7.65839 7.58876 7.68406 7.52138 7.73539C7.45722 7.78672 7.42514 7.85249 7.42514 7.9327C7.42514 8.0129 7.46524 8.08509 7.54544 8.14925C7.62565 8.21021 7.74596 8.27598 7.90637 8.34656C8.00583 8.39148 8.08764 8.43158 8.15181 8.46687C8.21918 8.49895 8.29457 8.54547 8.37799 8.60643C8.46461 8.66418 8.53198 8.72674 8.58011 8.79411C8.63144 8.85828 8.67475 8.93848 8.71004 9.03473C8.74533 9.13098 8.76298 9.23525 8.76298 9.34754Z"
			fill="black"
		/>
		<path
			d="M11.6151 10.1464C11.4194 10.2683 11.1371 10.3293 10.7682 10.3293C10.2933 10.3293 9.89552 10.1769 9.57469 9.87209C9.25707 9.56409 9.09827 9.16627 9.09827 8.67862C9.09827 8.18454 9.25226 7.78191 9.56026 7.47071C9.87146 7.15951 10.2757 7.00391 10.773 7.00391C11.1387 7.00391 11.4194 7.06486 11.6151 7.18678V7.89901C11.3745 7.75143 11.1066 7.67764 10.8115 7.67764C10.5516 7.67764 10.3334 7.76908 10.157 7.95195C9.98054 8.13161 9.89231 8.37383 9.89231 8.67862C9.89231 8.97698 9.98054 9.21439 10.157 9.39085C10.3334 9.56409 10.5612 9.65072 10.8403 9.65072C11.1419 9.65072 11.4002 9.57372 11.6151 9.41972V10.1464Z"
			fill="black"
		/>
		<path
			d="M17.3294 10.2908H16.5113L15.4334 7.04722H16.2322L16.622 8.34175C16.6926 8.57274 16.7536 8.79572 16.8049 9.01067C16.8562 9.22242 16.8899 9.37641 16.9059 9.47266L16.93 9.62184C16.9813 9.28818 17.0856 8.86149 17.2428 8.34175L17.6326 7.04722H18.4218L17.3294 10.2908Z"
			fill="black"
		/>
		<path
			d="M20.8433 10.2908H18.827V7.04722H20.8241V7.63914H19.5729V8.37544H20.6893V8.96255H19.5729V9.69884H20.8433V10.2908Z"
			fill="black"
		/>
		<path
			d="M22.5515 7.64877H22.1857V8.56312H22.537C22.8579 8.56312 23.0183 8.40591 23.0183 8.0915C23.0183 7.95355 22.9766 7.84607 22.8932 7.76908C22.8097 7.68887 22.6958 7.64877 22.5515 7.64877ZM22.1857 10.2908H21.4446V7.04722H22.5659C22.9284 7.04722 23.2188 7.14347 23.437 7.33596C23.6583 7.52846 23.769 7.7787 23.769 8.08669C23.769 8.54868 23.5717 8.8679 23.1771 9.04436L24 10.2908H23.1145L22.4119 9.16467H22.1857V10.2908Z"
			fill="black"
		/>
		<circle
			cx="13.7298"
			cy="8.67483"
			r="1.84866"
			fill="url(#paint0_radial_3866_15278)"
		/>
		<defs>
			<radialGradient
				id="paint0_radial_3866_15278"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(14.7951 9.83416) rotate(-122.062) scale(3.36449)"
			>
				<stop stopColor="#FEC928" />
				<stop offset="0.712517" stopColor="#ED601A" />
				<stop offset="1" stopColor="#B02E0B" />
			</radialGradient>
		</defs>
	</svg>
);
export default DiscoverIcon;
