import React, { useRef, useCallback } from 'react';
import { useReactToPrint } from 'react-to-print';

import { ButtonCustom } from '../../../components';
import { Icons } from '../../../assets';
import { TemplateDetalhesTransacao } from './pdf-templates/detalhes-transacao';

const GeradorPDF = ({ id, hideButton, disabled, loading, icon, dados }) => {
	const componentRef = useRef();

	const pageStyle = `
	@media all {
		.page-break {
			display: none;
		}
	}
	
	@media print {
		html, body {
			height: initial !important;
			overflow: initial !important;
			-webkit-print-color-adjust: exact;
		}
	}
	
	@media print {
		.page-break {
			margin-top: 1rem;
			display: block;
			page-break-before: auto;
		}
	}
	
	@page {
		margin: 20mm;
		size: auto;
	}
`;

	const reactToPrintContent = useCallback(
		() => componentRef.current,
		[componentRef.current]
	);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		pageStyle,
		documentTitle: 'Detalhes da Transação',
	});

	return (
		<div>
			<ButtonCustom
				id={id}
				style={hideButton && { display: 'none' }}
				onClick={handlePrint}
				height="3.125rem"
				width="11.25rem"
				border="none"
				startIcon={
					icon || (
						<Icons.Upload style={{ width: '1.35rem', height: '1.875rem' }} />
					)
				}
				loading={loading}
				disabled={disabled}
			>
				Exportar
			</ButtonCustom>
			<div style={{ display: 'none' }}>
				<TemplateDetalhesTransacao ref={componentRef} dados={dados} />
			</div>
		</div>
	);
};

export default GeradorPDF;
