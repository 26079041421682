import React, { useEffect } from 'react';

import { ButtonCustom } from '../../..';
import { colors, Icons } from '../../../../assets';
import AddNewItem from '../add-new-item/add-new-item';
import { useNewPaymentLink } from '../../../../contexts';

import * as s from './styled-purchase-items';

const DataPurchaseItems = () => {
	const { managingItems, setManagingItems, handleCalculateTotal } =
		useNewPaymentLink();
	const itemsQuantity = managingItems.length;

	const handleNewItem = () => {
			setManagingItems([
				...managingItems,
				{
					id: itemsQuantity + 1,
					error: {
						title: '',
						unit_price: '',
						quantity: '',
						productId: '',
					},
					title: '',
					unit_price: 100,
					productId: '',
					quantity: 1,
					tangible: true,
					subTotal: 100,
				}
			]);
	};

	 useEffect(() => {
	 	handleCalculateTotal();
	 }, [itemsQuantity]);

	return (
		<>
			<s.ContainerHeader>
				<s.Title>ITENS DA COMPRA</s.Title>
				<s.ButtonGroup>
					<ButtonCustom
						className="add-new-item"
						outlined
						startIcon={<Icons.PlusCircleIcon fill={colors.primaryBlue} />}
						width="14.875rem"
						height="2.75rem"
						fontWeight={600}
						onClick={() => handleNewItem()}
					>
						Adicionar novo item
					</ButtonCustom>
				</s.ButtonGroup>
			</s.ContainerHeader>
			<s.ContainerForm>
				{managingItems.map((item, index) => (
					<AddNewItem index={index} id={item.id} key={item.id} />
				))}
			</s.ContainerForm>
		</>
	);
};

export default DataPurchaseItems;
