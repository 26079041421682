/* eslint-disable camelcase */
import React from 'react';
import { useRecipientExtractInfo } from '../../../../hooks';
import { mascaraCPF, mascaraCNPJ, mascaraTelefone } from '../../../../utils';

import * as s from './styled-mais-informacoes';

const InformacaoSocios = ({ socio }) => {
	const { name, document_number, email } = socio;
	return (
		<s.ConfigContainer>
			<s.Title>Dados cadastrais dos sócios</s.Title>
			<s.Row>
				<s.Col>
					<s.ColTitle>Nome do sócio</s.ColTitle>
					<s.Info>{name}</s.Info>
				</s.Col>
				<s.Col>
					<s.ColTitle>CPF</s.ColTitle>
					<s.Info>{document_number}</s.Info>
				</s.Col>
				<s.Col>
					<s.ColTitle>E-mail</s.ColTitle>
					<s.Info>{email}</s.Info>
				</s.Col>
			</s.Row>
		</s.ConfigContainer>
	);
};

const MoreInfo = () => {
	const { recebedor } = useRecipientExtractInfo();
	const { bankAccount, registerInformation } = recebedor;
	const { documentNumber } = bankAccount;
	const { email, phone_numbers, managing_partners } = registerInformation ?? {};
	const tipoDocumento = bankAccount?.documentType;
	const documentoFormatado =
		documentNumber && tipoDocumento === 'CPF'
			? mascaraCPF(String(documentNumber))
			: mascaraCNPJ(String(documentNumber));
	const telefone = phone_numbers?.[0]?.number
		? mascaraTelefone(phone_numbers?.[0]?.ddd + phone_numbers?.[0]?.number)
		: '-';
	return (
		<s.Container>
			<s.ConfigContainer>
				<s.Title>Dados cadastrais do recebedor</s.Title>
				<s.Row>
					<s.Col>
						<s.ColTitle>{tipoDocumento?.toUpperCase()}</s.ColTitle>
						<s.Info>{documentoFormatado}</s.Info>
					</s.Col>
					<s.Col>
						<s.ColTitle>E-mail</s.ColTitle>
						<s.Info>{email || '-'}</s.Info>
					</s.Col>
					<s.Col>
						<s.ColTitle>Telefone</s.ColTitle>
						<s.Info>{telefone}</s.Info>
					</s.Col>
				</s.Row>
				<s.Row>
					<s.Col>
						<s.ColTitle>Nome da empresa</s.ColTitle>
						<s.Info>{bankAccount?.legalName || '-'}</s.Info>
					</s.Col>
				</s.Row>
			</s.ConfigContainer>

			{tipoDocumento === 'cnpj' &&
				managing_partners &&
				managing_partners.map((socio) => <InformacaoSocios socio={socio} />)}
		</s.Container>
	);
};

export default MoreInfo;
