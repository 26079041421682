import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	grid-column: span 4 / auto;
	display: flex;
	flex-direction: row;

	border-radius: 15px;
	padding: 23px 27px 16px 33px;
	color: ${colors.white};
	background-color: ${colors.darkGray};
	margin-bottom: 2px;

	.title {
		font-size: 1em;
		font-weight: 400;
		color: ${colors.white};
		overflow: hidden;
		white-space: nowrap;
	}

	.padding-bottom {
		padding-bottom: 2px;
	}

	.text {
		overflow: hidden;
		white-space: nowrap;
		font-family: Jost;
		font-style: normal;
		font-weight: 600;
		font-size: 1.125em;
		color: ${colors.offWhite};
	}

	.button {
		display: flex;
		align-items: center;
		transform: translateY(-11%);
	}

	.button a {
		text-decoration: none;
	}

	> div {
		padding: 0 12px;
		align-items: center;
		align-content: center;
		/* 		> div {
			padding-bottom: 2px;
		} */
	}

	.loading-rectangle {
		height: 2rem;
		width: calc(100% - 2px);
	}
`;

export const Transaction = styled.div`
	flex-direction: column;
	padding-right: 50px;
	overflow: hidden;
	white-space: nowrap;
	min-width: 134px;

	.transaction-id {
		color: ${colors.offWhite};
		font-family: Jost;
		font-size: 1.375em;
		font-weight: 600;
		line-height: 32px;
		letter-spacing: 0em;
		text-align: left;
		white-space: nowrap;
	}
`;

export const Status = styled.div`
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;

	color: ${({ status }) => {
		if (status === 'paid') {
			return colors.green;
		}
		if (status === 'authorized') {
			return colors.primaryBlue;
		}
		if (status === 'refused') {
			return colors.primaryRed;
		}
		if (status === 'chargedback') {
			return colors.purple;
		}
		return colors.white;
	}};
`;

export const Payment = styled.div`
	flex-direction: column;
`;

export const AutorizedValue = styled.div`
	flex-direction: column;
	margin-right: auto;
`;

export const Installments = styled.div``;
