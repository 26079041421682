import React from 'react';
import { useHistory } from 'react-router';
import { colors, Icons } from '../../../../assets';
import ContainerModal from '../container-modal';
import { ButtonCustom } from '../../..';

import * as s from './styled-modal-cancelar-criacao-payment-link';

const ModalCancelarPaymentLink = ({ isOpen, closeModal }) => {
	const history = useHistory();
	return (
		<ContainerModal isOpen={isOpen} closeModal={closeModal}>
			<s.Content>
				<s.Imagem>
					<Icons.InfoTriangleIcon />
				</s.Imagem>
				<s.Title>CANCELAR CRIAÇÃO DO LINK </s.Title>
				<s.Subtitle>
					Tem certeza que deseja cancelar a criação deste link de pagamento?
					Todas as informações já preenchidas serão perdidas.
				</s.Subtitle>
				<s.ButtonGroup>
					<ButtonCustom width="15rem" textTransform="none" onClick={closeModal}>
						Voltar
					</ButtonCustom>
					<ButtonCustom
						textTransform="none"
						outlined
						width="15rem"
						theme="red"
						backgroundColor={colors.background}
						disableParsedHover
						onClick={() => history.push('./')}
						className="button-custom-cancelar"
					>
						Sim, quero cancelar
					</ButtonCustom>
				</s.ButtonGroup>
			</s.Content>
		</ContainerModal>
	);
};

export default ModalCancelarPaymentLink;
