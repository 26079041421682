import Attention from './attention-icon';
import AttentionOutlined from './attention-outlined-icon';
import InfoIconOutlined from './info-icon-outlined';
import Question from './question-icon';

const notificationIcons = {
	Attention,
	AttentionOutlined,
	InfoIconOutlined,
	Question,
};

export default notificationIcons;
