import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 2.5rem 1.5rem;
	margin-top: 1rem;
	overflow-y: hidden;
`;

export const Title = styled.h2`
	font-family: Jost;
	font-style: normal;
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 2rem;
	letter-spacing: 0.04em;
	color: ${colors.offWhite};
	justify-content: flex-start;
	text-align: left;
	margin-bottom: 0.5rem;
`;

export const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 3rem;
	div#select {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: ${colors.white};
	}
	.custom-input {
		border-radius: 0;
		height: 100%;
		padding-top: 0.4375rem;
	}
	.checkbox-custom {
		height: 1.625rem;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: start;
		color: ${colors.primaryBlue};
	}
	p {
		font-size: 1.25rem;
		color: ${colors.lightGray};
	}
`;

export const InputGroup = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	width: 100%;
	gap: 2rem;
`;

export const InputTextField = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 2rem;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	color: ${colors.white};
	.underlineinput {
		display: flex;
		flex-direction: column;
		color: ${colors.white};
	}
	.custom-input {
		border-radius: 0;
		height: 100%;
		padding-top: 0.4375rem;
	}
	.select-period {
		border: 0.0625rem solid ${colors.offWhite};
		border-radius: 0.5rem;
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
	}
`;
export const TextCustom = styled.div`
	display: flex;
	align-self: flex-start;
	color: ${({ readOnly }) => (readOnly ? colors.gray : colors.offWhite)};
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
`;

export const ButtonGroup = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 3.5rem;
	justify-content: space-between;
	margin-top: 5rem;
	div {
		display: flex;
		gap: 1rem;
	}
`;
