import styled from 'styled-components';
import { colors } from '../../../../assets';

export const SegundoPasso = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.9375rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 1.875rem 1.25rem;
	gap: 0.9375rem;
`;

export const Line = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	p {
		font-size: 1rem;
		font-weight: 400;
		color: ${colors.offWhite};
	}

	button:last-child {
		margin-left: 1.25rem;
	}

	svg.graph-icon {
		width: 1.375rem;
		height: 1.375rem;
	}
`;

export const CardsDiv = styled.div`
	display: flex;
	flex: 1;
	align-items: stretch;
	flex-wrap: wrap;
	list-style: none;
	gap: 0.9375rem;
`;

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex: 1;
	min-width: 200px;
	padding: 2.5rem;
	border-radius: 15px;
	border: 1px solid
		${({ color }) =>
			color === 'green'
				? colors.green
				: color === 'red'
				? colors.primaryRed
				: colors.primaryBlue};

	label {
		font-weight: 400;
		font-size: 1.25rem;
		color: ${colors.lightGray};
		margin-bottom: 0.625rem;
	}
	span#data {
		font-weight: 600;
		font-size: 2rem;
		color: ${colors.white};
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	margin: ${({ step }) =>
		step === 2 ? '6.25rem auto 1.25rem auto' : '2.8125rem 0 1.25rem 0'};

	h2 {
		font-weight: 500;
		font-size: 1.375rem;
		color: ${colors.offWhite};
		margin-bottom: 1.25rem;
	}
`;

export const Message = styled.div`
	display: flex;
	align-items: center;
	padding: 0.9375rem;
	background: ${({ type }) =>
		type === 'success' ? colors.primaryBlue : colors.red};
	border-radius: 0.9375rem;

	svg.checked-icon,
	svg.info-icon-outlined {
		width: 1.5625rem;
		height: 1.5625rem;
	}

	svg.info-icon-outlined {
		transform: rotate(180deg);
	}

	p {
		font-weight: 600;
		font-size: 1.25rem;
		line-height: 1.625rem;
		color: ${colors.background};
		margin-left: 0.9375rem;
	}
`;

export const Resposta = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.9375rem;

	p {
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 1.625rem;
		color: ${colors.lightGray};
	}

	b {
		font-weight: 500;
		color: ${colors.offWhite};
	}
`;
