import styled from 'styled-components';

export const Container = styled.div`
	border-radius: 0.3125rem;
	height: 120px;
	width: 100%;

	span {
		width: 100%;
		height: 100%;
	}
`;
