import React from 'react';
import Select from 'react-select';
import { FloaterReact } from '../../../components';
import { Icons } from '../../../assets';

import * as s from './styled-select-react';

export default function SelectReact(props) {
	const {
		options,
		setSelectedOption,
		value,
		readOnly,
		selectedOption,
		menuPlacement,
		isLoading,
		onScrollToBottom,
		onChange,
		id,
		isSearchable = true,
		style,
		outlined,
		height,
		width,
		className,
		placeholder,
		label,
		info,
		floaterWidth,
	} = props;

	const InfoIcon = ({ text }) => {
		if (text) {
			return (
				<FloaterReact
					width={floaterWidth || '15.625rem'}
					fontSize="0.875rem"
					textAlign="left"
					placement="right"
					content={text}
					hover
				>
					<Icons.InfoIconOutlined />
				</FloaterReact>
			);
		}

		return null;
	};
	return (
		<s.Wrapper
			width={width}
			className="wrapper-select-react"
			readOnly={readOnly}
		>
			{label && (
				<label htmlFor={id} className="wrapper-select-react">
					{label}
					<InfoIcon text={info} />
				</label>
			)}
			<s.ContainerSelect
				className={className || `select-react${outlined && ' select-outlined'}`}
				height={height}
				width={width}
			>
				<Select
					id={id || 'select-itens-component'}
					menuPlacement={menuPlacement || 'bottom'}
					isDisabled={readOnly}
					isLoading={isLoading}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					onChange={onChange}
					onMenuScrollToBottom={() => onScrollToBottom && onScrollToBottom()}
					styles={style ? { ...s.customStyles, ...style } : s.customStyles}
					value={value}
					placeholder={value || placeholder || 'Selecione'}
					noOptionsMessage={() => (
						<span style={{ opacity: 0.7 }}>Não há opções</span>
					)}
					options={options}
					isSearchable={isSearchable}
				/>
			</s.ContainerSelect>
		</s.Wrapper>
	);
}
