import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import CsvDownloader from 'react-csv-downloader';
import xlsx from 'json-as-xlsx';
import {
	ButtonCustom,
	FloaterOverlay,
	Pagination,
	PaymentFilters,
	SelectReact,
	Loader,
} from '../../components/';
import { useAuth } from '../../contexts';
import { getPaymentLinks } from '../../services';
import { Icons, colors } from '../../assets';
import { formatValor } from '../../utils';

import * as s from './styled-payment-links';

function PaymentLinks() {
	const { recipientId } = useAuth().currentUser;
	const [paymentLinks, setPaymentLinks] = useState([]);
	const history = useHistory();
	const [page, setPage] = useState(() => {
		if (JSON.parse(sessionStorage.getItem('paymentPage'))) {
			return JSON.parse(sessionStorage.getItem('paymentPage'));
		}
		return 1;
	});
	const [searchValue, setSearchValue] = useState(() => {
		if (sessionStorage.getItem('namePayment')) {
			return JSON.parse(sessionStorage.getItem('namePayment'));
		}
		return '';
	});
	const [selectedFilter, setSelectedFilter] = useState();
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState(() => {
		if (sessionStorage.getItem('statusPayment')) {
			return JSON.parse(sessionStorage.getItem('statusPayment'));
		}
		return 'active';
	});
	const [dataCreated, setDataCreated] = useState();

	const [orderAZ, setOrderAZ] = useState('asc');
	const [dataAntiga, setDataAntiga] = useState(false);
	const [orderAmount, setOrderAmount] = useState('asc');

	 const paymentCalendarInicialStorage = JSON.parse(
	 	sessionStorage.getItem('PaymentSelectedDateStart')
	 );
	 const paymentCalendarFinalStorage = JSON.parse(
	 	sessionStorage.getItem('PaymentSelectedDateFinal')
	 );

	const sortingString = (col) => {
		if (orderAZ === 'asc') {
			const sortedName = [...paymentLinks].sort((a, b) =>
				a[col] > b[col] ? 1 : -1
			);
			setPaymentLinks(sortedName);
			setOrderAZ('dsc');
		}
		if (orderAZ === 'dsc') {
			const sortedName = [...paymentLinks].sort((a, b) =>
				a[col] < b[col] ? 1 : -1
			);
			setPaymentLinks(sortedName);
			setOrderAZ('asc');
		}
	};

	const sortingDate = () => {
		if (dataAntiga === true) {
			const sortedDate = [...paymentLinks].sort((a, b) =>
				a.date_created > b.date_created ? 1 : -1
			);
			setPaymentLinks(sortedDate);
			setDataAntiga(false);
		}
		if (dataAntiga === false) {
			const sortedDate = [...paymentLinks].sort((a, b) =>
				a.date_created < b.date_created ? 1 : -1
			);
			setPaymentLinks(sortedDate);
			setDataAntiga(true);
		}
	};

	const sortingAmount = () => {
		if (orderAmount === 'asc') {
			const sortedValue = [...paymentLinks].sort((a, b) =>
				a.amount > b.amount ? 1 : -1
			);
			setPaymentLinks(sortedValue);
			setOrderAmount('dsc');
		}
		if (orderAmount === 'dsc') {
			const sortedValue = [...paymentLinks].sort((a, b) =>
				a.amount < b.amount ? 1 : -1
			);
			setPaymentLinks(sortedValue);
			setOrderAmount('asc');
		}
	};

	const colunas = [
		{
			id: 'status',
			displayName: 'Status',
		},
		{
			id: 'id',
			displayName: 'ID do link',
		},
		{
			id: 'name',
			displayName: 'Nome',
		},
		{
			id: 'date_created',
			displayName: 'Data de criação',
		},
		{
			id: 'url',
			displayName: 'Link',
		},
		{
			id: 'amount',
			displayName: 'Valor pago',
		},
	];

	const optionsItemsPage = [
		{
			label: '15 itens por página',
			value: 15,
		},
		{
			label: '30 itens por página',
			value: 30,
		},
		{
			label: '60 itens por página',
			value: 60,
		},
		{
			label: '100 itens por página',
			value: 100,
		},
	];

	const [itemsPerPage, setItemsPerPage] = useState(() => {
		if (
			sessionStorage.getItem('paymentItemsPerPage') &&
			sessionStorage.getItem('paymentItemsPerPage') !== ''
		) {
			return JSON.parse(sessionStorage.getItem('paymentItemsPerPage'));
		}
		sessionStorage.setItem(
			'paymentItemsPerPage',
			JSON.stringify(optionsItemsPage[0])
		);
		return optionsItemsPage[0];
	});

	const intervaloInicial = () => {
		const data = new Date();

		const diaHoje = data.getDate();
		const mesHoje = data.getMonth() + 1;
		const anoHoje = data.getFullYear();

		data.setDate(diaHoje - 7);

		const diaInicio = data.getDate();
		const mesInicio = data.getMonth() + 1;
		const anoInicio = data.getFullYear();

		if (paymentCalendarInicialStorage && paymentCalendarFinalStorage) {
			const diaInicioStorage = paymentCalendarInicialStorage.day;
			const mesInicioStorage = paymentCalendarInicialStorage.month;
			const anoInicioStorage = paymentCalendarInicialStorage.year;

			return {
				from: {
					day: diaInicioStorage,
					month: mesInicioStorage,
					year: anoInicioStorage,
				},
				to: paymentCalendarFinalStorage,
			};
		}

		return {
			from: { day: diaInicio, month: mesInicio, year: anoInicio },
			to: { day: diaHoje, month: mesHoje, year: anoHoje },
		};
	};

	const [intervaloDatas, setIntervaloDatas] = useState(intervaloInicial);

	const dataPaymentFormatada = () => {
		if (intervaloDatas?.day) {
			return {
				from: `${intervaloDatas.year}-${intervaloDatas.month}-${intervaloDatas.day}`,
				to: `${intervaloDatas.year}-${intervaloDatas.month}-${intervaloDatas.day}`,
			};
		}
		const dataInicial = intervaloDatas.from;
		const dataFinal = intervaloDatas.to;

		return {
			from: `${dataInicial.year}-${dataInicial.month}-${dataInicial.day}`,
			to: `${dataFinal.year}-${dataFinal.month}-${dataFinal.day}`,
		};
	};

	const StatusStyle = (value) => {
		if (value === 'inactive') {
			return (
				<p className="status" style={{ color: 'red', border: 0 }}>
					Inativo
				</p>
			);
		}
		if (value === 'active') {
			return (
				<p className="status" style={{ color: `${colors.green}`, border: 0 }}>
					Ativo
				</p>
			);
		}
		if (value === 'refused') {
			return (
				<p className="status" style={{ color: `${colors.red}`, border: 0 }}>
					Recusada
				</p>
			);
		}
		if (value === 'processing') {
			return (
				<p className="status" style={{ color: `${colors.yellow}`, border: 0 }}>
					Processando
				</p>
			);
		}
		if (value === 'canceled') {
			return (
				<p className="status" style={{ color: `${colors.red}`, border: 0 }}>
					Cancelado
				</p>
			);
		}

		if (value === 'expired') {
			return (
				<p className="status" style={{ color: 'yellow', border: 0 }}>
					Expirado
				</p>
			);
		}
	};

	const saveDataInStorage = () => {
		sessionStorage.setItem('namePayment', JSON.stringify(searchValue));
		sessionStorage.setItem('statusPayment', JSON.stringify(status));
	};

	function buscarPaymentLinks() {
		getPaymentLinks(
			recipientId,
			searchValue.toLowerCase().trim(),
			status.toString(),
			dataPaymentFormatada().from,
			dataPaymentFormatada().to
		)
			.then((resp) => {
				setPaymentLinks(resp.data);
				setDataCreated({
					...dataCreated,
					start: new Date().toLocaleString('en-IN').split(' ')[0],
				});
			})
			.catch(() => toast.error('Erro ao buscar Links de pagamento'))
			.finally(() => setLoading(false));
	}

	const exportarLogs = (csv) => {
		const botaoExportar = document.getElementById(
			`baixar-logs-${csv ? 'csv' : 'xlsx'}`
		);
		if (botaoExportar) botaoExportar.click();
	};

	const statusPortugues = (value) => {
		if (value === 'active') return 'Ativo';
		if (value === 'rejected') return 'Recusado';
		if (value === 'canceled') return 'Cancelado';
		if (value === 'processing') return 'Processing';
		if (value === 'expired') return 'Expirado';
	};

	const dadosCSV = () => {
		const logs = [];
		let obj = {};

		const formatData = (value) => {
			const date = new Date(value);

			return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
		};

		paymentLinks.forEach((linha) => {
			obj = {
				status: statusPortugues(linha.status),
				id: linha.id,
				name: linha?.name || '',
				date_created: formatData(linha.date_created),
				url: linha.url,
				amount: formatValor(linha?.amount / 100) || '',
			};

			logs.push(obj);
		});

		return logs;
	};

	const settingsExportExcel = {
		fileName: 'Link_de_pagamento',
		extraLength: 3,
		writeOptions: {},
	};

	const exportarComo = () => {
		let columns = [];
		const content = [];
		let obj = {};
		const formatData = (value) => {
			const date = new Date(value);

			return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
		};

		columns = colunas.map((col) => {
			return { label: col.displayName, value: col.id };
		});

		paymentLinks.forEach((linha) => {
			obj = {
				status: statusPortugues(linha.status),
				id: linha.id,
				name: linha?.name || '',
				date_created: formatData(linha.date_created),
				url: linha.url,
				amount: formatValor(linha?.amount / 100) || '',
			};

			content.push(obj);
		});

		const logs = [{ sheet: 'Payment-Links', columns, content }];

		xlsx(logs, settingsExportExcel);
	};

	const opcoesExportacao = () => (
		<s.Options>
			<ul>
				<li>
					<button
						type="button"
						onClick={() => exportarLogs(true)}
						disabled={loading}
					>
						CSV
					</button>
				</li>
				<li>
					<button
						type="button"
						onClick={() => exportarLogs(false)}
						disabled={loading}
					>
						Excel
					</button>
				</li>
			</ul>
		</s.Options>
	);

	useEffect(() => {
		buscarPaymentLinks();
		saveDataInStorage();
	}, [status]);

	return (
		<s.Container>
			<s.ContainerAddPayment>
				<s.HeaderColumn>
					<h1>Receba pagamentos</h1>
					<p>
						Crie e compartilhe links de pagamento com <br />
						os seus clientes e comece a vender agora.
					</p>
				</s.HeaderColumn>
				<div style={{ paddingRight: '25px' }}>
					<ButtonCustom
						className="buttonError"
						disableParsedHover
						width="16.6875rem"
						height="3.25rem"
						onClick={() => {
							history.push('/links-pagamento/criar-link-pagamento');
						}}
					>
						<Icons.CriarPayment style={{ backgroundColor: 'transparent' }} />
						Criar link de pagamento
					</ButtonCustom>
				</div>
			</s.ContainerAddPayment>
			<s.ContainerHeader>
				<PaymentFilters
					id="filtros-pagamento"
					handleSubmit={buscarPaymentLinks}
					loading={loading}
					searchValue={searchValue}
					selectedDayRange={intervaloDatas}
					selectedFilter={selectedFilter}
					setSearchValue={setSearchValue}
					setSelectedDayRange={setIntervaloDatas}
					setSelectedFilter={setSelectedFilter}
					setStatus={setStatus}
					status={status}
					paymentLinks={paymentLinks}
					saveDataInStorage={saveDataInStorage}
				/>
			</s.ContainerHeader>
			{loading ? (
				<Loader height={250} width={250} />
			) : (
				<>
					{paymentLinks.length > 0 ? (
						<>
							<s.TableMaster>
								<table>
									<s.TableHeader>
										<CsvDownloader
											id="baixar-logs-csv"
											filename="Link_de_pagamento"
											extension=".csv"
											separator=","
											wrapColumnChar=""
											columns={colunas}
											datas={dadosCSV}
											style={{ display: 'none' }}
										/>
										<button
											type="button"
											id="baixar-logs-xlsx"
											style={{ display: 'none' }}
											onClick={() => exportarComo()}
										>
											a
										</button>

										<FloaterOverlay
											placement="bottom"
											background={colors.white}
											padding="0"
											content={opcoesExportacao()}
											disabled={loading || paymentLinks.length === 0}
										/>
										<SelectReact
											value={itemsPerPage}
											onChange={(opt) => {
												setItemsPerPage(opt);
												setPage(1);
												sessionStorage.setItem(
													'paymentItemsPerPage',
													JSON.stringify(opt)
												);
												sessionStorage.setItem(
													'paymentPage',
													JSON.stringify(1)
												);
											}}
											id="itemsPerPage"
											isSearchable={false}
											options={optionsItemsPage}
											readOnly={loading}
											outlined
											height="50px"
											width="auto"
										/>
										{Math.ceil(paymentLinks.length / itemsPerPage.value) >
											1 && (
											<s.Pagination>
												<Pagination
													paginaAtual={page - 1}
													totalPaginas={Math.ceil(
														paymentLinks.length / itemsPerPage?.value
													)}
													onClick={(pagina) => {
														setPage(pagina.selected + 1);
														sessionStorage.setItem(
															'paymentPage',
															JSON.stringify(pagina.selected + 1)
														);
													}}
													disabled={loading}
												/>
											</s.Pagination>
										)}
									</s.TableHeader>
									<div id="scroll-table">
										<s.Head>
											<thead>
												<tr>
													<td>Status</td>
													<td>ID do link</td>
													<td>
														Nome do link{' '}
														<ButtonCustom
															disableParsedHover
															outlined
															textTransform="none"
															height="2rem"
															width="2rem"
															className="ArrowOrder"
															onClick={() => sortingString('name')}
															startIcon={<Icons.ArrowUpDown />}
														/>
													</td>
													<td>
														Data de criação{' '}
														<ButtonCustom
															disableParsedHover
															outlined
															textTransform="none"
															height="2rem"
															width="2rem"
															className="ArrowOrder"
															onClick={() => sortingDate()}
															startIcon={<Icons.ArrowUpDown />}
														/>
													</td>
													<td>Link</td>
													<td>
														Valor Pago{' '}
														<ButtonCustom
															disableParsedHover
															outlined
															textTransform="none"
															height="2rem"
															width="2rem"
															className="ArrowOrder"
															onClick={() => sortingAmount()}
															startIcon={<Icons.ArrowUpDown />}
														/>
													</td>
													<td>Detalhes</td>
												</tr>
											</thead>
										</s.Head>

										<s.TBody>
											{paymentLinks
												.slice(
													(page === 0 ? page + 1 : page - 1) *
														itemsPerPage.value,
													page * itemsPerPage.value
												)
												.map((item) => (
													<tr id={`table-rows-${item.paymentLinksId}`}>
														<td>{StatusStyle(item.status)}</td>
														<td>{item.id}</td>
														<td>{item.name}</td>
														<td>
															{
																new Date(item.date_created)
																	.toLocaleString('br-PT')
																	.split(' ')[0]
															}
														</td>
														<td>
															{item.url}{' '}
															<CopyToClipboard text={item.url}>
																<ButtonCustom
																	disableParsedHover
																	outlined
																	textTransform="none"
																	height="1.25rem"
																	width="1.25rem"
																	className="copy"
																	onClick={() =>
																		toast.success('O link foi copiado!')
																	}
																	startIcon={<Icons.Copy />}
																/>
															</CopyToClipboard>
														</td>
														<td>{formatValor(item.amount / 100)}</td>
														<td className="center">
															<ButtonCustom
																disableParsedHover
																theme="transparent"
																id={`detalhes-acesso-${item.userId}`}
																onClick={() =>
																	history.push(`/links-pagamento/${item.id}`)
																}
																startIcon={<Icons.ArrowUpRight />}
															/>
														</td>
													</tr>
												))}
										</s.TBody>
									</div>
									<s.TableHeader>
										{Math.ceil(paymentLinks.length / itemsPerPage.value) >
											1 && (
											<s.Pagination>
												<Pagination
													paginaAtual={page - 1}
													totalPaginas={Math.ceil(
														paymentLinks?.length / itemsPerPage?.value
													)}
													onClick={(pagina) => {
														setPage(pagina.selected + 1);
														sessionStorage.setItem(
															'paymentPage',
															JSON.stringify(pagina.selected + 1)
														);
													}}
													disabled={loading}
												/>
											</s.Pagination>
										)}
									</s.TableHeader>
								</table>
							</s.TableMaster>
						</>
					) : (
						<s.ErrorContent>
							<Icons.UnHappySearch />
							<h2 className="error-title">Sem resultados</h2>
							<p className="error-text">
								Reveja o período selecionado ou tente limpar os filtros. Se você
								nunca criou um link, clique no botão &quot;Criar link de
								pagamento&quot; e comece a vender!
							</p>
							<ButtonCustom
								className="buttonError"
								disableParsedHover
								width="16.6875rem"
								height="3.25rem"
								onClick={() => {
									toast.error(
										'Infelizmente a opção de criar link de pagamento está indisponivel no momento! :('
									);
								}}
							>
								<Icons.CriarPayment
									style={{ backgroundColor: 'transparent' }}
								/>
								Criar link de pagamento
							</ButtonCustom>
						</s.ErrorContent>
					)}
				</>
			)}
		</s.Container>
	);
}

export default PaymentLinks;
