import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const ConfigContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 1.5rem;
`;

export const Title = styled.h3`
	font-style: normal;
	font-weight: normal;
	font-size: 1.5rem;
	line-height: 1.9375rem;
	color: ${colors.lightGray};
	margin-bottom: 3rem;
`;

export const Info = styled.span`
	font-size: 1.25rem;
	line-height: 1.625rem;
	color: ${colors.lightGray};
`;

export const Row = styled.div`
	display: flex;
	gap: 3rem;
	:not(:last-child) {
		margin-bottom: 2.5rem;
	}
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const ColTitle = styled.h4`
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.3125rem;
	color: ${colors.offWhite};
`;
