import React from 'react';
import { CheckboxCustom } from '../../../../atoms';
import { capitalizeFirstLetter } from '../../../../../utils';

import * as S from './dropdown-filter-style-refuse-reasons';

const refuseReasonsData = [
	'Operadora de Cartão',
	'Tempo de resposta',
	'Antifraude',
	'Erro interno',
];

const refuseReasonsLabel = (value) => {
	const data = {
		'Operadora de Cartão': 'acquirer',
		'Tempo de resposta': 'acquirer_timeout',
		Antifraude: 'antifraud',
		'Erro interno': 'internal_error',
	};
	return data[value];
};

function RenderCheckboxCustom({ state, setState, id, text }) {
	return (
		<CheckboxCustom
			checked={state.includes(id)}
			state={state}
			setState={setState}
			fontSize="1.25rem"
			text={capitalizeFirstLetter(text)}
			checkboxAlign="flex-start"
			onChange={() => {
				if (state.includes(id)) {
					setState(state.filter((item) => item !== id));
				} else {
					setState([...state, id]);
				}
			}}
			TransactionFilters
		/>
	);
}

function DropdownFilterRefuseReasons({
	refuseReason,
	setRefuseReason,
	innerRef,
}) {
	return (
		<S.Container ref={innerRef}>
			<S.Column>
				<S.ColumnTitle>Motivos de recusa</S.ColumnTitle>
				{refuseReasonsData.map((item) => (
					<RenderCheckboxCustom
						state={refuseReason}
						setState={setRefuseReason}
						key={item}
						id={refuseReasonsLabel(item)}
						text={item}
						transactionsFilters
					/>
				))}
			</S.Column>
		</S.Container>
	);
}

export default DropdownFilterRefuseReasons;
