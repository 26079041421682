import React from 'react';

const DiscoverIcon = ({ title, style }) => (
	<svg
		width="26"
		height="16"
		viewBox="0 0 26 17"
		style={style}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="discover-icon"
	>
		<title>{title || 'Ícone Discover'}</title>
		<rect
			x="0.15"
			y="0.976172"
			width="25.7"
			height="15.7"
			rx="1.85"
			fill="#FAFAFA"
			stroke="#B1B6B6"
			strokeWidth="0.3"
		/>
		<rect width="26" height="16" rx="2" fill="#0063BF" />
		<path
			d="M21 11.581C21 12.9143 19.8851 14 18.5159 14H5V4.41905C5 3.08571 6.11491 2 7.48411 2H21V11.581Z"
			fill="white"
		/>
		<path
			d="M16.5991 9.12327H17.626C17.6554 9.12327 17.7238 9.11375 17.7532 9.11375C17.9488 9.07565 18.115 8.90422 18.115 8.66613C18.115 8.43756 17.9488 8.26613 17.7532 8.21851C17.7238 8.20898 17.6651 8.20898 17.626 8.20898H16.5991V9.12327Z"
			fill="url(#paint0_linear_3866_15287)"
		/>
		<path
			d="M17.5087 2.80859C16.5307 2.80859 15.7288 3.58002 15.7288 4.54193V6.34193H18.2422C18.3009 6.34193 18.3694 6.34193 18.4183 6.35145C18.9855 6.38002 19.406 6.66574 19.406 7.16097C19.406 7.55145 19.1224 7.88478 18.5943 7.95145V7.9705C19.1713 8.00859 19.6114 8.32288 19.6114 8.80859C19.6114 9.3324 19.1224 9.67526 18.4769 9.67526H15.719V13.1991H18.3302C19.3082 13.1991 20.1102 12.4276 20.1102 11.4657V2.80859H17.5087Z"
			fill="url(#paint1_linear_3866_15287)"
		/>
		<path
			d="M17.9879 7.27647C17.9879 7.0479 17.8216 6.89552 17.626 6.86695C17.6065 6.86695 17.5576 6.85742 17.5282 6.85742H16.5991V7.69552H17.5282C17.5576 7.69552 17.6162 7.69552 17.626 7.68599C17.8216 7.65742 17.9879 7.50504 17.9879 7.27647Z"
			fill="url(#paint2_linear_3866_15287)"
		/>
		<path
			d="M7.66986 2.80859C6.69186 2.80859 5.88991 3.58002 5.88991 4.54193V8.81812C6.38868 9.05621 6.90702 9.20859 7.42536 9.20859C8.0415 9.20859 8.37401 8.84669 8.37401 8.35145V6.3324H9.89969V8.34193C9.89969 9.12288 9.40091 9.76097 7.70898 9.76097C6.68208 9.76097 5.88013 9.54193 5.88013 9.54193V13.1895H8.49137C9.46937 13.1895 10.2713 12.4181 10.2713 11.4562V2.80859H7.66986Z"
			fill="url(#paint3_linear_3866_15287)"
		/>
		<path
			d="M12.5893 2.80859C11.6113 2.80859 10.8093 3.58002 10.8093 4.54193V6.80859C11.2592 6.43717 12.0416 6.19907 13.3032 6.25621C13.978 6.28478 14.7017 6.46574 14.7017 6.46574V7.19907C14.3399 7.01812 13.9096 6.85621 13.3521 6.81812C12.3937 6.75145 11.8167 7.20859 11.8167 8.00859C11.8167 8.81812 12.3937 9.27526 13.3521 9.19907C13.9096 9.16098 14.3399 8.98955 14.7017 8.81812V9.55145C14.7017 9.55145 13.9878 9.7324 13.3032 9.76098C12.0416 9.81812 11.2592 9.58002 10.8093 9.20859V13.2086H13.4206C14.3986 13.2086 15.2005 12.4372 15.2005 11.4753V2.80859H12.5893Z"
			fill="url(#paint4_linear_3866_15287)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_3866_15287"
				x1="15.7268"
				y1="8.66708"
				x2="20.1222"
				y2="8.66708"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#007940" />
				<stop offset="0.2285" stopColor="#00873F" />
				<stop offset="0.7433" stopColor="#40A737" />
				<stop offset="1" stopColor="#5CB531" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_3866_15287"
				x1="15.727"
				y1="7.9995"
				x2="20.1222"
				y2="7.9995"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#007940" />
				<stop offset="0.2285" stopColor="#00873F" />
				<stop offset="0.7433" stopColor="#40A737" />
				<stop offset="1" stopColor="#5CB531" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_3866_15287"
				x1="15.7268"
				y1="7.27504"
				x2="20.1218"
				y2="7.27504"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#007940" />
				<stop offset="0.2285" stopColor="#00873F" />
				<stop offset="0.7433" stopColor="#40A737" />
				<stop offset="1" stopColor="#5CB531" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_3866_15287"
				x1="5.88768"
				y1="7.9995"
				x2="10.3507"
				y2="7.9995"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#1F286F" />
				<stop offset="0.4751" stopColor="#004E94" />
				<stop offset="0.8261" stopColor="#0066B1" />
				<stop offset="1" stopColor="#006FBC" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_3866_15287"
				x1="10.7839"
				y1="7.9995"
				x2="15.1184"
				y2="7.9995"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6C2C2F" />
				<stop offset="0.1735" stopColor="#882730" />
				<stop offset="0.5731" stopColor="#BE1833" />
				<stop offset="0.8585" stopColor="#DC0436" />
				<stop offset="1" stopColor="#E60039" />
			</linearGradient>
		</defs>
	</svg>
);
export default DiscoverIcon;
