import React from 'react';
import { useHistory } from 'react-router-dom';
import { colors, Icons } from '../../../../assets';
import { ButtonCustom, PaginationWithInput } from '../../../';

import * as s from './styled-table-header';

const TableHeader = ({
	page,
	setPage,
	itensPagina,
	searchValue,
	handleSubmit,
	isLoading,
}) => {
	const history = useHistory();
	return (
		<s.Container>
			<s.TableTitle>Recebedores</s.TableTitle>
			<s.Toolbar>
				<ButtonCustom
					outlined
					startIcon={<Icons.AddPerson fill={colors.primaryBlue} />}
					onClick={() => history.push('/recebedores/adicionar-recebedor')}
					width="16.875rem"
					height="2.8125rem"
				>
					Adicionar recebedor
				</ButtonCustom>
				<PaginationWithInput
					page={page}
					setPage={setPage}
					itensPagina={itensPagina}
					searchValue={searchValue}
					handleSubmit={handleSubmit}
					isLoading={isLoading}
				/>
			</s.Toolbar>
		</s.Container>
	);
};

export default TableHeader;
