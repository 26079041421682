import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router';
import { Toaster } from 'react-hot-toast';

import GlobalStyle from './styles/global';
import Routes from './routes/routes';
import { Header, Sidemenu, Loader, EnvironmentAlert } from './components';
import { useAuth } from './hooks';
import { colors } from './assets';
import { SidemenuProvider } from './contexts';

import * as s from './styled-App';

const App = ({ history }) => {
	const routePath = useLocation().pathname;
	const { REACT_APP_ENV } = process.env;
	const environment = REACT_APP_ENV;

	const { currentUser, loadingToken } = useAuth();
	const userType = currentUser?.role;
	const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
	const [notFound, setNotFound] = useState(false);
	const loading = loadingToken;

	useEffect(() => {
		if (localStorage.getItem('anticipation')) {
			localStorage.removeItem('anticipation');
		}
	}, [history.location.pathname]);

	return loading ? (
		<s.ContainerLoader>
			<Loader color={colors.primaryBlue} />
		</s.ContainerLoader>
	) : (
		<s.Container>
			<GlobalStyle />
			<Toaster position="bottom-right" />
			<s.Wrapper
				isLogged={userType}
				isNotFound={notFound}
				isSideMenuOpen={isSideMenuOpen}
			>
				{userType && !notFound && (
					<SidemenuProvider>
						<Sidemenu
							isSideMenuOpen={isSideMenuOpen}
							setIsSideMenuOpen={setIsSideMenuOpen}
						/>
					</SidemenuProvider>
				)}

				<main>
					{routePath !== '/login' && environment !== 'production' && (
						<s.ContainerAlert>
							<EnvironmentAlert />
						</s.ContainerAlert>
					)}

					{userType && !notFound && <Header />}
					<Routes setNotFound={setNotFound} />
				</main>
			</s.Wrapper>
		</s.Container>
	);
};

export default withRouter(App);
