import styled from 'styled-components';

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	div:nth-child(1) {
		padding: 0 !important;
	}
`;

export const IconWrapper = styled.div`
	svg {
		cursor: pointer;
		height: 1rem;
		width: 1rem;
		position: absolute;
		z-index: 1;
		right: 3rem;
		top: 3rem;
	}
`;

export const ContainerIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 1rem;
	width: 1rem;
	border-radius: 50%;
	background: ${({ background }) => background};
	position: absolute;
	z-index: 1;
	right: 20px;
	top: 20px;

	svg {
		position: initial;
	}
`;
