import React from 'react';
import { colors } from '../colors';

const CreditCardIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="credit-card-icon"
		width="24"
		height="16"
		viewBox="0 0 24 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || 'Ícone de filtro'}</title>
		<g clipPath="url(#clip0_3866_23312)">
			<path
				d="M20.0702 0H3.04094C2.23443 0 1.46096 0.301026 0.890676 0.836844C0.32039 1.37266 0 2.09938 0 2.85714V10.8571H23.1111V2.85714C23.1111 2.09938 22.7907 1.37266 22.2204 0.836844C21.6501 0.301026 20.8767 0 20.0702 0ZM7.06104 7.4743C7.06105 7.54382 7.04637 7.61264 7.01787 7.67679C6.98937 7.74095 6.94761 7.79918 6.895 7.84807C6.8424 7.89697 6.78003 7.93555 6.71144 7.96164C6.64285 7.98772 6.56942 8.00076 6.49543 8H3.49708C3.34868 8 3.20634 7.94461 3.10141 7.84602C2.99647 7.74743 2.93752 7.61372 2.93752 7.4743V6.17142C2.93751 6.03146 2.99627 5.89718 3.10103 5.79768C3.2058 5.69818 3.34812 5.6415 3.49708 5.64H6.45286C6.60287 5.64 6.74672 5.69599 6.8528 5.79565C6.95887 5.89532 7.01847 6.03048 7.01847 6.17142L7.06104 7.4743Z"
				fill="#B1B6B6"
			/>
			<path
				d="M23.1111 12.5996V13.171C23.1111 13.9288 22.7907 14.6556 22.2204 15.1914C21.6501 15.7272 20.8767 16.0282 20.0702 16.0282H3.04094C2.23443 16.0282 1.46096 15.7272 0.890676 15.1914C0.32039 14.6556 0 13.9288 0 13.171V12.5996H23.1111Z"
				fill="#B1B6B6"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3866_23312">
				<rect width="23.1111" height="16" fill={fill || colors.lightGray} />
			</clipPath>
		</defs>
	</svg>
);
export default CreditCardIcon;
