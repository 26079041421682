import React from 'react';
import { colors } from '../colors';

const SetaCurvaIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="seta-curva-icon"
		width="16"
		height="15"
		viewBox="0 0 16 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || ''}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.893693 6.70157C0.40012 6.21881 0.40012 5.4361 0.893693 4.95334L5.06426 0.874121C5.55783 0.39136 6.35807 0.39136 6.85165 0.874121C7.34522 1.35688 7.34522 2.13959 6.85165 2.62236L3.57477 5.82745L6.85165 9.03255C7.34522 9.51531 7.34522 10.298 6.85165 10.7808C6.35807 11.2635 5.55783 11.2635 5.06426 10.7808L0.893693 6.70157Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.36613 5.82745C1.36614 5.41536 2.10597 4.70272 2.79669 4.60423L3.40514 4.53004C3.05118 4.17918 2.79669 4.60423 2.79669 4.60423L2.80337 4.60329L2.81771 4.60129L2.86866 4.59439C2.91227 4.58858 2.97486 4.58046 3.05441 4.57068C3.21344 4.55114 3.44073 4.52494 3.72002 4.49743C4.2772 4.44257 5.04829 4.38182 5.90133 4.3592C6.75094 4.33667 7.70384 4.3513 8.61861 4.45357C9.51607 4.5539 10.466 4.74761 11.2574 5.13282C12.5733 5.77332 13.4652 6.94542 13.9987 7.82448C14.2761 8.28141 14.4821 8.70195 14.6194 9.00933C14.6884 9.16375 14.7409 9.29157 14.7772 9.38358C14.7953 9.42963 14.8095 9.46686 14.8196 9.49413L14.8319 9.52748L14.8358 9.53832L14.8372 9.54224L14.8377 9.54381C14.8377 9.54381 14.8382 9.54514 13.6434 9.94812L14.8382 9.54514C15.0657 10.1906 14.7152 10.8942 14.0554 11.1168C13.396 11.3392 12.6771 10.997 12.449 10.3525L12.4444 10.34C12.4394 10.3266 12.4308 10.3039 12.4187 10.2731C12.3943 10.2112 12.3558 10.1172 12.3031 9.99926C12.1971 9.76198 12.0374 9.43623 11.8252 9.08659C11.3796 8.3525 10.797 7.67031 10.1312 7.34628C9.72122 7.14671 9.10599 6.99653 8.33156 6.90995C7.57444 6.82531 6.7487 6.81002 5.96983 6.83067C5.19441 6.85123 4.48722 6.90676 3.97323 6.95737C3.71693 6.98261 3.51042 7.00646 3.36954 7.02377C3.29914 7.03242 3.24525 7.03943 3.20986 7.04414L3.17094 7.04941L3.05131 7.47571L2.62996 7.06365M1.36613 5.82745C1.46681 6.50294 1.72845 6.84983 2.62996 7.06365L1.36613 5.82745Z"
			fill={fill || colors.primaryBlue}
		/>
	</svg>
);
export default SetaCurvaIcon;
