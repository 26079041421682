import React from 'react';

import {
	FormStep,
	DataNewPaymentLinks,
	FormPayment,
	ConfirmPayment,
	ConclusionPayment,
} from '../../components';
import { useNewPaymentLink } from '../../contexts';
import * as s from './styled-new-payment-links';

const STEPS = ['informações', 'pagamento', 'confirmação', 'conclusão'];

const NewPaymentLink = () => {
	const { currentStep, setCurrentStep } = useNewPaymentLink();

	return (
		<s.Container>
			<FormStep steps={STEPS} currentStep={currentStep} />
			{currentStep === 1 && (
				<DataNewPaymentLinks
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
				/>
			)}
			{currentStep === 2 && (
				<FormPayment
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
				/>
			)}
			{currentStep === 3 && (
				<ConfirmPayment
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
				/>
			)}
			{currentStep === 4 && (
				<ConclusionPayment
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
				/>
			)}
		</s.Container>
	);
};

export default NewPaymentLink;
