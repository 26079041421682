import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: transparent;
	border-radius: 0.9375rem;
	padding: 2.5rem 1.5rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const Icon = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	margin: 2rem 0 1rem 0;
`;

export const Title = styled.h4`
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 2rem;
	text-align: center;
	letter-spacing: 0.04rem;
	text-transform: uppercase;
	margin-bottom: 1rem;
	${({ className }) =>
		className === 'success'
			? `color: ${colors.primaryBlue};`
			: `color: ${colors.offWhite};`}
`;

export const Subtitle = styled.h5`
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 1.25rem;
	line-height: 1.625rem;
	text-align: center;
	color: ${colors.lightGray};
`;

export const ButtonGroup = styled.div`
	display: flex;
	margin-top: 4rem;
	width: 100%;
	justify-content: center;
	gap: 1rem;
	button {
		width: 12.5rem;
		height: 3.3125rem;
	}
`;
