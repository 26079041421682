import styled from 'styled-components';
import { colors } from '../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	height: 3.75rem;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	margin-bottom: 1rem;
	justify-content: space-around;
`;

export const Step = styled.div`
	display: flex;
	flex: 1;
	min-width: 0;
	padding: 0 1rem;
	align-items: center;
	.active {
		color: ${colors.primaryBlue};
	}
`;

export const StepText = styled.p`
	flex: 1;
	font-family: Jost;
	font-size: 1.375rem;
	color: ${({ isChecked }) => (isChecked ? colors.primaryBlue : colors.gray)};
	text-align: center;
	line-height: 2rem;
	letter-spacing: 0.04em;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	.active {
		color: ${colors.primaryBlue};
	}
`;

export const Icon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.5rem;
	height: 100%;

	svg {
		height: 3.75rem;
	}
`;
