import styled from 'styled-components';

export const Container = styled.div`
	/* grid-area: ${(props) => (props.div ? props.div : 'none')}; */
	display: flex;
	flex-direction: row;
	align-content: center;
	height: 9.375rem;
	height: 100%;
	width: 80%;
	margin-right: 8px;
`;
