import React from 'react';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { LoadingRectangle } from '../../../../components';
import { useRecipientExtractInfo } from '../../../../hooks';

import * as s from './styled-recebedor';

const Recebedor = () => {
	const { recebedor, isLoadingRecebedor } = useRecipientExtractInfo();
	const { bankAccount, status, created } = recebedor;
	const nomeConta = bankAccount?.legalName;
	const { recipientId } = useParams();
	const dataContaCriada = created
		? format(new Date(created), 'dd/MM/yyyy')
		: '--/--/----';
	const traduzirStatus = () => {
		if (status === 'active') return 'Ativo';
		if (status === 'inactive') return 'Inativo';
		if (status === 'refused') return 'Recusado';
	};

	return (
		<s.Header>
			<s.Column>
				<s.Title>Conta bancária</s.Title>
				<s.MainInfo>
					{isLoadingRecebedor ? <LoadingRectangle /> : nomeConta}
				</s.MainInfo>
				<s.SecondaryInfo>
					{isLoadingRecebedor ? <LoadingRectangle /> : `ID#${bankAccount?.id}`}
				</s.SecondaryInfo>
			</s.Column>
			<s.Column>
				<s.Title>Status</s.Title>
				<s.Status>
					{isLoadingRecebedor ? <LoadingRectangle /> : traduzirStatus()}
				</s.Status>
			</s.Column>
			<s.Column className="id-recebedor">
				<s.Title>ID do recebedor</s.Title>
				<s.MainInfo>
					{isLoadingRecebedor ? <LoadingRectangle /> : recipientId || '--'}
				</s.MainInfo>
				<s.SecondaryInfo className="right-side">
					{isLoadingRecebedor ? (
						<LoadingRectangle />
					) : (
						`Data da criação: ${dataContaCriada}`
					)}
				</s.SecondaryInfo>
			</s.Column>
		</s.Header>
	);
};

export default Recebedor;
