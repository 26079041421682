import React from 'react';
import { colors } from '../../../assets';

const AttentionIcon = ({ title, fill, style }) => (
	<svg
		className="attention-icon"
		style={style}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.4455 6.55154C11.8245 5.85652 12.8224 5.85652 13.2014 6.55154L21.9094 22.5215C22.2728 23.1879 21.7905 24.0002 21.0315 24.0002H3.61543C2.85643 24.0002 2.37411 23.1879 2.73746 22.5215L11.4455 6.55154ZM12.3235 10.5444C12.7377 10.5444 13.0735 10.8801 13.0735 11.2944V16.5885C13.0735 17.0027 12.7377 17.3385 12.3235 17.3385C11.9092 17.3385 11.5735 17.0027 11.5735 16.5885V11.2944C11.5735 10.8801 11.9092 10.5444 12.3235 10.5444ZM12.3235 21.0885C12.8757 21.0885 13.3235 20.6144 13.3235 20.0297C13.3235 19.4449 12.8757 18.9708 12.3235 18.9708C11.7712 18.9708 11.3235 19.4449 11.3235 20.0297C11.3235 20.6144 11.7712 21.0885 12.3235 21.0885Z"
			fill={fill || colors.red}
		/>
	</svg>
);
export default AttentionIcon;
