import React from 'react';
import { format } from 'date-fns';
import CsvDownloader from 'react-csv-downloader';
import { useHistory } from 'react-router-dom';
import xlsx from 'json-as-xlsx';
import { Icons } from '../../../../../../assets';
import { LoadingRectangle, FloaterReact } from '../../../../../../components';
import {
	formatarParaReais,
	settingsExportExcel,
} from '../../../../../../utils';

import * as s from './styled-tabela';

const DataTable = ({ dados, loadingExtrato }) => {
	const history = useHistory();
	const totalColunas = dados?.[1]?.tipoMercado ? 8 : 7;
	const colunaMercado = totalColunas === 8;

	const colunas = [
		{
			id: 'data',
			displayName: 'Data',
		},
		{
			id: 'idOperacao',
			displayName: 'ID da Operação',
		},
		{
			id: 'idTransacao',
			displayName: 'ID da Transação',
		},
		{
			id: 'descricao',
			displayName: 'Descrição',
		},
		{
			id: 'entradas',
			displayName: 'Entradas',
		},
		{
			id: 'saidas',
			displayName: 'Saídas',
		},
		{
			id: 'total',
			displayName: 'Total',
		},
	];

	function msgDescricao({ type, installment, transactionId, description }) {
		let msg;

		switch (type) {
			case 'credit':
				msg = installment !== null ? `Cartão de crédito - Parcela ${installment}` : 'Pix';
				break;
			case 'refund':
				msg = 'Estorno';
				break;
			case 'refund_reversal':
				msg = 'Devolução de estorno';
				break;
			case 'chargeback':
				msg = `Chargeback - Parcela ${installment}`;
				break;
			case 'chargeback_refund':
				msg = 'Estorno de chargeback';
				break;
			case 'anticipation':
				msg = 'Antecipação';
				break;
			case 'transfer':
				msg = `Saque - Transferência ${transactionId}`;
				break;
			case 'fee_collection':
				msg = description;
				break;
			default:
				msg = 'Sem descrição';
		}

		return msg;
	}

	const saidas = ({ discountsValue, taxValue }, string) => {
		let saidaTotal = discountsValue + taxValue;
		saidaTotal = saidaTotal < 0 ? (saidaTotal * -1) / 100 : saidaTotal / 100;
		saidaTotal = formatarParaReais(saidaTotal, true);

		if (string) return saidaTotal;

		return (
			<s.Row>
				<Icons.Minus />
				<p>{saidaTotal}</p>
			</s.Row>
		);
	};

	const entradas = ({ grossValue }, string) => {
		const valor = formatarParaReais(grossValue / 100, true);

		if (string) return valor;

		return (
			<s.Row>
				<Icons.Plus />
				<p>{valor}</p>
			</s.Row>
		);
	};

	const total = ({ netValue }, string) => {
		let valor = netValue < 0 ? (netValue / 100) * -1 : netValue / 100;
		valor = formatarParaReais(valor, true);
		const sinal = netValue < 0 ? '-' : '+';

		if (string) return valor;

		return (
			<s.Row sinal={sinal}>
				{sinal === '-' ? <Icons.Minus /> : <Icons.Plus />}
				<p className="total">{valor}</p>
			</s.Row>
		);
	};

	const data = ({ operationDate = null, originalDate = null }, string, id) => {
		const dataOperacao = new Date(operationDate);
		const dataOriginal = new Date(originalDate);

		if (string) {
			if (originalDate) {
				return `${format(dataOriginal, 'dd/MM/yyyy')} | ${format(
					dataOperacao,
					'dd/MM/yyyy'
				)}`;
			}
			return `${format(dataOperacao, 'dd/MM/yyyy')}`;
		}

		return (
			<s.Data>
				{operationDate && (
					<p className="data bold">{format(dataOperacao, 'dd/MM/yyyy')}</p>
				)}
				{originalDate && (
					<FloaterReact
						id={id}
						hover
						placement="right"
						content="Antecipação efetuada"
					>
						<Icons.SetaCurva />
					</FloaterReact>
				)}
				{originalDate && (
					<p className="data">{format(dataOriginal, 'dd/MM/yyyy')}</p>
				)}
			</s.Data>
		);
	};

	const dadosCSV = () => {
		if (colunaMercado)
			colunas.push({
				id: 'mercado',
				displayName: 'Mercado',
			});

		const extrato = [];
		let obj = {};

		dados.forEach((linha) => {
			obj = {
				data: data(linha, true),
				idOperacao: linha.operationId,
				idTransacao: !['transfer', 'fee_collection'].includes(linha.type)
					? linha.transactionId
					: '--',
				descricao: msgDescricao(linha),
				entradas: entradas(linha, true),
				saidas: saidas(linha, true),
				total: total(linha, true),
			};

			if (colunaMercado) {
				obj = { ...obj, mercado: linha.tipoMercado };
			}

			extrato.push(obj);
		});

		return extrato;
	};

	function LoadingTable() {
		const columns = [1, 2, 3, 4, 5, 6, 7];
		if (totalColunas === 8) columns.push(8);

		return (
			<s.LoadingContainer columns={totalColunas}>
				{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() =>
					columns.map((item) => <LoadingRectangle key={item} />)
				)}
			</s.LoadingContainer>
		);
	}

	function exportarComo() {
		let columns = [];
		const content = [];
		let obj = {};

		columns = colunas.map((col) => {
			return { label: col.displayName, value: col.id };
		});

		if (colunaMercado) {
			columns.push({
				label: 'Mercado',
				value: 'mercado',
			});
		}

		dados.forEach((linha) => {
			obj = {
				data: data(linha, true),
				idOperacao: linha.operationId,
				idTransacao: !['transfer', 'fee_collection'].includes(linha.type)
					? linha.transactionId
					: '--',
				descricao: msgDescricao(linha),
				entradas: entradas(linha, true),
				saidas: saidas(linha, true),
				total: total(linha, true),
			};

			if (colunaMercado) obj = { ...obj, mercado: linha.tipoMercado };

			content.push(obj);
		});

		const extrato = [{ sheet: 'Extrato', columns, content }];

		xlsx(extrato, settingsExportExcel);
	}

	return (
		<s.WrapperTable>
			<CsvDownloader
				id="baixar-extrato-csv"
				filename="Extrato"
				extension=".csv"
				separator=","
				wrapColumnChar=""
				columns={colunas}
				datas={dadosCSV}
				style={{ display: 'none' }}
			/>
			<button
				type="button"
				id="baixar-extrato-xlsx"
				style={{ display: 'none' }}
				onClick={() => exportarComo()}
			>
				a
			</button>

			<s.Table>
				<s.Thead>
					<s.Tr columns={totalColunas} top>
						<th>Data</th>
						<th>ID da Operação</th>
						<th>ID da Transação</th>
						<th>Descrição</th>
						<th>Entradas</th>
						<th>Saídas</th>
						<th>Total</th>
						{colunaMercado && <th>Mercado</th>}
					</s.Tr>
				</s.Thead>
				<s.Tbody>
					{loadingExtrato ? (
						<LoadingTable />
					) : dados?.length > 0 ? (
						dados.map((linha, i) => (
							<s.Tr
								key={linha.operationId}
								columns={totalColunas}
								bottom={i === dados.length - 1}
							>
								<s.Td>{data(linha, false, i)}</s.Td>
								<s.Td>{linha.operationId}</s.Td>
								<s.Td
									blue
									clicavel={
										!['transfer', 'fee_collection'].includes(linha.type)
									}
									onClick={() =>
										history.push(`/transacoes/${linha.transactionId}`)
									}
								>
									{!['transfer', 'fee_collection'].includes(linha.type)
										? linha.transactionId
										: '--'}
								</s.Td>
								<s.Td>{msgDescricao(linha)}</s.Td>
								<s.Td>{entradas(linha)}</s.Td>
								<s.Td>{saidas(linha)}</s.Td>
								<s.Td>{total(linha)}</s.Td>
								{colunaMercado && <s.Td>{linha.tipoMercado}</s.Td>}
							</s.Tr>
						))
					) : (
						<s.Empty>
							<span>Não foram encontrados lançamentos neste período</span>
						</s.Empty>
					)}
				</s.Tbody>
			</s.Table>
		</s.WrapperTable>
	);
};

export default DataTable;
