import React from 'react';
import { withRouter } from 'react-router';
import { Dados, ContaBancaria, Configuracoes, Confirmacao, Avisos } from '../';
import { useRecipients } from '../../../../hooks';

import * as s from './styled-form';

const registerForm = () => {
	const { step } = useRecipients();

	return (
		<s.Container>
			{step === 1 && <Dados />}
			{step === 2 && <ContaBancaria />}
			{step === 3 && <Configuracoes />}
			{step === 4 && <Confirmacao />}
			{step === 5 && <Avisos />}
		</s.Container>
	);
};

export default withRouter(registerForm);
