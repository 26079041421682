import styled from 'styled-components';
import { colors } from '../../../../assets';

export const WrapperTable = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Table = styled.table`
	font-size: 1rem;
`;

export const Thead = styled.thead`
	color: ${colors.primaryBlue};
	font-weight: 500;
	height: 3.75rem;

	tr {
		color: ${colors.primaryBlue};
		background-color: ${colors.black};

		th {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			border-left: 1px solid ${colors.borderTable};
			height: 100%;
			padding: 10px;
			cursor: pointer;
		}

		th:last-child {
			border-right: 1px solid ${colors.borderTable};
		}
	}
`;

export const Tr = styled.tr`
	display: grid;
	grid-template-columns: 2fr 1fr 2fr 1fr;
	min-height: 3.75rem;
	justify-content: space-between;
	color: ${colors.white};
	background: ${({ index }) =>
		index % 2 === 0 ? colors.gridBlack : colors.rowTable};

	${({ top, bottom }) =>
		top &&
		`border-top: 1px solid ${colors.borderTable};` &&
		bottom &&
		`border-bottom: 1px solid ${colors.borderTable};`}

	/* borda direita da tabela */

	td {
		padding-right: 0;
	}

	td:last-child {
		border-right: 1px solid ${colors.borderTable};
		padding-right: 0;
	}
`;

export const Td = styled.td`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: left;
	min-width: ${({ minWidth }) => minWidth || 'none'};
	border-left: 1px solid ${colors.borderTable};
	height: 100%;
	color: ${({ value }) => {
		if (value === 'active') return colors.green;
		if (value === 'inactive') return colors.red;
	}};
	font-weight: 400;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const Tbody = styled.tbody`
	tr:first-child {
		margin-top: -2px;
	}

	height: ${({ loading }) => (loading ? '500px' : 'auto')};

	.loader {
		display: flex;
		height: 500px;
		justify-content: center;
		align-items: center;
	}
	tr:last-child {
		border-bottom: 1px solid ${colors.borderTable};
	}
`;

export const Footer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 2rem;
	margin-bottom: 2.5rem;

	div {
		width: 22.5rem;
		margin: 0;
		justify-content: center;
	}
`;
