import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Alert from '@mui/material/Alert';
import { colors } from '../../assets/colors';
import { EstornoContent, Estornosteps } from '../../components';
import { getTransactionDetails } from '../../services';
import { useAuth } from '../../contexts';

import * as s from './styled-estorno';

const Estorno = () => {
	const [step, setStep] = useState(1);
	const [loading, setLoading] = useState(false);
	const [dados, setDados] = useState({});
	const [alert, setAlert] = useState();
	const [alertMessage, setAlertMessage] = useState(
		'Erro inesperado, tente novamente mais tarde.'
	);
	const { transactionId } = useParams();
	const { recipientId } = useAuth().currentUser;

	useEffect(() => {
		setLoading(true);
		getTransactionDetails(transactionId, recipientId)
			.then((resp) => {
				setDados(resp);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<s.Container>
			<Estornosteps step={step} />
			{alert === 'error' && (
				<Alert
					severity="error"
					variant="outlined"
					sx={{
						color: `${colors.primaryRed}`,
						borderColor: `${colors.primaryRed}`,
						fontFamily: 'IBM Plex Sans, sans-serif',
						fontSize: '1em',
						fontWeight: '600',
						textAlign: 'center',
						alignItems: 'center',
						marginBottom: '1rem',
					}}
				>
					{alertMessage}
				</Alert>
			)}
			{alert === 'success' && (
				<Alert
					severity="success"
					variant="outlined"
					sx={{
						color: `${colors.green}`,
						borderColor: `${colors.green}`,
						fontFamily: 'IBM Plex Sans, sans-serif',
						fontSize: '1em',
						fontWeight: '600',
						textAlign: 'center',
						alignItems: 'center',
						marginBottom: '1rem',
					}}
				>
					Estorno realizado com sucesso.
				</Alert>
			)}
			<EstornoContent
				alert={alert}
				setAlert={setAlert}
				alertMessage={alertMessage}
				setAlertMessage={setAlertMessage}
				step={step}
				setStep={setStep}
				dados={dados}
				loading={loading}
				setLoading={setLoading}
			/>
		</s.Container>
	);
};

export default withRouter(Estorno);
