import React from 'react';
import { colors } from '../../../assets';

import * as s from './styled-custom-flex-div';

const CustomFlexDiv = (props) => {
	const {
		children,
		id,
		div,
		title,
		isInvalid,
		titleTextAlign,
		borderRadius,
		borderColor,
		maxHeight,
		width,
		minWidth,
		alignContent,
		justifyContent,
		errorType,
		tittleError,
	} = props;
	return (
		<s.Container
			borderColor={borderColor}
			isInvalid={isInvalid}
			id={id}
			div={div}
			borderRadius={borderRadius}
			maxHeight={maxHeight}
			width={width}
			minWidth={minWidth}
			alignContent={alignContent}
			justifyContent={justifyContent}
			errorType={errorType}
			tittleError={tittleError}
		>
			{title && (
				<s.TitleDiv>
					<s.Title isInvalid={isInvalid} tittleError={tittleError} titleTextAlign={titleTextAlign}>
						{title}
					</s.Title>
				</s.TitleDiv>
			)}
			{children}
			{(isInvalid && errorType === 'email' && (
				<span style={{ color: `${colors.red}`, paddingTop: '10px' }}>
					{' '}
					Email Inválido*
				</span>
			)) ||
				(isInvalid && errorType === 'nome' && (
					<span style={{ color: `${colors.red}`, paddingTop: '10px' }}>
						{' '}
						Nome Inválido*
					</span>
				)) ||
				(isInvalid && errorType === 'phone' && (
					<span style={{ color: `${colors.red}`, paddingTop: '10px' }}>
						{' '}
						Telefone Invalido*
					</span>
				))}
		</s.Container>
	);
};

export default CustomFlexDiv;
