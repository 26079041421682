import React from 'react';
import { colors } from '../../../assets';

const InvoiceIcon = ({ title, fill, style }) => (
	<svg
		className="invoice-icon"
		style={style}
		width="21"
		height="20"
		viewBox="0 0 21 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			d="M11.9282 7.37129C11.9282 6.95708 11.5924 6.62129 11.1782 6.62129H5.17822C4.76401 6.62129 4.42822 6.95708 4.42822 7.37129C4.42822 7.78551 4.76401 8.12129 5.17822 8.12129H11.1782C11.5924 8.12129 11.9282 7.78551 11.9282 7.37129Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			d="M10.9282 10.3713C10.9282 9.95708 10.5924 9.62129 10.1782 9.62129H5.17822C4.76401 9.62129 4.42822 9.95708 4.42822 10.3713C4.42822 10.7855 4.76401 11.1213 5.17822 11.1213H10.1782C10.5924 11.1213 10.9282 10.7855 10.9282 10.3713Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			d="M11.1782 12.6213C11.5924 12.6213 11
	</svg>.9282 12.9571 11.9282 13.3713C11.9282 13.7855 11.5924 14.1213 11.1782 14.1213H5.17822C4.76401 14.1213 4.42822 13.7855 4.42822 13.3713C4.42822 12.9571 4.76401 12.6213 5.17822 12.6213H11.1782Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.42822 19.3713H17.4282C18.947 19.3713 20.1782 18.1401 20.1782 16.6213V11.1213C20.1782 10.7071 19.8424 10.3713 19.4282 10.3713H16.1782V2.56449C16.1782 1.14109 14.5693 0.31312 13.4111 1.14046L13.236 1.26549C12.4555 1.82298 11.3983 1.82111 10.6142 1.261C9.31029 0.329667 7.54616 0.329667 6.24229 1.261C5.45814 1.82111 4.40093 1.82298 3.62044 1.26549L3.44539 1.14046C2.28712 0.31312 0.678223 1.14109 0.678223 2.56449V15.6213C0.678223 17.6924 2.35715 19.3713 4.42822 19.3713ZM7.11415 2.4816C7.89647 1.9228 8.95997 1.9228 9.74229 2.4816C11.0443 3.41162 12.8022 3.41873 14.1079 2.48609L14.2829 2.36106C14.4484 2.24286 14.6782 2.36115 14.6782 2.56449V16.6213C14.6782 17.0714 14.7864 17.4963 14.9781 17.8713H4.42822C3.18558 17.8713 2.17822 16.8639 2.17822 15.6213V2.56449C2.17822 2.36115 2.40807 2.24286 2.57353 2.36106L2.74858 2.48609C4.05428 3.41873 5.81212 3.41162 7.11415 2.4816ZM16.1782 16.6213V11.8713H18.6782V16.6213C18.6782 17.3117 18.1186 17.8713 17.4282 17.8713C16.7379 17.8713 16.1782 17.3117 16.1782 16.6213Z"
			fill={fill || colors.primaryBlue}
		/>
	</svg>
);

export default InvoiceIcon;
