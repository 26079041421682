import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Toggle } from 'react-toggle-component';
import {
	ButtonCustom,
	Pagination,
	SearchField,
	SelectReact,
	LoadingRectangle,
} from '../../components';
import { getUsers, getSearchUsers, putStatusUsers } from '../../services';
import { colors, Icons } from '../../assets';

import * as s from './styled-accesses';

const Accesses = () => {
	const history = useHistory();
	const [users, setUsers] = useState([]);
	const [pagination, setPagination] = useState({});
	const [searchValue, setSearchValue] = useState('');
	const [order, setOrder] = useState({ value: 'asc', label: 'A-Z' });
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState({});
	const reversedFiltered = useRef('');

	const [activeStatus, setActiveStatus] = useState({
		value: 'all',
		label: 'Todos',
	});

	const optionsStatus = [
		{ value: 'all', label: 'Todos' },
		{ value: 'active', label: 'Ativo' },
		{ value: 'inactive', label: 'Inativo' },
	];

	const optionsOrder = [
		{ value: 'asc', label: 'Nome A-Z' },
		{ value: 'desc', label: 'Nome Z-A' },
	];

	function buscarUsuarios(pagina = 1) {
		setLoading(true);
		getUsers(pagina, order.value, activeStatus.value)
			.then((resp) => {
				setUsers(resp.data);
				setPagination({ totalPages: resp.pages, page: pagina });
			})
			.catch(() => toast.error('Erro ao buscar usuário'))
			.finally(() => setLoading(false));
	}

	function searchUsers(value, pagina = 1) {
		setLoading(true);
		getSearchUsers(value, pagina)
			.then((resp) => {
				setUsers(resp.data);
				setPagination({ totalPages: resp.pages, page: pagina });
			})
			.catch(() => toast.error('Erro ao filtrar contratantes'))
			.finally(() => setLoading(false));
	}

	const filterUsers = () => {
		const busca = searchValue;

		setUsers(
			users.filter(
				(user) => user.name.toLowerCase().indexOf(busca.toLowerCase()) !== -1
			)
		);
		reversedFiltered.current = 'filtrado';
	};

	const disableCleanButton = () => {
		if (reversedFiltered.current === 'filtrado' || searchValue !== '') {
			return false;
		}
		return true;
	};

	function changeStatus(userId, status) {
		putStatusUsers(userId, status)
			.then(() => toast.success('Status alterado com sucesso!'))
			.catch(() => toast.error('Erro ao alterar o status!'));
	}

	function typeAdjusted() {
		const type = order.roleLabel;
		if (type === 'contractor') {
			return 'Contratante';
		}
		return 'Administrador';
	}

	const LoadingCell = () => {
		const celulas = [1, 2, 3, 4, 5].map((celula, index) => (
			<tr key={index} style={{ background: 'none' }}>
				{[1, 2, 3, 4, 5, 6].map((number, i) => (
					<td key={i}>
						<LoadingRectangle style={{ height: 40, width: '100%' }} />
					</td>
				))}
			</tr>
		));

		return celulas;
	};

	function handleKeyPress() {
		filterUsers();
	}

	useEffect(() => {
		buscarUsuarios(1);
	}, [order, activeStatus]);

	return (
		<s.Container>
			<s.ContainerHeader>
				<SearchField
					id="pesquisa-acessos"
					onChange={(e) => {
						setSearchValue(e.target.value);
					}}
					value={searchValue}
					onClick={() => searchUsers(searchValue.trim())}
					onKeyPress={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleKeyPress();
						}
					}}
					placeholder="Busque pelo nome do usuário"
					width="100%"
					border={`1px solid ${colors.gray}`}
				/>
				<ButtonCustom
					outlined
					onClick={() => {
						setSearchValue('');
						searchUsers(searchValue, 1);
						reversedFiltered.current = '';
					}}
					width="12.5rem"
					height="3.3125rem"
					theme="green"
					className="searchButton"
					disabled={disableCleanButton()}
				>
					Limpar Busca
				</ButtonCustom>

				<ButtonCustom
					onClick={() => {
						filterUsers();
					}}
					width="12.5rem"
					height="3.3125rem"
					theme="green"
					className="searchButton"
					disabled={searchValue === ''}
				>
					Buscar
				</ButtonCustom>
			</s.ContainerHeader>
			{users.length > 0 ? (
				<s.TableMaster>
					<s.Table>
						<s.ButtonTableContainer>
							<s.FiltersHeader>
								<div className="selectHeaderDiv">
									<SelectReact
										id="select-status"
										isSearchable={false}
										options={optionsStatus}
										onChange={(opt) => setActiveStatus(opt)}
										value={activeStatus}
										outlined
									/>

									<SelectReact
										id="select-ordenar-por"
										options={optionsOrder}
										isSearchable={false}
										onChange={(opt) => setOrder(opt)}
										value={order}
										height={200}
										outlined
									/>
								</div>
								{users.length > 0 && pagination && pagination.totalPages > 1 && (
									<Pagination
										id="paginacao-acessos"
										totalPaginas={pagination.totalPages}
										paginaAtual={pagination.page - 1}
										onClick={({ selected }) => {
											if (searchValue) searchUsers(searchValue, selected + 1);
											else buscarUsuarios(selected + 1);
										}}
										disabled={loading}
									/>
								)}

								<ButtonCustom
									id="adicionar-usuario"
									onClick={() => history.push('/acessos/novo')}
									startIcon={<Icons.AddPerson fill={colors.primaryBlue} />}
									outlined
								>
									Adicionar usuário
								</ButtonCustom>
							</s.FiltersHeader>
						</s.ButtonTableContainer>

						<s.BodyContent>
							<s.Head>
								<tr>
									<td>Nome de usuário</td>
									<td>Empresa</td>
									<td>E-mail</td>
									<td>Tipo</td>
									<td className="center">Ativação</td>
									<td className="center">Opções</td>
								</tr>
							</s.Head>

							<s.TBody>
								{loading ? (
									<LoadingCell />
								) : (
									users &&
									users.map((item, i) => (
										<s.Tr
											key={item.userId}
											bottom={i === users.length - 1}
											index={i}
										>
											<td>{item.name}</td>
											<td>{item.companyName}</td>
											<td>{item.email}</td>
											<td>{typeAdjusted()}</td>
											<td className="center">
												<Toggle
													id={`ativar-usuario-${item.userId}`}
													checked={checked.index || item.active}
													onChange={() => {
														changeStatus(
															item.userId,
															item.active ? 'inactive' : 'active'
														);
														users[i].active = !item.active;
														setChecked({ ...checked, [i]: !item.active });
													}}
													name={item.userId}
													borderWidth="0px"
													width="4rem"
													height="1.75rem"
													knobWidth="1.25rem"
													knobHeight="1.25rem"
													knobGap=".25rem"
													leftKnobColor={colors.darkGray}
													rightKnobColor={colors.primaryBlue}
													leftBackgroundColor={colors.lightGray}
													rightBackgroundColor={colors.darkTurquoise}
												/>
											</td>
											<td className="center">
												<ButtonCustom
													disableParsedHover
													backgroundColor="transparent"
													id={`detalhes-acesso-${item.id}`}
													onClick={() => {
														history.push(`acessos/${item.userId}`);
													}}
													startIcon={<Icons.EditPencil />}
												/>
											</td>
										</s.Tr>
									))
								)}
							</s.TBody>
						</s.BodyContent>
					</s.Table>
				</s.TableMaster>
			) : (
				<s.ErrorContent>
					<Icons.AttentionOutlined />
					<h2 className="error-title">Sem resultados</h2>
					<p className="error-text">
						Não encontramos resultados para sua busca. <br /> Reveja o que foi
						digitado ou faça uma nova pesquisa.
					</p>
					<div>
						<ButtonCustom
							id="adicionar-usuario"
							onClick={() => history.push('/acessos/novo')}
							startIcon={<Icons.AddPerson />}
						>
							Adicionar usuário
						</ButtonCustom>
					</div>
				</s.ErrorContent>
			)}
		</s.Container>
	);
};

export default Accesses;
