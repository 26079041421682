import React from 'react';
import { colors } from '../colors';

const PlusIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="plus-icon"
		width="30"
		height="30"
		viewBox="0 0 30 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || ''}</title>
		<path
			d="M17.8125 2.8125V12.1875H27.1875C27.9334 12.1875 28.6488 12.4838 29.1763 13.0112C29.7037 13.5387 30 14.2541 30 15C30 15.7459 29.7037 16.4612 29.1763 16.9886C28.6488 17.5161 27.9334 17.8125 27.1875 17.8125H17.8125V27.1875C17.8125 27.9334 17.5162 28.6487 16.9888 29.1761C16.4613 29.7036 15.7459 30 15 30C14.2541 30 13.5387 29.7036 13.0112 29.1761C12.4838 28.6487 12.1875 27.9334 12.1875 27.1875V17.8125H2.8125C2.06658 17.8125 1.35119 17.5161 0.823746 16.9886C0.296299 16.4612 0 15.7459 0 15C0 14.2541 0.296299 13.5387 0.823746 13.0112C1.35119 12.4838 2.06658 12.1875 2.8125 12.1875H12.1875V2.8125C12.1875 2.06658 12.4838 1.35119 13.0112 0.823746C13.5387 0.2963 14.2541 0 15 0C15.7459 0 16.4613 0.2963 16.9888 0.823746C17.5162 1.35119 17.8125 2.06658 17.8125 2.8125Z"
			fill={fill || colors.green}
		/>
	</svg>
);
export default PlusIcon;
