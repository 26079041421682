import styled from 'styled-components';
import { colors, images } from '../../assets';

export const NoContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: flex-start;
	position: absolute;

	width: 100%;
	height: calc(100vh);
	right: -60px;
	background-image: url(${images.nenhumRegistro});
	background-position: top right;
	background-repeat: no-repeat;
	background-size: contain;

	.content {
		max-width: 35.75rem;
	}
`;

export const NoContentContainerUnauthenticated = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding-top: 12rem;

	width: 100%;
	height: 100vh;
	background-image: url(${images.frame});
	background-position: bottom right;
	background-repeat: no-repeat;
	background-size: contain;

	.logo {
		width: 9.3125rem;
	}
`;

export const ContentContainerUnauthenticatedLogo = styled.div`
	position: absolute;
	top: 3.125rem;
	left: 2.8125rem;
	.logotipo {
		width: 9.3125rem;
		height: 3rem;
	}
`;

export const NoContentContainerContentUnauthenticated = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 23.625rem;
`;

export const NoContentTitleUnauthenticated = styled.h2`
	text-align: left;
	color: ${colors.primaryBlue};
	padding-bottom: 1rem;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 43.75rem;
	font-size: 3rem;
	line-height: 4.625rem;
`;

export const NoContentTextUnauthenticated = styled.p`
	color: ${colors.offWhite};
	text-align: left;
	width: 36rem;
	margin: 1.5rem 0 2.5rem;
	font-style: normal;
	font-weight: 25rem;
	font-size: 1.5rem;
	line-height: 2.25rem;
	a {
		color: ${colors.primaryBlue};
	}
`;

export const NoContentTextErrorUnauthenticated = styled.p`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	position: flex;
	width: 186px;
	height: 37px;
	left: 0px;
	top: 0px;
	color: ${colors.lightGray};
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid #585d5d;
	box-sizing: border-box;
	border-radius: 40px;
`;

export const NoContentButtons = styled.div`
	display: flex;

	align-items: left;
	.loginButton {
		width: 32.3125rem;
	}
`;

export const NoContentTextError = styled.p`
	margin-right: 23.5625rem;
	padding: 8px 16px;
	border-radius: 2.5rem;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.3125rem;
	text-align: left;
	color: #bdc2c2;
	background: rgba(255, 255, 255, 0.1);
	letter-spacing: 0.03em;
	border: 0.0625rem solid #585d5d;
	width: 28.3%;
`;

export const NoContentMessage = styled.div`
	position: absolute;
	top: 10%;
	left: 10%;
	display: flex;
	flex-direction: column;
	align-items: right;
	justify-content: left;
	text-align: left;
`;

export const NoContentTitle = styled.h2`
	font-weight: bold;
	font-size: 4rem;
	line-height: 5.1875rem;
	text-align: left;
	color: ${colors.primaryBlue};
	padding-bottom: 2rem;
`;

export const NoContentText = styled.p`
	font-size: 1.5rem;
	line-height: 1.9375rem;
	color: ${colors.offWhite};
	text-align: left;
	width: 100%;
	margin: 0;

	.errorcode {
		color: ${colors.primaryBlue};
		margin-top: 2.4375rem;
	}
	.graytext {
		color: ${colors.lightGray};
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 12.5rem;
		font-size: 1.125rem;
		line-height: 1.4375rem;
		margin-top: 0.5rem;
		margin-bottom: 0.625rem;
	}
	ul {
		list-style: none;
	}
	a {
		color: ${colors.primaryBlue};
	}
`;
