import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	width: 100%;
	color: ${colors.white};
	background-color: ${colors.darkGray};
	padding: 1rem 1.5rem 1rem 1.5rem;
	p {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 26px;
		color: ${colors.lightGray};
	}
`;

export const ContainerItems = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	width: 100%;

	color: ${colors.white};
	background-color: ${colors.darkGray};
`;

export const Title = styled.h1`
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	text-align: left;
	padding-bottom: 2rem;
	color: ${colors.white};
`;

export const Infos = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${colors.white};
	gap: 1rem;
	h3 {
		color: ${colors.white};
		font-weight: 600;
		margin: 0;
		font-size: 1.375em;
	}

	div {
		display: flex;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colors.lightBlack};
	padding: 10px 0 15px 15px;
	width: 100%;
	border-radius: 8px;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: row;
	gap: 11rem;
`;

export const SubContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 20%;
	font-size: 1em;
	padding-bottom: 1.25em;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	text-align: left;
	color: ${colors.white};
	span {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 26px;
		color: ${colors.lightGray};
	}
`;

export const CardPaymentItem = styled.div`
	display: flex;
	background-color: ${colors.darkGray};
`;
