import React from 'react';
import * as S from './styled-button-filters-transactions';

const ButtonFiltersTransactions = ({
	children,
	width,
	onClick,
	isSelected,
	innerRef,
}) => {
	return (
		<S.Button
			ref={innerRef}
			width={width}
			isSelected={isSelected}
			onClick={(event) => {
				event.preventDefault();
				onClick();
			}}
		>
			{children}
		</S.Button>
	);
};

export default ButtonFiltersTransactions;
