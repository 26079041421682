import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	width: 100%;
	align-content: center;
	align-items: center;
	padding: 2rem 0;
	justify-content: space-between;
`;

export const TableTitle = styled.span`
	font-size: 1.25rem;
	font-style: normal;
	font-weight: normal;
	color: ${colors.lightGray};
`;

export const Toolbar = styled.div`
	display: flex;
	align-items: center;
	gap: 3.5rem;
	.button-custom {
		font-size: 1rem;
		border-radius: '0.9375rem';
	}
`;
