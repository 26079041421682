import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { verifyLogin } from './user.service';

// envs
const {
	REACT_APP_BASE_URL,
	REACT_APP_API_KEY,
	REACT_APP_AUTH_DOMAIN,
	REACT_APP_PROJECT_ID,
	REACT_APP_STORAGE_BUCKET,
	REACT_APP_MESSAGING_SENDER_ID,
	REACT_APP_APP_ID,
	REACT_APP_MEASUREMENT_ID,
	REACT_APP_ENV,
} = process.env;

const firebaseConfig = {
	apiKey: REACT_APP_API_KEY,
	authDomain: REACT_APP_AUTH_DOMAIN,
	projectId: REACT_APP_PROJECT_ID,
	storageBucket: REACT_APP_STORAGE_BUCKET,
	messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
	appId: REACT_APP_APP_ID,
	measurementId: REACT_APP_MEASUREMENT_ID,
	env: REACT_APP_ENV,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

// axios
const axiosApp = axios.create({
	timeout: 530000,
	baseURL: REACT_APP_BASE_URL,
});

const requestHandler = async (request) => {
	request.headers['Content-Type'] = 'application/json;charset=UTF-8';
	request.headers['Access-Control-Allow-Methods'] = '*';
	request.headers['Access-Control-Allow-Origin'] = `${REACT_APP_AUTH_DOMAIN}`;
	request.headers['Access-Control-Expose-Headers'] = 'Authorization,Location';
	request.headers['Access-Control-Allow-Origin'] = '*';
	const token = await verifyLogin();
	if (token) request.headers.Authorization = `Bearer ${token}`;
	return request;
};

const errorHandler = (error) => error;

[axiosApp].forEach((item) => {
	item.interceptors.request.use(requestHandler, errorHandler);
});

export { app, analytics, auth, axiosApp };

export * from './anticipations.service';
export * from './bank.service';
export * from './contractor.service';
export * from './dashboard.service';
export * from './estorno.service';
export * from './payment-link.service';
export * from './recipient.service';
export * from './user.service';
