import React from 'react';
import { CheckboxCustom } from '../../../../atoms';
import { capitalizeFirstLetter } from '../../../../../utils';

import * as S from './styled-dropdown-filter-paymentoptions';

const PaymentsOptions = ['Pix', 'Boleto', 'Cartão de crédito'];

const paymentData = (value) => {
	const data = {
		Pix: 'pix',
		Boleto: 'ticket',
		'Cartão de crédito': 'credit_card',
	};

	return data[value];
};

function RenderCheckboxCustom({ state, setState, id, text }) {
	return (
		<CheckboxCustom
			checked={state.includes(id)}
			state={state}
			setState={setState}
			fontSize="1.25rem"
			text={capitalizeFirstLetter(text)}
			checkboxAlign="flex-start"
			onChange={() => {
				if (state.includes(id)) {
					setState(state.filter((item) => item !== id));
				} else {
					setState([...state, id]);
				}
			}}
			TransactionFilters
		/>
	);
}

function DropdownFilterPaymentsOptions({
	paymentMethod,
	setPaymentMethod,
	innerRef,
}) {
	return (
		<S.Container ref={innerRef}>
			<S.Column>
				<S.ColumnTitle>Formas de Pagamento</S.ColumnTitle>
				{PaymentsOptions.map((item) => (
					<RenderCheckboxCustom
						state={paymentMethod}
						setState={setPaymentMethod}
						key={item}
						id={paymentData(item)}
						text={item}
						transactionsFilters
					/>
				))}
			</S.Column>
		</S.Container>
	);
}

export default DropdownFilterPaymentsOptions;
