import React from 'react';
import { format } from 'date-fns';
import CsvDownloader from 'react-csv-downloader';
import { useHistory } from 'react-router-dom';
import xlsx from 'json-as-xlsx';

import { Icons } from '../../../../../assets';
import { useAuth, useExtrato } from '../../../../../contexts';
import { LoadingRectangle, FloaterReact } from '../../../../../components';
import { formatarParaReais, settingsExportExcel } from '../../../../../utils';

import * as s from './styled-tabela';

const DataTable = ({ dados, loadingExtrato, columnEntry, columnOut }) => {
	const history = useHistory();
	const { feeMdr, loadingFeeMdr } = useExtrato();
	const { currentUser } = useAuth();
	const beiraRioContractor =
		currentUser?.contractorId === 'KV9XYmke83Z8Pcmuvbec';
	const totalColunas =
		columnEntry && columnOut
			? 8
			: columnEntry && !columnOut
			? 7
			: !columnEntry && columnOut
			? 7
			: !columnEntry && !columnOut && 6;

	const colunas =
		columnEntry && columnOut
			? [
					{
						id: 'data',
						displayName: 'Data',
					},
					{
						id: 'idOperacao',
						displayName: 'ID da Operação',
					},
					{
						id: 'idTransacao',
						displayName: 'ID da Transação',
					},
					{
						id: 'descricao',
						displayName: 'Descrição',
					},
					{
						id: 'entradas',
						displayName: 'Entradas',
					},
					{
						id: 'saidas',
						displayName: 'Saídas',
					},
					{
						id: 'total',
						displayName: 'Total',
					},
					{
						id: 'mercado',
						displayName: 'Mercado',
					},
			  ]
			: columnEntry && !columnOut
			? [
					{
						id: 'data',
						displayName: 'Data',
					},
					{
						id: 'idOperacao',
						displayName: 'ID da Operação',
					},
					{
						id: 'idTransacao',
						displayName: 'ID da Transação',
					},
					{
						id: 'descricao',
						displayName: 'Descrição',
					},
					{
						id: 'entradas',
						displayName: 'Entradas',
					},
					{
						id: 'total',
						displayName: 'Total',
					},
					{
						id: 'mercado',
						displayName: 'Mercado',
					},
			  ]
			: !columnEntry && columnOut
			? [
					{
						id: 'data',
						displayName: 'Data',
					},
					{
						id: 'idOperacao',
						displayName: 'ID da Operação',
					},
					{
						id: 'idTransacao',
						displayName: 'ID da Transação',
					},
					{
						id: 'descricao',
						displayName: 'Descrição',
					},
					{
						id: 'saidas',
						displayName: 'Saídas',
					},
					{
						id: 'total',
						displayName: 'Total',
					},
					{
						id: 'mercado',
						displayName: 'Mercado',
					},
			  ]
			: !columnEntry &&
			  !columnOut && [
					{
						id: 'data',
						displayName: 'Data',
					},
					{
						id: 'idOperacao',
						displayName: 'ID da Operação',
					},
					{
						id: 'idTransacao',
						displayName: 'ID da Transação',
					},
					{
						id: 'descricao',
						displayName: 'Descrição',
					},
					{
						id: 'total',
						displayName: 'Total',
					},
					{
						id: 'mercado',
						displayName: 'Mercado',
					},
			  ];

	function msgDescricao({ type, installment, transactionId, description }) {
		let msg;

		switch (type) {
			case 'credit':
				msg =
					installment !== null
						? `Cartão de crédito - Parcela ${installment}`
						: 'Pix';
				break;
			case 'refund':
				msg = 'Estorno';
				break;
			case 'refund_reversal':
				msg = 'Devolução de estorno';
				break;
			case 'chargeback':
				msg = `Chargeback - Parcela ${installment}`;
				break;
			case 'chargeback_refund':
				msg = 'Estorno de chargeback';
				break;
			case 'anticipation':
				msg = 'Antecipação';
				break;
			case 'transfer':
				msg = `Saque - Transferência ${transactionId}`;
				break;
			case 'fee_collection':
				msg = description;
				break;
			default:
				msg = 'Sem descrição';
		}

		return msg;
	}

	const loadingColumns = (content) => {
		if (loadingExtrato) return <LoadingRectangle style={{ height: '40px' }} />;
		return content;
	};

	const calculateOutValue = (discountsValue, taxValue, transactionId) => {
		const filtermdr = feeMdr?.filter((item) => {
			return item?.transaction_id === transactionId;
		});

		if (!beiraRioContractor) return Number(discountsValue + taxValue);
		return Number(discountsValue + filtermdr?.[0]?.fee);
	};

	const saidas = (
		{ discountsValue, taxValue, transactionId, type },
		string
	) => {
		let saidaTotal;
		if (type === 'transfer') return '--';
		if (loadingFeeMdr) return <LoadingRectangle style={{ height: '40px' }} />;

		saidaTotal = calculateOutValue(discountsValue, taxValue, transactionId);
		if (Number.isNaN(saidaTotal)) return '--';
		saidaTotal = saidaTotal < 0 ? (saidaTotal * -1) / 100 : saidaTotal / 100;
		saidaTotal = formatarParaReais(saidaTotal, true);
		if (string) return saidaTotal;

		return (
			<s.Row>
				{beiraRioContractor && <p>(MDR)</p>}
				<Icons.Minus />
				<p>{saidaTotal}</p>
			</s.Row>
		);
	};

	const entradas = ({ grossValue }, string) => {
		const valor = formatarParaReais(grossValue / 100, true);

		if (string) return valor;

		return (
			<s.Row>
				<Icons.Plus />
				<p>{valor}</p>
			</s.Row>
		);
	};

	const total = (
		{ grossValue, discountsValue, transactionId, taxValue, type, netValue },
		string
	) => {
		if (loadingFeeMdr) return <LoadingRectangle style={{ height: '40px' }} />;
		const saida = calculateOutValue(discountsValue, taxValue, transactionId);
		const totalValue = grossValue - saida;

		let valor = type === 'transfer' ? netValue : totalValue;
		const sinal = valor < 0 ? '-' : '+';
		valor = formatarParaReais(valor / 100, true).slice(1);

		if (string) return totalValue;

		return (
			<s.Row sinal={sinal}>
				{sinal === '-' ? <Icons.Minus /> : <Icons.Plus />}
				<p className="total">{valor}</p>
			</s.Row>
		);
	};

	const data = ({ operationDate = null, originalDate = null }, string, id) => {
		const dataOperacao = new Date(operationDate);
		const dataOriginal = new Date(originalDate);

		if (string) {
			if (originalDate) {
				return `${format(dataOriginal, 'dd/MM/yyyy')} | ${format(
					dataOperacao,
					'dd/MM/yyyy'
				)}`;
			}
			return `${format(dataOperacao, 'dd/MM/yyyy')}`;
		}

		return (
			<s.Data>
				{operationDate && (
					<p className="data bold">{format(dataOperacao, 'dd/MM/yyyy')}</p>
				)}
				{originalDate && (
					<FloaterReact
						id={id}
						hover
						placement="right"
						content="Antecipação efetuada"
					>
						<Icons.SetaCurva />
					</FloaterReact>
				)}
				{originalDate && (
					<p className="data">{format(dataOriginal, 'dd/MM/yyyy')}</p>
				)}
			</s.Data>
		);
	};

	const dadosCSV = () => {
		const extrato = [];
		let obj = {};

		dados.forEach((linha) => {
			if (columnEntry) {
				obj = {
					data: data(linha, true),
					idOperacao: linha.operationId,
					idTransacao: !['transfer', 'fee_collection'].includes(linha.type)
						? linha.transactionId
						: '--',
					descricao: msgDescricao(linha),
					entradas: entradas(linha, true),
					total: total(linha, true),
					mercado: linha.tipoMercado ? linha.tipoMercado : '--',
				};
			}
			if (columnOut) {
				obj = {
					data: data(linha, true),
					idOperacao: linha.operationId,
					idTransacao: !['transfer', 'fee_collection'].includes(linha.type)
						? linha.transactionId
						: '--',
					descricao: msgDescricao(linha),
					saidas: saidas(linha, true),
					total: total(linha, true),
					mercado: linha.tipoMercado ? linha.tipoMercado : '--',
				};
			}
			if (columnEntry && columnOut) {
				obj = {
					data: data(linha, true),
					idOperacao: linha.operationId,
					idTransacao: !['transfer', 'fee_collection'].includes(linha.type)
						? linha.transactionId
						: '--',
					descricao: msgDescricao(linha),
					entradas: entradas(linha, true),
					saidas: saidas(linha, true),
					total: total(linha, true),
					mercado: linha.tipoMercado ? linha.tipoMercado : '--',
				};
			}
			if (!columnEntry && !columnOut) {
				obj = {
					data: data(linha, true),
					idOperacao: linha.operationId,
					idTransacao: !['transfer', 'fee_collection'].includes(linha.type)
						? linha.transactionId
						: '--',
					descricao: msgDescricao(linha),
					total: total(linha, true),
					mercado: linha.tipoMercado ? linha.tipoMercado : '--',
				};
			}

			extrato.push(obj);
		});

		return extrato;
	};

	function exportarComo() {
		let columns = [];
		const content = [];
		let obj = {};

		columns = colunas.map((col) => {
			return { label: col.displayName, value: col.id };
		});

		dados.forEach((linha) => {
			if (columnEntry) {
				obj = {
					data: data(linha, true),
					idOperacao: linha.operationId,
					idTransacao: !['transfer', 'fee_collection'].includes(linha.type)
						? linha.transactionId
						: '--',
					descricao: msgDescricao(linha),
					entradas: entradas(linha, true),
					total: total(linha, true),
					mercado: linha.tipoMercado ? linha.tipoMercado : '--',
				};
			}
			if (columnOut) {
				obj = {
					data: data(linha, true),
					idOperacao: linha.operationId,
					idTransacao: !['transfer', 'fee_collection'].includes(linha.type)
						? linha.transactionId
						: '--',
					descricao: msgDescricao(linha),
					saidas: saidas(linha, true),
					total: total(linha, true),
					mercado: linha.tipoMercado ? linha.tipoMercado : '--',
				};
			}
			if (columnEntry && columnOut) {
				obj = {
					data: data(linha, true),
					idOperacao: linha.operationId,
					idTransacao: !['transfer', 'fee_collection'].includes(linha.type)
						? linha.transactionId
						: '--',
					descricao: msgDescricao(linha),
					entradas: entradas(linha, true),
					saidas: saidas(linha, true),
					total: total(linha, true),
					mercado: linha.tipoMercado ? linha.tipoMercado : '--',
				};
			}
			if (!columnEntry && !columnOut) {
				obj = {
					data: data(linha, true),
					idOperacao: linha.operationId,
					idTransacao: !['transfer', 'fee_collection'].includes(linha.type)
						? linha.transactionId
						: '--',
					descricao: msgDescricao(linha),
					total: total(linha, true),
					mercado: linha.tipoMercado ? linha.tipoMercado : '--',
				};
			}

			content.push(obj);
		});
		const extrato = [{ sheet: 'Extrato', columns, content }];

		xlsx(extrato, settingsExportExcel);
	}

	return (
		<s.WrapperTable>
			<CsvDownloader
				id="baixar-extrato-csv"
				filename="Extrato"
				extension=".csv"
				separator=","
				wrapColumnChar=""
				columns={colunas}
				datas={dadosCSV}
				style={{ display: 'none' }}
			/>
			<button
				type="button"
				id="baixar-extrato-xlsx"
				style={{ display: 'none' }}
				onClick={() => exportarComo()}
			>
				a
			</button>

			<s.Table>
				<s.Thead>
					<s.Tr
						columnEntry={columnEntry}
						columnOut={columnOut}
						columns={totalColunas}
						top
					>
						<th>Data</th>
						<th>ID da Operação</th>
						<th>ID da Transação</th>
						<th>Descrição</th>
						{columnEntry && <th>Entradas</th>}
						{columnOut && <th>Saídas</th>}
						<th>Total</th>
						<th>Mercado</th>
					</s.Tr>
				</s.Thead>
				<s.Tbody>
					{dados?.length > 0 ? (
						dados.map((linha, i) => (
							<s.Tr
								key={linha.operationId}
								columns={totalColunas}
								bottom={i === dados.length - 1}
								columnEntry={columnEntry}
								columnOut={columnOut}
							>
								<s.Td>{loadingColumns(data(linha, false, i))}</s.Td>
								<s.Td>{loadingColumns(linha.operationId)}</s.Td>
								<s.Td
									blue
									clicavel={
										!['transfer', 'fee_collection'].includes(linha.type)
									}
									onClick={() =>
										history.push(`/transacoes/${linha.transactionId}`)
									}
								>
									{loadingColumns(
										!['transfer', 'fee_collection'].includes(linha.type)
											? linha.transactionId
											: '--'
									)}
								</s.Td>
								<s.Td>{loadingColumns(msgDescricao(linha))}</s.Td>
								{columnEntry && <s.Td>{loadingColumns(entradas(linha))}</s.Td>}
								{columnOut && <s.Td>{loadingColumns(saidas(linha))}</s.Td>}
								<s.Td>{loadingColumns(total(linha))}</s.Td>
								<s.Td>
									{loadingColumns(linha.tipoMercado ? linha.tipoMercado : '--')}
								</s.Td>
							</s.Tr>
						))
					) : !loadingExtrato ? (
						<s.Empty>
							<span>Não foram encontrados lançamentos neste período</span>
						</s.Empty>
					) : (
						<s.Empty>
							<LoadingRectangle style={{ height: '40px' }} />
						</s.Empty>
					)}
				</s.Tbody>
			</s.Table>
		</s.WrapperTable>
	);
};

export default DataTable;
