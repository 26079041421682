import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: auto;
	gap: 7rem;
	padding: 32px 24px 32px 24px;
`;

export const Between = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: auto;
	justify-content: space-between;
	.Desativar {
		font-size: 1rem;
		gap: 0.55rem;
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: row;
	gap: 7rem;
	width: 100%;
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;


	p.title {
		color: ${colors.lightGray};
		opacity: 0, 8;
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 1.625rem;
	}

	.text {
		font-family: 'Jost';
		font-style: normal;
		font-weight: 600;
		font-size: 1.375rem;
		color: ${({ floaterIsOpen }) => floaterIsOpen ? colors.primaryBlue : colors.offWhite};
	}

	div#link-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.7rem;

		button {
			background: transparent;
			cursor: pointer;
			pointer-events: ${({ floaterIsOpen }) => floaterIsOpen ? "none" : "auto"};

			:hover {
				color: ${colors.primaryBlue};
				text-decoration: underline;
				text-underline-offset: 0.3rem;
			}
		}

		div#icons-container {
			display: flex;
			align-items: center;

			div#icon-1{
				opacity: ${({ floaterIsOpen }) => floaterIsOpen ? 1 : 0};
				transition: opacity 0.3s linear;
			}

			div#icon-2{
				opacity: ${({ floaterIsOpen }) => floaterIsOpen ? 0 : 1};
				position: absolute;
				transition: opacity 0.3s linear;
			}
		}
	}
`;
