import { React } from 'react';
import { format } from 'date-fns';
import { Pagination } from '../../../../components';
import { formatDoc, mascaraTelefone } from '../../../../utils';

import * as s from './styled-tableClient';

const DataTableClient = (props) => {
	const {
		clientes,
		clientesFiltrados,
		itemsPerPage,
		isLoading,
		page,
		setPage,
	} = props;

	function formatarData(date) {
		return date ? format(new Date(date), 'dd/MM/yyyy') : '--/--/----';
	}

	return (
		<>
			{clientes.length > 0 && (
				<s.WrapperTable>
					<s.Table>
						<s.Thead>
							<s.Tr top>
								<th>Nome do cliente</th>
								<th>CNPJ/Código</th>
								<th>Telefone</th>
								<th>E-mail</th>
								<th>Data de Criação</th>
							</s.Tr>
						</s.Thead>
						<s.Tbody loading={isLoading.toString()}>
							{clientesFiltrados
								.slice(
									(page === 0 ? page + 1 : page - 1) * itemsPerPage.value,
									page * itemsPerPage.value
								)
								.map((linha, i) => {
									return (
										<s.Tr key={i} bottom={i === clientes.length - 1} index={i}>
											<s.Td>{linha.nome}</s.Td>
											<s.Td>{formatDoc(linha.detalhes.cnpj)}</s.Td>
											<s.Td>
												{!linha.telefone
													? '-'
													: mascaraTelefone(linha.telefone)}
											</s.Td>
											<s.Td>{linha.email}</s.Td>
											<s.Td>{formatarData(linha.detalhes.dataCreated)}</s.Td>
										</s.Tr>
									);
								})}
						</s.Tbody>
					</s.Table>
					<s.Footer>
						<div>
							{Math.ceil(clientesFiltrados.length / itemsPerPage.value) > 1 && (
								<Pagination
									paginaAtual={page - 1}
									totalPaginas={Math.ceil(
										clientesFiltrados.length / itemsPerPage.value
									)}
									onClick={(pagina) => {
										setPage(pagina.selected + 1);
									}}
									disabled={isLoading}
								/>
							)}
						</div>
					</s.Footer>
				</s.WrapperTable>
			)}
		</>
	);
};

export default DataTableClient;
