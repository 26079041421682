import React from 'react';

const Shield = () => (
	<svg
		width="16"
		height="20"
		viewBox="0 0 16 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.00021 18.5706C8.00021 18.5706 14.8574 15.142 14.8574 9.99916V3.99916L8.00021 1.42773L1.14307 3.99916V9.99916C1.14307 15.142 8.00021 18.5706 8.00021 18.5706Z"
			stroke="#191919"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default Shield;
