import React from 'react';
import { CustomInput } from '../../../components';
import { colors, Icons } from '../../../assets';

import * as s from './styled-pagination-with-input';

const PaginationWithInput = ({
	page,
	setPage,
	itensPagina,
	searchValue,
	handleSubmit,
	isLoading,
}) => {
	const ultimaPagina = itensPagina < 30;
	const isInvalid = page < 1 || page > 100;

	const handlePrevPage = () => {
		if (page === 1) return;
		setPage(Number(page) - 1);
		handleSubmit(Number(page - 1), searchValue);
	};

	const handleNextPage = () => {
		setPage(Number(page) + 1);
		handleSubmit(Number(page + 1), searchValue);
	};

	const handlePageInput = (number) => {
		const regexOnlyNumbers = /^[0-9\b]+$/;
		if (number === '' || regexOnlyNumbers.test(number)) {
			setPage(number);
		}
	};

	const handleOnBlur = () => {
		if (page === '') {
			setPage(1);
			handleSubmit(1, searchValue);
		}
	};

	const handleOnKeyPress = (e) => {
		if (e.key === 'Enter' && page === '') {
			setPage(1);
			handleSubmit(1, searchValue);
		}
		if (page >= 1 && page <= 100 && e.key === 'Enter') {
			handleSubmit(page, searchValue);
		}
	};

	return (
		<s.Container isInvalid={isInvalid}>
			<s.Botao
				page={page}
				disabled={page <= 1 || isLoading}
				onClick={handlePrevPage}
				isInvalid={isInvalid}
			>
				<Icons.ChevronLeft
					fill={isInvalid ? colors.primaryRed : colors.primaryBlue}
					style={{ transform: 'translateY(-0.125rem)' }}
				/>
			</s.Botao>

			<CustomInput
				title="Digite o número da página"
				onChange={(e) => handlePageInput(e.target.value)}
				value={page}
				min={1}
				maxLength={3}
				border="none"
				onBlur={handleOnBlur}
				onKeyPress={(e) => {
					handleOnKeyPress(e);
				}}
			/>

			<s.Botao
				disabled={ultimaPagina || isLoading}
				onClick={handleNextPage}
				isInvalid={isInvalid}
			>
				<Icons.ChevronLeft
					fill={isInvalid ? colors.primaryRed : colors.primaryBlue}
					style={{ transform: 'translateY(-0.125rem) rotate(180deg)' }}
				/>
			</s.Botao>
		</s.Container>
	);
};

export default PaginationWithInput;
