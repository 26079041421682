import React, { useState, useRef } from 'react';
import { browserLocalPersistence, setPersistence } from 'firebase/auth';
import { withRouter, Link } from 'react-router-dom';
import { loginWithoutAuthPersist, getUser, auth } from '../../services';
import { CustomInput, ButtonCustom } from '../../components';
import { Icons } from '../../assets';
import { setLocalStorageObjectFormat, validateEmail } from '../../utils';
import { useAuth } from '../../hooks';

import * as s from './styled-login';

const Login = () => {
	const { setLoading, setCurrentUser } = useAuth();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [isLoggingOnFirebase, setIsLoggingOnFirebase] = useState(false);
	const [tentativasLogin, setTentativasLogin] = useState(0);
	const [typedEmail, setTypedEmail] = useState(false);
	const [typingEmail, setTypingEmail] = useState(false);

	const errorPassword = useRef(false);
	const errorEmail = useRef(false);

	const invalidEmail = () => {
		if (!typingEmail && typedEmail) return validateEmail(email);
	};

	const saveTokenInStorage = async () => {
		await setPersistence(auth, browserLocalPersistence);
	};

	function handleErrors(err, msg) {
		switch (err.code) {
			case 'auth/wrong-password':
				errorPassword.current = true;
				setError('Seu e-mail ou senha estão incorretos!');
				if (tentativasLogin + 1 >= 4)
					setError(
						'Não se lembra da sua senha? Recupere-a clicando em “Esqueci minha senha”'
					);
				else setTentativasLogin(tentativasLogin + 1);
				break;
			case 'auth/user-not-found':
				errorEmail.current = true;
				setError(msg);
				break;
			case 'auth/user-disabled':
				setError('Usuário desabilitado');
				break;
			case 'auth/too-many-requests':
				setError(
					'Não se lembra da sua senha? Recupere-a clicando em “Esqueci minha senha”'
				);
				break;
			default:
				if (msg === 'Ocorreu um erro, tente novamente mais tarde!')
					setError(msg);
				break;
		}
	}

	const handleSubmit = async () => {
		setError('');
		setIsLoggingOnFirebase(true);
		await loginWithoutAuthPersist(email.trim(), password, true)
			.then(async () => {
				setLoading(true);
				await getUser()
					.then((user) => {
						setLocalStorageObjectFormat('user', {
							date: new Date().getHours(),
							data: user,
						});
						saveTokenInStorage();
						setCurrentUser(user);
					})
					.finally(() => setLoading(false));
			})
			.catch((err) => {
				setIsLoggingOnFirebase(false);
				handleErrors(err.error, err.msg);
			});
	};

	const isDisabled =
		!password ||
		!email ||
		invalidEmail() ||
		isLoggingOnFirebase ||
		errorEmail.current ||
		errorPassword.current;

	return (
		<s.Container>
			<s.Card>
				<Icons.Logotipo />
				<h1>EFETUAR O LOGIN</h1>
				<s.Form>
					<s.FormGroup>
						<s.TitleDiv>
							<s.FormLabel htmlFor="email">E-mail</s.FormLabel>
						</s.TitleDiv>
						<CustomInput
							id="email"
							type="email"
							placeholder="Digite aqui seu e-mail"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							maxLength={50}
							onFocus={() => {
								setTypingEmail(true);
								errorEmail.current = false;
							}}
							onBlur={() => {
								setTypingEmail(false);
								setTypedEmail(true);
							}}
							required
						/>
					</s.FormGroup>
					<s.FormGroup>
						<s.TitleDiv>
							<s.FormLabel htmlFor="password">Senha</s.FormLabel>
						</s.TitleDiv>
						<CustomInput
							id="password"
							type="password"
							placeholder="Digite aqui sua senha"
							value={password}
							onChange={(e) => setPassword(e.target.value.trim())}
							maxLength={25}
							onFocus={() => (errorPassword.current = false)}
							required
						/>
					</s.FormGroup>
					<div className="forgot-password">
						<Link to="/esqueci-senha">Esqueceu sua senha?</Link>
					</div>
					<ButtonCustom
						type="submit"
						id="login"
						disabled={isDisabled}
						isLoading={isLoggingOnFirebase}
						onClick={handleSubmit}
					>
						Entrar
					</ButtonCustom>

					{error && (
						<span>
							<Icons.Attention />
							{error}
						</span>
					)}
				</s.Form>
			</s.Card>
		</s.Container>
	);
};

export default withRouter(Login);
