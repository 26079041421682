import React from 'react';
import * as S from './styled-menu-header';

import { Icons } from '../../../../assets';

const MenuHeader = (props) => {
	const { isSideMenuOpen, setIsSideMenuOpen } = props;
	/* 						width: isSideMenuOpen ? '9.3125rem' : '4.9375rem',
						height: isSideMenuOpen ? '3rem' : '1.625rem', */
	return (
		<S.Container isSideMenuOpen={isSideMenuOpen}>
			<S.Logo isSideMenuOpen={isSideMenuOpen}>
				<Icons.Logotipo />
			</S.Logo>
			<S.ToggleIcon
				isSideMenuOpen={isSideMenuOpen}
				onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
			>
				<div>
					<Icons.ChevronLeft
						style={{
							transform: `rotate(${isSideMenuOpen ? '0' : '180'}deg)`,
						}}
					/>
				</div>
			</S.ToggleIcon>
		</S.Container>
	);
};

export default MenuHeader;
