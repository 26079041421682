import React from 'react';
import * as s from './styled-recebedor';
import { LoadingRectangle } from '../../../../../components';

const Recebedor = ({
	informacoesRecebedor: infos,
	bancos,
	tiposConta,
	loadingRecebedor,
}) => {
	const nomeBanco = (bankCode) =>
		bancos.find((bank) => bank.value === bankCode)?.label || '--';
	const tipoConta = (type) =>
		tiposConta.find((bank) => bank.value === type)?.label || '--';

	return (
		<s.Header>
			<div id="recebedor">
				<h2>Recebedor</h2>
				{loadingRecebedor ? (
					<LoadingRectangle />
				) : (
					<p id="nome">{infos?.legalName || 'Nome do recebedor'}</p>
				)}
			</div>

			<div>
				<h2>Conta</h2>
				{loadingRecebedor ? (
					<LoadingRectangle />
				) : (
					<p>{infos?.conta ? `${infos.conta}-${infos.contaDv}` : 'conta-dv'}</p>
				)}
			</div>

			<div>
				<h2>Agência</h2>
				{loadingRecebedor ? (
					<LoadingRectangle />
				) : (
					<p>
						{infos?.agencia
							? infos?.agenciaDv
								? `${infos.agencia} - ${infos.agenciaDv}`
								: infos.agencia
							: 'agência - dv'}
					</p>
				)}
			</div>

			<div id="banco">
				<h2>Banco</h2>
				{loadingRecebedor ? (
					<LoadingRectangle />
				) : (
					<p>
						{infos?.bankCode
							? `${infos?.bankCode} - ${nomeBanco(infos?.bankCode)}`
							: 'código - Nome do banco'}
					</p>
				)}
			</div>

			<div>
				<h2>Tipo de conta</h2>
				{loadingRecebedor ? (
					<LoadingRectangle />
				) : (
					<p>{infos?.type ? tipoConta(infos?.type) : 'Tipo de conta'}</p>
				)}
			</div>
		</s.Header>
	);
};

export default Recebedor;
