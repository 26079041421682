import { axiosApp } from '.';

const getEstornoDetails = (transactionId, recipientId, ) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/refunds/${transactionId}?recipientId=${recipientId}`)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const postEstorno = (transactionId, amount, recipientId) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.post(
				`/refunds/${transactionId}/${amount}?recipientId=${recipientId}`
			)
			.then((resp) => resolve(resp.data))
			.catch((e) => reject(e));
	});
};

const postEstornoTotal = (transactionId, recipientId, idContractor) => {
  return new Promise((resolve, reject) => {
    axiosApp
      .post(`/refunds/${transactionId}/total?recipientId=${recipientId}&idContractor=${idContractor}`)
      .then((resp) => resolve(resp.data))
      .catch((e) => reject(e));
  });
};

export { postEstorno, getEstornoDetails, postEstornoTotal };
