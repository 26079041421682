import React from 'react';

const EloIcon = ({ title, style }) => (
	<svg
		width="26"
		height="16"
		style={style}
		viewBox="0 0 26 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="elo-icon"
	>
		<title>{title || 'Ícone Elo'}</title>
		<rect
			x="0.15"
			y="0.15"
			width="25.7"
			height="15.7"
			rx="1.85"
			fill="#FAFAFA"
			stroke="#B1B6B6"
			strokeWidth="0.3"
		/>
		<path
			d="M6.25471 5.76679C6.48979 5.68843 6.74055 5.64664 7.00071 5.64664C8.13956 5.64664 9.09035 6.45533 9.30767 7.53045L10.9219 7.20133C10.552 5.37498 8.93676 4 7.00071 4C6.55666 4 6.13142 4.07209 5.7323 4.20583L6.25471 5.76679Z"
			fill="#FFCB05"
		/>
		<path
			d="M4.34991 10.998L5.44174 9.7641C4.95486 9.33259 4.64768 8.70257 4.64768 8.00045C4.64768 7.29937 4.95486 6.66935 5.44174 6.23888L4.34991 5.00391C3.52241 5.73737 3 6.80831 3 8.00045C3 9.19363 3.52241 10.2646 4.34991 10.998Z"
			fill="#00A4E0"
		/>
		<path
			d="M9.30767 8.47266C9.0893 9.54673 8.13956 10.3544 7.00175 10.3544C6.74055 10.3544 6.48875 10.3126 6.25471 10.2332L5.7323 11.7962C6.13142 11.9289 6.55771 12.001 7.00175 12.001C8.93571 12.001 10.55 10.6281 10.9219 8.80386L9.30767 8.47266Z"
			fill="#EF4123"
		/>
		<path
			d="M12.3334 9.81468C12.2801 9.729 12.2091 9.59213 12.1652 9.49078C11.9092 8.89733 11.8977 8.28297 12.1129 7.69369C12.3501 7.04695 12.8036 6.55275 13.3897 6.29991C14.1263 5.98333 14.9402 6.04497 15.6465 6.46394C16.0947 6.72097 16.4124 7.118 16.6537 7.68011C16.684 7.7522 16.7112 7.82848 16.7384 7.8943L12.3334 9.81468ZM13.8045 7.25383C13.281 7.47846 13.0115 7.96953 13.0679 8.54418L15.2829 7.59026C14.9016 7.14307 14.4063 6.99471 13.8045 7.25383ZM15.5588 9.32884C15.5577 9.32884 15.5577 9.32988 15.5577 9.32988L15.5117 9.29854C15.379 9.51272 15.1732 9.68721 14.9131 9.80005C14.4178 10.0153 13.9591 9.95991 13.629 9.67049L13.5987 9.71646C13.5987 9.71646 13.5987 9.71542 13.5976 9.71542L13.0355 10.5565C13.1755 10.6537 13.3249 10.7352 13.4817 10.801C14.1023 11.0591 14.7375 11.0465 15.3634 10.7749C15.8158 10.5784 16.171 10.2796 16.4134 9.90035L15.5588 9.32884Z"
			fill="black"
		/>
		<path
			d="M18.2774 5.0625V9.73702L19.0046 10.0317L18.5919 10.995L17.7894 10.6606C17.6097 10.5823 17.4864 10.4632 17.3945 10.3284C17.3057 10.1915 17.2399 10.0024 17.2399 9.74956V5.0625H18.2774Z"
			fill="black"
		/>
		<path
			d="M20.1601 8.54823C20.1601 8.15015 20.3366 7.79282 20.6146 7.55043L19.8686 6.71875C19.3629 7.16593 19.0442 7.81894 19.0442 8.54614C19.0431 9.27438 19.3618 9.92843 19.8665 10.3756L20.6114 9.54394C20.3345 9.3005 20.1601 8.94526 20.1601 8.54823Z"
			fill="black"
		/>
		<path
			d="M21.4849 9.87376C21.3386 9.87376 21.1965 9.84869 21.0649 9.80481L20.7086 10.8632C20.9521 10.9447 21.2122 10.9896 21.4839 10.9896C22.6645 10.9907 23.6498 10.1527 23.8786 9.04L22.7836 8.81641C22.6593 9.42136 22.1254 9.87481 21.4849 9.87376Z"
			fill="black"
		/>
		<path
			d="M21.4881 6.10547C21.2175 6.10547 20.9573 6.14935 20.7139 6.2298L21.067 7.28925C21.1997 7.24537 21.3408 7.22134 21.487 7.22134C22.1286 7.22238 22.6635 7.67792 22.7847 8.28392L23.8797 8.06242C23.655 6.94759 22.6698 6.10651 21.4881 6.10547Z"
			fill="black"
		/>
	</svg>
);
export default EloIcon;
