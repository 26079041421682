import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
import {
	getBalance,
	getPendingAnticipations,
	getExtract,
} from '../../services';
import { useRecipientExtractInfo } from '../../hooks';
import {
	DetalhesRecebedor,
	ExtratoRecebedores,
	ConfiguracoesRecebedores,
	MaisInformacoes,
} from '../../components';
// import { images } from '../../assets';

import * as s from './styled-recipient-details';

const RecipientDetails = () => {
	const { recipientId } = useParams();
	// const [hasRecipient, setHasRecipient] = useState(false);
	const [selectedTab, setSelectedTab] = useState('extrato');

	const {
		setAntecipacoes,
		isLoadingAntecipaes,
		setIsLoadingAntecipacoes,
		setBalanco,
		isLoadingBalanco,
		setIsLoadingBalanco,
		setExtrato,
		isLoadingExtrato,
		setIsLoadingExtrato,
		setPagination,
		isLoadingRecebedor,
	} = useRecipientExtractInfo();

	const isLoading =
		isLoadingAntecipaes ||
		isLoadingBalanco ||
		isLoadingExtrato ||
		isLoadingRecebedor;

	const intervaloInicial = () => {
		const data = new Date();

		const diaHoje = data.getDate();
		const mesHoje = data.getMonth() + 1;
		const anoHoje = data.getFullYear();

		data.setDate(diaHoje - 7);

		const diaInicio = data.getDate();
		const mesInicio = data.getMonth() + 1;
		const anoInicio = data.getFullYear();

		return {
			from: { day: diaInicio, month: mesInicio, year: anoInicio },
			to: { day: diaHoje, month: mesHoje, year: anoHoje },
		};
	};

	const [intervaloDatas, setIntervaloDatas] = useState(intervaloInicial);

	function dataFormatada() {
		if (intervaloDatas?.day) {
			return {
				from: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
				to: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
			};
		}
		const dataInicial = intervaloDatas.from;
		const dataFinal = intervaloDatas.to;

		return {
			from: `${dataInicial.year}/${dataInicial.month}/${dataInicial.day}`,
			to: `${dataFinal.year}/${dataFinal.month}/${dataFinal.day}`,
		};
	}

	function datasEstaoCompletas() {
		const dataInicial = intervaloDatas.from;
		const dataFinal = intervaloDatas.to;
		const dataUnica = intervaloDatas.day;

		if (dataUnica) return true;
		if (!dataInicial || !dataFinal) return false;
		if (!dataInicial.day || !dataInicial.month || !dataInicial.year)
			return false;
		if (!dataFinal.day || !dataFinal.month || !dataFinal.year) return false;
		return true;
	}

	function buscarExtrato(pagina = 1, itensPorPagina = 15) {
		if (datasEstaoCompletas()) {
			setIsLoadingExtrato(true);

			getExtract(
				recipientId,
				dataFormatada().from,
				dataFormatada().to,
				pagina,
				itensPorPagina
			)
				.then((resp) => {
					setExtrato((prev) => ({
						...prev,
						discountsValue: resp?.discountsValue
							? resp.discountsValue
							: prev.discountsValue,
						grossValue: resp.grossValue ?? prev.grossValue,
						netValue: resp.netValue ?? prev.netValue,
						taxValue: resp.taxValue ?? prev.taxValue,
						data: [...resp.data],
					}));

					setPagination((prev) => ({
						page: pagina,
						totalPages: resp?.totalPages ? resp.totalPages : prev?.totalPages,
					}));
				})
				.catch((resp) => {
					setExtrato([]);
					if (resp.message !== 'Recebedor não localizado.') {
						// setHasRecipient(true);
					}
					toast.error('Erro ao buscar extrato');
				})
				.finally(() => setIsLoadingExtrato(false));
		} else toast.error('Selecione as datas corretamente');
	}

	useEffect(() => {
		setIsLoadingBalanco(true);
		getBalance(recipientId)
			.then((resp) => setBalanco(resp))
			.catch(() => toast.error('Erro ao buscar o saldo'))
			.finally(() => setIsLoadingBalanco(false));

		setIsLoadingAntecipacoes(true);
		getPendingAnticipations(recipientId, 'pending')
			.then((resp) => setAntecipacoes(resp))
			.catch(() => toast.error('Erro ao buscar antecipações'))
			.finally(() => setIsLoadingAntecipacoes(false));
	}, []);

	return (
		<s.Container>
			{/* {isLoading && hasRecipient ? (
				<> */}
			<DetalhesRecebedor />
			<s.ContainerTabs loading={isLoading}>
				<s.Tab
					isActive={selectedTab === 'extrato'}
					onClick={() => setSelectedTab('extrato')}
				>
					Extrato
				</s.Tab>
				<s.Tab
					isActive={selectedTab === 'configuracoes'}
					onClick={() => setSelectedTab('configuracoes')}
				>
					Configurações
				</s.Tab>
				<s.Tab
					isActive={selectedTab === 'mais-info'}
					onClick={() => setSelectedTab('mais-info')}
				>
					Mais Informações
				</s.Tab>
			</s.ContainerTabs>

			{selectedTab === 'extrato' && (
				<ExtratoRecebedores
					recipientId={recipientId}
					buscarExtrato={buscarExtrato}
					intervaloInicial={intervaloInicial}
					intervaloDatas={intervaloDatas}
					setIntervaloDatas={setIntervaloDatas}
				/>
			)}
			{selectedTab === 'configuracoes' && (
				<ConfiguracoesRecebedores
					buscarExtrato={buscarExtrato}
					intervaloInicial={intervaloInicial}
					intervaloDatas={intervaloDatas}
					setIntervaloDatas={setIntervaloDatas}
				/>
			)}
			{selectedTab === 'mais-info' && <MaisInformacoes />}
			{/* </>
			) : (
				<s.NoContentContainer>
					<s.NoContentMessage>
						<s.NoContentTitle>Ooops! Que pena :(</s.NoContentTitle>
						<s.NoContentText>
							Não encontramos registros do recebedor selecionado. <br /> Tente
							novamente selecionando outro recebedor.
						</s.NoContentText>
					</s.NoContentMessage>
					<img
						className="nenhum-registro"
						src={images.nenhumRegistro}
						alt="Nenhum registro"
					/>
				</s.NoContentContainer>
			)} */}
		</s.Container>
	);
};

export default RecipientDetails;
