import styled from 'styled-components';
import { colors } from '../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 0px 1.5rem 0 1.5625rem;
`;

export const ContainerLoader = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 2rem;
`;

export const ErrorContent = styled.div`
	margin-top: 128px;
	margin-bottom: 50px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${colors.white};
	position: relative;

	svg.attention-outlined-icon {
		width: 8.75rem;
		height: 8.75rem;
		fill: ${colors.background};
		filter: drop-shadow(0px 0px 3px ${colors.yellow});
		margin-bottom: 1.75rem;
	}

	.error-title {
		font-size: 2rem;
		margin-bottom: 1rem;
		text-transform: uppercase;
		font-family: Jost;
		font-weight: 500;
	}

	.error-text {
		font-size: 1.25rem;
		text-align: center;
		color: ${colors.lightGray};
		font-family: IBM Plex Sans;
		font-weight: 400;
	}
	div {
		padding-top: 3rem;
	}
`;
