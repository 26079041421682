import React from 'react';
import {
	Page,
	Document,
	Text,
	View,
	StyleSheet,
	Font,
} from '@react-pdf/renderer';
import { formatarParaReais, formatCardNumber } from '../../../utils';

Font.register({
	family: 'IBM Plex Sans',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/ibmplexsans/v13/zYXgKVElMYYaJe8bpLHnCwDKtdbUFI5NadY.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/ibmplexsans/v13/zYX9KVElMYYaJe8bpLHnCwDKjQ76MIZmdd_qFmo.ttf',
			fontWeight: 600,
		},
	],
});

// Create styles
const S = StyleSheet.create({
	page: {
		flexDirection: 'column',
		justifyContent: 'flex-start',
		paddingTop: 20,
		paddingBottom: 35,
		paddingHorizontal: 35,
	},
	section: {
		margin: '10 0 10 0',
		borderBottom: '1 solid #DDD',
		paddingBottom: 10,
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
	},
	article: {
		margin: '10 0 10 0',
		padding: '10px 0 10px 35px',
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
	},
	footer: {
		margin: '10 0 10 0',
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
		color: '#777',
		borderBottom: '1 solid #DDD',
	},
	tittle: {
		fontSize: 20,
		fontFamily: 'IBM Plex Sans',
		fontWeight: 600,
		textAlign: 'center',
		marginBottom: 20,
	},
	subtitle: {
		fontSize: 13,
		fontFamily: 'IBM Plex Sans',
		textAlign: 'left',
		fontWeight: 600,
	},
	lineInfo: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '10px 0 10px 10px',
	},
	lineInfoBorder: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '10px 0 10px 10px',
		borderBottom: '1 solid #DDD',
	},
	textGray: {
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
		lineHeight: 1.75,
		color: '#777',
	},
	textBold: {
		fontWeight: 600,
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
	},
});

const RenderPage = ({ estorno, dados }) => {
	const amount = estorno?.amount;
	const dateUpdate = estorno?.date_created;
	const authorizationId = dados?.authorizationCode;
	return (
		<Page size="A4" style={S.page}>
			<Text style={S.tittle}>Comprovante de Cancelamento</Text>
			<View style={S.section}>
				<Text render={() => `Olá, ${dados?.customer?.name}`} />
				<Text> </Text>
				<Text>
					Informamos que a compra foi cancelada com sucesso conforme sua
					solicitação. Em caso de dúvida, por favor ligue para nossa equipe de
					relacionamento com o cliente.
				</Text>
			</View>
			<View style={S.article}>
				<Text style={S.subtitle}>Dados do estorno:</Text>

				<View style={S.lineInfoBorder}>
					<Text>ID da transação:</Text>
					<Text render={() => dados?.id} />
				</View>

				<View style={S.lineInfoBorder}>
					<Text>ID da autorização:</Text>
					<Text render={() => authorizationId} />
				</View>

				<View style={S.lineInfoBorder}>
					<Text>Data da compra:</Text>
					<Text
						render={() =>
							new Date(dados?.customer?.date_created).toLocaleDateString(
								'pt-BR',
								{
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
								}
							)
						}
					/>
				</View>

				<View style={S.lineInfoBorder}>
					<Text>Valor da Compra</Text>
					<Text
						render={() =>
							`R$ ${formatarParaReais(Number(dados?.splitRules?.total) / 100)}`
						}
					/>
				</View>

				<View style={S.lineInfoBorder}>
					<Text>Valor do cancelamento:</Text>
					<Text render={() => `R$ ${formatarParaReais(amount / 100)}`} />
				</View>

				<View style={S.lineInfo}>
					<Text>Data do cancelamento:</Text>
					<Text
						render={() =>
							new Date(dateUpdate).toLocaleDateString('pt-BR', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
							})
						}
					/>
				</View>
			</View>

			<View style={S.article}>
				<Text style={S.subtitle}>Dados do Favorecido:</Text>

				<View style={S.lineInfoBorder}>
					<Text>Nome do Titular:</Text>
					<Text render={() => dados?.cardHolderName} />
				</View>

				<View style={S.lineInfoBorder}>
					<Text>Número do Cartão:</Text>
					<Text
						render={() =>
							dados &&
							formatCardNumber(dados?.cardFirstDigits, dados?.cardLastDigits)
						}
					/>
				</View>

				<View style={S.lineInfo}>
					<Text>Bandeira:</Text>
					<Text render={() => dados?.cardBrand.toUpperCase()} />
				</View>
			</View>

			<View style={S.section}>
				<Text style={S.textBold}>Atenção: </Text>
				<Text>
					O cancelamento foi realizado com sucesso pela payOS. Porém, o crédito
					concedido ao seu cliente dependerá da data de fechamento da fatura e
					só poderá ser realizado mediante o banco emissor do cartão. Único
					responsável pela fatura do cliente.
				</Text>
				<Text> </Text>
				<Text>Atenciosamente,</Text>
				<Text>Equipe payOS</Text>
				<Text
					render={() =>
						`Campo Bom, ${new Date().toLocaleDateString('pt-BR', {
							day: '2-digit',
							month: '2-digit',
							year: 'numeric',
						})}`
					}
				/>
				<Text> </Text>
				<Text> </Text>
			</View>

			<View style={S.footer}>
				<Text style={S.textGray}>helpdeskpaipe@paipe.co</Text>
				<Text style={S.textGray}>
					Av. Carlos Strassburger Filho, 5796, Rio Grande do Sul, CEP 93700-000
				</Text>
				<Text style={S.textGray}>https://dashboard.payos.app/</Text>
			</View>
		</Page>
	);
};

const DetalhesEstorno = ({ loading, dadosEstorno, dados }) => {
	return (
		<>
			{!loading && dadosEstorno && (
				<Document>
					{dadosEstorno &&
						dadosEstorno?.map((estorno, index) => (
							<RenderPage estorno={estorno} index={index} dados={dados} />
						))}
				</Document>
			)}
		</>
	);
};

export default DetalhesEstorno;
