import React from 'react';
import { format } from 'date-fns';
import { Loader } from '../../../../components';
import { formatarParaReais } from '../../../../utils';

import * as s from './styled-card-history';

const Operations = ({ operations, index }) => {
	const formatType = (type) => {
		if (type === 'authorize' && operations?.status === 'success') {
			return 'Autorizada';
		}
		if (type === 'authorize' && operations?.status === 'failed') {
			return 'Autorização';
		}
		if (type === 'capture') {
			return 'Capturada';
		}
		if (type === 'refund') {
			return 'Estornada';
		}
		if (type === 'conciliate') {
			return 'Chargeback Recebido';
		}
	};

	return (
		<s.ReceiverContainer>
			<s.Wrapper>
				<s.Index>{`${index + 1}`}</s.Index>
				<s.Content>
					<h4 className="title">{`${formatType(operations?.type)}`}</h4>
					<div className="content">
						{operations?.status === 'failed' && (
							<span style={{ paddingTop: '10px' }} className>
								Falhou
							</span>
						)}
					</div>

					<div className="content">
						<span>{`${format(
							new Date(operations?.date),
							'dd/MM/yyyy'
						)} ${format(new Date(operations?.date), 'HH:mm')}`}</span>
					</div>

					{operations?.amount && (
						<div className="content">
							<span>{`Valor: ${formatarParaReais(
								operations.amount / 100,
								true
							)}`}</span>
						</div>
					)}

					{operations?.tid && (
						<div className="content">
							<span>{`TID: ${operations.tid}`}</span>
						</div>
					)}
				</s.Content>
			</s.Wrapper>
		</s.ReceiverContainer>
	);
};

const CardHistory = (props) => {
	const { id, div, title, history, loading } = props;
	const historyReversed = history && [...history].reverse();

	return (
		<s.Container id={id} div={div}>
			<s.Title>{title}</s.Title>
			<s.Infos>
				{!loading ? (
					historyReversed?.map((item, index) => (
						<Operations index={index} operations={item} />
					))
				) : (
					<Loader />
				)}
			</s.Infos>
		</s.Container>
	);
};

export default CardHistory;
