import React from 'react';
import { Icons, colors } from '../../../../assets/';

import * as s from './styled-contractor-steps';

const ContractorSteps = ({ steps }) => {
	const stepsData = [
		'dados',
		'contatos',
		'conta bancária',
		'confirmação',
		'conclusão',
	];
	return (
		<s.Container>
			{stepsData.map((currentStep, index) => {
				const ultimoItemMap = index === stepsData.length - 1;
				const isCurrentStep = steps > index;
				return (
					<>
						<s.Step key={index} index={index}>
							<s.StepText className={isCurrentStep && 'active'}>
								{`${index + 1}. ${currentStep.toUpperCase()}`}
							</s.StepText>
						</s.Step>
						{!ultimoItemMap && (
							<s.Icon index={index}>
								<Icons.ChevronThin
									fill={steps > index + 1 && colors.primaryBlue}
								/>
							</s.Icon>
						)}
					</>
				);
			})}
		</s.Container>
	);
};

export default ContractorSteps;
