import React from 'react';
import { colors } from '../colors';

const TicketMedioMiIcon = ({ title, fill, fillBackground, style }) => (
	<svg
		style={style}
		className="ticket-icon"
		width="56"
		height="56"
		fill="none"
		viewBox="0 0 56 56"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || 'Ícone de ticket médio mercado interno'}</title>
		<rect
			width="56"
			height="56"
			rx="28"
			fill={fillBackground || colors.lightTurquoise}
		/>
		<path
			d="M33 19.9158C33 18.8621 32.1 18 31 18C29.9 18 29 18.8621 29 19.9158V35.2419C29 36.2955 29.9 37.1576 31 37.1576C32.1 37.1576 33 36.2955 33 35.2419V19.9158Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			d="M19 19.9158C19 18.8621 18.1 18 17 18C15.9 18 15 18.8621 15 19.9158V35.2419C15 36.2955 15.9 37.1576 17 37.1576C18.1 37.1576 19 36.2955 19 35.2419V19.9158Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			d="M24.1333 21.748H23.8667C22.84 21.748 22 22.6101 22 23.6638V35.2426C22 36.2963 22.84 37.1584 23.8667 37.1584H24.1333C25.16 37.1584 26 36.2963 26 35.2426V23.6638C26 22.6101 25.16 21.748 24.1333 21.748Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			d="M38 25.9473C39.1 25.9473 40 26.8094 40 27.863V35.2419C40 36.2956 39.1 37.1577 38 37.1577C36.9 37.1577 36 36.2956 36 35.2419V27.863C36 26.8094 36.9 25.9473 38 25.9473Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			d="M38 18.1582C39.1 18.1582 40 19.0203 40 20.074V21.4529C40 22.5065 39.1 23.3686 38 23.3686C36.9 23.3686 36 22.5065 36 21.4529V20.074C36 19.0203 36.9 18.1582 38 18.1582Z"
			fill={fill || colors.primaryBlue}
		/>
	</svg>
);
export default TicketMedioMiIcon;
