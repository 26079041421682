import React from 'react';
import Modal from 'react-modal';
import { colors, Icons } from './../../../assets';
import * as s from './styled-modal';

Modal.setAppElement('#root');

export default ({
	children,
	isOpen,
	closeModal,
	styles,
	iconColor,
	backgroundIcon,
	maxWidth,
	width,
	background,
	height,
	overflow,
	borderRadius,
}) => {
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			border: 'none',
			borderRadius: borderRadius || '10px',
			zIndex: 2,
			backgroundColor: background || colors.background,
			maxWidth: maxWidth || '90%',
			width: width || 'auto',
			height: height || 'auto',
			overflow: overflow || 'hidden',
		},
		overlay: {
			zIndex: 99,
			backgroundColor: colors.modalOverlay,
		},
	};

	return (
		<Modal
			style={styles || customStyles}
			isOpen={isOpen}
			onRequestClose={closeModal}
		>
			<s.Column>
				<s.IconWrapper onClick={closeModal}>
					{backgroundIcon ? (
						<s.ContainerIcon background={backgroundIcon}>
							<Icons.Close
								fill={iconColor || colors.primaryBlue}
								title="Fechar"
							/>
						</s.ContainerIcon>
					) : (
						<Icons.Close
							fill={iconColor || colors.primaryBlue}
							title="Fechar"
						/>
					)}
				</s.IconWrapper>
				{children}
			</s.Column>
		</Modal>
	);
};
