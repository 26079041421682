import React from 'react';
import { colors } from '../../../assets';

const AttentionOutlinedIcon = ({ title, fill, style }) => (
	<svg
		className="attention-outlined-icon"
		style={style}
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			d="M12.0595 8.99975V10.9998V8.99975ZM12.0595 14.9998H12.0695H12.0595ZM5.13149 18.9998H18.9875C20.5275 18.9998 21.4895 17.3328 20.7195 15.9998L13.7915 3.99975C13.0215 2.66675 11.0975 2.66675 10.3275 3.99975L3.39949 15.9998C2.62949 17.3328 3.59149 18.9998 5.13149 18.9998Z"
			stroke={fill || colors.yellow}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default AttentionOutlinedIcon;
