import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	justify-content: ${(props) =>
		props.checkboxAlign ? props.checkboxAlign : 'center'};
	align-items: center;
	height: 100%;
	width: 100%;
	/* margin: 10px 0; */
	color: ${({ checked }) => (checked ? colors.primaryBlue : colors.lightGray)};

	label {
		font-size: ${(props) => (props.fontSize ? props.fontSize : '1.375rem')};

		cursor: pointer;
	}

	${({ disabled }) => disabled && 'label { cursor: not-allowed; }'};
`;

export const ContainerCheckbox = styled.div`
	display: flex;
	/* margin-right: 0.75rem; */
	height: 20px;
	width: auto;
	border-radius: 2px;
	color: ${({ checked }) => (checked ? colors.green : colors.lightGray)};
`;

export const Checkbox = styled.input`
	position: relative;

	cursor: pointer;
	height: 0;
	${({ disabled }) => disabled && 'cursor: not-allowed'};

	-moz-appearance: initial; // Hack for Firefox Browsers - permitir ::after e ::before
	::before {
		content: '';
		display: block;
		position: absolute;
		width: 20px;
		height: 20px;
		top: 0;
		left: 0;

		background: transparent;
		border: 1.2px solid ${colors.gray};
		border-radius: 2px;
	}

	:checked::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background: ${colors.primaryBlue};
		border: 1.2px solid ${colors.blue};
		border-radius: 2px;
	}

	:checked::after {
		content: '';
		display: block;
		width: 5px;
		height: 10px;

		border: solid ${colors.darkGray};
		border-width: 0 3.5px 3.5px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		top: 2.5px;
		left: 7px;
	}
`;
