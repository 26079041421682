import styled from 'styled-components';
import { ButtonCustom } from '../..';
import { colors } from '../../../assets';

export const Container = styled(ButtonCustom)`
	padding: 0.3125rem 1.375rem 0.4375rem 1.375rem;
	border: 1px solid ${colors.primaryBlue};
	cursor: pointer;
	border-radius: 0.625rem;
`;

export const Overlay = styled.div`
	background-color: rgba(0, 0, 0, 0.05);
	position: fixed;
	inset: 0;
	z-index: 10;
`;

export const ContainerFilters = styled.div`
	display: flex;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	p {
		font-family: Jost;
		font-size: 0.75rem;
		margin: 1.2rem 0 0.625rem 1.2rem;
		font-weight: 200;
		letter-spacing: 1px;
	}
`;
