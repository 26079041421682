import { useHistory, useParams } from 'react-router';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { formatarParaReais } from '../../../../utils';
import {
	ButtonCustom,
	GeradorPDF,
	LoadingRectangle,
} from '../../../../components';
import { DetalhesEstorno } from '../../../../components/molecules/gerador-pdf-estorno';
import * as S from './styled-card-header';

const CardHeader = ({
	id,
	div,
	transaction,
	status,
	installments,
	authorizedValue,
	dados,
	dadosEstorno,
	loading,
}) => {
	const history = useHistory();
	const { transactionId } = useParams();
	const operation = dados?.operations?.[0]?.type;

	const renderStatus = (transactionStatus) => {
		if (transactionStatus === 'paid')
			return <S.Status status={transactionStatus}>Paga</S.Status>;
		if (transactionStatus === 'refused')
			return <S.Status status={transactionStatus}>Recusada</S.Status>;
		if (transactionStatus === 'refunded')
			return <S.Status status={transactionStatus}>Estornada</S.Status>;
		if (transactionStatus === 'chargedback')
			return <S.Status status={transactionStatus}>Chargeback</S.Status>;
		if (transactionStatus === 'pending_refund')
			return (
				<S.Status status={transactionStatus}>Reembolso Pendente </S.Status>
			);
		if (transactionStatus === 'waiting_payment')
			return (
				<S.Status status={transactionStatus}>Aguardando Pagamento</S.Status>
			);
		if (transactionStatus === 'processing')
			return <S.Status status={transactionStatus}>Processando</S.Status>;
		if (transactionStatus === 'authorized')
			return <S.Status status={transactionStatus}>Autorizada</S.Status>;
		if (transactionStatus === 'pending_review')
			return <S.Status status={transactionStatus}>Análise pendente</S.Status>;

		return status;
	};

	const renderInstallments = (transactonInstallments) => {
		if (transactonInstallments === 1) {
			return 'À vista';
		}
		return <S.Installments>Parcelado {installments}x</S.Installments>;
	};

	const disabledEstorno = () => {
		if (status === 'paid') {
			return (
				<ButtonCustom
					onClick={() => history.push(`/transacoes/estorno/${transaction}`)}
				>
					Estorno
				</ButtonCustom>
			);
		}
		return null;
	};

	const getDadosEstornados = () => {
		if ((operation === 'refund' && status === 'paid') || status === 'refunded')
			return (
				<PDFDownloadLink
					document={
						<DetalhesEstorno
							loading={loading}
							dadosEstorno={dadosEstorno}
							dados={dados}
						/>
					}
					fileName={`comprovante-estorno-${transactionId}.pdf`}
				>
					<ButtonCustom width="fit-content" theme="blue">
						Comprovante do estorno
					</ButtonCustom>
					{() =>
						loading ? (
							<ButtonCustom
								width="-webkit-fit-content"
								theme="blue"
								disabled={loading}
							>
								Carregando comprovante
							</ButtonCustom>
						) : (
							<ButtonCustom width="fit-content" theme="blue">
								Comprovante do estorno
							</ButtonCustom>
						)
					}
				</PDFDownloadLink>
			);
	};

	return (
		<S.Container id={id} div={div} transaction={transaction}>
			<S.Transaction>
				<div>
					<span className="title">Transação</span>
				</div>
				<div>
					<span className="transaction-id">
						{!loading ? `#${transaction}` : <LoadingRectangle />}
					</span>
				</div>
			</S.Transaction>
			<S.Status>
				<div className="padding-bottom">
					<span className="title ">Status</span>
				</div>
				<div className="text">
					{!loading ? renderStatus(status) : <LoadingRectangle />}
				</div>
			</S.Status>
			<S.Payment>
				<div className="padding-bottom">
					<span className="title">Pagamento</span>
				</div>
				<div>
					{!loading ? (
						<span className="text">{renderInstallments(installments)}</span>
					) : (
						<LoadingRectangle />
					)}
				</div>
			</S.Payment>
			<S.AutorizedValue>
				<div className="padding-bottom">
					<span className="title">Valor autorizado</span>
				</div>
				<div>
					<span className="text">
						{!loading ? (
							formatarParaReais(authorizedValue / 100, true)
						) : (
							<LoadingRectangle />
						)}
					</span>
				</div>
			</S.AutorizedValue>
			<div className="button">{disabledEstorno()}</div>

			{!loading && dadosEstorno.length > 0 && (
				<div className="button">{getDadosEstornados()}</div>
			)}

			<div className="button">
				<GeradorPDF
					id="botao-gerar-relatorio-transacao"
					disabled={loading}
					dados={dados}
					loading={loading}
				/>
			</div>
		</S.Container>
	);
};

export default CardHeader;
