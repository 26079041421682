import React from 'react';
import * as s from './styled-modal-message';
import { images } from '../../../../assets';
import ContainerModal from '../container-modal';
import { ButtonCustom } from '../../../../components';

const ModalMessage = ({ isOpen, closeModal }) => {
	return (
		<ContainerModal isOpen={isOpen} closeModal={closeModal}>
			<s.Content>
				<img src={images.pranchetaConcluido} alt="prancheta" />
				<p>Confira seu e-mail!</p>
				<p>Enviamos para seu e-mail um link de redefinição de senha.</p>
				<ButtonCustom onClick={closeModal}>OK</ButtonCustom>
			</s.Content>
		</ContainerModal>
	);
};

export default ModalMessage;
