import styled from 'styled-components';
import { colors } from '../../../assets';

export const Container = styled.div`
	display: flex;
`;

export const Sinal = styled.span`
	display: inline-block;
	text-align: center;
	color: ${({ negativo, neutro }) =>
		negativo ? colors.primaryRed : neutro ? colors.white : colors.green};
	font-weight: 600;

	span.positivo {
		font-size: ${({ fontSize }) => fontSize || '1.5rem'};
		margin-right: 0.625rem;
	}
	span.negativo {
		font-size: ${({ fontSize }) => fontSize || '1.5rem'};
		margin-right: 0.9375rem;
	}

	.cifrao {
		color: ${({ filled, negativo, neutro }) =>
			!filled || neutro
				? colors.white
				: filled && (negativo ? colors.primaryRed : colors.green)};
		font-size: ${({ fontSize }) => fontSize || '1.5rem'};
		margin-right: 0.625rem;
		font-family: Jost;
	}
	.valor {
		font-family: Jost;
		color: ${({ filled, negativo, neutro }) =>
			!filled || neutro
				? colors.white
				: filled && (negativo ? colors.primaryRed : colors.green)};
		font-size: ${({ fontSize }) => fontSize || '1.5rem'};
	}
`;
