import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Toggle } from 'react-toggle-component';
import {
	ButtonCustom,
	LoadingRectangle,
	Pagination,
	SearchField,
	SelectReact,
	Loader,
} from '../../components';
import {
	getContractors,
	getSearchContractor,
	putContractorByStatus,
} from '../../services';
import { mascaraTelefone, mascaraCNPJ } from '../../utils';
import { Icons, colors } from '../../assets';

import * as s from './styled-contractors';

const Contractors = () => {
	const history = useHistory();
	const [contractors, setContractors] = useState([]);
	const [pagination, setPagination] = useState({});
	const [searchValue, setSearchValue] = useState('');
	const [page, setPage] = useState(1);
	const [contractorFilter, setContractorFilter] = useState('');
	const [order, setOrder] = useState({ value: 'asc', label: 'A-Z' });
	const [activeStatus, setActiveStatus] = useState({
		value: 'all',
		label: 'Todos',
	});
	const [loading, setLoading] = useState(false);

	const optionsStatus = [
		{ value: 'all', label: 'Todos' },
		{ value: 'active', label: 'Ativo' },
		{ value: 'inactive', label: 'Inativo' },
	];

	const optionsOrder = [
		{ value: 'asc', label: 'Nome A-Z' },
		{ value: 'desc', label: 'Nome Z-A' },
	];

	const isCleanFiltersDisabled = loading || searchValue === '';
	const isSubmitDisabled =
		loading || searchValue === '' || page < 1 || page > 100;

	function buscarContratantes(pagina = 1, ordem, filtro) {
		setLoading(true);
		getContractors(pagina, ordem, filtro)
			.then((resp) => {
				setContractors(resp.data);
				setContractorFilter(contractors);
				setPagination({ totalPages: resp.pages, page: pagina });
			})
			.catch(() => toast.error('Erro ao buscar contratantes'))
			.finally(() => setLoading(false));
	}

	function searchContractors(value, pagina = 1) {
		setLoading(true);
		getSearchContractor(value, pagina)
			.then((resp) => {
				setContractors(resp.data);
				setPagination({ totalPages: resp.pages, page: pagina });
			})
			.catch(() => toast.error('Erro ao filtrar contratantes'))
			.finally(() => setLoading(false));
	}

	function changeContractorStatus(contractorId, status) {
		putContractorByStatus(contractorId, status)
			.then(() => toast.success('Status atualizado com sucesso'))
			.catch(() => toast.error('Erro ao atualizar status'));
	}

	const LoadingCell = () => {
		const celulas = [1, 2, 3, 4, 5].map((i) => (
			<tr key={i} style={{ background: 'none' }}>
				{[1, 2, 3, 4, 5, 6].map((j) => (
					<td key={j}>
						<LoadingRectangle style={{ height: 50, width: '100%' }} />
					</td>
				))}
			</tr>
		));

		return celulas;
	};

	useEffect(() => {
		buscarContratantes();
	}, []);

	useEffect(() => {
		buscarContratantes(page, order.value, activeStatus.value);
	}, [order, activeStatus]);

	const [checked, setChecked] = useState({});

	return (
		<s.Container>
			<s.ContainerFilter>
				<SearchField
					value={searchValue}
					placeholder="Busque por Razão Social, CNPJ (somente números) ou Responsável"
					onChange={(e) => {
						setSearchValue(e.target.value);
						setPage(1);
					}}
					border={`1px solid ${colors.gray}`}
					onKeyPress={(e) => {
						if (e.key === 'Enter') {
							searchContractors(searchValue);
						}
					}}
				/>
				<s.ButtonGroup>
					<ButtonCustom
						outlined
						theme={isCleanFiltersDisabled ? 'gray' : 'green'}
						width="12.5rem"
						disabled={isCleanFiltersDisabled}
						onClick={() => {
							setContractors(contractorFilter);
							setSearchValue('');
						}}
						borderRadius=".9375rem"
					>
						Limpar busca
					</ButtonCustom>
					<ButtonCustom
						theme={isSubmitDisabled ? 'gray' : 'green'}
						width="12.5rem"
						disabled={isSubmitDisabled}
						onClick={() => searchContractors(searchValue)}
						borderRadius=".9375rem"
					>
						Buscar
					</ButtonCustom>
				</s.ButtonGroup>
			</s.ContainerFilter>

			{loading ? (
				<s.CenterLoader>
					<Loader />
				</s.CenterLoader>
			) : contractors.length > 0 ? (
				<s.TableMaster>
					<s.TableHeader>
						{contractors.length > 0 && (
							<s.TabelHeaderButtons>
								<SelectReact
									id="select-status"
									options={optionsStatus}
									onChange={(opt) => setActiveStatus(opt)}
									value={activeStatus}
									outlined
								/>

								<SelectReact
									id="select-ordenar-por"
									options={optionsOrder}
									onChange={(opt) => setOrder(opt)}
									value={order}
									outlined
								/>
							</s.TabelHeaderButtons>
						)}

						<s.TableHeaderRight>
							{contractors.length > 0 &&
								pagination &&
								pagination.totalPages > 1 && (
									<Pagination
										id="paginacao-contratantes"
										totalPaginas={pagination.totalPages}
										paginaAtual={pagination.page - 1}
										onClick={({ selected }) => {
											if (searchValue)
												searchContractors(searchValue, selected + 1);
											else buscarContratantes(selected + 1);
										}}
										disabled={loading}
									/>
								)}
							<ButtonCustom
								outlined
								id="adicionar-contratante"
								onClick={() =>
									history.push('/contratante/adicionar-contratante')
								}
								startIcon={<Icons.AddPerson fill={colors.primaryBlue} />}
							>
								Adicionar contratante
							</ButtonCustom>
						</s.TableHeaderRight>
					</s.TableHeader>

					<s.THead>
						<tr>
							<td>Razão social</td>
							<td>CNPJ</td>
							<td>Responsável</td>
							<td>Telefone</td>
							<td className="center">Ativação</td>
							<td className="center">Detalhes</td>
						</tr>
					</s.THead>

					<s.TBody>
						{loading ? (
							<LoadingCell />
						) : (
							contractors &&
							contractors.map((item, index) => (
								<tr key={index} id={`TableRows-${item.contractorId}`}>
									<td>{item.companyName}</td>
									<td>{mascaraCNPJ(item.cnpj)}</td>
									<td>{item.sponsorName}</td>

									<td>{mascaraTelefone(item.phone.substring(2))}</td>
									<td className="center">
										<Toggle
											id={`ativar-contratante-${item.contractorId}`}
											checked={checked.index || item.active}
											onChange={() => {
												changeContractorStatus(
													item.contractorId,
													item.active ? 'inactive' : 'active'
												);
												contractors[index].active = !item.active;
												setChecked({ ...checked, [index]: !item.active });
											}}
											name={item.contractorId}
											borderWidth="0px"
											width="4rem"
											height="1.75rem"
											knobWidth="1.25rem"
											knobHeight="1.25rem"
											knobGap=".25rem"
											leftKnobColor={colors.darkGray}
											rightKnobColor={colors.primaryBlue}
											leftBackgroundColor={colors.lightGray}
											rightBackgroundColor={colors.darkTurquoise}
										/>
									</td>
									<td className="center">
										<ButtonCustom
											className="button-icon"
											id={`detalhes-contratante-${item.contractorId}`}
											onClick={() =>
												history.push(`/contratante/${item.contractorId}`)
											}
										>
											<Icons.ArrowUpRight width="28" height="28" />
										</ButtonCustom>
									</td>
								</tr>
							))
						)}
					</s.TBody>
				</s.TableMaster>
			) : (
				<s.ErrorContent>
					<Icons.AttentionOutlined />
					<h2 className="error-title">Sem resultados</h2>
					<p className="error-text">
						Não encontramos resultados para sua busca. Reveja o que foi digitado
						ou faça uma nova pesquisa.
					</p>
				</s.ErrorContent>
			)}
		</s.Container>
	);
};
export default Contractors;
