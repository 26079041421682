/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { LoadingRectangle } from '../../../../components';
import { mascaraCPF, mascaraCNPJ } from '../../../../utils';

import * as S from './styled-card-client';

const CardClient = (props) => {
	const { id, div, title, customer, address, loading } = props;
	function formatNumber(value) {
		let mobile = '';
		if (value.charAt(0) === '+' || value.charAt(0) === '0') {
			// (00) 999999999
			mobile = value
				.replace(/\D/g, '')
				.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
		} else {
			mobile = value.replace(/[^a-zA-Z0-9]/g, '');
			mobile = mobile.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2$3');
		}
		return value;
	}

	const formatDoc = (doc) => {
		const tamanho = doc.length;
		if (tamanho === 11) {
			return mascaraCPF(doc);
		}
		if (tamanho === 14) {
			return mascaraCNPJ(doc);
		}
		return doc;
	};

	return (
		<S.Container id={id} div={div}>
			<S.Title>{title}</S.Title>

			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Nome do cliente</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							customer?.name ? (
								customer.name
							) : (
								'-'
							)
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>CNPJ/CPF</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							customer?.external_id ? (
								formatDoc(customer.external_id)
							) : (
								'-'
							)
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Data de nascimento</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							customer?.born_at ? (
								customer.born_at
							) : (
								'--/--/----'
							)
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
			</S.Row>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Telefone</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							customer?.phone_numbers ? (
								formatNumber(customer?.phone_numbers[0])
							) : (
								'-'
							)
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>E-mail</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							customer?.email ? (
								formatNumber(customer?.email)
							) : (
								'-'
							)
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
			</S.Row>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Rua</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							address?.street ? (
								address.street
							) : (
								'-'
							)
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>

				<S.Col>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<S.Col2>
							<div
								style={
									!loading ? { display: 'flex', marginRight: '50px' } : null
								}
							>
								<h5>Nº</h5>
							</div>
							<div
								style={
									!loading
										? { display: 'flex', marginRight: '50px', width: '100%' }
										: { width: '70px' }
								}
							>
								<span>
									{!loading ? (
										address?.street_number ? (
											address.street_number
										) : (
											'-'
										)
									) : (
										<LoadingRectangle />
									)}
								</span>
							</div>
						</S.Col2>
						<S.Col2 style={{ display: 'flex', width: '100%' }}>
							<div>
								<h5>Complemento</h5>
							</div>
							<div>
								<span>
									{!loading ? (
										address?.complementary ? (
											address.complementary
										) : (
											'-'
										)
									) : (
										<LoadingRectangle />
									)}
								</span>
							</div>
						</S.Col2>
					</div>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Bairro</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							address?.neighborhood ? (
								address.neighborhood
							) : (
								'-'
							)
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
			</S.Row>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Cidade</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							address?.city ? (
								address.city
							) : (
								'-'
							)
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Estado</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							address?.state ? (
								address.state
							) : (
								'-'
							)
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>CEP</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							address?.zipcode ? (
								address.zipcode
							) : (
								'-'
							)
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
			</S.Row>
		</S.Container>
	);
};

export default CardClient;
