import React from 'react';
import { colors } from '../../../assets';

const ChevronLeftIcon = ({ title, fill, style }) => (
	<svg
		width="7"
		height="10"
		viewBox="0 0 7 10"
		style={style}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="chevron-icon"
	>
		<title>{title || 'Ícone de seta'}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.03033 0.46967C6.32322 0.762563 6.32322 1.23744 6.03033 1.53033L2.56066 5L6.03033 8.46967C6.32322 8.76256 6.32322 9.23744 6.03033 9.53033C5.73744 9.82322 5.26256 9.82322 4.96967 9.53033L0.96967 5.53033C0.676777 5.23744 0.676777 4.76256 0.96967 4.46967L4.96967 0.46967C5.26256 0.176777 5.73744 0.176777 6.03033 0.46967Z"
			fill={fill || colors.primaryBlue}
		/>
	</svg>
);
export default ChevronLeftIcon;
