import React from 'react';

const TransactionSwitchGraphicIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		title={title}
		width="100%"
		height="100%"
		viewBox="8 -4 4 30"
		fill={fill || 'none'}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M17.8569 5.71436L11.0711 12.5001L7.49972 8.92864L2.14258 14.2858M17.8569 5.71436H13.5711M17.8569 5.71436V10.0001"
			stroke="#191919"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default TransactionSwitchGraphicIcon;
