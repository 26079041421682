import React from 'react';
import { colors } from '../colors';

const AddPersonIcon = ({ title, fill, style }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={style}
		className="antifraude-icon"
	>
		<title>{title || 'Ícone de antifraude'}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.3961 5.18147C15.8367 5.0664 12.0263 1.50586 12.0263 1.50586C12.0263 1.50586 8.24873 5.0697 2.69256 5.18476C2.61366 8.47244 3.53421 19.2724 12.0427 22.7771C20.5512 19.2593 21.4717 8.45928 21.3961 5.18147Z"
			stroke={fill || colors.lightGray}
			strokeWidth="1.3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.7539 12L11.5233 14.7694L16.7922 9.5"
			stroke={fill || colors.lightGray}
			strokeWidth="1.3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default AddPersonIcon;
