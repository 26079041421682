import React from 'react';
import { colors } from '../colors';

const NumeroTransacoes = ({ title, fill, style }) => (
	<svg
		style={style}
		className="ticket-icon"
		width="56"
		height="56"
		viewBox="0 0 56 56"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || 'Ícone do número de transações'}</title>
		<circle cx="28" cy="28" r="28" fill="url(#paint0_linear_3359_14326)" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.0727 30.4456C17.9925 30.6391 17.9482 30.8512 17.9482 31.0736C17.9482 31.2961 17.9925 31.5082 18.0727 31.7017C18.1528 31.8952 18.2715 32.0764 18.4288 32.2337L22.9325 36.7375C23.5732 37.3782 24.612 37.3782 25.2527 36.7375C25.8934 36.0968 25.8934 35.058 25.2527 34.4173L23.5497 32.7143L36.3171 32.7143C37.2232 32.7143 37.9577 31.9797 37.9577 31.0736C37.9577 30.1676 37.2232 29.433 36.3171 29.433L19.5889 29.433C19.3664 29.433 19.1543 29.4773 18.9609 29.5575C18.7674 29.6376 18.5861 29.7563 18.4288 29.9135C18.2715 30.0708 18.1528 30.2521 18.0727 30.4456ZM37.4761 23.6713L32.9738 19.169C32.3331 18.5283 31.2943 18.5283 30.6536 19.169C30.0129 19.8097 30.0129 20.8485 30.6536 21.4892L32.3566 23.1922L19.5893 23.1922C18.6832 23.1922 17.9486 23.9268 17.9486 24.8329C17.9486 25.7389 18.6832 26.4735 19.5893 26.4735L36.3153 26.4735C36.321 26.4735 36.3268 26.4735 36.3326 26.4734C36.7474 26.4696 37.1611 26.3094 37.4776 25.9929C37.6349 25.8356 37.7535 25.6544 37.8336 25.4609C37.9138 25.2674 37.9581 25.0553 37.9581 24.8328C37.9581 24.3853 37.7788 23.9795 37.4882 23.6835C37.4847 23.6799 37.4811 23.6763 37.4776 23.6728L37.4761 23.6713Z"
			fill="#E2F9FF"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_3359_14326"
				x1="0"
				y1="0"
				x2="56"
				y2="56"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor={fill || colors.primaryBlue} />
				<stop offset="1" stopColor="#3090A8" />
			</linearGradient>
		</defs>
	</svg>
);
export default NumeroTransacoes;
