import styled from 'styled-components';

import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 2.5rem 1.5rem;

	.checkbox-custom {
		display: flex;
		justify-content: flex-start;
		height: fit-content;
		color: ${({ checked }) =>
			checked ? colors.primaryBlue : colors.lightGray};
	}

	.label-underline-input {
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 1.3125rem;
	}

	.custom-select-input {
		border-bottom: 1px solid ${colors.primaryBlue};
		border-radius: 0;
	}
`;

export const InputGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.625rem;
	margin-bottom: 3.5rem;
`;

export const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 4.5rem;

	.aditional-form {
		display: ${({ checked }) => (checked ? 'flex' : 'none')};
	}
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	justify-flex: flex-start;
	margin-bottom: 3em;
`;

export const Title = styled.h2`
	font-family: Jost;
	font-style: normal;
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 2rem;
	letter-spacing: 0.04em;
	color: ${colors.offWhite};
	justify-content: flex-start;
	text-align: left;
	margin-bottom: 0.5rem;
`;

export const Subtitle = styled.h3`
	color: ${colors.lightGray};
	font-size: 1.25rem;
	font-weight: 400;
`;

export const TitleInput = styled.h4`
	color: ${colors.offWhite};
	font-size: 1rem;
	font-style: normal;
	font-weight: normal;
	padding-bottom: 1rem;
`;

export const RadioGroup = styled.div`
	display: block;
	margin-bottom: 2rem;
`;

export const Label = styled.label`
	display: inline-block;
	margin-right: 2.5rem;
	margin-left: 1rem;
	font-size: 1.25rem;
	line-height: 1.625rem;
	color: ${colors.lightGray};
`;

export const RowsGroup = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 4.625rem;
`;
export const Row = styled.div`
	display: flex;
	gap: 2rem;
	margin-bottom: 2rem;
	div:first-child {
		width: 20.5rem;
	}
`;

export const ButtonGroup = styled.div`
	display: flex;
	margin-top: auto;
	width: 100%;
	button {
		width: 12.5rem;
		height: 3.3125rem;
	}
`;

export const RightButtons = styled.div`
	display: flex;
	gap: 1rem;
	margin-left: auto;
`;

export const ButtonsTabs = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 28rem;
	height: 2.1875rem;
	background: ${colors.gray};
	border: 1px solid ${colors.gray};
	border-radius: 0.5rem;
	padding: 1px;
	box-shadow: inset 0px 0px 7px rgba(0, 0, 0, 0.6);
	margin-bottom: 4.5rem;

	${({ selected }) =>
		selected === 'start'
			? '#inicio { margin-left: -2px; }'
			: '#final { margin-right: -2px; }'};
`;

export const Button = styled.button`
	background: none;
	font-size: 0.875rem;
	font-weight: 600;
	letter-spacing: 0.02em;
	padding: 0 0.625rem;
	position: relative;
	text-align: center;
	text-transform: none;
	color: ${colors.lightGray};
	border-radius: 0.5rem;
	width: 50%;

	:hover {
		color: ${({ selected }) => !selected && colors.primaryBlue};
	}

	${({ selected }) =>
		selected &&
		`
		font-weight: 600;
		color: ${colors.background};
		background: ${colors.primaryBlue};
		border: 1px solid ${colors.darkTurquoise};
		animation: aparecer 0.4s ease-out;
		backdrop-filter: blur(10px);
		height: 2.1875rem;
		`};

	:disabled {
		opacity: 0.3;
	}
`;
