import React from 'react';
// import { colors } from '../colors';

const ArrowUpDown = () => (
	// { title, fill, style }
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.3158 18.3242C8.76691 17.828 9.53488 17.7914 10.0311 18.2425L15.8929 23.5714L21.7546 18.2425C22.2508 17.7914 23.0188 17.828 23.4699 18.3242C23.9211 18.8204 23.8845 19.5884 23.3883 20.0395L15.8929 26.8535L8.39748 20.0395C7.90125 19.5884 7.86468 18.8204 8.3158 18.3242Z"
			fill="#63D2ED"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.3158 13.5293C8.76691 14.0255 9.53488 14.0621 10.0311 13.611L15.8929 8.28212L21.7546 13.611C22.2508 14.0621 23.0188 14.0255 23.4699 13.5293C23.9211 13.0331 23.8845 12.2651 23.3883 11.814L15.8929 5L8.39748 11.814C7.90125 12.2651 7.86468 13.0331 8.3158 13.5293Z"
			fill="#63D2ED"
		/>
	</svg>
);
export default ArrowUpDown;
