import { axiosApp } from '.';

const getPaymentLinks = (
	recipientId,
	name = '',
	status,
	dataInicial,
	dataFinal
) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(
				`/payment-links?recipientId=${recipientId}&name=${name}&status=${status}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`
			)
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

const getPaymentLink = (PaymentLinkId, recipientId) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(`/payment-links/${PaymentLinkId}?recipientId=${recipientId}`)
			.then((resp) => resolve(resp.data))
			.catch((e) => reject(e));
	});
};

const getOrdensPaymentLink = (PaymentLinkId, recipientId) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(
				`/payment-links/${PaymentLinkId}/orders?recipientId=${recipientId}&idPaymentLink=${PaymentLinkId}`
			)
			.then((resp) => resolve(resp.data))
			.catch((e) => reject(e));
	});
};

const putDisablePaymentLink = (paymentLinkId, recipientId) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.put(`/payment-links/${paymentLinkId}/cancel?recipientId=${recipientId}`)
			.then((e) => resolve(e))
			.catch((e) => reject(e));
	});
};

const postPaymentLink = (recipientId, data) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.post(`/payment-links/${recipientId}?recipientId=${recipientId}`, data)
			.then((resp) => resolve(resp.data))
			.catch((e) => reject(e));
	});
};

export {
	postPaymentLink,
	getOrdensPaymentLink,
	getPaymentLinks,
	getPaymentLink,
	putDisablePaymentLink,
};
