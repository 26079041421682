import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ButtonCustom, CustomInput, ValidarForcaSenha } from '../../components';
import { userRegister } from '../../services';
import { Icons } from '../../assets';
import * as s from './styled-invite';

const invite = () => {
	const history = useHistory();
	const query = history.location.search;
	const [token, setToken] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [nome, setNome] = useState('');
	const [mail, setMail] = useState('');
	const [passwordStrength, setPasswordStrength] = useState();
	const [invalid, setInvalid] = useState(false);
	const [loading, setLoading] = useState(false);

	function isNotValid() {
		if (
			!mail ||
			!nome ||
			password !== confirmPassword ||
			!password.trim() ||
			!confirmPassword.trim() ||
			passwordStrength < 91 ||
			password.length < 8 ||
			confirmPassword.length < 8
		)
			return true;
		return false;
	}

	function signUpUser() {
		setLoading(true);

		userRegister(mail, password, nome, token)
			.then(() => {
				toast.success('Usuário cadastrado com sucesso!');
				history.push('/');
			})
			.catch(() =>
				toast.error(
					'Ocorreu um erro ao tentar cadastrar ou já possui um usuário com o e-mail informado'
				)
			)
			.finally(() => setLoading(false));
	}

	function getParameters() {
		const nameValue = query
			.split('name=')[1]
			.split('&email=')[0]
			.replace(/%20/g, ' ');
		const emailValue = query.split('email=')[1].split('&token=')[0];
		const tokenValue = query.split('token=')[1];
		setNome(nameValue);
		setMail(emailValue);
		setToken(tokenValue);
	}

	useEffect(() => {
		getParameters();
	}, []);

	useEffect(() => {
		if (
			password &&
			confirmPassword &&
			password !== confirmPassword &&
			password.trim() &&
			confirmPassword.trim()
		) {
			setInvalid(true);
		} else if (invalid) setInvalid(false);
	}, [password, confirmPassword]);

	return (
		<s.Container>
			<s.Card>
				<s.InviteHeader>
					<s.Button onClick={() => history.push('/login')}>
						<Icons.Logotipo title="Voltar ao login" />
					</s.Button>
					<h1>Cadastro de senha de acesso</h1>
				</s.InviteHeader>
				<s.InviteForm>
					<form>
						<label htmlFor="email">E-mail</label>
						<CustomInput
							type="email"
							name="e-mail"
							id="email"
							placeholder="nome@email.com.br"
							onChange={(e) => setMail(e.target.value)}
							value={mail}
							readOnly
						/>

						<label htmlFor="name">Nome completo</label>
						<CustomInput
							type="text"
							name="name"
							id="name"
							placeholder="Nome completo imutável"
							onChange={(e) => setNome(e.target.value)}
							value={nome}
						/>
						<label htmlFor="password">Senha</label>
						<div id="validar-senha">
							<ValidarForcaSenha
								senha={password}
								strength={passwordStrength}
								setStrength={setPasswordStrength}
							>
								<CustomInput
									type="password"
									name="senha"
									id="password"
									onChange={(e) => setPassword(e.target.value)}
									value={password}
								/>
							</ValidarForcaSenha>
						</div>

						<s.TitleDiv>
							<label htmlFor="confirm-password">Confirmar nova senha</label>
							{invalid && <s.Error>As senhas devem ser iguais*</s.Error>}
						</s.TitleDiv>

						<CustomInput
							type="password"
							name="confirma_senha"
							id="confirm-password"
							onChange={(e) => setConfirmPassword(e.target.value)}
							value={confirmPassword}
							isInvalid={invalid}
						/>
						<ButtonCustom
							onClick={signUpUser}
							disabled={isNotValid()}
							isLoading={loading}
						>
							CADASTRAR
						</ButtonCustom>
					</form>
				</s.InviteForm>
			</s.Card>
		</s.Container>
	);
};
export default invite;
