import React from 'react';
import { colors } from '../colors';

const TicketMedioIcon = ({ title, fill, fillBackground, style }) => (
	<svg
		style={style}
		className="ticket-icon"
		width="56"
		height="56"
		viewBox="0 0 56 56"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || 'Ícone de ticket médio'}</title>
		<circle
			cx="28"
			cy="28"
			r="28"
			fill={fillBackground || colors.lightTurquoise}
		/>
		<g clipPath="url(#clip0_3359_14244)">
			<path
				d="M20.2842 24.3147H20.5579C21.6115 24.3147 22.4736 25.1768 22.4736 26.2304V35.8093C22.4736 36.8629 21.6115 37.725 20.5579 37.725H20.2842C19.2305 37.725 18.3684 36.8629 18.3684 35.8093V26.2304C18.3684 25.1768 19.2305 24.3147 20.2842 24.3147ZM27.9472 18.5674C29.0009 18.5674 29.863 19.4295 29.863 20.4831V35.8093C29.863 36.8629 29.0009 37.725 27.9472 37.725C26.8936 37.725 26.0315 36.8629 26.0315 35.8093V20.4831C26.0315 19.4295 26.8936 18.5674 27.9472 18.5674ZM35.6103 29.5146C36.664 29.5146 37.526 30.3767 37.526 31.4304V35.8093C37.526 36.8629 36.664 37.725 35.6103 37.725C34.5566 37.725 33.6945 36.8629 33.6945 35.8093V31.4304C33.6945 30.3767 34.5566 29.5146 35.6103 29.5146Z"
				fill={fill || colors.primaryBlue}
			/>
		</g>
		<defs>
			<clipPath id="clip0_3359_14244">
				<rect
					width="32.8417"
					height="32.8417"
					fill="white"
					transform="translate(11.5264 11.7246)"
				/>
			</clipPath>
		</defs>
	</svg>
);
export default TicketMedioIcon;
