import React from 'react';
import { colors } from '../colors';

const Logotipo = ({ title, fill, style }) => (
	<svg 		
    style={style}
    className="logotipo" 
    width="160" 
    height="72" 
    viewBox="0 0 160 72" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >

		<title>{title || ''}</title>
		<g clipPath="url(#clip0)">
      <path 
        d="M9.46399 61.728V23.648H3.35999V61.728H9.46399ZM30.8 36.528C30.8 33.6533 30.2213 31.208 29.064 29.192C27.9067 27.176 26.376 25.6453 24.472 24.6C22.568 23.5173 20.496 22.976 18.256 22.976C16.0533 22.976 14.1307 23.536 12.488 24.656C10.8827 25.7386 9.63199 27.288 8.73599 29.304C7.87732 31.32 7.44799 33.728 7.44799 36.528C7.44799 39.2906 7.87732 41.6987 8.73599 43.752C9.63199 45.768 10.8827 47.336 12.488 48.456C14.1307 49.5387 16.0533 50.08 18.256 50.08C20.496 50.08 22.568 49.5573 24.472 48.512C26.376 47.4293 27.9067 45.88 29.064 43.864C30.2213 41.8106 30.8 39.3653 30.8 36.528ZM24.528 36.528C24.528 38.2453 24.1733 39.72 23.464 40.952C22.7547 42.1466 21.8027 43.0613 20.608 43.696C19.4507 44.2933 18.1627 44.592 16.744 44.592C15.5867 44.592 14.448 44.2933 13.328 43.696C12.208 43.0613 11.2747 42.1466 10.528 40.952C9.81865 39.7573 9.46399 38.2826 9.46399 36.528C9.46399 34.7733 9.81865 33.2986 10.528 32.104C11.2747 30.9093 12.208 30.0133 13.328 29.416C14.448 28.7813 15.5867 28.464 16.744 28.464C18.1627 28.464 19.4507 28.7813 20.608 29.416C21.8027 30.0133 22.7547 30.928 23.464 32.16C24.1733 33.3546 24.528 34.8106 24.528 36.528Z" 
        fill={fill || colors.primaryBlue}
      />
			<path 
        d="M40.3685 41.4C40.3685 40.5786 40.5552 39.888 40.9285 39.328C41.3392 38.768 41.9365 38.3386 42.7205 38.04C43.5045 37.704 44.5312 37.536 45.8005 37.536C47.2565 37.536 48.6379 37.7226 49.9445 38.096C51.2512 38.4693 52.4832 39.0666 53.6405 39.888V36.808C53.3046 36.3973 52.7259 35.9493 51.9045 35.464C51.0832 34.9413 50.0379 34.4933 48.7685 34.12C47.5365 33.7466 46.0619 33.56 44.3445 33.56C41.1339 33.56 38.6512 34.3066 36.8965 35.8C35.1419 37.256 34.2645 39.2346 34.2645 41.736C34.2645 43.4906 34.6752 44.984 35.4965 46.216C36.3179 47.448 37.4192 48.3813 38.8005 49.016C40.1819 49.6507 41.6752 49.968 43.2805 49.968C44.7739 49.968 46.2299 49.7066 47.6485 49.184C49.1045 48.624 50.2992 47.8027 51.2325 46.72C52.2032 45.6 52.6885 44.2186 52.6885 42.576L51.7925 39.216C51.7925 40.4853 51.4752 41.6053 50.8405 42.576C50.2432 43.5093 49.4405 44.2187 48.4325 44.704C47.4619 45.1893 46.3792 45.432 45.1845 45.432C44.2512 45.432 43.4112 45.2826 42.6645 44.984C41.9552 44.6853 41.3952 44.2373 40.9845 43.64C40.5739 43.0053 40.3685 42.2586 40.3685 41.4ZM38.4645 30.368C38.8379 30.1066 39.3979 29.7893 40.1445 29.416C40.9285 29.0053 41.8432 28.6693 42.8885 28.408C43.9712 28.1093 45.1099 27.96 46.3046 27.96C47.0886 27.96 47.8165 28.0346 48.4885 28.184C49.1605 28.3333 49.7392 28.576 50.2245 28.912C50.7099 29.248 51.0832 29.6773 51.3445 30.2C51.6432 30.7226 51.7925 31.3946 51.7925 32.216V49.408H57.7285V31.04C57.7285 29.3226 57.2619 27.8853 56.3285 26.728C55.3952 25.5333 54.1072 24.6186 52.4646 23.984C50.8592 23.3493 48.9925 23.032 46.8645 23.032C44.4752 23.032 42.3472 23.368 40.4805 24.04C38.6512 24.712 37.1579 25.4026 36.0005 26.112L38.4645 30.368Z" 
        fill={fill || colors.primaryBlue}
			/>
			<path
				d="M87.2979 23.648H80.8019L72.7379 43.864L75.5939 44.032L67.3619 23.648H60.4179L70.7779 46.384L64.0579 61.728H70.4979L87.2979 23.648Z"
				fill={fill || colors.primaryBlue}
			/>
			<path
				d="M91.5919 29.808C91.5919 26.2613 92.3199 23.1253 93.7759 20.4C95.2693 17.6746 97.3226 15.528 99.9359 13.96C102.549 12.3546 105.517 11.552 108.84 11.552C112.237 11.552 115.224 12.3546 117.8 13.96C120.413 15.528 122.448 17.6746 123.904 20.4C125.36 23.1253 126.088 26.2613 126.088 29.808C126.088 33.3173 125.36 36.4533 123.904 39.216C122.448 41.9413 120.413 44.1066 117.8 45.712C115.224 47.28 112.237 48.064 108.84 48.064C105.517 48.064 102.549 47.28 99.9359 45.712C97.3226 44.1066 95.2693 41.9413 93.7759 39.216C92.3199 36.4533 91.5919 33.3173 91.5919 29.808ZM89.6879 29.808C89.6879 32.6826 90.1546 35.352 91.0879 37.816C92.0586 40.28 93.4026 42.408 95.1199 44.2C96.8373 45.992 98.8533 47.392 101.168 48.4C103.52 49.408 106.077 49.912 108.84 49.912C111.64 49.912 114.197 49.408 116.512 48.4C118.827 47.392 120.843 45.992 122.56 44.2C124.315 42.408 125.659 40.28 126.592 37.816C127.563 35.352 128.048 32.6826 128.048 29.808C128.048 26.896 127.563 24.2266 126.592 21.8C125.659 19.336 124.315 17.208 122.56 15.416C120.843 13.624 118.827 12.224 116.512 11.216C114.197 10.208 111.64 9.70398 108.84 9.70398C106.077 9.70398 103.52 10.208 101.168 11.216C98.8533 12.224 96.8373 13.624 95.1199 15.416C93.4026 17.208 92.0586 19.336 91.0879 21.8C90.1546 24.2266 89.6879 26.896 89.6879 29.808Z"
				fill={fill || colors.primaryBlue}
			/>
			<path
				d="M134.953 38.488L133.441 39.384C133.889 41.2506 134.617 42.9866 135.625 44.592C136.633 46.1973 137.958 47.4853 139.601 48.456C141.243 49.4266 143.241 49.912 145.593 49.912C147.235 49.912 148.729 49.6507 150.073 49.128C151.417 48.6053 152.555 47.896 153.489 47C154.459 46.0666 155.206 44.9653 155.729 43.696C156.251 42.4267 156.513 41.0453 156.513 39.552C156.513 37.872 156.214 36.416 155.617 35.184C155.057 33.952 154.31 32.9066 153.377 32.048C152.443 31.1893 151.398 30.4613 150.241 29.864C149.121 29.2666 148.001 28.744 146.881 28.296C144.902 27.5866 143.166 26.8026 141.673 25.944C140.217 25.0853 139.059 24.0773 138.201 22.92C137.379 21.7253 136.969 20.288 136.969 18.608C136.969 16.4426 137.678 14.7253 139.097 13.456C140.553 12.1866 142.494 11.552 144.921 11.552C146.75 11.552 148.281 11.888 149.513 12.56C150.745 13.232 151.734 14.072 152.481 15.08C153.265 16.088 153.843 17.1146 154.217 18.16L155.897 17.264C155.449 16.1066 154.758 14.9493 153.825 13.792C152.891 12.6346 151.697 11.664 150.241 10.88C148.785 10.096 147.03 9.70398 144.977 9.70398C143.073 9.70398 141.374 10.0773 139.881 10.824C138.387 11.5706 137.211 12.6346 136.353 14.016C135.531 15.36 135.121 16.9466 135.121 18.776C135.121 20.3813 135.419 21.7626 136.017 22.92C136.614 24.0773 137.417 25.0853 138.425 25.944C139.47 26.7653 140.609 27.4933 141.841 28.128C143.073 28.7253 144.305 29.2666 145.537 29.752C147.03 30.312 148.467 30.984 149.849 31.768C151.23 32.552 152.369 33.5786 153.265 34.848C154.161 36.1173 154.609 37.7413 154.609 39.72C154.609 42.1466 153.825 44.1627 152.257 45.768C150.689 47.336 148.486 48.12 145.649 48.12C143.483 48.12 141.691 47.672 140.273 46.776C138.854 45.88 137.715 44.704 136.857 43.248C136.035 41.7547 135.401 40.168 134.953 38.488Z"
				fill={fill || colors.primaryBlue}
			/>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="160" height="72" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default Logotipo;

