import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	max-width: 575px;
`;

export const Title = styled.h2`
	font-size: 1.5rem;
	color: ${colors.white};
	margin: 10px 0 30px 0;
`;

export const Message = styled.p`
	font-size: 1.25rem;
	color: ${colors.offWhite};
	font-weight: 300;
`;

export const Question = styled.p`
	font-size: 1.25rem;
	color: ${colors.offWhite};
	font-weight: 300;
	margin-top: 20px;
`;

export const GroupButtons = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin: 20px 0 10px 0;
`;
