import styled from 'styled-components';
import { colors } from '../../../../assets';

export const ContainerForm = styled.div`
	display: flex;
	flex-direction: column;
	padding: 3rem 2rem;
	background-color: ${colors.lightBlack};
	border-radius: 0.5rem;
`;

export const CloseButtonDiv = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: flex-start;
	.close-button {
		border: 0px;
	}
`;

export const SubTotalDiv = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: flex-end;
	color: ${colors.lightGray};
	font-size: 1.25rem;
`;

export const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	.underline-input {
		padding: 1rem;
		width: 20.5rem;
	}
`;

export const Row = styled.div`
	display: flex;
	gap: 2rem;
	margin-bottom: 2rem;
`;

export const UnderlineInput = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.625rem;
	font-family: 'IBM Plex Sans';
	font-size: '1rem';
	font-weight: 400;
	line-height: '1.3rem';
	color: ${colors.white};
	p {
		@media (max-width: 1366px) {
			font-size: 1rem;
		}
	}
`;

export const SelectContainer = styled.div`
	display: flex;
	margin: 4.125rem 2rem 0 2rem;
	width: 132px;

	.select-type-item {
		border: 0.0625rem solid ${colors.offWhite};
		border-radius: 0.5rem;
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
	}
`;
