import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 2;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 0rem 1.625rem 1.875rem 1.875rem;
	width: 100%;
	height: auto;

	span.subtitle-text {
		font-size: 1rem;
		color: ${colors.lightestGray};
	}
`;

export const CustomToolbar = styled.div`
	display: flex;
	width: 100%;
	align-content: center;
	align-items: center;
	padding: 1.25rem 0rem;

	@media (max-width: 1500px) {
		padding: 1.25rem 1.25rem;
	}
	@media (max-width: 1050px) {
		padding: 1.25rem 1.75rem;
	}
	.button-custom {
		margin-right: 0.9375rem;
		width: auto;
		height: 50px;
		white-space: nowrap;
	}
`;

export const InfoTransactions = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	color: ${colors.offWhite};
	font-size: 1.25rem;
	margin-right: auto;
	width: 100%;
	justify-content: flex-start;
	gap: 8rem;
	div {
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: flex-start;
		gap: 0.5rem;
	}
	h4 {
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: normal;
		flex-wrap: nowrap;
		word-wrap: normal;
		color: #eff5f5;
	}
	p {
		font-family: IBM Plex Sans;
		font-style: Regular;
		font-size: 20px;
		color: ${colors.lightGray};
	}
	@media all and (max-width: 820px) {
		font-size: 1rem;
	}
`;

export const SelectDiv = styled.div`
	div > {
		border-radius: 15px;
	}
`;

export const Content = styled.table`
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
`;

export const GraphContent = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	height: 100%;
	gap: 10rem;
	@media (max-width: 1366px) {
		gap: 6rem;
	}
`;

export const Data = styled.div`
	display: flex;
	align-items: center;
	gap: 0.625rem;
	flex-wrap: wrap;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	p {
		font-size: 1rem;
		font-weight: 400;
		line-height: 2rem;
		margin-left: 0.3125rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	p.data {
		line-height: 0.9375rem;
		margin-top: 0;
		padding: 0;
	}

	p.bold {
		font-weight: 600;
	}

	svg.seta-curva-icon {
		height: 1.25rem;
		width: 1.25rem;
	}

	span {
		height: 0.9375rem;
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.3125rem;
	width: 100%;
	p.status {
		font-size: 1rem;
		font-weight: 400;
		line-height: 2rem;
		margin-left: 0.3125rem;
		color: ${({ value }) => {
			if (value === 'paid') {
				return colors.green;
			}
			if (value === 'authorized') {
				return colors.primaryBlue;
			}
			if (value === 'refused') {
				return colors.primaryRed;
			}
			return colors.white;
		}};
	}
	span {
		transform: translateY(1.25px);
	}
`;

export const LoadingContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 0.3125rem 0.3125rem;
	height: 100%;
	width: 100%;

	.loading-rectangle {
		height: 55px;
	}
`;

export const Pagination = styled.div`
	width: 22.5rem;
	display: flex;
	justify-content: center;
`;

export const Options = styled.div`
	width: 10rem;

	ul {
		list-style: none;
	}

	button {
		background: none;
		padding: 0.5rem 1rem;
		font-size: 0.875rem;
		width: 100%;

		:hover {
			background: rgba(0, 0, 0, 0.1);
		}
	}
`;

export const Header = styled.div`
	display: flex;
	width: 100%;
	align-content: center;
	align-items: center;
`;

export const ContentSubtitles = styled.div`
	display: flex;
	flex-direction: row !important;
	flex-wrap: wrap;
	padding-top: 1rem;
	gap: 1rem;
	margin-top: 1rem;
`;

export const LegendsGraph = styled.div`
	display: flex;
	flex-direction: column;
`;
