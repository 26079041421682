import background from './Background.jpg';
import nenhumRegistro from './nenhum-registro.png';
import pranchetaConcluido from './prancheta-concluido.png';
import frame from './Frame.png';

export const images = {
	background,
	nenhumRegistro,
	pranchetaConcluido,
	frame,
};
