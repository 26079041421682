import React, { useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { colors, Icons } from '../../../assets';

import * as s from './styled-custom-input';

const CustomInput = ({
	type,
	disabled,
	value,
	onChange,
	onFocus,
	onBlur,
	placeholder,
	required,
	isInvalid,
	readOnly,
	id,
	className,
	autoComplete,
	maxLength,
	border,
	max,
	min,
	onInput,
	title,
	onKeyPress,
	autoFocus,
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const inputType = () => {
		if (type === 'password' && !showPassword) return 'password';
		if (type !== 'text' && type !== 'password') return type;

		return 'text';
	};

	const icon = () => {
		return (
            <Tooltip
				title={showPassword ? 'Ocultar senha' : 'Mostrar senha'}
				PopperProps={{
					'& .MuiTooltip-tooltip': {
						border: `1px solid ${colors.lightBlack}`,
						color: `${colors.lightGray}`,
						backgroundColor: `${colors.darkGray}`,
						padding: '0.25rem 0.75rem',
						height: '1.5rem',
					},
				}}
				placement="top-end"
			>
				<IconButton size="large">
					{showPassword ? (
						<Icons.StripeEye fill={isInvalid && colors.red} />
					) : (
						<Icons.VisibleEye fill={isInvalid && colors.red} />
					)}
				</IconButton>
			</Tooltip>
        );
	};

	return (
		<s.Container className="container-custom-input" isInvalid={isInvalid}>
			<s.Input
				id={id}
				className={className || 'custom-input'}
				type={inputType()}
				readOnly={readOnly || false}
				disabled={disabled}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				onInput={onInput}
				border={border}
				placeholder={placeholder}
				autoComplete={autoComplete || 'off'}
				required={required || false}
				isInvalid={isInvalid}
				paddingRight={type === 'password'}
				maxLength={maxLength}
				max={max}
				min={min}
				title={title}
				onKeyPress={onKeyPress}
				autoFocus={autoFocus}
			/>
			{type === 'password' && (
				<s.IconWrapper onClick={() => setShowPassword(!showPassword)}>
					{icon()}
				</s.IconWrapper>
			)}
		</s.Container>
	);
};

export default CustomInput;
