import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import {
	getBanks,
	getRecipientBankAccounts,
	getRecipientDetails,
	updateRecipient,
} from '../services';
import { justNumbers } from '../utils';

const RecipientInfoContext = React.createContext();

export function useRecipientExtractInfo() {
	return useContext(RecipientInfoContext);
}

export function RecipientInfoProvider({ children }) {
	const { recipientId } = useParams();
	const [antecipacoes, setAntecipacoes] = useState({});
	const [balanco, setBalanco] = useState({});
	const [bancos, setBancos] = useState([]);
	const [extrato, setExtrato] = useState([]);
	const [recebedor, setRecebedor] = useState({});
	const [informacoesRecebedor, setInformacoesRecebedor] = useState({});
	const [banks, setBanks] = useState([]);

	const [pagination, setPagination] = useState();
	const [selectedTabOption, setSelectedTabOption] = useState('extrato');

	const [automaticAntecipationEnabled, setAutomaticAntecipationEnabled] =
		useState(false);
	const [transferInterval, setTransferInterval] = useState('daily');
	const [transferDay, setTransferDay] = useState(0);

	const [recipientBankAccounts, setRecipientBankAccounts] = useState([{}]);
	const [opcaoConta, setOpcaoConta] = useState('selecionar-conta');
	const [BankAccountId, setBankAccountId] = useState('');

	const [isLoadingAntecipacoes, setIsLoadingAntecipacoes] = useState(false);
	const [isLoadingBalanco, setIsLoadingBalanco] = useState(false);
	const [isLoadingExtrato, setIsLoadingExtrato] = useState(false);
	const [isLoadingRecebedor, setIsLoadingRecebedor] = useState(true);
	const [isBankOptionsLoading, setIsBankOptionsLoading] = useState(false);
	const [isRecipientUpdating, setIsRecipientUpdating] = useState(false);

	const handleSubmitRecipientUpdate = (body) => {
		setIsRecipientUpdating(true);
		updateRecipient(recipientId, body)
			.then(() => {
				toast.success('Dados atualizados com sucesso!');
				setAutomaticAntecipationEnabled(
					body.transfer.automaticAntecipationEnabled
				);
			})
			.catch(() => {
				toast.error('Erro ao atualizar dados do recebedor');
			})
			.finally(() => {
				setIsRecipientUpdating(false);
			});
	};

	useEffect(() => {
		const existemContasRecebedor = recipientBankAccounts.length > 0;
		if (existemContasRecebedor) {
			setOpcaoConta('selecionar-conta');
		} else {
			setOpcaoConta('adicionar-conta');
		}
	}, [recipientBankAccounts]);

	const value = {
		antecipacoes,
		setAntecipacoes,
		isLoadingAntecipacoes,
		setIsLoadingAntecipacoes,
		bancos,
		setBancos,
		informacoesRecebedor,
		setInformacoesRecebedor,
		balanco,
		setBalanco,
		extrato,
		setExtrato,
		pagination,
		setPagination,
		isLoadingRecebedor,
		setIsLoadingRecebedor,
		isLoadingBalanco,
		setIsLoadingBalanco,
		isLoadingExtrato,
		setIsLoadingExtrato,
		selectedTabOption,
		setSelectedTabOption,
		isBankOptionsLoading,
		setIsBankOptionsLoading,
		isRecipientUpdating,
		recebedor,
		setRecebedor,
		automaticAntecipationEnabled,
		setAutomaticAntecipationEnabled,
		transferInterval,
		setTransferInterval,
		transferDay,
		setTransferDay,
		recipientBankAccounts,
		setRecipientBankAccounts,
		opcaoConta,
		setOpcaoConta,
		BankAccountId,
		setBankAccountId,
		banks,
		recipientId,
		handleSubmitRecipientUpdate,
	};

	useEffect(() => {
		setIsLoadingRecebedor(true);
		getRecipientDetails(recipientId)
			.then((resp) => setRecebedor(resp))
			.catch(() => toast.error('Erro ao buscar os dados do recebedor'))
			.finally(() => setIsLoadingRecebedor(false));
	}, []);

	useEffect(() => {
		setIsBankOptionsLoading(true);
		getBanks()
			.then((resp) =>
				setBanks(
					resp.map((bank) => ({
						value: bank.code,
						label: `${bank.code} - ${bank.name}`,
						agencia: bank.agencia,
						agenciaDv: bank.agenciaDv,
						conta: bank.conta,
						contaDv: bank.contaDv,
						bankCode: bank.code,
					}))
				)
			)
			.finally(() => {
				setIsBankOptionsLoading(false);
			});

		if (!isLoadingRecebedor) {
			setBankAccountId('');
			getRecipientBankAccounts(
				1,
				justNumbers(recebedor?.BankAccount?.documentNumber),
				recipientId
			)
				.then((response) => {
					const bankAccountsValues = [];
					response.forEach((account) => {
						const bankName = banks?.find(
							(bank) => bank.value === account.bankCode
						);
						bankAccountsValues.push({
							label: `${account.legalName} - ${bankName.label} - ${
								account.agencia
							}${account.agenciaDv ? `-${account.agenciaDv}` : ''} - ${
								account.conta
							}-${account.contaDv}`,
							value: account.id,
							bankCode: account.bankCode,
							agencia: account.agencia,
							agenciaDv: account?.agenciaDv,
							conta: account.conta,
							contaDv: account.contaDv,
						});
					});
					setRecipientBankAccounts(bankAccountsValues);
				})
				.catch(() => {
					setRecipientBankAccounts([]);
				});
		}
	}, []);

	return (
		<RecipientInfoContext.Provider value={value}>
			{children}
		</RecipientInfoContext.Provider>
	);
}
