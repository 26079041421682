import styled from 'styled-components';
import { colors } from '../../../assets';

export const ContainerFloater = styled.div`
	display: flex;

	span:first-child {
		display: flex;
	}
`;

export const WrapperFloater = styled.div`
	display: flex;
`;

export const customStyles = (
	background,
	width,
	simbolicIcon,
	transform,
	textAlign,
	padding,
	titleColor,
	contentColor,
	fontSize,
	heigth
) => ({
	wrapper: {
		cursor: simbolicIcon ? 'default' : 'pointer',
		zIndex: 10,
		pointerEvents: simbolicIcon && 'none',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'center',
		borderRadius: '5px',
		zIndex: 10,
		padding: padding || 15,
		backgroundColor: background || colors.background,
		color: colors.offWhite,
		width: width || 'auto',
		transform: transform || 'none',
	},
	content: {
		fontSize: fontSize || '1rem',
		lineHeight: '1.56rem',
		fontWeight: 400,
		width: '100%',
		lineSpacing: '1px',
		textAlign: textAlign || 'justify',
		color: contentColor || colors.offWhite,
	},
	floater: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 10,
		heigth: heigth || 'auto',
	},
	arrow: {
		length: 10,
		position: 'absolute',
		spread: 15,
		color: background || colors.background,
	},
	title: {
		color: titleColor || colors.primaryRed,
		border: 0,
		fontSize: '1rem',
		fontWeight: 700,
		lineSpacing: '3px',
	},
});
