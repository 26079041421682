import styled from 'styled-components';
import { colors } from '../../../../../assets';

export const Container = styled.div`
	grid-area: ${(props) => (props.div ? props.div : 'none')};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	padding: 24px;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	min-height: 95px;
	height: 100%;
	width: 100%;
	margin-top: 2rem;
`;

export const ButtonsTabs = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	height: 3.13rem;
	background: ${colors.background};
	border-radius: 0.9375rem;
	padding: 1px;
	margin-bottom: 1.25rem;
	box-shadow: 0 0 5px rgba(177, 182, 182, 0.4);

	@keyframes aparecer {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes slideLeft {
		0% {
			transform: translateX(-40px);
		}
	}

	@keyframes slideRight {
		0% {
			transform: translateX(40px);
		}
	}

	.selected {
		font-weight: 600;
		color: ${colors.primaryBlue};
		background: radial-gradient(
			90.95% 100% at 66.86% 0%,
			rgba(46, 50, 51, 0.4) 0%,
			rgba(25, 25, 25, 0.4) 100%
		);
		backdrop-filter: blur(100px);
		border: 1px solid ${colors.darkGray};
		animation: aparecer 0.5s ease-out;
		backdrop-filter: blur(10px);
		height: calc(3.13rem - 0.25rem);
	}

	.anterior {
		animation: slideRight 0.4s ease-out;
	}

	.receber {
		animation: slideLeft 0.4s ease-out;
	}
`;

export const Divisao = styled.div`
	width: 100%;
	height: 2px;
	background: ${colors.gray};
`;

export const Button = styled.button`
	background: none;
	font-size: 1.25rem;
	font-weight: 400;
	letter-spacing: 0.02em;
	padding: 0 15px;
	position: relative;
	text-align: center;
	text-transform: none;
	color: ${colors.white};
	border-radius: 0.9375rem;

	:hover {
		color: ${colors.primaryBlue};
	}
`;

export const CardsDiv = styled.div`
	display: flex;
	flex: 1;
	align-items: stretch;
	flex-wrap: wrap;
	list-style: none;
	margin-top: 5px;
	.footer {
		justify-content: flex-end;
		width: 100%;
	}
`;

export const Card = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	position: relative;
	padding: 1.5rem;
	margin: 0 10px 20px 10px;
	min-width: 200px;
	border-radius: 15px;
	border: 2px solid
		${({ color }) =>
			color === 'green'
				? colors.green
				: color === 'red'
				? colors.primaryRed
				: colors.violet};

	h3 {
		font-size: 14px;
		font-weight: 500;
		color: ${({ color }) =>
			color === 'green'
				? colors.green
				: color === 'red'
				? colors.primaryRed
				: colors.violet};
	}

	span.valor {
		color: ${colors.white};
		font-size: 2.25rem;
		font-weight: 500;
	}

	:after {
		content: '';
		position: absolute;
		z-index: 1;
		width: 70%;
		height: 70%;
		background: ${({ color }) =>
			color === 'green'
				? colors.green
				: color === 'red'
				? colors.primaryRed
				: colors.violet};
		opacity: 0.2;
		filter: blur(50px);
		color: ${colors.white};
	}
`;

export const Content = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 3;

	.loading-rectangle {
		height: 30px;
		margin-top: 1.25rem;
	}

	h1 {
		margin-top: 24px;
		margin-bottom: 14px;
		font-size: 24px;
		color: #454550;
		font-weight: 700;
	}

	svg.question-icon {
		height: 1.25rem;
		width: 1.25rem;
	}
`;

export const Sinal = styled.span`
	display: inline-block;
	text-align: center;
	margin-top: 10px;
	color: ${({ negativo, neutro }) =>
		negativo ? colors.primaryRed : neutro ? colors.white : colors.green};
	font-weight: 600;

	svg.plus-icon {
		width: 1.88rem;
		height: 1.88rem;
		margin: 0 5px -4px 0;
	}
	svg.minus-icon {
		width: 1.88rem;
		height: 0.35125rem;
		margin: 0 5px 4px 0;
	}

	.cifrao {
		color: ${colors.white};
		font-size: 1.5rem;
	}
	.valor {
		font-family: Jost;
		color: ${colors.white};
		font-size: 2.25rem;
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;

	${({ headerTable }) =>
		headerTable &&
		`
	width: 100%;
	margin: 20px 0;
	justify-content: space-between;

	svg.upload-icon {
		height: 1.7rem;
		width: 1.25rem;
	}

`};
`;

export const Data = styled.p`
	color: ${colors.white};
	font-size: 1rem;
	font-weight: 300;
`;

export const Right = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;

	.button-custom {
		width: auto;
		padding: 0.375rem 1.5rem;
	}

	.select-react {
		width: 15.31rem;
		height: 3.13rem;

		@media (max-width: 960px) {
			width: calc(15.31rem + 10px);
		}
	}
`;

export const ButtonFilter = styled.button`
	border: none;
	color: ${colors.darkGray};
	margin-left: 10px;
	background: ${colors.primaryBlue};
	border-radius: 0.25rem;
	display: inline-block;
	font-size: 1.1rem;
	font-weight: 600;
	padding: 0.375rem 1rem;
	height: 2.7rem;

	:disabled {
		opacity: 0.6;
	}
`;

export const FilterTabs = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 7rem;
	width: 100%;
`;

export const Options = styled.div`
	width: 10rem;

	ul {
		list-style: none;
	}

	button {
		background: none;
		padding: 0.5rem 1rem;
		font-size: 0.875rem;
		width: 100%;

		:hover {
			background: rgba(0, 0, 0, 0.1);
		}
	}
`;

export const Footer = styled.div`
	display: flex;
	margin-top: 2rem;
	margin-bottom: 1rem;
`;
