import React from 'react';
import * as s from './styled-modal-confirmacao';
import ContainerModal from '../container-modal';
import { ButtonCustom } from '../../../../components';

const ModalConfirmacao = ({
	isOpen,
	closeModal,
	content,
	title,
	text,
	question,
	onPositivePress,
	negativeButtonLabel,
	positiveButtonLabel,
	isLoading,
}) => {
	return (
		<ContainerModal isOpen={isOpen} closeModal={closeModal}>
			<s.Container>
				{content || (
					<>
						{title && <s.Title>{title}</s.Title>}

						<s.Message>{text || 'Aqui vai a sua mensagem'}</s.Message>

						{question && <s.Question>{question}</s.Question>}

						<s.GroupButtons>
							<ButtonCustom
								outlined
								width="auto"
								disabled={isLoading}
								onClick={closeModal}
							>
								{negativeButtonLabel || 'Cancelar'}
							</ButtonCustom>
							<ButtonCustom
								width="auto"
								onClick={onPositivePress}
								isLoading={isLoading}
							>
								{positiveButtonLabel || 'Confirmar'}
							</ButtonCustom>
						</s.GroupButtons>
					</>
				)}
			</s.Container>
		</ContainerModal>
	);
};

export default ModalConfirmacao;
