import React, { useState } from 'react';
import { ButtonCustom, SearchField, FiltroCalendario } from '../../..';
import { colors } from '../../../../assets/colors';
import { padStart } from '../../../../utils';

import * as s from './styled-filter-log';

const FiltersLog = (props) => {
	const {
		logs,
		filtrarLogs,
		buscarLogs,
		isLoading,
		searchValue,
		setSearchValue,
		setPage,
		setLogsFiltrados,
		intervaloDatas,
		setIntervaloDatas,
	} = props;

	const disableCleanFilter = () => {
		if (searchValue === '') {
			return true;
		}
		return false;
	};

	const [dataInvalida, setDataInvalida] = useState({
		inicio: false,
		final: false,
	});

	const dataFormatada = () => {
		if (intervaloDatas?.from && intervaloDatas?.to) {
			const inicio = intervaloDatas.from;
			const final = intervaloDatas.to;
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(final.day)}/${padStart(final.month)}/${final.year}`,
			};
		}

		if (intervaloDatas?.day) {
			const inicio = intervaloDatas;

			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		if (intervaloDatas?.to) {
			const inicio = intervaloDatas.from;
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		return null;
	};

	const saveDateInStorage = () => {
		sessionStorage.setItem(
			'LogsInicialDate',
			JSON.stringify(intervaloDatas.from)
		);
		sessionStorage.setItem('LogsFinalDate', JSON.stringify(intervaloDatas.to));
	};

	const handleSubmitFilters = () => {
		setPage(1);
		buscarLogs();
		filtrarLogs();
	};

	return (
		<s.Container>
			<div className="calendar-input">
				<FiltroCalendario
					disabled={isLoading}
					formatedRangeDate={dataFormatada}
					selectedDayRange={intervaloDatas}
					setSelectedDayRange={setIntervaloDatas}
					dataInvalida={dataInvalida}
					setDataInvalida={setDataInvalida}
					selected="anterior"
					transform="translate(43%)"
					backgroundColor={colors.background}
					width="21rem"
					fontSizePlaceholder="1.5rem"
				/>
			</div>
			<SearchField
				value={searchValue}
				placeholder="Busque por nome, tipo de transação ou email"
				onChange={(e) => {
					setSearchValue(e.target.value);
					setPage(1);
				}}
				border={`1px solid ${colors.gray}`}
				onKeyPress={(e) => {
					if (e.key === 'Enter') {
						handleSubmitFilters();
					}
				}}
			/>
			<s.ButtonGroup>
				<ButtonCustom
					outlined
					theme={disableCleanFilter() ? 'gray' : 'green'}
					width="12.5rem"
					disabled={disableCleanFilter()}
					onClick={() => {
						setLogsFiltrados(logs);
						setSearchValue('');
					}}
					borderRadius=".9375rem"
				>
					Limpar busca
				</ButtonCustom>
				<ButtonCustom
					theme={isLoading ? 'gray' : 'green'}
					width="12.5rem"
					disabled={isLoading}
					onClick={() => {
						saveDateInStorage();
						handleSubmitFilters();
					}}
					borderRadius=".9375rem"
				>
					Buscar
				</ButtonCustom>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default FiltersLog;
