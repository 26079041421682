/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React from 'react';

import {
	ResponsiveContainer,
	Tooltip,
	Legend,
	RadialBarChart,
	RadialBar,
	PolarAngleAxis,
} from 'recharts';

import * as S from './styled-three-pie-charts';
import { colors } from '../../../../assets/colors';

const real = [{ name: 'Real', value: 99 }];
const noretries = [{ name: 'Sem retentativas', value: 95 }];
const boleto = [{ name: 'Boletos', value: 10 }];
const pix = [{ name: 'Pix', value: 50 }];

const style = {
	color: `${colors.white}`,
	fontStyle: 'normal',
	fontWeight: 'bold',
	fontSize: '0.875rem',
	lineHeight: '18px',
	textAlign: 'center',
};

export default function ThreePieCharts() {
	return (
		<S.Container>
			{/* gráfico 1 */}
			<ResponsiveContainer width="25%">
				<RadialBarChart innerRadius="70%" outerRadius="90%" data={real}>
					<PolarAngleAxis
						type="number"
						domain={[0, 100]}
						dataKey="value"
						angleAxisId={0}
						tick={false}
					/>
					<RadialBar
						label={{ fill: 'white', position: 'center' }}
						dataKey="value"
						background
						clockwise
						fill={colors.primaryBlue}
						barSize={10}
					/>
					<Tooltip />
					<Legend iconSize={0} wrapperStyle={style} />
				</RadialBarChart>
			</ResponsiveContainer>
			{/* gráfico 2 */}
			<ResponsiveContainer width="25%">
				<RadialBarChart innerRadius="70%" outerRadius="90%" data={noretries}>
					<PolarAngleAxis
						type="number"
						domain={[0, 100]}
						dataKey="value"
						angleAxisId={0}
						tick={false}
					/>
					<RadialBar
						label={{ fill: 'white', position: 'center' }}
						clockwise
						dataKey="value"
						fill={colors.primaryBlue}
						background
						barSize={10}
					/>
					<Tooltip />
					<Legend iconSize={0} wrapperStyle={style} />
				</RadialBarChart>
			</ResponsiveContainer>
			{/* gráfico 3 */}
			<ResponsiveContainer width="25%">
				<RadialBarChart
					innerRadius="70%"
					outerRadius="90%"
					data={boleto}
					fill={colors.primaryBlue}
				>
					<PolarAngleAxis
						type="number"
						domain={[0, 100]}
						dataKey="value"
						angleAxisId={0}
						tick={false}
					/>
					<RadialBar
						label={{ fill: 'white', position: 'center' }}
						background
						clockwise
						dataKey="value"
						formatter={(value) => `${value}%`}
						barSize={10}
					/>
					<Tooltip />
					<Legend iconSize={0} wrapperStyle={style} />
				</RadialBarChart>
			</ResponsiveContainer>
			{/* gráfico 4 */}
			<ResponsiveContainer width="25%">
				<RadialBarChart
					innerRadius="70%"
					outerRadius="90%"
					data={pix}
					fill={colors.primaryBlue}
				>
					<PolarAngleAxis
						type="number"
						domain={[0, 100]}
						dataKey="value"
						angleAxisId={0}
						tick={false}
					/>
					<RadialBar
						label={{ fill: 'white', position: 'center' }}
						background
						clockwise
						dataKey="value"
						formatter={(value) => `${value}%`}
						barSize={10}
					/>
					<Tooltip />
					<Legend iconSize={0} wrapperStyle={style} />
				</RadialBarChart>
			</ResponsiveContainer>
		</S.Container>
	);
}
