import { React } from 'react';
import { isValid } from 'date-fns';
import { Pagination } from '../../..';

import * as s from './styled-table-log';

const TableLog = (props) => {
	const {
		logs,
		logsFiltrados,
		itemsPerPage,
		isLoading,
		page,
		setPage,
		sortBy,
		sortByDate,
	} = props;

	return (
		<>
			{logs.length > 0 && (
				<s.WrapperTable>
					<s.Table>
						<s.Thead>
							<s.Tr top>
								<th onClick={() => sortBy('name')}>Nome</th>
								<th onClick={() => sortBy('acao')}>Tipo da transação</th>
								<th onClick={() => sortBy('email')}>E-mail</th>
								<th onClick={() => sortByDate()}>Data</th>
							</s.Tr>
						</s.Thead>
						<s.Tbody loading={isLoading.toString()}>
							{logsFiltrados
								.slice(
									(page === 0 ? page + 1 : page - 1) * itemsPerPage.value,
									page * itemsPerPage.value
								)
								.map((linha, i) => {
									const data = linha.data;
									return (
										<s.Tr key={i} bottom={i === logs.length - 1} index={i}>
											<s.Td>{linha.name || ''}</s.Td>
											<s.Td>{linha.acao}</s.Td>
											<s.Td minWidth="16rem">
												{linha.email ? linha.email : ''}
											</s.Td>
											<s.Td>
												{isValid(data)
													? new Date(data).toLocaleDateString('pt-BR')
													: new Date(Number(data)).toLocaleDateString(
															'pt-BR'
													  ) || '--/--/----'}
											</s.Td>
										</s.Tr>
									);
								})}
						</s.Tbody>
					</s.Table>
					<s.Footer>
						{Math.ceil(logsFiltrados.length / itemsPerPage.value) > 1 && (
							<Pagination
								paginaAtual={page - 1}
								totalPaginas={Math.ceil(
									logsFiltrados.length / itemsPerPage.value
								)}
								onClick={(pagina) => {
									setPage(pagina.selected + 1);
								}}
								disabled={isLoading}
							/>
						)}
					</s.Footer>
				</s.WrapperTable>
			)}
		</>
	);
};

export default TableLog;
