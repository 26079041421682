import React from 'react';
import RoundChart from '../pie-chart/pie-chart';

import * as S from './styled-card-tall';

const CardTall = (props) => {
	const { id, div, title, data, loading } = props;
	return (
		<S.Container id={id} div={div}>
			<S.Title>{title}</S.Title>
			<S.GraphContainer>
				<RoundChart title={title} data={data} loading={loading} />
			</S.GraphContainer>
		</S.Container>
	);
};

export default CardTall;
