import React from 'react';

const AcessosIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="acessos-icon"
		width="32"
		height="32"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
		fill={fill || 'currentcolor'}
	>
		<title>{title}</title>
		<g clipPath="url(#clip0)">
			<path d="M26.1818 31.698L24.4954 29.9781C24.3992 29.8856 24.3228 29.7748 24.2706 29.6523C24.2185 29.5298 24.1917 29.398 24.1918 29.2649V27.0331C24.1918 26.9219 24.1474 26.8152 24.0683 26.7365C23.9892 26.6579 23.882 26.6136 23.7702 26.6136H21.4851C21.3515 26.6129 21.2194 26.5858 21.0964 26.5339C20.9734 26.4821 20.8619 26.4065 20.7684 26.3116L19.5794 25.137C19.2625 24.8171 19.084 24.3864 19.082 23.9372V22.7206C19.0831 22.6575 19.0714 22.5948 19.0477 22.5363C19.0239 22.4777 18.9886 22.4246 18.9437 22.3799C18.8989 22.3353 18.8454 22.3001 18.7866 22.2764C18.7278 22.2528 18.6647 22.2413 18.6013 22.2424H17.3786C16.9272 22.2404 16.4944 22.0627 16.1729 21.7474L13.6432 19.2303H4.44373C3.26518 19.2303 2.13491 18.7645 1.30154 17.9353C0.46818 17.1061 0 15.9814 0 14.8087V4.45528C0 3.2826 0.46818 2.15796 1.30154 1.32875C2.13491 0.499534 3.26518 0.0336609 4.44373 0.0336609H14.8743C16.0529 0.0336609 17.1831 0.499534 18.0165 1.32875C18.8499 2.15796 19.318 3.2826 19.318 4.45528V13.9361L31.7049 26.2697C31.8927 26.4635 31.9984 26.7219 32 26.9912V31.0017C32 31.135 31.9736 31.267 31.9223 31.3902C31.8711 31.5133 31.7959 31.6253 31.7011 31.7196C31.6064 31.8138 31.494 31.8886 31.3702 31.9396C31.2464 31.9906 31.1137 32.0169 30.9797 32.0169H26.8985C26.7636 32.0149 26.6304 31.9856 26.5072 31.9308C26.384 31.876 26.2733 31.7968 26.1818 31.698V31.698ZM7.5552 5.15168C7.5552 4.65385 7.40684 4.16718 7.12888 3.75325C6.85092 3.33933 6.45583 3.01672 5.9936 2.82621C5.53137 2.6357 5.02275 2.58586 4.53205 2.68298C4.04134 2.7801 3.59061 3.01983 3.23683 3.37185C2.88306 3.72386 2.64212 4.17237 2.54452 4.66063C2.44691 5.14889 2.497 5.65495 2.68847 6.11488C2.87993 6.5748 3.20415 6.96793 3.62015 7.2445C4.03615 7.52108 4.52524 7.66872 5.02555 7.66872C5.69646 7.66872 6.33987 7.4035 6.81427 6.93146C7.28867 6.45942 7.5552 5.81924 7.5552 5.15168V5.15168Z" />
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="32" height="32" />
			</clipPath>
		</defs>
	</svg>
);

export default AcessosIcon;
