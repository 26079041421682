/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { LoadingRectangle } from '../../../../components';

import * as S from './styled-transaction-details';

const CardTransactionDetails = (props) => {
	const {
		id,
		div,
		title,
		loading,
		acquirerName,
		captureMethod,
		acquirerResponseCode,
		nsu,
		tid,
		authorizationCode,
		description,
		signature,
	} = props;
	return (
		<S.Container id={id} div={div}>
			<S.Title>{title}</S.Title>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Operadora do cartão</h5>
					</S.ColTitle>
					<span>{!loading ? acquirerName || '-' : <LoadingRectangle />}</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Resposta da operadora</h5>
					</S.ColTitle>
					<span>
						{!loading ? acquirerResponseCode || '-' : <LoadingRectangle />}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Código de autorização</h5>
					</S.ColTitle>
					<span>
						{!loading ? authorizationCode || '-' : <LoadingRectangle />}
					</span>
				</S.Col>
			</S.Row>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Método de captura</h5>
					</S.ColTitle>
					<span>{!loading ? captureMethod || '-' : <LoadingRectangle />}</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>NSU</h5>
					</S.ColTitle>
					<span>{!loading ? nsu || '-' : <LoadingRectangle />}</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Descrição da fatura</h5>
					</S.ColTitle>
					<span>{!loading ? description || '-' : <LoadingRectangle />}</span>
				</S.Col>
			</S.Row>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>ID da assinatura</h5>
					</S.ColTitle>
					<span>{!loading ? signature || '-' : <LoadingRectangle />}</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>TID (ID da transação)</h5>
					</S.ColTitle>
					<span>{!loading ? tid : <LoadingRectangle />}</span>
				</S.Col>
			</S.Row>
		</S.Container>
	);
};

export default CardTransactionDetails;
