/* eslint-disable import/no-cycle */
import { axiosApp } from '.';

const getBanks = (signal) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/banks`, { signal })
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getBankAccountTypes = (signal) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/banks/account-types`, { signal })
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

export { getBanks, getBankAccountTypes };
