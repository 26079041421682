import styled from 'styled-components';
import { colors } from '../../../../../../assets';

export const WrapperTable = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Table = styled.table`
	font-size: 1rem;
`;

export const Thead = styled.thead`
	color: ${colors.primaryBlue};
	background: ${colors.background};
	font-weight: bold;
	height: 3.75rem;

	tr {
		color: ${colors.primaryBlue};

		th {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			border-left: 1px solid ${colors.borderTable};
			height: 100%;
			padding: 10px;
		}

		th:last-child {
			border-right: 1px solid ${colors.borderTable};
		}
	}
`;

export const Tr = styled.tr`
	min-height: 3.75rem;
	align-items: center;
	display: grid;
	grid-template-columns: ${({ columns }) =>
		columns && `repeat(${columns}, calc(100% / ${columns}))`};
	color: ${colors.white};
	${({ top, bottom }) =>
		top
			? `border-top: 1px solid ${colors.borderTable};`
			: bottom && `border-bottom: 1px solid ${colors.borderTable}`};

	td:last-child {
		border-right: 1px solid ${colors.borderTable};
	}
`;

export const Td = styled.td`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-left: 1px solid ${colors.borderTable};
	padding: 10px;
	height: 100%;
	color: ${({ blue }) => blue && colors.primaryBlue};
	font-weight: 400;

	${({ blue, clicavel }) =>
		clicavel
			? `cursor: pointer;
		text-decoration: none;

		:hover {
			text-decoration: underline;
		}`
			: blue && 'pointer-events: none'};
`;

export const Tbody = styled.tbody`
	tr {
		:nth-child(odd) {
			background: ${colors.gridBlack};
		}
		:nth-child(even) {
			background: ${colors.rowTable};
		}
	}

	tr:first-child {
		margin-top: -2px;
	}
`;

export const Data = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	flex-wrap: wrap;

	p.data {
		font-size: 1rem;
		line-height: 0.9375rem;
		margin-top: 0;
		padding: 0;
	}

	p.bold {
		font-weight: 600;
	}

	svg.seta-curva-icon {
		height: 1.25rem;
		width: 1.25rem;
	}

	span {
		height: 0.9375rem;
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	width: 100%;

	svg.plus-icon {
		width: 0.75rem;
		height: 0.75rem;
	}

	svg.minus-icon {
		width: 0.75rem;
		height: 0.75rem;
	}

	p.total {
		color: ${({ sinal }) => (sinal === '+' ? colors.green : colors.primaryRed)};
	}
`;

export const LoadingContainer = styled.div`
	display: grid;
	align-items: flex-start;
	grid-template-columns: ${({ columns }) =>
		columns && `repeat(${columns}, 1fr)`};
	gap: 5px;
	height: 100%;
	width: 100%;
	padding: 0 2px;

	.loading-rectangle {
		height: 55px;
	}
`;

export const Empty = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100px;
	border: 1px solid ${colors.borderTable};
	border-top: none;
	margin-top: -2px;

	span {
		color: ${colors.white};
		font-size: 1rem;
		font-weight: 300;
		font-family: Jost;
	}
`;
