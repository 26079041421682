import React, { useState } from 'react';
import {
	ContractorSteps,
	ContractorContent,
} from '../../components/organisms/new-contractor-components';

import * as s from './styled-new-contractor';

const NewContractor = () => {
	const [steps, setSteps] = useState(1);
	const [loading, setLoading] = useState(false);
	return (
		<s.Container>
			<s.Steps>
				<ContractorSteps steps={steps} />
			</s.Steps>
			<s.Form steps={steps}>
				<ContractorContent
					setSteps={setSteps}
					steps={steps}
					setLoading={setLoading}
					loading={loading}
				/>
			</s.Form>
		</s.Container>
	);
};

export default NewContractor;
