import React from 'react';
// import { colors } from '../assets';

const UnHappySearch = () => (
	// { title, fill, fillBackground, style }
	<svg
		width="146"
		height="146"
		viewBox="0 0 146 146"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g filter="url(#filter0_d_5834_20805)">
			<path
				d="M73 109C95.0914 109 113 91.0914 113 69C113 46.9086 95.0914 29 73 29C50.9086 29 33 46.9086 33 69C33 91.0914 50.9086 109 73 109Z"
				stroke="#63D2ED"
				strokeWidth="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M57 85C57 85 63 77 73 77C83 77 89 85 89 85"
				stroke="#63D2ED"
				strokeWidth="7.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M61 57H61.0382"
				stroke="#63D2ED"
				strokeWidth="10.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M85 57H85.0382"
				stroke="#63D2ED"
				strokeWidth="10.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<filter
				id="filter0_d_5834_20805"
				x="0"
				y="0"
				width="146"
				height="146"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="14" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.388235 0 0 0 0 0.823529 0 0 0 0 0.929412 0 0 0 0.6 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_5834_20805"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_5834_20805"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);
export default UnHappySearch;
