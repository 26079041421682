import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	height: 100%;

	color: ${colors.white};
	background-color: ${colors.darkGray};
	padding: 18px 35px 37px 42px;

	.loading-rectangle {
		height: 40px;
		width: calc(100% - 1rem);
		padding-top: 0.5rem;
	}
`;

export const Title = styled.h1`
	font-size: 1em;
	padding-bottom: 16px;

	font-family: IBM Plex Sans;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: ${colors.white};
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	width: calc(100% / 3);

	span {
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 1.125em;
		line-height: 32px;
		color: ${colors.offWhite};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

export const Col2 = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;

	span {
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 1.125em;
		color: ${colors.offWhite};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	h5 {
		font-size: 1em;
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: 400;
		line-height: 21px;
	}
`;
export const ColTitle = styled.div`
	justify-content: center;
	h5 {
		font-size: 1em;
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: 400;
		line-height: 21px;
		:first-of-type {
			margin-right: 50px;
		}
	}
`;
export const ColAddress = styled.div`
	span {
		:first-of-type {
			margin-right: ${({ loading }) => (loading === true ? 0 : '60px')};
		}
	}
`;
