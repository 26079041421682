/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { utils } from '@amir04lm26/react-modern-calendar-date-picker';
import * as s from './styled-infos-table';
import Tabela from './tabela/tabela';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { colors, Icons } from '../../../../../assets';
import {
	ButtonCustom,
	FiltroCalendario,
	FloaterReact,
	LoadingRectangle,
	Pagination,
	SelectReact,
} from '../../../../../components';
import {
	formatarParaReais,
	padStart,
	adicionarDiasCalendario,
} from '../../../../../utils';
import { useRecipientExtractInfo } from '../../../../../hooks';

const InfosTable = ({
	intervaloInicial,
	selectedDayRange,
	setSelectedDayRange,
	buscarExtrato,
}) => {
	const {
		extrato,
		isLoadingExtrato,
		pagination,
		setPagination,
	} = useRecipientExtractInfo();
	const { discountsValue, grossValue, netValue, taxValue, data } = extrato;

	const optionsItemsPage = [
		{
			label: '15 itens por página',
			value: 15,
		},
		{
			label: '30 itens por página',
			value: 30,
		},
		{
			label: '60 itens por página',
			value: 60,
		},
		{
			label: '100 itens por página',
			value: 100,
		},
	];

	const [itemsPage, setItemsPage] = useState(optionsItemsPage[0]);

	const cards = [
		{
			type: 'green',
			titulo: 'Total de entradas',
			id: 'total-entradas',
			content: 'Todos os lançamentos de vendas brutas',
			valor: grossValue,
			color: colors.green,
		},
		{
			type: 'red',
			titulo: 'Total de saídas',
			id: 'total-saidas',
			content: 'Todos os lançamentos de transferências, chargebacks e estornos',
			valor: discountsValue,
			color: colors.primaryRed,
		},
		{
			type: 'red',
			titulo: 'Taxas PayOS',
			id: 'taxas-pagarme',
			content:
				'Todos os descontos de tarifas de cartão, gateway, boleto e TEDs',
			valor: taxValue,
			color: colors.primaryRed,
		},
		{
			type: 'purple',
			titulo: 'Total líquido',
			id: 'total-liquido',
			content:
				'Todos os lançamentos de vendas descontando as taxas, chargebacks e estornos',
			valor: netValue,
			color: colors.violet,
		},
	];

	const [selected, setSelected] = useState('anterior');

	const [dataInvalida, setDataInvalida] = useState({
		inicio: false,
		final: false,
	});

	const desabilitado =
		isLoadingExtrato ||
		dataInvalida?.inicio ||
		dataInvalida?.final ||
		extrato?.length < 1 ||
		!extrato;

	const exportarExtrato = (csv) => {
		const botaoExportar = document.getElementById(
			`baixar-extrato-${csv ? 'csv' : 'xlsx'}`
		);
		if (botaoExportar) botaoExportar.click();
	};

	const opcoesExportacao = () => (
		<s.Options>
			<ul>
				<li>
					<button
						type="button"
						onClick={() => exportarExtrato(true)}
						disabled={desabilitado}
					>
						CSV
					</button>
				</li>
				<li>
					<button
						type="button"
						onClick={() => exportarExtrato(false)}
						disabled={desabilitado}
					>
						Excel
					</button>
				</li>
			</ul>
		</s.Options>
	);

	const valorEstilizado = (valor, fill) => {
		if (valor) {
			const negativo = valor < 0;
			let valorVisual = valor;

			if (negativo) {
				valorVisual = -1 * valor;
			}

			const valorFormatado = formatarParaReais(valorVisual / 100);

			return (
				<s.Sinal negativo={negativo} neutro={valor === 0}>
					{negativo ? <Icons.Minus fill={fill} /> : <Icons.Plus fill={fill} />}
					<span className="cifrao"> R$ </span>
					<span className="valor">{valorFormatado}</span>
				</s.Sinal>
			);
		}

		return (
			<s.Sinal neutro>
				<span className="cifrao"> R$ </span>
				<span className="valor">0,00</span>
			</s.Sinal>
		);
	};

	const dataFormatada = () => {
		if (selectedDayRange?.from && selectedDayRange?.to) {
			const inicio = selectedDayRange.from;
			const final = selectedDayRange.to;
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(final.day)}/${padStart(final.month)}/${final.year}`,
			};
		}

		if (selectedDayRange?.day) {
			const inicio = selectedDayRange;

			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		if (selectedDayRange?.to) {
			const inicio = selectedDayRange.from;
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		return null;
	};

	const dataSelecionada = () => {
		const msg = dataFormatada();

		if (msg === null) return 'Selecione o período de filtragem';
		if (msg.to) return `${msg.from} - ${msg.to}`;
		return `${msg.from}`;
	};

	useEffect(() => {
		if (itemsPage?.value) buscarExtrato(1, itemsPage?.value);
	}, [itemsPage]);

	return (
		<s.Container>
			<s.ButtonsTabs>
				<s.Button
					className={selected === 'anterior' ? 'selected anterior' : ''}
					onClick={() => {
						setSelected('anterior');
						setSelectedDayRange(intervaloInicial());
						setItemsPage(optionsItemsPage[0]);
					}}
					disabled={isLoadingExtrato}
				>
					Anteriores
				</s.Button>
				<s.Button
					className={selected === 'receber' ? 'selected receber' : ''}
					onClick={() => {
						setSelected('receber');
						setSelectedDayRange(adicionarDiasCalendario(7));
						setItemsPage(optionsItemsPage[0]);
					}}
					disabled={isLoadingExtrato}
				>
					A receber
				</s.Button>
			</s.ButtonsTabs>

			<s.Divisao />
			<s.FilterTabs>
				<FiltroCalendario
					disabled={isLoadingExtrato}
					formatedRangeDate={dataFormatada}
					selectedDayRange={selectedDayRange}
					setSelectedDayRange={setSelectedDayRange}
					maximumDate={selected === 'anterior' ? utils().getToday() : null}
					minimumDate={selected === 'receber' ? utils().getToday() : null}
					dataInvalida={dataInvalida}
					setDataInvalida={setDataInvalida}
					selected={selected}
				/>
				<s.ButtonFilter
					onClick={() => buscarExtrato('', itemsPage.value)}
					disabled={
						isLoadingExtrato || dataInvalida?.inicio || dataInvalida?.final
					}
				>
					Filtrar
				</s.ButtonFilter>
			</s.FilterTabs>
			<s.CardsDiv>
				{cards.map((card) => (
					<s.Card key={card.id} color={card.type}>
						<s.Content>
							<s.Row>
								<h3>{card.titulo}</h3>
								<FloaterReact
									hover
									id={card.id}
									content={card.content}
									width="200px"
								>
									<Icons.Question />
								</FloaterReact>
							</s.Row>

							{isLoadingExtrato ? (
								<LoadingRectangle />
							) : (
								valorEstilizado(card.valor, card.color)
							)}
						</s.Content>
					</s.Card>
				))}

				<s.Row headerTable>
					<s.Data>{dataSelecionada()}</s.Data>

					<s.Right>
						<FloaterReact
							placement="bottom"
							background={colors.white}
							padding="0"
							content={opcoesExportacao()}
						>
							<ButtonCustom
								textTransform="capitalize"
								startIcon={<Icons.Upload />}
								disabled={desabilitado}
							>
								Exportar tabela
							</ButtonCustom>
						</FloaterReact>
						<SelectReact
							options={optionsItemsPage}
							value={itemsPage}
							onChange={(opt) => {
								setItemsPage(opt);
								setPagination({ ...pagination, page: 1 });
							}}
							readOnly={desabilitado}
							outlined
						/>
						{pagination?.totalPages > 1 && (
							<Pagination
								paginaAtual={pagination?.page - 1}
								totalPaginas={pagination?.totalPages}
								onClick={(pagina) =>
									buscarExtrato(pagina.selected + 1, itemsPage.value)
								}
								disabled={desabilitado}
							/>
						)}
					</s.Right>
				</s.Row>

				<Tabela dados={data} loadingExtrato={isLoadingExtrato} />
				<s.Row className="footer">
					<s.Footer>
						{pagination?.totalPages > 1 && (
							<Pagination
								paginaAtual={pagination?.page - 1}
								totalPaginas={pagination?.totalPages}
								onClick={(pagina) =>
									buscarExtrato(pagina.selected + 1, itemsPage.value)
								}
								disabled={desabilitado}
							/>
						)}
					</s.Footer>
				</s.Row>
			</s.CardsDiv>
		</s.Container>
	);
};

export default InfosTable;
