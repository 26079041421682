import styled from 'styled-components';
import { colors } from '../../../assets/colors';

const optionColors = {
	green: colors.graphs.green,
	red: colors.graphs.red,
	yellow: colors.graphs.yellow,
	purple: colors.graphs.purple,
	white: colors.graphs.white,
	darkBlue: colors.graphs.blue,
	pink: colors.graphs.pink,
	lightBlue: colors.graphs.cyan,
	orange: colors.graphs.orange,
};

const backgroundColors = {
	green: colors.graphsRgba.green,
	red: colors.graphsRgba.red,
	yellow: colors.graphsRgba.yellow,
	purple: colors.graphsRgba.purple,
	white: colors.graphsRgba.white,
	darkBlue: colors.graphsRgba.blue,
	pink: colors.graphsRgba.pink,
	lightBlue: colors.graphsRgba.cyan,
	orange: colors.graphsRgba.orange,
};

export const Subtitle = styled.span`
	color: ${({ color }) => optionColors[color] || colors.gray};
	border: 1px solid ${({ color }) => optionColors[color] || colors.gray};
	font-size: 1.5rem;
	background-color: ${({ color }) => backgroundColors[color] || colors.gray};
	width: max-content;
	height: 2rem;
	padding: 0.5rem 1rem;
	border-radius: 2rem;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1rem;
`;
