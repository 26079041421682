/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { ButtonCustom, FloaterReact } from '../../../atoms';
import { colors, Icons } from '../../../../assets';
import { ModalAvisoDesativacaoPaymentLink } from '../../../molecules';

import { putDisablePaymentLink } from '../../../../services';

import * as s from './styled-payment-details-header';

const DetailsHeader = ({ dados, recipientId, paymentLinkId }) => {
	const { name, status, url } = dados;
	const [open, setOpen] = useState(false);
	const [floaterIsOpen, setFloaterIsOpen] = useState(false);
	const [strokeIcon, setStrokeIcon] = useState(false);

	const tooltipRef = useRef(null);

	const handleFloaterVisibility = () => {
		setFloaterIsOpen(true)

		setTimeout(() => {
			setFloaterIsOpen(false);
		}, 2500)
	}

	const StatusStyle = (value) => {
		if (value === 'inactive') {
			return (
				<p className="status" style={{ color: 'red', border: 0 }}>
					Inativo
				</p>
			);
		}
		if (value === 'active') {
			return (
				<p className="status" style={{ color: `${colors.green}`, border: 0 }}>
					Ativo
				</p>
			);
		}
		if (value === 'refused') {
			return (
				<p className="status" style={{ color: `${colors.red}`, border: 0 }}>
					Recusada
				</p>
			);
		}
		if (value === 'processing') {
			return (
				<p className="status" style={{ color: `${colors.yellow}`, border: 0 }}>
					Processando
				</p>
			);
		}
		if (value === 'canceled') {
			return (
				<p className="status" style={{ color: `${colors.red}`, border: 0 }}>
					Cancelado
				</p>
			);
		}

		if (value === 'expired') {
			return (
				<p className="status" style={{ color: 'yellow', border: 0 }}>
					Expirado
				</p>
			);
		}
	};

	const disableLink = () => {
		putDisablePaymentLink(paymentLinkId, recipientId)
			.then(() => {
				toast.success('Link desativado com sucesso!');
				window.location.reload()
			})
			.catch(() => toast.error('Erro ao desativar o link'));
	};

	const handleClickOutside = () => {
    setFloaterIsOpen(false);
  };
	useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

	return (
		<s.Container ref={tooltipRef}>
			<s.Between>
				<s.Column>
					<s.TextContainer>
						<p className="title">Nome do Link</p>
						<p className="text">{name}</p>
					</s.TextContainer>

					<s.TextContainer>
						<p className="title">Status</p>
						{StatusStyle(status)}
					</s.TextContainer>

					<s.TextContainer floaterIsOpen={floaterIsOpen}>
						<p className="title">Url do link</p>

						<div id="link-container">
								<FloaterReact 
									isOpen={floaterIsOpen} 
									hover={floaterIsOpen} 
									content="Link Copiado" >
										<CopyToClipboard text={url}>
												<button
													type="button"
													className="text"
													onClick={() => handleFloaterVisibility()}
													onMouseOver={() => setStrokeIcon(true)}
													onMouseLeave={() => setStrokeIcon(false)}
												>
														{url}
												</button>
										</CopyToClipboard>
								</FloaterReact>

							<div id='icons-container'>
								<div id='icon-1'>
									<Icons.ChekedCircle />
								</div>

								<div id='icon-2'>
									<Icons.Copy stroke={strokeIcon && colors.primaryBlue} />
								</div>
							</div>
						</div>
					</s.TextContainer>
				</s.Column>

				{status === 'active' && (
					<div>
						<ButtonCustom
							outlined
							disableParsedHover
							width="12rem"
							height="2.75rem"
							className="Desativar"
							textTransform="none"
							onClick={() => setOpen(true)}
						>
							<Icons.DesativarLink /> Desativar Link
						</ButtonCustom>
					</div>
				)}
			</s.Between>

			<ModalAvisoDesativacaoPaymentLink
				isOpen={open}
				closeModal={() => setOpen(false)}
				disableLink={() => disableLink()}
				paymentLinkId={paymentLinkId}
			/>
		</s.Container>
	);
};

export default DetailsHeader;
