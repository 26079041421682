import React from 'react';
import { colors } from '../../../assets';

const TransferenciaIcon = ({ title, fill, style }) => (
	<svg
		className="antecipacao-icon"
		style={style}
		width="17"
		height="15"
		viewBox="0 0 17 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			d="M4.20855 1.95416C4.50145 1.66127 4.50145 1.18639 4.20855 0.893498C3.91566 0.600605 3.44079 0.600605 3.14789 0.893498L1.14789 2.8935C0.854999 3.18639 0.854999 3.66126 1.14789 3.95416L3.14789 5.95416C3.44079 6.24705 3.91566 6.24705 4.20855 5.95416C4.50145 5.66127 4.50145 5.18639 4.20855 4.8935L3.48888 4.17383H13.6782C14.0924 4.17383 14.4282 3.83804 14.4282 3.42383C14.4282 3.00961 14.0924 2.67383 13.6782 2.67383H3.48888L4.20855 1.95416Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			d="M13.1479 8.8935C12.855 9.18639 12.855 9.66127 13.1479 9.95416L13.8676 10.6738H3.67822C3.26401 10.6738 2.92822 11.0096 2.92822 11.4238C2.92822 11.838 3.26401 12.1738 3.67822 12.1738H13.8676L13.1479 12.8935C12.855 13.1864 12.855 13.6613 13.1479 13.9542C13.4408 14.2471 13.9157 14.2471 14.2086 13.9542L16.2086 11.9542C16.5014 11.6613 16.5014 11.1864 16.2086 10.8935L14.2086 8.8935C13.9157 8.60061 13.4408 8.60061 13.1479 8.8935Z"
			fill={fill || colors.primaryBlue}
		/>
	</svg>
);
export default TransferenciaIcon;
