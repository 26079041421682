import React from 'react';
import { Loader } from '../..';
import { colors } from '../../../assets';

import * as s from './styled-loader-center-circle';

function LoaderCenterCircle() {
	return (
		<s.ContainerLoader>
			<Loader color={colors.primaryBlue} />
		</s.ContainerLoader>
	);
}

export default LoaderCenterCircle;
