import React, { useState } from 'react';
import { passwordResetEmail } from '../../services';
import * as s from './styled-forgot-password';
import { Icons, colors } from '../../assets';
import {
	ButtonCustom,
	FloaterReact,
	CustomInput,
	ModalMessage,
} from '../../components';
import { validateEmail } from '../../utils';

const ForgotPassword = () => {
	const [openModal, setOpenModal] = useState(false);
	const [openFloater, setOpenFloater] = useState(false);
	const [email, setEmail] = useState('');
	const emailInvalido = validateEmail(email);
	const [EmailTyped, setEmailTyped] = useState(false);
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const error = [400, 404].includes(message?.type);

	function tipoErro(erro) {
		switch (erro) {
			case 'auth/user-not-found':
				return { type: 404, text: 'Usuário não encontrado.' };
			case 'auth/invalid-email':
				return {
					type: 400,
					text: 'E-mail incorreto! Corrija-o e tente novamente.',
				};
			default:
				return { type: 500, texte: 'Erro ao verificar e-mail :(' };
		}
	}

	const validateFloaterOpen = () => {
		if (openFloater) return setOpenFloater(false);
		return setOpenFloater(true);
	};

	function handleSubmit(event) {
		event.preventDefault();

		setLoading(true);
		passwordResetEmail(email)
			.then(() => {
				setOpenModal(true);
				setMessage();
			})
			.catch((e) => setMessage(tipoErro(e.code)))
			.finally(() => setLoading(false));
	}

	return (
		<s.Container onClick={() => openFloater && validateFloaterOpen()}>
			<ModalMessage isOpen={openModal} closeModal={() => setOpenModal(false)} />

			<s.Card>
				<Icons.Logotipo />
				<s.Form onSubmit={handleSubmit}>
					<s.FormGroup>
						<h1>Recuperação de Senha</h1>
						<p>
							Para prosseguirmos com a recuperação da sua senha, por favor
							insira seu <span>e-mail cadastrado no PayOS</span>
						</p>
						<s.TitleDiv>
							<s.FormLabel htmlFor="email">
								E-mail
								<FloaterReact
									isOpen={openFloater}
									hover={openFloater}
									width="15.31rem"
									background={colors.lightBlack}
									textAlign="center"
									placement="bottom"
									content="Caso você não possua mais acesso ao e-mail vinculado à sua conta payOS, entre em contato com a nossa Central de Atendimento através do e-mail suporte@payos.app"
								>
									<ButtonCustom
										onClick={() => validateFloaterOpen()}
										startIcon={<Icons.Info />}
										backgroundColor="transparent"
										width={8}
										height={8}
										className="info-button"
										disableParsedHover
									/>
								</FloaterReact>
							</s.FormLabel>
							{((EmailTyped && emailInvalido && email) || error) && (
								<s.Error>
									{error ? `${message.text}*` : 'E-mail incorreto*'}
								</s.Error>
							)}
						</s.TitleDiv>

						<CustomInput
							id="email"
							type="email"
							value={email}
							isInvalid={(EmailTyped && emailInvalido && email) || error}
							onChange={(e) => {
								setEmail(e.target.value);
								if (error) setMessage({});
							}}
							onBlur={() => setEmailTyped(true)}
							onFocus={() => setEmailTyped(false)}
							maxLength={80}
							required
							placeholder="Digite aqui o seu e-mail cadastrado"
						/>
					</s.FormGroup>
					<ButtonCustom
						id="recuperar-senha-enviar"
						type="submit"
						disabled={emailInvalido || !email}
						isLoading={loading}
					>
						ENVIAR
					</ButtonCustom>
					<ButtonCustom
						backgroundColor={colors.background}
						outlined
						onClick={() => window.history.back()}
					>
						VOLTAR AO LOGIN
					</ButtonCustom>
				</s.Form>
				{!error && message?.type === 500 && (
					<s.Span>
						<Icons.Attention />
						{message.text}
					</s.Span>
				)}
			</s.Card>
		</s.Container>
	);
};
export default ForgotPassword;
