import React from 'react';

const Diners = ({ title, style }) => (
	<svg
		width="26"
		height="16"
		style={style}
		viewBox="0 0 26 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="diners-icon"
	>
		<title>{title || 'Ícone Diners'}</title>
		<rect
			x="0.15"
			y="0.15"
			width="25.7"
			height="15.7"
			rx="1.85"
			fill="#FAFAFA"
			stroke="#B1B6B6"
			strokeWidth="0.3"
		/>
		<mask
			id="mask0_3866_15297"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="6"
			y="2"
			width="14"
			height="12"
		>
			<path d="M6 2H20V14H6V2Z" fill="white" />
		</mask>
		<g mask="url(#mask0_3866_15297)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.1033 14.0003C8.7555 14.0156 6 11.3265 6 8.05244C6 4.47432 8.7555 1.99934 12.1033 2H13.6725C16.9805 1.99934 20 4.47299 20 8.05244C20 11.3248 16.9805 14.0003 13.6725 14.0003H12.1033Z"
				fill="#004C97"
			/>
		</g>
		<mask
			id="mask1_3866_15297"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="6"
			y="2"
			width="13"
			height="12"
		>
			<path d="M6 2H18.3949V14H6V2Z" fill="white" />
		</mask>
		<g mask="url(#mask1_3866_15297)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.1033 14.0003C8.7555 14.0156 6 11.3265 6 8.05244C6 4.47432 8.7555 1.99934 12.1033 2H12.1912C15.4988 1.99934 18.3949 4.47299 18.3949 8.05244C18.3949 11.3248 15.4988 14.0003 12.1912 14.0003H12.1033Z"
				fill="#004C97"
			/>
		</g>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.1168 2.49609C9.05753 2.49709 6.57861 4.95552 6.57861 7.98935C6.57861 11.0225 9.05753 13.4806 12.1168 13.481C15.1764 13.481 17.6556 11.0225 17.6566 7.98935C17.6556 4.95519 15.1761 2.49676 12.1168 2.49609Z"
			fill="#FEFEFE"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.8594 11.238V4.7409C9.54281 5.24383 8.60927 6.50713 8.60596 7.99013C8.60927 9.47247 9.54314 10.7364 10.8594 11.238ZM15.6285 7.99013C15.6259 6.50647 14.691 5.24152 13.3731 4.74023V11.2397C14.691 10.7378 15.6259 9.47412 15.6282 7.99013"
			fill="#004C97"
		/>
	</svg>
);
export default Diners;
