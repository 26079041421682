import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import * as s from './styled-confirmar-antecipacao';
import RecebedorAntecipacoes from '../dados-antecipacoes/recebedor-antecipacoes/recebedor-antecipacoes';
import {
	ButtonCustom,
	CustomMonetaryValue,
	UnderlineInput,
} from '../../../../components';
import { colors, Icons } from '../../../../assets';
import {
	postConfirmarAntecipacao,
	loginWithoutAuthPersist,
} from '../../../../services';
import { useAntecipacao, useAuth } from '../../../../hooks';

const confirmarAntecipacao = ({ history, setOpenModalDeletar }) => {
	const { currentUser } = useAuth();
	const {
		idRecebedor,
		idAntecipacao,
		step,
		setStep,
		taxaTransf,
		valores,
		dataFormatadaAntecipacao,
		ordemAntecipacao,
		setTimerConfirmarAntecipacao,
	} = useAntecipacao();
	const [loading, setLoading] = useState(false);
	const [senha, setSenha] = useState('');
	const [erroSenha, setErroSenha] = useState('');
	const [resposta, setResposta] = useState({});
	const success = resposta?.type === 'success';

	const mensagem = () => {
		if (step === 2) return 'O valor da antecipação será depositado em:';
		if (success)
			return 'Assim que aprovado, o valor será depositado a partir das 16 horas do dia selecionado.';
		return 'Tente novamente ou entre em contato com os administradores.';
	};

	const cards = [
		{
			label: 'Data da antecipação',
			data: dataFormatadaAntecipacao() || '00/00/0000',
			color: 'blue',
		},
		{ label: 'Valor bruto', valor: valores?.valorBruto || 0, color: 'green' },
		{
			label: 'Total de taxas',
			valor: valores?.taxaAntecipacao + taxaTransf || 0,
			color: 'red',
		},
		{
			label: 'Valor a ser antecipado',
			valor: valores?.valorAntecipacao + taxaTransf || 0,
			color: 'green',
		},
	];

	const Notificacao = ({ type = 'success', text }) => {
		return (
			<s.Message type={type}>
				{type === 'success' ? (
					<Icons.Checked fill={colors.background} />
				) : (
					<Icons.InfoIconOutlined fill={colors.background} />
				)}
				<p>{text}</p>
			</s.Message>
		);
	};

	const validarSenha = () => {
		return new Promise((resolve, reject) => {
			loginWithoutAuthPersist(currentUser.email, senha, true)
				.then(() => resolve())
				.catch((e) => {
					setErroSenha(e.msg);
					reject();
				});
		});
	};

	const confirmarSenhaAntecipacao = () => {
		setLoading(true);

		validarSenha()
			.then(() => {
				postConfirmarAntecipacao(idRecebedor, idAntecipacao)
					.then(() => {
						setTimerConfirmarAntecipacao(false);
						localStorage.removeItem('anticipation');
						setResposta({
							type: 'success',
							text: 'Pedido confirmado! Sua antecipação será analisada em breve.',
						});
					})
					.catch(() => {
						setResposta({
							type: 'error',
							text: 'Houve um erro ao confirmar a antecipação.',
						});
					})
					.finally(() => {
						setLoading(false);
						setStep(3);
					});
			})
			.catch(() => setLoading(false));

		return;
	};

	useEffect(() => {
		if (erroSenha) setErroSenha('');
	}, [senha]);

	return (
		<s.SegundoPasso>
			{step === 3 && resposta && (
				<Notificacao type={resposta?.type} text={resposta?.text} />
			)}
			<s.Content>
				<s.Line>
					<p>{mensagem()}</p>
					{step === 3 && (
						<ButtonCustom
							outlined
							startIcon={<Icons.Graph />}
							onClick={() => history.push('/extrato')}
						>
							Ver no extrato
						</ButtonCustom>
					)}
				</s.Line>
				<RecebedorAntecipacoes step={2} />
				<s.CardsDiv>
					{cards.map((card, index) => (
						<s.Card key={card.label} color={card.color}>
							<label>{card.label}</label>
							{index === 0 ? (
								<span id="data">{card.data}</span>
							) : (
								<CustomMonetaryValue valor={card.valor} />
							)}
						</s.Card>
					))}
				</s.CardsDiv>

				<s.Column step={step}>
					{step === 2 ? (
						<>
							<h2>DIGITE SUA SENHA PARA CONFIRMAR</h2>

							<UnderlineInput
								id="senha"
								type="password"
								label="Senha"
								disabled={loading}
								isInvalid={erroSenha}
								erro={erroSenha}
								maxLength={25}
								value={senha}
								onChange={(e) => setSenha(e.target.value)}
							/>
						</>
					) : (
						<s.Resposta>
							<p>
								Ordem de antecipação:
								<b>{ordemAntecipacao === 'start' ? ' Início' : ' Final'}</b>
							</p>
							<p>
								Em caso de dúvidas, acesse a Central de Ajuda para ter respostas
								instantâneas às perguntas mais frequentes ou entrar em contato
								conosco.
							</p>
						</s.Resposta>
					)}
				</s.Column>

				{step === 2 && (
					<s.Line>
						<ButtonCustom
							theme="gray"
							outlined
							disabled={loading}
							onClick={() => setOpenModalDeletar(true)}
						>
							Cancelar
						</ButtonCustom>

						<s.Line>
							<ButtonCustom
								theme="green"
								outlined
								disabled={loading}
								onClick={() => setStep(step - 1)}
							>
								Voltar
							</ButtonCustom>
							<ButtonCustom
								theme="green"
								disabled={senha?.length < 6}
								isLoading={loading}
								onClick={() => confirmarSenhaAntecipacao()}
							>
								Antecipar valor
							</ButtonCustom>
						</s.Line>
					</s.Line>
				)}
			</s.Content>
		</s.SegundoPasso>
	);
};

export default withRouter(confirmarAntecipacao);
