import React, { useEffect, useState, useRef } from 'react';
import {
	DropdownFilterCardbrand,
	DropdownFilterInstallments,
	DropdownFilterPaymentsOptions,
	DropdownFilterStatusTransactions,
	DropdownFilterRefuseReasons,
} from '../dropdown-filters';
import FiltersSubtitle from '../filters-subtitle/filters-subtitle';
import {
	ButtonCustom,
	ButtonFiltersTransactions,
	FiltroCalendario,
	SearchField,
	FloaterReact,
} from '../../../../components';
import { padStart } from '../../../../utils';
import { colors } from '../../../../assets/colors';
import * as S from './styles';

const Filters = ({
	selectedDayRange,
	setSelectedDayRange,
	searchValue,
	setSearchValue,
	handleSubmit,
	cardBrand,
	installments,
	paymentMethod,
	refuseReason,
	status,
	setCardBrand,
	setInstallments,
	setPaymentMethod,
	setRefuseReason,
	setStatus,
	loading,
	page,
	setPage,
	dataClear,
	setDataClear,
}) => {
	const [floaterFlagsIsOpen, setFloaterFlagsIsOpen] = useState(false);
	const floaterFlagsRef = useRef();
	const buttonFlagsRef = useRef();

	const [floaterInstallmentsIsOpen, setFloaterInstallmentsIsOpen] =
		useState(false);
	const floaterInstallmentsRef = useRef();
	const buttonInstallmentsRef = useRef();

	const [floaterPaymentsOptionsIsOpen, setFloaterPaymentsOptionsIsOpen] =
		useState(false);
	const floaterPaymentsOptionsRef = useRef();
	const buttonPaymentsOptionsRef = useRef();

	const [floaterStatusTransactionsIsOpen, setFloaterStatusTransactionsIsOpen] =
		useState(false);
	const floaterStatusTransactionsRef = useRef();
	const buttonStatusTransactionsRef = useRef();

	const [floaterRefuseReasonIsOpen, setFloaterRefuseReasonIsOpen] =
		useState(false);
	const floaterRefuseReasonRef = useRef();
	const buttonRefuseReasonRef = useRef();

	const [dataInvalida, setDataInvalida] = useState({
		inicio: false,
		final: false,
	});

	const [dataChanged, setDataChanged] = useState(false);

	const [selectedButton, setSelectedButton] = useState('');

	const dataFormatada = () => {
		if (selectedDayRange?.from && selectedDayRange?.to) {
			const inicio = selectedDayRange.from;
			const final = selectedDayRange.to;
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(final.day)}/${padStart(final.month)}/${final.year}`,
			};
		}

		if (selectedDayRange?.day) {
			const inicio = selectedDayRange;

			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		if (selectedDayRange?.to) {
			const inicio = selectedDayRange.from;
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		return null;
	};

	const saveDataInStorage = () => {
		sessionStorage.setItem(
			'transactionInicialDate',
			JSON.stringify(selectedDayRange?.from || selectedDayRange)
		);
		sessionStorage.setItem(
			'transactionFinalDate',
			JSON.stringify(selectedDayRange?.to || selectedDayRange)
		);
		sessionStorage.setItem('transactionCardBrand', JSON.stringify(cardBrand));
		sessionStorage.setItem('transactionStatus', JSON.stringify(status));
		sessionStorage.setItem(
			'transactionInstallments',
			JSON.stringify(installments)
		);
		sessionStorage.setItem(
			'transactionRefuseReason',
			JSON.stringify(refuseReason)
		);
		sessionStorage.setItem(
			'transactionPaymentMethod',
			JSON.stringify(paymentMethod)
		);
		sessionStorage.setItem('transactionPage', JSON.stringify(page));
		setDataChanged(true);
	};

	const dataFormatadaStorage = (initialDate, finalDate) => {
		if (initialDate && finalDate) {
			return {
				from: `${padStart(initialDate.day)}/${padStart(initialDate.month)}/${
					initialDate.year
				}`,
				to: `${padStart(finalDate.day)}/${padStart(finalDate.month)}/${
					finalDate.year
				}`,
			};
		}
	};

	const disableButton = () => {
		if (!dataChanged) {
			return true;
		}
		if (dataChanged) {
			return false;
		}
		if (
			dataFormatada()?.from ===
				dataFormatadaStorage(
					JSON.parse(sessionStorage.getItem('transactionInicialDate')),
					JSON.parse(sessionStorage.getItem('transactionFinalDate'))
				)?.from &&
			dataFormatada()?.to ===
				dataFormatadaStorage(
					JSON.parse(sessionStorage.getItem('transactionInicialDate')),
					JSON.parse(sessionStorage.getItem('transactionFinalDate'))
				)?.to &&
			searchValue === '' &&
			cardBrand?.length === 0 &&
			installments?.length === 0 &&
			paymentMethod?.length === 0 &&
			refuseReason?.length === 0 &&
			status?.length === 0
		)
			return true;
	};

	const clearStatesFilters = () => {
		setSearchValue('');
		setCardBrand([]);
		setInstallments([]);
		setPaymentMethod([]);
		setRefuseReason([]);
		setStatus([]);
	};

	useEffect(() => {
		sessionStorage.setItem(
			'transactionInicialDate',
			JSON.stringify(selectedDayRange?.from || selectedDayRange)
		);
		sessionStorage.setItem(
			'transactionFinalDate',
			JSON.stringify(selectedDayRange?.to || selectedDayRange)
		);
	}, [selectedDayRange]);

	function useOnClickOutside(buttonRef, floaterRef, handler) {
		useEffect(() => {
			const listener = (event) => {
				if (
					!floaterRef.current ||
					floaterRef?.current?.contains(event.target) ||
					!buttonRef.current ||
					buttonRef?.current?.contains(event.target)
				) {
					return;
				}
				setSelectedButton('');
				handler(false);
			};
			document.addEventListener('mousedown', listener);
			document.addEventListener('touchstart', listener);
			return () => {
				document.removeEventListener('mousedown', listener);
				document.removeEventListener('touchstart', listener);
			};
		}, [buttonRef, floaterRef, handler]);
	}

	useOnClickOutside(buttonFlagsRef, floaterFlagsRef, setFloaterFlagsIsOpen);

	useOnClickOutside(
		buttonInstallmentsRef,
		floaterInstallmentsRef,
		setFloaterInstallmentsIsOpen
	);
	useOnClickOutside(
		buttonPaymentsOptionsRef,
		floaterPaymentsOptionsRef,
		setFloaterPaymentsOptionsIsOpen
	);
	useOnClickOutside(
		buttonStatusTransactionsRef,
		floaterStatusTransactionsRef,
		setFloaterStatusTransactionsIsOpen
	);
	useOnClickOutside(
		buttonRefuseReasonRef,
		floaterRefuseReasonRef,
		setFloaterRefuseReasonIsOpen
	);

	useEffect(() => {
		const delay = setTimeout(() => {
			handleSubmit();
		}, 500);
		return () => clearTimeout(delay);
	}, [cardBrand, refuseReason, installments, paymentMethod, status]);

	return (
		<S.Container>
			<S.Form>
				<S.InputGroup>
					<div className="calendar-input">
						<FiltroCalendario
							disabled={loading}
							formatedRangeDate={dataFormatada}
							selectedDayRange={selectedDayRange}
							setSelectedDayRange={setSelectedDayRange}
							dataInvalida={dataInvalida}
							setDataInvalida={setDataInvalida}
							selected="anterior"
							transform="translate(43%)"
							backgroundColor={colors.darkGray}
							width="21rem"
							fontSizePlaceholder="1.25rem"
							onFocus={() => setDataChanged(true)}
						/>
					</div>
					<SearchField
						border={`1px solid ${colors.gray}`}
						value={searchValue}
						placeholder="Filtre por ID, número do documento e nome"
						onChange={(e) => {
							setSearchValue(e.target.value);
							setDataChanged(true);
						}}
						width="100%"
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
								handleSubmit();
							}
						}}
					/>

					<ButtonCustom
						disabled={disableButton(false)}
						width="10rem"
						onClick={() => {
							clearStatesFilters();
							setDataClear(!dataClear);
						}}
						theme="green"
						outlined
					>
						Limpar
					</ButtonCustom>
					<ButtonCustom
						disabled={disableButton(true)}
						width="10rem"
						onClick={() => {
							setPage(1);
							saveDataInStorage();
							handleSubmit();
						}}
						theme="green"
					>
						Buscar
					</ButtonCustom>
				</S.InputGroup>

				<S.FiltersGroup>
					<FloaterReact
						isOpen={floaterFlagsIsOpen}
						hover
						background={colors.secondaryDarkGray}
						content={
							<DropdownFilterCardbrand
								innerRef={floaterFlagsRef}
								cardBrand={cardBrand}
								setCardBrand={setCardBrand}
							/>
						}
						width="12.125rem"
						placement="bottom"
					>
						<ButtonFiltersTransactions
							innerRef={buttonFlagsRef}
							width="12rem"
							isSelected={selectedButton === 'bandeiras'}
							onClick={() => {
								setFloaterFlagsIsOpen(!floaterFlagsIsOpen);

								setSelectedButton((prev) =>
									prev === 'bandeiras' ? '' : 'bandeiras'
								);

								disableButton();
								setDataChanged(true);
							}}
						>
							Bandeiras
						</ButtonFiltersTransactions>
					</FloaterReact>

					<FloaterReact
						isOpen={floaterInstallmentsIsOpen}
						hover
						background={colors.secondaryDarkGray}
						content={
							<DropdownFilterInstallments
								innerRef={floaterInstallmentsRef}
								installments={installments}
								setInstallments={setInstallments}
							/>
						}
						width="13.4375rem"
						placement="bottom"
					>
						<ButtonFiltersTransactions
							innerRef={buttonInstallmentsRef}
							width="15rem"
							isSelected={selectedButton === 'numeroParcelas'}
							onClick={() => {
								setFloaterInstallmentsIsOpen(!floaterInstallmentsIsOpen);
								setSelectedButton((prev) =>
									prev === 'numeroParcelas' ? '' : 'numeroParcelas'
								);
								disableButton();
								setDataChanged(true);
							}}
						>
							Número de parcelas
						</ButtonFiltersTransactions>
					</FloaterReact>

					<FloaterReact
						isOpen={floaterPaymentsOptionsIsOpen}
						hover
						background={colors.secondaryDarkGray}
						content={
							<DropdownFilterPaymentsOptions
								innerRef={floaterPaymentsOptionsRef}
								paymentMethod={paymentMethod}
								setPaymentMethod={setPaymentMethod}
							/>
						}
						width="16.7rem"
						height="2.5rem"
						placement="bottom"
					>
						<ButtonFiltersTransactions
							innerRef={buttonPaymentsOptionsRef}
							width="16.7rem"
							isSelected={selectedButton === 'formasPagamentos'}
							onClick={() => {
								setFloaterPaymentsOptionsIsOpen(!floaterPaymentsOptionsIsOpen);

								setSelectedButton((prev) =>
									prev === 'formasPagamentos' ? '' : 'formasPagamentos'
								);

								disableButton();
								setDataChanged(true);
							}}
						>
							Formas de Pagamentos
						</ButtonFiltersTransactions>
					</FloaterReact>

					<FloaterReact
						isOpen={floaterStatusTransactionsIsOpen}
						hover
						background={colors.secondaryDarkGray}
						content={
							<DropdownFilterStatusTransactions
								innerRef={floaterStatusTransactionsRef}
								status={status}
								setStatus={setStatus}
							/>
						}
						width="22rem"
						height="23rem"
					>
						<ButtonFiltersTransactions
							innerRef={buttonStatusTransactionsRef}
							width="16.7rem"
							isSelected={selectedButton === 'statusTransacao'}
							onClick={() => {
								setFloaterStatusTransactionsIsOpen(
									!floaterStatusTransactionsIsOpen
								);
								setSelectedButton((prev) =>
									prev === 'statusTransacao' ? '' : 'statusTransacao'
								);

								disableButton();
								setDataChanged(true);
							}}
						>
							Status de transação
						</ButtonFiltersTransactions>
					</FloaterReact>

					<FloaterReact
						isOpen={floaterRefuseReasonIsOpen}
						hover
						background={colors.secondaryDarkGray}
						content={
							<DropdownFilterRefuseReasons
								innerRef={floaterRefuseReasonRef}
								refuseReason={refuseReason}
								setRefuseReason={setRefuseReason}
							/>
						}
						width="16.7rem"
						height="2.5rem"
					>
						<ButtonFiltersTransactions
							innerRef={buttonRefuseReasonRef}
							width="16.7rem"
							isSelected={selectedButton === 'motivosRecusa'}
							onClick={() => {
								setFloaterRefuseReasonIsOpen(!floaterRefuseReasonIsOpen);
								setSelectedButton((prev) =>
									prev === 'motivosRecusa' ? '' : 'motivosRecusa'
								);

								disableButton();
								setDataChanged(true);
							}}
						>
							Motivos de recusa
						</ButtonFiltersTransactions>
					</FloaterReact>
					{/* 
					
					Deixar, para quando tiver que colocar o filtro, retoma-lo 

					<FloaterReact
						hover
						content={
							<DropdownFilterLastTransaction
								disabled={loading}
								formatedRangeDate={dataFormatada}
								selectedDayRange={selectedDayRange}
								setSelectedDayRange={setSelectedDayRange}
								dataInvalida={dataInvalida}
								setDataInvalida={setDataInvalida}
								selected="anterior"
								transform="translate(43%)"
								backgroundColor={colors.darkGray}
								width="21rem"
								fontSizePlaceholder="1.25rem"
							/>
						}
						width="50rem"
						height="2.5rem"
						transform={positionCalendar}
						placement="bottom"
					>
						<ButtonCustom
							outlined
							width="16.7rem"
							theme="gray"
							justifyContent="left"
							alignItems="center"
							backgroundColor={colors.darkGray}
							onClick={() => {
								disableButton();
							}}
						>
							Última atualização
						</ButtonCustom>
					</FloaterReact> */}
				</S.FiltersGroup>
				<S.LegendsFilter>
					{cardBrand.length > 0 && (
						<FiltersSubtitle
							state={cardBrand}
							setState={setCardBrand}
							id="CardBrand"
						/>
					)}
					{installments.length > 0 && (
						<FiltersSubtitle
							state={installments}
							setState={setInstallments}
							id="Installments"
						/>
					)}
					{paymentMethod.length > 0 && (
						<FiltersSubtitle
							state={paymentMethod}
							setState={setPaymentMethod}
							id="PaymentMethod"
						/>
					)}
					{status.length > 0 && (
						<FiltersSubtitle state={status} setState={setStatus} id="Status" />
					)}
					{refuseReason.length > 0 && (
						<FiltersSubtitle
							state={refuseReason}
							setState={setRefuseReason}
							id="RefuseReason"
						/>
					)}
				</S.LegendsFilter>
			</S.Form>
		</S.Container>
	);
};

export default Filters;
