import React from 'react';
import { colors } from '../../../assets';

const ContratantesIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="contratantes-icon"
		width="36"
		height="24"
		viewBox="0 0 36 24"
		fill="currentcolor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<g clipPath="url(#clip0)">
			<path d="M29.6432 19.0705L28.6295 20.1505C28.3147 20.5084 27.8766 20.7344 27.4025 20.7835C26.9284 20.8327 26.4532 20.7014 26.0716 20.4158L25.6548 20.0842L24.4611 21.4579C24.2927 21.6516 24.0877 21.8102 23.8579 21.9246C23.6282 22.039 23.3781 22.107 23.122 22.1246C22.8659 22.1422 22.6089 22.1091 22.3656 22.0272C22.1223 21.9453 21.8976 21.8162 21.7043 21.6473L20.8232 22.5947C20.3699 23.0879 19.7468 23.3913 19.079 23.444C18.4112 23.4967 17.7483 23.2948 17.2232 22.8789L8.0906 16.3231C7.69016 16.0025 7.19196 15.8286 6.67897 15.8305H0.909527C0.658269 15.8305 0.417316 15.7307 0.23965 15.553C0.0619837 15.3754 -0.0378418 15.1344 -0.0378418 14.8831V4.25367C-0.0378418 4.00242 0.0619837 3.76146 0.23965 3.5838C0.417316 3.40613 0.658269 3.30631 0.909527 3.30631H4.53796C5.15742 3.30529 5.75833 3.09496 6.24322 2.70946L9.08532 0.416844C9.48575 0.0961987 9.9839 -0.0776739 10.4969 -0.075806H11.6337L9.9474 1.67683C8.96809 2.70055 8.42865 4.06707 8.44459 5.48368C8.46052 6.9003 9.0306 8.25435 10.0327 9.25578C11.0317 10.2624 12.3866 10.8355 13.8047 10.8515C15.2228 10.8674 16.5902 10.3249 17.6116 9.34104L18.9759 8.03367H21.818L29.4632 16.4368L29.7095 16.721C29.9746 17.0588 30.1131 17.4786 30.101 17.9078C30.0889 18.3369 29.927 18.7483 29.6432 19.0705V19.0705Z" />
			<path
				d="M35.9999 4.54737V14.6747C35.9999 14.9813 35.8782 15.2753 35.6614 15.492C35.4447 15.7088 35.1507 15.8305 34.8442 15.8305H32.7221L23.4852 5.67474C23.3654 5.54056 23.2197 5.43196 23.0569 5.35544C22.8941 5.27893 22.7176 5.23609 22.5379 5.22948H18.3979C18.2234 5.22572 18.0499 5.25642 17.8873 5.31983C17.7248 5.38323 17.5763 5.47809 17.4505 5.59897L15.641 7.32315C15.1547 7.76904 14.5149 8.00976 13.8552 7.99503C13.1956 7.98029 12.5672 7.71126 12.1013 7.24411C11.6354 6.77696 11.368 6.14783 11.355 5.48818C11.342 4.82852 11.5844 4.18938 12.0315 3.70423L14.7126 0.862123C15.1066 0.458823 15.6052 0.173371 16.1525 0.0378881C16.385 -0.0195785 16.6236 -0.0482114 16.8631 -0.0473716H24.8494C25.3835 -0.0504609 25.901 0.137731 26.3084 0.483152L28.942 2.69999C29.4344 3.10935 30.0544 3.33387 30.6947 3.33474H34.8536C35.0091 3.33714 35.1626 3.37065 35.3049 3.43327C35.4472 3.4959 35.5756 3.58636 35.6824 3.69937C35.7892 3.81238 35.8724 3.94563 35.927 4.09127C35.9815 4.2369 36.0063 4.39199 35.9999 4.54737V4.54737Z"
				fill={fill || colors.offWhite}
			/>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="36" height="23.4568" fill="none" />
			</clipPath>
		</defs>
	</svg>
);

export default ContratantesIcon;
