import React from 'react';

const EditPencil = () => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" y="0.5" width="29" height="29" rx="4.5" fill="#2E3333" />
			<path
				d="M8.5 22.8571H22.3571M18.4286 7.14282L21.5714 10.2857L12.9286 18.9285H9.78571V15.7857L18.4286 7.14282Z"
				stroke="#BDC2C2"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect x="0.5" y="0.5" width="29" height="29" rx="4.5" stroke="#BDC2C2" />
		</svg>
	);
};

export default EditPencil;
