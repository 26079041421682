import styled from 'styled-components';

export const Container = styled.div`
	grid-area: ${(props) => (props.div ? props.div : 'none')};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	/* justify-content: center; */
	padding: 24px;
	background-color: white;
	border-radius: 4px;
	min-height: 95px;
	height: 100%;
	width: 100%;
	box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.07);

	div {
		display: flex !important;
		flex-direction: column;
		margin-right: 24px;

		h1 {
			color: #454550;
			font-size: 14px;
			margin-bottom: 24px;
		}
		h2 {
			color: #454550;
			font-size: 16px;
		}
	}
`;
