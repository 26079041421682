import React from 'react';

const PlusCircleIcon = ({ title, styled }) => (
	<svg
		styled={styled}
		className="add-new-items"
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || ''}</title>
		<path
			d="M14 10V18M10 14H18M24 14C24 19.5228 19.5228 24 14 24C8.47715 24 4 19.5228 4 14C4 8.47715 8.47715 4 14 4C19.5228 4 24 8.47715 24 14Z"
			stroke="#63D2ED"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default PlusCircleIcon;
