import React from 'react';
import { validateBr } from 'js-brasil';
import { Toggle } from 'react-toggle-component';
import { ButtonCustom, SelectReact, UnderlineInput } from '../../..';
import { justNumbers, mascaraCNPJ, validaCNPJ } from '../../../../utils';

import { colors } from '../../../../assets';
import * as s from './styled-contractor-dados';

const ContractorDados = ({
	setSteps,
	cnpj,
	setCnpj,
	companyName,
	setCompanyName,
	stateRegistry,
	setStateRegistry,
	cityRegistry,
	setCityRegistry,
	optionsRoleType,
	active,
	setActive,
	roleId,
	setRoleId,
	disableData,
	setDisableData,
	setIsCancelarCriacaoOpen,
	errorData,
	setErrorData,
	PJCompleted,
	setPJCompleted,
}) => {
	const disabledContinuar = () => {
		const {
			razaoSocial,
			inscricaoEstadual,
			inscricaoMunicipal,
			document,
		} = errorData;

		if (
			razaoSocial === '' &&
			inscricaoMunicipal === '' &&
			inscricaoEstadual === '' &&
			document === '' &&
			cnpj.length > 0 &&
			cityRegistry.length > 0 &&
			companyName.length > 0 &&
			stateRegistry.length > 0
		) {
			setDisableData(false);
		} else {
			setDisableData(true);
		}
	};

	const validateRazaoSocial = (e) => {
		const existingDocument = e.length;
		const doc = e;
		if (existingDocument) {
			setCompanyName(doc);
			setErrorData({ ...errorData, razaoSocial: '' });
			disabledContinuar();
		} else {
			setErrorData({ ...errorData, razaoSocial: 'Razão Social Inválida' });
		}
	};

	const validateInscricaoMunicipal = (e) => {
		const existingDocument = e.length;
		const doc = justNumbers(e);
		if (existingDocument && doc) {
			setErrorData({ ...errorData, inscricaoMunicipal: '' });
			disabledContinuar();
		} else {
			setErrorData({
				...errorData,
				inscricaoMunicipal: 'Incrição Municipal Inválida',
			});
		}
	};

	const validateDocumento = (e) => {
		const existingDocument = e.length;
		const doc = e;
		if (PJCompleted === true) {
			if (existingDocument !== 18) {
				if (validaCNPJ(doc)) {
					setErrorData({ ...errorData, document: '' });

					disabledContinuar();
				} else {
					setErrorData({ ...errorData, document: 'CNPJ inválido' });
				}
			}
		}
		if (existingDocument === 18) {
			if (validaCNPJ(doc)) {
				setErrorData({ ...errorData, document: '' });
				setPJCompleted(true);
				disabledContinuar();
			} else {
				setErrorData({ ...errorData, document: 'CNPJ inválido' });
			}
		} else {
			setErrorData({ ...errorData, document: 'CNPJ inválido' });
		}
	};

	const validateInscricaoEstadual = (e) => {
		const existingDocument = e.length;
		const doc = justNumbers(e);
		if (existingDocument && doc) {
			if (!validateBr.inscricaoestadual(doc)) {
				setErrorData({
					...errorData,
					inscricaoEstadual: 'Inscrição Estadual Inválida',
				});
			} else {
				setErrorData({ ...errorData, inscricaoEstadual: '' });
				setStateRegistry(doc);
				disabledContinuar();
			}
		}
	};

	const handleChangeDocumento = (e, type) => {
		if (type === 'CNPJ') {
			validateDocumento(e);
		}
		if (type === 'ie') {
			validateInscricaoEstadual(e);
		}
	};

	return (
		<s.Container>
			<s.Tittle>
				IDENTIFICAÇÃO
				<p>Preencha abaixo as informações sobre o novo contratante</p>
			</s.Tittle>
			<s.Form>
				<s.SelectContainer isInvalid={errorData.roleid}>
					Tipo de usuário*
					<SelectReact
						className="custom-select-input"
						id="tipo-account"
						value={roleId}
						erro={errorData.roleid}
						onChange={(opt) => {
							setRoleId(opt);
							setErrorData({ ...errorData, roleid: '' });
						}}
						options={optionsRoleType}
						outlined
						width="21.5rem"
					/>
					<p>{errorData.roleid}</p>
				</s.SelectContainer>
				<s.StatusContratante>
					Status do contratante
					<s.InputTextField active={active}>
						<p>INATIVO</p>
						<Toggle
							checked={active}
							onChange={() => setActive(!active)}
							name="active"
							borderWidth="0px"
							width="4rem"
							height="1.75rem"
							knobWidth="1.25rem"
							knobHeight="1.25rem"
							knobGap=".25rem"
							leftKnobColor={colors.darkGray}
							rightKnobColor={colors.cyan}
							leftBackgroundColor={colors.lightGray}
							rightBackgroundColor={colors.darkTurquoise}
						/>
						<span>ATIVO</span>
					</s.InputTextField>
				</s.StatusContratante>
				<s.InputTextField>
					<s.Underlineinput isInvalid={errorData.razaoSocial}>
						Razão Social*
						<UnderlineInput
							type="text"
							name="razaosocial"
							id="razaosocial"
							width="21rem"
							placeholder="Digite a razão social da empresa"
							value={companyName}
							erro={errorData.razaoSocial}
							isInvalid={errorData.razaoSocial}
							onFocus={() => {
								setDisableData(true);
								setErrorData({ ...errorData, razaoSocial: '' });
							}}
							onChange={(e) => {
								setCompanyName(e.target.value);
							}}
							onBlur={(e) => {
								validateRazaoSocial(e.target.value);
							}}
							maxLength={50}
						/>
					</s.Underlineinput>
					<s.Underlineinput isInvalid={errorData.document}>
						CNPJ*
						<UnderlineInput
							type="text"
							name="cnpj"
							id="cnpj"
							width="20.5rem"
							placeholder="Digite o CNPJ da empresa"
							value={mascaraCNPJ(cnpj)}
							onFocus={() => {
								setDisableData(true);
								setErrorData({ ...errorData, document: '' });
							}}
							onChange={(e) => {
								setCnpj(e.target.value);
							}}
							onBlur={(e) => {
								handleChangeDocumento(e.target.value, 'CNPJ');
							}}
							erro={errorData.document}
							isInvalid={errorData.document}
							maxLength={18}
							max={18}
						/>
					</s.Underlineinput>
				</s.InputTextField>
				<s.InputTextField>
					<s.Underlineinput isInvalid={errorData.inscricaoEstadual}>
						Inscrição Estadual*
						<UnderlineInput
							type="text"
							name="stateRegistry"
							id="inscricao-estadual"
							value={justNumbers(stateRegistry)}
							width="21rem"
							placeholder="Digite a inscrição estadual da empresa"
							onFocus={() => {
								setDisableData(true);
								setErrorData({ ...errorData, inscricaoEstadual: '' });
							}}
							onChange={(e) => {
								setStateRegistry(e.target.value);
							}}
							onBlur={(e) => {
								handleChangeDocumento(e.target.value, 'ie');
							}}
							erro={errorData.inscricaoEstadual}
							isInvalid={errorData.inscricaoEstadual}
							maxLength={16}
						/>
					</s.Underlineinput>
					<s.Underlineinput isInvalid={errorData.inscricaoMunicipal}>
						Inscrição Municipal*
						<UnderlineInput
							type="text"
							id="inscricao-municipal"
							width="20.5rem"
							placeholder="Digite a inscrição municipal da empresa"
							value={justNumbers(cityRegistry)}
							name="cityRegistry"
							onFocus={() => {
								setDisableData(true);
								setErrorData({ ...errorData, inscricaoMunicipal: '' });
							}}
							onChange={(e) => {
								setCityRegistry(e.target.value);
							}}
							onBlur={(e) => {
								validateInscricaoMunicipal(e.target.value);
							}}
							isInvalid={errorData.inscricaoMunicipal}
							maxLength={10}
						/>
					</s.Underlineinput>
				</s.InputTextField>
			</s.Form>

			<s.ButtonGroup>
				<ButtonCustom
					outlined
					theme="green"
					onClick={() => setIsCancelarCriacaoOpen(true)}
				>
					Cancelar
				</ButtonCustom>

				<ButtonCustom
					onClick={() => {
						if (roleId === '') {
							setErrorData({
								...errorData,
								roleid: 'Defina o tipo de usuário',
							});
						} else {
							setErrorData({ ...errorData, roleid: '' });
							setSteps(2);
						}
					}}
					disabled={disableData}
					theme={!disableData ? 'green' : 'gray'}
				>
					Continuar
				</ButtonCustom>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default ContractorDados;
