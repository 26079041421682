/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import { format } from 'date-fns';
import React, { useState } from 'react';
import { colors } from '../../../../assets';
import { Icons } from '../../../../assets/icons';
import {
	Loader,
	LoadingCircle,
	LoadingRectangle,
} from '../../../../components';
import { formatarParaReais } from '../../../../utils';

import * as S from './styled-card-receivers';

const Receivers = (props) => {
	const { isOpen, setIsOpen, index, receiver } = props;
	const {
		name,
		mdr,
		grossValue,
		discountsValue,
		taxValue,
		anticipationValue,
		data,
	} = receiver;

	const renderStatus = (transactionStatus) => {
		if (transactionStatus === 'paid') {
			return <S.Status status={transactionStatus}>Paga</S.Status>;
		}
		if (transactionStatus === 'refused') {
			return <S.Status status={transactionStatus}>Recusada</S.Status>;
		}
		if (transactionStatus === 'refunded') {
			return <S.Status status={transactionStatus}>Reembolsada</S.Status>;
		}
		if (transactionStatus === 'pending_refund') {
			return (
				<S.Status status={transactionStatus}>Reembolso Pendente </S.Status>
			);
		}
		if (
			transactionStatus === 'waiting_payment' ||
			transactionStatus === 'waiting_funds'
		) {
			return (
				<S.Status status={transactionStatus}>Aguardando Pagamento</S.Status>
			);
		}
		if (transactionStatus === 'processing') {
			return <S.Status status={transactionStatus}>Processando</S.Status>;
		}
		if (transactionStatus === 'chargedback') {
			return <S.Status status={transactionStatus}>Chargeback</S.Status>;
		}
		if (transactionStatus === 'authorized') {
			return <S.Status status={transactionStatus}>Autorizada</S.Status>;
		}
		if (transactionStatus === 'pending_review') {
			return <S.Status status={transactionStatus}>Análise pendente</S.Status>;
		}
		return transactionStatus;
	};

	const reversedInstallments = [...data].reverse();

	const checkIsOpen = (key) => {
		if (isOpen.includes(key)) {
			return true;
		}
		return false;
	};

	return (
		<S.ReceiverContainer>
			<S.ReceiverHeader>
				<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
					<S.LeftReceiverHeader>
						<span className="title">{`${index + 1}. ${name}`}</span>
						<span>Responsável por: Taxa</span>
						<div className="status">
							<span>Status da próxima parcela:</span>
							{renderStatus(receiver?.nextInstallment)}
						</div>
					</S.LeftReceiverHeader>
					<S.RightReceiverHeader>
						<S.Row>
							<S.Column style={{ textAlign: 'right' }}>
								<div style={{ marginBottom: '0.9rem' }}>
									<p>Total bruto(R$)</p>
								</div>
								<div style={{ marginBottom: '0.65rem' }}>
									<p>Total de saídas(R$)</p>
								</div>
								<div>
									<p>Total líquido(R$)</p>
								</div>
							</S.Column>
							<S.Column style={{ textAlign: 'right', justifyContent: 'right' }}>
								<div
									style={{ paddingBottom: '0.5625rem', marginTop: '-0.3rem' }}
								>
									<h2>{formatarParaReais(grossValue / 100)} </h2>
								</div>
								<div style={{ paddingBottom: '0.375rem' }}>
									<h3>{formatarParaReais(discountsValue / 100)}</h3>
								</div>
								<div>
									<h3>{formatarParaReais((grossValue - taxValue) / 100)}</h3>
								</div>
							</S.Column>
						</S.Row>
					</S.RightReceiverHeader>
				</div>
				<S.InstallmentsButton
					role="button"
					tabIndex={0}
					isOpen={checkIsOpen(index)}
					onClick={() => {
						if (isOpen.includes(index)) {
							return setIsOpen(isOpen.filter((item) => item !== index));
						}
						setIsOpen([...isOpen, index]);
					}}
				>
					<div>
						<Icons.Chevron
							style={{
								width: '100%',
								height: '100%',
								padding: '11px, 7px, 10px, 7px',
								transform: isOpen.includes(index)
									? 'rotate(-90deg)'
									: 'rotate(90deg)',
								transition: '.1s linear',
							}}
							fill={colors.lightGray}
						/>
					</div>
					<span>Visualizar Parcelas</span>
				</S.InstallmentsButton>
			</S.ReceiverHeader>
			<S.Installments
				style={{
					display: checkIsOpen(index) ? 'flex' : 'none',
				}}
			>
				<table>
					<thead>
						<tr>
							<th>Parcela</th>
							<th>Status da Parcela</th>
							<th>Data</th>
							<th>Total Bruto</th>
							<th>Taxas</th>
							<th>Serviço de Antecipação</th>
							<th>Estorno ou Chargeback</th>
						</tr>
					</thead>
					<tbody>
						{reversedInstallments.map((item) => (
							<tr key={item.id}>
								<td>{item.installment}</td>
								<td>{renderStatus(item.status)}</td>
								<td>{format(new Date(item.paymentDate), 'dd/MM/yyyy')}</td>
								<td>{formatarParaReais(item.amount / 100, true)}</td>
								<td>{formatarParaReais(item.fee / 100, true)}</td>
								<td>{formatarParaReais(item.anticipationFee / 100, true)}</td>
								<td>{item.chargeback}</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={3}>TOTAIS</td>
							<td>{formatarParaReais(grossValue / 100, true)}</td>
							<td>
								{formatarParaReais(
									discountsValue >= 0
										? discountsValue / 100
										: (discountsValue * -1) / 100,
									true
								)}
							</td>
							<td>{formatarParaReais(anticipationValue / 100, true)}</td>
						</tr>
					</tfoot>
				</table>
			</S.Installments>
		</S.ReceiverContainer>
	);
};

const CardReceivers = (props) => {
	const { id, div, title, splitRules, loading } = props;
	const [isOpen, setIsOpen] = useState([]);

	const mapReceivers = (obj) => {
		const elements = [];
		for (const [key, value] of Object.entries(obj)) {
			elements.push({ key, value });
		}
		return elements?.map((item, index) => (
			<Receivers
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				key={item.key}
				index={index}
				receiver={item.value}
			/>
		));
	};

	return (
		<S.Container id={id} div={div}>
			<S.Title>
				<div>{title}</div>
				{!loading ? (
					<div>Valor total bruto</div>
				) : (
					<LoadingRectangle style={{ width: '25%' }} />
				)}
			</S.Title>
			<div className="subtitle">
				<div>
					{!loading && <span>{splitRules?.recipients} recebedores</span>}
				</div>
				<div>
					{!loading && (
						<h2>{formatarParaReais(splitRules?.total / 100, true)}</h2>
					)}
				</div>
			</div>
			<S.Infos>
				{!loading ? splitRules && mapReceivers(splitRules?.data) : <Loader />}
			</S.Infos>
		</S.Container>
	);
};

export default CardReceivers;
