import React from 'react';
import { colors } from '../../assets';

const GraphIcon = ({ fill, style, title }) => {
	return (
		<svg
			className="graph-icon"
			style={style}
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>{title}</title>
			<path
				d="M1.99988 10.947C1.44759 10.947 0.999932 11.3948 1 11.9471C1.00007 12.4994 1.44784 12.947 2.00012 12.947L1.99988 10.947ZM5.3157 11.9465L5.31583 12.9465C5.75143 12.9465 6.13688 12.6645 6.26877 12.2493L5.3157 11.9465ZM16.6841 11.9465V10.9465C16.2472 10.9465 15.8609 11.2302 15.73 11.647L16.6841 11.9465ZM20 12.9465C20.5523 12.9465 21 12.4988 21 11.9465C21 11.3943 20.5523 10.9465 20 10.9465V12.9465ZM8.15781 3L9.11139 2.69887C8.98011 2.28314 8.59463 2.00037 8.15867 2C7.7227 1.99963 7.33674 2.28173 7.20475 2.69723L8.15781 3ZM13.842 21L12.8884 21.3011C13.0199 21.7175 13.4063 22.0004 13.8429 22C14.2795 21.9996 14.6653 21.7161 14.7961 21.2995L13.842 21ZM2.00012 12.947L5.31583 12.9465L5.31558 10.9465L1.99988 10.947L2.00012 12.947ZM16.6841 12.9465H20V10.9465H16.6841V12.9465ZM6.26877 12.2493L9.11088 3.30277L7.20475 2.69723L4.36264 11.6438L6.26877 12.2493ZM7.20423 3.30113L12.8884 21.3011L14.7956 20.6989L9.11139 2.69887L7.20423 3.30113ZM14.7961 21.2995L17.6382 12.2461L15.73 11.647L12.8879 20.7005L14.7961 21.2995Z"
				fill={fill || colors.primaryBlue}
			/>
		</svg>
	);
};

export default GraphIcon;
