import React from 'react';
import { colors } from '../../../assets';

const AntecipacaoIcon = ({ title, fill, style }) => (
	<svg
		className="antecipacao-icon"
		style={style}
		width="19"
		height="14"
		viewBox="0 0 19 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.2626 12.0521C18.4842 11.8224 18.536 11.4775 18.3918 11.1927L17.6617 9.75082C16.0014 6.47199 12.6385 4.40536 8.96326 4.40536H8.5594C8.53398 3.85202 8.50113 3.29893 8.46084 2.74625L8.39239 1.80729C8.3371 1.04876 7.49133 0.624744 6.85045 1.03425C4.73475 2.38614 2.89546 4.12808 1.43065 6.16724L0.970462 6.80785C0.782632 7.06933 0.782632 7.42151 0.970462 7.68298L1.43065 8.3236C2.89547 10.3628 4.73475 12.1047 6.85045 13.4566C7.49133 13.8661 8.3371 13.4421 8.39239 12.6835L8.46084 11.7446C8.50892 11.085 8.54641 10.4249 8.57332 9.76443C10.7455 9.70076 12.9152 10.1387 14.9091 11.059L17.4084 12.2125C17.6982 12.3463 18.041 12.2819 18.2626 12.0521ZM16.0517 9.93429L15.5376 9.69703C13.106 8.57473 10.4379 8.10092 7.79047 8.30426C7.40816 8.33362 7.10957 8.64662 7.09823 9.02989C7.07254 9.89906 7.02806 10.7678 6.96481 11.6355L6.95847 11.7225C5.29567 10.5488 3.83865 9.10469 2.6489 7.44847L2.50304 7.24542L2.6489 7.04237C3.83864 5.38614 5.29567 3.94205 6.95847 2.76834L6.96481 2.8553C7.02127 3.62986 7.06277 4.40524 7.08931 5.181C7.10313 5.585 7.43464 5.90536 7.83887 5.90536L8.96326 5.90536C11.8841 5.90536 14.5719 7.44803 16.0517 9.93429Z"
			fill={fill || colors.primaryBlue}
		/>
	</svg>
);
export default AntecipacaoIcon;
