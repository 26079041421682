import styled from 'styled-components';
import { colors } from '../../../assets';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: ${({ width }) => width || '100%'};

	label {
		display: flex;
		align-items: center;
		color: ${({ isInvalid, readOnly }) =>
			readOnly ? colors.gray : isInvalid ? colors.red : colors.offWhite};
		font-size: 1rem;
		font-weight: 400;
	}

	.info-icon {
		width: 1.5rem;
		height: 1.5rem;
		margin-left: 0.5rem;
	}
`;

export const ContainerSelect = styled.div`
	display: flex;
	height: ${(props) => props.height || '60px'};
	background: ${colors.gridBlack};
	width: ${(props) => props.width || '100%'};
	border-radius: 0.9375rem;

	[class$='control'],
	[class$='container'] {
		border-radius: inherit;
	}

	[class$='indicatorContainer'] {
		padding: 0.5rem;
	}

	[class$='indicatorSeparator'] {
		display: none;
	}

	[class$='Option']:last-child {
		border-bottom-left-radius: 0.9375rem;
		border-bottom-right-radius: 0.9375rem;
	}
`;

export const customStyles = {
	container: (provided) => ({
		...provided,
		width: '100%',
		boxSizing: 'border-box',
		height: '100%',
		borderRadius: '0.9375rem',
		outline: 'none',
		minWidth: 50,
		borderColor: 'transparent',
		background: colors.gridBlack,
	}),
	control: (provided) => ({
		...provided,
		fontFamily: 'IBM Plex Sans, sans-serif',
		minHeight: '25px',
		height: '100%',
		borderRadius: '0.9375rem',
		color: colors.white,
		fontSize: '1.13rem',
		background: colors.gridBlack,
		boxShadow: 'none',
		borderColor: 'transparent',
		'&:hover': {
			borderColor: 'transparent',
		},
		cursor: 'pointer',
	}),
	placeholder: (provided, { isDisabled }) => ({
		...provided,
		fontFamily: 'IBM Plex Sans, sans-serif',
		fontSize: '1.13rem',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		outline: 'none',
		position: 'absolute',
		color: isDisabled ? colors.gray : colors.white,
	}),
	menu: (provided) => ({
		...provided,
		boxShadow: 'none',
		background: colors.gridBlack,
		marginTop: '-1px',
		borderRadius: 'inherit',
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
	}),
	menuList: (provided) => ({
		...provided,
		paddingBottom: 0,
	}),
	option: ({ styles }) => ({
		...styles,
		fontFamily: 'IBM Plex Sans, sans-serif',
		color: colors.white,
		fontSize: '1.13rem',
		boxSizing: 'border-box',
		height: 'min-content',
		padding: '8px 10px',
		cursor: 'pointer',
		width: '100%',
		':hover': { backgroundColor: colors.white, color: colors.gridBlack },
	}),
	singleValue: ({ isDisabled }) => ({
		fontFamily: 'IBM Plex Sans, sans-serif',
		position: 'absolute',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		paddingLeft: '10px',
		paddingTop: '6px',
		maxWidth: '32rem',
		color: isDisabled ? colors.gray : colors.white,
	}),
	input: () => ({
		fontFamily: 'IBM Plex Sans, sans-serif',
		'& input': {
			fontFamily: 'inherit',
		},
	}),
	dropdownIndicator: (base, state) => ({
		...base,
		transition: 'all .5s ease',
		transform: state.isFocused ? 'rotate(180deg)' : null,
		color: state.isFocused ? colors.primaryBlue : colors.white,
	}),
};
