import React from 'react';
import { useHistory } from 'react-router';

import { ButtonCustom, Loader } from '../../../atoms';
import { Icons } from '../../../../assets';
import {} from '../../../../services';

import * as s from './styled-contractor-conclusao';

const ContractorConclusao = ({ loading, contractorsFilters }) => {
	const history = useHistory();

	return (
		<s.Container>
			{loading ? (
				<Loader />
			) : (
				<>
					<s.SvgContainer>
						<Icons.SuccessIcon />
					</s.SvgContainer>
					<s.Text>
						<h1>CONTRANTE ADICIONADO COM SUCESSO!</h1>
						<p>
							Você já pode visualizá-lo na seção <strong>Contrantes</strong>
						</p>
					</s.Text>
					<s.ButtonGroup>
						<ButtonCustom
							outlined
							width={100}
							onClick={() => history.push('/resumo')}
						>
							Sair
						</ButtonCustom>
						<ButtonCustom
							onClick={() =>
								history.push(
									`/contratante/${contractorsFilters[0].contractorId}`
								)
							}
						>
							Ver Contratante
						</ButtonCustom>
					</s.ButtonGroup>
				</>
			)}
		</s.Container>
	);
};

export default ContractorConclusao;
