import React from 'react';
import { colors } from '../..';

const VisibleEyeIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="visible-eye-icon"
		width="29"
		height="19"
		viewBox="0 0 29 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			d="M28.7655 9.06921C25.7267 3.41909 20.3974 0 14.5 0C8.60261 0 3.2643 3.41909 0.252488 9.06921L0 9.49547L0.234453 9.93079C3.27332 15.5809 8.60261 19 14.5 19C20.3974 19 25.7357 15.6263 28.7655 9.93079L29 9.49547L28.7655 9.06921ZM14.5 17.1408C9.4232 17.1408 4.76119 14.2931 2.05597 9.49547C4.76119 4.69785 9.4232 1.85012 14.5 1.85012C19.5768 1.85012 24.1847 4.70692 26.935 9.49547C24.1847 14.2931 19.5678 17.1408 14.5 17.1408Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			d="M14.7619 3.30131C13.5392 3.31029 12.3467 3.68347 11.3348 4.37373C10.3229 5.06399 9.53705 6.04037 9.07657 7.17954C8.61609 8.31871 8.50159 9.56958 8.74755 10.7742C8.9935 11.9787 9.58887 13.083 10.4585 13.9474C11.328 14.8119 12.4329 15.3978 13.6333 15.6311C14.8338 15.8645 16.0761 15.7347 17.2033 15.2584C18.3305 14.782 19.2921 13.9804 19.9666 12.9547C20.6411 11.929 20.9982 10.7253 20.9929 9.4956C20.9894 8.67705 20.8253 7.86724 20.51 7.11259C20.1947 6.35793 19.7345 5.67327 19.1556 5.09784C18.5768 4.52241 17.8907 4.06752 17.1368 3.75924C16.3828 3.45095 15.5758 3.29534 14.7619 3.30131ZM14.7619 13.9305C13.8983 13.9215 13.0565 13.6561 12.3424 13.1676C11.6282 12.679 11.0735 11.9892 10.7479 11.1846C10.4223 10.38 10.3404 9.49666 10.5124 8.6454C10.6843 7.79415 11.1026 7.01298 11.7145 6.40001C12.3265 5.78704 13.1049 5.3696 13.952 5.20011C14.7991 5.03062 15.6771 5.11665 16.4757 5.44739C17.2743 5.77812 17.958 6.33882 18.4408 7.05906C18.9236 7.7793 19.1841 8.62697 19.1894 9.4956C19.1918 10.0802 19.0788 10.6595 18.857 11.1998C18.6351 11.7402 18.3088 12.2308 17.8969 12.6433C17.485 13.0559 16.9959 13.3821 16.4577 13.603C15.9196 13.824 15.3431 13.9353 14.7619 13.9305Z"
			fill={fill || colors.primaryBlue}
		/>
	</svg>
);
export default VisibleEyeIcon;
