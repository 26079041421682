import { axiosApp } from '.';

const getRecipients = (
	page = 1,
	recipientId,
	id = '',
	name = '',
	bank_account_id = ''
) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(
				// eslint-disable-next-line camelcase
				`/recipients?page=${page}&recipientId=${recipientId}&id=${id}&name=${name}&bank_account_id=${bank_account_id}`
			)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getRecipientDetails = (idRecebedor, signal) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/recipients/${idRecebedor}`, { signal })
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const updateRecipient = (idRecebedor, dataRecebedor) =>
	new Promise((resolve, reject) => {
		axiosApp
			.put(`/recipients/${idRecebedor}`, dataRecebedor)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getBankAccounts = (page = 1, documento) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/recipients/bank-accounts?page=${page}&document=${documento}`)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getRecipientBankAccounts = (page, recipientDocument, recipientId) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(
				`/recipients/bank-accounts?page=${page}&document=${recipientDocument}&recipientId=${recipientId}`
			)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});
};

const postCriarRecebedor = (body) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.post('/recipients', body)
			.then((resp) => resolve(resp.data))
			.catch((e) => reject(e));
	});
};

const postRegistrarRecebedor = (recipientId, newRecipientId) => {
	return new Promise((resolve, reject) => {
		axiosApp
			.post('/recipients/register', { recipientId, newRecipientId })
			.then((resp) => resolve(resp.data))
			.catch((e) => reject(e));
	});
};

export {
	getRecipientDetails,
	getRecipients,
	updateRecipient,
	getBankAccounts,
	getRecipientBankAccounts,
	postCriarRecebedor,
	postRegistrarRecebedor,
};
