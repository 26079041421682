import styled from 'styled-components';
import { colors } from '../../../../assets';

export const ContainerHeader = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 4.5rem;
	margin-bottom: 3rem;
	justify-content: space-between;
`;
export const ContainerForm = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 2rem;
	gap: 1rem;
	border-radius: 0.5rem;
	height: 100%;
`;

export const Title = styled.h2`
	font-family: Jost;
	font-style: normal;
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 2rem;
	letter-spacing: 0.04em;
	color: ${colors.offWhite};
	justify-content: flex-start;
	text-align: left;
`;

export const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	.underline-input {
		padding: 1rem;
		width: 20.5rem;
	}
`;

export const Row = styled.div`
	display: flex;
	gap: 2rem;
	margin-bottom: 2rem;
`;

export const UnderlineInput = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.625rem;
	font-family: 'IBM Plex Sans';
	font-size: '1rem';
	font-weight: 400;
	line-height: '1.3rem';
	color: ${colors.white};
`;

export const ButtonGroup = styled.div`
	display: flex;
	.add-new-item {
		border-radius: 0.5rem;
	}
`;

export const SelectContainer = styled.div`
	display: flex;
	margin: 4.125rem 2rem 0 2rem;
	width: 132px;

	.select-type-item {
		border: 0.0625rem solid ${colors.offWhite};
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 600;
		font-size: 1rem;
	}
`;
