import styled from 'styled-components';

export const Container = styled.div`
	border-radius: 50%;
	height: 120px;
	width: 120px;

	span {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}
`;
