import axios from 'axios';
import {
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	confirmPasswordReset,
} from 'firebase/auth';
import { auth, axiosApp } from '.';

const { REACT_APP_API, REACT_APP_BASE_URL } = process.env;

const errorTreatment = (error, returnTheErrorToo) => {
	let resp = '';
	switch (error.code) {
		case 'auth/network-request-failed':
			resp = 'Não foi possível se comunicar com o servidor...';
			break;
		case 'auth/invalid-email':
			resp = 'O endereço de email é inválido!';
			break;
		case 'auth/user-not-found':
			resp = 'Seu e-mail ou senha estão incorretos!';
			break;
		case 'auth/weak-password':
			resp = 'A senha precisa ter no mínimo 6 caracteres!';
			break;
		case 'auth/wrong-password':
			resp = 'Senha incorreta! Tente novamente.';
			break;
		case 'auth/email-already-in-use':
			resp = 'Este e-mail já está sendo utilizado!';
			break;
		case 'auth/too-many-requests':
			resp = 'Muitas tentativas de login, tente novamente mais tarde!';
			break;
		default:
			resp = 'Ocorreu um erro, tente novamente mais tarde!';
			break;
	}
	if (returnTheErrorToo) return { error, msg: resp };
	return resp;
};

// const validatePassword = (password) =>
// 	new Promise((resolve, reject) => {
// 		firebase
// 			.auth()
// 			.user.reauthenticateWithCredential(
// 				firebase.auth.EmailAuthProvider.credential(
// 					firebase.auth().user.email,
// 					password
// 				)
// 			)
// 			.then(() => resolve())
// 			.catch(() => reject());
// 	});

const loginWithoutAuthPersist = (email, password, returnTheErrorToo) =>
	new Promise((resolve, reject) => {
		signInWithEmailAndPassword(auth, email, password)
			.then(() => resolve())
			.catch((error) => reject(errorTreatment(error, returnTheErrorToo)));
	});

const verifyLogin = () =>
	new Promise((resolve) => {
		auth.onAuthStateChanged((user) => {
			if (!user) resolve(false);
			else
				user
					.getIdToken()
					.then((t) => resolve(t))
					.catch(() => resolve(false));
		});
	});

const logout = () =>
	new Promise((resolve, reject) => {
		auth
			.signOut()
			.then(() => resolve())
			.catch((error) => reject(error));
	});

const passwordResetEmail = (email) => {
	const ActionCodeSettings = {
		url: `${REACT_APP_BASE_URL}/redefinir-senha?email=${email}`,
		handleCodeInApp: true,
	};

	return new Promise((resolve, reject) => {
		sendPasswordResetEmail(auth, email, ActionCodeSettings)
			.then(() => resolve())
			.catch((error) => reject(error));
	});
};

const passwordReset = (code, password) =>
	new Promise((resolve, reject) => {
		confirmPasswordReset(auth, code, password)
			.then(() => resolve())
			.catch((e) => reject(e));
	});

const getUser = () =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/users/profile`)
			.then(({ data }) => resolve(data))
			.catch((error) => {
				reject(errorTreatment(error));
			});
	});

const getRoleUser = () =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/users/roles`)
			.then(({ data }) => resolve(data))
			.catch((error) => {
				reject(errorTreatment(error));
			});
	});

const registerUser = (dataUser, sendEmail) =>
	new Promise((resolve, reject) => {
		axiosApp
			.post(`/users?sendEmail=${sendEmail}`, dataUser)
			.then((data) => resolve({ data }))
			.catch((error) => reject(error));
	});

const getUserInfos = (id) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/users/${id}`)
			.then((data) => resolve(data.data))
			.catch((e) => reject(e.response.data.error));
	});

const updateUser = (idUser, dataUser, sendEmail) =>
	new Promise((resolve, reject) => {
		axiosApp
			.put(`/users/${idUser}?sendEmail=${sendEmail}`, dataUser)
			.then((data) => resolve({ data }))
			.catch((error) => reject(error));
	});

const userRegister = (email, password, name, token) =>
	new Promise((resolve, reject) => {
		const axiosAuth = axios.create({
			baseURL: `${REACT_APP_API}`,
		});
		const requestHandler = async (request) => {
			request.headers['Content-Type'] = 'application/json;charset=UTF-8';
			request.headers['Access-Control-Allow-Methods'] = '*';
			request.headers['Access-Control-Expose-Headers'] =
				'Authorization,Location';
			request.headers['Access-Control-Allow-Origin'] = '*';
			request.headers.Authorization = `Bearer ${token}`;
			return request;
		};
		const errorHandler = (error) => error;
		[axiosAuth].forEach((item) => {
			item.interceptors.request.use(requestHandler, errorHandler);
		});
		axiosAuth
			.post(`/users/register`, { email, password, name })
			.then(({ data }) => resolve(data))
			.catch((error) => reject(errorTreatment(error)));
	});

// accesses

const getUserActiveStatus = (filter) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/users?filter=${filter}`)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getUsers = (page = 1, order = 'asc', filter = 'all') =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/users?order=${order}&filter=${filter}&page=${page}`)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getSearchUsers = (searchValue, page = 1) =>
	// ?sendEmail=${sendEmail}sendEmail , qs.stringify({ sendEmail })
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/users/search?term=${searchValue}&page=${page}`)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getUsersWallet = (
	idContractor = 'KV9XYmke83Z8Pcmuvbec',
	name = '',
	cnpj = '',
	email = ''
) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(
				`/users/wallet?idContractor=${idContractor}&name=${name}&cnpj=${cnpj}&email=${email}`
			)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const putStatusUsers = (userId, status) =>
	new Promise((resolve, reject) => {
		axiosApp
			.put(`users/status/${userId}/${status}`)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

export {
	loginWithoutAuthPersist,
	logout,
	getUser,
	getUsers,
	getUserInfos,
	getRoleUser,
	registerUser,
	passwordReset,
	passwordResetEmail,
	updateUser,
	// validatePassword,
	verifyLogin,
	userRegister,
	getSearchUsers,
	getUserActiveStatus,
	getUsersWallet,
	putStatusUsers,
};
