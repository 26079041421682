import React from 'react';
import { colors } from '../../../assets';

const LinksIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="links-icon"
		width="32"
		height="23"
		viewBox="0 0 32 23"
		fill="currentcolor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 3.55263C0 1.89578 1.34315 0.552628 3 0.552628H29C30.6569 0.552628 32 1.89577 32 3.55263V19.4474C32 21.1042 30.6569 22.4474 29 22.4474H3C1.34315 22.4474 0 21.1042 0 19.4474V3.55263ZM3.78947 9.81579C3.78947 7.72292 5.48608 6.02631 7.57895 6.02631H14.1115C15.1165 6.02631 16.0804 6.42556 16.791 7.13622L20.2616 10.6068C20.7549 11.1001 20.7549 11.8999 20.2616 12.3932C19.7683 12.8865 18.9685 12.8865 18.4752 12.3932L15.0046 8.9226C14.7678 8.68571 14.4465 8.55263 14.1115 8.55263H7.57895C6.88132 8.55263 6.31579 9.11816 6.31579 9.81579V13.1842C6.31579 13.8818 6.88132 14.4474 7.57895 14.4474H11.7895C12.4871 14.4474 13.0526 15.0129 13.0526 15.7105C13.0526 16.4081 12.4871 16.9737 11.7895 16.9737H7.57895C5.48608 16.9737 3.78947 15.2771 3.78947 13.1842V9.81579ZM25.2632 16.9737C27.356 16.9737 29.0526 15.2771 29.0526 13.1842V9.81579C29.0526 7.72292 27.356 6.02631 25.2632 6.02631L21.0526 6.02631C20.355 6.02631 19.7895 6.59185 19.7895 7.28947C19.7895 7.98709 20.355 8.55263 21.0526 8.55263H25.2632C25.9608 8.55263 26.5263 9.11816 26.5263 9.81579V13.1842C26.5263 13.8818 25.9608 14.4474 25.2632 14.4474L18.7306 14.4474C18.3956 14.4474 18.0743 14.3143 17.8375 14.0774L14.3669 10.6068C13.8736 10.1135 13.0738 10.1135 12.5805 10.6068C12.0872 11.1001 12.0872 11.8999 12.5805 12.3932L16.0511 15.8638C16.7617 16.5744 17.7256 16.9737 18.7306 16.9737H25.2632Z"
			fill={fill || colors.offWhite}
		/>
	</svg>
);

export default LinksIcon;
