import React, { useState, useEffect } from 'react';
import {
	//  useParams,
	withRouter,
} from 'react-router';
import * as s from './styled-dados-antecipacoes';
import RecebedorAntecipacoes from './recebedor-antecipacoes/recebedor-antecipacoes';
import { Icons } from '../../../../assets';
import {
	FloaterReact,
	InputCalendario,
	UnderlineInput,
	RadioButton,
	ButtonCustom,
	CustomMonetaryValue,
} from '../../../../components';
import {
	adicionarDiasCalendario,
	formatarParaReais,
	valorMonetario,
	capitalizeFirstLetter,
} from '../../../../utils';
import { useAntecipacao } from '../../../../hooks';
import { LoadingRectangle } from '../../../molecules';

const DadosAntecipacoes = ({ history, setOpenModalDeletar }) => {
	const {
		setStep,
		buscarLimites,
		dataSelecionada,
		setDataSelecionada,
		dataFormatadaAntecipacao,
		dataFormatadaRequisicao,
		dataInvalida,
		setDataInvalida,
		erroData,
		minimo,
		maximo,
		valores,
		loadingValores,
		valorDesejado,
		setValorDesejado,
		idAntecipacao,
		tipoAntecipacao,
		setTipoAntecipacao,
		taxaTransf,
		ordemAntecipacao,
		setOrdemAntecipacao,
		calcularAntecipacao,
		deletarAntecipacao,
		enableDisableClicks,
	} = useAntecipacao();

	const [erroValor, setErroValor] = useState('');

	// const { recipientId } = useParams();
	const pathName = history.location.pathname;

	const Info = ({ text }) => (
		<FloaterReact
			width="15.625rem"
			fontSize="0.875rem"
			textAlign="left"
			placement="right"
			content={text}
			hover
		>
			<Icons.InfoIconOutlined />
		</FloaterReact>
	);

	function selecionarOrdem(ordem) {
		if (idAntecipacao) {
			deletarAntecipacao(false);
			setTimeout(() => {
				buscarLimites(dataFormatadaRequisicao(), ordem);
			}, 1000);
		} else buscarLimites(dataFormatadaRequisicao(), ordem);

		setOrdemAntecipacao(ordem);
	}

	const disabledContinuar = () => {
		if (
			dataInvalida ||
			erroData ||
			loadingValores ||
			minimo <= 0 ||
			(valores?.tipoAntecipacaoSelecionado &&
				tipoAntecipacao !== valores.tipoAntecipacaoSelecionado) ||
			(valores?.valorDesejado && valorDesejado !== valores?.valorDesejado) ||
			(valores?.ordemAntecipacaoSelecionado &&
				ordemAntecipacao !== valores.ordemAntecipacaoSelecionado)
		) {
			return true;
		}
		return false;
	};

	function validarValor(value) {
		let msg = '';

		let valor = value.replace(/\D,/g, '');
		valor = valor.replace('.', '').replace(',', '.');
		valor = Number(valor);

		if (valor < minimo) msg = 'Valor menor do que o permitido';
		else if (valor > maximo) msg = 'Valor maior do que o permitido';

		setErroValor(msg);
	}

	useEffect(() => {
		validarValor(valorDesejado);
	}, [valorDesejado]);

	return (
		<s.PrimeiroPasso>
			<RecebedorAntecipacoes />

			<s.Content>
				<s.Column>
					<label htmlFor="tabs">
						Ordem de antecipação:
						<Info text="Você pode escolher que o valor seja descontado a partir dos recebíveis do início do período vigente, ou a partir do final. Esta opção afeta o custo da sua antecipação." />
					</label>

					<s.ButtonsTabs id="tabs" selected={ordemAntecipacao}>
						<s.Button
							id="inicio"
							onClick={() => selecionarOrdem('start')}
							selected={ordemAntecipacao === 'start'}
							disabled={dataInvalida || erroData || loadingValores}
						>
							INÍCIO
						</s.Button>
						<s.Button
							id="final"
							onClick={() => selecionarOrdem('end')}
							selected={ordemAntecipacao === 'end'}
							disabled={dataInvalida || erroData || loadingValores}
						>
							FINAL
						</s.Button>
					</s.ButtonsTabs>

					<label htmlFor="data-antecipacao">
						Data de antecipação:
						<Info text="Selecione aqui a data em que a antecipação acontecerá. Você pode antecipar apenas em dias úteis." />
					</label>
					<InputCalendario
						id="data-antecipacao"
						disabled={loadingValores}
						formatedRangeDate={dataFormatadaAntecipacao}
						dataInvalida={dataInvalida || erroData}
						setDataInvalida={setDataInvalida}
						selectedDayRange={dataSelecionada}
						setSelectedDayRange={setDataSelecionada}
						minimumDate={adicionarDiasCalendario(0).to}
						transform="translateX(19.63rem)"
					/>

					{erroData && <span id="erro">{capitalizeFirstLetter(erroData)}</span>}

					<s.Line marginTop>
						<UnderlineInput
							id="valor-antecipacao"
							label="Valor desejado (R$):"
							info="Transfira para conta bancária para sacar o valor da antecipação direto em conta. Costuma ficar disponível após as 16 horas do dia selecionado, dependendo do tempo de compensação do banco. Adicione ao Saldo para somar o valor da antecipação ao saldo. Útil se quiser sacar mais tarde ou somar ao valor de saldo antes de sacar."
							placeholder="0,00"
							maxLength={maximo && formatarParaReais(maximo).length}
							isInvalid={erroValor}
							disabled={erroData || loadingValores || minimo <= 0}
							erro={erroValor}
							value={valorDesejado}
							onChange={(e) => {
								setValorDesejado(valorMonetario(e.target.value));
								validarValor(e.target.value);
							}}
						/>

						<s.Group>
							<label>Disponível para antecipação:</label>
							<s.Line>
								<p>
									Mínimo:
									<span>
										{minimo ? formatarParaReais(minimo, true) : 'R$ 0,00'}
									</span>
								</p>
								<p>
									Máximo:
									<span>
										{maximo ? formatarParaReais(maximo, true) : 'R$ 0,00'}
									</span>
								</p>
							</s.Line>
						</s.Group>
					</s.Line>

					<label>Tipo de antecipação:</label>
					<s.Line>
						<RadioButton
							id="transferir-conta"
							label="Transferir para conta bancária"
							checked={tipoAntecipacao === 'conta'}
							onChange={() => setTipoAntecipacao('conta')}
						/>
						<RadioButton
							id="adicionar-saldo"
							label="Adicionar ao saldo"
							checked={tipoAntecipacao === 'saldo'}
							onChange={() => setTipoAntecipacao('saldo')}
						/>
					</s.Line>

					<ButtonCustom
						theme="green"
						outlined
						width="12.5rem"
						disabled={dataInvalida || erroData || erroValor || minimo <= 0}
						onClick={() => {
							enableDisableClicks('disabled');
							calcularAntecipacao(true, false, true);
						}}
						isLoading={loadingValores}
					>
						Calcular valor
					</ButtonCustom>

					<hr />

					<p id="mensagem-rodape">
						Antecipações para o mesmo dia útil precisam ser solicitadas antes
						das 10:59 da manhã. Esta antecipação será analisada e poderá ser
						aprovada ou rejeitada. Após a aprovação, o valor a ser antecipado é
						adicionado ao saldo a partir das 12 horas do dia selecionado.
					</p>
				</s.Column>
				<s.Column second>
					<label>
						Valor bruto:
						<Info text="Este é o valor mais próximo ao desejado que encontramos, baseado nos parâmetros selecionados. Ele é a soma dos valores na ordem escolhida, até chegar o mais próximo possível do valor desejado. Como antecipamos os recebíveis de cada transação por inteiro, este valor costuma ficar diferente do desejado caso você não antecipe o valor máximo disponível." />
					</label>
					{loadingValores ? (
						<LoadingRectangle id="valor-bruto" />
					) : (
						<CustomMonetaryValue
							valor={valores?.valorBruto || 0}
							fontSize="2rem"
						/>
					)}
					<label>
						Total de taxas:
						<Info text="Estes são os valores cobrados para que os valores sejam antecipados. A Taxa de Transferência não é cobrada caso você adicione ao saldo ao invés de transferir para a conta bancária." />
					</label>
					{loadingValores ? (
						<LoadingRectangle id="total-taxas" />
					) : (
						<CustomMonetaryValue
							valor={valores?.taxaAntecipacao + taxaTransf || 0}
							fontSize="2rem"
						/>
					)}

					<s.Table>
						<s.Row first>
							<label>Antecipação:</label>
							{loadingValores ? (
								<LoadingRectangle id="antecipacao" />
							) : (
								<CustomMonetaryValue
									valor={valores?.taxaAntecipacao}
									fontSize="1rem"
									filled
								/>
							)}
						</s.Row>
						<s.Row>
							<label>Transferência:</label>
							{loadingValores ? (
								<LoadingRectangle id="transferencia" />
							) : (
								<CustomMonetaryValue
									valor={taxaTransf}
									fontSize="1rem"
									filled
								/>
							)}
						</s.Row>
					</s.Table>

					<label>
						Valor a ser antecipado:
						<Info text="Este é o valor líquido a ser antecipado, já com as taxas descontadas." />
					</label>
					{loadingValores ? (
						<LoadingRectangle id="valor-antecipacao" />
					) : (
						<CustomMonetaryValue
							valor={valores?.valorAntecipacao + taxaTransf || 0}
							fontSize="2rem"
							filled
						/>
					)}

					<s.Row buttons>
						<ButtonCustom
							theme="green"
							outlined
							width="12.5rem"
							onClick={() => {
								if (idAntecipacao) setOpenModalDeletar(true);

								if (pathName === '/antecipacoes') history.push('/extrato');
								else {
									history.push(`./`);
								}
							}}
							disabled={loadingValores}
						>
							Cancelar
						</ButtonCustom>
						<ButtonCustom
							type="submit"
							theme="green"
							width="12.5rem"
							onClick={() => setStep(2)}
							disabled={disabledContinuar()}
						>
							Continuar
						</ButtonCustom>
					</s.Row>
				</s.Column>
			</s.Content>
		</s.PrimeiroPasso>
	);
};

export default withRouter(DadosAntecipacoes);
