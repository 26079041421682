import React from 'react';
import { colors } from '../../colors';

const SuccessIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		width="164"
		height="164"
		viewBox="0 0 164 164"
		fill="none"
		className="sucess-icon"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || ''}</title>
		<g filter="url(#filter0_d_4333_17898)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M103.244 63.7753C104.252 64.809 104.252 66.4851 103.244 67.5188L79.1643 92.2247C78.1568 93.2584 76.5232 93.2584 75.5157 92.2247L61.7557 78.107C60.7481 77.0733 60.7481 75.3973 61.7557 74.3635C62.7632 73.3298 64.3968 73.3298 65.4043 74.3635L77.34 86.6094L99.5957 63.7753C100.603 62.7416 102.237 62.7416 103.244 63.7753Z"
				fill={fill || colors.primaryBlue}
				stroke={fill || colors.primaryBlue}
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx="82"
				cy="78"
				r="50"
				stroke={fill || colors.primaryBlue}
				strokeWidth="8"
			/>
		</g>
		<defs>
			<filter
				id="filter0_d_4333_17898"
				x="0"
				y="0"
				width="164"
				height="164"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="14" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.388235 0 0 0 0 0.823529 0 0 0 0 0.929412 0 0 0 0.5 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_4333_17898"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_4333_17898"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);
export default SuccessIcon;
