import React, { useState } from 'react';
import { parse, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import * as s from './styled-input-calendario';
import { FloaterReact, Calendario } from '../../../components';
import { Icons } from '../../../assets';
import { padStart } from '../../../utils';

const InputCalendario = ({
	id,
	backgroundColor,
	width,
	fontSize,
	placement,
	transform,
	disabled,
	formatedRangeDate,
	dataInvalida,
	setDataInvalida,
	selectedDayRange,
	setSelectedDayRange,
	minimumDate,
	maximumDate,
}) => {
	const [open, setOpen] = useState(false);

	const setarValor = (valor) => {
		let dataValida;

		if (valor.includes('_') || valor === '') {
			dataValida = false;
			setDataInvalida(true);
		} else {
			const formatDate = (date) =>
				parse(date, 'P', new Date(), { locale: ptBR });
			const dataEscolhida = formatDate(valor);
			const isValidDate = isValid(dataEscolhida);

			if (!isValidDate) {
				dataValida = false;
				setDataInvalida(true);
			} else {
				const data = new Date();
				const diaHoje = data.getDate();
				const mesHoje = data.getMonth() + 1;
				const anoHoje = data.getFullYear();
				const dataHoje = formatDate(
					`${padStart(diaHoje)}/${padStart(mesHoje)}/${anoHoje}`
				);

				if (dataEscolhida < dataHoje) {
					dataValida = false;
					setDataInvalida(true);
				} else {
					dataValida = true;
					setDataInvalida(false);
				}
			}
		}

		let dataSelecionada;

		if (valor) {
			dataSelecionada = valor.split('/');
		} else dataSelecionada = ['__', '__', '___'];

		if (dataValida) {
			dataSelecionada = {
				day: Number(dataSelecionada[0]),
				month: Number(dataSelecionada[1]),
				year: Number(dataSelecionada[2]),
			};
		} else {
			dataSelecionada = {
				day: dataSelecionada[0],
				month: dataSelecionada[1],
				year: dataSelecionada[2],
			};
		}

		setSelectedDayRange(dataSelecionada);
	};

	return (
		<s.Container
			backgroundcolor={backgroundColor}
			invalid={dataInvalida.toString()}
			disabled={disabled}
			width={width}
			fontSize={fontSize}
		>
			<FloaterReact
				simbolicIcon
				isOpen={open}
				placement={placement}
				transform={transform}
				content={
					<s.Column>
						<Calendario
							selectedDayRange={dataInvalida ? null : selectedDayRange}
							setSelectedDayRange={setSelectedDayRange}
							minimumDate={minimumDate}
							maximumDate={maximumDate}
							numberOfMonths={2}
						/>
						<p>1 dia selecionado</p>
					</s.Column>
				}
			>
				<Icons.Calendar />
			</FloaterReact>
			{open && <s.Overlay onClick={() => setOpen(false)} />}

			<s.InputDays
				id={id || 'data'}
				mask="99/99/9999"
				value={formatedRangeDate()}
				onChange={(e) => setarValor(e.target.value)}
				onClick={() => !open && setOpen(true)}
				disabled={disabled}
				invalid={dataInvalida.toString()}
				backgroundcolor={backgroundColor}
			/>
		</s.Container>
	);
};

export default InputCalendario;
