import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { formatarParaReais, replaceAccent } from '../../utils';
import { Filters, Loader, TransactionBody } from '../../components';
import { getPayableTransaction } from '../../services/dashboard.service';
import { useAuth } from '../../hooks';
import { Icons } from '../../assets';

import * as S from './styled-transactions';

const Transactions = () => {
	const { recipientId } = useAuth().currentUser;
	const [transacoes, setTransacoes] = useState([]);
	const [excelTransactions, setExcelTransactions] = useState([]);
	const [renderTable, setRenderTable] = useState(false);

	const [cardBrand, setCardBrand] = useState(() => {
		if (sessionStorage.getItem('transactionCardBrand')) {
			return JSON.parse(sessionStorage.getItem('transactionCardBrand'));
		}
		return [];
	});

	const [installments, setInstallments] = useState(() => {
		if (sessionStorage.getItem('transactionInstallments')) {
			return JSON.parse(sessionStorage.getItem('transactionInstallments'));
		}
		return [];
	});

	const [paymentMethod, setPaymentMethod] = useState(() => {
		if (sessionStorage.getItem('transactionPaymentMethod')) {
			return JSON.parse(sessionStorage.getItem('transactionPaymentMethod'));
		}
		return [];
	});

	const [refuseReason, setRefuseReason] = useState(() => {
		if (sessionStorage.getItem('transactionRefuseReason')) {
			return JSON.parse(sessionStorage.getItem('transactionRefuseReason'));
		}
		return [];
	});

	const [status, setStatus] = useState(() => {
		if (sessionStorage.getItem('transactionStatus')) {
			return JSON.parse(sessionStorage.getItem('transactionStatus'));
		}
		return [];
	});

	const dataInicialStorage = JSON.parse(
		sessionStorage.getItem('transactionInicialDate')
	);
	const dataFinalStorage = JSON.parse(
		sessionStorage.getItem('transactionFinalDate')
	);

	const [searchValue, setSearchValue] = useState('');
	const [totalValue, setTotalValue] = useState(0);
	const [totalElements, setTotalElements] = useState(0);
	const [loading, setLoading] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState();
	const [page, setPage] = useState(() => {
		if (JSON.parse(sessionStorage.getItem('transactionPage'))) {
			return JSON.parse(sessionStorage.getItem('transactionPage'));
		}
		return 1;
	});

	const intervaloInicial = () => {
		const data = new Date();

		const diaHoje = data.getDate();
		const mesHoje = data.getMonth() + 1;
		const anoHoje = data.getFullYear();

		data.setDate(diaHoje - 7);

		const diaInicio = data.getDate();
		const mesInicio = data.getMonth() + 1;
		const anoInicio = data.getFullYear();

		if (dataInicialStorage && dataFinalStorage) {
			const diaInicioStorage = dataInicialStorage.day;
			const mesInicioStorage = dataInicialStorage.month;
			const anoInicioStorage = dataInicialStorage.year;

			return {
				from: {
					day: diaInicioStorage,
					month: mesInicioStorage,
					year: anoInicioStorage,
				},
				to: dataFinalStorage,
			};
		}
		return {
			from: { day: diaInicio, month: mesInicio, year: anoInicio },
			to: { day: diaHoje, month: mesHoje, year: anoHoje },
		};
	};
	const [intervaloDatas, setIntervaloDatas] = useState(intervaloInicial);
	const [dataClear, setDataClear] = useState(false);

	function dataFormatada() {
		if (intervaloDatas?.day) {
			return {
				from: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
				to: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
			};
		}
		const dataInicial = intervaloDatas.from;
		const dataFinal = intervaloDatas.to;

		return {
			from: `${dataInicial.year}/${dataInicial.month}/${dataInicial.day}`,
			to: `${dataFinal.year}/${dataFinal.month}/${dataFinal.day}`,
		};
	}

	function datasEstaoCompletas() {
		const dataInicial = intervaloDatas.from;
		const dataFinal = intervaloDatas.to;
		const dataUnica = intervaloDatas.day;

		if (dataUnica) return true;
		if (!dataInicial || !dataFinal) return false;
		if (!dataInicial.day || !dataInicial.month || !dataInicial.year)
			return false;
		if (!dataFinal.day || !dataFinal.month || !dataFinal.year) return false;
		return true;
	}

	const optionsItemsPage = [
		{
			label: '15 itens por página',
			value: 15,
		},
		{
			label: '30 itens por página',
			value: 30,
		},
		{
			label: '60 itens por página',
			value: 60,
		},
		{
			label: '100 itens por página',
			value: 100,
		},
	];

	const [itemsPerPage, setItemsPerPage] = useState(() => {
		if (
			sessionStorage.getItem('transactionItemsPerPage') &&
			sessionStorage.getItem('transactionItemsPerPage') !== ''
		) {
			return JSON.parse(sessionStorage.getItem('transactionItemsPerPage'));
		}
		sessionStorage.setItem(
			'transactionItemsPerPage',
			JSON.stringify(optionsItemsPage[0])
		);
		return optionsItemsPage[0];
	});

	function checkStorage() {
		const pageStorage = JSON.parse(sessionStorage.getItem('transactionPage'));
		const totalPaginas = totalElements / itemsPerPage.value;
		if (pageStorage !== 1) {
			if (totalPaginas >= pageStorage) {
				setPage(pageStorage);
			}
		} else {
			setPage(1);
		}
	}

	const exportData = (pagina = 1) => {
		if (totalElements > 0) {
			getPayableTransaction(
				recipientId,
				dataFormatada().from,
				dataFormatada().to,
				pagina,
				totalElements,
				cardBrand.toString(),
				installments.toString(),
				paymentMethod.toString(),
				refuseReason.toString(),
				status.toString(),
				replaceAccent(searchValue)
			)
				.then((resp) => {
					setExcelTransactions(() => resp.data);
				})
				.catch(() => toast.error('Erro ao buscar os dados das planilhas'));
		}
	};

	function buscarTransacoes(pagina = 1) {
		setLoading(true);
		checkStorage();

		if (datasEstaoCompletas()) {
			getPayableTransaction(
				recipientId,
				dataFormatada().from,
				dataFormatada().to,
				pagina,
				itemsPerPage.value,
				cardBrand,
				installments.toString(),
				paymentMethod.toString(),
				refuseReason.toString(),
				status.toString(),
				replaceAccent(searchValue)
			)
				.then((resp) => {
					setTransacoes(() => resp.data);
					setTotalElements(resp.totalElements);
					setTotalValue(formatarParaReais(resp.totalValue / 100, true));
				})
				.catch(() => toast.error('Erro ao buscar transações'))
				.finally(() => {
					setLoading(false);
				});
		}
	}
	const clearData = () => {
		sessionStorage.removeItem('transactionCardBrand');
		sessionStorage.removeItem('transactionStatus');
		sessionStorage.removeItem('transactionInstallments');
		sessionStorage.removeItem('transactionRefuseReason');
		sessionStorage.removeItem('transactionPaymentMethod');
		sessionStorage.setItem('transactionPage', JSON.stringify(1));
		setLoading(true);

		setPage(1);
		buscarTransacoes(1);
	};

	useEffect(() => {
		setPage(1);
		clearData();
	}, [dataClear]);

	useEffect(() => {
		exportData();
	}, [totalElements]);

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		if (isMounted) return buscarTransacoes(1);
		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<S.Container>
			<Filters
				selectedDayRange={intervaloDatas}
				setSelectedDayRange={setIntervaloDatas}
				handleSubmit={buscarTransacoes}
				selectedFilter={selectedFilter}
				setSelectedFilter={setSelectedFilter}
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				cardBrand={cardBrand}
				setCardBrand={setCardBrand}
				installments={installments}
				setInstallments={setInstallments}
				paymentMethod={paymentMethod}
				setPaymentMethod={setPaymentMethod}
				refuseReason={refuseReason}
				setRefuseReason={setRefuseReason}
				status={status}
				setStatus={setStatus}
				loading={loading}
				page={page}
				setPage={setPage}
				clearData={clearData}
				dataClear={dataClear}
				setDataClear={setDataClear}
			/>
			{loading ? (
				<S.CenterLoader>
					<Loader />
				</S.CenterLoader>
			) : transacoes.length > 0 ? (
				<TransactionBody
					intervaloDatas={intervaloDatas}
					excelTransactions={excelTransactions}
					handleSubmit={buscarTransacoes}
					transacoes={transacoes}
					page={page}
					setPage={setPage}
					itemsPerPage={itemsPerPage}
					setItemsPerPage={setItemsPerPage}
					optionsItemsPage={optionsItemsPage}
					totalElements={totalElements}
					totalValue={totalValue}
					setStatus={setStatus}
					loading={loading}
					renderTable={renderTable}
					setRenderTable={setRenderTable}
				/>
			) : (
				<S.ErrorContent>
					<Icons.AttentionOutlined />
					<h2 className="error-title">Sem resultados</h2>
					<p className="error-text">
						Não encontramos resultados para sua busca. Reveja o que foi digitado
						ou faça uma nova pesquisa.
					</p>
				</S.ErrorContent>
			)}
		</S.Container>
	);
};

export default Transactions;
