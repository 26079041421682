import ErrorAlertIcon from './error-alert-icon';
import InfoTriangleIcon from './outline-info-triangle-icon';
import SuccessIcon from './outline-success-icon';

const statusIcons = {
	ErrorAlertIcon,
	InfoTriangleIcon,
	SuccessIcon,
};

export default statusIcons;
