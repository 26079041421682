import React from 'react';

const ChekedCircle = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20.1431 10.1641V11.0053C20.142 12.9768 19.5036 14.8953 18.3231 16.4744C17.1426 18.0535 15.4833 19.2087 13.5926 19.7677C11.7019 20.3267 9.6812 20.2596 7.83179 19.5763C5.98239 18.893 4.40339 17.6303 3.3303 15.9763C2.25721 14.3223 1.74752 12.3658 1.87724 10.3985C2.00696 8.43115 2.76915 6.55848 4.05013 5.05973C5.33111 3.56099 7.06225 2.51647 8.98536 2.08197C10.9085 1.64747 12.9205 1.84626 14.7214 2.6487M20.1431 3.69099L11.0003 12.843L8.25742 10.1001"
			stroke="#63D2ED"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default ChekedCircle;
