import React from 'react';
import { colors } from '../../../assets';

const StripeEyeIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="stripe-eye-icon"
		width="31"
		height="20"
		viewBox="0 0 31 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			d="M29.7655 10.0692C26.7267 4.41909 21.3974 1 15.5 1C9.60261 1 4.2643 4.41909 1.25249 10.0692L1 10.4955L1.23445 10.9308C4.27332 16.5809 9.60261 20 15.5 20C21.3974 20 26.7357 16.6263 29.7655 10.9308L30 10.4955L29.7655 10.0692ZM15.5 18.1408C10.4232 18.1408 5.76119 15.2931 3.05597 10.4955C5.76119 5.69785 10.4232 2.85012 15.5 2.85012C20.5768 2.85012 25.1847 5.70692 27.935 10.4955C25.1847 15.2931 20.5678 18.1408 15.5 18.1408V18.1408Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			d="M15.7619 4.30131C14.5392 4.31029 13.3467 4.68347 12.3348 5.37373C11.3229 6.06399 10.537 7.04037 10.0766 8.17954C9.61609 9.31871 9.50159 10.5696 9.74755 11.7742C9.9935 12.9787 10.5889 14.083 11.4585 14.9474C12.328 15.8119 13.4329 16.3978 14.6333 16.6311C15.8338 16.8645 17.0761 16.7347 18.2033 16.2584C19.3305 15.782 20.2921 14.9804 20.9666 13.9547C21.6411 12.929 21.9982 11.7253 21.9929 10.4956C21.9894 9.67705 21.8253 8.86724 21.51 8.11259C21.1947 7.35793 20.7345 6.67327 20.1556 6.09784C19.5768 5.52241 18.8907 5.06752 18.1368 4.75924C17.3828 4.45095 16.5758 4.29534 15.7619 4.30131V4.30131ZM15.7619 14.9305C14.8983 14.9215 14.0565 14.6561 13.3424 14.1676C12.6282 13.679 12.0735 12.9892 11.7479 12.1846C11.4223 11.38 11.3404 10.4967 11.5124 9.6454C11.6843 8.79415 12.1026 8.01298 12.7145 7.40001C13.3265 6.78704 14.1049 6.3696 14.952 6.20011C15.7991 6.03062 16.6771 6.11665 17.4757 6.44739C18.2743 6.77812 18.958 7.33882 19.4408 8.05906C19.9236 8.7793 20.1841 9.62697 20.1894 10.4956C20.1918 11.0802 20.0788 11.6595 19.857 12.1998C19.6351 12.7402 19.3088 13.2308 18.8969 13.6433C18.485 14.0559 17.9959 14.3821 17.4577 14.603C16.9196 14.824 16.3431 14.9353 15.7619 14.9305V14.9305Z"
			fill={fill || colors.primaryBlue}
		/>
		<path
			d="M1 1C3.23077 2.5 20.0855 13.25 30 19"
			stroke={fill || colors.primaryBlue}
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
);
export default StripeEyeIcon;
