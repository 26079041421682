import React from 'react';

import * as s from './styled-radio-button';

const RadioButton = ({
	label,
	id,
	checked,
	onChange,
	register,
	name,
	value,
	disabled,
}) => {
	return (
		<s.Container
			id={id}
			disabled={disabled}
			checked={checked}
			className="radio-button"
		>
			<input
				type="radio"
				id={disabled ? id : ''}
				checked={checked}
				register={register}
				name={name}
				value={value}
				onChange={onChange}
				disabled={disabled}
			/>
			<label
				id={disabled ? id : ''}
				className="label-radio-button"
				onClick={onChange}
				onKeyPress={onChange}
				role="presentation"
				disabled={disabled}
			>
				{label}
			</label>
		</s.Container>
	);
};

export default RadioButton;
