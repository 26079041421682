import React from 'react';
// import { colors } from '../colors';

const DesativarLink = () => (
	// { title, fill, style }
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.4836 15.4736L22.4836 12.4736C23.3944 11.5305 23.8983 10.2675 23.8869 8.95655C23.8755 7.64557 23.3497 6.39151 22.4227 5.46447C21.4956 4.53743 20.2415 4.01158 18.9306 4.00019C17.6196 3.9888 16.3566 4.49277 15.4136 5.40356L13.6936 7.11356M8.40356 12.3936L5.40356 15.3936C4.49277 16.3366 3.9888 17.5996 4.00019 18.9106C4.01158 20.2216 4.53743 21.4756 5.46447 22.4027C6.39151 23.3297 7.64557 23.8555 8.95655 23.8669C10.2675 23.8783 11.5305 23.3744 12.4736 22.4636L14.1836 20.7536M8 5L19.9306 22.7536"
			stroke="#63D2ED"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default DesativarLink;
