import React from 'react';
import { CheckboxCustom } from '../../../../atoms';
import { capitalizeFirstLetter } from '../../../../../utils';

import * as S from './dropdown-filter-style-statustransactions';

const statusTransaction = [
	'Paga',
	'Recusada',
	'Estornada',
	'Estorno Pendente',
	'Aguardando Pagamento',
	'Processando',
	'Chargeback',
	'Autorizada',
	'Revisão pendente',
];

const statusData = (value) => {
	const data = {
		Paga: 'paid',
		Recusada: 'refused',
		Estornada: 'refunded',
		'Estorno Pendente': 'pending_refund',
		'Aguardando Pagamento': 'waiting_payment',
		Processando: 'processing',
		Chargeback: 'chargedback',
		Autorizada: 'authorized',
		'Revisão pendente': 'pending_review',
	};
	return data[value];
};

function RenderCheckboxCustom({ state, setState, id, text }) {
	return (
		<CheckboxCustom
			checked={state?.includes(id)}
			state={state}
			setState={setState}
			fontSize="1.25rem"
			text={capitalizeFirstLetter(text)}
			checkboxAlign="flex-start"
			onChange={() => {
				if (state.includes(id)) {
					setState(state.filter((item) => item !== id));
				} else {
					setState([...state, id]);
				}
			}}
			TransactionFilters
		/>
	);
}

function DropdownFilterStatusTransactions({ status, setStatus, innerRef }) {
	return (
		<S.Container ref={innerRef}>
			<S.Column>
				<S.ColumnTitle>Status de transação</S.ColumnTitle>
				{statusTransaction.map((item) => (
					<RenderCheckboxCustom
						state={status}
						setState={setStatus}
						key={item}
						id={statusData(item)}
						text={item}
						transactionsFilters
					/>
				))}
			</S.Column>
		</S.Container>
	);
}

export default DropdownFilterStatusTransactions;
