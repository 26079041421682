import React, { useEffect } from 'react';
import { justNumbers } from '../../../../utils';
import { ButtonCustom, SelectReact, UnderlineInput } from '../../../atoms';

import * as s from './styled-contractor-bancaria';

const ContractorContaBancaria = ({
	setSteps,
	bankCode,
	setBankCode,
	optionsBank,
	contaDv,
	setContaDv,
	agencia,
	setAgencia,
	agenciaDv,
	setAgenciaDv,
	conta,
	setConta,
	disableBankAccount,
	setDisableBankAccount,
	tipo,
	setTipo,
	optionsBankType,
	setIsCancelarCriacaoOpen,
	errorBankAccount,
	setErrorBankAccount,
	activeConta,
	setActiveConta,
	activeAgencia,
	setActiveAgencia,
}) => {
	const disabledContinuar = () => {
		if (
			errorBankAccount.agencia === '' &&
			errorBankAccount.dgAgencia === '' &&
			errorBankAccount.conta === '' &&
			errorBankAccount.dgConta === '' &&
			errorBankAccount.banktype === '' &&
			errorBankAccount.bankCode === ''
		) {
			return setDisableBankAccount(false);
		}
		return setDisableBankAccount(true);
	};

	const validate = (e, type) => {
		if (type === 'agencia') {
			const documentExist = e.target.value.length;
			const doc = e.target.value;
			setAgencia(doc);
			if (documentExist) {
				if (documentExist === 4) {
					e.target.value = doc;
					setActiveAgencia(true);
					setErrorBankAccount({ ...errorBankAccount, agencia: '' });
					disabledContinuar();
				}
				if (activeAgencia) {
					if (documentExist < 4) {
						setAgencia(doc);
						setErrorBankAccount({
							...errorBankAccount,
							agencia: 'Agência inválida',
						});
					}
				}
			} else {
				setAgencia(doc);
				setErrorBankAccount({
					...errorBankAccount,
					agencia: 'Agência inválida',
				});
			}
		}

		if (type === 'dgagencia') {
			const doc = justNumbers(e.target.value);
			const documentExist = doc.length;
			setAgenciaDv(doc);

			if (documentExist) {
				e.target.value = doc;
				setErrorBankAccount({ ...errorBankAccount, dgAgencia: '' });
				disabledContinuar();
			} else {
				setErrorBankAccount({
					...errorBankAccount,
					dgAgencia: 'digito da agência inválido',
				});
			}
		}

		if (type === 'conta') {
			const documentExist = e.target.value.length;
			const doc = e.target.value;
			setConta(doc);
			if (documentExist) {
				if (documentExist === 4) {
					e.target.value = doc;
					setActiveConta(true);
					setErrorBankAccount({ ...errorBankAccount, conta: '' });
					disabledContinuar();
				}
				if (activeConta) {
					if (documentExist < 4) {
						setConta(doc);
						setErrorBankAccount({
							...errorBankAccount,
							conta: 'Conta inválida',
						});
					}
				}
			} else {
				setErrorBankAccount({ ...errorBankAccount, conta: 'Conta inválida' });
			}
		}

		if (type === 'dgconta') {
			const doc = justNumbers(e.target.value);
			const documentExist = doc.length;
			setContaDv(doc);
			if (documentExist) {
				e.target.value = doc;
				setErrorBankAccount({ ...errorBankAccount, dgConta: '' });
				disabledContinuar();
			} else {
				setErrorBankAccount({
					...errorBankAccount,
					dgConta: 'digito da conta inválido',
				});
			}
		}
	};

	useEffect(() => {
		disabledContinuar();
	}, [errorBankAccount]);

	return (
		<s.Container>
			<s.Tittle>
				CONTA BANCÁRIA
				<p>Adicione uma conta bancária para esse contratante</p>
			</s.Tittle>
			<s.Form>
				<s.SelectContainer isInvalid={errorBankAccount.bankCode}>
					Banco*
					<SelectReact
						className="custom-select-input"
						id="banco"
						value={bankCode}
						erro={errorBankAccount.bankCode}
						onChange={(opt) => {
							setBankCode(opt);
							setErrorBankAccount({ ...errorBankAccount, bankCode: '' });
						}}
						options={optionsBank}
						outlined
						width="19.625rem"
					/>
					<p>{errorBankAccount.bankCode}</p>
				</s.SelectContainer>
				<s.InputTextField>
					<s.Underlineinput isInvalid={errorBankAccount.agencia}>
						Agência*
						<UnderlineInput
							id="agencia"
							placeholder="Digite o número da agência"
							value={justNumbers(agencia)}
							name="bankAccount.agencia"
							erro={errorBankAccount.agencia}
							isInvalid={errorBankAccount.agencia}
							onChange={(e) => validate(e, 'agencia')}
							maxLength={4}
							width="18.6875rem"
						/>
					</s.Underlineinput>
					<s.Underlineinput isInvalid={errorBankAccount.dgAgencia}>
						Digito da Agência*
						<UnderlineInput
							id="ag-dv"
							placeholder="Digite o código verificador da agência"
							value={justNumbers(agenciaDv)}
							name="bankAccount.agenciaDv"
							erro={errorBankAccount.dgAgencia}
							isInvalid={errorBankAccount.dgAgencia}
							onChange={(e) => validate(e, 'dgagencia')}
							maxLength={1}
							width="18.6875rem"
						/>
					</s.Underlineinput>
				</s.InputTextField>
				<s.InputTextField>
					<s.Underlineinput isInvalid={errorBankAccount.conta}>
						Conta*
						<UnderlineInput
							id="conta"
							typeNumber
							placeholder="Digite o número da conta"
							value={justNumbers(conta)}
							name="bankAccount.conta"
							erro={errorBankAccount.conta}
							isInvalid={errorBankAccount.conta}
							onChange={(e) => validate(e, 'conta')}
							maxLength={13}
							width="20.5rem"
						/>
					</s.Underlineinput>
					<s.Underlineinput isInvalid={errorBankAccount.dgConta}>
						Digito da Conta*
						<UnderlineInput
							id="dv"
							placeholder="Digite o código verificador da conta"
							value={justNumbers(contaDv)}
							name="bankAccount.agenciaDv"
							erro={errorBankAccount.dgConta}
							isInvalid={errorBankAccount.dgConta}
							onChange={(e) => validate(e, 'dgconta')}
							maxLength={1}
							width="20.5rem"
						/>
					</s.Underlineinput>
				</s.InputTextField>
				<s.SelectContainer
					style={{ paddingTop: '3rem' }}
					isInvalid={errorBankAccount.banktype}
				>
					Tipo da Conta*
					<SelectReact
						className="custom-select-input"
						id="banco"
						value={tipo}
						erro={errorBankAccount.banktype}
						onChange={(opt) => {
							setErrorBankAccount({ ...errorBankAccount, banktype: '' });
							setTipo(opt);
						}}
						options={optionsBankType}
						outlined
						width="19.625rem"
					/>
					<p>{errorBankAccount.banktype}</p>
				</s.SelectContainer>
			</s.Form>
			<s.ButtonGroup>
				<ButtonCustom
					outlined
					theme="green"
					onClick={() => setIsCancelarCriacaoOpen(true)}
				>
					Cancelar
				</ButtonCustom>
				<div id="step">
					<ButtonCustom theme="green" onClick={() => setSteps(2)}>
						Voltar
					</ButtonCustom>

					<ButtonCustom
						theme="green"
						onClick={() => {
							setSteps(4);
						}}
						disabled={disableBankAccount}
					>
						Continuar
					</ButtonCustom>
				</div>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default ContractorContaBancaria;
