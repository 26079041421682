import React from 'react';

const ResumoIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="resumo-icon"
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill={fill || 'currentcolor'}
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<rect width="14.3158" height="14.3158" rx="3" />
		<rect x="17.6842" width="14.3158" height="14.3158" rx="3" />
		<rect y="17.6842" width="14.3158" height="14.3158" rx="3" />
		<rect x="17.6842" y="17.6842" width="14.3158" height="14.3158" rx="3" />
	</svg>
);

export default ResumoIcon;
