import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const Container = styled.div`
	width: 100%;
	margin: 1rem 0;
	padding: 30px;

	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	color: ${colors.white};
	background-color: ${colors.darkGray};
	border-radius: 15px;
`;

export const Column = styled.div`
	padding-right: 0.75rem;
`;

export const ColumnTitle = styled.div`
	text-align: left;
	text-transform: uppercase;
	color: ${colors.primaryBlue};
	font-size: 1.25rem;
`;

export const ColumnParcelas = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

export const RowParcelas = styled.div`
	width: 50%;
`;
