import styled from 'styled-components';

import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center !important;
	justify-items: center;

	color: ${colors.white};
	background-color: ${colors.darkGray};
	padding: 18px 35px 37px 42px;
`;

export const ItemContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	border: 1px solid ${colors.lightGray};
	border-radius: 15px;
	padding: 1rem;
`;

export const TitleHeader = styled.div`
	display: flex;
	margin-bottom: 0.6rem;
	width: 100%;
	justify-content: flex-start !important;
`;

export const Title = styled.h1`
	font-size: 1em;
	padding-bottom: 16px;

	font-family: IBM Plex Sans;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: ${colors.white};
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	width: calc(100% / 3);

	span {
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 1.125em;
		line-height: 32px;
		color: ${colors.offWhite};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

export const ColTitle = styled.div`
	justify-content: center;
	h5 {
		font-size: 1em;
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: 400;
		line-height: 21px;
		:first-of-type {
			margin-right: 50px;
		}
	}
`;
export const ColAddress = styled.div`
	span {
		:first-of-type {
			margin-right: 48px;
		}
	}
`;
