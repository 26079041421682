import React from 'react';
import * as s from './styled-text-field-custom';

const TextFieldCustom = ({
	value,
	onKeyPress,
	maxLength,
	onChange,
	placeholder,
	defaultValue,
	name,
	readOnly,
	typeInput,
	typeNumber,
	onFocus,
	id,
}) => (
	<s.InputCustom
		id={id}
		type={typeInput || 'text'}
		pattern={typeNumber ? '[0-9]*' : ''}
		value={value}
		onKeyPress={onKeyPress}
		onChange={onChange}
		placeholder={placeholder}
		defaultValue={defaultValue}
		maxLength={maxLength}
		name={name}
		readOnly={readOnly}
		onFocus={onFocus}
	/>
);

export default TextFieldCustom;
