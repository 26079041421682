import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 2.5rem 1.5rem;
	margin-top: 1rem;
`;

export const Title = styled.h2`
	display: flex;
	align-items: center;
	gap: 1rem;
	flex-direction: row;
	font-family: Jost;
	font-style: normal;
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 2rem;
	letter-spacing: 0.04em;
	color: ${colors.offWhite};
	justify-content: flex-start;
	text-align: left;
	margin-bottom: 0.5rem;
`;

export const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	div#select {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: ${colors.white};
	}
	.custom-input {
		border-radius: 0;
		height: 100%;
		padding-top: 0.4375rem;
	}
	.checkbox-custom {
		height: 1.625rem;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: start;
		color: ${colors.primaryBlue};
	}
`;

export const InputGroup = styled.div`
	display: flex;

	flex-direction: row;
`;

export const TooltipDiv = styled.div`
	display: flex;

	flex-direction: column;
	max-width: 16.5rem;
	flex-wrap: wrap;
	p{
		font-size: 0.875rem;
		color: ${colors.lightGray}
	}
	strong{
		color: ${colors.offWhite};
	}
`;

export const InputTextField = styled.div`
	display: flex;
	flex-direction: row;
	width: 20.5rem;
	margin-top: 3.125rem;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;

	color: ${colors.white};
	gap: 2rem;
	margin-left: 2rem;
	.underlineinput {
		display: flex;
		flex-direction: column;
		color: ${colors.white};
	}
	.label {
		font-weight: 700;
	}
`;

export const Underlineinput = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.625rem;
	font-family: 'IBM Plex Sans';
	font-size: '1rem';
	font-weight: 400;
	line-height: '1.3rem';
	color: ${colors.white};
`;
export const TotalDiv = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: flex-end;
	color: ${colors.offWhite};
	font-size: 1.25rem;
`;

export const SelectContainer = styled.div`
	display: flex;
	margin: 5.125rem 2rem 0 2rem;
	width: 8.25rem;

	.select-period {
		border: 0.0625rem solid ${colors.offWhite};
		border-radius: 0.5rem;
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
	}
`;

export const ButtonGroup = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 3.5rem;
	justify-content: space-between;
	margin-top: 5rem;
`;
