import styled from 'styled-components';
import { colors } from '../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.9375rem;
	flex: 1;
`;

export const Group = styled.section`
	display: flex;
	flex-wrap: wrap;
	gap: 0.9375rem;
`;

export const ContainerTabs = styled.div`
	display: flex;
	width: 100%;
	gap: 2.5rem;
	justify-content: center;
	margin: 3rem 0;
	pointer-events: ${({ loading }) => (loading ? 'none' : 'all')};
`;

export const Tab = styled.span`
	display: flex;
	align-items: center;
	color: ${colors.lightGray};
	font-size: 1.125rem;
	font-weight: 600;
	font-size: 20px;
	line-height: 26px;
	letter-spacing: 0.005em;
	:hover {
		cursor: pointer;
		border-bottom: 2px solid ${colors.primaryBlue};
	}
	${({ isActive }) =>
		isActive &&
		`
			color: ${colors.primaryBlue};
			border-bottom: 2px solid ${colors.primaryBlue};
		`}
`;

export const NoContentContainer = styled.div`
	display: flex;
	position: relative;
	justify-content: flex-end;
	width: 100%;

	.nenhum-registro {
		position: absolute;
		height: 43rem;
		width: 90rem;
		user-select: none;
		justify-content: flex-end;
		right: -60px;
	}
`;

export const NoContentMessage = styled.div`
	position: absolute;
	top: 10%;
	left: 10%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: left;
`;

export const NoContentTitle = styled.h2`
	font-weight: bold;
	font-size: 4rem;
	line-height: 5.1875rem;
	text-align: center;
	color: ${colors.primaryBlue};
	padding-bottom: 2rem;
`;

export const NoContentText = styled.p`
	font-size: 1.5rem;
	line-height: 1.9375rem;
	color: ${colors.offWhite};
`;
