import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	align-items: center;
	gap: 1rem;
	border-radius: 8px;
`;

export const TableMaster = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: static;
	background: ${colors.gridBlack};
	border-radius: 8px;
	margin-bottom: 2rem;

	table {
		font-size: 1rem;
		background: ${colors.gridBlack};
		padding: 1.56rem;
		border-radius: 8px;
		justify-content: flex-end;
		align-content: flex-end;
		.scroll-table {
			border: 1px solid ${colors.red};
		}

		td {
			display: flex;
			align-items: center;
			justify-content: center;
			border-right: 1px solid ${colors.borderTable};
			padding: 10px;
			height: 100%;

			:last-child {
				border-right: 0;
			}
		}
		td.status {
			border-right: 0px;
		}

		td.center {
			justify-content: center;
		}
	}
`;

export const Border = styled.div`
	border: 1px solid ${colors.borderTable};
	border-radius: 8px;
`;

export const ContainerHeader = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
	gap: 15px;

	background: ${colors.gridBlack};
	border-radius: 8px;

	padding-left: 0.9rem;
	padding-right: 0.9rem;

	button {
		width: 9.44rem;
		height: 3.13rem;
	}
	.select-react {
		width: 10.44rem;
		height: 3.13rem;
	}
`;

export const TableHeader = styled.div`
	width: auto;
	display: flex;
	justify-content: flex-end;
	align-items: right;
	gap: 15px;
	padding: 1.56rem;
	border-radius: 8px;

	.itemsPerPage {
		width: 232px;
		height: 50px;
	}

	button {
		width: auto;
		height: 3.13rem;
	}
	.select-react {
		width: 14.75rem;
		height: 3.13rem;
	}
`;

export const TBody = styled.tbody`
	border-radius: 8px;

	tr {
		border-radius: 8px;
		text-align: left;
		background: ${colors.gridBlack};
		color: ${colors.white};
		display: grid;
		justify-content: center;
		align-items: center;
		grid-template-columns: 12.3125rem 14.625rem 14rem 13.75rem 15.75rem 17.5rem 16.0625rem;
		min-height: 3.75rem;
		align-items: center;
		margin-right: -2px;
		margin-top: -2px;
		margin-left: -2px;

		:nth-child(odd) {
			background: ${colors.gridBlack};
		}
		:nth-child(even) {
			background: ${colors.rowTable};
		}
	}

	td {
		justify-content: center;
		align-items: center;
	}
	.status {
		border-left: 0;
		border-right: 1px solid ${colors.borderTable};
	}

	button.button-custom {
		width: auto;
		background-color: transparent;
		box-shadow: none;
		white-space: nowrap;

		svg {
			width: 1.875rem;
			height: 1.875rem;
		}
	}
`;

export const Head = styled.div`
	background: ${colors.background};
	height: 3.75rem;
	margin-bottom: -2px;
	border-radius: 8px;

	tr {
		text-align: left;
		height: 3.75rem;
		align-items: center;
		display: grid;
		grid-template-columns: 12.3125rem 14.625rem 14rem 13.75rem 15.75rem 17.5rem 16.0625rem;
		margin-right: -2px;
		margin-top: -2px;
		margin-left: -2px;
	}

	td {
		margin-top: -3px;
		color: ${colors.primaryBlue};
	}

	.ArrowOrder {
		background-color: transparent;
		border: 0;
		margin: 0;
		padding: 0;
	}
`;

export const Pagination = styled.div`
	width: 22.5rem;
	display: flex;
	justify-content: center;
`;

export const ErrorContent = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5rem 0 5rem 5rem;
	color: ${colors.offWhite};
	position: relative;
	gap: 5rem;

	svg.attention-outlined-icon {
		width: 8.75rem;
		height: 8.75rem;
		fill: ${colors.background};
		filter: drop-shadow(0px 0px 3px ${colors.yellow});
	}
`;

export const ErrorTexts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	p#error-title {
		font-size: 1.378rem;
		text-transform: uppercase;
		font-family: Jost;
		font-weight: 500;
		text-align: left;
	}
`;

export const ContainerText = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;

	p.error-text {
		font-size: 1.25rem;
		color: ${colors.lightGray};
		font-family: IBM Plex Sans;
		font-weight: 400;
	}

	button#link {
		background: transparent;
		font-size: 1.25rem;
		font-weight: bold;
		color: ${({ floaterIsOpen }) => floaterIsOpen ? colors.primaryBlue : colors.offWhite};
		cursor: pointer;
		pointer-events: ${({ floaterIsOpen }) => floaterIsOpen ? "none" : "auto"};

		:hover {
			color: ${colors.primaryBlue} !important;
			text-decoration: underline;
			text-underline-offset: 0.3rem;
		}
	}
`;

export const Options = styled.div`
	width: 10rem;

	ul {
		list-style: none;
	}

	button {
		background: none;
		padding: 0.5rem 1rem;
		font-size: 0.875rem;
		width: 100%;

		:hover {
			background: rgba(0, 0, 0, 0.1);
		}
	}
`;
