import React, { useState } from 'react';

import {
	ButtonCustom,
	SearchField,
	FiltroCalendario,
	CustomCheckboxPayment,
} from '../../..';
import { padStart } from '../../../../utils';
import { colors } from '../../../../assets/colors';

import * as S from './payment-styles';

const PaymentFilters = ({
	selectedDayRange,
	setSelectedDayRange,
	searchValue,
	setSearchValue,
	handleSubmit,
	setStatus,
	saveDataInStorage,
	loading,
	status,
}) => {
	const [dataInvalida, setDataInvalida] = useState({
		inicio: false,
		final: false,
	});
	const dataFormatada = () => {
		const start = selectedDayRange.from || {day: new Date().getDay(), month: new Date().getMonth(), year: new Date().getFullYear()};
		const end = selectedDayRange.to || {day: new Date().getDay(), month: new Date().getMonth(), year: new Date().getFullYear()};

		sessionStorage.setItem('PaymentSelectedDateStart', JSON.stringify(start));
		sessionStorage.setItem('PaymentSelectedDateFinal', JSON.stringify(end));
		if (selectedDayRange?.from && selectedDayRange?.to) {
			const inicio = selectedDayRange.from;
			const final = selectedDayRange.to;
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(final.day)}/${padStart(final.month)}/${final.year}`,
			};
		}

		if (selectedDayRange?.day) {
			const inicio = selectedDayRange;

			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		if (selectedDayRange?.to) {
			const inicio = selectedDayRange.from;
			const final = selectedDayRange.to;
			sessionStorage.setItem(
				'PaymentDataSelecionada',
				JSON.stringify({
					...inicio,
					start: new Date().toLocaleString('en-IN').split(' ')[0],
				})
			);
			sessionStorage.setItem(
				'PaymentDataSelecionada',
				JSON.stringify({
					...final,
					start: new Date().toLocaleString('en-IN').split(' ')[0],
				})
			);
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		return null;
	};

	return (
		<S.Container>
			<S.Form>
				<div className="calendar-input">
					<FiltroCalendario
						borderGreen
						disabled={loading}
						formatedRangeDate={dataFormatada}
						selectedDayRange={selectedDayRange}
						setSelectedDayRange={setSelectedDayRange}
						dataInvalida={dataInvalida}
						setDataInvalida={setDataInvalida}
						selected="anterior"
						transform="translate(43%)"
						backgroundColor={colors.background}
						width="17.375rem"
						fontSizePlaceholder="1rem"
					/>
				</div>
				<div className="search-input">
					<SearchField
						value={searchValue}
						placeholder="Filtre pelo nome do link"
						onChange={(e) => setSearchValue(e.target.value)}
						width="100%"
						border={`1px solid ${colors.lightGray}`}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
								handleSubmit();
							}
						}}
					/>
				</div>

				<S.Checkbox>
					<CustomCheckboxPayment
						id="Ativo"
						name="extract-transaction"
						text="Ativo"
						onChange={() => setStatus((prev) => prev === "active" ? "" : "active")}
						checked={status === "active" || status === ""}
					/>
					<CustomCheckboxPayment
						id="Cancelado"
						name="extract-transaction"
						text="Cancelado"
						onChange={() => setStatus((prev) => prev === "canceled" ? "" : "canceled")}
						checked={status === "canceled" || status === ""}
					/>
					<CustomCheckboxPayment
						id="Expirado"
						name="extract-transaction"
						text="Expirado"
						onChange={() => setStatus((prev) => prev === "expired" ? "" : "expired")}
						checked={status === "expired" || status === ""}
					/>
				</S.Checkbox>
				<S.ButtonGroup>
					<ButtonCustom
						outlined
						theme="green"
						onClick={() => {
							setSearchValue('');
							setStatus('');
							handleSubmit();
						}}
					>
						LIMPAR
					</ButtonCustom>
					<ButtonCustom
						theme="green"
						onClick={() => {
							saveDataInStorage();

							handleSubmit();
						}}
					>
						FILTRAR
					</ButtonCustom>
				</S.ButtonGroup>
			</S.Form>
		</S.Container>
	);
};

export default PaymentFilters;
