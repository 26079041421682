import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 2.5rem 1.5rem;
	margin-top: 1rem;
	p {
		font-size: 20px;
		color: ${colors.lightGray};
	}
`;

export const Title = styled.h2`
	font-family: Jost;
	font-style: normal;
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 2rem;
	letter-spacing: 0.04em;
	color: ${colors.offWhite};
	justify-content: flex-start;
	text-align: left;
	margin-bottom: 0.5rem;
`;

export const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 3rem;
	border-bottom: 1px solid ${colors.lightGray};

	p {
		font-size: 20px;
		color: ${colors.lightGray};
	}
`;
export const PaymentGroup = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 3rem;
	p {
		font-size: 20px;
		color: ${colors.lightGray};
	}
`;

export const EditionTitle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	width: 100%;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	color: ${colors.white};
	gap: 2rem;
	.edition-button {
		border-radius: 8px;
	}
`;

export const TextGroup = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	width: 100%;
	gap: 2rem;
`;

export const ItensMapGroup = styled.div`
	display: flex;
	padding-top: 5rem;
	flex-direction: column;
	justify-content: start;
	width: 55%;
	gap: 2rem;
	color: ${colors.offWhite};
	font-size: 1.25rem;
	padding-bottom: 4rem;
	p {
		display: flex;
		width: 100%;
		justify-content: end;
	}
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: start;
	width: 100%;
	gap: 2rem;
`;

export const TextField = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	gap: 1rem;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	color: ${colors.white};
`;
export const TextCustom = styled.div`
	color: ${colors.offWhite};
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
`;

export const ButtonGroup = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 3.5rem;
	justify-content: space-between;
	margin-top: 5rem;
	div {
		display: flex;
		gap: 1rem;
	}
`;

export const Table = styled.table`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Thead = styled.thead`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-top: 1.5rem;
`;
export const Tbody = styled.tbody`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-bottom: 1.5rem;
	border-bottom: 1px solid ${colors.gray};
`;

export const Tr = styled.tr`
	display: flex;
	width: 100%;
	gap: 3rem;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 21px;
	color: ${colors.offWhite};
	th {
		display: flex;
		justify-content: flex-start;
		padding-bottom: 1rem;
		width: 100%;
	}
	td {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		color: ${colors.lightGray};
	}
`;
