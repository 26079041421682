import styled from 'styled-components';
import { colors } from '../../../assets';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: ${({ width }) => width || '100%'};

	label {
		display: flex;
		align-items: center;
		color: ${({ isInvalid, readOnly }) =>
			readOnly ? colors.lightGray : isInvalid ? colors.red : colors.offWhite};
		font-size: 1rem;
		font-weight: 400;
		margin-bottom: 15px;
	}

	span#error {
		font-size: 0.75rem;
		color: ${colors.red};
		margin-top: 5px;
	}

	.info-icon {
		width: 1.5rem;
		height: 1.5rem;
		margin-left: 0.5rem;
	}
`;

export const Container = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: 2.5rem;
	border-bottom: 1px solid
		${({ isInvalid, disabled, readOnly }) =>
			disabled || readOnly
				? colors.gray
				: isInvalid
				? colors.primaryRed
				: colors.primaryBlue};

	svg {
		top: 50%;
		right: 1rem;
		position: absolute;
		transform: translateY(-50%);
	}

	input::-ms-reveal {
		display: none;
	}

	:focus-within {
		background: rgba(0, 0, 0, 0.08);
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
	}

	input {
		width: 100%;
		height: 100%;
		background: none;
		padding: ${({ isInvalid, paddingRight }) =>
			isInvalid
				? '0 2.5rem 0 1rem'
				: paddingRight
				? '0 3rem 0 1rem'
				: '0 1rem'};
		color: ${colors.lightGray};
		font-size: 1rem;
		font-weight: 400;
		${({ readOnly }) => readOnly && `cursor: not-allowed;`}
	}
`;

export const IconWrapper = styled.div`
	display: contents;

	svg {
		width: 1.75rem;
		height: 0.9375rem;
		cursor: pointer;

		:hover {
			opacity: 0.8;
		}
	}

	svg.visible-eye-icon {
		height: 0.85rem;
	}
`;
