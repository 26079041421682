import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../utils/i18n';
import toast from 'react-hot-toast';
import {
	ContractorConclusao,
	ContractorConfirmacao,
	ContractorContaBancaria,
	ContractorContatos,
	ContractorDados,
} from '../';
import {
	getRoleUser,
	getBanks,
	getBankAccountTypes,
	postContractor,
	getSearchContractor,
} from '../../../../services';
import { formatAccountTypes, justNumbers } from '../../../../utils';
import { LoaderCenterCircle } from '../../..';
import { ModalCancelarContractor } from '../../../molecules';

import * as s from './styled-contractor-content';

const ContractorContent = ({ steps, setSteps, loading, setLoading }) => {
	const { t } = useTranslation();

	const [cnpj, setCnpj] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [stateRegistry, setStateRegistry] = useState('');
	const [cityRegistry, setCityRegistry] = useState('');
	const [type, setType] = useState('');
	const [optionsRoleType, setOptionsRoleType] = useState([]);
	const [roleId, setRoleId] = useState('');
	const [active, setActive] = useState(true);
	const [disableData, setDisableData] = useState(true);
	const [country] = useState('Brazil');
	const [PJCompleted, setPJCompleted] = useState(false);
	const [errorData, setErrorData] = useState({});

	const [street, setStreet] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [streetNumber, setStreetNumber] = useState('');
	const [complement, setComplement] = useState('');
	const [neighborhood, setNeighborhood] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [sponsorName, setSponsorName] = useState('');
	const [sendEmail, setSendEmail] = useState(false);
	const [disableContact, setDisableContact] = useState(true);
	const [activatePhone, setActivatePhone] = useState(false);
	const [contactError, setContactError] = useState({});

	const [bankCode, setBankCode] = useState('');
	const [optionsBank, setOptionsBank] = useState([]);
	const [optionsBankType, setOptionsBankType] = useState([]);
	const [agencia, setAgencia] = useState('');
	const [agenciaDv, setAgenciaDv] = useState('');
	const [conta, setConta] = useState('');
	const [contaDv, setContaDv] = useState('');
	const [disableBankAccount, setDisableBankAccount] = useState(true);
	const [activeAgencia, setActiveAgencia] = useState(false);
	const [activeConta, setActiveConta] = useState(false);
	const [errorBankAccount, setErrorBankAccount] = useState({});

	const [isCancelarCriacaoOpen, setIsCancelarCriacaoOpen] = useState(false);
	const [contractorsFilters, setContractorsFilters] = useState();

	const typeOfError = (err) => {
		if (
			err?.data?.extra?.message ===
			'The email address is already in use by another account.'
		) {
			return t('EmailErrorMessage');
		}
	};

	useEffect(() => {
		getBanks()
			.then((resp) =>
				setOptionsBank(
					resp.map((item) => ({ value: item.code, label: item.name }))
				)
			)
			.catch(() => toast.error('Erro ao buscar bancos'));

		getBankAccountTypes()
			.then((resp) =>
				setOptionsBankType(
					resp.map((item) => ({
						value: item,
						label: formatAccountTypes(item),
					}))
				)
			)
			.catch(() => toast.error('Erro ao buscar tipos de conta'));

		getRoleUser()
			.then((resp) => {
				setOptionsRoleType(
					resp.map((item) => {
						if (item.label === 'subcontractor')
							return { value: item.id, label: 'SubContratante' };
						if (item.label === 'contractor')
							return { value: item.id, label: 'Contratante' };
						return { value: item.id, label: 'Administrador' };
					})
				);
			})
			.catch(() => toast.error('Erro ao buscar tipos de usuário'))
			.finally(() => setLoading(false));
	}, []);

	async function saveContractor() {
		const newDataContractor = {
			cnpj: `${justNumbers(cnpj)}`,
			companyName,
			stateRegistry,
			cityRegistry,
			roleId: Number(roleId.value),
			active,
			contact: {
				phone: `+55${justNumbers(phone)}`,
				email,
				sponsorName,
			},
			address: {
				street,
				streetNumber,
				zipcode,
				country,
				state,
				city,
				neighborhood,
				complement,
			},
			bankAccount: {
				bankCode: bankCode.value,
				agencia,
				agenciaDv,
				conta,
				type: type.value,
				contaDv,
			},
		};

		postContractor(newDataContractor, sendEmail)
			.then(() => {
				setSteps(5);
			})
			.catch((err) => {
				toast.error(typeOfError(err.response));
			});
	}

	useEffect(() => {
		getSearchContractor(justNumbers(cnpj))
			.then((resp) => {
				setContractorsFilters(resp.data);
			})
			.catch(() => toast.error('Erro ao filtrar contratantes'))
			.finally(() => setLoading(false));
	}, [steps === 5]);

	return !loading ? (
		<s.Container>
			{steps === 1 && (
				<ContractorDados
					setSteps={setSteps}
					steps={steps}
					cnpj={cnpj}
					setCnpj={setCnpj}
					companyName={companyName}
					setCompanyName={setCompanyName}
					stateRegistry={stateRegistry}
					setStateRegistry={setStateRegistry}
					cityRegistry={cityRegistry}
					setCityRegistry={setCityRegistry}
					type={type}
					setType={setType}
					optionsRoleType={optionsRoleType}
					setOptionsRoleType={setOptionsRoleType}
					roleId={roleId}
					setRoleId={setRoleId}
					active={active}
					setActive={setActive}
					state={state}
					disableData={disableData}
					setDisableData={setDisableData}
					setIsCancelarCriacaoOpen={setIsCancelarCriacaoOpen}
					errorData={errorData}
					setErrorData={setErrorData}
					PJCompleted={PJCompleted}
					setPJCompleted={setPJCompleted}
				/>
			)}
			{steps === 2 && (
				<ContractorContatos
					setSteps={setSteps}
					steps={steps}
					zipcode={zipcode}
					setZipcode={setZipcode}
					street={street}
					setStreet={setStreet}
					streetNumber={streetNumber}
					setStreetNumber={setStreetNumber}
					complement={complement}
					setComplement={setComplement}
					neighborhood={neighborhood}
					setNeighborhood={setNeighborhood}
					city={city}
					setCity={setCity}
					state={state}
					setState={setState}
					phone={phone}
					setPhone={setPhone}
					email={email}
					setEmail={setEmail}
					sponsorName={sponsorName}
					setSponsorName={setSponsorName}
					sendEmail={sendEmail}
					setSendEmail={setSendEmail}
					disableContact={disableContact}
					setDisableContact={setDisableContact}
					setIsCancelarCriacaoOpen={setIsCancelarCriacaoOpen}
					contactError={contactError}
					setContactError={setContactError}
					activatePhone={activatePhone}
					setActivatePhone={setActivatePhone}
				/>
			)}
			{steps === 3 && (
				<ContractorContaBancaria
					setSteps={setSteps}
					steps={steps}
					bankCode={bankCode}
					setBankCode={setBankCode}
					optionsBank={optionsBank}
					setOptionsBank={setOptionsBank}
					contaDv={contaDv}
					setContaDv={setContaDv}
					agencia={agencia}
					setAgencia={setAgencia}
					agenciaDv={agenciaDv}
					setAgenciaDv={setAgenciaDv}
					conta={conta}
					setConta={setConta}
					disableBankAccount={disableBankAccount}
					setDisableBankAccount={setDisableBankAccount}
					tipo={type}
					setTipo={setType}
					optionsBankType={optionsBankType}
					setOptionsBankType={setOptionsBankType}
					setIsCancelarCriacaoOpen={setIsCancelarCriacaoOpen}
					errorBankAccount={errorBankAccount}
					setErrorBankAccount={setErrorBankAccount}
					activeAgencia={activeAgencia}
					activeConta={activeConta}
					setActiveAgencia={setActiveAgencia}
					setActiveConta={setActiveConta}
				/>
			)}
			{steps === 4 && (
				<ContractorConfirmacao
					setSteps={setSteps}
					steps={steps}
					optionsRoleType={optionsRoleType}
					roleId={roleId}
					active={active}
					companyName={companyName}
					cnpj={cnpj}
					stateRegistry={stateRegistry}
					cityRegistry={cityRegistry}
					sendEmail={sendEmail}
					street={street}
					complement={complement}
					email={email}
					city={city}
					state={state}
					streetNumber={streetNumber}
					neighborhood={neighborhood}
					phone={phone}
					zipcode={zipcode}
					sponsorName={sponsorName}
					optionsBank={optionsBank}
					contaDv={contaDv}
					agencia={agencia}
					agenciaDv={agenciaDv}
					conta={conta}
					bankCode={bankCode}
					saveContractor={saveContractor}
					type={type}
					setIsCancelarCriacaoOpen={setIsCancelarCriacaoOpen}
				/>
			)}
			{steps === 5 && (
				<ContractorConclusao
					setSteps={setSteps}
					steps={steps}
					loading={loading}
					setLoading={setLoading}
					contractorsFilters={contractorsFilters}
				/>
			)}
			<ModalCancelarContractor
				isOpen={isCancelarCriacaoOpen}
				closeModal={() => setIsCancelarCriacaoOpen(false)}
			/>
		</s.Container>
	) : (
		<s.Container>
			<LoaderCenterCircle />
		</s.Container>
	);
};

export default ContractorContent;
