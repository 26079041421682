import styled from 'styled-components';
import { colors } from '../../../../../assets';

export const Header = styled.div`
	display: flex;
	gap: 1.5rem;
	width: 100%;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: ${({ step }) =>
		step > 1 ? '0.625rem 0.9375rem' : '0.9375rem 1.25rem'};
	${({ step }) => step > 1 && `border: 1px solid ${colors.gray}`};

	div {
		display: flex;
		flex-direction: column;

		.loading-rectangle {
			height: 25px;
			margin: 5px 0 0 0;
		}

		h2 {
			color: ${colors.lightGray};
			font-size: 1.25rem;
			font-weight: 400;
		}

		p {
			color: ${colors.white};
			font-family: Jost;
			font-size: 1.38rem;
			font-weight: 600;

			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}
	}

	div#recebedor {
		max-width: 21.875rem;
	}
	div#banco {
		max-width: 21.875rem;
	}

	div:not(#recebedor, .loading-rectangle) {
		border-left: ${({ step }) => (step > 1 ? '1px' : '2px')} solid
			${colors.gray};
		padding-left: 10px;
	}
`;
