import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { colors } from '../../../assets';

// Tipos: Audio. BallTriangle, Bars, Circles, Grid, Hearts, MutatingDots, Oval, Puff,
// RevolvingDots, Rings, TailSpin, ThreeDots, Trinagle, Watch

const CustomLoader = ({ size, color, style, type }) => (
	<div className="loader" style={style}>
		<Loader
			type={type || 'TailSpin'}
			color={color || colors.white}
			height={size || 50}
			width={size || 50}
			style={style || { marginBottom: '-0.375rem' }}
		/>
	</div>
);

export default CustomLoader;
