/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDom from 'react-dom';
import { useHistory } from 'react-router-dom';
import { colors, Icons } from '../../../assets';
import { ButtonCustom } from '../../atoms/';

import * as S from './styled-modal';

const Modal = (props) => {
	const {
		onClickOutside,
		open,
		onClose,
		title,
		textButton1,
		textButton2,
		onClickButton1,
		onClickButton2,
		buttonType1,
		buttonType2,
	} = props;

	const history = useHistory();

	function handleRedirect(url) {
		history.push(url);
	}

	if (!open) return null;

	return ReactDom.createPortal(
		<>
			<S.Overlay onClick={onClickOutside ? onClose : null} />
			<S.Modal>
				<ButtonCustom
					disableParsedHover
					type="button"
					onClick={onClose}
					backgroundColor="transparent"
					className="buttom-custom"
					startIcon={
						<Icons.Close
						// style={{ display: 'block', width: '9px', height: '9px' }}
						// title="Fechar"
						/>
					}
				/>

				<S.Title>{title}</S.Title>
				<S.ButtonGroup>
					{textButton1 && (
						<S.Button
							textColor={
								buttonType1 === 'primary' ? colors.darkGray : colors.primaryBlue
							}
							background={buttonType1 === 'primary' && colors.degradeeTurquoise}
							backgroundColor={buttonType1 === 'secondary' && colors.gray}
							onClick={() => handleRedirect(onClickButton1)}
						>
							<S.ButtonText>{textButton1}</S.ButtonText>
						</S.Button>
					)}
					{textButton2 && (
						<S.Button
							textColor={
								buttonType2 === 'primary' ? colors.darkGray : colors.primaryBlue
							}
							background={
								buttonType2 === 'primary' ? colors.degradeeTurquoise : 'none'
							}
							backgroundColor={
								buttonType2 === 'secondary' ? colors.gray : 'none'
							}
							letterSpacing="normal"
							buttonType={buttonType2}
							onClick={() => {
								handleRedirect(onClickButton2);
							}}
						>
							<S.ButtonText>{textButton2}</S.ButtonText>
						</S.Button>
					)}
				</S.ButtonGroup>
			</S.Modal>
		</>,
		document.getElementById('portal')
	);
};

export default Modal;
