import React from 'react';
import { Icons } from '../../../../assets/icons';
import { LoadingRectangle } from '../../../../components';

import * as S from './styled-card-small';

const CardSmall = (props) => {
	const {
		id,
		div,
		title,
		totalElements,
		averageAmount,
		totalAmount,
		loading,
		borderRadius,
	} = props;
	const valorCard = averageAmount || totalAmount || totalElements;
	return (
		<S.Container id={id} div={div} borderRadius={borderRadius}>
			<S.Infos id={id}>
				<S.StartIcon id={id}>
					{id === 'transactions-number' && (
						<Icons.NumeroTransacoes
							style={{ height: '3.4rem', width: '3.4rem' }}
						/>
					)}
					{id === 'total-amount' && (
						<Icons.VolumeTransacionado
							style={{ height: '3.4rem', width: '3.4rem' }}
						/>
					)}
					{id === 'average-mi' && (
						<Icons.IconMi style={{ height: '3.4rem', width: '3.4rem' }} />
					)}
					{id === 'average-me' && (
						<Icons.IconME style={{ height: '3.4rem', width: '3.4rem' }} />
					)}
					{id === 'average-ticket' && (
						<Icons.GraphResumo style={{ height: '3.4rem', width: '3.4rem' }} />
					)}
				</S.StartIcon>
				<S.MidContent>
					<S.Title>{title}</S.Title>
					<h2>
						{!loading ? (
							!Number.isNaN(valorCard) && valorCard ? (
								valorCard
							) : (
								'R$ 0,00'
							)
						) : (
							<LoadingRectangle id={id} />
						)}
					</h2>
				</S.MidContent>
				{id === 'transactions-number' && (
					<Icons.GraficoTransacoes
						style={{ height: '3.1875rem', width: '5.125rem' }}
					/>
				)}
				{id === 'total-amount' && (
					<Icons.GraficoVolumeTransacionado
						style={{
							height: '2.5rem',
							width: '6rem',
							transform: 'translateY(-25%)',
						}}
					/>
				)}
			</S.Infos>
		</S.Container>
	);
};

export default CardSmall;
