import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.25rem 3.44rem 3.44rem 3.44rem;

	img {
		width: 17.03rem;
		height: 21.06rem;
		margin: 0 auto 1.88rem auto;
	}

	p {
		font-size: 1.13rem;
		text-align: center;
		color: ${colors.primaryBlue};
		max-width: 27.5rem;
	}

	.button-custom {
		background: ${colors.primaryBlue};
		width: 15.63rem;
		margin: 2.5rem auto 0 auto;
		border: none;
	}
`;
