/* eslint-disable radix */

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	DataGrid,
	GridToolbarExport,
	GridToolbarContainer,
	GridOverlay,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import xlsx from 'json-as-xlsx';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Icons } from '../../../../assets/icons';
import {
	FloaterReact,
	FloaterOverlay,
	SelectReact,
	Pagination,
	LoadingRectangle,
	TransactionSwitch,
} from '../../../../components';
import {
	formatarParaReais,
	translateStatus,
	translatePaymentMethod,
} from '../../../../utils';
import { colors } from '../../../../assets';
import { DetalhesTransacoes } from '../../../molecules/gerador-pdf-transacao';

import * as S from './styles';

const Table = ({
	excelTransactions,
	rows,
	loading,
	itemsPerPage,
	setItemsPerPage,
	optionsItemsPage,
	totalElements,
	page,
	setPage,
	totalValue,
	handleSubmit,
	renderTable,
	setRenderTable,
}) => {
	const history = useHistory();

	const stringPeriod = (string, pos) => {
		const newString = string.split('.');
		if (newString[pos]) return newString[pos];
		if (newString[0]) return newString[0];
	};

	const columnsGrid = [
		{
			field: 'status',
			headerName: 'Status',
			flex: 1,
			disableColumnMenu: true,
			cellClassName: () => 'cell-table data',
			renderCell: ({ value, row }) => (
				<S.Row value={value}>
					<p className="status">{translateStatus(value)}</p>
					{value === 'refused' && (
						<FloaterReact
							hover
							placement="right"
							title={`${
								row?.acquirerResponseCode ? row.acquirerResponseCode : ' '
							} ${row?.acquirerResponseCode ? '-' : ''} ${
								stringPeriod(row.acquirerMessage, 0) ||
								'Transação não autorizada.'
							}`}
							content={
								stringPeriod(row.acquirerMessage, 1) ||
								'Você pode tentar refazer a transação. Se o erro persistir, oriente o portador a entrar em contato com o banco emissor do cartão.'
							}
						>
							<Icons.Question
								fill={colors.primaryRed}
								style={{
									width: '12px',
									height: '12px',
									transform: 'translateY(-20%)',
								}}
							/>
						</FloaterReact>
					)}
				</S.Row>
			),
			valueFormatter: ({ value }) => `${translateStatus(value)}`,
		},
		{
			field: 'date',
			type: 'date',
			headerName: 'Data da transação',
			flex: 1,
			disableColumnMenu: true,
			headerClassName: 'header-table data',
			cellClassName: () => 'cell-table data',
			renderCell: ({ value }) => (
				<S.Data>
					{value && <p className="status">{format(value, 'dd/MM/yyyy')}</p>}
				</S.Data>
			),
			valueFormatter: ({ value }) => `${format(value, 'dd/MM/yyyy')}`,
		},
		{
			field: 'transactionId',
			headerName: 'ID da Transação ',
			flex: 1,
			disableColumnMenu: true,
			renderCell: ({ value }) => (
				<S.Data>
					<p className="data">{value}</p>
				</S.Data>
			),
		},
		{
			field: 'name',
			headerName: 'Nome',
			flex: 1,
			disableColumnMenu: true,
			renderCell: ({ value }) => (
				<S.Data>
					<p className="data">{value}</p>
				</S.Data>
			),
		},
		{
			field: 'paymentMethod',
			headerName: 'Forma de pagamento',
			flex: 1,
			disableColumnMenu: true,
			renderCell: ({ value }) => (
				<S.Data>
					<p className="data">{translatePaymentMethod(value)}</p>
				</S.Data>
			),
			valueFormatter: ({ value }) => `${translatePaymentMethod(value)}`,
		},
		{
			field: 'transactionValue',
			headerName: 'Valor',
			flex: 1,
			disableColumnMenu: true,
			cellClassName: () => 'cell-table',
			renderCell: ({ value }) => (
				<S.Data>
					<p className="status">{formatarParaReais(value, true)}</p>
				</S.Data>
			),
		},
	];

	const columnsExport = [
		{
			id: 'status',
			displayName: 'Status',
		},
		{
			id: 'date',
			displayName: 'Data',
		},
		{
			id: 'transactionId',
			displayName: 'ID da Transação ',
		},
		{
			id: 'name',
			displayName: 'Nome',
		},
		{
			id: 'paymentMethod',
			displayName: 'Forma de pagamento',
		},
		{
			id: 'transactionValue',
			displayName: 'Valor',
		},
	];

	const handleRowClick = (param) =>
		history.push(`/transacoes/${param.row.transactionId}`);

	function exportarCsv() {
		const botaoExportar = document.getElementsByClassName('MuiButton-label')[0];

		if (botaoExportar) {
			botaoExportar.click();

			setTimeout(() => {
				const exportar =
					document.getElementsByClassName('MuiListItem-button')[0];
				if (exportar) {
					const itemExportar = exportar.getElementsByClassName(
						'MuiTouchRipple-root'
					)[0];
					itemExportar.click();
				}
			}, 100);
		}
	}

	const desabilitado = loading || rows?.length < 1 || !rows;

	const exportarExcel = () => {
		const botaoExportar = document.getElementById('baixar-extrato-xlsx');
		if (botaoExportar) botaoExportar.click();
	};

	const exportarPdfTransacoes = () => {
		return (
			rows !== undefined && (
				<PDFDownloadLink
					document={<DetalhesTransacoes loading={loading} data={rows} />}
					fileName="transacoes.pdf"
				>
					<button type="button">PDF</button>
				</PDFDownloadLink>
			)
		);
	};

	const opcoesExportacao = () => (
		<S.Options>
			<ul>
				<li>
					<GridToolbarContainer>
						<GridToolbarExport
							disabled={desabilitado}
							onClick={(e) => {
								e.preventDefault();
								exportarCsv();
							}}
							style={{ color: `${colors.black}` }}
							startIcon={false}
							csvOptions={{
								utf8WithBom: true,
								fileName: 'transacoes',
							}}
						/>
					</GridToolbarContainer>
				</li>
				<li>
					<button
						type="button"
						onClick={() => exportarExcel(false)}
						disabled={desabilitado}
					>
						Excel
					</button>
				</li>
				<li>
					{!loading && <div className="button">{exportarPdfTransacoes()}</div>}
				</li>
			</ul>
		</S.Options>
	);

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<S.CustomToolbar>
					<S.InfoTransactions>
						<div>
							<h4>{totalElements} &nbsp;</h4>
							<p>transações &nbsp; - &nbsp;</p>{' '}
						</div>
						<div>
							<p>total capturado de &nbsp; </p>
							<h4>{totalValue}</h4>
						</div>
					</S.InfoTransactions>
					<div>
						<FloaterOverlay
							placement="bottom"
							background={colors.white}
							padding="0"
							content={opcoesExportacao()}
							disabled={desabilitado}
						/>
					</div>

					<S.SelectDiv>
						<SelectReact
							value={itemsPerPage}
							onChange={(opt) => {
								setItemsPerPage(opt);
								setPage(1);
								sessionStorage.setItem(
									'transactionItemsPerPage',
									JSON.stringify(opt)
								);
								sessionStorage.setItem('transactionPage', JSON.stringify(1));
								handleSubmit(page);
							}}
							isSearchable={false}
							options={optionsItemsPage}
							readOnly={loading}
							outlined
							height="3.125rem"
							width="15.31rem"
						/>
					</S.SelectDiv>

					{Math.ceil(totalElements / itemsPerPage?.value) > 1 && (
						<S.Pagination>
							<Pagination
								paginaAtual={page - 1}
								totalPaginas={Math.ceil(totalElements / itemsPerPage?.value)}
								onClick={(pagina) => {
									setPage(pagina.selected + 1);
									sessionStorage.setItem(
										'transactionPage',
										JSON.stringify(pagina.selected + 1)
									);
									handleSubmit(pagina.selected + 1);
								}}
								disabled={loading}
							/>
						</S.Pagination>
					)}
					<S.SwitchContainer>
						<TransactionSwitch
							className="TransactionSwitch"
							id="TransactionSwitch"
							checked={renderTable}
							onChange={() => setRenderTable(!renderTable)}
						/>
					</S.SwitchContainer>
				</S.CustomToolbar>
			</GridToolbarContainer>
		);
	}

	function FooterToolbar() {
		return (
			<GridToolbarContainer
				style={{ padding: '20px 0', alignContent: 'center' }}
			>
				<S.CustomToolbar style={{ justifyContent: 'flex-end' }}>
					{Math.ceil(totalElements / itemsPerPage?.value) > 1 && (
						<S.Pagination>
							<Pagination
								paginaAtual={page - 1}
								totalPaginas={Math.ceil(totalElements / itemsPerPage?.value)}
								onClick={(pagina) => {
									setPage(pagina.selected + 1);
									sessionStorage.setItem(
										'transactionPage',
										JSON.stringify(pagina.selected + 1)
									);
									handleSubmit(pagina.selected + 1);
								}}
								disabled={loading}
							/>
						</S.Pagination>
					)}
				</S.CustomToolbar>
			</GridToolbarContainer>
		);
	}

	function dadosTabela() {
		if (rows && !loading) {
			return rows.map((linha) => ({
				status: linha.status,
				acquirerResponseCode: linha.acquirerResponseCode,
				acquirerMessage: linha.acquirerMessage,
				chargebackCode: linha.chargebackCode,
				chargebackMessage: linha.chargebackMessage,
				date: linha?.date ? new Date(linha.date) : null,
				transactionId: linha.transactionId,
				name: linha.name,
				paymentMethod: linha.paymentMethod,
				transactionValue: linha.transactionValue / 100,
			}));
		}

		return [];
	}

	function LoadingTable() {
		return (
			<GridOverlay>
				<S.LoadingContainer>
					{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() =>
						[1, 2, 3, 4, 5, 6].map((item) => <LoadingRectangle key={item} />)
					)}
				</S.LoadingContainer>
			</GridOverlay>
		);
	}

	const settingsExportToExcel = {
		fileName: 'Transacoes',
		extraLength: 3,
		writeOptions: {},
	};

	function exportarComo() {
		let columns = [];
		const content = [];
		let obj = {};

		columns = columnsExport.map((col) => {
			return { label: col.displayName, value: col.id };
		});

		excelTransactions.forEach((linha) => {
			obj = {
				status: translateStatus(linha.status),
				date: linha?.date
					? new Date(linha.date).toLocaleDateString('pt-BR')
					: '',
				transactionId: linha.transactionId,
				name: linha?.name || '',
				paymentMethod: translatePaymentMethod(linha.paymentMethod),
				transactionValue: formatarParaReais(linha.transactionValue / 100, true),
			};

			content.push(obj);
		});

		const transactions = [{ sheet: 'Transacoes', columns, content }];

		xlsx(transactions, settingsExportToExcel);
	}

	return (
		<S.Container>
			<S.Table>
				<button
					type="button"
					id="baixar-extrato-xlsx"
					style={{ display: 'none' }}
					onClick={() => exportarComo()}
				>
					a
				</button>

				<DataGrid
					getRowId={(row) => row.transactionId}
					getRowClassName={() => 'row-table'}
					rows={dadosTabela()}
					columns={columnsGrid}
					autoHeight
					style={{ height: loading ? '750px' : 'auto' }}
					pageSize={itemsPerPage?.value}
					components={{
						Toolbar: CustomToolbar,
						LoadingOverlay: LoadingTable,
						Footer: FooterToolbar,
					}}
					density="standard"
					localeText={{
						toolbarExport: 'CSV',
					}}
					onCellClick={null}
					loading={loading}
					csvOptions={{
						utf8WithBom: true,
					}}
					onRowClick={handleRowClick}
				/>
			</S.Table>
		</S.Container>
	);
};

export default Table;
