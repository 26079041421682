import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	padding: 2.5rem 1.5rem;
	margin-top: 1rem;
	h1 {
		font-family: 'Jost';
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 32px;
		text-align: center;
		letter-spacing: 0.04em;
		color: ${colors.primaryBlue};
	}
	p {
		font-size: 20px;
		color: ${colors.lightGray};
	}
	div {
		margin-top: 1rem;
		display: flex;
		flex-direction: row;
		gap: 1rem;
	}
	div#button-container {
		margin-top: 4rem;
	}
`;

export const Link = styled.div`
	display: flex;
	align-items: center;
	color: ${colors.primaryBlue};
	font-size: 20px;
	.copy {
		border: 0;
	}
	.text {
		font-family: 'Jost';
		font-style: normal;
		font-weight: 600;
		font-size: 1.375rem;
		color: ${colors.primaryBlue};
	}

	div#link-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.7rem;

		button {
			background: transparent;
			cursor: pointer;
			pointer-events: ${({ floaterIsOpen }) =>
				floaterIsOpen ? 'none' : 'auto'};

			:hover {
				color: ${colors.primaryBlue};
				text-decoration: underline;
				text-underline-offset: 0.3rem;
			}
		}
	}
`;
