import AcquirerTimeout from './acquirer-timeout-icon';
import AddPerson from './add-person-icon';
import Antifraude from './antifraude-icon';
import Arrow from './Arrow.svg';
import ArrowUpRight from './arrow-up-right';
import ArrowUpDown from './arrowUpDown';
import Boleto from './boleto-icon';
import Calendar from './calendar-icon';
import Checked from './checked-icon';
import CreditCard from './credit-card-icon';
import CriarPayment from './criarPayment';
import Close from './close-icon';
import Copy from './copy';
import ChekedCircle from './CheckedCircle';
import DesativarLink from './desativarlink';
import EditPencil from './EditPencil';
import Filter from './filter-icon';
import GraficoTransacoes from './grafico-transacoes-icon';
import GraficoVolumeTransacionado from './grafico-volume-transacionado';
import Graph from './graph-icon';
import GraphResumo from './GraphResumo';
import Info from './info-icon';
import LeftArrow from './LeftArrow.svg';
import Logotipo from './logo-payos';
import Minus from './minus-icon';
import NumeroTransacoes from './numero-transacoes-icon';
import Perfil from './perfil-icon';
import Pix from './pix-icon';
import Plus from './plus-icon';
import PlusCircleIcon from './plus-circle';
import SearchIcon from './SearchIcon.svg';
import SetaCurva from './seta-curva-icon';
import TicketMedio from './ticket-medio-icon';
import TicketMedioMiIcon from './ticket-medio-mi-icon';
import IconMi from './iconMI';
import IconME from './iconME';
import UnHappySearch from './unHappySearch';
import Upload from './upload-icon';
import VolumeTransacionado from './volume-transacionado-icon';
import bodyIcons from './body';
import filesIcons from './files';
import notificationIcons from './notifications';
import setasIcons from './setas';
import sidemenuIcons from './sidemenu';
import statusIcons from './status';
import Shield from './shield-icon';
import ArrowEnvironment from './arrow-environment';
import TransactionTableSwitchIcon from './transactionSwitchGraphic';
import TransactionSwitchGraphicIcon from './transactionSwitchTable';

export const Icons = {
	...bodyIcons,
	...filesIcons,
	...notificationIcons,
	...setasIcons,
	...sidemenuIcons,
	...statusIcons,
	AcquirerTimeout,
	AddPerson,
	Antifraude,
	Arrow,
	ArrowUpRight,
	ArrowUpDown,
	Boleto,
	Calendar,
	Checked,
	CreditCard,
	CriarPayment,
	Close,
	Copy,
	ChekedCircle,
	DesativarLink,
	EditPencil,
	Filter,
	GraficoTransacoes,
	GraficoVolumeTransacionado,
	Graph,
	GraphResumo,
	IconMi,
	IconME,
	Info,
	LeftArrow,
	Logotipo,
	Minus,
	NumeroTransacoes,
	Perfil,
	Pix,
	Plus,
	PlusCircleIcon,
	SearchIcon,
	SetaCurva,
	TicketMedio,
	TicketMedioMiIcon,
	UnHappySearch,
	Upload,
	VolumeTransacionado,
	Shield,
	ArrowEnvironment,
	TransactionTableSwitchIcon,
	TransactionSwitchGraphicIcon,
};
