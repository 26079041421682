import React from 'react';
import { Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { formatarParaReais } from '../../../../utils';

Font.register({
	family: 'IBM Plex Sans',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/ibmplexsans/v13/zYXgKVElMYYaJe8bpLHnCwDKtdbUFI5NadY.ttf',
		},
		{
			src: 'https://fonts.gstatic.com/s/ibmplexsans/v13/zYX9KVElMYYaJe8bpLHnCwDKjQ76MIZmdd_qFmo.ttf',
			fontWeight: 600,
		},
	],
});

const columnTitle = {
	textAlign: 'left',
	boxSizing: 'border-box',
	paddingLeft: '8px',
	borderRight: `1px solid #EDEDED`,
	fontWeight: 'bold',
};

const textContent = {
	color: 'rgba(0, 0, 0, 0.60)',
	fontSize: 8,
	fontFamily: 'IBM Plex Sans',
	textAlign: 'left',
	paddingLeft: '8px',
	boxSizing: 'border-box',
};

const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		justifyContent: 'flex-start',
		paddingTop: 20,
		paddingBottom: 35,
		paddingHorizontal: 35,
	},
	section: {
		margin: '10 0 10 0',
		paddingBottom: 10,
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
	},

	article: {
		margin: '10 0 10 0',
		padding: '10px 0 10px 35px',
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
	},
	footer: {
		margin: '10 0 10 0',
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
		color: '#777',
		borderBottom: '1 solid #DDD',
	},
	title: {
		fontSize: 12,
		fontFamily: 'IBM Plex Sans',
		fontWeight: 600,
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'center',
		marginBottom: 20,
	},
	text1: {
		...textContent,
		width: '11%',
	},
	text2: {
		...textContent,
		width: '12%',
	},
	text3: {
		...textContent,
		width: '12%',
	},
	text4: {
		...textContent,
		width: '13.5%',
	},
	text5: {
		...textContent,
		width: '13.5%',
	},
	text6: {
		...textContent,
		width: '13.5%',
	},
	text7: {
		...textContent,
		width: '13.5%',
	},
	text8: {
		...textContent,
		width: '11%',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		borderTop: '1px solid #EDEDED',
		paddingTop: 8,
		paddingBottom: 8,
		gap: 12,
		width: '100%',
	},

	header: {
		borderRight: '1px solid',
	},
	lineInfo: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '10px 0 10px 10px',
	},
	lineInfoBorder: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '10px 0 10px 10px',
		opacity: '0.1',
		borderBottom: '1 solid #DDD',
	},
	textBold: {
		fontWeight: 600,
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
	},
	column1: {
		...columnTitle,
		width: '11%',
	},
	column2: {
		...columnTitle,
		width: '12%',
	},
	column3: {
		...columnTitle,
		width: '12%',
	},
	column4: {
		...columnTitle,
		width: '13.5%',
	},
	column5: {
		...columnTitle,
		width: '13.5%',
	},
	column6: {
		...columnTitle,
		width: '13.5%',
	},
	column7: {
		...columnTitle,
		width: '13.5%',
	},
	column8: {
		...columnTitle,
		borderRight: 'none',
		width: '11%',
	},
});

const descriptionPaymentMethod = (type, installment) => {
	const descriptionMessage = {
		credit:
			installment !== null
				? `Cartão de crédito - Parcela ${installment}`
				: 'Pix',
		waiting_funds: 'Aguardando pagamento',
		pending_review: 'Aguardando pagamento',
		chargeback: `Estornada - Parcela ${installment}`,
		paid: 'Pago',
		processing: 'Processando',
		refund: 'Estorno',
		refused: 'Recusada',
		pending_refused: 'Reembolso pendente',
		refunded: 'Reembolsada',
		default: 'sem descrição',
	};
	return descriptionMessage[type] || descriptionMessage.default;
};

const ExtractPdfTemplate = ({ data, columnEntry, columnOut }) => {
	const formatData = (value) => {
		const date = new Date(value);

		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
	};

	return (
		<Page size="A4" style={styles.page}>
			<Text style={styles.title}>Extratos</Text>
			<View style={styles.section}>
				<View style={styles.row}>
					<Text style={styles.column1}>Data</Text>
					<Text style={styles.column2}>{'ID da \n operação'}</Text>
					<Text style={styles.column3}>ID da transação</Text>
					{descriptionPaymentMethod ? (
						<Text style={styles.column4}>Descrição</Text>
					) : null}
					{columnEntry ? <Text style={styles.column5}>Entradas</Text> : null}
					{columnOut ? <Text style={styles.column6}>Saídas</Text> : null}
					<Text style={styles.column7}>Total</Text>
					<Text style={styles.column8}>Mercado</Text>
				</View>
			</View>
			{data.map((row, i) => {
				return (
					<View key={i} style={styles.row} wrap={false}>
						<Text style={styles.text1}>{formatData(row.operationDate)}</Text>

						<Text style={styles.text2}>{row.operationId}</Text>

						<Text style={styles.text3}>{row.transactionId}</Text>

						<Text style={styles.text4}>
							{descriptionPaymentMethod(row.type, row?.installment)}
						</Text>

						{columnEntry ? (
							<Text style={styles.text5}>
								{formatarParaReais(row.grossValue / 100, true)}
							</Text>
						) : null}

						{columnOut ? (
							<Text style={styles.text6}>
								{formatarParaReais(row.taxValue / 100, true)}
							</Text>
						) : null}

						<Text style={styles.text7}>
							{formatarParaReais(row.netValue / 100, true)}
						</Text>

						<Text style={styles.text8}>{row.tipoMercado}</Text>
					</View>
				);
			})}
		</Page>
	);
};

export default ExtractPdfTemplate;
