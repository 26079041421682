import React from 'react';
import { useHistory } from 'react-router';
import { ButtonCustom } from '../../../../components';
import { colors, Icons } from '../../../../assets';
import { useRecipients } from '../../../../hooks';
import * as s from './styled-avisos';

const Avisos = () => {
	const history = useHistory();
	const { newRecipientId, alertConclusao, setStep } = useRecipients();
	const sucessoCriarRecebedor = alertConclusao === 'sucesso';

	const handleOnClick = () => {
		if (sucessoCriarRecebedor) history.push(`/recebedores/${newRecipientId}`);
		else setStep(4);
	};

	return (
		<s.Container>
			{sucessoCriarRecebedor ? (
				<s.Content>
					<s.Icon>
						<Icons.SuccessIcon />
					</s.Icon>
					<s.Title className="success">Recebedor criado com sucesso!</s.Title>
					<s.Subtitle>
						Você já pode visualizá-lo na seção Recebedores
					</s.Subtitle>
				</s.Content>
			) : (
				<s.Content>
					<s.Icon>
						<Icons.ErrorAlertIcon />
					</s.Icon>
					<s.Title className="error">Erro nas informações preenchidas</s.Title>
					<s.Subtitle>
						Verifique novamente os dados inseridos <br /> e tente novamente
					</s.Subtitle>
				</s.Content>
			)}

			<s.ButtonGroup>
				<ButtonCustom
					outlined
					backgroundColor={colors.secondaryDarkGray}
					width="6.25rem"
					onClick={() => history.push('/recebedores')}
				>
					Sair
				</ButtonCustom>
				<ButtonCustom outlined width="11.375rem" onClick={handleOnClick}>
					{sucessoCriarRecebedor ? 'Ver recebedor' : 'Tentar novamente'}
				</ButtonCustom>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default Avisos;
