import React, { useEffect } from 'react';
import {
	ButtonCustom,
	CheckboxCustom,
	RadioButton,
	SelectReact,
	UnderlineInput,
} from '../../..';
import { useRecipients } from '../../../../hooks';
import { justNumbers, optionsWeekly } from '../../../../utils';

import * as s from './styled-configuracoes';

const FormGroupTitle = ({ title, subtitle }) => (
	<s.Header>
		<s.Title>{title}</s.Title>
		<s.Subtitle>{subtitle}</s.Subtitle>
	</s.Header>
);

const optionsMonthly = () => {
	const options = [];
	for (let i = 1; i <= 31; i++) {
		options.push({
			label: `${i}`,
			value: `${i}`,
		});
	}
	return options;
};

const Configuracoes = () => {
	const {
		step,
		setStep,
		antecipationVolumePercentage,
		setAntecipationVolumePercentage,
		automaticAntecipationEnabled,
		setAutomaticAntecipationEnabled,
		transferInterval,
		setTransferInterval,
		transferDay,
		setTransferDay,
		setIsCancelarCriacaoOpen,
	} = useRecipients();
	const isAntecipationPercentageInvalid =
		antecipationVolumePercentage > 100 || antecipationVolumePercentage <= 0;
	const isReadOnly =
		transferInterval === 'daily' || !automaticAntecipationEnabled;

	useEffect(() => {
		if (!automaticAntecipationEnabled) {
			setTransferInterval('daily');
			setTransferDay(0);
		}
	}, [automaticAntecipationEnabled]);

	return (
		<s.Container>
			<FormGroupTitle
				title="CONFIGURAÇÕES DE ANTECIPAÇÃO"
				subtitle="Escolha abaixo o volume antecipável para esse recebedor"
			/>
			<s.FormGroup>
				<s.InputGroup>
					<UnderlineInput
						width="13rem"
						label="Modelo de antecipação"
						className="readonly-antecipacao"
						placeholder="Manual por volume"
						readOnly
						floaterWidth="25rem"
						info={
							<p>
								Na antecipação <b>manual por volume</b>, você solicita o volume
								a ser antecipado dos seus recebíveis de acordo com sua
								necessidade.
								<br /> O volume liberado para antecipar é um percentual
								referente as suas vendas, sendo possível antecipar as parcelas
								do início com um custo menor, ou do final com um custo maior.
							</p>
						}
					/>

					<UnderlineInput
						width="13rem"
						label="Volume antecipável (%)"
						placeholder="100"
						info={<p>Defina aqui um volume antecipável entre 1% e 100%</p>}
						value={antecipationVolumePercentage}
						maxLength={3}
						max={100}
						erro="O número precisa ser entre 1 e 100"
						isInvalid={isAntecipationPercentageInvalid}
						onChange={(e) =>
							setAntecipationVolumePercentage(justNumbers(e.target.value))
						}
					/>
				</s.InputGroup>

				<s.GroupContainer>
					<s.GroupTitle>CONFIGURAÇÕES DE TRANSFERÊNCIA</s.GroupTitle>
					<CheckboxCustom
						value={automaticAntecipationEnabled}
						onChange={() => {
							setAutomaticAntecipationEnabled(!automaticAntecipationEnabled);
						}}
						text="Habilitar transferência automática"
					/>
				</s.GroupContainer>

				<s.InputGroup readOnly={isReadOnly} className="radio-group">
					<s.TitleInput>Periodicidade da transferência</s.TitleInput>
					<s.RadioGroup>
						<RadioButton
							id="daily"
							label="Diária"
							width="15.6875rem"
							value="daily"
							checked={transferInterval === 'daily'}
							onChange={() => {
								setTransferDay(0);
								setTransferInterval('daily');
							}}
							disabled={!automaticAntecipationEnabled}
						/>
						<RadioButton
							id="weekly"
							label="Semanal"
							value="weekly"
							checked={transferInterval === 'weekly'}
							onChange={() => {
								setTransferDay(optionsWeekly[0]);
								setTransferInterval('weekly');
							}}
							disabled={!automaticAntecipationEnabled}
						/>
						<RadioButton
							id="monthly"
							label="Mensal"
							value="monthly"
							checked={transferInterval === 'monthly'}
							onChange={() => {
								setTransferDay(1);
								setTransferInterval('monthly');
							}}
							disabled={!automaticAntecipationEnabled}
						/>
					</s.RadioGroup>
					<SelectReact
						readOnly={isReadOnly}
						height="4rem"
						className="custom-select-input"
						label="Dia da transferência"
						placeholder="Segunda-feira"
						width="16.6875rem"
						value={transferDay}
						onChange={(e) => setTransferDay(e.target.value)}
						options={
							transferInterval === 'weekly' ? optionsWeekly : optionsMonthly()
						}
						isSearchable={false}
						info={<p>Dia em que a transferência ocorrerá</p>}
						floaterWidth="12rem"
					/>
				</s.InputGroup>
			</s.FormGroup>

			<s.ButtonGroup>
				<ButtonCustom
					outlined
					theme="gray"
					onClick={() => setIsCancelarCriacaoOpen(true)}
				>
					Cancelar
				</ButtonCustom>
				<s.RightButtons>
					<ButtonCustom
						outlined
						theme="green"
						className="botao-voltar"
						onClick={() => setStep(step - 1)}
					>
						Voltar
					</ButtonCustom>
					<ButtonCustom
						theme="green"
						disabled={isAntecipationPercentageInvalid}
						onClick={() => setStep(step + 1)}
					>
						Continuar
					</ButtonCustom>
				</s.RightButtons>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default Configuracoes;
