import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	grid-column: span 1 / auto;
	flex-direction: column;
	border-radius: 15px;
	max-height: 157px;
	min-height: 157px;
	height: 100%;
	width: 100%;

	color: ${colors.white};
	background-color: ${colors.darkGray};
	padding: 18px 35px 37px 42px;

	.loading-rectangle {
		height: 40px;
		width: calc(100% - 1rem);
	}
`;

export const Title = styled.h1`
	font-size: 1em;
	flex: 1;
	padding-bottom: 10px;

	font-family: IBM Plex Sans;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: ${colors.white};
`;

export const Infos = styled.div`
	display: flex;
	align-items: center;
	color: ${colors.white};
	justify-content: space-between;
	padding-bottom: 20px;
	h3 {
		color: ${colors.white};
		font-weight: 600;
		margin: 0;
		font-size: 1.375em;
	}

	div {
		display: flex;
		align-items: center;
	}
`;

export const CardFooter = styled.div`
	display: flex;
	flex: 1;
`;

export const CardFooterItem = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
`;
