import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	padding-top: 6.25rem;
	flex-direction: column;
	width: 100%;
	align-items: center;
	align-content: center;
	padding: center;
	height: 100%;
	width: 100%;
	.loading-rectangle {
		height: 2rem;
		width: calc(100% - 2px);
	}
`;

export const SvgContainer = styled.div`
	display: contents;
	align-content: center;
	height: 100%;
	width: 100%;
`;

export const Text = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	h1 {
		font-family: 'Jost';
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 32px;
		color: ${colors.primaryBlue};
	}
	p {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 26px;
		color: ${colors.gray};
	}
`;

export const ButtonGroup = styled.div`
	display: flex;
	align-items: center;
	padding-top: 4rem;
	gap: 1.1875rem;
	justify-content: space-between;
`;
