import styled from 'styled-components';
import { colors } from '../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
`;

export const TableMaster = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background: ${colors.gridBlack};
	border-radius: 15px;
	margin-bottom: 2rem;
	width: 100%;

	table {
		font-size: 1rem;
		background: ${colors.gridBlack};
		padding: 1.56rem;
		border-radius: 15px;
		justify-content: flex-end;
		align-content: flex-end;


		td {
			display: flex;
			align-items: center;
			justify-content: center;
			border-right: 1px solid ${colors.borderTable};
			padding: 10px;
			height: 100%;
		}
		td.status {
			border-right: 0px;
		}

		td.center {
			justify-content: center;
		}
	}
`;

export const HeaderColumn = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	h1 {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 31px;
		color: ${colors.white};
	}

	p {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: ${colors.lightGray};
	}
`;

export const ContainerAddPayment = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	gap: 0.9375rem;
	width: 100%;
	background: ${colors.gridBlack};
	border-radius: 15px;

	padding: 2rem 1.5rem 2rem 1.5rem;
	.buttonError {
		font-size: 1rem;
		gap: 0.55rem;
	}

	.select-react {
		width: 10.44rem;
		height: 3.13rem;
	}
`;

export const ContainerHeader = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
	gap: 0.9375rem;
	width: 100%;
	background: ${colors.gridBlack};
	border-radius: 15px;
	flex-wrap: wrap;

	button {
		width: 9.44rem;
		height: 3.13rem;
	}
	.select-react {
		width: 10.44rem;
		height: 3.13rem;
	}
`;

export const TableHeader = styled.div`
	width: auto;
	display: flex;
	justify-content: flex-end;
	align-items: right;
	gap: 15px;
	padding: 1.56rem;
	width: 100%;

	.itemsPerPage {
		width: 232px;
		height: 50px;
	}

	button {
		width: auto;
		height: 3.13rem;
	}
	.select-react {
		width: 14.75rem;
		height: 3.13rem;
	}
`;

export const TBody = styled.tbody`
	width: 100%;
	tr {
		text-align: left;
		background: ${colors.gridBlack};
		color: ${colors.white};
		display: grid;
		justify-content: center;
		align-items: center;
		grid-template-columns: 9rem 19rem 12rem 15rem 22rem 14rem 10rem;
		min-height: 3.75rem;
		align-items: center;
		margin-right: -2px;
		margin-top: -2px;
		margin-left: -2px;
		:nth-child(odd) {
			background: ${colors.gridBlack};
		}
		:nth-child(even) {
			background: ${colors.rowTable};
		}

		:last-child {
			border-bottom: 1px solid ${colors.borderTable};
		}

		@media (max-width: 1025px) {
			grid-template-columns: 6rem 15rem 8rem 11rem 18rem 10rem 6.2rem;
			font-size: 80%;
		}
	}

	.copy {
		background-color: transparent;
		border: 0;
		margin: 0;
		padding: 0;
		padding-left: 0.75rem;
	}

	td {
		justify-content: center;
		align-items: center;
		:first-child {
			border-left: 1px solid ${colors.borderTable};
		}

		@media (max-width: 1025px) {
			grid-template-columns: 6rem 15rem 8rem 11rem 18rem 10rem 6.2rem;
			font-size: 80%;
		}
	}
	.status {
		border-left: 0;
		border-right: 1px solid ${colors.borderTable};
	}

	button.button-custom {
		width: auto;
		background-color: transparent;
		box-shadow: none;
		white-space: nowrap;

		svg {
			width: 1.875rem;
			height: 1.875rem;
		}
	}

	@media (max-width: 1025px) {
		grid-template-columns: 6rem 15rem 8rem 11rem 18rem 10rem 6.2rem;
		font-size: 80%;
	}
`;

export const Head = styled.div`
	background: ${colors.background};
	height: 3.75rem;
	margin-bottom: -2px;
	width: 100%;
	tr {
		text-align: left;
		height: 3.75rem;
		align-items: center;
		display: grid;
		grid-template-columns: 9rem 19rem 12rem 15rem 22rem 14rem 10rem;
		margin-right: -2px;
		margin-top: -2px;
		margin-left: -2px;

		:first-child {
			border-top: 1px solid ${colors.borderTable};
		}

		@media (max-width: 1025px) {
			grid-template-columns: 6rem 15rem 8rem 11rem 18rem 10rem 6.2rem;
			font-size: 80%;
		}
	}

	td {
		margin-top: -3px;
		color: ${colors.primaryBlue};
		:first-child {
			border-left: 1px solid ${colors.borderTable};
		}
	}

	.ArrowOrder {
		background-color: transparent;
		border: 0;
		margin: 0;
		padding: 0;
	}

	@media (max-width: 1025px) {
		grid-template-columns: 6rem 15rem 8rem 11rem 18rem 10rem 6.2rem;
		font-size: 80%;
	}
`;

export const Pagination = styled.div`
	width: auto;
	display: flex;
	justify-content: center;
`;

export const ErrorContent = styled.div`
	margin-top: 8rem;
	margin-bottom: 3.125rem;
	width: 30.8125rem;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${colors.white};
	position: relative;

	svg.attention-outlined-icon {
		width: 8.75rem;
		height: 8.75rem;
		fill: ${colors.background};
		filter: drop-shadow(0px 0px 3px ${colors.yellow});
		margin-bottom: 1.75rem;
	}

	.error-title {
		font-size: 2rem;
		margin-bottom: 1rem;
		text-transform: uppercase;
		font-family: Jost;
		font-weight: 500;
	}

	.error-text {
		font-size: 1.25rem;
		text-align: center;
		color: ${colors.lightGray};
		font-family: IBM Plex Sans;
		font-weight: 400;
	}
	p {
		padding-bottom: 4rem;
	}
	.buttonError {
		font-size: 1rem;
		gap: 0.55rem;
	}
`;

export const Options = styled.div`
	width: 10rem;

	ul {
		list-style: none;
	}

	button {
		background: none;
		padding: 0.5rem 1rem;
		font-size: 0.875rem;
		width: 100%;

		:hover {
			background: rgba(0, 0, 0, 0.1);
		}
	}
`;
