import React from 'react';
import { Document, Font } from '@react-pdf/renderer';
import { TransacoesPdfTemplate } from '../download-pdf/templates';

Font.register({
	family: 'IBM Plex Sans',
	fonts: [
		{
			src:
				'https://fonts.gstatic.com/s/ibmplexsans/v13/zYXgKVElMYYaJe8bpLHnCwDKtdbUFI5NadY.ttf',
		},
		{
			src:
				'https://fonts.gstatic.com/s/ibmplexsans/v13/zYX9KVElMYYaJe8bpLHnCwDKjQ76MIZmdd_qFmo.ttf',
			fontWeight: 600,
		},
	],
});

const DetalhesTransacoes = ({ loading, data }) => {
	return (
		<>
			{!loading && data && (
				<Document>
					<TransacoesPdfTemplate data={data} />
				</Document>
			)}
		</>
	);
};

export default DetalhesTransacoes;
