import styled from 'styled-components';
import { colors } from '../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.9375rem;
	flex: 1;
`;

export const Steps = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;

	svg {
		position: absolute;
		left: 33.33%;
		width: 1.65rem;
		transform: translateX(-50%);
	}

	svg:nth-child(2) {
		left: 66.66%;
	}

	svg {
		path {
			stroke: ${({ step }) => step > 1 && colors.primaryBlue};
		}
	}

	svg:nth-child(2) {
		path {
			stroke: ${({ step }) => (step > 2 ? colors.primaryBlue : colors.gray)};
		}
	}
`;

export const Span = styled.span`
	padding: 0.9375rem;
	font-weight: ${({ selected }) => (selected ? 500 : 400)};
	color: ${({ selected }) =>
		selected ? colors.primaryBlue : colors.lightGray};
	font-size: 1.375rem;
	font-family: Jost;
	width: 33.33%;
	text-align: center;
`;

export const Alerta = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	border: 1px solid ${colors.yellow};
	padding: 0.9375rem 1.25rem;

	p {
		color: ${colors.yellow};
		font-size: 500;
		font-size: 1.25rem;
	}

	svg.attention-outlined-icon {
		width: 1.5rem;
		height: 1.5rem;
		margin-top: 1px;
	}
`;

export const Expired = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 300px;
	text-align: center;

	svg.attention-outlined-icon {
		width: 5.625rem;
		height: 5.625rem;
		fill: ${colors.background};
		filter: drop-shadow(0px 0px 3px ${colors.yellow});
		margin-bottom: 1.5625rem;
	}

	h2 {
		font-weight: 500;
		color: ${colors.offWhite};
		font-size: 1.375rem;
		text-transform: uppercase;
		font-family: Jost;
		margin-bottom: 1.25rem;
	}

	p {
		font-weight: 400;
		color: ${colors.lightGray};
		font-size: 1.25rem;
		margin-bottom: 3.75rem;
	}

	.button-custom {
		width: auto;
		padding: 0 1.25rem;
		text-transform: none;
	}
`;
