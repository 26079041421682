import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.button`
	background: none;
	display: flex;
	flex-direction: row;
	height: 3.75rem;
	min-height: 3.75rem;
	color: ${colors.offWhite};
	border-radius: 0.9375rem;
	align-items: center;
	font-size: 1rem;
	justify-content: ${({ isSideMenuOpen }) =>
		isSideMenuOpen ? 'flex-start' : 'center'};
	margin-bottom: 2rem;
	transition: all 0.1s ease-out;

	width: ${({ isSideMenuOpen }) => (isSideMenuOpen ? '16.25rem' : '100%')};
	margin-left: ${({ isSideMenuOpen }) => (isSideMenuOpen ? '2.125rem' : '0')};
	padding-left: ${({ isSideMenuOpen }) => (isSideMenuOpen ? '0.875rem' : '0')};
	margin-right: ${({ isSideMenuOpen }) => (isSideMenuOpen ? '1.9375rem' : '0')};

	svg {
		fill: ${colors.white};
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	}

	:not(:focus, .selected) {
		margin-left: ${({ isSideMenuOpen }) => (isSideMenuOpen ? '2.125rem' : '0')};
		padding-left: ${({ isSideMenuOpen }) =>
			isSideMenuOpen ? '0.875rem' : '0'};
	}

	/* when clicking TAB, will show the selected position with a blue border */
	:focus:not(.selected) {
		border: 1px solid ${colors.primaryBlue};
	}
`;
