import styled from 'styled-components';
import { colors } from '../../../assets';

export const Container = styled.div`
	display: flex;
	position: relative;

	width: 100%;
	height: 3.13rem;
	border-radius: 0.625rem;
	background: rgba(25, 25, 25, 0.4);
	border: 1px solid
		${({ isInvalid }) => (isInvalid ? colors.primaryRed : colors.gray)};

	svg {
		top: 50%;
		right: 1rem;
		position: absolute;
		transform: translateY(-50%);
	}

	input::-ms-reveal {
		display: none;
	}
`;

export const IconWrapper = styled.div`
	display: contents;

	svg {
		width: 1.75rem;
		height: 0.9375rem;
		cursor: pointer;

		:hover {
			opacity: 0.8;
		}
	}

	svg.visible-eye-icon {
		height: 0.85rem;
	}
`;

export const Input = styled.input`
	width: 100%;
	height: 100%;
	background: none;
	padding: ${({ isInvalid, paddingRight }) =>
		isInvalid ? '0 2.5rem 0 1rem' : paddingRight ? '0 3rem 0 1rem' : '0 1rem'};
	color: ${colors.lightGray};
	font-size: 1.13rem;
	font-weight: 300;
	${({ autoFocus }) => autoFocus ? 'autofocus;' : ''};
`;
