import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 0.9375rem 1.25rem;
	min-width: 200px;
	flex: 1;
	min-height: 8.13rem;

	h2 {
		color: ${colors.lightGray};
		font-size: 1.5rem;
		font-weight: 400;
	}

	span {
		color: ${colors.white};
		font-weight: 500;
	}

	span.cifrao {
		font-size: 1.5rem;
		display: flex;
		align-items: flex-end;
		gap: 5px;
	}

	span.valor {
		font-family: Jost;
		font-size: 2.25rem;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		word-break: break-word;
		line-height: 2.19rem;
	}

	span.msg {
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 1.63rem;
		opacity: 0.5;
	}

	p {
		font-size: 1.25rem;
		color: ${colors.white};
		font-weight: 400;
		margin: 0;
		padding: 0;
	}

	.loading-rectangle:nth-child(even) {
		height: 30px;
		width: 65%;
	}

	.loading-rectangle:nth-child(odd) {
		height: 20px;
		width: 85%;
	}

	.button-custom {
		margin: 0 auto;
		height: 2.8125rem;
		width: auto;
		padding: 0.375rem 2.5rem;
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.625rem;

	div {
		display: flex;
		align-items: center;
		width: 100%;

		span,
		p {
			font-family: Jost;
			font-weight: 300;
			font-size: 1.125rem;
		}

		span {
			margin-right: 1.875rem;
		}
	}
`;

export const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.3125rem;
	position: absolute;
	right: 1.25rem;
	background: none;
	border: 1px solid ${colors.primaryBlue};
	border-radius: 0.3125rem;

	svg.close-icon {
		height: 0.625rem;
		width: 0.625rem;

		path {
			fill: ${colors.primaryBlue};
		}
	}

	:disabled {
		opacity: 0.3;
	}

	:hover:not(:disabled) {
		background: ${colors.primaryBlue};

		svg path {
			fill: ${colors.background};
		}
	}
`;

export const Reload = styled.button`
	background: none;
	color: ${colors.primaryBlue};
	font-weight: 400;
	font-size: 1.25rem;
	line-height: 1.63rem;
	text-decoration: underline;

	:hover {
		transform: scale(1.05);
	}
`;
