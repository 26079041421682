import styled from 'styled-components';
import { colors } from '../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
`;

export const Steps = styled.div`
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	justify-content: space-around;
`;

export const Form = styled.div`
	background-color: ${({ steps }) =>
		steps === 5 ? colors.background : colors.darkGray};

	display: flex;
	border-radius: 0.9375rem;
`;
