import React, { useEffect } from 'react';
import { blue } from '@mui/material/colors';
import { useHistory } from 'react-router';
import { useAuth } from '../../hooks';
import { Icons } from '../../assets';
import { ButtonCustom } from '../../components/atoms/button-custom/styled-button-custom';

import * as s from './styled-not-found';

const NotFound = ({ setNotFound }) => {
	const { currentUser } = useAuth();
	const userType = currentUser?.role;

	const history = useHistory();

	useEffect(() => {
		setNotFound(false);
	}, [userType]);

	return (
		<>
			{userType ? (
				<s.NoContentContainer>
					<s.NoContentTitle className="content-tittle">
						Ooops! Que pena :(
					</s.NoContentTitle>
					<s.NoContentText className="content">
						Não conseguimos encontrar a página que você está procurando...
						<s.NoContentText className="errorcode">
							Código de erro: 404
						</s.NoContentText>
						<s.NoContentText className="graytext">
							Aqui estão alguns links úteis:
						</s.NoContentText>
						<ul>
							<li>
								<a href="/resumo">Resumo</a>
							</li>
							<li>
								<a href="/extrato">Extrato</a>
							</li>
							<li>
								<a href="/transacoes">Transações</a>
							</li>
						</ul>
					</s.NoContentText>
				</s.NoContentContainer>
			) : (
				<s.NoContentContainerUnauthenticated>
					<s.NoContentContainerContentUnauthenticated>
						<s.ContentContainerUnauthenticatedLogo>
							<Icons.Logotipo />
						</s.ContentContainerUnauthenticatedLogo>
						<s.NoContentTextErrorUnauthenticated>
							Código de erro: 404
						</s.NoContentTextErrorUnauthenticated>
						<s.NoContentTitleUnauthenticated className="content-tittle">
							Página não encontrada
						</s.NoContentTitleUnauthenticated>

						<s.NoContentTextUnauthenticated className="content">
							Não encontramos a página que você tentou acessar. Atualize seu
							navegador ou faça seu
							<br /> login clicando no botão abaixo.
						</s.NoContentTextUnauthenticated>
						<s.NoContentButtons>
							<ButtonCustom
								theme={blue}
								className="loginButton"
								onClick={() => history.push('/login')}
							>
								LOGIN
							</ButtonCustom>
						</s.NoContentButtons>
					</s.NoContentContainerContentUnauthenticated>
				</s.NoContentContainerUnauthenticated>
			)}
		</>
	);
};

export default NotFound;
