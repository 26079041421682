import styled from 'styled-components';
import { colors } from '../../../assets';

export const Button = styled.button`
	padding-left: 1rem;
	width: ${({ width }) => width || 'auto'};
	height: 3.13rem;
	border-radius: 0.9375rem;
	border: ${({ isSelected }) => {
		let color;
		if (isSelected) color = colors.primaryBlue;
		else color = colors.gray;
		return `1px solid ${color}`;
	}};
	background: none;
	color: ${({ isSelected }) => {
		let color;
		if (isSelected) color = colors.primaryBlue;
		else color = colors.lightGray;
		return color;
	}};
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.005em;
	text-align: left;
	align-items: center;
	display: flex;
`;
