import React from 'react';

import {
	capitalizeFirstLetter,
	renderFlag,
	translatePaymentMethod,
	translateRefuseReason,
} from '../../../../utils';
import { FloaterReact, LoadingRectangle } from '../../../../components';
import { colors, Icons } from '../../../../assets';

import * as s from './styled-card-payment';

const CardPayment = (props) => {
	const { id, div, title, data, loading } = props;

	const renderIcon = (type) => {
		if (type?.includes('credit_card') || type === 'acquirer')
			return <Icons.CreditCard />;
		if (type === 'boleto') return <Icons.Boleto />;
		if (type === 'pix') return <Icons.Pix />;
		if (type === 'acquirer_timeout') return <Icons.AcquirerTimeout />;
		if (type === 'antifraud') return <Icons.Antifraude />;
		if (type === 'internal_error')
			return <Icons.Placeholder fill={colors.lightGray} />;
		return renderFlag(type);
	};

	const translateType = (type) => {
		if (title === 'Motivos de recusa') return translateRefuseReason(type);
		if (title === 'Formas de pagamento') return translatePaymentMethod(type);
		return capitalizeFirstLetter(type);
	};

	const RenderItens = () => {
		const isDataBiggerThanFive = data?.length > 5;

		const firstData = isDataBiggerThanFive ? data.slice(0, 4) : data;
		const otherData = isDataBiggerThanFive ? data.slice(4) : null;

		const ItensList = () =>
			firstData?.map((item) => (
				<s.Row item={item} key={item?.type}>
					<s.Item id={id}>
						<s.Icon>{renderIcon(item?.type)}</s.Icon>
						<s.ItemType>{translateType(item?.type)}</s.ItemType>
					</s.Item>
					<s.Percentage>{`${item?.percentage}%`}</s.Percentage>
				</s.Row>
			));

		const OtherItens = () => (
			<s.FloaterWrapper>
				{otherData?.map((item) => (
					<s.FloaterRow item={item} key={item?.type}>
						<s.Item id={id}>
							<s.ItemType>{`${translateType(item?.type)}:`}&nbsp;</s.ItemType>
						</s.Item>
						<span className="percentage">{`${item?.percentage}%`}</span>
					</s.FloaterRow>
				))}
			</s.FloaterWrapper>
		);

		return (
			<>
				<ItensList />
				{otherData?.length > 0 && (
					<>
						<s.Row>
							<s.Item>
								<s.Icon style={{ paddingLeft: '0.1rem' }}>
									<Icons.CreditCard />
								</s.Icon>
								<s.ItemType className="outros">Outros</s.ItemType>
								<FloaterReact hover component={OtherItens} placement="right">
									<Icons.InfoIconOutlined fill={colors.white} />
								</FloaterReact>
							</s.Item>
						</s.Row>
					</>
				)}
			</>
		);
	};

	return (
		<s.Container id={id} div={div}>
			<s.Title>{title}</s.Title>
			<s.Infos>
				{loading ? (
					<LoadingRectangle />
				) : data && data?.length > 0 ? (
					<RenderItens />
				) : (
					<Icons.Placeholder
						style={{ margin: '10% auto', height: '5rem', width: '5rem' }}
					/>
				)}
			</s.Infos>
		</s.Container>
	);
};

export default CardPayment;
