import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { ButtonCustom, SelectReact, UnderlineInput } from '../../../atoms';
import { formatarParaReais, justNumbers } from '../../../../utils';
import { Installments } from '../../../../pages/transaction-details/components/card-header/styled-card-header';
import { LoadingRectangle, ModalCancelarEstorno } from '../../../molecules';

import * as s from './styled-identificacao';

const Identificacao = ({
	step,
	setStep,
	valorEstorno,
	dados,
	loading,
	setValorEstorno,
	setChosedRecebedor,
	recebedor,
	setRecebedor,
	opcoesRecebedor,
	setOpcoesRecebedor,
}) => {
	const { transactionId } = useParams();
	const [showElement, setShowElement] = useState(false);
	const [inputError, setInputError] = useState('');

	const {
		customer,
		cardFirstDigits,
		cardLastDigits,
		cardBrand,
		installments,
    grossValue,
	} = dados;

	const nomeCliente = customer?.name;
	const emailCliente = customer?.email;
	const bandeira = cardBrand;
	const firstDigitsCard = cardFirstDigits;
	const lastDigitsCard = cardLastDigits;
	const valor = grossValue;
	const valorLiquido = grossValue;

	const history = useHistory();

	const [openModal, setOpenModal] = useState(false);

	const voltar = () => {
		history.push(`/transacoes/${transactionId}`);
	};

	const chavesRecebedores =
		dados?.splitRules?.data && Object.keys(dados.splitRules.data);

	const recebedores = dados?.splitRules?.data;

	const renderCard = (firstDigits, lastDigits) => {
		const formatedFirstDigits = firstDigits?.substring(0, 4);
		const formatedFirstDigits2 = firstDigits?.substring(4, 6);
		return `${formatedFirstDigits} ${formatedFirstDigits2}** **** ${lastDigits}`;
	};

	const renderInstallments = () => {
		if (installments === 1) {
			return 'À vista';
		}
		return <Installments>Parcelado {installments}x</Installments>;
	};

	useEffect(() => {
		if (chavesRecebedores?.length) {
			const options = [{ label: 'Manter regras de divisão', value: '' }];

			for (let pos = 0; pos < chavesRecebedores.length; pos++) {
				const opt = recebedores[chavesRecebedores[pos]];
				options.push({ label: opt.name, value: chavesRecebedores[pos] });
			}

			setOpcoesRecebedor(options);
			setChosedRecebedor(options[0].label);
		}
	}, [dados?.splitRules?.data]);

	useEffect(() => {
		setValorEstorno(valor);
	}, [valor]);

	function onChangeValorEstorno(e) {
		e.target.value = justNumbers(e.target.value);
		setValorEstorno(e.target.value);
		setShowElement(false);
		setInputError('');
	}

	function handleSelect(e) {
		setChosedRecebedor(e.label);
		setRecebedor(e.label);
	}
	return (
		<s.Container>
			<s.Content>
				<s.Column>
					<h2>Dados do Cliente:</h2>
					<br />
					<s.Linegroup>
						<s.Label>
							<s.TituloParagrafo>Nome do Cliente:</s.TituloParagrafo>
							<s.P>
								<br />
								{!loading ? `${nomeCliente}` : <LoadingRectangle />}
							</s.P>
						</s.Label>
						<s.Label>
							<s.TituloParagrafo>Email:</s.TituloParagrafo>
							<s.P>
								<br />
								{!loading ? `${emailCliente}` : <LoadingRectangle />}
							</s.P>
						</s.Label>
					</s.Linegroup>
					<s.Linediv />
				</s.Column>
				<s.Column>
					<h2>Dados do Cartão:</h2>
					<br />
					<s.Linegroup>
						<s.Label>
							<s.TituloParagrafo>Número do Cartão:</s.TituloParagrafo>
							<s.P>
								<br />
								{!loading ? (
									`${renderCard(firstDigitsCard, lastDigitsCard)}`
								) : (
									<LoadingRectangle />
								)}
							</s.P>
						</s.Label>
						<s.Label>
							<s.TituloParagrafo>Bandeira:</s.TituloParagrafo>
							<s.P>{!loading ? `${bandeira}` : <LoadingRectangle />}</s.P>
						</s.Label>
					</s.Linegroup>
					<s.Linediv />
				</s.Column>
				<s.Column>
					<h2>Dados da transação:</h2>
					<br />
					<s.Linegroup>
						<s.Label>
							<s.TituloParagrafo>Valor da transação:</s.TituloParagrafo>
							<s.P>
								<br />

								{!loading ? (
									`R$ ${formatarParaReais(valorLiquido / 100)}`
								) : (
									<LoadingRectangle />
								)}
							</s.P>
						</s.Label>
						<s.Label>
							<s.TituloParagrafo>Parcelas:</s.TituloParagrafo>
							<s.P>
								<br />
								{!loading ? renderInstallments() : <LoadingRectangle />}
							</s.P>
						</s.Label>
						<s.Label className="custom-label">
							<s.TituloParagrafo>Recebedor:</s.TituloParagrafo>
							{!loading ? (
								<SelectReact
									className="custom-select-input"
									options={opcoesRecebedor}
									selectedOption={recebedor}
									value={recebedor}
									outlined
									width="21.5rem"
									onChange={(option) => {
										handleSelect(option);
									}}
								/>
							) : (
								<LoadingRectangle />
							)}
						</s.Label>
					</s.Linegroup>
					<s.Linediv />
				</s.Column>
				<s.Column>
					{showElement ? (
						<h2 className="titleError">Valor do Estorno(R$):</h2>
					) : (
						<h2>Valor do Estorno(R$):</h2>
					)}
					{!loading ? (
						<UnderlineInput
							type="text"
							name="valorestorno"
							id="valorestorno"
							width="15.625rem"
							isInvalid={showElement}
							onChange={(e) => onChangeValorEstorno(e)}
							value={`R$ ${formatarParaReais(valorEstorno / 100)}`}
              disabled
						/>
					) : (
						<LoadingRectangle />
					)}
					<s.Erro className="titleError">{inputError}</s.Erro>
				</s.Column>
				<s.Linegroup
					style={{
						justifyContent: 'space-between',
						marginBottom: '40px',
						padding: '0 1.25rem',
					}}
				>
					<ButtonCustom
						theme="green"
						outlined
						onClick={() => setOpenModal(true)}
					>
						Cancelar
					</ButtonCustom>
					<ButtonCustom
						valorEstorno={valorEstorno}
						disabled={loading}
						theme="green"
						onClick={() => {
							if (Number(valorEstorno) === 0) {
								setShowElement(true);
								setInputError(
									'O valor do estorno não pode ser igual a R$ 0,00'
								);
							// } else if (Number(valorEstorno) > Number(valorLiquido)) {
							// 	setShowElement(true);
							// 	setInputError(
							// 		'O valor do estorno não pode ser maior que o valor da transação!'
							// 	);
							// }
                } else if (step === 1) {
								setStep(2);
								setShowElement(false);
							} else {
								setStep(3);
							}
						}}
					>
						Continuar
					</ButtonCustom>
				</s.Linegroup>
			</s.Content>

			<ModalCancelarEstorno
				isOpen={openModal}
				cancel={voltar}
				closeModal={() => setOpenModal(false)}
			/>
		</s.Container>
	);
};

export default Identificacao;
