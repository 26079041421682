import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { Loader } from '../../components';
import {
	DetailsBody,
	DetailsFooterList,
	DetailsHeader,
	DetailsItens,
} from '../../components/organisms';
import { useAuth } from '../../contexts';
import {
	getPaymentLink,
	getOrdensPaymentLink,
	getTransactionsOrders,
} from '../../services';

import * as s from './styled-payment-details';

const PaymentDetails = () => {
	const { paymentLinkId } = useParams();
	const { recipientId } = useAuth().currentUser;
	const [loading, setLoading] = useState(true);
	const [dados, setDados] = useState({});
	const [tableData, setTableData] = useState([]);
	const [transactions, setTransactions] = useState({});
	const { amount } = dados;
	const paymentConfig = dados?.payment_config;
	const expires = dados?.expires_at;
	const dataCriada = dados?.date_created;
	const creditCard = paymentConfig?.credit_card;
	const boletoLink = paymentConfig?.boleto;
	const freeInstallments = creditCard?.free_installments;
	const maxInstallments = creditCard?.max_installments;
	const minInstallments = creditCard?.min_installments;
	const chargeTransaction = creditCard?.charge_transaction_fee;


	const SearchData = () => {
		const idOrders = tableData.map((order) => {
			return order?.id;
		});
		getPaymentLink(paymentLinkId, recipientId)
			.then((resp) => {
				setDados(() => resp);
			})
			.catch(() => toast.error('Erro ao Buscar os links de pagamento'));

		getOrdensPaymentLink(paymentLinkId, recipientId)
			.then((resp) => {
				setTableData(() => resp);
			})
			.catch(() => toast.error('Erro ao buscar as ordens de pagamento'))
			.finally(() => setLoading(false));
		getTransactionsOrders(recipientId, { idOrders })
			.then((resp) => {
				setTransactions(() => resp);
			})
			.catch(() => toast.error('Erro ao buscar as ordens de transações'))
	};

	useEffect(() => {
		SearchData();
	}, []);

	return loading ? (
		<s.CenterLoader>
			<Loader />
		</s.CenterLoader>
	) : (
		<s.Container>
			<s.Header>
				<DetailsHeader
					dados={dados}
					recipientId={recipientId}
					paymentLinkId={paymentLinkId}
				/>
			</s.Header>
			<s.DetailsBody>
				<DetailsBody
					loading={loading}
					dados={dados}
					amount={amount}
					paymentConfig={paymentConfig}
					expires={expires}
					dataCriada={dataCriada}
					creditCard={creditCard}
					boletoLink={boletoLink}
					freeInstallments={freeInstallments}
					maxInstallments={maxInstallments}
					minInstallments={minInstallments}
					chargeTransaction={chargeTransaction}
				/>
			</s.DetailsBody>
			<s.ItensBody>
				<DetailsItens loading={loading} dados={dados} />
			</s.ItensBody>
			<s.FooterList>
				<DetailsFooterList
					dados={dados}
					transactions={transactions}
					paymentLinkId={paymentLinkId}
				/>
			</s.FooterList>
		</s.Container>
	);
};

export default PaymentDetails;
