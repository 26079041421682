import styled from 'styled-components';
import { colors } from '../../../assets';

export const Container = styled.div`
	background-color: ${colors.primaryBlue};
	border-radius: 0.938rem;
	height: 3.75rem;
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	line-height: 1.63rem;
	padding: 0.625rem 0.938rem;

	.alert-text {
		font-family: 'IBM Plex Sans';
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-style: normal;
		font-weight: 500;
		font-size: 1.25rem;
		margin-right: 3px;
	}

	.alert-text-strong {
		font-family: 'IBM Plex Sans';
		font-weight: 700;
		font-size: 1.25rem;
		display: flex;
		justify-content: center;
		letter-spacing: 0.005em;
		line-height: 1.63rem;
	}
`;

export const ContainerShield = styled.div`
	display: flex;
	margin-right: 1.3125rem;
`;

export const ContainerFlex = styled.div`
	display: flex;
	align-items: center;
`;

export const ContainerSpace = styled.div`
	margin-left: 1.3125rem;
`;
