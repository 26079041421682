import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.9375rem;
	flex: 1;
`;

export const Group = styled.section`
	display: flex;
	flex-wrap: wrap;
	gap: 0.9375rem;
`;
