import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	.loading-rectangle {
		height: 2rem;
		width: calc(100% - 2px);
	}
`;

export const Content = styled.div`
	background: ${colors.darkGray};
	height: 100%;
	border-radius: 0.9375rem;
	padding: 0px 1.5rem 0 1.5625rem;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 1.25rem;
	.valorestorno {
		font-size: 1.5625rem;
	}
	h2 {
		font-size: 1.375rem;
		font-style: normal;
		font-family: 'Jost';
		font-weight: 500;
		letter-spacing: 0.04em;
		color: ${colors.white};
		text-transform: uppercase;
	}
	.valorEstornoBig {
		font-size: 40px;
	}
	.erroValorEstorno {
		font-size: 1rem;
		color: ${colors.red};
		font-weight: 400;
	}
`;

export const Linegroup = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 3.5rem;

	.groupbtn {
		display: flex;
	}

	.groupbtn > button {
		margin-left: 16px;
	}
`;

export const Label = styled.label`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 0.9375rem;
	color: ${colors.white};
	font-weight: 400;
	text-align: left;

	.custom-select-input {
		border-bottom: 1px solid ${colors.primaryBlue};
		border-radius: 0;
	}
	h2 {
		font-size: 1.375rem;
		font-style: normal;
		font-family: 'Jost';
		font-weight: 500;
		letter-spacing: 0.04em;
		color: ${colors.white};
	}
	div#contestorno {
		font-size: 1rem;
		color: ${colors.lightGray};
		text-align: left;
	}
	input#valorestorno {
		border-top: 0.3125rem;
		background: ${colors.lightBlack};
		width: auto;
		box-shadow: 0 0.125rem 0 0 grey;
		color: white;
		font-size: 40px;
	}
`;

export const Linediv = styled.div`
	position: relative;
	opacity: 0.5;
	background: ${colors.darkGray};
	border: 1px solid #585d5d;
	margin-top: 28px;
`;

export const P = styled.p`
	color: ${colors.offWhite};
	font-size: 1.25rem;
	font-weight: 400;
	font-family: 'IBM Plex Sans';
	text-align: left;
		
	&:first-letter {
		text-transform: uppercase;
	}
`;
export const TituloParagrafo = styled.p`
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-size: 1rem;
	color: ${colors.lightGray};
`;

export const Progress = styled.div`
	display: flex;
	width: 100%;
	min-width: 18.75rem;
	padding: 0.625rem 3.125rem;
	margin-bottom: 1.25rem;
	justify-content: space-between;
	background: ${colors.darkGray};
	border-radius: 0.4375rem;
`;

export const Span = styled.span`
	color: ${({ selected }) =>
		selected ? colors.primaryBlue : colors.lightGray};
`;

export const TempButton = styled.button`
	margin-top: 1.25rem;
`;
