import React from 'react';
import { TransactionSwitch, Table, CardGraphs } from '../../../../components';
import { ChartSubtitle } from '../../../atoms';

import * as S from './styled-transactionBody';

const TransactionBody = ({
	excelTransactions,
	transacoes,
	loading,
	itemsPerPage,
	setItemsPerPage,
	optionsItemsPage,
	totalElements,
	page,
	setPage,
	totalValue,
	handleSubmit,
	renderTable,
	setRenderTable,
	intervaloDatas,
}) => {
	const dinamicTimeData = (type) => {

		if(type ==="initial"){

			if(intervaloDatas?.from) return`${intervaloDatas?.from?.day}/${intervaloDatas.from.month}/${intervaloDatas.from.year}`
			return `${intervaloDatas.day}/${intervaloDatas.month}/${intervaloDatas.year}`
		} 
		if(type ==="final"){

			if(intervaloDatas?.to) return`${intervaloDatas?.to?.day}/${intervaloDatas.to.month}/${intervaloDatas.from.year}`
			return `${intervaloDatas.day}/${intervaloDatas.month}/${intervaloDatas.year}`
		}
	}
	const inicialData = dinamicTimeData("initial");
	const finalData = dinamicTimeData("final");

	const graphSubtitles = [
		{ title: 'Paga', color: 'green' },
		{ title: 'Recusada', color: 'red' },
		{ title: 'Estornada', color: 'yellow' },
		{ title: 'Estorno pendente', color: 'purple' },
		{ title: 'Aguardando pagamento', color: 'white' },
		{ title: 'Processando', color: 'darkBlue' },
		{ title: 'Chargeback', color: 'pink' },
		{ title: 'Autorizada', color: 'lightBlue' },
		{ title: 'Revisão pendente', color: 'orange' },
	];

	return (
		<S.Container>
			{renderTable && (
				<S.Header>
					<S.CustomToolbar>
						<S.InfoTransactions>
							<div>
								<h4>Período &nbsp; </h4>
								<p>
									{inicialData} - {finalData}
								</p>
							</div>
							<div>
								<h4>transações &nbsp;</h4> <p>{totalElements} &nbsp;</p>
							</div>
							<div>
								<h4>total capturado de &nbsp; </h4>
								<p>{totalValue}</p>
							</div>
						</S.InfoTransactions>
						<TransactionSwitch
							className="TransactionSwitch"
							id="TransactionSwitch"
							checked={renderTable}
							onChange={() => setRenderTable(!renderTable)}
						/>
					</S.CustomToolbar>
				</S.Header>
			)}
			<S.Content>
				{!renderTable ? (
					<Table
						excelTransactions={excelTransactions}
						handleSubmit={handleSubmit}
						rows={transacoes}
						page={page}
						setPage={setPage}
						itemsPerPage={itemsPerPage}
						setItemsPerPage={setItemsPerPage}
						optionsItemsPage={optionsItemsPage}
						totalElements={totalElements}
						totalValue={totalValue}
						loading={loading}
						renderTable={renderTable}
						setRenderTable={setRenderTable}
					/>
				) : (
					<S.GraphContent>
						<CardGraphs
							id="transaction-chart"
							div="div8"
							title=""
							totalElements={totalElements}
							totalValue={totalValue}
							data={excelTransactions}
						/>
						<S.LegendsGraph>
							<span className="subtitle-text">Legenda</span>
							<S.ContentSubtitles>
								{graphSubtitles.map((sub, index) => (
									<ChartSubtitle
										key={index}
										title={sub.title}
										color={sub.color}
									/>
								))}
							</S.ContentSubtitles>
						</S.LegendsGraph>
					</S.GraphContent>
				)}
			</S.Content>
		</S.Container>
	);
};

export default TransactionBody;
