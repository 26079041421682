import React from 'react';

const GraphResumo = ({ fill, style }) => {
	return (
		<svg
			width="56"
			height="56"
			viewBox="0 0 56 56"
			fill={!fill && 'none'}
			style={style}
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="56" height="56" rx="28" fill="#E2F9FF" />
			<path
				d="M37 28C37 26.9 36.1 26 35 26C33.9 26 33 26.9 33 28V36C33 37.1 33.9 38 35 38C36.1 38 37 37.1 37 36V28Z"
				fill="#63D2ED"
			/>
			<path
				d="M30 20C30 18.9 29.1 18 28 18C26.9 18 26 18.9 26 20V36C26 37.1 26.9 38 28 38C29.1 38 30 37.1 30 36V20Z"
				fill="#63D2ED"
			/>
			<path
				d="M21.1333 21.748H20.8667C19.84 21.748 19 22.6431 19 23.7371V35.759C19 36.853 19.84 37.748 20.8667 37.748H21.1333C22.16 37.748 23 36.853 23 35.759V23.7371C23 22.6431 22.16 21.748 21.1333 21.748Z"
				fill="#63D2ED"
			/>
		</svg>
	);
};

export default GraphResumo;
