import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	grid-column: span 3 / auto;
	flex-direction: column;
	border-radius: 15px;

	color: ${colors.white};
	background-color: ${colors.darkGray};
	padding: 18px 35px 37px 42px;

	> span {
		padding-bottom: 6px;
	}

	.loader {
		margin-top: 0.5rem;
	}
`;

export const Title = styled.h1`
	font-size: 1em;
	padding-bottom: 1.25em;

	font-family: IBM Plex Sans;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: ${colors.white};
`;

export const Infos = styled.div`
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	color: ${colors.white};
	h3 {
		color: ${colors.white};
		font-weight: 600;
		margin: 0;
		font-size: 1.375em;
	}

	div {
		display: flex;
	}
`;

export const ReceiverContainer = styled.div`
	display: flex;
	display: relative;
	width: 100%;
	flex-direction: column;
	align-items: center;
	padding-bottom: 20px;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	padding: 10px 0 15px 15px;
	width: 100%;
	border: 1px solid ${colors.lightGray};
	border-radius: 15px;
`;

export const Index = styled.div`
	display: flex-start;
	display-flex: column;
	position: relative;
	color: ${colors.primaryBlue};
	margin-right: 15px;
	font-family: Jost;
	font-style: normal;
	font-weight: 600;
	font-size: 1.125em;
	line-height: 26px;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	.title {
		font-family: Jost;
		font-style: normal;
		font-weight: 600;
		font-size: 1.125em;
		line-height: 26px;
	}
	.content {
		font-family: Work Sans;
		font-style: normal;
		font-weight: 500;
		font-size: 1em;
		line-height: 19px;
		letter-spacing: -0.02em;

		padding-bottom: 10px;
	}
`;
