import React, { useState, useRef, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { colors, Icons } from '../../../../assets';
import { formatValor, mascaraCNPJ, mascaraCPF } from '../../../../utils';
import { SelectReact, FloaterReact } from '../../../atoms';
import { Pagination } from '../../../molecules';

import * as s from './styled-payment-details-footer';

const DetailsFooterList = ({ transactions, loading, dados }) => {
	const [page, setPage] = useState(1);
	const link = dados?.url;

	const tooltipRef = useRef(null);
	const [floaterIsOpen, setFloaterIsOpen] = useState(false);

	 const handleFloaterVisibility = () => {
	 	setFloaterIsOpen(true);

	 	 setTimeout(() => {
	 	 	setFloaterIsOpen(false);
	 	 }, 2000);
	 };

	const optionsItemsPage = [
		{
			label: '15 itens por página',
			value: 15,
		},
		{
			label: '30 itens por página',
			value: 30,
		},
		{
			label: '60 itens por página',
			value: 60,
		},
		{
			label: '100 itens por página',
			value: 100,
		},
	];

	const [itemsPerPage, setItemsPerPage] = useState(() => {
		if (
			sessionStorage.getItem('paymentDetailsItemsPerPage') &&
			sessionStorage.getItem('paymentDetailsItemsPerPage') !== ''
		) {
			return JSON.parse(sessionStorage.getItem('paymentDetailsItemsPerPage'));
		}
		sessionStorage.setItem(
			'paymentDetailsItemsPerPage',
			JSON.stringify(optionsItemsPage[0])
		);
		return optionsItemsPage[0];
	});

	const StatusStyle = (value) => {
		if (value === 'inactive') {
			return (
				<td className="status" style={{ color: 'red', border: 0 }}>
					Inativo
				</td>
			);
		}
		if (value === 'active') {
			return (
				<td className="status" style={{ color: `${colors.green}`, border: 0 }}>
					Ativo
				</td>
			);
		}
		if (value === 'refused') {
			return (
				<td className="status" style={{ color: `${colors.red}`, border: 0 }}>
					Recusada
				</td>
			);
		}
		if (value === 'processing') {
			return (
				<td className="status" style={{ color: `${colors.yellow}`, border: 0 }}>
					Processando
				</td>
			);
		}
		if (value === 'canceled') {
			return (
				<td className="status" style={{ color: `${colors.red}`, border: 0 }}>
					Cancelado
				</td>
			);
		}

		return (
			<td className="status" style={{ color: 'yellow', border: 0 }}>
				Expirado
			</td>
		);
	};
	const handleClickOutside = () => {
    setFloaterIsOpen(false);
  };
	useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

	return (
		transactions.length > 0 ? (
			<s.Container>
				<>
					<s.TableMaster>
						<table>
							<s.TableHeader>
								{Math.ceil(transactions.length / itemsPerPage.value) > 1 && (
									<>
										<SelectReact
											value={itemsPerPage}
											onChange={(opt) => {
												setItemsPerPage(opt);
												setPage(1);
												sessionStorage.setItem(
													'paymentDetailsItemsPerPage',
													JSON.stringify(opt)
												);
												sessionStorage.setItem(
													'paymentDetailsPage',
													JSON.stringify(1)
												);
											}}
											id="itemsPerPage"
											isSearchable={false}
											options={optionsItemsPage}
											readOnly={loading}
											outlined
											height="50px"
											width="auto"
										/>
										<s.Pagination>
											<Pagination
												paginaAtual={page - 1}
												totalPaginas={Math.ceil(
													transactions.length / itemsPerPage?.value
												)}
												onClick={(pagina) => {
													setPage(pagina.selected + 1);
													sessionStorage.setItem(
														'paymentDetailsPage',
														JSON.stringify(pagina.selected + 1)
													);
												}}
												disabled={loading}
											/>
										</s.Pagination>
									</>
								)}
							</s.TableHeader>
							<s.Border>
								<s.Head>
									<thead>
										<tr>
											<td>Status</td>
											<td>ID da transação</td>
											<td>Data da criação</td>
											<td>Nome</td>
											<td>CPF / CNPJ</td>
											<td>Forma de Pagamento</td>
											<td>Valor da Transação</td>
										</tr>
									</thead>
								</s.Head>

								<s.TBody>
									{transactions &&
										transactions.map((item, i) => (
											<tr
												key={item.transactionsId}
												id={`table-rows-${item.transactionsId}`}
												index={i}
											>
												<td key={i.status}>{StatusStyle(item.status)}</td>
												<td key={i.id}>{item.id}</td>

												<td key={i.dateCreated}>
													{
														new Date(item.dateCreated)
															.toLocaleString('br-PT')
															.split(' ')[0]
													}
												</td>
												<td key={i.customerName}>{item.customerName}</td>
												<td key={i.customerCNPJ}>
													{item.customerCNPJ !== null
														? mascaraCNPJ(item.customerCNPJ)
														: mascaraCPF(item.customerDocuments[0].number)}
												</td>
												<td className="center" key={i.payment_method}>
													{item.payment_method}
												</td>
												<td key={i.amount}>{formatValor(item.amount / 100)}</td>
											</tr>
										))}
								</s.TBody>
							</s.Border>
						</table>
					</s.TableMaster>
					<>
						{Math.ceil(transactions.length / itemsPerPage.value) > 1 && (
							<s.Pagination>
								<Pagination
									paginaAtual={page - 1}
									totalPaginas={Math.ceil(
										transactions.length / itemsPerPage?.value
									)}
									onClick={(pagina) => {
										setPage(pagina.selected + 1);
										sessionStorage.setItem(
											'paymentDetailsPage',
											JSON.stringify(pagina.selected + 1)
										);
									}}
									disabled={loading}
								/>
							</s.Pagination>
						)}
					</>
				</>
			</s.Container>
		) : (
			<s.ErrorContent ref={tooltipRef}>
				<Icons.AttentionOutlined />

				<s.ErrorTexts>
					<p id="error-title">Sem transações neste link de pagamentos.</p>

					<s.ContainerText floaterIsOpen={floaterIsOpen}>
						<p className="error-text">Copie o link</p>

								<FloaterReact isOpen={floaterIsOpen} hover={floaterIsOpen} content="Link Copiado">
									<CopyToClipboard text={link}>
										<button
											type="button"
											onClick={() => handleFloaterVisibility()}
											id="link"
										>
										clicando aqui
										</button>
									</CopyToClipboard>
								</FloaterReact>

						<p className="error-text">
							e compartilhe com seus clientes para ver suas vendas aqui.
						</p>
					</s.ContainerText>
				</s.ErrorTexts>
			</s.ErrorContent>
		)
	);
};

export default DetailsFooterList;
