import React, { Fragment } from 'react';
import { colors, Icons } from '../../../assets';

import * as s from './styled-form-steps';

const FormStep = ({ currentStep, steps }) => {
	return (
		<s.Container>
			{steps.map((step, index) => {
				const ultimoItemMap = index === steps.length - 1;
				const isCurrentStep = currentStep > index;
				return (
					<Fragment key={step}>
						<s.Step index={index}>
							<s.StepText className={isCurrentStep && 'active'}>
								{`${index + 1}. ${step.toUpperCase()}`}
							</s.StepText>
						</s.Step>
						{!ultimoItemMap && (
							<s.Icon index={index}>
								<Icons.ChevronThin
									fill={step.length > index + 1 && colors.primaryBlue}
								/>
							</s.Icon>
						)}
					</Fragment>
				);
			})}
		</s.Container>
	);
};

export default FormStep;
