import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	margin-bottom: 1.875rem;
`;

export const User = styled.div`
	display: flex;
	flex-direction: column;
`;

export const UserInfo = styled.div`
	color: ${colors.lightGray};
	font-size: 1rem;
	h3 {
		font-weight: 600;
		font-size: 1rem;
	}
	span {
		font-style: normal;
		font-weight: normal;
	}
	b {
		color: ${colors.offWhite};
	}
`;

export const Filter = styled.div`
	display: flex;
	flex-direction: row;

	/* 	padding-right: 16px;
	padding-top: 16px;
	padding-bottom: 8px; */

	label {
		cursor: pointer;
	}
`;

export const Buttons = styled.div`
	display: flex;
	user-select: none;
	align-items: center;
	align-content: center;
	gap: 1rem;
`;

export const Button = styled.button`
	margin-right: 8px;
`;

export const Calendar = styled.div`
	display: flex;
	align-self: center;
	height: '2rem' !important;
	width: '7.625rem' !important;
	justify-content: center !important;
	cursor: pointer;
`;
