import { React, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import {
	Pagination,
	FiltersClient,
	DataTableClient,
	Loader,
	SelectReact,
} from '../../components';
import { getUsersWallet } from '../../services';
import { Icons } from '../../assets';
import { validaCNPJ } from '../../utils';
import * as s from './styled-client';

function Client() {
	const optionsItemsPage = [
		{
			label: '15 itens por página',
			value: 15,
		},
		{
			label: '30 itens por página',
			value: 30,
		},
		{
			label: '60 itens por página',
			value: 60,
		},
		{
			label: '100 itens por página',
			value: 100,
		},
	];

	const [clientes, setClientes] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [itemsPerPage, setItemsPerPage] = useState(optionsItemsPage[0]);
	const [page, setPage] = useState(1);
	const [clientesFiltrados, setClientesFiltrados] = useState([]);
	const { idContractor } = useParams();

	function filtrarClientes() {
		const isCNPJ = validaCNPJ(searchValue);
		const phoneSemMascara = searchValue
			.replace('+', '')
			.replace('(', '')
			.replace(')', '')
			.replace('-', '')
			.replace(' ', '')
			.replace(/\s/g, '');
		const isNum = /^\d+$/.test(searchValue);
		const busca = searchValue;

		if (isNum) {
			if (isCNPJ) {
				setClientesFiltrados(
					clientes.filter((el) => el.detalhes.cnpj.startsWith(busca))
				);
			}

			if (phoneSemMascara === busca) {
				setClientesFiltrados(
					clientes.filter((el) => el.telefone?.startsWith(busca))
				);
			}

			setClientesFiltrados(
				clientes.filter(
					(el) =>
						el?.telefone?.startsWith(busca) ||
						el?.detalhes.cnpj.startsWith(busca)
				)
			);
		} else {
			setClientesFiltrados(
				clientes.filter((el) => {
					return el?.nome.startsWith(busca) || el?.email.startsWith(busca);
				})
			);
		}
	}

	function buscarClientes() {
		setIsLoading(true);
		getUsersWallet(idContractor)
			.then((resp) => {
				setClientes(() => resp);
				setClientesFiltrados(() => resp);
			})
			.catch(() => toast.error('Erro ao buscar clientes'))
			.finally(() => setIsLoading(false));
	}

	useEffect(() => {
		filtrarClientes();
	}, [searchValue]);

	useEffect(() => {
		buscarClientes();
	}, []);

	return (
		<s.Container>
			<FiltersClient
				loading={isLoading}
				clientes={clientes}
				setClientes={setClientes}
				filtrarClientes={filtrarClientes}
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				page={page}
				setPage={setPage}
				clientesFiltrados={clientesFiltrados}
				setClientesFiltrados={setClientesFiltrados}
			/>
			{isLoading ? (
				<s.CenterLoader>
					<Loader />
				</s.CenterLoader>
			) : clientesFiltrados.length > 0 ? (
				<s.TableContainer>
					<s.ContainerHeader>
						<s.Toolbar>
							<s.SelectDiv className="margin-right">
								<SelectReact
									value={itemsPerPage}
									onChange={(opt) => {
										setItemsPerPage(opt);
										setPage(1);
									}}
									isSearchable={false}
									options={optionsItemsPage}
									readOnly={isLoading}
									outlined
									height="50px"
									width="220px"
								/>
							</s.SelectDiv>
							<s.Pagination>
								{Math.ceil(clientesFiltrados.length / itemsPerPage.value) >
									1 && (
									<Pagination
										paginaAtual={page - 1}
										totalPaginas={Math.ceil(
											clientesFiltrados.length / itemsPerPage.value
										)}
										onClick={(pagina) => {
											setPage(pagina.selected + 1);
										}}
										disabled={isLoading}
									/>
								)}
							</s.Pagination>
						</s.Toolbar>
					</s.ContainerHeader>
					<DataTableClient
						isLoading={isLoading}
						clientes={clientes}
						page={page}
						setPage={setPage}
						clientesFiltrados={clientesFiltrados}
						itemsPerPage={itemsPerPage}
					/>
				</s.TableContainer>
			) : (
				<s.ErrorContent>
					<Icons.AttentionOutlined />
					<h2 className="error-title">Sem resultados</h2>
					<p className="error-text">
						Não encontramos resultados para sua busca. Reveja o que foi digitado
						ou faça uma nova pesquisa.
					</p>
				</s.ErrorContent>
			)}
		</s.Container>
	);
}

export default Client;
