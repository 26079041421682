import React from 'react';
import { colors } from '../../../assets';

const ChevronThinIcon = ({ fill, style, title }) => {
	return (
		<svg
			className="chevron-thin-icon"
			style={style}
			width="26"
			height="62"
			viewBox="0 0 26 62"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>{title}</title>
			<path d="M0.5 1L24.5 31L0.5 61" stroke={fill || colors.gray} />
		</svg>
	);
};

export default ChevronThinIcon;
