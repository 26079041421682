import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const ButtonCustom = styled.button`
	display: flex;
	justify-content: ${({ justifyContent }) => justifyContent || 'center'};
	align-items: ${({ alignItems }) => alignItems || 'center'};
	font-size: 1.25rem;
	font-weight: 600;
	text-transform: ${({ textTransform }) => textTransform || 'none'};
	padding: 0.375rem 1.25rem;
	letter-spacing: 0.02857rem;
	border-radius: ${({ borderRadius }) => borderRadius || '0.9375rem'};
	width: ${({ width }) => width || '150px'};
	height: ${({ height }) => height || '3.13rem'};
	color: ${({ tema, outlined }) => {
		if (outlined) {
			if (tema === 'gray') return colors.lightGray;
			if (tema === 'green') return colors.green;
			if (!tema) return colors.primaryBlue;
		} else return colors.darkGray;
	}};
	background: ${({ tema, outlined, backgroundColor }) => {
		if (backgroundColor) return backgroundColor;
		if (outlined) return 'transparent';
		if (tema === 'gray') return colors.lightGray;
		if (tema === 'green') return colors.green;
		return colors.primaryBlue;
	}};
	border: ${({ tema, outlined }) => {
		if (outlined) {
			let color;
			if (tema === 'gray') color = colors.lightGray;
			else if (tema === 'green') color = colors.green;
			else if (!tema) color = colors.primaryBlue;

			return `1px solid ${color}`;
		}
		return 'none';
	}};

	${({ isLoading }) =>
		isLoading &&
		`
		pointer-events: none;
	`};

	:disabled {
		background: ${colors.lightGray};
		opacity: 0.3;
		border: none;
		color: ${colors.darkGray};
	}

	&:hover:not(:disabled) {
		${({ tema, outlined, disableParsedHover }) => {
			if (!disableParsedHover) {
				if (outlined) {
					let color;

					if (tema === 'gray') color = colors.lightGray;
					if (tema === 'green') color = colors.green;
					if (!tema) color = colors.primaryBlue;

					return `background: ${color}`;
				}
			}
		}};
		color: ${({ disableParsedHover }) =>
			!disableParsedHover && colors.darkGray};
		opacity: 0.8;

		svg path {
			fill: ${({ disableParsedHover }) =>
				!disableParsedHover && colors.darkGray};
		}

		box-shadow: ${({ tema, disableParsedHover }) => {
			let color;
			if (!disableParsedHover) {
				if (tema === 'gray') color = 'rgba(177,182,182,0.4)';
				else if (tema === 'green') color = 'rgba(99,237,121,0.4)';
				else color = 'rgba(99, 210, 237, 0.4)';
				return `0 0.25rem 0.4375rem ${color}`;
			}
		}};
	}
	font-family: ${({ fontFamily }) => fontFamily || 'IBM Plex Sans'};
	font-size: ${({ fontSize }) => fontSize || '1rem'};
	font-weight: ${({ fontWeight }) => fontWeight || '400'};
`;

export const Link = styled.a`
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.25rem;
	font-weight: 600;
	text-transform: ${({ textTransform }) => textTransform || 'uppercase'};
	padding: 1rem;
	letter-spacing: 0.02857rem;
	border-radius: ${({ borderRadius }) => borderRadius || '0.625rem'};
	width: ${({ width }) => width || '150px'};
	height: ${({ height }) => height || '3.13rem'};
	color: ${({ tema, outlined }) => {
		if (outlined) {
			if (tema === 'gray') return colors.lightGray;
			if (tema === 'green') return colors.green;
			if (tema === 'alert-white') return colors.red;
			if (!tema) return colors.primaryBlue;
		} else return colors.darkGray;
	}};
	background: ${({ tema, outlined }) => {
		if (tema === 'alert-white') return colors.offWhite;
		if (outlined) return 'transparent';
		if (tema === 'gray') return colors.lightGray;
		if (tema === 'green') return colors.green;
		return colors.primaryBlue;
	}};
	border: ${({ tema, outlined }) => {
		if (outlined) {
			let color;
			if (tema === 'gray') color = colors.lightGray;
			else if (tema === 'green') color = colors.green;
			else if (tema === 'alert-white') color = colors.lightGray;
			else if (!tema) color = colors.primaryBlue;

			return `1px solid ${color}`;
		}
		return 'none';
	}};

	${({ isLoading }) =>
		isLoading &&
		`
		pointer-events: none;
	`};

	:disabled {
		background: ${colors.lightGray};
		opacity: 0.3;
		border: none;
		color: ${colors.darkGray};
	}

	&:hover:not(:disabled) {
		${({ tema, outlined }) => {
			if (outlined) {
				let color;

				if (tema === 'gray') color = colors.lightGray;
				if (tema === 'green') color = colors.green;
				if (!tema) color = colors.primaryBlue;

				return `background: ${color}`;
			}
		}};
		color: ${colors.darkGray};
		opacity: 0.8;

		svg path {
			fill: ${colors.darkGray};
		}

		box-shadow: ${({ tema }) => {
			let color;
			if (tema === 'gray') color = 'rgba(177,182,182,0.4)';
			else if (tema === 'green') color = 'rgba(99,237,121,0.4)';
			else color = 'rgba(99, 210, 237, 0.4)';

			return `0 0.25rem 0.4375rem ${color}`;
		}};
	}
`;

export const ContainerIcon = styled.span`
	margin-right: ${({ haveChildren }) => (haveChildren ? '10px' : '0')};
	display: flex;
`;
