import React, { useState } from 'react';
import { parse, isValid } from 'date-fns';
import moment from 'moment';
import { ptBR } from 'date-fns/locale';
import { colors, Icons } from '../../../assets';
import { FloaterReact, Calendario } from '../../../components';
import { adicionarDiasCalendario, padStart } from '../../../utils';

import * as s from './styled-filtro-calendario';

const FiltroCalendario = ({
	disabled,
	formatedRangeDate,
	selectedDayRange,
	setSelectedDayRange,
	minimumDate,
	maximumDate,
	dataInvalida,
	setDataInvalida,
	selected,
	placement,
	transform,
	backgroundColor,
	width,
	fontSizePlaceholder,
	borderBlue,
	padding,
	onFocus,
	hideArrow,
}) => {
	const [open, setOpen] = useState(false);
	const [filterSelected, setFilterSelected] = useState('7 dias');
	const anterior = selected === 'anterior';
	const opcoes = [
		{
			label: 'hoje',
			filtrar: () => setSelectedDayRange(adicionarDiasCalendario(0)),
		},
		{
			label: '7 dias',
			filtrar: () =>
				setSelectedDayRange(adicionarDiasCalendario(anterior ? -7 : 7)),
		},
		{
			label: '15 dias',
			filtrar: () =>
				setSelectedDayRange(adicionarDiasCalendario(anterior ? -15 : 15)),
		},
		{
			label: '30 dias',
			filtrar: () =>
				setSelectedDayRange(adicionarDiasCalendario(anterior ? -30 : 30)),
		},
		{
			label: '60 dias',
			filtrar: () =>
				setSelectedDayRange(adicionarDiasCalendario(anterior ? -60 : 60)),
		},
		{
			label: 'dia',
			filtrar: () => setSelectedDayRange(adicionarDiasCalendario(0).to),
		},
		{
			label: 'período',
			filtrar: () =>
				setSelectedDayRange(adicionarDiasCalendario(anterior ? -5 : 5)),
		},
	];

	function totalDiasSelecionados() {
		const { from, to } = formatedRangeDate() || { from: '_', to: '_' };

		let dias = 0;

		if (from?.includes('_') || to?.includes('_'))
			return `Nenhum dia selecionado`;

		if (['hoje', 'dia'].includes(filterSelected)) dias = 1;
		else {
			let inicio = from.split('/');
			inicio = `${inicio[2]}-${inicio[1]}-${inicio[0]}`;
			let final = to?.split('/');
			if (final) final = `${final[2]}-${final[1]}-${final[0]}`;

			const start = moment(inicio, 'YYYY-MM-DD');
			const end = moment(final, 'YYYY-MM-DD');

			dias = moment.duration(end.diff(start)).asDays() + 1;
		}

		if (dias === 1) return `1 dia selecionado`;
		return `${Number.isNaN(dias) ? 0 : dias.toFixed(0)} dias selecionados`;
	}

	const ColunaOpcoes = () => (
		<s.Column>
			{opcoes.map((opt, index) => (
				<div key={index}>
					<s.Label
						borderBlue={borderBlue}
						selected={opt.label === filterSelected}
						onClick={() => {
							setFilterSelected(opt.label);
							setDataInvalida(() => ({ inicio: false, final: false }));
							opt.filtrar();
						}}
					>
						{opt.label}
					</s.Label>
					{(index === 0 || index === 4) && (
						<h2>
							{index === 0
								? anterior
									? 'últimos'
									: 'próximos'
								: 'personalizado'}
						</h2>
					)}
				</div>
			))}
		</s.Column>
	);

	function formatMinMaxDate(date) {
		if (date) {
			return `${padStart(date.day)}/${padStart(date.month)}/${date.year}`;
		}
		return null;
	}

	const setarValor = (valor, inicio = false) => {
		if (filterSelected !== 'período') setFilterSelected('período');

		let dataValida;
		let converterDataInicial = false;

		if (valor.includes('_') || valor === '') {
			dataValida = false;
			setDataInvalida((prev) =>
				inicio ? { ...prev, inicio: true } : { ...prev, final: true }
			);
		} else {
			const formatDate = (date) =>
				parse(date, 'P', new Date(), { locale: ptBR });
			const dataAtual = formatDate(valor);
			const isValidDate = isValid(dataAtual);

			if (!isValidDate) {
				dataValida = false;
				setDataInvalida((prev) =>
					inicio ? { ...prev, inicio: true } : { ...prev, final: true }
				);
			} else {
				const dataFinal = formatDate(formatedRangeDate().to);
				const dataInicial = formatDate(formatedRangeDate().from);
				let anoInicial;
				let anoFinal;

				if (inicio) {
					anoInicial = Number(valor.split('/')[2]);
					anoFinal = formatedRangeDate().to.split('/')[2];

					if (anoInicial < 2013 || anoInicial > 2032) {
						dataValida = false;
						setDataInvalida((resp) => ({ ...resp, inicio: true }));
					} else if (dataAtual > dataFinal) {
						dataValida = false;
						setDataInvalida(() => ({ inicio: true, final: true }));
					} else if (
						selected === 'receber' &&
						dataAtual < formatDate(formatMinMaxDate(minimumDate))
					) {
						dataValida = false;
						setDataInvalida(() => ({ inicio: true, final: true }));
					} else {
						dataValida = true;
						setDataInvalida(() => ({ inicio: false, final: false }));
					}
				} else if (!inicio) {
					anoInicial = formatedRangeDate().from.split('/')[2];
					anoFinal = Number(valor.split('/')[2]);

					if (anoFinal > 2032 || anoInicial < 2013) {
						dataValida = false;
						setDataInvalida((resp) => ({ ...resp, final: true }));
					} else if (dataAtual < dataInicial) {
						dataValida = false;
						setDataInvalida(() => ({ inicio: true, final: true }));
					} else if (
						selected === 'anterior' &&
						dataAtual > formatDate(formatMinMaxDate(maximumDate))
					) {
						dataValida = false;
						setDataInvalida(() => ({ inicio: true, final: true }));
					} else {
						dataValida = true;
						setDataInvalida(() => ({ inicio: false, final: false }));
						converterDataInicial = true;
					}
				}
			}
		}

		let dataAtual;
		let dataInicial;

		if (valor) {
			dataAtual = valor.split('/');
		} else dataAtual = ['__', '__', '___'];

		if (dataValida) {
			dataAtual = {
				day: Number(dataAtual[0]),
				month: Number(dataAtual[1]),
				year: Number(dataAtual[2]),
			};

			if (converterDataInicial) {
				dataInicial = formatedRangeDate().from.split('/');
				dataInicial = {
					day: Number(dataInicial[0]),
					month: Number(dataInicial[1]),
					year: Number(dataInicial[2]),
				};
			}
		} else {
			dataAtual = {
				day: dataAtual[0],
				month: dataAtual[1],
				year: dataAtual[2],
			};
		}

		if (inicio) setSelectedDayRange((resp) => ({ ...resp, from: dataAtual }));
		else if (converterDataInicial) {
			setSelectedDayRange(() => ({ from: dataInicial, to: dataAtual }));
		} else setSelectedDayRange((resp) => ({ ...resp, to: dataAtual }));
	};
	return (
		<s.ContainerInputs
			backgroundColor={backgroundColor}
			invalid={dataInvalida?.inicio || dataInvalida?.final}
			width={width}
			fontSizePlaceholder={fontSizePlaceholder}
			borderBlue={borderBlue || `${colors.primaryBLue}`}
		>
			<FloaterReact
				simbolicIcon
				hideArrow={hideArrow}
				isOpen={open}
				placement={placement}
				transform={transform}
				padding={padding}
				content={
					<s.ContainerFilters>
						<ColunaOpcoes />
						<s.Column>
							<Calendario
								borderBlue={borderBlue}
								readOnly={!['dia', 'período'].includes(filterSelected)}
								selectedDayRange={dataInvalida.inicio ? null : selectedDayRange}
								setSelectedDayRange={setSelectedDayRange}
								minimumDate={minimumDate}
								maximumDate={maximumDate}
								numberOfMonths={2}
							/>
							<p>{totalDiasSelecionados()}</p>
						</s.Column>
					</s.ContainerFilters>
				}
			>
				<Icons.Calendar />
			</FloaterReact>
			{open && <s.Overlay onClick={() => setOpen(false)} />}

			<s.InputDays
				id="data-inicio"
				mask="99/99/9999"
				value={formatedRangeDate()?.from}
				onChange={(e) => setarValor(e.target.value, true)}
				onClick={() => !open && setOpen(true)}
				disabled={disabled}
				invalid={dataInvalida?.inicio}
				backgroundcolor={backgroundColor}
				onFocus={onFocus}
			/>
			<s.Divisor
				backgroundColor={backgroundColor}
				invalid={dataInvalida?.inicio || dataInvalida?.final}
			/>
			<s.InputDays
				id="data-final"
				mask="99/99/9999"
				value={
					filterSelected === 'dia'
						? formatedRangeDate()?.from
						: formatedRangeDate()?.to
				}
				onChange={(e) => setarValor(e.target.value)}
				onClick={() => !open && setOpen(true)}
				disabled={disabled}
				invalid={dataInvalida?.final}
				onFocus={onFocus}
			/>
		</s.ContainerInputs>
	);
};

export default FiltroCalendario;
