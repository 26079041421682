import React from 'react';
import { useHistory } from 'react-router';
import { Icons } from '../../../../assets';
import ContainerModal from '../container-modal';
import { ButtonCustom } from '../../..';

import * as s from './styled-modal-sucesso-edicao-contratante';

const ModalSucessoEdicao = ({ isOpen, closeModal }) => {
	const history = useHistory();
	return (
		<ContainerModal isOpen={isOpen} closeModal={closeModal}>
			<s.Content>
				<s.Imagem>
					<Icons.SuccessIcon />
				</s.Imagem>
				<s.Title>INFORMAÇÕES ALTERADAS COM SUCESSO</s.Title>
				<s.Subtitle>
					Você já pode ver as alterações na seção <strong>Contrantes</strong>
				</s.Subtitle>
				<s.ButtonGroup>
					<ButtonCustom
						width="15rem"
						textTransform="none"
						onClick={() => history.push('/contratantes')}
					>
						Ok
					</ButtonCustom>
				</s.ButtonGroup>
			</s.Content>
		</ContainerModal>
	);
};

export default ModalSucessoEdicao;
