import React from 'react';
import { useHistory } from 'react-router';
import * as s from './styled-card-extrato';
import { LoadingRectangle, ButtonCustom } from '../../../../../components';
import { formatarParaReais } from '../../../../../utils';

const CardExtratoRecebedores = ({
	title,
	valor,
	loading,
	saque,
	receber,
	antecipacoes,
	recipientId,
}) => {
	const history = useHistory();

	return (
		<s.Card>
			<h2>{title}</h2>
			{loading ? (
				<LoadingRectangle />
			) : (
				<>
					<span className="cifrao">
						{(!antecipacoes || (antecipacoes && valor !== 0)) && 'R$'}
						<span className="valor">
							{valor === 0
								? !antecipacoes && '0,00'
								: formatarParaReais(valor / 100)}
						</span>
					</span>
					{antecipacoes && valor === 0 && (
						<span className="msg">Nenhuma antecipação disponível</span>
					)}
				</>
			)}

			{loading ? (
				<LoadingRectangle />
			) : saque ? (
				<p>
					Disponível para saque{' '}
					{valor === 0 ? (
						<strong>R$ 0,00</strong>
					) : (
						formatarParaReais(valor / 100, true)
					)}
				</p>
			) : (
				receber && (
					<ButtonCustom
						theme="green"
						textTransform="capitalize"
						onClick={() =>
							history.push(`/recebedores/${recipientId}/antecipacoes`)
						}
					>
						Antecipar
					</ButtonCustom>
				)
			)}
		</s.Card>
	);
};

export default CardExtratoRecebedores;
