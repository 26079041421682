import React from 'react';
import { colors } from '../colors';

const GraficoTransacoesIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="grafico-transacoes-icon"
		width="85"
		height="52"
		viewBox="0 0 85 52"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || 'Ícone gráfico do número de transações'}</title>
		<path
			d="M83 28.5721C82.4071 28.1436 80.9203 28.0007 79.7162 30.8579C78.2111 34.4293 75.6066 34.4928 75.0642 32.0008C74.3801 28.8576 74.6537 18.7148 72.6014 19.0005C71.5082 19.1527 71.2331 22.5719 71.2331 25.4291C71.2331 28.2862 70.5671 32.0008 68.4966 32.0008C66.7179 32.0008 66.3074 29.0005 65.7601 26.4291C65.2128 23.8576 63.4341 24.1433 63.4341 27.2862C63.4341 31.1326 63.4341 35.8576 61.2449 35.8576C57.4041 35.8576 58.3716 17.858 57.5507 4.57226C57.3123 0.715186 53.9932 0.286704 53.8564 9.71526C53.7049 20.1583 53.0355 25.4287 52.8986 26.4291C52.7618 27.4294 52.0777 29.858 50.0253 27.1437C48.3834 24.9723 47.1008 25.8577 46.7416 27.1437C45.7838 30.572 45.2091 34.1148 43.4578 34.0006C41.1318 33.8488 40.9949 20.1434 39.6267 11.1433C39.2792 8.8577 37.1639 8.8577 37.1639 12.572C37.1639 16.4581 37.4375 22.0005 35.7956 23.572C34.928 24.4024 33.8459 24.1434 33.3328 22.0005C33.0044 20.6291 32.6486 11.1433 32.1014 6.28621C31.5541 2.71484 29.5017 3.42906 28.9544 6.28621C28.1882 10.2863 26.6178 25.7905 23.2078 22.0005C18.9662 17.2863 16.8254 20.8641 15.9561 16.7148C14.9983 12.1434 14.7247 8.8577 10.4831 11.1433C6.28354 13.4064 3.77872 11.6672 2.13682 11.1433"
			stroke="url(#paint0_linear_3410_11071)"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_3410_11071"
				x1="4.46284"
				y1="11.2857"
				x2="78.8217"
				y2="45.2003"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor={fill || colors.primaryBlue} stopOpacity="0" />
				<stop offset="1" stopColor={fill || colors.primaryBlue} />
			</linearGradient>
		</defs>
	</svg>
);
export default GraficoTransacoesIcon;
