import styled from 'styled-components';
import { colors } from '../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const ContainerFilter = styled.div`
	align-items: center;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	display: flex;
	height: 6.3125rem;
	justify-content: flex-end;
	margin-bottom: 2rem;
	padding: 0 1.5rem;
	width: 100%;
`;

export const ButtonGroup = styled.div`
	display: flex;
	gap: 0.9375rem;
	margin-left: 1.5rem;
`;

export const Pagination = styled.div`
	width: 22.5rem;
	display: flex;
	justify-content: center;
`;

export const TableMaster = styled.table`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	background: ${colors.gridBlack};
	padding: 1.56rem;
	border-radius: 15px;

	td {
		display: flex;
		align-items: center;
		border-right: 1px solid ${colors.borderTable};
		padding: 0 1.25rem;
		height: 100%;
	}

	td.center {
		justify-content: center;
	}
`;

export const TableHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 1.5625rem;
`;

export const TabelHeaderButtons = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	width: 50%;

	.wrapper-select-react {
		width: 10.9375rem;
		height: 3.125rem;
	}
`;

export const TableHeaderRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 50%;

	.paginacao {
		margin-right: 1.5625rem;
	}

	button#adicionar-contratante {
		white-space: nowrap;
		width: 18.44rem;
		height: 2.56rem;

		svg.add-person-icon {
			width: 1.12rem;
			height: 1.25rem;
		}
	}
`;

export const THead = styled.thead`
	background: ${colors.background};
	width: 100%;
	height: 3.75rem;

	tr {
		text-align: left;
		height: 3.75rem;
		align-items: center;
		display: grid;

		grid-template-columns:
			minmax(15.625rem, 3fr)
			minmax(9.375rem, 2fr)
			minmax(15.625rem, 3fr)
			minmax(9.375rem, 2fr)
			minmax(7.8125rem, 1fr)
			minmax(7.8125rem, 1fr);
	}

	td {
		color: ${colors.primaryBlue};
	}
`;

export const TBody = styled.tbody`
	width: 100%;

	tr {
		background: ${colors.gridBlack};
		color: ${colors.white};
		text-align: left;
		align-items: center;
		height: 3.75rem;
		display: grid;

		grid-template-columns:
			minmax(15.625rem, 3fr)
			minmax(9.375rem, 2fr)
			minmax(15.625rem, 3fr)
			minmax(9.375rem, 2fr)
			minmax(7.8125rem, 1fr)
			minmax(7.8125rem, 1fr);

		:nth-child(odd) {
			background: ${colors.gridBlack};
		}
		:nth-child(even) {
			background: ${colors.rowTable};
		}
	}

	button.button-custom {
		margin: 0 auto;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1.875rem;
		height: 1.875rem;
	}

	.button-icon {
		background: ${colors.transparent};
		width: auto;
	}

	.button-icon:hover {
		background: ${colors.transparent};
		opacity: 1;
		box-shadow: 0 0 0 0px ${colors.primaryBlue};
		color: ${colors.lightGray};

		svg path {
			fill: ${colors.lightGray};
		}
	}
`;

export const ChevronContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: 1rem;
	border: 1px solid ${colors.lightGray};
	border-radius: 5px;
	margin: 0 0.5rem 0 auto;
`;

export const ErrorContent = styled.div`
	margin-top: 8rem;
	width: 31rem;
	height: auto;
	align-self: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${colors.white};
	position: relative;

	svg.attention-outlined-icon {
		width: 8.75rem;
		height: 8.75rem;
		fill: ${colors.background};
		filter: drop-shadow(0px 0px 3px ${colors.yellow});
		margin-bottom: 1.75rem;
	}

	.error-title {
		font-size: 2rem;
		margin-bottom: 1rem;
		text-transform: uppercase;
		font-family: Jost;
		font-weight: 500;
	}

	.error-text {
		font-size: 1.25rem;
		text-align: center;
		color: ${colors.lightGray};
		font-family: IBM Plex Sans;
		font-weight: 400;
	}
`;

export const CenterLoader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 3.125rem;
`;
