/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Alert from '@mui/material/Alert';
import {
	CardClient,
	CardHeader,
	CardReceivers,
	CardSmall,
	CardTransactionDetails,
	CardMetadata,
	CardHistory,
	CardItems,
} from './components';

import { getTransactionDetails, getEstornoDetails } from '../../services';
import { useAuth } from '../../hooks';

import { colors } from '../../assets/colors';
import * as S from './styled-transaction-details';

const TransactionDetails = () => {
	const [transactionDetails, setTransactionDetails] = useState({});
	const [estornoDetails, setEstornoDetails] = useState({});
	const [loading, setLoading] = useState(true);
	const { transactionId } = useParams();
	const recipientId = useAuth().currentUser.recipientId;
	useEffect(() => {
		setLoading(true);
		getTransactionDetails(transactionId, recipientId)
			.then((resp) => {
				setTransactionDetails(resp);
				getEstornoDetails(transactionId, recipientId).then((respEstorno) => {
					setEstornoDetails(respEstorno);
				});
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const errorCode = transactionDetails?.acquirerResponseCode;
	const errorMessage = transactionDetails?.acquirerMessage;
	const chargebackCode = transactionDetails?.chargebackCode;
	const chargebackMessage = transactionDetails?.chargebackMessage;

	return (
		<S.Container>
			{transactionDetails.status === 'refused' && (
				<Alert
					id="alert"
					severity="error"
					variant="outlined"
					sx={{
						color: `${colors.primaryRed}`,
						borderColor: `${colors.primaryRed}`,
						fontFamily: 'IBM Plex Sans, sans-serif',
						fontSize: '1em',
						fontWeight: '600',
						textAlign: 'center',
						alignItems: 'center',
					}}
				>
					{`Motivo da Recusa: ${
						errorCode ? `${errorCode} -` : ''
					} ${errorMessage}`}
				</Alert>
			)}
			<CardHeader
				id="cardheader"
				transaction={transactionDetails?.id}
				status={transactionDetails?.status}
				installments={transactionDetails?.installments}
				authorizedValue={transactionDetails?.authorizedValue}
				pegaDados={getTransactionDetails}
				loading={loading}
				dados={transactionDetails}
				dadosEstorno={estornoDetails}
				setLoading={setLoading}
			/>
			<CardSmall
				id="smallcard1"
				title="Cartão de crédito"
				paymentMethod={transactionDetails?.paymentMethod}
				cardBrand={transactionDetails?.cardBrand}
				cardFirstDigits={transactionDetails?.cardFirstDigits}
				cardLastDigits={transactionDetails?.cardLastDigits}
				cardHolderName={transactionDetails?.cardHolderName}
				loading={loading}
			/>
			<CardSmall
				id="smallcard2"
				title="Valor capturado"
				grossValue={transactionDetails?.grossValue}
				loading={loading}
			/>
			<CardSmall
				id="smallcard3"
				title="Total de saídas"
				saidas={transactionDetails?.discountsValue?.total}
				loading={loading}
			/>
			<CardSmall
				id="smallcard4"
				title="Valor líquido"
				liquidValue={transactionDetails?.netValue}
				loading={loading}
			/>
			{transactionDetails.status === 'chargedback' && (
				<Alert
					id="alert"
					severity="info"
					sx={{
						fontFamily: 'IBM Plex Sans, sans-serif',
						fontSize: '1em',
						fontWeight: '600',
						textAlign: 'center',
						alignItems: 'center',
					}}
				>
					{`Motivo do chargeback: ${
						chargebackCode ? `${chargebackCode} -` : ''
					} ${chargebackMessage}`}
				</Alert>
			)}
			<CardReceivers
				id="receiverscard"
				title="Divisão de recebedores"
				splitRules={transactionDetails?.splitRules}
				loading={loading}
			/>
			<CardClient
				id="clientcard"
				title="Detalhes do Cliente"
				customer={transactionDetails?.customer}
				address={transactionDetails?.billing?.address}
				loading={loading}
			/>
			<CardTransactionDetails
				id="transactiondetails"
				title="Detalhes da transação"
				acquirerName={transactionDetails?.acquirerName}
				acquirerResponseCode={transactionDetails?.acquirerResponseCode}
				captureMethod={transactionDetails?.captureMethod}
				nsu={transactionDetails?.nsu}
				tid={transactionDetails?.tid}
				authorizationCode={transactionDetails?.authorizationCode}
				description={transactionDetails?.softDescriptor}
				signature={transactionDetails?.orderId}
				loading={loading}
			/>
			<CardItems
				id="itemscard"
				title="Itens da transação"
				items={transactionDetails?.items}
				loading={loading}
			/>
			<CardMetadata
				id="metadata"
				title="Metadata"
				metadata={transactionDetails?.metadata}
				loading={loading}
			/>

			<CardHistory
				id="history"
				title="Histórico de transações"
				history={transactionDetails?.operations}
				loading={loading}
			/>
		</S.Container>
	);
};

export default TransactionDetails;
