import React from 'react';

const VisaIcon = ({ title, style }) => (
	<svg
		width="26"
		height="16"
		viewBox="0 0 26 17"
		style={style}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="visa-icon"
	>
		<title>{title || 'Ícone Visa'}</title>
		<rect
			x="0.15"
			y="0.15"
			width="25.7"
			height="15.7"
			rx="1.85"
			fill="#FAFAFA"
			stroke="#B1B6B6"
			strokeWidth="0.3"
		/>
		<path
			d="M13.3092 6.91817C13.2989 7.75395 14.0307 8.22038 14.5821 8.49768C15.1485 8.78222 15.3388 8.96466 15.3366 9.21908C15.3323 9.60852 14.8848 9.78036 14.4659 9.78706C13.7351 9.79877 13.3103 9.58341 12.9724 9.42049L12.7092 10.692C13.0481 10.8533 13.6756 10.9939 14.3264 11C15.8539 11 16.8533 10.2217 16.8587 9.01488C16.8646 7.48336 14.8064 7.39855 14.8204 6.71397C14.8253 6.50642 15.0172 6.28492 15.4377 6.22857C15.6458 6.20011 16.2203 6.17835 16.8717 6.488L17.1273 5.25776C16.7771 5.12609 16.3268 5 15.7663 5C14.3286 5 13.3173 5.78892 13.3092 6.91817ZM19.5839 5.10601C19.305 5.10601 19.0699 5.27394 18.965 5.53171L16.783 10.9096H18.3094L18.6132 10.0431H20.4785L20.6547 10.9096H22L20.826 5.10601H19.5839ZM19.7974 6.6738L20.2379 8.85308H19.0315L19.7974 6.6738ZM11.4585 5.10601L10.2553 10.9096H11.7098L12.9124 5.10601H11.4585ZM9.30671 5.10601L7.79275 9.05617L7.18035 5.69741C7.10846 5.32248 6.8247 5.10601 6.50958 5.10601H4.03459L4 5.2745C4.50808 5.38832 5.08534 5.57188 5.43505 5.76827C5.64909 5.88823 5.71017 5.99312 5.78043 6.27822L6.94036 10.9096H8.47757L10.8342 5.10601H9.30671Z"
			fill="#254AA5"
		/>
	</svg>
);
export default VisaIcon;
