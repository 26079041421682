/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { format } from 'date-fns';
import React from 'react';
import { Loader, LoadingRectangle } from '../../../../components';
import { formatarParaReais } from '../../../../utils';

import * as S from './styled-card-items';

const Item = (props) => {
	const { index, item, loading } = props;
	const { id, title, unit_price, quantity, category, tangible, venue, date } =
		item;
	return (
		<S.ItemContainer>
			<S.TitleHeader>
				<h3>{`${index + 1}. ${title}`}</h3>
			</S.TitleHeader>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>ID do produto</h5>
					</S.ColTitle>
					<span>{!loading ? id || '-' : <LoadingRectangle />}</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Preço da unidade</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							formatarParaReais(unit_price / 100, true) || '-'
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Quantidade</h5>
					</S.ColTitle>
					<span>{!loading ? quantity || '-' : <LoadingRectangle />}</span>
				</S.Col>
			</S.Row>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Bem físico</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							(tangible ? 'Sim' : 'Não') || '-'
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Categoria</h5>
					</S.ColTitle>
					<span>{!loading ? category || '-' : <LoadingRectangle />}</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Data do evento</h5>
					</S.ColTitle>
					<span>
						{!loading ? (
							(date && format(date, 'dd/MM/yyyy')) || '-'
						) : (
							<LoadingRectangle />
						)}
					</span>
				</S.Col>
			</S.Row>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Local do evento</h5>
					</S.ColTitle>
					<span>{!loading ? venue || '-' : <LoadingRectangle />}</span>
				</S.Col>
			</S.Row>
		</S.ItemContainer>
	);
};

function CardItems(props) {
	const { id, div, title, loading, items } = props;
	return (
		<S.Container id={id} div={div}>
			<S.TitleHeader>
				<S.Title style={{ justifyContent: 'flex-start' }}>{title}</S.Title>
			</S.TitleHeader>

			{!loading ? (
				items?.map((item, index) => (
					<Item
						index={index}
						item={item}
						productName={items?.title}
						productId={items?.id}
						price={items?.unit_price}
						quantity={items?.quantity}
						category={items?.category}
						tangible={items?.tangible}
						venue={items?.venue}
						date={items?.date}
					/>
				))
			) : (
				<Loader />
			)}
		</S.Container>
	);
}

export default CardItems;
