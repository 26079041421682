import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	width: auto;
	height: 1.5rem;
	flex-direction: row;
	align-items: center;
	margin-top: 1rem;
	border-radius: 0rem 0.5rem 0.5rem 0rem;
`;

export const Row = styled.div`
	width: auto;
	display: flex;
	flex-direction: row;
	font-size: 1.5rem;
	font-family: 'IBM Plex Sans';
	font-style: normal;

	p {
		display: flex;
		flex-direction: row;
		font-size: 0.875rem;
		border-radius: 0.5rem;
		border-left: 0px;
		color: ${colors.lightGray};
		border: 1px dashed ${colors.lightGray};
		border-radius: 0rem 0.5rem 0.5rem 0rem;
		padding: 0.25rem 0.75rem;
		border-left: 0rem;
		margin-right: 1rem;
	}

	h4 {
		display: flex;
		align-items: center;
		width: auto;
		font-size: 0.875rem;
		background-color: ${colors.lightGray};
		border-radius: 0.5rem 0rem 0rem 0.5rem;
		border: 1px solid ${colors.lightGray};
		padding: 0.25rem 0.75rem;
		gap: 1rem;
	}
	:hover {
		p {
			color: ${colors.primaryBlue};
		}
		h4 {
			background-color: ${colors.primaryBlue};
		}
	}
`;
