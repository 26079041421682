import React from 'react';
import { CardExtratoRecebedores, InfosTableRecebedores } from '../..';
import { useRecipientExtractInfo } from '../../../../hooks';

import * as s from './styled-extrato-recebedores';

const ExtratoRecebedores = ({
	intervaloInicial,
	intervaloDatas,
	setIntervaloDatas,
	buscarExtrato,
	recipientId,
}) => {
	const { antecipacoes, isLoadingAntecipacoes, balanco, isLoadingBalanco } =
		useRecipientExtractInfo();

	return (
		<s.Group>
			<CardExtratoRecebedores
				title="Saldo atual"
				valor={balanco?.available}
				loading={isLoadingBalanco}
				saque
			/>
			<CardExtratoRecebedores
				title="A receber"
				valor={balanco?.toRecieve}
				loading={isLoadingBalanco}
				recipientId={recipientId}
				receber
			/>
			<CardExtratoRecebedores
				title="Antecipações pendentes"
				valor={antecipacoes?.netValue || 0}
				loading={isLoadingAntecipacoes}
				antecipacoes
			/>
			<InfosTableRecebedores
				intervaloInicial={intervaloInicial}
				selectedDayRange={intervaloDatas}
				setSelectedDayRange={setIntervaloDatas}
				buscarExtrato={buscarExtrato}
			/>
		</s.Group>
	);
};

export default ExtratoRecebedores;
