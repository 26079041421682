import Switch from 'react-switch';
import { colors, Icons } from '../../../../assets';
import * as s from './styled-transaction-switch-custom';

export default function TransactionSwitch({
	checked,
	onChange,
	name,
	readOnly,
}) {
	return (
		<s.Label>
			<s.SelectDiv checked={checked}>
				<Switch
					width={72}
					height={36}
					id="transactionSwitch"
					offHandleColor={colors.primaryBlue}
					onHandleColor={colors.primaryBlue}
					onColor={colors.gray}
					offColor={colors.gray}
					checkedIcon={
						<Icons.TransactionTableSwitchIcon
							style={{
								borderRadius: '5px',
							}}
						/>
					}
					uncheckedIcon={<Icons.TransactionSwitchGraphicIcon />}
					checkedHandleIcon={<Icons.TransactionSwitchGraphicIcon />}
					uncheckedHandleIcon={<Icons.TransactionTableSwitchIcon />}
					onChange={onChange}
					checked={checked}
					name={name}
					disabled={readOnly}
					className="transactionSwitch"
					borderRadius={8}
					boxShadow={`0px 0px 1px 1px ${colors.darkTurquoise}`}
				/>
			</s.SelectDiv>
		</s.Label>
	);
}
