import { React, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { compareAsc, compareDesc, getTime, parse } from 'date-fns';
import { getTransactionsLogs } from '../../services';
import {
	Pagination,
	FiltersLog,
	TableLog,
	Loader,
	SelectReact,
} from '../../components';
import { Icons } from '../../assets';
import { validateEmail } from '../../utils';

import * as s from './styled-logs';

function Logs() {
	const optionsItemsPage = [
		{
			label: '15 itens por página',
			value: 15,
		},
		{
			label: '30 itens por página',
			value: 30,
		},
		{
			label: '60 itens por página',
			value: 60,
		},
		{
			label: '100 itens por página',
			value: 100,
		},
	];

	const optionsOrder = [
		{ value: 'asc', label: 'Nome A-Z' },
		{ value: 'desc', label: 'Nome Z-A' },
	];

	const [isLoading, setIsLoading] = useState(true);
	const [logs, setLogs] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	const [itemsPerPage, setItemsPerPage] = useState(() => {
		if (
			sessionStorage.getItem('LogsItemsPerPage') &&
			sessionStorage.getItem('LogsItemsPerPage') !== ''
		) {
			return JSON.parse(sessionStorage.getItem('LogsItemsPerPage'));
		}
		sessionStorage.setItem(
			'LogsItemsPerPage',
			JSON.stringify(optionsItemsPage[0])
		);
		return optionsItemsPage[0];
	});

	const [page, setPage] = useState(1);
	const [logsFiltrados, setLogsFiltrados] = useState([]);
	const [order, setOrder] = useState({ value: 'asc', label: 'A-Z' });

	const [sort, setSort] = useState({
		name: 'asc',
		acao: 'asc',
		email: 'asc',
		data: 'asc',
	});

	const intervaloInicial = () => {
		const data = new Date();

		const diaHoje = data.getDate();
		const mesHoje = data.getMonth() + 1;
		const anoHoje = data.getFullYear();

		data.setDate(diaHoje - 7);

		const diaInicio = data.getDate();
		const mesInicio = data.getMonth() + 1;
		const anoInicio = data.getFullYear();

		const dataInicialStorage = JSON.parse(
			sessionStorage.getItem('LogsInicialDate')
		);
		const dataFinalStorage = JSON.parse(
			sessionStorage.getItem('LogsFinalDate')
		);

		if (dataInicialStorage && dataFinalStorage) {
			return {
				from: dataInicialStorage,
				to: dataFinalStorage,
			};
		}
		return {
			from: { day: diaInicio, month: mesInicio, year: anoInicio },
			to: { day: diaHoje, month: mesHoje, year: anoHoje },
		};
	};

	const [intervaloDatas, setIntervaloDatas] = useState(intervaloInicial);

	function dataFormatada() {
		if (intervaloDatas?.day) {
			return {
				from: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
				to: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
			};
		}
		const dataInicial = intervaloDatas.from;
		const dataFinal = intervaloDatas.to;

		return {
			from: `${dataInicial.year}/${dataInicial.month}/${dataInicial.day}`,
			to: `${dataFinal.year}/${dataFinal.month}/${dataFinal.day}`,
		};
	}

	function filtrarLogs() {
		const busca = searchValue;

		const filterEmail = (mail) => {
			if (mail !== undefined)
				return mail.toLowerCase().indexOf(busca.toLowerCase()) !== -1;
		};
		setLogsFiltrados(
			logs.filter(
				(el) =>
					filterEmail(el.email) ||
					el.name?.toLowerCase().indexOf(busca.toLowerCase()) !== -1 ||
					el.acao?.toLowerCase().indexOf(busca.toLowerCase()) !== -1
			)
		);
	}

	function orderLogs() {
		if (logs.length > 0) {
			if (order.value === 'asc') {
				setLogsFiltrados(
					logs.sort((a, b) => {
						if (a.name > b.name) return 1;
						if (a.name < b.name) return -1;
						return 0;
					})
				);
			} else {
				setLogsFiltrados(
					logs.sort((a, b) => {
						if (a.name > b.name) return -1;
						if (a.name < b.name) return 1;
						return 0;
					})
				);
			}
		}
	}

	function sortBy(type) {
		if (logs.length > 0) {
			let nameA;
			let nameB;

			if (sort[type] === 'asc') {
				setSort({ ...sort, [type]: 'desc' });
			} else {
				setSort({ ...sort, [type]: 'asc' });
			}

			if (sort[type] === 'asc') {
				logsFiltrados.sort((a, b) => {
					nameA = a[type]?.toLowerCase();
					nameB = b[type]?.toLowerCase();
					if (nameA > nameB) return 1;
					if (nameA < nameB) return -1;
					return 0;
				});
			} else {
				logsFiltrados.sort((a, b) => {
					nameA = a[type]?.toLowerCase();
					nameB = b[type]?.toLowerCase();
					if (nameA > nameB) return -1;
					if (nameA < nameB) return 1;
					return 0;
				});
			}
		}
	}

	function sortByDate() {
		if (logs.length > 0) {
			logsFiltrados.forEach((e) => {
				if (typeof e.data === 'string') e.data = Number(e.data);
			});

			if (sort.data === 'asc') {
				setSort({ ...sort, data: 'desc' });
			} else {
				setSort({ ...sort, data: 'asc' });
			}

			if (sort.data === 'asc') {
				logsFiltrados.sort((a, b) => compareAsc(a.data, b.data));
			} else {
				logsFiltrados.sort((a, b) => compareDesc(a.data, b.data));
			}
		}
	}

	function buscarLogs() {
		setIsLoading(true);

		const dataInicial = getTime(
			parse(dataFormatada().from, 'yyyy/MM/dd', new Date())
		);
		const dataFinial = getTime(
			parse(dataFormatada().to, 'yyyy/MM/dd', new Date())
		);

		const isEmail = () => {
			if (!validateEmail(searchValue)) {
				return '';
			}
			return '';
		};

		getTransactionsLogs(dataInicial, dataFinial, '', '', isEmail())
			.then((resp) => {
				setLogs(resp);
				setLogsFiltrados(resp);
			})
			.catch(() => toast.error('Erro ao buscar logs'))
			.finally(() => setIsLoading(false));
	}

	useEffect(() => {
		buscarLogs();
	}, []);

	useEffect(() => {
		orderLogs();
		filtrarLogs();
	}, [order]);

	useEffect(() => {
		if (searchValue.length > 2) {
			filtrarLogs();
		}
		if (searchValue.length === 0) {
			setLogsFiltrados(logs);
		}
	}, [searchValue.length]);

	return (
		<s.Container>
			<FiltersLog
				isLoading={isLoading}
				logs={logs}
				setLogs={setLogs}
				filtrarLogs={filtrarLogs}
				buscarLogs={buscarLogs}
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				page={page}
				setPage={setPage}
				logsFiltrados={logsFiltrados}
				setLogsFiltrados={setLogsFiltrados}
				intervaloDatas={intervaloDatas}
				setIntervaloDatas={setIntervaloDatas}
			/>
			{isLoading ? (
				<s.CenterLoader>
					<Loader />
				</s.CenterLoader>
			) : logsFiltrados.length > 0 ? (
				<s.TableContainer>
					<s.ContainerHeader>
						<s.Toolbar>
							<s.LeftOptions>
								<SelectReact
									id="select-ordenar-por"
									options={optionsOrder}
									isSearchable={false}
									onChange={(opt) => setOrder(opt)}
									value={order}
									outlined
								/>
							</s.LeftOptions>

							<s.SelectDiv className="margin-right">
								<SelectReact
									value={itemsPerPage}
									onChange={(opt) => {
										setItemsPerPage(opt);
										setPage(1);
										sessionStorage.setItem(
											'LogsItemsPerPage',
											JSON.stringify(opt)
										);
									}}
									isSearchable={false}
									options={optionsItemsPage}
									readOnly={isLoading}
									outlined
									height="50px"
									width="220px"
								/>

								<s.Pagination>
									{Math.ceil(logsFiltrados.length / itemsPerPage.value) > 1 && (
										<Pagination
											paginaAtual={page - 1}
											totalPaginas={Math.ceil(
												logsFiltrados.length / itemsPerPage.value
											)}
											onClick={(pagina) => {
												setPage(pagina.selected + 1);
											}}
											disabled={isLoading}
										/>
									)}
								</s.Pagination>
							</s.SelectDiv>
						</s.Toolbar>
					</s.ContainerHeader>
					<TableLog
						isLoading={isLoading}
						logs={logs}
						page={page}
						setPage={setPage}
						logsFiltrados={logsFiltrados}
						setLogsFiltrados={setLogsFiltrados}
						itemsPerPage={itemsPerPage}
						setItemsPerPage={setItemsPerPage}
						sortBy={sortBy}
						sortByDate={sortByDate}
						sort={sort}
						setSort={setSort}
					/>
				</s.TableContainer>
			) : (
				<s.ErrorContent>
					<Icons.AttentionOutlined />
					<h2 className="error-title">Sem resultados</h2>
					<p className="error-text">
						Não encontramos resultados para sua busca. Reveja o que foi digitado
						ou faça uma nova pesquisa.
					</p>
				</s.ErrorContent>
			)}
		</s.Container>
	);
}

export default Logs;
