import React, { useState, useEffect } from 'react';
import { ButtonCustom } from '../../..';
import { useAuth, useRecipients } from '../../../../hooks';
import {
	loginWithoutAuthPersist,
	postCriarRecebedor,
	postRegistrarRecebedor,
} from '../../../../services';
import { mascaraCPF } from '../../../../utils';
import { ModalConfirmacaoSenha } from '../../../molecules';

import * as s from './styled-confirmacao';

const FormGroupTitle = ({ title, subtitle, onClick }) => (
	<s.Header>
		<div>
			<s.Title>{title}</s.Title>
			{onClick && (
				<ButtonCustom
					outlined
					width="5.6875rem"
					height="2.3125rem"
					onClick={onClick}
				>
					Editar
				</ButtonCustom>
			)}
		</div>
		<s.Subtitle>{subtitle}</s.Subtitle>
	</s.Header>
);

const Confirmacao = () => {
	const recipientProfile = useAuth().currentUser;
	const recipientMail = recipientProfile.email;
	const recipientId = recipientProfile.recipientId;
	const [isModalConfirmacaoOpen, setIsModalConfirmacaoOpen] = useState(false);
	const [senha, setSenha] = useState('');
	const [erroSenha, setErroSenha] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const {
		step,
		setStep,
		tipoPessoa,
		documento,
		email,
		nome,
		telefone,
		antecipationVolumePercentage,
		transferInterval,
		transferDay,
		automaticAntecipationEnabled,
		body,
		companyName,
		banks,
		managingPartners,
		bankCode,
		agencia,
		agenciaDv,
		conta,
		type,
		contaDv,
		setIsCancelarCriacaoOpen,
		setAlertConclusao,
		setNewRecipientId,
		bankAccountId,
	} = useRecipients();

	const bankName =
		bankCode.value && banks.find((bank) => bank.value === bankCode.value);
	const bankNameBankAccountId =
		bankAccountId.bankCode &&
		banks.find((bank) => bank.value === bankAccountId.bankCode);

	const translateTransferInterval = (interval) => {
		if (interval === 'daily') return 'Diariamente';
		if (interval === 'weekly') return 'Semanal';
		if (interval === 'monthly') return 'Mensal';
	};
	const translateType = (accountType) => {
		if (accountType === 'conta_corrente') return 'Conta Corrente';
		if (accountType === 'conta_corrente_conjunta')
			return 'Conta Corrente Conjunta';
		if (accountType === 'conta_poupanca') return 'Conta Poupança';
		if (accountType === 'conta_poupanca_conjunta')
			return 'Conta Poupança Conjunta';
	};

	const validarSenha = () => {
		return new Promise((resolve, reject) => {
			loginWithoutAuthPersist(recipientMail, senha, true)
				.then(() => resolve())
				.catch((e) => {
					setErroSenha(e.msg);
					setIsLoading(false);
					reject();
				});
		});
	};

	const registrarRecebedor = (novoRecebedorId) => {
		return new Promise((resolve, reject) => {
			postRegistrarRecebedor(recipientId, novoRecebedorId)
				.then(() => {
					setStep(5);
					setAlertConclusao('sucesso');
					resolve();
				})
				.catch(() => {
					setIsModalConfirmacaoOpen(false);
					setAlertConclusao('erro');
					reject();
				})
				.finally(() => {
					setIsLoading(false);
					setStep(5);
				});
		});
	};

	const confirmarSenhaNovoRecebedor = () => {
		setIsLoading(true);

		validarSenha().then(() => {
			postCriarRecebedor(body)
				.then((resp) => {
					const novoRecebedorId = resp.data.id;
					setNewRecipientId(novoRecebedorId);
					registrarRecebedor(novoRecebedorId);
					return;
				})
				.catch(() => {
					setAlertConclusao('erro');
					setIsLoading(false);
					setStep(5);
				});
		});
		return;
	};

	useEffect(() => {
		if (erroSenha) setErroSenha('');
	}, [senha]);

	return (
		<s.Container>
			<ModalConfirmacaoSenha
				open={isModalConfirmacaoOpen}
				setIsOpen={setIsModalConfirmacaoOpen}
				title="Autenticação necessária"
				onClickOutside
				onClose={() => setIsModalConfirmacaoOpen(false)}
				onKeyPress={(e) => {
					if (e.keyCode === 'Enter') confirmarSenhaNovoRecebedor();
				}}
				senha={senha}
				setSenha={setSenha}
				onSubmit={confirmarSenhaNovoRecebedor}
				isLoading={isLoading}
				erroSenha={erroSenha}
			/>
			<FormGroupTitle
				title="CONFIRMAÇÃO DE CADASTRO DO RECEBEDOR"
				subtitle="Confirme abaixo os dados informados e finalize a criação desse recebedor"
			/>
			<s.FormGroup>
				<s.TitleGroup>
					<h3>Dados do recebedor</h3>
					<ButtonCustom
						outlined
						width="5.6875rem"
						height="2.3125rem"
						onClick={() => setStep(1)}
					>
						Editar
					</ButtonCustom>
				</s.TitleGroup>

				<s.Row>
					<s.Column>
						<s.Label>{tipoPessoa === 'PF' ? 'CPF' : 'CNPJ'}</s.Label>
						<s.Span>{documento}</s.Span>
					</s.Column>
					<s.Column>
						<s.Label>E-mail</s.Label>
						<s.Span>{email || '-'}</s.Span>
					</s.Column>
					<s.Column>
						<s.Label>Telefone</s.Label>
						<s.Span>{telefone || '-'}</s.Span>
					</s.Column>
				</s.Row>
				<s.Row>
					<s.Column>
						<s.Label>Nome</s.Label>
						<s.Span>{nome || '-'}</s.Span>
					</s.Column>
				</s.Row>
			</s.FormGroup>

			<s.FormGroup>
				<s.TitleGroup>
					<h3>Conta bancária</h3>
					<ButtonCustom
						outlined
						width="5.6875rem"
						height="2.3125rem"
						onClick={() => setStep(2)}
					>
						Editar
					</ButtonCustom>
				</s.TitleGroup>

				<s.Row>
					<s.Column>
						<s.Label>Nome da conta bancária</s.Label>
						<s.Span>{companyName || '-'}</s.Span>
					</s.Column>
					<s.Column>
						<s.Label>Banco</s.Label>
						<s.Span>{bankName?.label || bankNameBankAccountId?.label}</s.Span>
					</s.Column>
				</s.Row>
				<s.Row>
					<s.Column>
						<s.Label>Tipo de conta bancária</s.Label>
						<s.Span>{translateType(type.value) || '-'}</s.Span>
					</s.Column>
					<s.Column>
						<s.Label>Agência</s.Label>
						<s.Span>
							{agencia}
							{agenciaDv ? `-${agenciaDv}` : ''}
						</s.Span>
					</s.Column>
					<s.Column>
						<s.Label>Conta</s.Label>
						<s.Span>
							{conta}-{contaDv}
						</s.Span>
					</s.Column>
				</s.Row>
			</s.FormGroup>

			<s.Divisoria />

			{tipoPessoa === 'PJ' && (
				<>
					<s.FormGroup>
						<FormGroupTitle
							title="DADOS DOS SÓCIOS"
							onClick={() => setStep(1)}
						/>
						{managingPartners.length ? (
							managingPartners.map((socio, index) => (
								<s.Row key={index}>
									<s.Column>
										<s.Label>Nome</s.Label>
										<s.Span>{socio.name}</s.Span>
									</s.Column>
									<s.Column>
										<s.Label>CPF</s.Label>
										<s.Span>{mascaraCPF(socio.document_number)}</s.Span>
									</s.Column>
									<s.Column>
										<s.Label>E-mail</s.Label>
										<s.Span>{socio.email}</s.Span>
									</s.Column>
								</s.Row>
							))
						) : (
							<s.Row>
								<s.Span>Nenhum sócio cadastrado</s.Span>
							</s.Row>
						)}
					</s.FormGroup>
					<s.Divisoria />
				</>
			)}

			<s.FormGroup>
				<FormGroupTitle
					title="CONFIGURAÇÕES DE ANTECIPAÇÃO"
					onClick={() => setStep(3)}
				/>
				<s.Row>
					<s.Column>
						<s.Label>Modelo de antecipação</s.Label>
						<s.Span>Manual por volume</s.Span>
					</s.Column>
					<s.Column>
						<s.Label>Volume antecipável</s.Label>
						<s.Span>{antecipationVolumePercentage}%</s.Span>
					</s.Column>
				</s.Row>
			</s.FormGroup>
			<s.Divisoria />
			<s.FormGroup>
				<FormGroupTitle
					title="CONFIGURAÇÕES DE TRANSFERÊNCIA"
					onClick={() => setStep(3)}
				/>
				<s.Row>
					<s.Column>
						<s.Label>Transferência automática</s.Label>
						<s.Span>
							{automaticAntecipationEnabled ? 'Habilitada' : 'Desabilitada'}
						</s.Span>
					</s.Column>
					<s.Column>
						<s.Label>Intervalo de transferências automáticas</s.Label>
						<s.Span>{translateTransferInterval(transferInterval)}</s.Span>
					</s.Column>
					{transferInterval !== 'daily' && (
						<s.Column>
							<s.Label>Dia da transferência</s.Label>
							<s.Span>{transferDay}</s.Span>
						</s.Column>
					)}
				</s.Row>
			</s.FormGroup>
			<s.ButtonGroup>
				<ButtonCustom
					outlined
					theme="gray"
					onClick={() => {
						setIsCancelarCriacaoOpen(true);
					}}
				>
					Cancelar
				</ButtonCustom>
				<s.RightButtons>
					<ButtonCustom
						outlined
						theme="green"
						className="botao-voltar"
						onClick={() => setStep(step - 1)}
					>
						Voltar
					</ButtonCustom>
					<ButtonCustom
						theme="green"
						onClick={() => setIsModalConfirmacaoOpen(true)}
					>
						Criar recebedor
					</ButtonCustom>
				</s.RightButtons>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default Confirmacao;
