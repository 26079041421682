/* eslint-disable no-unused-vars */
import React from 'react';
import { useRecipients } from '../../../../hooks';
import { Icons, colors } from '../../../../assets/';

import * as s from './styled-form-steps';

const formSteps = ({ isChecked }) => {
	const { step: currentStep, setSteps: setCurrentStep } = useRecipients();
	const steps = [
		'dados',
		'conta bancária',
		'configurações',
		'confirmação',
		'conclusão',
	];
	return (
		<s.Container>
			{steps.map((step, index) => {
				const ultimoItemMap = index === steps.length - 1;
				const isCurrentStep = currentStep > index;
				return (
					<>
						<s.Step key={step} index={index}>
							<s.StepText className={isCurrentStep && 'active'}>
								{`${index + 1}. ${step.toUpperCase()}`}
							</s.StepText>
						</s.Step>
						{!ultimoItemMap && (
							<s.Icon index={index}>
								<Icons.ChevronThin
									fill={currentStep > index + 1 && colors.primaryBlue}
								/>
							</s.Icon>
						)}
					</>
				);
			})}
		</s.Container>
	);
};

export default formSteps;
