import React from 'react';
import { colors } from '../colors';

const FilterIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="filter-icon"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || 'Ícone de filtro'}</title>
		<g clipPath="url(#clip0_1474:4762)">
			<path
				d="M1.13151 3.29271e-06H18.8736C19.0721 0.000838592 19.2669 0.0538456 19.4384 0.153748C19.6099 0.25365 19.7521 0.396934 19.8508 0.569172C19.9494 0.74141 20.001 0.936545 20.0004 1.13503C19.9998 1.33352 19.947 1.52837 19.8473 1.69999L13.2052 12.2842V19.1737C13.2046 19.3187 13.1658 19.461 13.0928 19.5863C13.0197 19.7115 12.9151 19.8154 12.7892 19.8874C12.6633 19.9594 12.5207 19.997 12.3757 19.9964C12.2307 19.9959 12.0884 19.9572 11.9631 19.8842L7.33676 17.1579C7.13432 17.0361 6.96684 16.864 6.85063 16.6584C6.73442 16.4527 6.67346 16.2204 6.67363 15.9842V12.3L0.147301 1.71053C0.0462197 1.5376 -0.00736566 1.34102 -0.00805 1.14072C-0.00873435 0.940414 0.0435167 0.743496 0.143414 0.569879C0.24331 0.396261 0.387281 0.252117 0.560798 0.152045C0.734314 0.0519729 0.931203 -0.000478743 1.13151 3.29271e-06V3.29271e-06Z"
				fill={fill || colors.primaryBlue}
			/>
		</g>
		<defs>
			<clipPath id="clip0_1474:4762">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default FilterIcon;
