import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router';
import toast from 'react-hot-toast';
import * as s from './styled-card-extrato';
import {
	LoadingRectangle,
	ButtonCustom,
	ModalConfirmacao,
} from '../../../../components';
import { formatarParaReais, padStart } from '../../../../utils';
import { postCancelarAntecipacao } from '../../../../services';
import { useExtrato } from '../../../../hooks';
import { Icons } from '../../../../assets';

const Card = ({
	history,
	title,
	valor,
	loading,
	saque,
	receber,
	antecipacoes,
	dados,
	setDados,
	loadingLimites,
	limitesAntecipacao,
	buscarLimites,
}) => {
	const { idRecebedor } = useExtrato();
	const [deletando, setDeletando] = useState({});
	const [modalDeletar, setModalDeletar] = useState({ open: false });
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);

	const totalAntecipacao = (ant) => {
		const valorBruto = ant.amount - ant.fee;
		const taxaAntecipacao = -1 * ant.anticipation_fee;
		const taxaTransf = ant.automatic_transfer ? -290 : 0;
		const valorAntecipacao = valorBruto + taxaAntecipacao + taxaTransf;
		return formatarParaReais(valorAntecipacao / 100, true);
	};

	const dataFormatada = (data) => {
		const formattedDate = new Date(data);
		const dia = padStart(formattedDate.getDate());
		const mes = padStart(formattedDate.getMonth() + 1);
		const ano = padStart(formattedDate.getFullYear()).replace('20', '');
		return `${dia}/${mes}/${ano}`;
	};

	const cancelarAntecipacao = (antecipacao) => {
		const idAntecipacao = antecipacao.id;
		setDeletando((prev) => ({ ...prev, [idAntecipacao]: true }));

		postCancelarAntecipacao(idRecebedor, idAntecipacao)
			.then(() => {
				const antecipacoesAtualizadas = dados;

				for (let pos = 0; pos < dados.length; pos++) {
					if (dados[pos].id === idAntecipacao) {
						antecipacoesAtualizadas.splice(pos, 1);
						break;
					}
				}

				setDados(antecipacoesAtualizadas);
				toast.success('Antecipação cancelada');
				forceUpdate();
			})
			.catch(() => {
				setDeletando((prev) => prev);
				toast.error('Houve um erro ao cancelar a antecipação');
			})
			.finally(() => setModalDeletar({ open: false }));
	};

	return (
		<s.Card>
			<ModalConfirmacao
				isOpen={modalDeletar?.open}
				title="CANCELAR ANTECIPAÇÃO"
				text="Atenção: caso queira antecipar nesta mesma data, você precisará criar outro pedido antes das 10:50 de hoje."
				question="Quer cancelar mesmo assim?"
				onPositivePress={() => cancelarAntecipacao(modalDeletar?.antecipacao)}
				negativeButtonLabel="Não quero cancelar"
				positiveButtonLabel="Sim, quero cancelar"
				isLoading={deletando[modalDeletar?.antecipacao?.id]}
				closeModal={() => setModalDeletar({ open: false })}
			/>

			<h2>{title}</h2>

			{loading ? (
				<LoadingRectangle />
			) : (
				<>
					<span className="cifrao">
						{!antecipacoes && 'R$'}
						<span className="valor">
							{valor === 0
								? !antecipacoes && '0,00'
								: !antecipacoes && formatarParaReais(valor / 100)}
						</span>
					</span>
					{antecipacoes && (
						<s.Column>
							{dados?.length ? (
								dados.map((antecipacao) => (
									<div>
										<span>{dataFormatada(antecipacao.payment_date)}</span>
										<p>{totalAntecipacao(antecipacao)}</p>
										<s.Button
											onClick={() =>
												setModalDeletar({ open: true, antecipacao })
											}
											disabled={deletando[antecipacao.id]}
										>
											<Icons.Close title="Cancelar antecipação" />
										</s.Button>
									</div>
								))
							) : (
								<span className="msg">Nenhuma antecipação disponível</span>
							)}
						</s.Column>
					)}
				</>
			)}

			{loading ? (
				<LoadingRectangle />
			) : saque ? (
				<p>
					Disponível para saque{' '}
					{valor === 0 ? 'R$ 0,00' : formatarParaReais(valor / 100, true)}
				</p>
			) : (
				receber &&
				(loadingLimites ? (
					<LoadingRectangle />
				) : limitesAntecipacao <= 0 ? (
					<p>
						<span className="msg">Não há limites de antecipação. </span>
						<s.Reload onClick={buscarLimites}>Atualizar.</s.Reload>
					</p>
				) : (
					<ButtonCustom
						theme="green"
						textTransform="capitalize"
						onClick={() => history.push('/antecipacoes')}
					>
						Antecipar
					</ButtonCustom>
				))
			)}
		</s.Card>
	);
};

export default withRouter(Card);
