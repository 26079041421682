import Acessos from './acessos-icon';
import Clientes from './clientes-icon';
import Configuracoes from './configuracoes-icon';
import Contratantes from './contratantes-icon';
import Extrato from './extrato-icon';
import Links from './links-icon';
import Logs from './logs-icon';
import { ReactComponent as Placeholder } from './Placeholder.svg';
import Recebedores from './recebedores-icon';
import Resumo from './resumo-icon';
import Taxas from './taxas-icon';
import Transacoes from './transacoes-icon';

const sidemenuIcons = {
	Acessos,
	Clientes,
	Configuracoes,
	Contratantes,
	Extrato,
	Links,
	Logs,
	Placeholder,
	Recebedores,
	Resumo,
	Taxas,
	Transacoes,
};

export default sidemenuIcons;
