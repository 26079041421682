import React, { useEffect } from 'react';

import { FloaterReact } from '../../../components';
import { Icons } from '../../../assets';

import * as s from './styled-validar-forca-senha';

const ValidarForcaSenha = ({ senha, strength, setStrength, children }) => {
	const regra = {
		letraMinuscula: senha.match(/[a-z]+/),
		letraMaiuscula: senha.match(/[A-Z]+/),
		numero: senha.match(/\d+/),
		especial: senha.match(/\W+/),
	}
	function forcaSenha() {
		let forca = 0;

		if (regra.letraMinuscula) forca += 10;
		if (regra.numero) forca += 20;
		if (regra.letraMaiuscula) forca += 20;
		if (senha.length > 7) forca += 25;
		if (regra.especial) forca += 25;

		setStrength(forca);
	}

	const mensagemProgresso = () => {
		let msg;

		if (strength <= 20) msg = 'Muito fraca';
		else if (strength > 20 && strength < 36) msg = 'Fraca';
		else if (strength >= 36 && strength < 56) msg = 'Média';
		else if (strength >= 56 && strength < 91) msg = 'Boa';
		else msg = 'Excelente';

		return msg;
	};

	useEffect(() => {
		forcaSenha();
	}, [senha]);

	const content = () => (
		<s.Container>
			
			<h2>Sua senha deve conter:</h2>
			<s.P success={senha.length > 7}>
				<Icons.Checked /> Pelo menos 8 caracteres
			</s.P>
			
			<s.P success={regra.letraMinuscula}>
				<Icons.Checked /> Ao menos uma letra minúscula
			</s.P>

			<s.P success={regra.letraMaiuscula}>
				<Icons.Checked /> Ao menos uma letra maiúscula
			</s.P>

			<s.P success={regra.numero}>
				<Icons.Checked /> Ao menos um número
			</s.P>

			<s.P success={regra.especial}>
				<Icons.Checked /> Ao menos um caractere especial
			</s.P>

				<s.ColumnBar>
					<span>Força:</span>
					<s.Bar progress={strength} />
					<s.Span progress={strength}>{mensagemProgresso()}</s.Span>
				</s.ColumnBar>

		</s.Container>
	);

	return (
		<FloaterReact content={content()} placement="bottom" hover>
			<>{children}</>
		</FloaterReact>
	);
};

export default ValidarForcaSenha;