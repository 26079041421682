import React from 'react';

const Copy = ({ stroke, fill }) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill={fill || 'none'}
		xmlns="http://www.w3.org/2000/svg"
		stroke={stroke || '#FAFAFA'}
	>
		<path
			d="M5.00004 12.1429H4.28575C3.90687 12.1429 3.54351 11.9924 3.2756 11.7245C3.00769 11.4566 2.85718 11.0932 2.85718 10.7143V4.28575C2.85718 3.90687 3.00769 3.54351 3.2756 3.2756C3.54351 3.00769 3.90687 2.85718 4.28575 2.85718H10.7143C11.0932 2.85718 11.4566 3.00769 11.7245 3.2756C11.9924 3.54351 12.1429 3.90687 12.1429 4.28575V5.00004M9.28575 7.85718H15.7143C16.5033 7.85718 17.1429 8.49677 17.1429 9.28575V15.7143C17.1429 16.5033 16.5033 17.1429 15.7143 17.1429H9.28575C8.49677 17.1429 7.85718 16.5033 7.85718 15.7143V9.28575C7.85718 8.49677 8.49677 7.85718 9.28575 7.85718Z"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default Copy;
