import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { Icons } from '../../assets';
import { CustomInput, ButtonCustom, ValidarForcaSenha } from '../../components';
import { passwordReset, loginWithoutAuthPersist } from '../../services';

import * as s from './styled-new-password';

const NewPassword = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [invalid, setInvalid] = useState(false);
	const [message, setMessage] = useState('');
	const [passwordStrength, setPasswordStrength] = useState();

	const urlParams = new URLSearchParams(window.location.search);
	const continueUrl = urlParams.get('continueUrl');
	const email = continueUrl.slice(
		continueUrl.indexOf('email') + 6,
		continueUrl.length
	);
	const code = urlParams.get('oobCode');

	function mostrarErro(e) {
		if (e?.code === 'auth/invalid-action-code')
			return 'Código de solicitação inválido';
		if (e?.code === 'auth/expired-action-code') return 'invalid';
		return 'Oops... Algo deu errado. Tente novamente.';
	}

	function resetarSenha(event) {
		event.preventDefault();
		setLoading(true);

		passwordReset(code, password)
			.then(() => {
				toast.success('Senha alterada com sucesso!');
				const toastId = toast.loading('Redirecionando...');

				loginWithoutAuthPersist(email, password)
					.then(() => history.push('/resumo'))
					.catch(() => setMessage('Erro ao tentar login'))
					.finally(() => toast.dismiss(toastId));
			})
			.catch((e) => setMessage(mostrarErro(e)))
			.finally(() => setLoading(false));
	}

	const invalidPasswords = () => {
		if (
			invalid ||
			!password ||
			!confirmPassword ||
			password !== confirmPassword ||
			!password.trim() ||
			!confirmPassword.trim() ||
			passwordStrength < 91 ||
			password.length < 8 ||
			confirmPassword.length < 8 ||
			password.length !== confirmPassword.length
		)
			return true;
		return false;
	};

	useEffect(() => {
		document.title = 'Redefinir senha - Dashboard PayOS';
		return () => (document.title = 'Dashboard PayOS');
	}, []);

	useEffect(() => {
		if (password !== confirmPassword) {
			setInvalid(true);
		} else setInvalid(false);
	}, [confirmPassword]);

	return (
		<s.Container>
			<s.Card>
				<Icons.Logotipo title="PayOS" />
				<s.Form onSubmit={resetarSenha}>
					<s.FormGroup>
						<h1>CADASTRAR NOVA SENHA</h1>

						<s.TitleDiv>
							<s.FormLabel type="password" required>
								Criar nova senha
							</s.FormLabel>
						</s.TitleDiv>
						<div id="validar-senha">
							<ValidarForcaSenha
								senha={password}
								strength={passwordStrength}
								setStrength={setPasswordStrength}
							>
								<CustomInput
									id="password"
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value.trim())}
									required
									autoComplete="rutjfkde"
									placeholder="Digite aqui sua nova senha"
								/>
							</ValidarForcaSenha>
						</div>
						<s.TitleDiv>
							<s.FormLabel type="password" required>
								Confirmar nova senha
							</s.FormLabel>
							{invalid && <s.Error>As senhas devem ser iguais*</s.Error>}
						</s.TitleDiv>
						<div id="validar-senha">
							<CustomInput
								id="confirm-password"
								type="password"
								value={confirmPassword}
								setPassword={setConfirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value.trim())}
								required
								autoComplete="rutjfkde"
								placeholder="Digite novamente sua senha"
								isInvalid={invalid}
							/>
						</div>
					</s.FormGroup>

					<ButtonCustom
						id="criar-senha-enviar"
						type="submit"
						isLoading={loading}
						disabled={invalidPasswords()}
					>
						CADASTRAR
					</ButtonCustom>

					{message && (
						<s.Span>
							<Icons.Attention />
							{message === 'invalid' ? (
								<>
									Seu link expirou.
									<a href="/esqueci-senha">Clique aqui</a>
									para reenviar seu e-mail para Recuperação de Senha
								</>
							) : (
								message
							)}
						</s.Span>
					)}
				</s.Form>
			</s.Card>
		</s.Container>
	);
};
export default NewPassword;
