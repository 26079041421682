import styled from 'styled-components';
import { colors } from '../../assets';
import { images } from '../../assets/images';

export const Container = styled.div`
	min-height: 100vh;
	width: 100vw;
	background-image: url(${images.background});
	backdrop-filter: blur(100px);
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
`;

export const Card = styled.div`
	padding: 5rem;
	border: 2px solid rgba(43, 66, 70, 0.5);
	display: flex;
	position: relative;
	border-radius: 15px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: radial-gradient(
		90.95% 100% at 66.86% 0%,
		rgba(46, 50, 51, 0.4) 0%,
		rgba(25, 25, 25, 0.4) 100%
	);
	backdrop-filter: blur(100px);

	svg.logotipo {
		width: 12.5rem;
		height: 4.06rem;
		margin-bottom: 2.5rem;
	}

	a {
		color: ${colors.primaryBlue};
		font-size: 1.13rem;
		font-weight: 400;
		text-align: center;
		margin-top: 0.9375rem;
	}
`;

export const Form = styled.form`
	width: 28.13rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	button.button-custom {
		width: 100%;
		height: 3.13rem;
		font-size: 1.5rem;
		margin-top: 2rem;
	}
`;

export const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 2.38rem;
	align-items: center;

	h1 {
		font-size: 1.25rem;
		color: ${colors.white};
		text-transform: uppercase;
		font-weight: 600;
		text-align: center;
		margin-bottom: 1.25rem;
		margin-top: 0.625rem;
	}

	p {
		font-size: 1rem;
		color: ${colors.lightGray};
		font-weight: 400;
		text-align: center;

		span {
			font-weight: 600;
		}
	}
`;

export const TitleDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: space-between;
	align-self: flex-start;
`;

export const Error = styled.p`
	color: ${colors.primaryRed} !important;
	font-size: 1rem;
	line-height: 27px;
`;

export const FormLabel = styled.label`
	display: flex;
	align-items: center;
	line-height: 1.63rem;
	font-size: 1.25rem;
	font-weight: 600;
	color: ${colors.offWhite};
	margin: 1.88rem 0 0.625rem 0;

	svg.info-icon {
		width: 1.5rem;
		height: 1.5rem;
		margin: 0 0 1px 5px;
	}
	.info-button {
		margin: 0;
		padding: 0;
	}
`;

export const Span = styled.span`
	max-width: 28.13rem;
	font-size: 1.25rem;
	margin-top: 1.88rem;
	text-align: center;
	color: ${colors.primaryRed};

	svg.attention-icon {
		width: 1.13rem;
		height: 1.25rem;
		margin-right: 0.625rem;
	}
`;

export const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
	height: 100%;
	width: 100vw;
	transition: ease-in-out;
`;
