import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const Container = styled.div`
	grid-area: ${(props) => (props.div ? props.div : 'none')};
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: 18px 27px 19px 33px;
	justify-content: space-between;
	background-color: ${colors.darkGray};
	min-height: ${(props) => (props.minHeight ? props.minHeight : '95px')};
	max-height: ${(props) => (props.maxHeight ? props.maxHeight : 'none')};
	/* 	box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.07); */
	border: ${({ isInvalid }) =>
		isInvalid ? `1px solid ${colors.primaryRed}` : 'none'};
	border-radius: ${(props) =>
		props.borderRadius ? props.borderRadius : '15px'};
	border-color: ${({ isInvalid, borderColor }) =>
		isInvalid ? colors.primaryRed : borderColor};
`;
export const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: space-between;
`;

export const Title = styled.label`
	flex: 1;
	padding-bottom: 5px;
	font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
	text-align: ${({ titleTextAlign }) => titleTextAlign || 'left'};
	color: ${colors.white};
`;

export const Infos = styled.div`
	display: flex;
	flex: 2;
	padding: 0 24px 24px 24px;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	h2 {
		color: #454550;
		font-weight: 600;
		margin: 0;
	}

	div {
		display: flex;
		align-items: center;
	}
`;

export const Warning = styled.span`
	color: ${colors.primaryRed};
`;
