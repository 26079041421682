import React from 'react';
import * as s from './styled-checkbox-custom-payment';

export default function CustomCheckboxPayment({
	id,
	name,
	onChange,
	value,
	disabled,
	margin,
	secondary,
	text,
	fontSize,
	checkboxAlign,
	checked,
}) {
	return (
		<s.Container
			fontSize={fontSize}
			checkboxAlign={checkboxAlign}
			disabled={disabled}
			checked={checked}
			className="checkbox-custom"
		>
			<s.ContainerCheckbox margin={margin || 10}>
				<s.Checkbox
					id={id}
					name={name}
					secondary={secondary}
					disabled={disabled}
					type="checkbox"
					value={value}
					checked={checked}
					onChange={onChange}
				/>
			</s.ContainerCheckbox>
			{text && (
				<label onClick={onChange} htmlFor={id}>
					{text}
				</label>
			)}
		</s.Container>
	);
}
