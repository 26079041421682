import React, { useContext, useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getLocalStorageObjectFormat, formatDateAuth } from '../utils';
import { auth } from '../services';

const AuthContext = React.createContext(); // cria o contexto

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const userLocalStorage = getLocalStorageObjectFormat('user');
	const [user, loading] = useAuthState(auth);
	const [isLoading, setIsLoading] = useState(false);

	const validateFastEntry = () => {
		const dateInFireBase = formatDateAuth(
			user?.auth?.currentUser?.metadata?.lastSignInTime
		);
		const presentHour = new Date().getHours();

		if (userLocalStorage === null) return false;
		if (dateInFireBase - presentHour > 2) return false;
		if (dateInFireBase - presentHour > -2) return false;
		return true;
	};

	const validateAutoEntry = () => {
		const isInvalid = validateFastEntry();
		if (isInvalid) return userLocalStorage?.data;

		return localStorage.clear('user') && {};
	};

	const [currentUser, setCurrentUser] = useState(() => validateAutoEntry());

	const authUser = () => {
		const unsubscribe = auth.onAuthStateChanged(() => {
			if (!currentUser) {
				setIsLoading(false);
			}
		});
		return unsubscribe;
	};

	useEffect(() => {
		if (currentUser) {
			authUser();
		}
	}, []);

	const value = {
		currentUser,
		setCurrentUser,
		loading: isLoading,
		setLoading: setIsLoading,
		loadingToken: loading,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
