import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const Container = styled.div`
	width: 100%;
	padding: 0.5rem;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	color: ${colors.lightGray};
	border-radius: 0.9375rem;
`;

export const Column = styled.div`
	padding-right: 0.75rem;
	display: flex;
	flex-direction: column;
	align-content: flex-end;
`;

export const Row = styled.div`
	display: flex;
	justify-content: center;
	align-content: flex-end;
`;

export const ColumnTitle = styled.div`
	display: flex;
	text-align: left;
	color: ${colors.lightestGray};
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.005em;
	width: 100%;
	margin-bottom: 1.25rem;
	justify-content: flex-start;
	padding-left: 0.625rem;
`;
