import React from 'react';
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	Label,
} from 'recharts';
import { colors } from '../../../../assets/colors';
import { formatarParaReais, formatNumberUnits } from '../../../../utils';

import * as S from './styled-area-chart';

const daysOfWeek = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];
function formatDay(day) {
	return daysOfWeek[day];
}

const renderCustomTooltip = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
			<S.TooltipContainer>
				<S.Description>
					Volume:{' '}
					<S.Value>
						{formatarParaReais(payload[0]?.payload?.amount / 100, true)}
					</S.Value>
				</S.Description>
			</S.TooltipContainer>
		);
	}

	return null;
};

export default function VolumeChart({ data }) {
	return (
		<S.Container>
			<ResponsiveContainer>
				<AreaChart
					data={data}
					margin={{
						top: 10,
						right: 25,
						left: 30,
						bottom: 10,
					}}
				>
					<defs>
						<linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
							<stop
								offset="5%"
								stopColor={colors.primaryBlue}
								stopOpacity={0.9}
							/>
							<stop
								offset="95%"
								stopColor={colors.primaryBlue}
								stopOpacity={0.05}
							/>
						</linearGradient>
					</defs>
					<CartesianGrid
						vertical={false}
						strokeDasharray="2 3"
						stroke="rgba(177, 182, 182, 0.5)"
					/>
					<XAxis
						dataKey="type"
						tick={{ fontSize: '0.875rem', color: colors.white }}
						tickLine={false}
						axisLine={false}
						tickFormatter={(value) => formatDay(value)}
						stroke={colors.white}
						tickMargin={10}
					/>
					<YAxis
						dataKey="amount"
						tickLine={false}
						axisLine={false}
						tick={{ fontSize: '0.875rem', color: colors.white }}
						tickFormatter={(value) => formatNumberUnits(value / 100)}
						stroke={colors.white}
					>
						<Label
							angle={-90}
							position="insideLeft"
							fill={colors.white}
							stroke={colors.white}
							strokeWidth={0.5}
							style={{
								fontSize: '0.675rem',
								color: colors.white,
								textAnchor: 'middle',
							}}
							dx={-10}
						>
							Valor em reais(R$)
						</Label>
					</YAxis>
					<Tooltip
						content={renderCustomTooltip}
						wrapperStyle={{
							color: colors.darkTurquoise,
							backgroundColor: 'white',
							borderRadius: '4px',
							border: '1px solid #A5BFD7',
							padding: '1rem 1.125rem',
							boxShadow: '0px 7px 16px -1px rgba(4, 32, 38, 0.9)',
						}}
					/>
					<Area
						connectNulls
						type="linear"
						dataKey="amount"
						name="Valor Transacionado"
						label="Valor Transacionado"
						stroke={`${colors.primaryBlue}`}
						strokeWidth={2}
						fill="url(#gradient)"
						activeDot={{
							r: 5,
							stroke: colors.primaryBlue,
							strokeWidth: 1.5,
							fill: colors.black,
						}}
					/>
				</AreaChart>
			</ResponsiveContainer>
		</S.Container>
	);
}
