import React, { useContext, useEffect, useState } from 'react';
import { ModalCancelar } from '../components';
import { useAuth } from '../hooks';
import { getRecipientBankAccounts } from '../services';
import { justNumbers } from '../utils';

const RecipientsContext = React.createContext(); // cria o contexto

export function RecipientsProvider({ children }) {
	const [step, setStep] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [isContasRecebedorLoading, setIsContasRecebedorLoading] = useState([]);
	const [isBankOptionsLoading, setIsBankOptionsLoading] = useState(false);
	const [isCancelarCriacaoOpen, setIsCancelarCriacaoOpen] = useState(false);

	const [tipoPessoa, setTipoPessoa] = useState('PF');
	const [documento, setDocumento] = useState('');
	const [isMoreInfoChecked, setIsMoreInfoChecked] = useState(false);
	const [nome, setNome] = useState('');
	const [email, setEmail] = useState('');
	const [telefone, setTelefone] = useState('');
	const [managingPartners, setManagingPartners] = useState([]);
	const [url, setUrl] = useState('');
	const [transferEnabled, setTransferEnabled] = useState(false);
	const [transferInterval, setTransferInterval] = useState('daily');
	const [transferDay, setTransferDay] = useState(0);
	const [automaticAntecipationEnabled, setAutomaticAntecipationEnabled] =
		useState(false);
	const [antecipationVolumePercentage, setAntecipationVolumePercentage] =
		useState(100);
	const [bankAccountId, setBankAccountId] = useState('');
	const [bankCode, setBankCode] = useState('');
	const [agencia, setAgencia] = useState('');
	const [agenciaDv, setAgenciaDv] = useState('');
	const [conta, setConta] = useState('');
	const [contaDv, setContaDv] = useState('');
	const [type, setType] = useState('');
	const [banks, setBanks] = useState({});
	const [companyName, setCompanyName] = useState('');
	const [recipientBankAccounts, setRecipientBankAccounts] = useState([{}]);
	const [opcaoConta, setOpcaoConta] = useState('selecionar-conta');
	const [erros, setErros] = useState({});
	const [newRecipientId, setNewRecipientId] = useState('');
	const [alertConclusao, setAlertConclusao] = useState('erro');
	const [partnersQuantity, setPartnersQuantity] = useState(0);

	const { recipientId } = useAuth().currentUser;

	const definirTipoPessoa = () =>
		tipoPessoa === 'PF' ? 'individual' : 'corporation';

	function removerMascara(string) {
		const stringSemMascara = string.replace(/\D/g, '');
		return stringSemMascara;
	}

	const transferDayNumber = transferDay || 0;

	const registerInformation = {
		type: definirTipoPessoa(),
		document_number: removerMascara(documento),
		email,
		company_name: companyName,
	};

	if (tipoPessoa === 'PF') {
		registerInformation.name = nome;
	}
	if (tipoPessoa === 'PJ') {
		registerInformation.company_name = nome;
	}
	if (telefone && telefone.length > 10 && !erros.telefone) {
		registerInformation.phone_numbers = [
			{
				ddd: telefone.substring(1, 3),
				number: removerMascara(telefone.substring(3)),
				type: 'mobile',
			},
		];
	}

	if (url && !erros.url) {
		registerInformation.url = url;
	}

	if (managingPartners.length > 0 && !erros.managingPartners) {
		registerInformation.managing_partners = managingPartners;
	}

	const tempArray = managingPartners?.map((partner) => {
		return { ...partner, type: 'individual' };
	});

	registerInformation.managing_partners = tempArray;

	const bankAccount = {
		bankCode: bankCode.value,
		agencia,
		documentNumber: removerMascara(documento),
		agenciaDv,
		conta,
		type: type.value,
		contaDv,
	};

	const body = {
		recipientId,
		registerInformation,
		transferEnabled,
		transferInterval,
		transferDay: transferDayNumber,
		automaticAntecipationEnabled,
		antecipationVolumePercentage,
		bankAccountId: bankAccountId.value,
		bankAccount,
		companyName,
	};

	const value = {
		body,
		isLoading,
		setIsLoading,
		step,
		setStep,
		registerInformation,
		tipoPessoa,
		setTipoPessoa,
		nome,
		setNome,
		email,
		setEmail,
		documento,
		setDocumento,
		isMoreInfoChecked,
		setIsMoreInfoChecked,
		telefone,
		setTelefone,
		managingPartners,
		setManagingPartners,
		url,
		setUrl,
		transferEnabled,
		setTransferEnabled,
		transferInterval,
		setTransferInterval,
		transferDay,
		setTransferDay,
		automaticAntecipationEnabled,
		setAutomaticAntecipationEnabled,
		antecipationVolumePercentage,
		setAntecipationVolumePercentage,
		bankAccountId,
		setBankAccountId,
		bankCode,
		setBankCode,
		agencia,
		setAgencia,
		agenciaDv,
		setAgenciaDv,
		conta,
		setConta,
		contaDv,
		setContaDv,
		type,
		setType,
		companyName,
		setCompanyName,
		banks,
		setBanks,
		erros,
		setErros,
		recipientBankAccounts,
		setRecipientBankAccounts,
		opcaoConta,
		setOpcaoConta,
		isContasRecebedorLoading,
		setIsContasRecebedorLoading,
		isBankOptionsLoading,
		setIsBankOptionsLoading,
		setIsCancelarCriacaoOpen,
		newRecipientId,
		setNewRecipientId,
		alertConclusao,
		setAlertConclusao,
		partnersQuantity,
		setPartnersQuantity,
	};

	useEffect(() => {
		const existemContasRecebedor = recipientBankAccounts.length > 0;
		if (existemContasRecebedor) {
			setOpcaoConta('selecionar-conta');
		} else {
			setOpcaoConta('adicionar-conta');
		}
	}, [recipientBankAccounts]);

	useEffect(() => {
		if (bankAccountId && bankAccountId.value) {
			setBankCode(bankAccountId.bankCode);
			setAgencia(bankAccountId.agencia);
			setAgenciaDv(bankAccountId?.agenciaDv);
			setConta(bankAccountId.conta);
			setContaDv(bankAccountId.contaDv);
			setCompanyName(bankAccountId.legalName);
		}
	}, [bankAccountId]);

	useEffect(() => {
		if (documento && !erros.documento) {
			setBankAccountId('');
			setIsContasRecebedorLoading(true);
			getRecipientBankAccounts(1, justNumbers(documento), recipientId)
				.then((response) => {
					const bankAccountsValues = [];
					response.forEach((account) => {
						const bankName = banks?.find(
							(bank) => bank.value === account.bankCode
						);
						bankAccountsValues.push({
							label: `${account.legalName} - ${bankName.label} - ${
								account.agencia
							}${account.agenciaDv ? `-${account.agenciaDv}` : ''} - ${
								account.conta
							}-${account.contaDv}`,
							value: account.id,
							bankCode: account.bankCode,
							agencia: account.agencia,
							agenciaDv: account?.agenciaDv,
							conta: account.conta,
							contaDv: account.contaDv,
						});
					});
					setRecipientBankAccounts(bankAccountsValues);
				})
				.catch(() => {
					setRecipientBankAccounts([]);
				})
				.finally(() => {
					setIsContasRecebedorLoading(false);
				});
		}
	}, [documento]);

	return (
		<RecipientsContext.Provider value={value}>
			{children}
			<ModalCancelar
				isOpen={isCancelarCriacaoOpen}
				closeModal={() => setIsCancelarCriacaoOpen(false)}
			/>
		</RecipientsContext.Provider>
	);
}

export function useRecipients() {
	return useContext(RecipientsContext);
}
