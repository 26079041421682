import React from 'react';
// import { colors } from '../colors';

const CriarPayment = () => (
	// { title, fill, style }
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.9436 14.9336C12.373 15.5077 12.9209 15.9827 13.5501 16.3265C14.1793 16.6703 14.8751 16.8747 15.5902 16.9259C16.3054 16.9771 17.0231 16.8739 17.6949 16.6233C18.3667 16.3728 18.9767 15.9806 19.4836 15.4736L22.4836 12.4736C23.3944 11.5305 23.8983 10.2675 23.8869 8.95655C23.8755 7.64557 23.3497 6.39151 22.4227 5.46447C21.4956 4.53743 20.2415 4.01158 18.9306 4.00019C17.6196 3.9888 16.3566 4.49277 15.4136 5.40356L13.6936 7.11356M15.9436 12.9336C15.5141 12.3594 14.9662 11.8844 14.337 11.5406C13.7078 11.1969 13.0121 10.9924 12.2969 10.9412C11.5818 10.89 10.864 10.9932 10.1922 11.2438C9.52045 11.4944 8.91044 11.8865 8.40356 12.3936L5.40356 15.3936C4.49277 16.3366 3.9888 17.5996 4.00019 18.9106C4.01158 20.2216 4.53743 21.4756 5.46447 22.4027C6.39151 23.3297 7.64557 23.8555 8.95655 23.8669C10.2675 23.8783 11.5305 23.3744 12.4736 22.4636L14.1836 20.7536"
			stroke="#1A1A1A"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default CriarPayment;
