import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import toast from 'react-hot-toast';
import {
	ButtonCustom,
	CustomFlexDiv,
	SelectReact,
	CheckboxCustom,
	Modal,
	UnderlineInput,
	Loader,
} from '../../components';
import {
	getUserInfos,
	getRoleUser,
	registerUser,
	getContractors,
	updateUser,
} from '../../services';
import { justNumbers, mascaraTelefone, validateEmail } from '../../utils';
import { ReactComponent as LeftArrow } from '../../assets/icons/LeftArrow.svg';
import { ReactComponent as Pencil } from '../../assets/icons/EditPen.svg';
import { colors } from '../../assets/colors';

import * as S from './styled-access';

const Access = () => {
	const history = useHistory();
	const { userId } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [isInvalid, setIsInvalid] = useState({
		name: '',
		phone: '',
		email: '',
	});

	const [userName, setUserName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');

	const [roleId, setRoleId] = useState({});
	const [contractorId, setContractorId] = useState({});
	const [active, setActive] = useState(false);
	const [activatePhone, setActivatePhone] = useState(false);
	const [activateName, setActivateName] = useState(false);

	// Select options
	const [optionsContractor, setOptionsContractor] = useState([]);
	const [optionsRoleType, setOptionsRoleType] = useState([]);

	const [sendEmail, setSendEmail] = useState(false);
	const [readOnly, setReadOnly] = useState(userId !== undefined);

	const [isOpen, setIsOpen] = useState(false);
	const [titleModal, setTitleModal] = useState('');

	function haveEmptyFields() {
		if (
			!userName ||
			!phone ||
			!email ||
			roleId === '' ||
			!contractorId === ''
		) {
			return true;
		}
		return false;
	}

	const validaEmail = (e) => {
		e.target.value = e.target.value.toLowerCase().trim();
		setEmail(e.target.value);
		if (e.target.value.length) {
			if (!validateEmail(e.target.value)) {
				setIsInvalid({ ...isInvalid, email: '' });
			} else {
				setIsInvalid({ ...isInvalid, email: 'erro' });
			}
		}
	};

	const validaPhone = (e) => {
		const documentoExiste = e.target.value.length;
		const doc = e.target.value;
		setPhone(doc);
		if (activatePhone) {
			if (documentoExiste !== 15) {
				setIsInvalid({ ...isInvalid, phone: '' });
			} else {
				setIsInvalid({ ...isInvalid, phone: 'erro' });
			}
		}
		if (documentoExiste === 15) {
			e.target.value = doc;
			setActivatePhone(true);
			setIsInvalid({ ...isInvalid, phone: '' });
		}
	};

	const validaName = (e) => {
		const documentoExiste = e.target.value.length;
		const doc = e.target.value;
		setUserName(doc);
		if (activateName) {
			if (documentoExiste === 0) {
				setIsInvalid({ ...isInvalid, name: '' });
			} else {
				setIsInvalid({ ...isInvalid, name: 'erro' });
			}
		}
		if (documentoExiste !== 0) {
			e.target.value = doc;
			setActivateName(true);
			setIsInvalid({ ...isInvalid, name: '' });
		}
	};

	useEffect(() => {
		if (userId !== undefined) {
			getUserInfos(userId)
				.then((resp) => {
					setUserName(resp.name);
					setPhone(resp.phone);
					setEmail(resp.email);
					setRoleId({
						value: resp.roleId,
						label:
							resp.roleLabel === 'administrator'
								? 'Administrador'
								: 'Contratante',
					});
					setContractorId(resp.companyName);
					setActive(resp.active);
				})
				.catch((e) => toast.error(e));
		}

		getRoleUser()
			.then((resp) => {
				setOptionsRoleType(
					resp.map((item) => {
						if (item.label === 'contractor')
							return { value: item.id, label: 'Contratante' };
						return { value: item.id, label: 'Administrador' };
					})
				);
			})
			.catch(() => toast.error('Erro ao buscar tipos de usuário'));

		getContractors()
			.then((resp) => {
				setOptionsContractor(
					resp.data.map((item) => ({
						value: item.contractorId,
						label: item.companyName,
					}))
				);
			})
			.catch(() => toast.error('Erro ao buscar contratantes'))
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	async function cadastrarEditarUsuario() {
		setIsLoading(true);

		let newUserData = {
			name: userName,
			phone: `+55${justNumbers(phone)}`,
			email,
			roleId: Number(roleId.value),
			contractorId: Number(contractorId.value),
			active,
		};

		if (userId === undefined) {
			registerUser(newUserData, sendEmail)
				.then(() => {
					setTitleModal('Novo cadastro feito com Sucesso.');
					setIsOpen(true);
				})
				.catch(() => {
					toast.error('Erro ao cadastrar Usuário');
					setIsOpen(true);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			newUserData = { ...newUserData, phone: justNumbers(phone) };
			let contId;
			// isLoading.current = true;
			// o Back retorna somente o 'Nome de Contratante' , portanto preciso percorrer as opções do
			// select e encontrar o Id correspondende ao nome de contratante
			// Porem, se eu selecionar outra opção do select, retorna um objeto, então preciso da segunda validação (else), para pegar o valor de contid.value
			if (typeof contractorId === 'string') {
				contId = optionsContractor.find((opt) => opt.label === contractorId);
			} else contId = contractorId;

			newUserData.contractorId = contId?.value;

			updateUser(userId, newUserData, sendEmail)
				.then(() => {
					toast.success('Contratante editado com sucesso!');
					history.push('/acessos');
				})
				.catch(() => toast.error('Erro ao atualizar usuário'))
				.finally(() => setIsLoading(false));
		}
	}

	return isLoading ? (
		<S.CenterLoader>
			<Loader color={colors.primaryBlue} />
		</S.CenterLoader>
	) : (
		<S.Container>
			<S.Header>
				<ButtonCustom
					startIcon={<LeftArrow color={colors.primaryBlue} />}
					outlined
					onClick={() => history.push(`/acessos`)}
				>
					VOLTAR
				</ButtonCustom>
				{userId !== undefined && !readOnly && (
					<ButtonCustom
						theme="gray"
						outlined
						onClick={() => setReadOnly(!readOnly)}
					>
						CANCELAR
					</ButtonCustom>
				)}
				{userId !== undefined && readOnly ? (
					<ButtonCustom
						startIcon={
							<Pencil width="22px" height="22px" color={colors.darkGray} />
						}
						onClick={() => setReadOnly(!readOnly)}
						textColor={colors.darkGray}
						background={colors.degradeeTurquoise}
					>
						EDITAR
					</ButtonCustom>
				) : null}
			</S.Header>
			<S.Content>
				<S.Row>
					<CustomFlexDiv
						isInvalid={isInvalid.name}
						tittleError
						title="Nome do usuário *"
						minWidth="315px"
					>
						<UnderlineInput
							placeholder="Digite Aqui.."
							value={userName}
							name="userName"
							onChange={(e) => validaName(e)}
							isInvalid={isInvalid.name !== ''}
							erro={
								{ ...(isInvalid.name === 'erro') } && 'Nome do Usuário Inválido'
							}
							maxLength={50}
							width="35rem"
							readOnly={readOnly || isLoading}
						/>
					</CustomFlexDiv>
				</S.Row>

				<S.Row>
					<CustomFlexDiv
						tittleError
						isInvalid={isInvalid.phone}
						width="20%"
						minWidth="285px"
						maxwidth="285px"
						title="Telefone *"
					>
						<UnderlineInput
							placeholder="00 90000-0000"
							value={mascaraTelefone(phone)}
							name="phone"
							onChange={(e) => validaPhone(e)}
							erro={{ ...(isInvalid.phone === 'erro') } && 'Telefone Inválido'}
							isInvalid={isInvalid.phone !== ''}
							maxLength={15}
							readOnly={readOnly || isLoading}
						/>
					</CustomFlexDiv>
					<CustomFlexDiv
						tittleError
						title="E-mail *"
						isInvalid={isInvalid.email}
						width="80%"
						minWidth="220px"
					>
						<UnderlineInput
							placeholder="nomeresponsavel@empresa.com.br"
							value={email}
							name="email"
							width="315px"
							onChange={(e) => validaEmail(e)}
							isInvalid={isInvalid.email !== ''}
							maxLength={50}
							erro={{ ...(isInvalid.email === 'erro') } && 'Email Invalido'}
							readOnly={readOnly || isLoading}
						/>
					</CustomFlexDiv>
				</S.Row>

				<S.RowSelect>
					<CustomFlexDiv
						width="15%"
						minWidth="285px"
						maxwidth="285px"
						maxHeight="95px"
						title="Tipo de Usuário *"
					>
						<SelectReact
							value={roleId}
							onChange={(opt) => setRoleId(opt)}
							options={optionsRoleType}
							readOnly={readOnly || isLoading}
							className="custom-select-input-type"
							id="tipo-account"
						/>
					</CustomFlexDiv>

					<CustomFlexDiv
						title="Nome de Contratante *"
						maxHeight="95px"
						width="20%"
						minWidth="285px"
						maxwidth="285px"
						isInvalid={isInvalid.contactorName}
					>
						<SelectReact
							width="5.5rem"
							value={contractorId}
							onChange={(opt) => setContractorId(opt)}
							options={optionsContractor}
							readOnly={readOnly || isLoading}
							className="custom-select-input"
							id="tipo-account"
						/>
					</CustomFlexDiv>
				</S.RowSelect>
			</S.Content>
			<S.Row>
				<CustomFlexDiv
					justifyContent="center"
					isInvalid={isInvalid.contactorName}
					width="350px"
					maxHeight="95px"
				>
					<CheckboxCustom
						id="invite-email"
						disabled={readOnly || isLoading}
						checked={sendEmail}
						onChange={() => setSendEmail(!sendEmail)}
						text="Enviar convite via e-mail"
					/>
				</CustomFlexDiv>

				{!readOnly && (
					<S.SaveButtonDiv>
						<ButtonCustom
							width="33.13rem"
							onClick={cadastrarEditarUsuario}
							disabled={haveEmptyFields() || isLoading}
							isLoading={isLoading}
						>
							SALVAR
						</ButtonCustom>
					</S.SaveButtonDiv>
				)}
				{isOpen && (
					<Modal
						open={isOpen}
						onClose={() => setIsOpen(false)}
						title={titleModal}
						textButton1="ACESSOS"
						onClickButton1="/acessos"
						textButton2="Adicionar Novo"
						onClickButton2="/acessos/novo"
						buttonType1="secondary"
						buttonType2="primary"
					/>
				)}
			</S.Row>
		</S.Container>
	);
};

export default Access;
