import styled from 'styled-components';
import { colors } from '../../../assets';

export const LabelCustom = styled.div``;

export const InputCustom = styled.input`
	width: 100%;
	height: 32px;
	top: 0;
	color: ${colors.lightGray};
	background-color: rgba(0, 0, 0, 0);
	border: 0px;
	border-radius: 5px;
	outline: none;
	font-weight: normal;
	font-style: normal;
	font-size: 1.38rem;
	line-height: 32px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&::placeholder {
		color: ${colors.white};
		opacity: 0.7;
		margin-right: 7px;
	}
`;

export const SpanCustom = styled.span`
	color: ${colors.primaryRed};
	white-space: nowrap;
	word-break: keep-all;
	padding-right: 7px;
	font-size: 1rem;
	height: 18px;
`;

export const TextFieldCustom = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	${InputCustom} {
		border: 0px;
		border-radius: 5px;
		outline: none;
		/* padding: 20px 20px; */
		font-weight: 600;
		font-size: 22px;
		line-height: 32px;
		transition: all 0.1s linear;
		-webkit-transition: all 0.1s linear;
		-moz-transition: all 0.1s linear;
		-webkit-appearance: none;
	}
	${LabelCustom} {
		color: ${colors.white} !important;
		width: 100%;
		pointer-events: none;
		position: absolute;
		top: 0;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 21px;
		left: 39px;
		transition: all 0.1s linear;
		-webkit-transition: all 0.1s linear;
		-moz-transition: all 0.1s linear;
		background-color: rgba(0, 0, 0, 0);
		padding: 0px;
		box-sizing: border-box;
	}
`;
