export const colors = {
	primaryBlue: '#63D2ED',
	darkTurquoise: '#3090A8',
	degradeeTurquoise: 'linear-gradient(135deg, #63D2ED 0%, #3090A8 100%)',
	lightTurquoise: '#E2F9FF',
	primaryRed: 'hsla(0, 79%, 66%, 1)',
	error: '#E52734',
	red: '#ED6363',
	white: '#fff',
	black: '#000',
	lightBlack: '#262929',
	gridBlack: '#2E3233',
	offWhite: '#FDFDFD',
	lightestGray: '#EFF5F5',
	lightGray: '#BDC2C2',
	gray: '#585D5D',
	darkGray: '#2E3333',
	secondaryDarkGray: '#262929',
	background: '#191919',
	transparent: 'rgba(0,0,0,0)',
	green: '#63ED79',
	loading: 'rgba(88, 93, 93, 0.5)',
	borderTable: '#484d4e',
	rowTable: '#232626',
	modalOverlay: 'rgb(100, 209, 237, 0.3)',
	yellow: '#DFEF22',
	orange: '#EF9D22',
	violet: '#B963ED',
	pink: '#FF6BFF',
	cyan: '#00FFFF',
	graphs: {
		red: '#FF1515',
		green: '#22EF22',
		yellow: '#FFF200',
		cyan: '#00FFFF',
		pink: '#FF6BFF',
		gray: '#868992',
		orange: '#FF960D',
		purple: '#852EFF',
		blue: '#1B84FF',
		white: '#FFFFFF',
	},
	graphsRgba: {
		red: 'rgba(237, 99, 99, 0.1)',
		green: 'rgba(34, 239, 34, 0.1)',
		yellow: 'rgba(255, 242, 0, 0.1)',
		cyan: 'rgba(0, 255, 255, 0.1)',
		pink: 'rgba(255, 107, 255, 0.05)',
		gray: 'rgba(134, 137, 146, 0.1)',
		orange: 'rgba(255, 150, 13, 0.1)',
		purple: 'rgba(133, 46, 255, 0.1)',
		blue: 'rgba(27, 132, 255, 0.05)',
		white: 'rgba(239, 245, 245, 0.1)',
	},
};
