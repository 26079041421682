import React, { createContext, useContext, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import {
	getBanks,
	getBankAccountTypes,
	getRecipientDetails,
} from '../services';
import { capitalizeFirstLetterSpace } from '../utils';
import { useAuth } from '.';

const ExtractContext = createContext();

export function ExtractProvider({ children }) {
	const { currentUser } = useAuth();
	const idRecebedor = currentUser?.recipientId;
	const [bancos, setBancos] = useState([]);
	const [tiposConta, setTiposConta] = useState([]);
	const [informacoesRecebedor, setInformacoesRecebedor] = useState({});
	const [loadingRecebedor, setLoadingRecebedor] = useState(true);
	const [loadingFeeMdr, setLoadingFeeMdr] = useState(true);
	const [feeMdr, setFeeMdr] = useState([]);

	useEffect(() => {
		const abortController = new AbortController();
		const { signal } = abortController;

		getRecipientDetails(idRecebedor, signal)
			.then((resp) => setInformacoesRecebedor(resp.bankAccount))
			.catch(() => toast.error('Erro ao buscar os dados do recebedor'))
			.finally(() => setLoadingRecebedor(false));

		getBanks(signal)
			.then((resp) => {
				setBancos(resp.map((item) => ({ value: item.code, label: item.name })));
			})
			.catch(() => toast.error('Erro ao buscar bancos'));

		getBankAccountTypes(signal)
			.then((resp) => {
				setTiposConta(
					resp.map((item) => ({
						value: item,
						label: capitalizeFirstLetterSpace(item),
					}))
				);
			})
			.catch(() => toast.error('Erro ao buscar tipos de conta'));

		return () => {
			abortController.abort();
		};
	}, []);

	const value = {
		bancos,
		tiposConta,
		informacoesRecebedor,
		loadingRecebedor,
		idRecebedor,
		feeMdr,
		setFeeMdr,
		loadingFeeMdr,
		setLoadingFeeMdr,
	};

	return (
		<ExtractContext.Provider value={value}>{children}</ExtractContext.Provider>
	);
}

export function useExtrato() {
	return useContext(ExtractContext);
}
