/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { colors, Icons } from '../../../assets';
import { FloaterReact, Calendario } from '../../../components';

import * as s from './styled-calendar-without-placeholder';

const CalendarWithoutPlaceholder = ({
	formatedRangeDate,
	selectedDayRange,
	setSelectedDayRange,
	filterSelected,
	minimumDate,
	maximumDate,
	dataInvalida,
	placement,
	transform,
	disabled,
	selectedFilter,
	setSelectedFilter,
}) => {
	const [isOpenCalendar, setIsOpenCalendar] = useState(false);

	useEffect(() => {
		if (isOpenCalendar && !dataInvalida.final) {
			setSelectedFilter('custom');
		}
	}, [selectedDayRange]);
	function totalDiasSelecionados() {
		const { from, to } = formatedRangeDate() || { from: '_', to: '_' };

		let dias = 0;

		if (from?.includes('_') || to?.includes('_'))
			return `Nenhum dia selecionado`;

		if (['hoje', 'dia'].includes(filterSelected)) dias = 1;
		else {
			let inicio = from.split('/');
			inicio = `${inicio[2]}-${inicio[1]}-${inicio[0]}`;
			let final = to?.split('/');
			if (final) final = `${final[2]}-${final[1]}-${final[0]}`;

			const start = moment(inicio, 'YYYY-MM-DD');
			const end = moment(final, 'YYYY-MM-DD');

			dias = moment.duration(end.diff(start)).asDays() + 1;
		}

		if (dias === 1) return `1 dia selecionado`;
		return `${Number.isNaN(dias) ? 0 : dias.toFixed(0)} dias selecionados`;
	}

	return (
		<s.Container
			height="2rem"
			width="4rem"
			outlined={selectedFilter !== 'custom'}
			disabled={disabled}
			onClick={() => !isOpenCalendar && setIsOpenCalendar(!isOpenCalendar)}
		>
			<FloaterReact
				id="calendar-without-placeholder"
				isOpen={isOpenCalendar}
				placement={placement}
				transform={transform}
				simbolicIcon
				content={
					<s.Column>
						<Calendario
							selectedDayRange={dataInvalida.inicio ? null : selectedDayRange}
							setSelectedDayRange={setSelectedDayRange}
							minimumDate={minimumDate}
							maximumDate={maximumDate}
							numberOfMonths={2}
						/>
						<p>{totalDiasSelecionados()}</p>
					</s.Column>
				}
			>
				<Icons.Calendar
					style={{
						padding: '0 auto',
						width: '1.25rem',
						height: '1.25rem',
						zIndex: '100',
					}}
					fill={
						selectedFilter === 'custom' ? colors.darkGray : colors.primaryBlue
					}
				/>
			</FloaterReact>
			{isOpenCalendar && (
				<s.Overlay
					onClick={() => {
						setIsOpenCalendar(false);
					}}
				/>
			)}
		</s.Container>
	);
};

export default CalendarWithoutPlaceholder;
