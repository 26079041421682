import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1.5rem;
	width: 100%;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 0.625rem;

	svg.calendar-icon {
		path {
			fill: ${colors.blue};
		}
	}
	> div {
		display: flex;
		width: auto;
	}

	.calendar-input {
		:focus {
			outline: '50px border solid' !important;
		}
	}
	.search-input {
		width: 100%;
	}

	.__floater__container {
		transform: translate(43%);
	}

	.Button-Remove-Filters {
		white-space: nowrap;
		color: ${colors.purple};
		border-color: ${colors.purple};
		background-color: ${colors.white};
	}

	.children-text {
		text-transform: none;
	}
`;
export const ButtonGroup = styled.div`
	display: flex;
	white-space: nowrap;
	justify-content: space-between;
	gap: 1rem;
`;

export const Checkbox = styled.div`
	width: fit-content;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;

	.Ativo {
		color: ${({ checked }) =>
			checked ? colors.primaryBlue : colors.lightGray};
	}
	.Inativo {
		color: ${({ checked }) =>
			checked ? colors.primaryBlue : colors.lightGray};
	}
`;
