import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Alert from '@mui/material/Alert';
import { colors } from '../../../../assets/colors';
import { Identificacao, ConfirmacaoEstorno, Conclusao } from '../';
import { useAuth } from '../../../../contexts';
import { getTransactionDetails } from '../../../../services';

import * as s from './styled-estorno-content';

const EstornoContent = ({
	step,
	setStep,
	alert,
	setAlert,
	alertMessage,
	setAlertMessage,
	loading,
	setLoading,
}) => {
	const [valorEstorno, setValorEstorno] = useState(0);
	const [chosedRecebedor, setChosedRecebedor] = useState();
	const [dados, setDados] = useState({});
	const { transactionId } = useParams();
	const { recipientId } = useAuth().currentUser;
	const [recebedor, setRecebedor] = useState({
		label: 'Manter regras de divisão',
		value: '',
	});
	const [opcoesRecebedor, setOpcoesRecebedor] = useState([
		{ label: 'Manter regras de divisão', value: '' },
	]);
	const chavesRecebedores =
		dados?.splitRules?.data && Object.keys(dados.splitRules.data);
	const recebedores = dados?.splitRules?.data;

	useEffect(() => {
		if (chavesRecebedores?.length) {
			const options = [{ label: 'Manter regras de divisão', value: '' }];

			for (let pos = 0; pos < chavesRecebedores.length; pos++) {
				const opt = recebedores[chavesRecebedores[pos]];
				options.push({ label: opt.name, value: chavesRecebedores[pos] });
			}

			setOpcoesRecebedor(options);
		}
	}, [dados?.splitRules?.data]);

	useEffect(() => {
		setLoading(true);
		getTransactionDetails(transactionId, recipientId)
			.then((resp) => {
				setDados(resp);
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<s.Container>
			{step === 1 && (
				<Identificacao
					setStep={setStep}
					step={step}
					valorEstorno={valorEstorno}
					setValorEstorno={setValorEstorno}
					setChosedRecebedor={setChosedRecebedor}
					dados={dados}
					loading={loading}
					recebedor={recebedor}
					setRecebedor={setRecebedor}
					opcoesRecebedor={opcoesRecebedor}
					setOpcoesRecebedor={setOpcoesRecebedor}
				/>
			)}
			{step === 2 && (
				<ConfirmacaoEstorno
					setStep={setStep}
					step={step}
					valorEstorno={valorEstorno}
					chosedRecebedor={chosedRecebedor}
					dados={dados}
					loading={loading}
					recebedor={recebedor}
					alert={alert}
					setAlert={setAlert}
					alertMessage={alertMessage}
					setAlertMessage={setAlertMessage}
				/>
			)}

			{step === 3 && (
				<>
					{alertMessage === 'success' && (
						<Alert
							severity="error"
							variant="outlined"
							sx={{
								color: `${colors.primaryRed}`,
								borderColor: `${colors.primaryRed}`,
								fontFamily: 'IBM Plex Sans, sans-serif',
								fontSize: '1em',
								fontWeight: '600',
								textAlign: 'center',
								alignItems: 'center',
								marginBottom: '1rem',
							}}
						/>
					)}

					<Conclusao
						setStep={setStep}
						step={step}
						valorEstorno={valorEstorno}
						chosedRecebedor={chosedRecebedor}
						dados={dados}
						loading={loading}
						recebedor={recebedor}
					/>
				</>
			)}
		</s.Container>
	);
};

export default withRouter(EstornoContent);
