import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { colors } from '../../../../../assets/colors';

export const Container = styled.div`
	width: 100%;
	padding: 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	align-content: flex-end;

	color: ${colors.lightGray};
	border-radius: 15px;
`;

export const Row = styled.div`
	display: flex;
	justify-content: center;
	align-content: flex-end;
`;

export const ColumnTitle = styled.div`
	text-align: left;
	color: ${colors.lightestGray};
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.005em;
	width: 10.4375rem;
	margin-bottom: 1.25rem;
`;

export const ContainerInputs = styled.div`
	display: flex;
	align-items: center;
	background: ${({ backgroundColor }) => backgroundColor || colors.white};
	border: 1px solid
		${({ borderGreen, invalid }) =>
			invalid
				? '#df285f'
				: borderGreen
				? colors.primaryBlue
				: colors.primaryBlue};
	border-radius: 0.25rem;
	width: ${({ width }) => width || '17rem'};
	height: 2.7rem;
	padding: 0 0.125rem 0 0.5rem;

	svg.calendar-icon {
		height: 1.35rem;
		width: 1.35rem;
		pointer-events: none;
		path {
			fill: ${({ invalid }) => invalid && '#df285f'};
		}
	}

	input#data-inicio {
		margin-left: 0.3125rem;
		background: ${({ backgroundColor }) => backgroundColor || colors.white};
		color: ${({ backgroundColor }) =>
			backgroundColor ? colors.white : colors.black};
		font-size: ${({ fontSizePlaceholder }) => fontSizePlaceholder || '1rem'};
	}

	input#data-final {
		background: ${({ backgroundColor }) => backgroundColor || colors.white};
		color: ${({ backgroundColor }) =>
			backgroundColor ? colors.white : colors.black};
		font-size: ${({ fontSizePlaceholder }) => fontSizePlaceholder || '1rem'};
	}
`;

export const Overlay = styled.div`
	background-color: rgba(0, 0, 0, 0.05);
	position: fixed;
	inset: 0;
	z-index: 1;
`;

export const InputDays = styled(InputMask)`
	text-align: center;
	font-size: 1.1rem;
	font-family: Jost;
	width: 50%;
	height: 100%;
	background: ${({ invalid }) => invalid && '#fbe5ec'};
	overflow: hidden;

	:focus {
		background: ${({ invalid }) => !invalid && 'rgba(0, 0, 0, 0.05)'};
	}
`;

export const Divisor = styled.div`
	height: 70%;
	width: 1px;
	background: ${({ backgroundColor, invalid }) =>
		backgroundColor ? colors.white : invalid ? '#df285f' : colors.primaryBlue};
	margin: 0 0.5px;
`;

export const ContainerFilters = styled.div`
	display: flex;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	label,
	h2 {
		font-size: 0.875rem;
		font-family: Jost;
		text-transform: uppercase;
	}

	h2 {
		font-weight: 200;
		margin-top: 5px;
	}

	p {
		font-family: Jost;
		font-size: 0.75rem;
		margin: 1.2rem 0 0.625rem 1.2rem;
		font-weight: 200;
		letter-spacing: 1px;
	}
`;

export const Label = styled.label`
	font-weight: 500;
	color: ${({ selected, borderBlue }) =>
		borderBlue
			? selected && colors.primaryBlue
			: selected && colors.primaryBlue};
	cursor: pointer;

	:hover {
		color: ${({ selected, borderBlue }) =>
			borderBlue
				? !selected && colors.primaryBlue
				: !selected && colors.primaryBlue};
	}
`;
