import React from 'react';
import { colors } from '../colors';

const AddPersonIcon = ({ title, fill, style }) => (
	<svg
		width="18"
		height="20"
		viewBox="0 0 18 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={style}
		className="add-person-icon"
	>
		<title>{title || ''}</title>
		<g clipPath="url(#clip0_803_4790)">
			<path
				d="M6.31766 9.47618C8.90827 9.47618 11.0084 7.35486 11.0084 4.73809C11.0084 2.12131 8.90827 0 6.31766 0C3.72705 0 1.62695 2.12131 1.62695 4.73809C1.62695 7.35486 3.72705 9.47618 6.31766 9.47618Z"
				fill={fill || colors.darkGray}
			/>
			<path
				d="M11.3244 18.1574H10.2715C9.64316 18.1574 9.0406 17.9078 8.59632 17.4636C8.15204 17.0193 7.90243 16.4167 7.90243 15.7884C7.90243 15.1601 8.15204 14.5575 8.59632 14.1132C9.0406 13.6689 9.64316 13.4193 10.2715 13.4193H11.3244V12.3664C11.3235 12.0123 11.4046 11.6627 11.5613 11.3451C11.1083 10.6998 10.4304 10.2473 9.6608 10.0764C8.66407 10.7106 7.50712 11.0475 6.3257 11.0475C5.14427 11.0475 3.98736 10.7106 2.99063 10.0764C2.36337 10.2142 1.79301 10.54 1.35565 11.0103C0.918279 11.4806 0.634671 12.073 0.542605 12.7086L0.0477367 16.3938C-0.0122766 16.8399 0.0237295 17.2936 0.153355 17.7247C0.282981 18.1557 0.503232 18.554 0.799342 18.893C1.09545 19.232 1.46058 19.5038 1.87028 19.6902C2.27997 19.8765 2.72478 19.9732 3.17488 19.9737H9.49233C10.1602 19.9734 10.8108 19.7615 11.3507 19.3683C11.3455 19.3069 11.3455 19.2453 11.3507 19.184L11.3244 18.1574Z"
				fill={fill || colors.darkGray}
			/>
			<path
				d="M17.115 14.9987H14.4828V12.3664C14.4828 12.157 14.3996 11.9561 14.2515 11.808C14.1034 11.6599 13.9025 11.5767 13.6931 11.5767C13.4836 11.5767 13.2828 11.6599 13.1347 11.808C12.9866 11.9561 12.9034 12.157 12.9034 12.3664V14.9987H10.2711C10.0617 14.9987 9.86083 15.0819 9.71273 15.23C9.56464 15.3781 9.48145 15.5789 9.48145 15.7884C9.48145 15.9978 9.56464 16.1987 9.71273 16.3467C9.86083 16.4948 10.0617 16.578 10.2711 16.578H12.9034V19.2103C12.9034 19.4197 12.9866 19.6206 13.1347 19.7687C13.2828 19.9168 13.4836 20 13.6931 20C13.9025 20 14.1034 19.9168 14.2515 19.7687C14.3996 19.6206 14.4828 19.4197 14.4828 19.2103V16.578H17.115C17.3245 16.578 17.5253 16.4948 17.6734 16.3467C17.8215 16.1987 17.9047 15.9978 17.9047 15.7884C17.9047 15.5789 17.8215 15.3781 17.6734 15.23C17.5253 15.0819 17.3245 14.9987 17.115 14.9987Z"
				fill={fill || colors.darkGray}
			/>
		</g>
		<defs>
			<clipPath id="clip0_803_4790">
				<rect width="17.9047" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default AddPersonIcon;
