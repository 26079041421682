import React from 'react';

const Pix = ({ title, fill, style }) => (
	<svg
		style={style}
		className="pix-icon"
		width="23"
		height="23"
		viewBox="0 0 23 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || 'Ícone de pix'}</title>
		<path
			d="M8.69305 3.07963L3.07932 8.69335C1.52914 10.2435 1.52914 12.7569 3.07932 14.3071L8.69305 19.9208C10.2433 21.471 12.7566 21.471 14.3068 19.9208L19.9207 14.3071C21.4708 12.7568 21.4708 10.2434 19.9207 8.69335L14.3068 3.07963C12.7566 1.52945 10.2432 1.52945 8.69305 3.07963ZM12.9034 4.48307L18.5173 10.0968C19.2923 10.8719 19.2923 12.1286 18.5172 12.9037L12.9035 18.5175C12.1284 19.2926 10.8718 19.2926 10.0965 18.5175L4.48282 12.9037C3.7077 12.1286 3.7077 10.8719 4.48282 10.0968L10.0965 4.48307C10.8717 3.70795 12.1282 3.70795 12.9035 4.48307H12.9034Z"
			fill={fill || '#B1B6B6'}
		/>
		<path
			d="M2.0723 11.6003C2.0723 14.9349 6.60062 18.0229 9.14461 15.3679L9.69377 14.7715C10.5264 13.8663 10.9395 13.5837 11.4975 13.585C11.9902 13.5862 12.3776 13.8114 13.0372 14.4875L13.7532 15.2562C16.2445 18.1276 20.9277 14.9914 20.9277 11.6004C20.9277 8.26574 16.3995 5.17779 13.8554 7.83276L13.265 8.47185L12.9751 8.77268C12.3392 9.41116 11.9705 9.61563 11.4999 9.61563C10.9931 9.61563 10.5682 9.34871 9.84383 8.58887L9.2475 7.94529C6.75526 5.07336 2.07227 8.20927 2.07227 11.6004L2.0723 11.6003ZM18.943 11.6003C18.943 13.3592 16.444 15.0789 15.3151 14.019L15.0695 13.7569L14.7983 13.4616C13.5931 12.152 12.7987 11.6034 11.5024 11.6003C10.2817 11.5972 9.50909 12.0794 8.42674 13.2204L7.79664 13.9011C6.69299 15.1717 4.05722 13.4067 4.05722 11.6004C4.05722 9.84151 6.55617 8.12199 7.68509 9.18171L8.22513 9.76405C9.40403 11.0385 10.2271 11.6004 11.5001 11.6004C12.757 11.6004 13.5063 11.1115 14.6853 9.85893L15.2266 9.27375C16.3072 8.02905 18.9431 9.79403 18.9431 11.6004L18.943 11.6003Z"
			fill={fill || '#B1B6B6'}
		/>
	</svg>
);
export default Pix;
