import styled from 'styled-components';

import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 2.5rem 1.5rem;

	.checkbox-custom {
		display: flex;
		justify-content: flex-start;
		height: fit-content;
		color: ${({ checked }) =>
			checked ? colors.primaryBlue : colors.lightGray};
	}

	.label-underline-input {
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 1.3125rem;
	}
`;

export const InputGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.625rem;
	margin-bottom: 3.5rem;
`;

export const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 2.5rem;

	.aditional-form {
		display: ${({ checked }) => (checked ? 'flex' : 'none')};
	}
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	justify-flex: flex-start;
	margin-bottom: 3em;

	div {
		display: flex;
	}
`;

export const Title = styled.h2`
	font-family: Jost;
	font-style: normal;
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 2rem;
	letter-spacing: 0.04em;
	color: ${colors.offWhite};
	justify-content: flex-start;
	text-align: left;
	margin-bottom: 0.5rem;
	margin-right: 2rem;
`;

export const Subtitle = styled.h3`
	color: ${colors.lightGray};
	font-size: 1.25rem;
	font-weight: 400;
	margin-bottom: 0.5rem;
`;

export const TitleInput = styled.h4`
	color: ${colors.offWhite};
	font-size: 1rem;
	font-style: normal;
	font-weight: normal;
	padding-bottom: 1rem;
`;

export const TitleGroup = styled.div`
	display: flex;
	gap: 2rem;
	margin-bottom: 1.5rem;
	h3 {
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 1.3125rem;
		color: ${colors.offWhite};
	}
	.button-custom {
		padding: 1rem 0.5rem;
	}
`;

export const RadioGroup = styled.div`
	display: block;
	margin-bottom: 2rem;
`;

export const Label = styled.label`
	color: ${colors.offWhite};
`;

export const ColumnsGroup = styled.div`
	display: flex;
	gap: 2rem;
	margin-bottom: 4.625rem;
	.wrapper-underline-input:first-of-type {
		margin-bottom: 2rem;
	}
`;

export const LeftColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	.container-custom-input {
		width: 20.5rem;
	}
`;

export const RightColumn = styled.div`
	display: flex;
	flex-direction: column;
	.container-custom-input {
		width: 20.5rem;
	}
`;

export const ButtonGroup = styled.div`
	display: flex;
	margin-top: auto;
	width: 100%;
	button {
		width: 12.5rem;
		height: 3.3125rem;
	}
`;

export const RightButtons = styled.div`
	display: flex;
	gap: 1rem;
	margin-left: auto;
`;

export const Row = styled.div`
	display: flex;
	flex: wrap;
	gap: 3rem;
	margin-bottom: 2.5rem;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 1rem;
`;

export const Span = styled.span`
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 26px;
	color: ${colors.lightGray};
`;

export const Divisoria = styled.div`
	display: flex;
	height: 1px;
	background-color: ${colors.gray};
	margin-bottom: 3.5rem;
	opacity: 0.5;
`;
