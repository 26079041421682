import React from 'react';
import { colors } from '../../../assets';

const RecebedoresIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="recebedores-icon"
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M26.969 27.6217L16.5428 31.7577C15.7579 32.0255 14.9178 32.0705 14.1103 31.8878L7.27411 30.327C6.72314 30.2015 6.16071 30.1375 5.59652 30.1362H2.66074C2.21582 30.1362 1.78914 29.9536 1.47453 29.6283C1.15992 29.3031 0.983154 28.862 0.983154 28.402V21.5606C0.983154 21.1007 1.15992 20.6596 1.47453 20.3344C1.78914 20.0092 2.21582 19.8264 2.66074 19.8264L5.77269 19.4016C6.00706 19.3687 6.23945 19.3224 6.46885 19.2628C7.11472 19.0808 8.49875 18.6905 9.11107 18.5345C10.4316 18.1009 11.7922 17.8103 13.1709 17.6674L19.5121 17.8581C19.684 17.8626 19.8533 17.9021 20.0104 17.9743C20.1675 18.0464 20.3093 18.1499 20.4277 18.2787C20.5461 18.4076 20.6388 18.5593 20.7004 18.7251C20.7621 18.891 20.7915 19.0678 20.7871 19.2455C20.7813 19.5866 20.651 19.9129 20.4224 20.1589C20.1937 20.4049 19.8836 20.5525 19.5541 20.5722L15.6453 20.7976C15.48 20.809 15.3186 20.8539 15.1702 20.9298C15.0217 21.0057 14.8892 21.111 14.7802 21.2399C14.6711 21.3687 14.5877 21.5184 14.5347 21.6806C14.4816 21.8428 14.46 22.0142 14.471 22.185C14.482 22.3558 14.5255 22.5226 14.5989 22.6761C14.6723 22.8295 14.7742 22.9665 14.8988 23.0793C15.0234 23.192 15.1683 23.2782 15.3252 23.3331C15.482 23.3879 15.6478 23.4103 15.813 23.3989L20.2754 23.1735L25.9625 22.0636L30.6849 20.4767C31.12 20.3267 31.5946 20.3588 32.007 20.5663C32.4194 20.7738 32.7367 21.1401 32.8909 21.5867C33.0233 21.9607 33.0336 22.3692 32.9199 22.7498C32.8063 23.1304 32.5751 23.4619 32.2618 23.6937L26.969 27.6217Z"
			fill={fill || colors.offWhite}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17 15.1579C21.1858 15.1579 24.579 11.7647 24.579 7.57895C24.579 3.39321 21.1858 0 17 0C12.8143 0 9.42108 3.39321 9.42108 7.57895C9.42108 11.7647 12.8143 15.1579 17 15.1579ZM16.5274 11.6185C16.5274 11.9455 16.7925 12.2105 17.1195 12.2105C17.4464 12.2105 17.7115 11.9455 17.7115 11.6185V11.1789C18.4039 11.0571 18.9129 10.7956 19.2383 10.3944C19.5707 9.99324 19.7369 9.52758 19.7369 8.99744C19.7369 8.4458 19.5603 8.00879 19.2072 7.68641C18.8609 7.36403 18.3208 7.11686 17.5869 6.94493V5.16107C17.8361 5.21838 18.0473 5.30435 18.2204 5.41898C18.4906 5.59784 18.8636 5.6226 19.1006 5.40168L19.1601 5.34619C19.4135 5.10991 19.4423 4.7073 19.1633 4.50184C19.0498 4.41828 18.9294 4.3443 18.8021 4.27989C18.4905 4.11511 18.127 4.00765 17.7115 3.9575V3.5394C17.7115 3.21243 17.4464 2.94737 17.1195 2.94737C16.7925 2.94737 16.5274 3.21243 16.5274 3.5394V3.9575C15.8558 4.03631 15.3434 4.25481 14.9902 4.61302C14.644 4.97122 14.4709 5.4333 14.4709 5.99927C14.4709 6.52225 14.6406 6.95925 14.9799 7.31029C15.3191 7.66133 15.88 7.91924 16.6625 8.08401V10.0183C16.3716 9.97533 16.1051 9.88578 15.8627 9.74966C15.8048 9.71545 15.7481 9.67919 15.6926 9.64089C15.395 9.43547 14.984 9.41406 14.7208 9.66216C14.4654 9.90296 14.4428 10.3087 14.7182 10.5264C14.8672 10.6441 15.0271 10.7506 15.198 10.8458C15.558 11.0464 16.0012 11.1717 16.5274 11.2219V11.6185ZM16.0808 6.43986C15.9631 6.32523 15.9043 6.16404 15.9043 5.95628C15.9043 5.74852 15.9631 5.57659 16.0808 5.44047C16.2055 5.29719 16.3993 5.19689 16.6625 5.13958V6.73C16.3993 6.6512 16.2055 6.55448 16.0808 6.43986ZM18.1685 8.66431C18.2585 8.80759 18.3035 8.95803 18.3035 9.11564C18.3035 9.3234 18.2447 9.50609 18.127 9.6637C18.0093 9.81414 17.8292 9.92518 17.5869 9.99683V8.29894C17.8846 8.39207 18.0785 8.51386 18.1685 8.66431Z"
			fill={fill || colors.offWhite}
		/>
	</svg>
);

export default RecebedoresIcon;
