import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 2.5rem 1.5rem 2.5rem 1.5rem;
	.loading-rectangle {
		height: 2rem;
		width: calc(100% - 2px);
	}
`;

export const ButtonGroup = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 3.5rem;
	justify-content: space-between;
	margin-top: 6.125rem;
	div#step {
		display: flex;
		flex-direction: row;
		gap: 1.5rem;
	}
`;

export const Tittle = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	color: ${colors.white};
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 32px;
	p {
		height: 2rem;
		color: ${colors.lightGray};
		font-family: IBM Plex Sans;
		font-size: 20px;
		line-height: 26px;
		line-height: 100%;
		vertical-align: Top;
		font-weight: 400;
	}
`;

export const Form = styled.div`
	margin-top: 3.125rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	div#select {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: ${colors.white};
	}

	.custom-select-input {
		border-bottom: 1px solid ${colors.primaryBlue};
		border-radius: 0;
		height: 100%;
		padding-top: 0.4375rem;
	}
	div#convite-email {
		display: flex;
		flex-direction: row;
		align-content: left;
		color: ${colors.white};
		width: 20.7%;
		margin-top: 1.25rem;
	}
`;

export const Underlineinput = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.625rem;

	color: ${colors.primaryRed};
	color: ${({ isInvalid }) => (isInvalid ? colors.red : colors.offWhite)};
`;

export const InputTextField = styled.div`
	margin-top: 3.125rem;
	display: flex;
	flex-direction: row;
	width: 100%;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: ${colors.white};
	align-items: flex-end;
	gap: 2rem;
	.underlineinput {
		display: flex;
		flex-direction: column;
	}
`;
export const LinkCep = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 0.3125rem;
	a {
		align-items: flex-start;
		color: ${colors.primaryBlue};
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.01em;
		text-decoration-line: none;
		border-bottom: 1px solid ${colors.primaryBlue};
		&:hover {
			color: ${colors.darkTurquoise};
			border-bottom: 1px solid ${colors.darkTurquoise};
		}
	}
`;
