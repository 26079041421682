/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* import React from 'react'; */

import React, { useRef, useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { loginWithoutAuthPersist } from '../../services';

export default function Signup() {
	const emailRef = useRef();
	const passwordRef = useRef();
	const passwordConfirmRef = useRef();
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const handleSubmit = (e) => {
		e.preventDefault();
		setError('');
		setLoading(true);
		if (emailRef.current.value === '') {
			setError('Email is required');
			setLoading(false);
			return;
		}
		if (passwordRef.current.value === '') {
			setError('Password is required');
			setLoading(false);
			return;
		}

		loginWithoutAuthPersist(
			emailRef.current.value,
			passwordRef.current.value
		).then(() => {
			setLoading(false);
			history.push('/resumo');
		});
	};

	return (
		<>
			<Card>
				<Card.Body>
					<h2 className="text-center mb-4">Sign Up</h2>
					{error && <Alert severity="error">{error}</Alert>}
					<Form onSubmit={handleSubmit}>
						<Form.Group id="email">
							<Form.Label>Email</Form.Label>
							<Form.Control type="email" ref={emailRef} required />
						</Form.Group>
						<Form.Group id="password">
							<Form.Label>Password</Form.Label>
							<Form.Control type="password" ref={passwordRef} required />
						</Form.Group>
						<Form.Group id="password-confirm">
							<Form.Label>Password Confirmation</Form.Label>
							<Form.Control type="password" ref={passwordConfirmRef} required />
						</Form.Group>
						<Button disabled={loading} className="w-100" type="submit">
							Sign Up
						</Button>
					</Form>
				</Card.Body>
			</Card>
			<div className="w-100 text-center mt-2">
				Already have an account? <Link to="/login">Log In</Link>
			</div>
		</>
	);
}
