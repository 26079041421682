import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	margin-bottom: 1rem;
	width: 100%;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin-bottom: 1rem;
	height: auto;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 1.5rem 1.5rem;
	gap: 0.9375rem;
	flex-wrap: wrap;
	> div {
		display: flex;
	}

	.__floater__container {
		transform: translate(43%);
	}

	.Button-Remove-Filters {
		white-space: nowrap;
		color: ${colors.purple};
		border-color: ${colors.purple};
		background-color: ${colors.white};
	}

	.children-text {
		text-transform: none;
	}
	.legendsFilter {
		display: flex;
		flex-wrap: wrap;
	}
`;

export const LegendsFilter = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`;

export const InputGroup = styled.div`
	display: flex;
	white-space: nowrap;
	gap: 0.9375rem;
	flex-direction: row;
	width: 100%;

	button {
		font-size: 1.25rem;
	}
`;

export const FiltersGroup = styled.div`
	display: flex;
	white-space: nowrap;
	gap: 0.9375rem;
	flex-wrap: wrap;
	z-index: 0;

	button {
		font-size: 1.25rem;
	}
`;
