import React, { useState } from 'react';

import { useNewPaymentLink } from '../../../../contexts';
import { justNumbers } from '../../../../utils';
import {
	ButtonCustom,
	CheckboxCustom,
	SelectReact,
	UnderlineInput,
} from '../../../atoms';

import * as s from './styled-new-payment-link-form-payment';

function FormPayment({ setCurrentStep }) {
	const {
		paymentConfigurationBoleto,
		setPaymentConfigurationBoleto,
		paymentConfigurationCredit,
		setPaymentConfigurationCredit,
		setIsCancelOpenCreation,
	} = useNewPaymentLink();

	const [errorConfiguration, setErrorConfiguration] = useState({
		percentage: '',
		expires: '',
	});

	const Installment = [
		{ label: 'Máximo de 1 parcela', parcela: 1 },
		{ label: 'Máximo de 2 parcelas', parcela: 2 },
		{ label: 'Máximo de 3 parcelas', parcela: 3 },
		{ label: 'Máximo de 4 parcelas', parcela: 4 },
		{ label: 'Máximo de 5 parcelas', parcela: 5 },
		{ label: 'Máximo de 6 parcelas', parcela: 6 },
		{ label: 'Máximo de 7 parcelas', parcela: 7 },
		{ label: 'Máximo de 8 parcelas', parcela: 8 },
		{ label: 'Máximo de 9 parcelas', parcela: 9 },
		{ label: 'Máximo de 10 parcelas', parcela: 10 },
		{ label: 'Máximo de 11 parcelas', parcela: 11 },
		{ label: 'Máximo de 12 parcelas', parcela: 12 },
	];

	const FreeInstallments = Installment.filter(
		(item) => item.parcela <= paymentConfigurationCredit.max_installments
	);

	const isCreditCardReadOnly = FreeInstallments.length <= 1;

	const isBoletoReadOnly = !paymentConfigurationBoleto.enabled;

	const validateInformations =
		(paymentConfigurationBoleto.enabled ||
			paymentConfigurationCredit.enabled) &&
		errorConfiguration.expires === '' &&
		errorConfiguration.percentage === '';

	const handleChangePercentage = (e) => {
		const doc = justNumbers(e.target.value);
		if (doc < 1) {
			setPaymentConfigurationCredit({
				...paymentConfigurationCredit,
				interest_rate: Number(doc),
			});
			setErrorConfiguration({
				...errorConfiguration,
				percentage: 'Porcentagem deve ser maior que 0',
			});
		}
		if (doc <= 100 && doc > 0) {
			setErrorConfiguration({ ...errorConfiguration, percentage: '' });
			setPaymentConfigurationCredit({
				...paymentConfigurationCredit,
				interest_rate: Number(doc),
			});
		}
	};
	const handleChangeExpires = (e) => {
		const doc = justNumbers(e.target.value);

		if (doc < 1) {
			setPaymentConfigurationBoleto({
				...paymentConfigurationBoleto,
				expires_in: Number(doc),
			});
			setErrorConfiguration({
				...errorConfiguration,
				expires: 'Dias para o vencimento deve ser maior que 0',
			});
		}
		if (doc > 0) {
			setErrorConfiguration({ ...errorConfiguration, expires: '' });
			setPaymentConfigurationBoleto({
				...paymentConfigurationBoleto,
				expires_in: Number(doc),
			});
		}
	};

	const handleChangeMaxInstallments = (opt) => {
			
			if(opt.value < paymentConfigurationCredit.free_installments){
				setPaymentConfigurationCredit({
						...paymentConfigurationCredit,
						max_installments: opt.value,
						free_installments: opt.value,
				})}
			else{
				setPaymentConfigurationCredit({
					...paymentConfigurationCredit,
					max_installments: opt.value,
				})
			}
	}
	

	const renderFreeInstallment = () => {
		if(paymentConfigurationCredit.free_installments <= 1) return `À vista`
		return `Máximo de ${paymentConfigurationCredit.free_installments} parcelas sem juros`
	}

	return (
		<s.Container>
			<s.Title>MEIOS DE PAGAMENTO</s.Title>{' '}
			<s.FormGroup>
				<CheckboxCustom
					text="Cartão de crédito"
					value={paymentConfigurationCredit.enabled}
					onChange={() =>
						setPaymentConfigurationCredit({
							...paymentConfigurationCredit,
							enabled: !paymentConfigurationCredit.enabled,
							free_installments: 1,
							interest_rate: 1,
							max_installments: 1,
						})
					}
					checked={paymentConfigurationCredit.enabled}
				/>
				<p>
					Oferecemos parcelamento em 12 vezes, escolha o modelo de juros
					oferecido
				</p>
				<s.InputGroup>
					<s.InputTextField>
						<br />
						<SelectReact
							options={Installment.map((option) => ({
									label: `Parcela ${option.parcela}`,
									value: option.parcela,
							}))}
							className="select-period"
							type="text"
							id="select.period"
							outlined
							readOnly={!paymentConfigurationCredit.enabled}
							width="17rem"
							height="2.5rem"
							placeholder="Limites de parcelas"
							value={paymentConfigurationCredit.max_installments === 1? "À vista" : `Máximo de ${paymentConfigurationCredit.max_installments} parcelas` }
							onChange={(opt) => handleChangeMaxInstallments(opt)}
						/>
					</s.InputTextField>
					<s.InputTextField>
						<s.TextCustom readOnly={isCreditCardReadOnly}>
							Quantidade de parcelas sem juros
						</s.TextCustom>
						<SelectReact
							options={FreeInstallments}
							className="select-period"
							type="number"
							id="select-period"
							outlined
							readOnly={!paymentConfigurationCredit.enabled || isCreditCardReadOnly}
							width="24rem"
							height="2.5rem"
							placeholder="Quantidade de parcelas sem juros"
							value={renderFreeInstallment()}
							onChange={(opt) =>
								setPaymentConfigurationCredit({
									...paymentConfigurationCredit,
									free_installments: opt.parcela,
								})
							}
						/>
					</s.InputTextField>
					<s.InputTextField readOnly={isCreditCardReadOnly}>
						<s.TextCustom readOnly={isCreditCardReadOnly}>
							Porcentagem de juros ao mês (de 1% a 99%)
						</s.TextCustom>
						<div style={{ height:"2.5rem"}}>
						<UnderlineInput
							className="custom-input"
							id="value-of-link"
							name="value-link"
							width="20.5rem"
							placeholder="Digite a porcentagem aqui"
							readOnly={isCreditCardReadOnly}
							value={paymentConfigurationCredit.interest_rate}
							onChange={(e) => handleChangePercentage(e)}
							erro={errorConfiguration.percentage}
							isInvalid={errorConfiguration.percentage}
							maxLength={3}
						/>
						</div>
					</s.InputTextField>
				</s.InputGroup>
			</s.FormGroup>
			<s.FormGroup>
				<CheckboxCustom
					text="Boleto"
					value={paymentConfigurationBoleto.enabled}
					onChange={() =>
						setPaymentConfigurationBoleto({
							...paymentConfigurationBoleto,
							enabled: !paymentConfigurationBoleto.enabled,
						})
					}
					checked={paymentConfigurationBoleto.enabled}
				/>
				<p>
					Os dias serão contados a partir do momento que o seu cliente gerar o
					boleto
				</p>
				<s.InputGroup>
					<s.InputTextField>
						<s.TextCustom readOnly={isBoletoReadOnly}>
							Dias para o vencimento
						</s.TextCustom>
						<UnderlineInput
							className="custom-input"
							id="value-of-link"
							name="value-link"
							width="20.5rem"
							placeholder="Digite os dias aqui"
							readOnly={isBoletoReadOnly}
							value={
								paymentConfigurationBoleto.expires_in > 0
									? paymentConfigurationBoleto.expires_in
									: ''
							}
							onChange={(e) => handleChangeExpires(e)}
							erro={errorConfiguration.expires}
							isInvalid={errorConfiguration.expires}
							maxLength={4}
						/>
					</s.InputTextField>
				</s.InputGroup>
			</s.FormGroup>
			{/* <s.FormGroup>
				<CheckboxCustom text="Pix" />
				<p>
					Ative o recebimento por Pix para receber transações por essa
					modalidade
				</p>
			</s.FormGroup> */}
			<s.ButtonGroup>
				<ButtonCustom
					theme="gray"
					outlined
					onClick={() => setIsCancelOpenCreation(true)}
				>
					Cancelar
				</ButtonCustom>
				<div>
					<ButtonCustom
						outlined
						theme="green"
						onClick={() => setCurrentStep(1)}
					>
						Voltar
					</ButtonCustom>

					<ButtonCustom
						theme="green"
						disabled={!validateInformations || false}
						onClick={() => setCurrentStep(3)}
					>
						Continuar
					</ButtonCustom>
				</div>
			</s.ButtonGroup>
		</s.Container>
	);
}

export default FormPayment;
