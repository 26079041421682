import styled from 'styled-components';
import { colors } from '../../../../../assets';

export const Header = styled.div`
	display: flex;
	gap: 1.5rem;
	width: 100%;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 0.9375rem 1.25rem;

	div {
		display: flex;
		flex-direction: column;

		.loading-rectangle {
			height: 25px;
			margin: 5px 0 0 0;
		}

		h2 {
			color: ${colors.lightGray};
			font-size: 1.25rem;
			font-weight: 400;
		}

		p {
			color: ${colors.white};
			font-family: Jost;
			font-size: 1.38rem;
			font-weight: 600;

			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}
	}

	div#recebedor {
		max-width: 28.13rem;
	}
	div#banco {
		max-width: 28.13rem;
	}

	div:not(#recebedor, .loading-rectangle) {
		border-left: 2px solid ${colors.gray};
		padding-left: 10px;
	}
`;
