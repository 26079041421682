import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	grid-column: span 3 / auto;
	flex-direction: column;
	border-radius: 15px;
	height: 100%;

	color: ${colors.white};
	background-color: ${colors.darkGray};
	padding: 18px 35px 37px 42px;
	> span {
		padding-bottom: 6px;
	}

	.loading-rectangle {
		height: 2rem;
		width: calc(100% - 1rem);
	}
	.loader {
		margin-top: 1rem;
	}
	.subtitle {
		display: flex;
		flex-direction: row;
		font-size: 1em;
		justify-content: space-between;

		font-family: IBM Plex Sans;
		font-style: normal;
		text-align: left;
		color: ${colors.white};
		h2 {
			font-family: Jost;
			font-style: normal;
			font-weight: 600;
			font-size: 1.375rem;
			line-height: 32px;
			text-align: right;
		}
	}
`;

export const Title = styled.h1`
	display: flex;
	flex-direction: row;
	font-size: 1em;
	padding-bottom: 10px;
	justify-content: space-between;

	font-family: IBM Plex Sans;
	font-style: normal;
	font-weight: 400;

	letter-spacing: 0em;
	text-align: left;
	color: ${colors.white};
`;

export const Infos = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${colors.white};
	justify-content: space-between;
	h3 {
		font-family: Jost;
		font-style: normal;
		font-weight: 600;
		font-size: 1.375rem;
		text-align: right;
	}

	div {
		display: flex;
	}
`;

export const CardFooter = styled.div`
	display: flex;
	flex: 1;
`;

export const CardFooterItem = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
`;

export const ReceiverContainer = styled.div`
	display: relative;
	flex-direction: column;
	width: 100%;
	margin-top: 13px;
`;

export const ReceiverHeader = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 13rem;
	align-items: flex-start;
	padding: 14px 23px 11px 19px;
	border: 1px solid ${colors.lightGray};
	border-radius: 15px;
	background-color: ${colors.darkGray};
`;

export const LeftReceiverHeader = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	span {
		font-size: 1.25rem;
	}

	.title {
		font-family: Jost;
		font-style: normal;
		font-weight: 600;
		font-size: 1em;
		line-height: 23px;
	}
	.status {
		font-size: 1.25rem;
		font-weight: 400;
		align-content: center;
		align-items: center;
		span:first-child {
			margin-right: 0.625rem;
		}
	}
`;

export const RightReceiverHeader = styled.div`
	display: flex;
	flex-direction: column;
	text-align: right;
	justify-content: space-between;
	margin-left: auto;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	text-align: right;
	align-items: flex-end;
	h2 {
		font-family: Jost;
		font-weight: 600;
		font-size: 1.375rem;
	}
	h3 {
		font-weight: bold;
		font-size: 1rem;
	}
	p {
		font-size: 1rem;
		justify-content: right;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	:last-of-type {
		padding-left: 3.125rem;
	}
`;

export const InstallmentsButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	padding-top: 17px;

	div {
		display: flex;
		flex-direction: row;
		width: 2rem;
		height: 2rem;
		border: 1px solid ${colors.lightGray};
		border-radius: 5px;
		margin-right: 13px;
	}
	span {
		font-style: normal;
		font-weight: 600;
		color: ${colors.offWhite};
		font-size: 1.25rem;
	}
`;

export const Installments = styled.div`
	display: ${({ isOpen }) => (isOpen === true ? 'flex' : 'none')};
	width: 100%;
	transform: translateY(-15px);
	border: 1px solid ${colors.lightGray};
	border-top-width: 0;
	border-radius: 15px;
	border-radius: 0px 0px 15px 15px;
	padding: 37px 45px 22px 23px;
	transition: '.2s ease-in-out';
	table {
		width: 100%;
		text-align: left;
		font-size: 1rem;
	}
	th {
		overflow: hidden;
		text-overflow: ellipsis;
	}
	td {
		font-size: 1rem !important;
	}
	tbody {
	}
`;

export const Status = styled.div`
	color: ${colors.primaryBlue};
	font-family: Jost;
	font-style: normal;
	font-weight: 600;

	color: ${({ status }) => {
		if (status === 'paid') {
			return colors.green;
		}
		if (status === 'authorized') {
			return colors.primaryBlue;
		}
		if (status === 'refused') {
			return colors.primaryRed;
		}
		if (status === 'chargedback') {
			return colors.black;
		}
		if (status === 'waiting_funds') {
			return colors.primaryBlue;
		}
		return colors.white;
	}};
`;
