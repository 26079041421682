import React from 'react';
import { useHistory } from 'react-router';
import { Icons } from '../../../../assets';
import ContainerModal from '../container-modal';
import { ButtonCustom } from '../../..';

import * as s from './styled-modal-aviso-desativacao-paymentLink';

const ModalAvisoDesativacaoPaymentLink = ({
	isOpen,
	closeModal,
	disableLink,
	paymentLinkId,
}) => {
	const history = useHistory();

	return (
		<ContainerModal isOpen={isOpen} closeModal={closeModal}>
			<s.Content>
				<s.Imagem>
					<Icons.InfoTriangleIcon />
				</s.Imagem>
				<s.Title>DESATIVAR LINK DE PAGAMENTO</s.Title>
				<s.Subtitle>
					Após desativar este link de pagamento não será <br />
					possível reativá-lo e seus clientes não poderão <br />
					realizar pagamentos através dele.
				</s.Subtitle>
				<s.ButtonGroup>
					<ButtonCustom
						className="button-custom-desativar"
						width="15rem"
						textTransform="none"
						onClick={closeModal}
					>
						Cancelar
					</ButtonCustom>
					<ButtonCustom
						textTransform="none"
						outlined
						width="15rem"
						theme="alert-white"
						onClick={() => {
							disableLink();

							history.push(`/links-pagamento/${paymentLinkId}`);
						}}
						className="button-custom-cancelar"
					>
						Desativar o Link
					</ButtonCustom>
				</s.ButtonGroup>
			</s.Content>
		</ContainerModal>
	);
};

export default ModalAvisoDesativacaoPaymentLink;
