import * as React from 'react';
import { DataGrid, ptBR, GridToolbarExport } from '@mui/x-data-grid';
import { useState } from 'react';

const columns = [
	{
		field: 'data',
		headerName: 'DATA',
		flex: 1,
	},
	{
		field: 'id',
		headerName: 'ID DA OPERAÇÃO',
		editable: true,
		flex: 1,
	},
	{
		field: 'id_transacao',
		headerName: 'ID DA TRANSAÇÃO',
		editable: true,
		flex: 1,
	},
	{
		field: 'descricao',
		headerName: 'DESCRIÇÃO',
		editable: true,
		flex: 1,
	},
	{
		field: 'entradas',
		headerName: 'ENTRADAS',
		editable: true,
		flex: 1,
	},
	{
		field: 'saidas',
		headerName: 'SAÍDAS',
		editable: true,
		flex: 1,
	},
	{
		field: 'total',
		headerName: 'TOTAL',
		editable: true,
		flex: 1,
	},
];

const rows = [
	{
		data: new Date(1996, 5, 23),
		id: 1,
		id_transacao: 1,
		descricao: 'teste 1',
		entradas: 10,
		saidas: 20,
		total: 10,
	},
	{
		data: new Date(1996, 5, 23),
		id: 2,
		id_transacao: 2,
		descricao: 'teste 2',
		entradas: 10,
		saidas: 20,
		total: 10,
	},
	{
		data: new Date(1996, 5, 23),
		id: 3,
		id_transacao: 3,
		descricao: 'teste 3',
		entradas: 10,
		saidas: 20,
		total: 10,
	},
	{
		data: new Date(1996, 5, 23),
		id: 4,
		id_transacao: 4,
		descricao: 'teste 4',
		entradas: 10,
		saidas: 20,
		total: 10,
	},
	{
		data: new Date(1996, 5, 23),
		id: 5,
		id_transacao: 5,
		descricao: 'teste 5',
		entradas: 10,
		saidas: 20,
		total: 10,
	},
	{
		data: new Date(1996, 5, 23),
		id: 6,
		id_transacao: 6,
		descricao: 'teste 6',
		entradas: 10,
		saidas: 20,
		total: 10,
	},
];

export default function DataTable() {
	const [pageSize, setPageSize] = useState(5);

	return (
		<div style={{ width: '100%' }}>
			<DataGrid
				columns={columns}
				rows={rows}
				autoHeight
				pagination
				components={{ Header: GridToolbarExport }}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 15, 30]}
				localeText={ptBR.props.MuiDataGrid.localeText}
			/>
		</div>
	);
}
