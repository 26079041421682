import React from 'react';
import { colors } from '../colors';

const GraficoVolumeTransacionado = ({ title, fill, style }) => (
	<svg
		style={style}
		className="grafico-transacoes-icon"
		width="100"
		height="44"
		viewBox="0 0 100 44"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || 'Ícone gráfico volume transacionado'}</title>
		<path
			d="M2 40.8829C2 40.8829 12.0855 43.3964 22.1708 40.8829C45.6749 35.0253 38.1026 -2.21053 56.1538 7.61403C77.78 19.3842 94.9744 18.7562 98 2"
			stroke="url(#paint0_linear_3359_14304)"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_3359_14304"
				x1="-58.7179"
				y1="52.5263"
				x2="66.0246"
				y2="-36.2598"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor={fill || colors.primaryBlue} />
				<stop
					offset="1"
					stopColor={fill || colors.primaryBlue}
					stopOpacity="0"
				/>
			</linearGradient>
		</defs>
	</svg>
);
export default GraficoVolumeTransacionado;
