import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Header = styled.div`
	display: flex;
	gap: 4rem;
	width: 100%;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 0.9375rem 1.25rem;

	.loading-rectangle {
		height: 25px;
		width: 100%;
	}

	.id-recebedor {
		margin-left: auto;
		text-align: right;
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	.right-side {
		display: flex;
		justify-content: flex-end;
	}
`;

export const Title = styled.span`
	font-size: 1.25rem;
	line-height: 1.625rem;
	color: ${colors.lightGray};
`;

export const MainInfo = styled.span`
	font-family: Jost;
	font-style: normal;
	font-weight: 600;
	font-size: 1.375rem;
	line-height: 2rem;
	margin-top: 1rem;
	color: ${colors.offWhite};

	.loading-rectangle {
		height: 25px;
		width: 200px;
	}
`;

export const Status = styled.span`
	font-family: IBM Plex Sans;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.3125rem;
	letter-spacing: 0.03125rem;
	color: ${colors.green};
	margin-top: 1.25rem;
	.loading-rectangle {
		margin-top: 2px;
		height: 18px;
	}
`;

export const SecondaryInfo = styled.span`
	font-size: 1rem;
	line-height: 1.3125rem;
	letter-spacing: 0.01rem;
	color: ${colors.lightGray};
	margin-top: 0.5rem;
	width: 100%;
	.loading-rectangle {
		height: 20px;
		width: 150px;
	}
`;
