import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	width: 100%;
	height: 9.375rem;
`;

export const TooltipContainer = styled.div`
	display: flex;
	font-family: Jost;
	font-size: 0.875rem;
`;

export const Description = styled.p`
	line-height: 1.125rem;
	color: ${({ color }) => color || colors.darkTurquoise};
`;

export const Value = styled.span`
	font-weight: 500;
	line-height: 1.125rem;
	color: ${({ color }) => color || colors.darkGray};
`;
