import styled from 'styled-components';

import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 2.5rem 1.5rem;

	.checkbox-custom {
		display: flex;
		justify-content: flex-start;
		height: fit-content;
		color: ${({ checked }) =>
			checked ? colors.primaryBlue : colors.lightGray};
	}

	.label-underline-input {
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 1.3125rem;
	}
	.info-icon-outlined {
		margin-left: 0.625rem;
	}
`;

export const InputGroup = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 3.5rem;
	:not(.radio-group) {
		gap: 2.625rem;
	}
	.label-underline-input {
		font-style: normal;
		font-weight: normal;
		font-size: 1rem;
		line-height: 1.3125rem;
		color: ${colors.offWhite};
		width: 15rem;
	}

	.custom-select-input {
		svg path {
			fill: ${colors.offWhite};
		}
		border-radius: 0;
		border-bottom: 1px solid
			${({ readOnly }) => (readOnly ? colors.gray : colors.primaryBlue)};
		label {
			color: ${({ readOnly }) => (readOnly ? colors.gray : colors.primaryBlue)};
		}
	}
`;

export const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 4.5rem;

	.aditional-form {
		display: ${({ checked }) => (checked ? 'flex' : 'none')};
	}
`;

export const GroupTitle = styled.h2`
	font-family: Jost;
	font-style: normal;
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 2rem;
	letter-spacing: 0.04rem;
	color: ${colors.offWhite};
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	justify-flex: flex-start;
	margin-bottom: 3em;
`;

export const Title = styled.h2`
	font-family: Jost;
	font-style: normal;
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 2rem;
	letter-spacing: 0.04em;
	color: ${colors.offWhite};
	justify-content: flex-start;
	text-align: left;
	margin-bottom: 0.5rem;
`;

export const Subtitle = styled.h3`
	color: ${colors.lightGray};
	font-size: 1.25rem;
	font-weight: 400;
`;

export const TitleInput = styled.h4`
	color: ${colors.offWhite};
	font-size: 1rem;
	font-style: normal;
	font-weight: normal;
	padding-bottom: 1rem;
`;

export const RadioGroup = styled.div`
	display: flex;
	gap: 2rem;
	margin-bottom: 2rem;
`;

export const Label = styled.label`
	display: inline-block;
	margin-right: 2.5rem;
	margin-left: 1rem;
	font-size: 1.25rem;
	line-height: 1.625rem;
	color: ${colors.lightGray};
`;

export const ColumnsGroup = styled.div`
	display: flex;
	gap: 2rem;
	margin-bottom: 4.625rem;
	.wrapper-underline-input:first-of-type {
		margin-bottom: 2rem;
	}
`;

export const GroupContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 3rem;
`;

export const ButtonGroup = styled.div`
	display: flex;
	margin-top: auto;
	width: 100%;
	button {
		width: 12.5rem;
		height: 3.3125rem;
	}
`;

export const RightButtons = styled.div`
	display: flex;
	gap: 1rem;
	margin-left: auto;
`;
