import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 14.9375rem;
	min-height: 14.9375rem;
	max-height: 14.9375rem;
	padding: 1.125rem 0.875rem 1rem 0.9375rem;
	.loading-rectangle {
		display: flex;
		height: 2rem;
		width: 100%;
	}
`;

export const Title = styled.h1`
	padding-bottom: 0.625rem;
	font-size: 1rem;
	font-weight: 400;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const Infos = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-family: Jost;
	font-style: normal;
	font-weight: 300;
	font-size: 1rem;
	:not(:last-child) {
		margin-bottom: 0.625rem;
	}
`;

export const Item = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	align-content: center;
	.outros {
		margin-right: 0.25rem;
	}
`;

export const ItemType = styled.span`
	font-family: Jost;
	font-style: normal;
	font-weight: 300;
	font-size: 1rem;
`;
export const Icon = styled.div`
	display: flex;
	margin-right: 1rem;
	user-select: none;
	user-drag: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	pointer-events: none;
	.credit-card-icon {
		height: 1.25rem;
	}
`;

export const Percentage = styled.span`
	display: flex;
	font-family: IBM Plex Sans;
	font-style: normal;
	font-weight: 700;
	font-size: 1.125rem;
	text-align: right;
	color: ${colors.primaryBlue};
`;

export const FloaterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colors.background};
	border-radius: 4px;
	color: ${colors.white};
	width: fit-content;
	padding: 0.4375rem 0.75rem 0.75rem 1rem;
`;

export const FloaterRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	font-family: Jost;
	font-style: normal;
	font-size: 0.875rem;

	.percentage {
		color: ${colors.primaryBlue};
	}
`;
