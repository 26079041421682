import React, { useState } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { colors, Icons } from '../../../../assets';
import { ButtonCustom, PaginationWithInput } from '../../..';
import { formatDoc } from '../../../../utils';

import * as s from './styled-recipients-table';

const RecipientsTable = (props) => {
	const { dados, isLoading, page, setPage, itensPagina, handleSubmit } = props;
	const history = useHistory();
	const [isRowOpen, setIsRowOpen] = useState([]);

	const translateStatus = (status) => {
		const translatedStatus = {
			active: 'Ativo',
			inactive: 'Inativo',
			refused: 'Recusado',
		};
		return translatedStatus[status];
	};

	const handleRowClick = (param) => {
		if (isRowOpen.includes(param.id)) {
			return setIsRowOpen(isRowOpen.filter((item) => item !== param.id));
		}
		setIsRowOpen([...isRowOpen, param.id]);
	};

	function formateDate(date) {
		return date ? format(new Date(date), 'dd/MM/yyyy') : '--/--/----';
	}

	return (
		<s.WrapperTable>
			<s.Table>
				<s.Thead>
					<s.Tr top>
						<th>Status</th>
						<th>ID do recebedor</th>
						<th>ID da Conta Bancária</th>
						<th>Nome da conta bancária</th>
						<th>Número do Documento</th>
						<th>Data de criação</th>
					</s.Tr>
				</s.Thead>
				<s.Tbody>
					{dados.map((line, i) => {
						const dateCreated = formateDate(line?.created);
						const recipientId = line?.id;
						const lastUpdate = formateDate(line?.lastUpdate);
						const transferEnabled = line?.transferEnabled ? 'Sim' : 'Não';
						const transferInterval = line?.transferInterval;
						const transferDay = line?.transferDay;
						const transferDayTranslated = () => {
							if (transferInterval === 'weekly') {
								if (transferDay === 1) return 'Segunda-feira';
								if (transferDay === 2) return 'Terça-feira';
								if (transferDay === 3) return 'Quarta-feira';
								if (transferDay === 4) return 'Quinta-feira';
								if (transferDay === 5) return 'Sexta-feira';
							}
							return transferDay;
						};

						const intervalTransferTranslated = () => {
							if (transferInterval === 'daily') return 'Diária';
							if (transferInterval === 'weekly') return 'Semanal';
							if (transferInterval === 'monthly') return 'Mensal';
						};

						const antecipatedVolume = line?.antecipationVolume ?? '-';
						const antecipatedModel = line?.automatic
							? 'Automática por Volume'
							: 'Manual por Volume';
						return (
							<>
								<s.Tr
									key={line.id}
									bottom={i === dados.length - 1}
									onClick={() => handleRowClick(line)}
									index={i}
									clicavel
								>
									<s.Td value={line.status}>
										{translateStatus(line.status)}
									</s.Td>
									<s.Td minWidth="16rem">{line.id}</s.Td>
									<s.Td>{line.bankAccountId}</s.Td>
									<s.Td>{line.name}</s.Td>
									<s.Td>{formatDoc(line.document)}</s.Td>
									<s.Td>
										{dateCreated}
										{line && (
											<s.ChevronContainer>
												<Icons.Chevron
													fill={colors.lightGray}
													style={{
														height: '1.5rem',
														width: '1.5rem',
														transform: isRowOpen.includes(line.id)
															? 'rotate(-90deg)'
															: 'rotate(90deg)',
														transition: 'transform 0.1s ease',
													}}
												/>
											</s.ChevronContainer>
										)}
									</s.Td>
								</s.Tr>
								{isRowOpen.includes(line.id) && (
									<s.MoreInfo index={i}>
										<s.Content>
											<s.LeftColumn>
												<s.Item>
													Útima atualização: <span>{lastUpdate}</span>
												</s.Item>
												<s.Item>
													Transferência habilitada:{' '}
													<span>{transferEnabled}</span>
												</s.Item>
												<s.Item>
													Intervalo de transferência:{' '}
													<span>{intervalTransferTranslated()}</span>
												</s.Item>
											</s.LeftColumn>
											<s.RightColumn>
												<s.Item>
													Modelo: <span>{antecipatedModel}</span>
												</s.Item>
												<s.Item>
													Volume antecipável: <span>{antecipatedVolume}%</span>
												</s.Item>
												{transferInterval !== 'daily' && (
													<s.Item>
														Dia da transferência:{' '}
														<span>{transferDayTranslated()}</span>
													</s.Item>
												)}
											</s.RightColumn>
										</s.Content>
										<ButtonCustom
											theme="green"
											outlined
											onClick={() =>
												history.push(`/recebedores/${recipientId}`)
											}
										>
											Ver detalhes
										</ButtonCustom>
									</s.MoreInfo>
								)}
							</>
						);
					})}
				</s.Tbody>
				<s.Tfoot>
					<PaginationWithInput
						page={page}
						setPage={setPage}
						itensPagina={itensPagina}
						handleSubmit={handleSubmit}
						loading={isLoading}
					/>
				</s.Tfoot>
			</s.Table>
		</s.WrapperTable>
	);
};

export default RecipientsTable;
