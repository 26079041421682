import React from 'react';
import { withRouter } from 'react-router';
import { FormSteps, RegisterForm } from '../../components';

import * as s from './styled-new-recipient';

const NewRecipient = () => {
	return (
		<s.Container>
			<FormSteps />
			<RegisterForm />
		</s.Container>
	);
};

export default withRouter(NewRecipient);
