import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import {
	ButtonCustom,
	CheckboxCustom,
	RadioButton,
	UnderlineInput,
	SelectReact,
} from '../../../';
import {
	mascaraCPF,
	mascaraCNPJ,
	validaCPF,
	validaCNPJ,
	validateEmail,
	mascaraTelefone,
	validateUrl,
} from '../../../../utils';
import { useRecipients } from '../../../../hooks';
import { getBanks } from '../../../../services';

import * as s from './styled-dados';

const FormGroupTitle = ({ title, subtitle, requiredInputs }) => (
	<s.Header>
		<s.Title>{title}</s.Title>
		<s.Subtitle>{subtitle}</s.Subtitle>
		{requiredInputs && <s.RequiredText>* dados obrigatórios</s.RequiredText>}
	</s.Header>
);

const Dados = () => {
	const {
		step,
		setStep,
		tipoPessoa,
		setTipoPessoa,
		nome,
		setNome,
		email,
		setEmail,
		telefone,
		setTelefone,
		managingPartners,
		setManagingPartners,
		documento,
		setDocumento,
		url,
		setUrl,
		setIsBankOptionsLoading,
		setBanks,
		erros,
		setErros,
		setIsCancelarCriacaoOpen,
		isMoreInfoChecked,
		setIsMoreInfoChecked,
		isBankOptionsLoading,
		isContasRecebedorLoading,
		partnersQuantity,
		setPartnersQuantity,
	} = useRecipients();
	const [managingPartnersLocal, setManagingPartnersLocal] = useState(
		managingPartners
	);

	const isTipoPessoaFisica = tipoPessoa === 'PF';
	const partnersOptions = [
		{
			value: '0',
			label: '0',
		},
		{
			value: '1',
			label: '1',
		},
		{
			value: '2',
			label: '2',
		},
		{
			value: '3',
			label: '3',
		},
		{
			value: '4',
			label: '4',
		},
		{
			value: '5',
			label: '5',
		},
	];

	const validateDocumento = (e) => {
		const documentoExiste = e.target.value.length;
		const doc = e.target.value;

		if (isTipoPessoaFisica && documentoExiste) {
			if (validaCPF(doc)) setErros({ ...erros, documento: '' });
			else setErros({ ...erros, documento: 'CPF inválido' });
		}
		if (!isTipoPessoaFisica && documentoExiste) {
			if (validaCNPJ(doc)) setErros({ ...erros, documento: '' });
			else setErros({ ...erros, documento: 'CNPJ inválido' });
		}
	};

	const handleChangeDocumento = (e) => {
		if (isTipoPessoaFisica) {
			e.target.value = mascaraCPF(e.target.value);
			setDocumento(e.target.value);
		}
		if (!isTipoPessoaFisica) {
			e.target.value = mascaraCNPJ(e.target.value);
			setDocumento(e.target.value);
		}
		validateDocumento(e);
	};

	const handleChangeEmail = (e) => {
		e.target.value = e.target.value.toLowerCase().trim();
		setEmail(e.target.value);
		if (e.target.value.length) {
			if (!validateEmail(e.target.value)) {
				setErros({ ...erros, email: '' });
			} else {
				setErros({ ...erros, email: 'Email inválido' });
			}
		}
	};

	const handleChangeEmailSocios = (e, id) => {
		const socios = managingPartnersLocal;
		if (e) {
			e.target.value = e.target.value.toLowerCase().trim();
			socios[id] = { ...socios[id], email: e.target.value };
			setManagingPartnersLocal(socios);
		}
	};

	const handleChangeTelefone = (e) => {
		e.target.value = mascaraTelefone(e.target.value);
		if (e.target.value.length < 15) {
			setErros({ ...erros, telefone: 'Telefone inváldo' });
		} else setErros({ ...erros, telefone: '' });
		setTelefone(e.target.value);
	};

	const handleChangeUrl = (e) => {
		e.target.value = e.target.value.toLowerCase();
		setUrl(e.target.value);
		if (e.target.value) {
			if (validateUrl(e.target.value)) {
				setErros({ ...erros, url: 'Url inválida' });
			} else {
				setErros({ ...erros, url: '' });
			}
		}
	};

	const disabledContinuar = () => {
		if (isMoreInfoChecked) {
			if (
				nome.length < 2 ||
				!email ||
				erros.documento ||
				erros.email ||
				!documento ||
				erros.telefone ||
				erros.url
			)
				return true;
		} else if ((!isMoreInfoChecked && erros.documento) || !documento)
			return true;
		return false;
	};

	const handleChangeDocumentoSocios = (e, id) => {
		e.target.value = mascaraCPF(e.target.value);
		const socios = managingPartnersLocal;
		socios[id] = { ...socios[id], document_number: e.target.value };
		setManagingPartnersLocal(socios);
	};

	const SociosInputs = ({ id }) => {
		return (
			<s.Row key={id} id={id}>
				<UnderlineInput
					width="18rem"
					label="Nome"
					placeholder="Digite o nome do sócio"
					value={managingPartnersLocal?.[id]?.name}
					erro={erros?.nomeSocio}
					isInvalid={erros?.nomeSocio}
					minLength="2"
					onChange={(e) => {
						const socios = managingPartnersLocal;
						socios[id] = { ...socios[id], name: e.target.value };
						setManagingPartnersLocal(socios);
					}}
					required
				/>
				<UnderlineInput
					width="11.4375rem"
					label="CPF"
					placeholder="Digite o CPF do sócio"
					maxLength={14}
					value={managingPartnersLocal?.[id]?.document_number}
					erro={erros?.managingPartners?.[`documentoSocio${id}`]}
					isInvalid={erros?.managingPartners?.[`documentoSocio${id}`]}
					onChange={(e) => handleChangeDocumentoSocios(e, id)}
					required
				/>
				<UnderlineInput
					width="18rem"
					label="E-mail"
					placeholder="Digite o e-mail"
					value={managingPartnersLocal?.[id]?.email}
					isInvalid={erros?.managingPartners?.[`emailSocio${id}`]}
					erro={erros?.managingPartners?.[`emailSocio${id}`]}
					onChange={(e) => {
						handleChangeEmailSocios(e, id);
					}}
					required
				/>
			</s.Row>
		);
	};

	const isContinuarLoading =
		isBankOptionsLoading || (isContasRecebedorLoading && !disabledContinuar());

	const createPartnersInputs = (numeroInputs) => {
		setManagingPartnersLocal([]);
		setPartnersQuantity(numeroInputs);
	};

	useEffect(() => {
		setIsBankOptionsLoading(true);
		getBanks()
			.then((resp) =>
				setBanks(
					resp.map((bank) => ({
						value: bank.code,
						label: `${bank.code} - ${bank.name}`,
						agencia: bank.agencia,
						agenciaDv: bank.agenciaDv,
						conta: bank.conta,
						contaDv: bank.contaDv,
						bankCode: bank.code,
					}))
				)
			)
			.finally(() => {
				setIsBankOptionsLoading(false);
			});
	}, []);

	return (
		<s.Container checked={isMoreInfoChecked}>
			<form>
				<FormGroupTitle
					title="IDENTIFICAÇÃO"
					subtitle="Escolha qual o tipo de pessoa do seu recebedor e preencha o documento"
					requiredInputs
				/>
				<s.FormGroup>
					<s.TitleInput>Tipo de recebedor:</s.TitleInput>
					<s.RadioGroup>
						<RadioButton
							id="pf"
							label="Pessoa Física"
							name="tipoPessoa"
							value="PF"
							checked={tipoPessoa === 'PF'}
							onChange={() => {
								setDocumento('');
								setErros({ ...erros, documento: '' });
								setTipoPessoa('PF');
							}}
						/>
						<RadioButton
							id="pj"
							label="Pessoa Jurídica"
							name="tipoPessoa"
							value="PJ"
							checked={tipoPessoa === 'PJ'}
							onChange={() => {
								setDocumento('');
								setErros({ ...erros, documento: '' });
								setTipoPessoa('PJ');
							}}
						/>
					</s.RadioGroup>
					<s.InputGroup>
						{isTipoPessoaFisica ? (
							<UnderlineInput
								required
								label="CPF *"
								placeholder="Digite o CPF aqui"
								maxLength="14"
								width="9.625rem"
								value={documento}
								isInvalid={erros.documento}
								erro={erros.documento}
								onChange={(e) => handleChangeDocumento(e)}
							/>
						) : (
							<UnderlineInput
								id="documento-cnpj"
								label="CNPJ *"
								placeholder="Digite o CNPJ aqui"
								maxLength="18"
								width="12rem"
								value={documento}
								isInvalid={erros.documento}
								erro={erros.documento}
								onChange={(e) => handleChangeDocumento(e)}
							/>
						)}
					</s.InputGroup>
					<CheckboxCustom
						value={isMoreInfoChecked}
						onChange={() => setIsMoreInfoChecked(!isMoreInfoChecked)}
						fontSize="1.25rem"
						text={`Quero incluir mais informações ${
							isTipoPessoaFisica
								? 'sobre o recebedor'
								: 'sobre a empresa e os sócios'
						}`}
						checked={isMoreInfoChecked}
					/>
				</s.FormGroup>

				<s.FormGroup style={{ display: isMoreInfoChecked ? 'flex' : 'none' }}>
					<FormGroupTitle
						title={isTipoPessoaFisica ? 'RECEBEDOR' : 'EMPRESA'}
						subtitle={
							isTipoPessoaFisica
								? 'Preencha os dados do recebedor'
								: 'Preencha abaixo as informações sobre a empresa do seu recebedor'
						}
					/>
					<s.RowsGroup>
						<s.Row>
							<UnderlineInput
								required
								label="Nome *"
								placeholder={`Digite o nome completo ${
									isTipoPessoaFisica ? 'do recebedor' : 'da empresa'
								}`}
								width="20.5rem"
								maxLength={70}
								value={nome}
								erro={erros.nome}
								isInvalid={erros.nome}
								minLength="2"
								onChange={(e) => {
									setNome(e.target.value);
								}}
							/>
							<UnderlineInput
								required
								label="E-mail *"
								width="20.5rem"
								maxLength={50}
								placeholder={`Digite o e-mail ${
									isTipoPessoaFisica ? 'do recebedor' : 'da empresa'
								}`}
								value={email}
								erro={erros.email}
								isInvalid={erros.email}
								onChange={(e) => handleChangeEmail(e)}
							/>
						</s.Row>
						<s.Row>
							<UnderlineInput
								label="URL do site (opcional)"
								placeholder={`Digite a  URL do site ${
									isTipoPessoaFisica ? 'do recebedor' : 'da empresa'
								}`}
								width="20.5rem"
								isInvalid={erros.url}
								erro={erros.url}
								value={url}
								onChange={(e) => handleChangeUrl(e)}
							/>
							<UnderlineInput
								className="telefone"
								label="Telefone (opcional)"
								placeholder={`Digite o número do telefone ${
									isTipoPessoaFisica ? 'do recebedor' : 'da empresa'
								}`}
								width="20.5rem"
								value={telefone}
								erro={erros.telefone}
								isInvalid={erros.telefone}
								maxLength="15"
								onChange={(e) => handleChangeTelefone(e)}
							/>
						</s.Row>
					</s.RowsGroup>

					{!isTipoPessoaFisica && (
						<>
							<FormGroupTitle
								title="SÓCIOS"
								subtitle="Preencha abaixo as informações sobre os sócios do seu recebedor"
							/>
							<SelectReact
								width="16rem"
								className="custom-select-input"
								label="Escolha a quantidade de sócios"
								options={partnersOptions}
								placeholder={partnersOptions[0].label}
								value={partnersQuantity}
								onChange={(e) => {
									createPartnersInputs(e.value);
								}}
								isSearchable={false}
							/>
							<s.SociosContainer>
								{[...Array(Number(partnersQuantity)).keys()].map(
									(_input, index) => {
										return (
											<SociosInputs
												managingPartners={managingPartnersLocal}
												setManagingPartners={setManagingPartnersLocal}
												key={index}
												id={index}
											/>
										);
									}
								)}
							</s.SociosContainer>
						</>
					)}
				</s.FormGroup>

				<s.ButtonGroup checked={isMoreInfoChecked}>
					<ButtonCustom
						outlined
						theme="gray"
						onClick={() => {
							setIsCancelarCriacaoOpen(true);
						}}
					>
						Cancelar
					</ButtonCustom>
					<ButtonCustom
						theme="green"
						disabled={disabledContinuar()}
						isLoading={isContinuarLoading}
						onClick={() => {
							setStep(step + 1);
							setManagingPartners(managingPartnersLocal);
						}}
					>
						Continuar
					</ButtonCustom>
				</s.ButtonGroup>
			</form>
		</s.Container>
	);
};

export default withRouter(Dados);
