import React from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icons/SearchIcon.svg';
import * as s from './styled-search-field';
import { TextFieldCustom } from '../../../components';

const SearchField = ({
	value,
	onKeyPress,
	onChange,
	children,
	placeholder,
	width,
	display,
	margin,
	id,
	onFocus,
	border,
}) => (
	<s.SearchField
		margin={margin}
		width={width}
		display={display}
		border={border}
	>
		<s.IconButtonCustom>
			<SearchIcon />
		</s.IconButtonCustom>
		<TextFieldCustom
			id={id}
			value={value}
			onKeyPress={onKeyPress}
			onChange={onChange}
			placeholder={placeholder}
			onFocus={onFocus}
		>
			{children}
		</TextFieldCustom>
	</s.SearchField>
);

export default SearchField;
