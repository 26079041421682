import { createGlobalStyle } from 'styled-components';
import { colors } from '../assets/colors';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
    font-family: 'IBM Plex Sans', sans-serif;

    //Firefox
    scrollbar-width: thin;
    scrollbar-color: ${colors.gray} ${colors.darkGray};

    //Chrome, edge, safari
    ::-webkit-scrollbar {
      width: 8px;
      background: ${colors.darkGray};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: ${colors.gray};
      width: 10px;
    } 
}

      @media (max-width: 1725px) {
        html {
          font-size: 80%;
        }
      }

      @media (max-width: 1366px) {
        html {
          font-size: 70%;
        }
      }

      @media (max-width: 1025px) {
        html {
          font-size: 65%;
        }
      }

      @media (max-width: 960px) {
        html {
          font-size: 60%;
        }
      }

      @media (max-width: 480px) {
        html {
          font-size: 50%;
        }
      }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }


  body {
    background: ${colors.background};
    letter-spacing: .02em;
    -webkit-font-smoothing: antialiased;
    min-height: 100vh;
    margin: 0 auto; 
    overflow-x: hidden;
  }

  body, input, button {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button, input, textarea{
    :disabled {
      cursor: not-allowed !important;
    }
  }

  button {
    cursor: pointer !important;
    text-align: center !important;
  }

  .custom-scroll{
    // Firefox
    scrollbar-color: ${colors.gray} transparent;

    ::-webkit-scrollbar {
      width: 8px;
      background: none;
    }
  
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: ${colors.gray};
      width: 5px;
    }
  }

   .custom-scroll-dark{
      // Firefox
      scrollbar-color: rgba(46, 50, 51, 0.5) transparent;
 
    ::-webkit-scrollbar {
      width: 8px;
      background: none;
    }
  
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(46, 50, 51, 0.5);
      width: 10px;
    }
  }

  .DatePicker{
    z-index: 5;
  }

  //Estilização do select com borda
  .select-outlined {
		border: 1px solid ${colors.primaryBlue};

		[class$='menu'] {
			margin-top: -0.625rem;
			padding-top: 3px;
			border: 1px solid ${colors.primaryBlue};
			border-top: none;
			width: calc(100% + 2px);
			margin-left: -1px;
		}
	}

  //Esconder floater de exportação da tela de extratos
	.MuiGridMenu-root {
		display: none !important;
	}

  //Tirar comprimento mínimo do floater
  .__floater__container{
    min-width: 10px !important;
  }
  /* input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;

  }
  input[type=number] { 
   -moz-appearance: textfield;
   appearance: textfield;
} */
`;
