import styled from 'styled-components';
import { colors } from '../../../../assets/colors';
import { statusColor } from '../../../../utils';

export const Container = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: space-between;
`;

export const Ul = styled.ul`
	padding-left: 2rem;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 100%;
`;

export const Li = styled.li`
	color: ${({ status }) => statusColor(status)};
	line-height: 1;
	margin-left: 0.5rem;
	::marker {
		font-size: 2rem;
	}
`;

export const ItemLista = styled.div`
	display: flex;
	flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
	transform: translateY(-0.4rem);
	font-style: normal;
	font-weight: 600;
	font-size: 0.75em;
	color: ${colors.white};
	.title {
		font-family: Jost;
		font-style: normal;
		font-weight: normal;
		font-size: 0.875rem;
		line-height: 1.25rem;
		margin-right: 0.5rem;
	}
	.value {
		font-family: Jost;
		font-style: normal;
		font-weight: bold;
		font-size: 1rem;
		line-height: 1.4375rem;
		text-align: left;
	}
	.outros {
		font-family: Jost;
		font-weight: normal;
		font-size: 0.875rem;
		line-height: 1.25rem;
		margin-right: 0.5rem;
	}
`;

export const FloaterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colors.background};
	border-radius: 4px;
	color: ${colors.white};
	width: fit-content;
	padding: 0.4375rem 0.75rem 0.75rem 1rem;
`;

export const FloaterRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	font-family: Jost;
	font-style: normal;
	font-size: 0.875rem;

	.percentage {
		color: ${colors.primaryBlue};
	}
`;

export const Item = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	align-content: center;
	.outros {
		margin-right: 0.25rem;
	}
`;

export const ItemType = styled.span`
	font-family: Jost;
	font-style: normal;
	font-weight: 300;
	font-size: 1rem;
`;
