import styled from 'styled-components';
import { colors } from '../../assets/colors';

export const Container = styled.div`
	display: grid;
	gap: 1.25rem;

	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	width: 100%;
	grid-template-areas: ${({ ticketMedioGrid }) =>
		!ticketMedioGrid
			? `'div1 div1 div3 div3' 
			  'div2 div5 div6 div7' 
			  'div12 div5 div6 div7' 
			  'div8 div8 div4 div4' 
			  'div8 div8 div4 div4' 
			  'div10 div10 div11 div11' 
			  'div10 div10 div11 div11'`
			: `
				'div1 div1 div3 div3' 
			  'div2 div5 div6 div7' 
			  'div2 div5 div6 div7' 
			  'div8 div8 div4 div4' 
			  'div8 div8 div4 div4' 
			  'div10 div10 div11 div11' 
			  'div10 div10 div11 div11'`};

	/* 'div1 div1 div3 div4'
		'div5 div6 div7 div4'
		'div8 div8 div11 div11'
		'div10 div10  . .'; */
	/* 	@media all and (max-width: 1350px) {
		grid-template-areas:
			'div1 div2 div3'
			'div5 div4 div6'
			'div7 div4 div8'
			'div9 div9 div9'
			'div10 div10 div10'
			'div11 div11 div11';
	}
 */
	/* 	@media all and (max-width: 1400px) {
		grid-template-areas:
			'div1 div2'
			'div3 div4'
			'div7 div4'
			'div5 div6'
			'div8 div8'
			'div9 div9'
			'div10 div10'
			'div11 div11';
	}
 */
	/* 	@media (max-width: 800px) {
		grid-template-areas:
			'div1'
			'div2'
			'div3'
			'div4'
			'div4'
			'div5'
			'div6'
			'div7'
			'div8'
			'div9'
			'div10';
	} */
	#transactions-number {
		grid-area: div1;
	}

	#average-mi {
		grid-area: div2;
	}
	#total-amount {
		grid-area: div3;
	}
	#pie-chart {
		grid-area: div4;
		align-items: center;
	}
	#payment-methods {
		grid-area: div5;
	}
	#card-brands {
		grid-area: div6;
	}
	#refuse-reasons {
		grid-area: div7;
	}
	#installments-chart {
		grid-area: div8;
		padding: 5px;
	}
	#three-pie-charts {
		grid-area: div9;
	}
	#volume-chart {
		grid-area: div10;
	}

	#market-types-chart {
		grid-area: div11;
	}
	#average-me {
		grid-area: div12;
	}
	#average-ticket {
		grid-area: div2;
	}

	#transactions-number,
	#average-mi,
	#average-me,
	#total-amount,
	#pie-chart,
	#payment-methods,
	#card-brands,
	#refuse-reasons,
	#installments-chart,
	#three-pie-charts,
	#market-types-chart,
	#average-ticket,
	#volume-chart {
		color: ${colors.white};
		background-color: ${colors.darkGray};
		border-radius: 15px;
		height: 100%;
		width: auto;
	}
`;

export const NoContentContainer = styled.div`
	display: flex;
	position: relative;
	justify-content: flex-end;
	width: 100%;

	.nenhum-registro {
		height: 43rem;
		width: 90rem;
		user-select: none;
		justify-content: flex-end;
	}
`;

export const NoContentMessage = styled.div`
	position: absolute;
	top: 10%;
	left: 10%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: left;
`;

export const NoContentTitle = styled.h2`
	font-weight: bold;
	font-size: 4rem;
	line-height: 5.1875rem;
	text-align: center;
	color: ${colors.primaryBlue};
	padding-bottom: 2rem;
`;

export const NoContentText = styled.p`
	font-size: 1.5rem;
	line-height: 1.9375rem;
	color: ${colors.offWhite};
`;

export const CenterLoader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 300px;
`;

export const ContainerAlert = styled.div`
	margin-top: 2.5rem;
`;
