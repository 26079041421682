/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import { colors } from '../../../assets';

export const Sidemenu = styled.div`
	display: flex;
	position: fixed;
	overflow: hidden;
	height: 100vh; /* important */
	flex-direction: column;
	border-radius: 0px 0.9375rem 0.9375rem 0px;
	width: ${({ isSideMenuOpen }) => (isSideMenuOpen ? '21.25rem' : '7rem')};
	min-width: 6.25rem;
	border: 0.125rem solid ${colors.darkGray};
	background-color: ${colors.background};
	transition: all 0.5s ease-in-out;
	span {
		display: ${({ isSideMenuOpen }) => (isSideMenuOpen ? 'flex' : 'none')};
		padding-left: 1.25rem;
		transition: all 0.5s ease-in-out;
	}

	.selected {
		width: ${({ isSideMenuOpen }) => (isSideMenuOpen ? '16.25rem' : '100%')};
		margin-left: ${({ isSideMenuOpen }) => (isSideMenuOpen ? '2.125rem' : '0')};
		padding-left: ${({ isSideMenuOpen }) =>
			isSideMenuOpen ? '0.875rem' : '0'};
		background-color: ${colors.primaryBlue} !important;
		transition: all 0.5s ease-in-out;
		svg {
			filter: brightness(0) saturate(100%) invert(17%) sepia(6%) saturate(576%)
				hue-rotate(131deg) brightness(95%) contrast(92%)
				drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		}
		span {
			color: ${colors.darkGray};
			filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		}
	}
`;

export const NavItem = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${({ isSideMenuOpen }) =>
		isSideMenuOpen ? '0' : '1.25rem 1.25rem 0 1.25rem'};
	user-select: none;
	overflow-y: auto;
	overflow-x: hidden;
	transition: all 0.5s linear;

	svg {
		width: 2rem;
		height: 2rem;
	}
`;
