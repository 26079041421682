import React from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Legend,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	Label,
} from 'recharts';
import { formatarParaReais, formatDate } from '../../../../../utils';
import { ChartSubtitle } from '../../../..';
import { colors } from '../../../../../assets';

import * as S from './styled-transaction-bar-chart';

export default function TransactionBarChart({ data }) {
	const sortingDate = (array) => {
		return [...array].sort((a, b) =>
			Number(a.date) > Number(b.date) ? 1 : -1
		);
	};

	const dataLabel = data.map((value) => {
		return {
			status: value.status,
			transactionValue: value.transactionValue,
			date: formatDate(value.date),
		};
	});

	const dataOrder = sortingDate(dataLabel);

	const formatingData = (array, prevDate) => {
		const paidFilter = array.filter(
			(item) => item.status === 'paid' && item.date === prevDate
		);
		const refusedFilter = array.filter(
			(item) => item.status === 'refused' && item.date === prevDate
		);
		const refundedFilter = array.filter(
			(item) => item.status === 'refunded' && item.date === prevDate
		);
		const waitingPaymentFilter = array.filter(
			(item) => item.status === 'waitingPayment' && item.date === prevDate
		);
		const pendingReviewFilter = array.filter(
			(item) => item.status === 'pendingReview' && item.date === prevDate
		);
		const pendingRefundFilter = array.filter(
			(item) => item.status === 'pendingRefund' && item.date === prevDate
		);
		const chargedbackFilter = array.filter(
			(item) => item.status === 'chargedback' && item.date === prevDate
		);
		const authorizedFilter = array.filter(
			(item) => item.status === 'authorized' && item.date === prevDate
		);
		const processingFilter = array.filter(
			(item) => item.status === 'processing' && item.date === prevDate
		);

		// o reduceDataByDate recebe o array e compara os valores de date que forem iguais
		// e por fim soma os valores de transactionValue

		const reduceDataByDate = (arrayFiltred) =>
			Object.values(
				arrayFiltred.reduce(
					(acc, { date, transactionValue }) => (
						((acc[date] = acc[date] || {
							date,

							transactionValue: 0,
							// eslint-disable-next-line no-sequences
						}).transactionValue += transactionValue),
						acc
					),
					{}
				)
			);

		return {
			paid: reduceDataByDate(paidFilter),
			refused: reduceDataByDate(refusedFilter),
			refunded: reduceDataByDate(refundedFilter),
			waitingPayment: reduceDataByDate(waitingPaymentFilter),
			pendingReview: reduceDataByDate(pendingReviewFilter),
			pendingRefund: reduceDataByDate(pendingRefundFilter),
			chargedback: reduceDataByDate(chargedbackFilter),
			authorized: reduceDataByDate(authorizedFilter),
			processing: reduceDataByDate(processingFilter),
		};
	};

	// o graphData recebe um array com os dados formatados
	// útiliza o método reduce para comparar os valores de date dentro de orderData
	// e atribuir os dados remanecentes das datas em: data: formatingData

	const graphData = Object.values(
		dataOrder.reduce(
			(acc, { date }) => (
				(acc[date] = acc[date] || {
					date,
					data: formatingData(dataOrder, date),
					// eslint-disable-next-line no-sequences
				}),
				acc
			),
			{}
		)
	);

	const graphColor = (color) => {
		const colorsGraph = {
			Paga: 'green',
			Recusada: 'red',
			Estornada: 'yellow',
			'Estorno pendente': 'purple',
			'Aguardando pagamento': 'white',
			Processando: 'darkBlue',
			chargeback: 'pink',
			Autorizada: 'lightBlue',
			'Revisão pendente': 'orange',
		};

		return colorsGraph[color];
	};

	const tickYFormater = (tick) => {
		if (tick > 100000) {
			return ` ${tick / 100000} mil`;
		}
		return formatarParaReais(tick / 100);
	};

	const renderCustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<S.TooltipContainer>
					<S.Description>
						{label}
						{payload.map((item, index) => {
							return (
								<S.Value>
									<ChartSubtitle
										key={index}
										title={item.name}
										color={graphColor(item.name)}
									/>
									{`R$ ${formatarParaReais(item.value / 100)}`}
								</S.Value>
							);
						})}
					</S.Description>
				</S.TooltipContainer>
			);
		}
		return null;
	};

	return (
		<S.Container>
			<ResponsiveContainer debounce={1} aspect={5} width="100%">
				<BarChart
					data={graphData}
					barCategoryGap="35%"
					margin={{
						top: 0,
						right: 30,
						left: 20,
						bottom: 20,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" vertical={false} />
					<XAxis
						dataKey="date"
						tick={{ stroke: colors.offWhite, fontSize: 12 }}
					/>
					<YAxis
						width={80}
						tickFormatter={tickYFormater}
						tick={{
							stroke: colors.offWhite,
							fontSize: 12,
						}}
						tickLine={false}
						tickSize={8}
						axisLine={false}
					>
						<Label
							angle={-90}
							position="left"
							fill={colors.white}
							stroke={colors.white}
							strokeWidth={0.5}
							style={{
								fontSize: '0.75rem',
								color: colors.white,
								textAnchor: 'middle',
							}}
						>
							Valor em Reais (R$)
						</Label>
					</YAxis>
					<Tooltip content={renderCustomTooltip} separator={' '} />
					<Legend />
					<Bar
						dataKey="data.paid[0].transactionValue"
						stackId="a"
						fill={colors.green}
						name="Paga"
						legendType="none"
					/>
					<Bar
						dataKey="data.refunded[0].transactionValue"
						stackId="a"
						fill={colors.yellow}
						name="Estornada"
						legendType="none"
					/>
					<Bar
						dataKey="data.refused[0].transactionValue"
						stackId="a"
						fill={colors.red}
						name="Recusada"
						legendType="none"
					/>
					<Bar
						dataKey="data.pendingRefund[0].transactionValue"
						stackId="a"
						fill={colors.violet}
						name="Estorno pendente"
						legendType="none"
					/>
					<Bar
						dataKey="data.waitingPayment[0].transactionValue"
						stackId="a"
						fill={colors.offWhite}
						name="Aguardando pagamento"
						legendType="none"
					/>
					<Bar
						dataKey="data.processing[0].transactionValue"
						stackId="a"
						fill={colors.primaryBlue}
						name="Processando"
						legendType="none"
					/>
					<Bar
						dataKey="data.chargedback[0].transactionValue"
						stackId="a"
						fill={colors.pink}
						name="Chargedback"
						legendType="none"
					/>
					<Bar
						dataKey="data.authorized[0].transactionValue"
						stackId="a"
						fill={colors.cyan}
						name="Autorizada"
						legendType="none"
					/>
					<Bar
						dataKey="data.pendingReview[0].transactionValue"
						stackId="a"
						fill={colors.orange}
						name="Revisão pendente"
						legendType="none"
					/>
				</BarChart>
			</ResponsiveContainer>
		</S.Container>
	);
}
