import React from 'react';
import { ButtonCustom, UnderlineInput, SelectReact } from '../../..';
import { useRecipients } from '../../../../hooks';
import { justNumbers, optionsTipoConta } from '../../../../utils';

import * as s from './styled-conta-bancaria';

const FormGroupTitle = ({ title, subtitle }) => (
	<s.Header>
		<s.Title>{title}</s.Title>
		<s.Subtitle>{subtitle}</s.Subtitle>
	</s.Header>
);

const ContaBancaria = () => {
	const {
		step,
		setStep,
		documento,
		companyName,
		setCompanyName,
		bankCode,
		agencia,
		agenciaDv,
		conta,
		type,
		contaDv,
		banks,
		bankAccountId,
		setBankAccountId,
		setBankCode,
		setAgencia,
		setAgenciaDv,
		setConta,
		setContaDv,
		setType,
		recipientBankAccounts,
		isRecipientBankAccountsLoading,
		isBankOptionsLoading,
		opcaoConta,
		setOpcaoConta,
		setIsCancelarCriacaoOpen,
	} = useRecipients();

	const isDisabledAdicionarConta =
		!bankCode || !agencia || !conta || !contaDv || !type || !companyName;
	const isDabledSelecionarConta = !bankAccountId;
	const isTipoPessoaFisica = opcaoConta === 'fisica';
	const existemContas = recipientBankAccounts.length > 0;

	const ButtonTabs = () => (
		<s.ButtonsTabs id="tabs" selected={opcaoConta}>
			<s.Button
				id="inicio"
				onClick={() => setOpcaoConta('selecionar-conta')}
				selected={opcaoConta === 'selecionar-conta'}
			>
				SELECIONAR UMA CONTA
			</s.Button>
			<s.Button
				id="final"
				onClick={() => setOpcaoConta('adicionar-conta')}
				selected={opcaoConta === 'adicionar-conta'}
			>
				ADICIONAR UMA CONTA
			</s.Button>
		</s.ButtonsTabs>
	);

	return (
		<s.Container>
			<FormGroupTitle
				title="CONTA BANCÁRIA"
				subtitle="Adicione uma conta bancária para esse recebedor"
			/>

			{existemContas ? <ButtonTabs /> : null}
			{opcaoConta === 'adicionar-conta' ? (
				<s.InputGroup>
					<UnderlineInput
						width={isTipoPessoaFisica ? '10.375rem' : '12rem'}
						label="Documento do titular"
						placeholder={documento}
						readOnly
					/>
					<SelectReact
						width="22rem"
						className="custom-select-input"
						label="Banco *"
						placeholder="Digite o nome ou código do banco"
						value={bankCode}
						isLoading={isBankOptionsLoading}
						options={banks}
						onChange={(e) => {
							setBankCode(e);
						}}
					/>
				</s.InputGroup>
			) : (
				<s.FormGroup>
					<s.InputGroup>
						<UnderlineInput
							width={isTipoPessoaFisica ? '10.375rem' : '11.75rem'}
							label="Documento do titular"
							placeholder={documento}
							readOnly
						/>

						<SelectReact
							width="30rem"
							className="custom-select-input"
							label="Selecione uma conta bancária"
							placeholder="Selecione uma conta bancária"
							isLoading={isRecipientBankAccountsLoading}
							value={bankAccountId}
							options={recipientBankAccounts}
							onChange={(e) => {
								setBankAccountId(e);
							}}
						/>
					</s.InputGroup>
				</s.FormGroup>
			)}

			{opcaoConta === 'adicionar-conta' && (
				<s.FormGroup>
					<s.RowsGroup>
						<s.Row>
							<UnderlineInput
								label="Agência *"
								placeholder="Digite o número da agência"
								maxLength={4}
								value={agencia}
								onChange={(e) => {
									e.target.value = justNumbers(e.target.value);
									setAgencia(e.target.value);
								}}
							/>
							<UnderlineInput
								label="Dígito da agência (Opcional)"
								placeholder="Digite o código verificador da agência"
								width="13.625rem"
								maxLength={1}
								value={agenciaDv}
								onChange={(e) => {
									e.target.value = justNumbers(e.target.value);
									setAgenciaDv(e.target.value);
								}}
							/>
						</s.Row>
						<s.Row>
							<UnderlineInput
								label="Conta *"
								placeholder="Digite o número da conta"
								maxLength={13}
								value={conta}
								onChange={(e) => {
									e.target.value = justNumbers(e.target.value);
									setConta(e.target.value);
								}}
							/>

							<UnderlineInput
								label="Dígito da conta *"
								placeholder="Digite o código verificador da conta"
								width="13.625rem"
								maxLength={2}
								value={contaDv}
								onChange={(e) => {
									e.target.value = justNumbers(e.target.value);
									setContaDv(e.target.value);
								}}
							/>
						</s.Row>
					</s.RowsGroup>

					<s.InputGroup>
						<SelectReact
							label="Tipo de conta bancária *"
							className="custom-select-input"
							width="20rem"
							options={optionsTipoConta}
							value={type.label}
							onChange={(e) => {
								setType(e);
							}}
							isSearchable={false}
						/>
						<UnderlineInput
							label="Nome da conta bancária *"
							placeholder="Dê um nome para esta conta"
							width="16.6875rem"
							value={companyName}
							onChange={(e) => {
								setCompanyName(e.target.value);
							}}
						/>
					</s.InputGroup>
				</s.FormGroup>
			)}

			<s.ButtonGroup>
				<ButtonCustom
					outlined
					theme="gray"
					onClick={() => setIsCancelarCriacaoOpen(true)}
				>
					Cancelar
				</ButtonCustom>
				<s.RightButtons>
					<ButtonCustom
						outlined
						theme="green"
						className="botao-voltar"
						onClick={() => {
							setStep(step - 1);
						}}
					>
						Voltar
					</ButtonCustom>
					<ButtonCustom
						theme="green"
						onClick={() => setStep(step + 1)}
						disabled={
							opcaoConta === 'selecionar-conta'
								? isDabledSelecionarConta
								: isDisabledAdicionarConta
						}
					>
						Continuar
					</ButtonCustom>
				</s.RightButtons>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default ContaBancaria;
