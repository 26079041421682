/* eslint-disable no-unused-vars */
import React from 'react';
import { Icons, colors } from '../../../../assets/';

import * as s from './styled-estorno-steps';

const Estornosteps = ({ step }) => {
	const steps = ['identificação', 'confirmação', 'conclusão'];
	return (
		<s.Container>
			{steps.map((currentStep, index) => {
				const ultimoItemMap = index === steps.length - 1;
				const isCurrentStep = step > index;
				return (
					<>
						<s.Step key={index} index={index}>
							<s.StepText className={isCurrentStep && 'active'}>
								{`${index + 1}. ${currentStep.toUpperCase()}`}
							</s.StepText>
						</s.Step>
						{!ultimoItemMap && (
							<s.Icon index={index}>
								<Icons.ChevronThin
									fill={step > index + 1 && colors.primaryBlue}
								/>
							</s.Icon>
						)}
					</>
				);
			})}
		</s.Container>
	);
};

export default Estornosteps;
