import React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as s from './styled-loading-rectangle';
import 'react-loading-skeleton/dist/skeleton.css';
import { colors } from '../../../../assets';

const LoadingRectangle = ({ style, color, id }) => (
	<s.Container className="loading-rectangle" id={id} style={style}>
		<Skeleton baseColor={color || colors.loading} />
	</s.Container>
);

export default LoadingRectangle;
