/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { format } from 'date-fns';
import * as React from 'react';
import { colors } from '../../../../assets/colors';
import {
	formatarParaReais,
	translateStatus,
	formatCardNumber,
	renderFlag,
	formatDoc,
	formatNumberDDD,
} from '../../../../utils';

import * as S from './styled-detalhes-transacao';

export const TemplateDetalhesTransacao = React.forwardRef(({ dados }, ref) => {
	const metadata = dados?.metadata;
	const items = dados?.items;
	const splitRules = dados?.splitRules;
	const history = dados?.operations;

	const renderInstallments = (transactonInstallments) => {
		if (transactonInstallments === 1) {
			return 'À vista';
		}
		return `Parcelado ${transactonInstallments}x`;
	};

	const renderCard = () => (
		<S.DadosCartao>
			<S.CartaoTitle>Dados do cartão</S.CartaoTitle>

			<S.CartaoContent>
				<S.CartaoContentItem>
					<S.CartaoContentItemTitle>Número:</S.CartaoContentItemTitle>
					<S.CartaoContentItemContent>
						{dados &&
							formatCardNumber(dados.cardFirstDigits, dados.cardLastDigits)}
					</S.CartaoContentItemContent>
				</S.CartaoContentItem>

				<S.CartaoContentItem>
					<S.CartaoContentItemTitle>Portador:</S.CartaoContentItemTitle>
					<S.CartaoContentItemContent>
						{dados?.cardHolderName}
					</S.CartaoContentItemContent>
				</S.CartaoContentItem>

				<S.CartaoContentItem>
					<S.CartaoContentItemTitle>Bandeira:</S.CartaoContentItemTitle>
					<S.CartaoContentItemContent>
						{renderFlag(dados?.cardBrand)}
					</S.CartaoContentItemContent>
				</S.CartaoContentItem>
			</S.CartaoContent>
		</S.DadosCartao>
	);

	const renderValues = () => {
		function valorCapturado() {
			if (dados?.grossValue && dados.grossValue > 0)
				return `+ ${formatarParaReais(dados.grossValue)}`;
			if (dados?.grossValue === 0)
				return `${formatarParaReais(dados.grossValue)}`;
			if (dados?.grossValue < 0)
				return `- ${formatarParaReais(dados.grossValue)}`;
			return '-';
		}

		function valorSaida() {
			return `${
				dados?.discountsValue?.total > 0
					? '+'
					: dados?.discountsValue?.total < 0
					? '-'
					: ''
			} ${
				dados?.discountsValue?.total === 0
					? formatarParaReais(dados?.discountsValue?.total / 100, true)
					: formatarParaReais((dados?.discountsValue?.total * -1) / 100, true)
			}`;
		}

		function valorLiquido() {
			return `${dados?.netValue > 0 ? '+' : ''} ${formatarParaReais(
				dados?.netValue / 100,
				true
			)}`;
		}

		return (
			<S.ValuesContainer>
				<S.ValuesDiv>
					<S.ValuesTitle>Valor capturado</S.ValuesTitle>
					<S.Values>{valorCapturado()}</S.Values>
				</S.ValuesDiv>

				<S.ValuesDiv>
					<S.ValuesTitle>Total de saídas</S.ValuesTitle>
					<S.Values>{valorSaida()}</S.Values>
				</S.ValuesDiv>

				<S.ValuesDiv>
					<S.ValuesTitle>Valor líquido</S.ValuesTitle>
					<S.Values>{valorLiquido()}</S.Values>
				</S.ValuesDiv>
			</S.ValuesContainer>
		);
	};

	const renderClientDetails = () => (
		<S.ClientContainer>
			<S.Title>Detalhes do cliente</S.Title>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Nome do cliente</h5>
					</S.ColTitle>
					<span>{dados?.customer?.name ? dados?.customer.name : '-'}</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>CNPJ/CPF</h5>
					</S.ColTitle>
					<span>
						{dados?.customer?.external_id
							? formatDoc(dados?.customer.external_id)
							: '-'}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Data de nascimento</h5>
					</S.ColTitle>
					<span>
						{dados?.customer?.born_at ? dados.customer.born_at : '--/--/----'}
					</span>
				</S.Col>
			</S.Row>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Telefone</h5>
					</S.ColTitle>
					<span>
						{dados?.customer?.phone_numbers
							? formatNumberDDD(dados?.customer?.phone_numbers[0])
							: '-'}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>E-mail</h5>
					</S.ColTitle>
					<span>{dados?.customer?.email ? dados.customer.email : '-'}</span>
				</S.Col>
			</S.Row>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Rua</h5>
					</S.ColTitle>
					<span>
						{dados?.billing?.address?.street
							? dados.billing.address.street
							: '-'}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<div style={{ display: 'flex' }}>
							<h5>Nº</h5>
							<h5>Complemento</h5>
						</div>
					</S.ColTitle>
					<S.ColAddress>
						<span>
							{dados?.billing?.address?.street_number
								? dados.billing.address.street_number
								: '-'}
						</span>
						<span>
							{dados?.billing?.address?.complementary
								? dados.billing.address.complementary
								: '-'}
						</span>
					</S.ColAddress>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Bairro</h5>
					</S.ColTitle>
					<span>
						{dados?.billing?.address?.neighborhood
							? dados.billing.address.neighborhood
							: '-'}
					</span>
				</S.Col>
			</S.Row>
			<S.Row>
				<S.Col>
					<S.ColTitle>
						<h5>Cidade</h5>
					</S.ColTitle>
					<span>
						{dados?.billing?.address?.city ? dados?.billing?.address.city : '-'}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>Estado</h5>
					</S.ColTitle>
					<span>
						{dados?.billing?.address?.state
							? dados?.billing?.address.state
							: '-'}
					</span>
				</S.Col>
				<S.Col>
					<S.ColTitle>
						<h5>CEP</h5>
					</S.ColTitle>
					<span>
						{dados?.billing?.address?.zipcode
							? dados?.billing?.address.zipcode
							: '-'}
					</span>
				</S.Col>
			</S.Row>
		</S.ClientContainer>
	);

	const renderMetadata = () => (
		<S.ContentContainer>
			<S.Title>Metadata</S.Title>
			<S.Row>
				<S.Col2>
					<span>
						<b>cliente: </b>
						{metadata?.cliente}
					</span>
					<span>
						<b>comprovante: </b>
						{metadata?.comprovante}
					</span>
					<span>
						<b>documento: </b> {metadata?.documentoContractor}
					</span>
				</S.Col2>
				<S.Col2>
					<span>
						<b>Taxa: </b>
						{metadata?.mdr}
					</span>
					<span>
						<b>transação: </b>
						{metadata?.transacao}
					</span>
				</S.Col2>
			</S.Row>
		</S.ContentContainer>
	);

	const Item = (props) => {
		const { item, index } = props;
		const { id, title, unit_price, quantity, category, tangible, venue, date } =
			item;
		return (
			<S.ContentContainer style={{ pageBreakAfter: 'always' }}>
				<h3>{`${index + 1}. ${title}`}</h3>
				<S.Content>
					<S.Col2>
						<span>
							<b>ID do produto: </b> {id ?? '-'}
						</span>
						<span>
							<b>Preço da unidade: </b>
							{unit_price ? formatarParaReais(unit_price / 100, true) : '-'}
						</span>
						<span>
							<b>Quantidade: </b>
							{quantity ?? '-'}
						</span>
						<span>
							<b>Bem físico: </b>
							{tangible ? 'sim' : 'não'}
						</span>
					</S.Col2>
					<S.Col2>
						<span>
							<b>Categoria: </b>
							{category ?? '-'}
						</span>
						<span>
							<b>Data do Evento: </b>
							{date ?? '-'}
						</span>
						<span>
							<b>Local do evento: </b>
							{venue ?? '-'}
						</span>
					</S.Col2>
				</S.Content>
			</S.ContentContainer>
		);
	};

	const renderItems = () => (
		<S.ContentContainer>
			<S.Title>Itens da transação</S.Title>
			{dados &&
				items?.map((item, index) => (
					<Item index={index} key={item.id} item={item} />
				))}
		</S.ContentContainer>
	);

	const renderTransactionDetails = () => (
		<S.ContentContainer>
			<S.Title>Detalhes da transação</S.Title>
			<S.Content>
				<S.Col2>
					<S.Row>
						<h3>Operadora do cartão:&nbsp;</h3>
						<span>{dados?.acquirerName ? dados.acquirerName : '-'}</span>
					</S.Row>
					<S.Row>
						<h3>Resposta da operadora:&nbsp;</h3>
						<span>
							{dados?.acquirerResponseCode ? dados.acquirerResponseCode : '-'}
						</span>
					</S.Row>
					<S.Row>
						<h3>Código de autorização:&nbsp;</h3>
						<span>
							{dados?.authorizationCode ? dados.authorizationCode : '-'}
						</span>
					</S.Row>
					<S.Row>
						<h3>Método de captura:&nbsp;</h3>
						<span>{dados?.captureMethod ? dados.captureMethod : '-'}</span>
					</S.Row>
				</S.Col2>
				<S.Col2>
					<S.Row>
						<h3>NSU: &nbsp;</h3>
						<span>{dados?.nsu ? dados.nsu : '-'}</span>
					</S.Row>
					<S.Row>
						<h3>Descrição na fatura: &nbsp;</h3>
						<span>{dados?.softDescriptor ? dados.softDescriptor : '-'}</span>
					</S.Row>
					<S.Row>
						<h3>Id da assinatura:&nbsp;</h3>
						<span>{dados?.orderId ? dados.orderId : '-'}</span>
					</S.Row>
					<S.Row>
						<h3>TID (ID da transação):&nbsp;</h3>
						<span>{dados?.tid ? dados.tid : '-'}</span>
					</S.Row>
				</S.Col2>
			</S.Content>
		</S.ContentContainer>
	);

	const Receivers = (props) => {
		const { receiver, index } = props;
		const {
			name,
			mdr,
			grossValue,
			discountsValue,
			taxValue,
			anticipationValue,
			data,
		} = receiver;

		const reversedInstallments = [...data].reverse();
		return (
			<S.ReceiverContainer>
				<S.ReceiverHeader>
					<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<S.LeftReceiverHeader>
							<span className="title">{`${index + 1}. ${name}`}</span>
							<span>Responsável por: Taxa</span>
							<div className="status">
								<span>Status da próxima parcela:</span>
								{translateStatus(receiver?.nextInstallment)}
							</div>
						</S.LeftReceiverHeader>
						<S.RightReceiverHeader>
							<S.Row>
								<S.ReceiversCol style={{ textAlign: 'right' }}>
									<div style={{ marginBottom: '0.9rem' }}>
										<p>Total bruto(R$)</p>
									</div>
									<div style={{ marginBottom: '0.65rem' }}>
										<p>Total de saídas(R$)</p>
									</div>
									<div>
										<p>Total líquido(R$)</p>
									</div>
								</S.ReceiversCol>
								<S.ReceiversCol
									style={{ textAlign: 'right', justifyContent: 'right' }}
								>
									<div
										style={{ paddingBottom: '0.5625rem', marginTop: '-0.3rem' }}
									>
										<h2>{formatarParaReais(grossValue / 100)} </h2>
									</div>
									<div style={{ paddingBottom: '0.375rem' }}>
										<h3>{formatarParaReais(discountsValue / 100)}</h3>
									</div>
									<div>
										<h3>{formatarParaReais((grossValue - taxValue) / 100)}</h3>
									</div>
								</S.ReceiversCol>
							</S.Row>
						</S.RightReceiverHeader>
					</div>
				</S.ReceiverHeader>
				<S.Installments>
					<table>
						<thead>
							<tr>
								<th>Parcela</th>
								<th>Status da Parcela</th>
								<th>Data</th>
								<th>Total Bruto</th>
								<th>Taxas</th>
								<th>Serviço de Antecipação</th>
								<th>Estorno ou Chargeback</th>
							</tr>
						</thead>
						<tbody>
							{reversedInstallments.map((item) => (
								<tr key={item.id}>
									<td>{item.installment}</td>
									<td className="status-cell">
										{item.status === 'waiting_funds'
											? 'Aguardando pgto'
											: translateStatus(item.status)}
									</td>
									<td>{format(new Date(item.paymentDate), 'dd/MM/yyyy')}</td>
									<td>{formatarParaReais(item.amount / 100, true)}</td>
									<td>{formatarParaReais(item.fee / 100, true)}</td>
									<td>{formatarParaReais(item.anticipationFee / 100, true)}</td>
									<td>{item.chargeback}</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={3}>TOTAIS</td>
								<td>{formatarParaReais(grossValue / 100, true)}</td>
								<td>
									{formatarParaReais(
										discountsValue >= 0
											? discountsValue / 100
											: (discountsValue * -1) / 100,
										true
									)}
								</td>
								<td>{formatarParaReais(anticipationValue / 100, true)}</td>
							</tr>
						</tfoot>
					</table>
				</S.Installments>
			</S.ReceiverContainer>
		);
	};

	const renderReceivers = () => {
		const mapReceivers = (obj) => {
			const elements = [];
			// eslint-disable-next-line no-restricted-syntax
			for (const [key, value] of Object.entries(obj)) {
				elements.push({ key, value });
			}

			return elements?.map((item, index) => (
				<Receivers key={item.key} index={index} receiver={item.value} />
			));
		};
		return (
			<S.MainReceiverContainer>
				<S.Title>Divisão entre recebedores</S.Title>
				<div className="subtitle">
					<div>
						<span>{splitRules?.recipients} recebedores</span>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							textAlign: 'right',
						}}
					>
						<span style={{ fontSize: '12px' }}>Valor total bruto (R$)</span>
						<h2 style={{ fontSize: '24px', fontWeight: '700' }}>
							{formatarParaReais(splitRules?.total / 100, true)}
						</h2>
					</div>
				</div>
				<S.Infos>{splitRules && mapReceivers(splitRules?.data)}</S.Infos>
			</S.MainReceiverContainer>
		);
	};

	const Operations = ({ operations, index }) => {
		const formatType = (type) => {
			if (type === 'authorize' && operations?.status === 'success') {
				return 'Autorizada';
			}
			if (type === 'authorize' && operations?.status === 'failed') {
				return 'Autorização';
			}
			if (type === 'capture') {
				return 'Capturada';
			}
			if (type === 'refund') {
				return 'Estornada';
			}
			if (type === 'conciliate') {
				return 'Chargeback Recebido';
			}
		};

		return (
			<S.ReceiverContainer>
				<S.Wrapper>
					<S.Index>{`${index + 1}`}</S.Index>
					<S.OperationContent>
						<h4 className="title">{`${formatType(operations?.type)}`}</h4>
						<div className="content">
							{operations?.status === 'failed' && (
								<span style={{ paddingTop: '10px' }} className>
									Falhou
								</span>
							)}
						</div>

						<div className="content">
							<span>{`${format(
								new Date(operations?.date),
								'dd/MM/yyyy'
							)} ${format(new Date(operations?.date), 'HH:mm')}`}</span>
						</div>

						{operations?.amount && (
							<div className="content">
								<span>
									Valor: &nbsp;
									<b>{formatarParaReais(operations.amount / 100, true)}</b>
								</span>
							</div>
						)}

						{operations?.tid && (
							<div className="content">
								<span>{`TID: ${operations.tid}`}</span>
							</div>
						)}
					</S.OperationContent>
				</S.Wrapper>
			</S.ReceiverContainer>
		);
	};

	const History = () => {
		const historyReversed = history && [...history]?.reverse();

		return (
			<S.MainHistoryContainer>
				<S.HistoryTitle>Histórico da transação</S.HistoryTitle>
				<S.HistoryInfos>
					{historyReversed &&
						historyReversed.map((item, index) => (
							<Operations key={item.id} index={index} operations={item} />
						))}
				</S.HistoryInfos>
			</S.MainHistoryContainer>
		);
	};

	return (
		<S.Container ref={ref} id="detalhes-transacao">
			<S.Header>
				<S.HeaderColumn>
					<S.HeaderColumnTitle>Transação</S.HeaderColumnTitle>
					<S.HeaderColumnContent
						className="transaction-id"
						style={{ fontSize: '24px' }}
					>
						{`#${dados?.id}`}
					</S.HeaderColumnContent>
				</S.HeaderColumn>
				<S.HeaderColumn>
					<S.HeaderColumnTitle>Status</S.HeaderColumnTitle>
					<S.HeaderColumnContent>
						{translateStatus(dados?.status)}
					</S.HeaderColumnContent>
				</S.HeaderColumn>
				<S.HeaderColumn>
					<S.HeaderColumnTitle>Pagamento</S.HeaderColumnTitle>
					<S.HeaderColumnContent>
						{renderInstallments(dados?.installments)}
					</S.HeaderColumnContent>
				</S.HeaderColumn>
				<S.HeaderColumn>
					<S.HeaderColumnTitle>Valor Autorizado</S.HeaderColumnTitle>
					<S.HeaderColumnContent>
						{formatarParaReais(dados?.authorizedValue / 100, true)}
					</S.HeaderColumnContent>
				</S.HeaderColumn>
			</S.Header>
			{/* renderiza os dados do cartao */}
			{dados?.cardBrand && renderCard()}
			{/* renderiza a divisória */}
			<S.Divisoria>
				<svg>
					<rect id="verde" />
				</svg>
				<svg>
					<rect id="roxo" />
				</svg>
				<svg>
					<rect id="azul" />
				</svg>
			</S.Divisoria>
			{/* valores principais */}
			{dados && renderValues()}
			{/* Divisão de recebedores */}
			<div className="page-break">{dados && renderReceivers()}</div>
			{/* dados do cliente */}
			<div className="page-break" style={{ display: 'block' }}>
				{dados && renderClientDetails()}
			</div>
			{/* detalhes da transação */}
			<div className="page-break" style={{ display: 'block' }}>
				{dados && renderTransactionDetails()}
			</div>
			{/* itens */}
			<div style={{ display: 'block' }}>{dados && renderItems()}</div>
			{/* metadata */}
			{dados && renderMetadata()}
			{/* Histórico da transação */}
			{dados && <History />}
		</S.Container>
	);
});
