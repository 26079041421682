import styled from 'styled-components';

export const Container = styled.div`
	grid-area: ${(props) => (props.div ? props.div : 'none')};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	padding: 24px;
	background-color: white;
	border-radius: 4px;
	min-height: 95px;
	height: 100%;
	width: 100%;
	box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.07);

	/* div {
		display: flex !important;
		flex-direction: column;
		margin-right: 24px;

		h1 {
			color: #454550;
    	font-size: 14px;
			margin-bottom: 24px;
		}
		h2 {
			color: #454550;
    	font-size: 16px;
		}
	} */
`;

export const ButtonsTabs = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 37px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: #f2f2f3;
	width: 100%;
`;

export const Button = styled.button`
	border: none;
	background: none;
	cursor: pointer;
	display: inline-block;
	font-family: Lato, sans-serif;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.02em;
	margin-right: 16px;
	padding: 7px;
	position: relative;
	text-align: center;
	text-transform: none;

	&:hover {
		color: #8246d0;
		margin-top: 3px;
		border-bottom-width: 2px;
		border-bottom-style: solid;
		border-bottom-color: #8246d0;
	}
`;

export const CardsDiv = styled.div`
	display: flex;
	flex: 1;
	align-items: stretch;
	flex-wrap: wrap;
	list-style: none;
	margin-top: 5px;
`;

export const Card = styled.div`
	display: flex;
	flex: 1;
	background-color: #f2f2f3;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 24px;
	margin: 0 10px 20px 10px;
	min-width: 200px;

	h2 {
		font-size: 14px;
		color: ${(props) => props.color};
	}

	h1 {
		margin-top: 24px;
		margin-bottom: 14px;
		font-size: 24px;
		color: #454550;
		font-weight: 700;
	}
`;

export const ButtonFilter = styled.button`
	border: none;
	color: white;
	margin-left: 10px;
	background: #9c88ff;
	cursor: pointer;
	display: inline-block;
	font-family: Lato, sans-serif;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.02em;
	margin-right: 16px;
	padding: 7px;
	position: relative;
	text-align: center;
	text-transform: none;
`;

export const FilterTabs = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	width: 100%;
`;
