import React from 'react';
import { colors } from '../colors';

const CalendarIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="calendar-icon"
		width="25"
		height="25"
		viewBox="0 0 25 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || ''}</title>
		<g clipPath="url(#clip0_1474_4747)">
			<path
				d="M25 6.68795V9.17215H0V6.68795C0 5.78794 0.35694 4.92478 0.992343 4.28837C1.62775 3.65196 2.48954 3.29442 3.38813 3.29442H5.16446V1.07381C5.16446 0.794195 5.27536 0.526046 5.47277 0.328327C5.67017 0.130608 5.9379 0.0195312 6.21707 0.0195312H7.07237C7.35154 0.0195312 7.61927 0.130608 7.81668 0.328327C8.01408 0.526046 8.12498 0.794195 8.12498 1.07381V3.29442H17.6645V1.07381C17.6645 0.794195 17.7754 0.526046 17.9728 0.328327C18.1702 0.130608 18.4379 0.0195312 18.7171 0.0195312H19.5724C19.8515 0.0195312 20.1193 0.130608 20.3167 0.328327C20.5141 0.526046 20.625 0.794195 20.625 1.07381V3.29442H21.6118C22.5104 3.29442 23.3722 3.65196 24.0076 4.28837C24.643 4.92478 25 5.78794 25 6.68795Z"
				fill={fill || colors.primaryBlue}
			/>
			<path
				d="M25 11.1294V21.5999C25 22.5005 24.6432 23.3643 24.008 24.0018C23.3728 24.6393 22.511 24.9982 21.6118 25H3.38813C2.48893 24.9982 1.62716 24.6393 0.991942 24.0018C0.356727 23.3643 -1.6886e-06 22.5005 0 21.5999V11.1294H25Z"
				fill={fill || colors.primaryBlue}
			/>
		</g>
		<defs>
			<clipPath id="clip0_1474_4747">
				<rect width="25" height="25" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default CalendarIcon;
