import styled from 'styled-components';
import { colors } from '../../../assets';

export const Container = styled.div`
	padding: 0.3125rem;
	width: 15.31rem;

	h2 {
		font-size: 1rem;
		font-weight: 600;
		margin-bottom: 10px;
	}

	h3 {
		font-size: 1rem;
		font-weight: 500;
		margin: 10px 0;
	}

	h4 {
		font-size: 0.875rem;
		font-weight: 500;
		color: ${colors.lightGray};
		margin-top: 15px;
	}
`;

export const P = styled.p`
	font-size: 0.875rem;
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	color: ${({ success }) => success && colors.green};

	svg.checked-icon {
		margin-right: 5px;
		path {
			fill: ${({ success }) => success && colors.green};
		}
	}
`;

export const ColumnBar = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 15px;

	span {
		font-size: 0.875rem;
		color: ${colors.white};
	}
`;

export const Bar = styled.div`
	width: 100%;
	height: 3px;
	margin: 5px 0;
	background: ${({ progress = 0 }) => {
		let colorInit = colors.white;

		if (progress || progress === 0) {
			if (progress > 0 && progress < 30) colorInit = colors.red;
			else if (progress >= 30 && progress < 60) colorInit = colors.yellow;
			else if (progress >= 60 && progress <= 100) colorInit = colors.green;

			return `linear-gradient(to right, ${colorInit} 0%, ${colorInit} ${progress}%, ${
				colors.white
			} ${progress + 1}%, ${colors.white} ${100}%)`;
		}
	}};
`;

export const Span = styled.span`
	align-self: end;
	color: ${({ progress = 0 }) => {
		let color = colors.white;

		if (progress || progress === 0) {
			if (progress > 0 && progress < 30) color = colors.red;
			else if (progress >= 30 && progress < 60) color = colors.yellow;
			else if (progress >= 60 && progress <= 100) color = colors.green;
		}

		return `${color} !important`;
	}};
`;
