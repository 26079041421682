import React from 'react';
import { withRouter } from 'react-router';
import * as s from './styled-nav-link';
import { useSidemenu } from '../../../../hooks';

const NavLink = ({
	history,
	to,
	children,
	span,
	isSideMenuOpen,
	selected,
	onClick,
}) => {
	const { handleDeleteAnticipation } = useSidemenu();

	const handleClick = () => {
		if (localStorage.getItem('anticipation')) {
			handleDeleteAnticipation(onClick, to);
		} else {
			onClick();
			history.push(to);
		}
	};

	return (
		<s.Container
			isSideMenuOpen={isSideMenuOpen}
			selected={selected}
			className={selected && 'selected'}
			title={span}
			onClick={handleClick}
		>
			{children}
			<span>{span}</span>
		</s.Container>
	);
};

export default withRouter(NavLink);
