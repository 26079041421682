import styled from 'styled-components';

/* ======= MAIN CONTAINERS ======= */

export const Container = styled.div`
	display: grid;

	gap: 16px;
	padding: 0 16px 16px 16px;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-template-areas:
		'alert              alert              alert              alert     '
		'cardheader   			cardheader   			 cardheader  				cardheader'
		'smallcard1   			smallcard2   			 smallcard3  				smallcard4'
		'receiverscard  		receiverscard 		 receiverscard  		history		'
		'clientcard	 				clientcard   			 clientcard   				 	.			'
		'transactiondetails transactiondetails transactiondetails 		.			'
		'itemscard					itemscard					 itemscard  						.			'
		'metadata						metadata					 metadata								.			';

	@media all and (max-width: 1386px) {
		grid-template-areas:
			'alert              alert     '
			'cardheader 				cardheader'
			'smallcard1 				smallcard2'
			'smallcard3 				smallcard4'
			'receiverscard 			receiverscard'
			'clientcard 				clientcard'
			'transactiondetails transactiondetails'
			'itemscard					itemscard'
			'metadata						metadata'
			'history						history';
	}

	@media all and (max-width: 960px) {
		grid-template-areas:
			'  alert   '
			'cardheader'
			'smallcard1'
			'smallcard2'
			'smallcard3'
			'smallcard4'
			'receiverscard'
			'clientcard'
			'transactiondetails'
			'itemscard'
			'metadata'
			'history';
	}

	#alert {
		grid-area: alert;
	}
	#cardheader {
		grid-area: cardheader;
	}
	#smallcard1 {
		grid-area: smallcard1;
	}
	#smallcard2 {
		grid-area: smallcard2;
	}
	#smallcard3 {
		grid-area: smallcard3;
	}
	#smallcard4 {
		grid-area: smallcard4;
	}
	#receiverscard {
		grid-area: receiverscard;
	}
	#clientcard {
		grid-area: clientcard;
	}
	#transactiondetails {
		grid-area: transactiondetails;
	}
	#itemscard {
		grid-area: itemscard;
	}
	#metadata {
		grid-area: metadata;
	}
	#history {
		grid-area: history;
	}
`;
