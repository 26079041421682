import React, { useEffect, useState } from 'react';
import { Icons } from '../../../../assets';
import { useRecipientExtractInfo } from '../../../../hooks';
import { getRecipientBankAccounts } from '../../../../services';
import {
	UnderlineInput,
	ButtonCustom,
	CheckboxCustom,
	RadioButton,
	SelectReact,
} from '../../../../components';
import {
	justNumbers,
	optionsMonthly,
	optionsTipoConta,
	optionsWeekly,
} from '../../../../utils';

import * as s from './styled-configuracoes';

const Configuracoes = () => {
	const [isOpen, setIsOpen] = useState([]);
	const {
		recebedor,
		transferInterval,
		setTransferInterval,
		transferDay,
		setTransferDay,
		opcaoConta,
		setOpcaoConta,
		banks,
		isBankOptionsLoading,
		recipientId,
		handleSubmitRecipientUpdate,
		isRecipientUpdating,
	} = useRecipientExtractInfo();
	const { bankAccount, antecipation, transfer } = recebedor;
	const {
		id: bankAccountId,
		bankCode,
		agencia,
		agenciaDv,
		conta,
		contaDv,
		type,
		documentNumber,
		legalName,
	} = bankAccount;
	const { enable: antecipationEnabled, volume } = antecipation;
	const { enable: transferEnabled, interval, day } = transfer;
	const [isContasRecebedorLoading, setIsContasRecebedorLoading] =
		useState(false);
	const [recipientBankAccounts, setRecipientBankAccounts] = useState([]);

	// eslint-disable-next-line no-unused-vars
	const [isAnticipationEnabled, setIsAnticipationEnabled] =
		useState(antecipationEnabled);
	const [antecipationVolumePercentage, setAntecipationVolumePercentage] =
		useState(volume);

	const [isTransferEnabled, setIsTransferEnabled] = useState(transferEnabled);
	const [transferIntervalValue, setTransferIntervalValue] = useState(interval);
	const [transferDayValue, setTransferDayValue] = useState(day);

	const [agenciaNova, setAgenciaNova] = useState('');
	const [agenciaDvNova, setAgenciaDvNova] = useState('');
	const [contaNova, setContaNova] = useState('');
	const [contaDvNova, setContaDvNova] = useState('');
	const [bankCodeNovo, setBankCodeNovo] = useState('');
	const [legalNameNovo, setLegalNameNovo] = useState('');
	const [typeNovo, setTypeNovo] = useState('');
	const bankNameBankAccountId =
		bankCode && banks.find((bank) => bank.value === bankCode);
	const [bankAccountIdNovo, setBankAccountIdNovo] = useState(bankAccountId);

	const body = {
		antecipation: {
			enable: isAnticipationEnabled,
			volume: antecipationVolumePercentage,
		},
		transfer: {
			enable: isTransferEnabled,
			interval: transferIntervalValue,
			day: transferDayValue?.value,
		},
		bankAccount: {
			agencia: agenciaNova,
			agenciaDv: agenciaDvNova,
			conta: contaNova,
			contaDv: contaDvNova,
			bankCode: bankCodeNovo,
			legalName: legalNameNovo,
			type: typeNovo,
		},
		bankAccountId: bankAccountId ?? bankAccountIdNovo?.value,
	};

	const handleClick = (param) => {
		if (isOpen.includes(param)) {
			return setIsOpen(isOpen.filter((item) => item !== param));
		}
		setIsOpen([...isOpen, param]);
	};

	const handleCancel = (id, close) => {
		if (close) handleClick(id);
		if (id === 'transferencia') {
			setIsTransferEnabled(transferEnabled);
			setTransferInterval(transferInterval);
			setTransferDay(transferDay);
		}
		if (id === 'antecipacao') {
			setAntecipationVolumePercentage(volume);
		}
		if (id === 'conta-bancaria') {
			setAgenciaNova(agencia);
			setAgenciaDvNova(agenciaDv);
			setContaNova(conta);
			setContaDvNova(contaDv);
			setBankCodeNovo(bankCode);
			setLegalNameNovo(legalName);
			setTypeNovo(type);
			setBankAccountIdNovo(bankAccountId);
		}
	};
	const ButtonTabs = () => (
		<s.ButtonsTabs id="tabs" selected={opcaoConta}>
			<s.Button
				id="inicio"
				onClick={() => {
					handleCancel('conta-bancaria');
					setOpcaoConta('selecionar-conta');
					setBankAccountIdNovo('');
				}}
				selected={opcaoConta === 'selecionar-conta'}
			>
				SELECIONAR UMA CONTA
			</s.Button>
			<s.Button
				id="final"
				onClick={() => setOpcaoConta('adicionar-conta')}
				selected={opcaoConta === 'adicionar-conta'}
			>
				ADICIONAR UMA CONTA
			</s.Button>
		</s.ButtonsTabs>
	);
	const isAntecipationPercentageInvalid =
		antecipationVolumePercentage > 100 || antecipationVolumePercentage < 0;
	const isReadOnly = transferIntervalValue === 'daily' || !isTransferEnabled;
	const automaticTransfer = isTransferEnabled ? 'Habilitado' : 'Desabilitado';
	const bankAccountDetails = `${legalName} - ${bankCode} - ${agencia}${
		agenciaDv ? `-${agenciaDv}` : ''
	} - ${conta}-${contaDv || '0'}`;

	const transformDay = (dia) => {
		if (transferIntervalValue === 'weekly') {
			if (dia === 1) return 'Segunda-feira';
			if (dia === 2) return 'Terça-feira';
			if (dia === 3) return 'Quarta-feira';
			if (dia === 4) return 'Quinta-feira';
			if (dia === 5) return 'Sexta-feira';
			return dia;
		}
		return dia;
	};

	const Buttons = ({ id }) => (
		<s.ButtonGroup className="button-group">
			<ButtonCustom
				outlined
				width="8.1875rem"
				isLoading={isRecipientUpdating}
				onClick={() => handleCancel(id, true)}
			>
				Cancelar
			</ButtonCustom>
			<ButtonCustom
				width="11.125rem"
				isLoading={isRecipientUpdating}
				onClick={() => handleSubmitRecipientUpdate(body)}
			>
				Salvar
			</ButtonCustom>
		</s.ButtonGroup>
	);

	useEffect(() => {
		setIsContasRecebedorLoading(true);
		getRecipientBankAccounts(1, justNumbers(documentNumber), recipientId)
			.then((response) => {
				const bankAccountsValues = [];
				response.forEach((account) => {
					const bankName = banks?.find(
						(bank) => bank.value === account.bankCode
					);
					bankAccountsValues.push({
						label: `${account.legalName} - ${bankName.label} - ${
							account.agencia
						}${account.agenciaDv === null ? '' : `-${account.agenciaDv}`} - ${
							account.conta
						}-${account.contaDv}`,
						value: account.id,
						bankCode: account.bankCode,
						agencia: account.agencia,
						agenciaDv: account?.agenciaDv,
						conta: account.conta,
						contaDv: account.contaDv,
					});
				});
				setRecipientBankAccounts(bankAccountsValues);
			})
			.catch(() => {
				setRecipientBankAccounts([]);
			})
			.finally(() => {
				setIsContasRecebedorLoading(false);
			});
	}, []);

	return (
		<s.Container>
			<s.ConfigContainer>
				<s.Config isOpen={isOpen.includes('antecipacao')}>
					<s.Icon>
						<Icons.AntecipacaoIcon />
					</s.Icon>
					<s.MidContent>
						<s.Title>Antecipação</s.Title>
						<s.Row>
							<s.Info>Modelo de antecipação: Manual por volume</s.Info>
							<s.Info floater>Volume antecipável: {volume}%</s.Info>
						</s.Row>
					</s.MidContent>
					<s.ExpandIcon
						isOpen={isOpen.includes('antecipacao')}
						onClick={() => handleClick('antecipacao')}
					>
						<Icons.Chevron />
					</s.ExpandIcon>
				</s.Config>
				<s.ExpandedContent isOpen={isOpen.includes('antecipacao')}>
					<s.Divisor />
					<s.FormGroup>
						<s.InputGroup>
							<s.ReadOnlyText>Manual Por Volume</s.ReadOnlyText>
							<UnderlineInput
								width="13rem"
								label="Volume antecipável (%)"
								placeholder="100"
								info={<p>Defina aqui um volume antecipável entre 1% e 100%</p>}
								maxLength={3}
								max={100}
								erro="O número precisa ser entre 1 e 100"
								isInvalid={isAntecipationPercentageInvalid}
								value={antecipationVolumePercentage}
								onChange={(e) =>
									setAntecipationVolumePercentage(justNumbers(e.target.value))
								}
							/>
						</s.InputGroup>
					</s.FormGroup>
					<Buttons id="antecipacao" />
				</s.ExpandedContent>
			</s.ConfigContainer>

			<s.ConfigContainer>
				<s.Config isOpen={isOpen.includes('transferencia')}>
					<s.Icon>
						<Icons.TransferenciaIcon />
					</s.Icon>
					<s.MidContent>
						<s.Title>Transferência</s.Title>
						<s.Row>
							<s.Info>Transferência automática: {automaticTransfer}</s.Info>
						</s.Row>
					</s.MidContent>
					<s.ExpandIcon
						isOpen={isOpen.includes('transferencia')}
						onClick={() => handleClick('transferencia')}
					>
						<Icons.Chevron />
					</s.ExpandIcon>
				</s.Config>
				<s.ExpandedContent isOpen={isOpen.includes('transferencia')}>
					<s.Divisor />
					<s.GroupContainer>
						<s.GroupTitle>CONFIGURAÇÕES DE TRANSFERÊNCIA</s.GroupTitle>
						<CheckboxCustom
							value={isTransferEnabled}
							onChange={() => {
								setIsTransferEnabled(!isTransferEnabled);
							}}
							text="Habilitar transferência automática"
						/>
					</s.GroupContainer>

					<s.InputGroup readOnly={isReadOnly} className="radio-group">
						<s.TitleInput>Periodicidade da transferência</s.TitleInput>
						<s.RadioGroup>
							<RadioButton
								id="daily"
								label="Diária"
								width="15.6875rem"
								value="daily"
								checked={transferIntervalValue === 'daily'}
								onChange={() => {
									setTransferDayValue(0);
									setTransferIntervalValue('daily');
								}}
								disabled={!isTransferEnabled}
							/>
							<RadioButton
								id="weekly"
								label="Semanal"
								value="weekly"
								checked={transferIntervalValue === 'weekly'}
								onChange={() => {
									setTransferDayValue(optionsWeekly[0]);
									setTransferIntervalValue('weekly');
								}}
								disabled={!isTransferEnabled}
							/>
							<RadioButton
								id="monthly"
								label="Mensal"
								value="monthly"
								checked={transferIntervalValue === 'monthly'}
								onChange={() => {
									setTransferDayValue(1);
									setTransferIntervalValue('monthly');
								}}
								disabled={!isTransferEnabled}
							/>
						</s.RadioGroup>
						<SelectReact
							readOnly={isReadOnly}
							height="4rem"
							className="custom-select-input"
							label="Dia da transferência"
							placeholder="Segunda-feira"
							width="16.6875rem"
							value={transformDay(transferDayValue)}
							onChange={(e) => setTransferDayValue(e)}
							options={
								transferIntervalValue === 'weekly'
									? optionsWeekly
									: optionsMonthly()
							}
							isSearchable={false}
							info={<p>Dia em que a transferência ocorrerá</p>}
							floaterWidth="12rem"
						/>
					</s.InputGroup>

					<Buttons id="transferencia" />
				</s.ExpandedContent>
			</s.ConfigContainer>

			<s.ConfigContainer>
				<s.Config isOpen={isOpen.includes('conta-bancaria')}>
					<s.Icon>
						<Icons.InvoiceIcon />
					</s.Icon>
					<s.MidContent>
						<s.Title>Conta Bancária</s.Title>
						<s.Row>
							<s.Info>{bankAccountDetails}</s.Info>
						</s.Row>
					</s.MidContent>
					<s.ExpandIcon
						isOpen={isOpen.includes('conta-bancaria')}
						onClick={() => handleClick('conta-bancaria')}
					>
						<Icons.Chevron />
					</s.ExpandIcon>
				</s.Config>
				<s.ExpandedContent isOpen={isOpen.includes('conta-bancaria')}>
					<s.Divisor />
					{recipientBankAccounts && <ButtonTabs />}
					{opcaoConta === 'adicionar-conta' ? (
						<s.InputGroup>
							<UnderlineInput
								width="10.375rem"
								label="Documento do titular"
								placeholder={documentNumber}
								readOnly
							/>
							<SelectReact
								width="22rem"
								className="custom-select-input"
								label="Banco *"
								placeholder="Digite o nome ou código do banco"
								isLoading={isBankOptionsLoading}
								options={banks}
								value={bankCodeNovo}
								onChange={(e) => {
									setBankCodeNovo(e.value);
								}}
							/>
						</s.InputGroup>
					) : (
						<s.FormGroup>
							<s.InputGroup>
								<UnderlineInput
									width="10.375rem"
									label="Documento do titular"
									placeholder={documentNumber}
									readOnly
								/>

								<SelectReact
									width="22rem"
									className="custom-select-input"
									label="Selecione uma conta bancária"
									placeholder="Selecione uma conta bancária"
									isLoading={isContasRecebedorLoading}
									isReadOnly={isReadOnly}
									value={bankNameBankAccountId || bankAccountIdNovo}
									options={recipientBankAccounts}
									onChange={(e) => {
										setBankAccountIdNovo(e);
									}}
								/>
							</s.InputGroup>
						</s.FormGroup>
					)}

					{opcaoConta === 'adicionar-conta' && (
						<s.FormGroup>
							<s.RowsGroup>
								<s.BankRow>
									<UnderlineInput
										label="Agência *"
										placeholder="Digite o número da agência"
										maxLength={4}
										value={agenciaNova}
										onChange={(e) => {
											e.target.value = justNumbers(e.target.value);
											setAgenciaNova(e.target.value);
										}}
									/>
									<UnderlineInput
										label="Dígito da agência (Opcional)"
										placeholder="Digite o código verificador da agência"
										width="13.625rem"
										maxLength={1}
										value={agenciaDvNova}
										onChange={(e) => {
											e.target.value = justNumbers(e.target.value);
											setAgenciaDvNova(e.target.value);
										}}
									/>
								</s.BankRow>
								<s.BankRow>
									<UnderlineInput
										label="Conta *"
										placeholder="Digite o número da conta"
										maxLength={13}
										value={contaNova}
										onChange={(e) => {
											e.target.value = justNumbers(e.target.value);
											setContaNova(e.target.value);
										}}
									/>

									<UnderlineInput
										label="Dígito da conta"
										placeholder="Digite o código verificador da conta"
										width="13.625rem"
										maxLength={2}
										value={contaDvNova}
										onChange={(e) => {
											e.target.value = justNumbers(e.target.value);
											setContaDvNova(e.target.value);
										}}
									/>
								</s.BankRow>
							</s.RowsGroup>

							<s.InputGroup>
								<SelectReact
									label="Nome da conta bancária *"
									className="custom-select-input"
									width="20rem"
									options={optionsTipoConta}
									value={typeNovo.label}
									onChange={(e) => {
										setTypeNovo(e.value);
									}}
									isSearchable={false}
								/>
								<UnderlineInput
									label="Nome da conta bancária *"
									placeholder="Dê um nome para esta conta"
									width="16.6875rem"
									value={legalNameNovo}
									onChange={(e) => {
										setLegalNameNovo(e.target.value);
									}}
								/>
							</s.InputGroup>
						</s.FormGroup>
					)}
					<Buttons id="conta-bancaria" />
				</s.ExpandedContent>
			</s.ConfigContainer>
		</s.Container>
	);
};

export default Configuracoes;
