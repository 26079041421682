import React, { useState } from 'react';
import { utils } from 'norama-react-modern-calendar-datepicker';
import { useAuth } from '../../../../hooks';
import { padStart, adicionarDiasCalendario } from '../../../../utils';
import {
	ButtonCustom,
	CalendarWithoutPlaceholder,
} from '../../../../components';

import * as s from './styled-grid-header';

const UserInfo = ({ formatedRangeDate }) => {
	const { currentUser } = useAuth();

	const dataInicial = formatedRangeDate()?.from;
	const dataFinal = formatedRangeDate()?.to;

	return (
		<s.User>
			<s.UserInfo>
				<h3>Olá, {currentUser.name}</h3>
				<p>
					Aqui está o resumo geral da sua conta entre{' '}
					<b>
						{dataInicial && dataFinal
							? `${dataInicial} a ${dataFinal}`
							: `--/--/---- à --/--/----`}
					</b>
				</p>
			</s.UserInfo>
		</s.User>
	);
};

const Filter = (props) => {
	const {
		loading,
		selectedDayRange,
		setSelectedDayRange,
		formatedRangeDate,
		selectedFilter,
		setSelectedFilter,
	} = props;
	const [dataInvalida, setDataInvalida] = useState({
		inicio: false,
		final: false,
	});

	return (
		<s.Filter>
			<s.Buttons>
				<CalendarWithoutPlaceholder
					id="custom"
					name="filter"
					disabled={loading}
					selectedDayRange={selectedDayRange}
					setSelectedDayRange={setSelectedDayRange}
					dataInvalida={dataInvalida}
					setDataInvalida={setDataInvalida}
					formatedRangeDate={formatedRangeDate}
					selected="período"
					placement="bottom-end"
					maximumDate={utils().getToday()}
					selectedFilter={selectedFilter}
					setSelectedFilter={setSelectedFilter}
				/>
				<ButtonCustom
					id="today"
					name="filter"
					height="2rem"
					width="7.625rem"
					outlined={true || selectedFilter !== 'today'}
					disabled={selectedFilter === 'today' || loading}
					onClick={() => {
						setSelectedDayRange(adicionarDiasCalendario(0));
						setSelectedFilter('today');
					}}
				>
					Hoje
				</ButtonCustom>
				<ButtonCustom
					id="days-7"
					name="filter"
					height="2rem"
					width="7.625rem"
					outlined={selectedFilter !== 'days-7'}
					disabled={selectedFilter === 'days-7' || loading}
					onClick={() => {
						setSelectedDayRange(adicionarDiasCalendario(-7));
						setSelectedFilter('days-7');
					}}
				>
					7 Dias
				</ButtonCustom>
				<ButtonCustom
					id="days-15"
					name="filter"
					height="2rem"
					width="7.625rem"
					outlined={selectedFilter !== 'days-15'}
					disabled={selectedFilter === 'days-15' || loading}
					onClick={() => {
						setSelectedDayRange(adicionarDiasCalendario(-15));
						setSelectedFilter('days-15');
					}}
				>
					15 Dias
				</ButtonCustom>
				<ButtonCustom
					id="days-30"
					name="filter"
					height="2rem"
					width="7.625rem"
					outlined={selectedFilter !== 'days-30'}
					disabled={selectedFilter === 'days-30' || loading}
					onClick={() => {
						setSelectedDayRange(adicionarDiasCalendario(-30));
						setSelectedFilter('days-30');
					}}
				>
					30 Dias
				</ButtonCustom>
				<ButtonCustom
					id="days-180"
					name="filter"
					height="2rem"
					width="8rem"
					outlined={selectedFilter !== 'days-180'}
					disabled={selectedFilter === 'days-180' || loading}
					onClick={() => {
						setSelectedDayRange(adicionarDiasCalendario(-180));
						setSelectedFilter('days-180');
					}}
				>
					180 dias
				</ButtonCustom>
			</s.Buttons>
		</s.Filter>
	);
};

const GridHeader = ({
	loading,
	selectedDayRange,
	setSelectedDayRange,
	selectedFilter,
	setSelectedFilter,
}) => {
	const dataFormatada = () => {
		if (selectedDayRange?.from && selectedDayRange?.to) {
			const inicio = selectedDayRange.from;
			const final = selectedDayRange.to;
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(final.day)}/${padStart(final.month)}/${final.year}`,
			};
		}

		if (selectedDayRange?.day) {
			const inicio = selectedDayRange;

			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		if (selectedDayRange?.to) {
			const inicio = selectedDayRange.from;
			return {
				from: `${padStart(inicio.day)}/${padStart(inicio.month)}/${
					inicio.year
				}`,
				to: `${padStart(inicio.day)}/${padStart(inicio.month)}/${inicio.year}`,
			};
		}

		return null;
	};
	return (
		<s.Container>
			<UserInfo
				selectedDayRange={selectedDayRange}
				formatedRangeDate={dataFormatada}
			/>
			<Filter
				formatedRangeDate={dataFormatada}
				selectedDayRange={selectedDayRange}
				setSelectedDayRange={setSelectedDayRange}
				loading={loading}
				selectedFilter={selectedFilter}
				setSelectedFilter={setSelectedFilter}
			/>
		</s.Container>
	);
};

export default GridHeader;
