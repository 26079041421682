import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
	getBalance,
	getAntecipacoes,
	getExtract,
	getFee,
	getLimitesAntecipacao,
} from '../../services';
import { useAuth, useExtrato } from '../../hooks';
import { CardExtrato, Recebedor, InfosTable } from '../../components';
import { dataInicialLimitesAntecipacao } from '../../utils';

import * as s from './styled-extract';

const Extract = () => {
	const {
		idRecebedor,
		bancos,
		tiposConta,
		informacoesRecebedor,
		loadingRecebedor,
		feeMdr,
		setFeeMdr,
		setLoadingFeeMdr,
	} = useExtrato();

	const { currentUser } = useAuth();
	const isBeiraRioContractor =
		currentUser?.contractorId === 'KV9XYmke83Z8Pcmuvbec';
	const [balanco, setBalanco] = useState({});
	const [antecipacoes, setAntecipacoes] = useState({});
	const [extrato, setExtrato] = useState([]);
	const [marketType, setMarketType] = useState('');
	const [extratoInfos, setExtratoInfos] = useState(() => {
		const extratoInfosStorage = sessionStorage.getItem('extratoInfos');
		if (extratoInfosStorage) {
			return JSON.parse(extratoInfosStorage);
		}
		return {};
	});
	const [limitesAntecipacao, setLimitesAntecipacao] = useState();
	const [pagination, setPagination] = useState([]);
	const [loadingBalanco, setLoadingBalanco] = useState(false);
	const [loadingLimites, setLoadingLimites] = useState(false);
	const [loadingAntecipacoes, setLoadingAntecipacoes] = useState(false);
	const [loadingExtrato, setLoadingExtrato] = useState(false);

	const intervaloInicial = () => {
		const data = new Date();
		const periodoStorage = sessionStorage.getItem('selectPeriodo');
		const diaHoje = data.getDate();
		const mesHoje = data.getMonth() + 1;
		const anoHoje = data.getFullYear();

		data.setDate(diaHoje - 7);

		const diaInicio = data.getDate();
		const mesInicio = data.getMonth() + 1;
		const anoInicio = data.getFullYear();

		const dataInicialStorageAnterior = JSON.parse(
			sessionStorage.getItem('anteriorDataInicial')
		);
		const dataFinalStorageAnterior = JSON.parse(
			sessionStorage.getItem('anteriorDataFinal')
		);
		const dataInicialStorageReceber = JSON.parse(
			sessionStorage.getItem('receberDataInicial')
		);
		const dataFinalStorageReceber = JSON.parse(
			sessionStorage.getItem('receberDataFinal')
		);
		if (
			dataInicialStorageAnterior &&
			dataFinalStorageAnterior &&
			dataInicialStorageReceber &&
			dataFinalStorageReceber
		) {
			if (periodoStorage === 'anterior') {
				return {
					from: {
						day: dataInicialStorageAnterior.day,
						month: dataInicialStorageAnterior.month,
						year: dataInicialStorageAnterior.year,
					},
					to: {
						day: dataFinalStorageAnterior.day,
						month: dataFinalStorageAnterior.month,
						year: dataFinalStorageAnterior.year,
					},
				};
			}
			return {
				from: {
					day: dataInicialStorageReceber.day,
					month: dataInicialStorageReceber.month,
					year: dataInicialStorageReceber.year,
				},
				to: {
					day: dataFinalStorageReceber.day,
					month: dataFinalStorageReceber.month,
					year: dataFinalStorageReceber.year,
				},
			};
		}

		return {
			from: { day: diaInicio, month: mesInicio, year: anoInicio },
			to: { day: diaHoje, month: mesHoje, year: anoHoje },
		};
	};
	const [intervaloDatas, setIntervaloDatas] = useState(intervaloInicial);

	const [selected, setSelected] = useState(() => {
		const periodoStorage = sessionStorage.getItem('selectPeriodo');
		if (periodoStorage === '') {
			return 'anterior';
		}

		if (periodoStorage === 'receber') {
			setIntervaloDatas(intervaloInicial());
			return periodoStorage;
		}
		setIntervaloDatas(intervaloInicial());
		return 'anterior';
	});

	function dataFormatada() {
		if (intervaloDatas?.day) {
			return {
				from: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
				to: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
			};
		}
		const dataInicial = intervaloDatas.from;
		const dataFinal = intervaloDatas.to;

		return {
			from: `${dataInicial.year}/${dataInicial.month}/${dataInicial.day}`,
			to: `${dataFinal.year}/${dataFinal.month}/${dataFinal.day}`,
		};
	}

	function datasEstaoCompletas() {
		const dataInicial = intervaloDatas.from;
		const dataFinal = intervaloDatas.to;
		const dataUnica = intervaloDatas.day;
		if (selected === 'anterior') {
			sessionStorage.setItem(
				'anteriorDataInicial',
				JSON.stringify(intervaloDatas.from)
			);
			sessionStorage.setItem(
				'anteriorDataFinal',
				JSON.stringify(intervaloDatas.to)
			);
		} else {
			sessionStorage.setItem(
				'receberDataInicial',
				JSON.stringify(intervaloDatas.from)
			);
			sessionStorage.setItem(
				'receberDataFinal',
				JSON.stringify(intervaloDatas.to)
			);
		}

		if (dataUnica) return true;
		if (!dataInicial || !dataFinal) return false;
		if (!dataInicial.day || !dataInicial.month || !dataInicial.year)
			return false;
		if (!dataFinal.day || !dataFinal.month || !dataFinal.year) return false;
		return true;
	}

	let transactionsWithoutMdrStorage = [];

	const verifyMdrInState = (data) => {
		const filterFee = feeMdr?.filter((item) => item.transaction_id);
		data.map(
			(item) =>
				item.transactionId !== filterFee &&
				transactionsWithoutMdrStorage.push(item)
		);
	};

	const verifyTheExistenceOfData = (data) => {
		if (!data) return null;
		verifyMdrInState(data);
		const mathLengthData = Math.ceil(
			transactionsWithoutMdrStorage?.length / 20
		);
		const slicesArray = [];
		let contador = 0;
		for (let index = 0; index < mathLengthData; index++) {
			const slice = transactionsWithoutMdrStorage.slice(
				contador,
				contador + 20
			);
			slicesArray.push(slice);
			contador += 20;
		}

		return slicesArray;
	};

	const saveMdrInContext = (resp) => {
		resp.map((item) => {
			return setFeeMdr((prev) => [...prev, item]);
		});
	};

	const feeData = (data) => {
		const transactionsDataMap = data
			?.filter((item) => {
				return item.type !== 'transfer';
			})
			.map((item) => {
				return {
					id: `${item.transactionId}`,
					installment: item.installment,
				};
			});

		const transactionDataObject = JSON.stringify(transactionsDataMap);
		getFee(idRecebedor, transactionDataObject)
			.then((resp) => saveMdrInContext(resp))
			.catch(() => {
				toast.error('Erro ao buscar a taxa de MDR');
			})
			.finally(() => setLoadingFeeMdr(false));

		transactionsWithoutMdrStorage = [];
	};

	async function buscarExtrato(pagina = 1, itensPorPagina = 15) {
		if (datasEstaoCompletas()) {
			setLoadingExtrato(true);

			await getExtract(
				idRecebedor,
				dataFormatada().from,
				dataFormatada().to,
				pagina,
				itensPorPagina,
				marketType
			)
				.then((resp) => {
					setExtrato(() => ({
						data: [...resp.data],
					}));

					if (pagina === 1) {
						const infosExtrato = {
							discountsValue: resp.discountsValue,
							grossValue: resp.grossValue,
							netValue: resp.netValue,
							taxValue: resp.taxValue,
						};
						setExtratoInfos(infosExtrato);
						sessionStorage.setItem(
							'extratoInfos',
							JSON.stringify(infosExtrato)
						);
					}

					setPagination((prev) => {
						return {
							page: pagina,
							totalPages: resp?.totalPages ? resp.totalPages : prev?.totalPages,
						};
					});
				})
				.catch(() => {
					setExtrato([]);
					toast.error('Erro ao buscar extrato');
				})
				.finally(() => setLoadingExtrato(false));
		} else toast.error('Selecione as datas corretamente');
	}

	async function buscarLimitesAntecipacao() {
		setLoadingLimites(true);
		await getLimitesAntecipacao(
			idRecebedor,
			dataInicialLimitesAntecipacao(true)
		)
			.then((resp) => setLimitesAntecipacao(resp.minimum.amount))
			.catch(() => setLimitesAntecipacao('erro'))
			.finally(() => setLoadingLimites(false));
	}

	useEffect(async () => {
		if (extrato && isBeiraRioContractor) {
			const slicedArray = verifyTheExistenceOfData(extrato.data);

			const mdrData = () => slicedArray?.map((item) => feeData(item));
			setLoadingFeeMdr(true);

			mdrData();
		} else {
			setLoadingFeeMdr(false);
		}
	}, [extrato]);

	useEffect(() => {
		let isMounted = true;
		setLoadingBalanco(true);
		getBalance(idRecebedor)
			.then((resp) => isMounted && setBalanco(resp))
			.catch(() => toast.error('Erro ao buscar o saldo'))
			.finally(() => setLoadingBalanco(false));

		buscarLimitesAntecipacao();

		setLoadingAntecipacoes(true);
		getAntecipacoes(idRecebedor, 'pending')
			.then((resp) => isMounted && setAntecipacoes(resp))
			.catch(() => toast.error('Erro ao buscar antecipações'))
			.finally(() => setLoadingAntecipacoes(false));

		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		buscarExtrato();
	}, [marketType]);

	const tableData = extrato.data;

	return (
		<s.Container>
			<Recebedor
				informacoesRecebedor={informacoesRecebedor}
				bancos={bancos}
				tiposConta={tiposConta}
				loadingRecebedor={loadingRecebedor}
			/>

			<s.Group>
				<CardExtrato
					title="Saldo atual"
					valor={balanco?.available || 0}
					loading={loadingBalanco}
					saque
				/>
				<CardExtrato
					title="A receber"
					valor={balanco?.toRecieve || 0}
					loading={loadingBalanco}
					loadingLimites={loadingLimites}
					limitesAntecipacao={limitesAntecipacao}
					buscarLimites={buscarLimitesAntecipacao}
					receber
				/>
				<CardExtrato
					title="Antecipações pendentes"
					loading={loadingAntecipacoes}
					dados={antecipacoes}
					setDados={setAntecipacoes}
					antecipacoes
				/>
				<InfosTable
					marketType={marketType}
					setMarketType={setMarketType}
					loadingExtrato={loadingExtrato}
					intervaloInicial={intervaloInicial}
					selectedDayRange={intervaloDatas}
					setSelectedDayRange={setIntervaloDatas}
					extrato={extrato}
					extratoInfos={extratoInfos}
					buscarExtrato={buscarExtrato}
					pagination={pagination}
					setPagination={setPagination}
					selected={selected}
					setSelected={setSelected}
					tableData={tableData}
				/>
			</s.Group>
		</s.Container>
	);
};

export default Extract;
