import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { replaceAccent } from '../../utils';
import {
	RecipientFilters,
	RecipientsTable,
	RecipientsTableHeader,
	Loader,
} from '../../components/';
import { getRecipients } from '../../services/recipient.service';
import { useAuth } from '../../hooks';
import { Icons } from '../../assets';

import * as s from './styled-recipients';

const Recipients = () => {
	const { recipientId } = useAuth().currentUser;
	const [recebedores, setRecebedores] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState();

	const [page, setPage] = useState(1);
	const itensPagina = recebedores?.length || 0;

	function handleSearchValueType(search) {
		const isNum = /^\d+$/.test(search);
		if (search[2] === '_') return 'id';
		if (isNum) return 'bank';
		if (search.length > 0) return 'name';
		return '';
	}

	function buscarRecebedores(pagina = 1, search = '') {
		setIsLoading(true);
		const tipoParametro = handleSearchValueType(search);
		let id = '';
		let recipientName = '';
		let bankAccountId = '';

		switch (tipoParametro) {
			case 'id':
				id = searchValue;
				break;
			case 'bank':
				bankAccountId = searchValue;
				break;
			case 'name':
				recipientName = replaceAccent(searchValue);
				break;
			default:
				break;
		}
		if (pagina > 0) {
			getRecipients(
				Number(pagina),
				recipientId,
				id,
				recipientName,
				bankAccountId
			)
				.then((resp) => {
					setRecebedores(() => resp);
				})
				.catch(() => toast.error('Erro ao buscar recebedores'))
				.finally(() => setIsLoading(false));
		}
	}

	useEffect(() => {
		buscarRecebedores();
	}, []);

	return (
		<>
			<s.Container>
				<RecipientFilters
					handleSubmit={buscarRecebedores}
					selectedFilter={selectedFilter}
					setSelectedFilter={setSelectedFilter}
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					page={page}
					setPage={setPage}
					loading={isLoading}
				/>
				<s.TableContainer>
					{!isLoading && recebedores?.length > 0 && (
						<>
							<RecipientsTableHeader
								page={page}
								setPage={setPage}
								itensPagina={itensPagina}
								handleSubmit={buscarRecebedores}
								loading={isLoading}
								searchValue={searchValue}
							/>
							<RecipientsTable
								isLoading={isLoading}
								handleSubmit={buscarRecebedores}
								dados={recebedores}
								page={page}
								setPage={setPage}
								itensPagina={itensPagina}
							/>
						</>
					)}
				</s.TableContainer>
			</s.Container>
			{isLoading ? (
				<s.ContainerLoader>
					<Loader />
				</s.ContainerLoader>
			) : (
				recebedores?.length === 0 && (
					<s.ErrorContent>
						<Icons.AttentionOutlined />
						<h2 className="error-title">Sem resultados</h2>
						<p className="error-text">
							Não encontramos resultados para sua busca. <br /> Reveja o que foi
							digitado ou faça uma nova pesquisa.
						</p>
					</s.ErrorContent>
				)
			)}
		</>
	);
};

export default Recipients;
