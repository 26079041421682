import React from 'react';
import { Icons } from '../../../../assets';
import { capitalizeFirstLetter } from '../../../../utils';

import * as S from './styled-filters-subtitle';

function FiltersSubtitle({ id, state, setState }) {
	const paymentData = (value) => {
		const data = {
			pix: 'Pix',
			ticket: 'Boleto',
			credit_card: 'Cartão de crédito',
		};
		return data[value];
	};

	const refuseReasonsData = (value) => {
		const data = {
			acquirer: 'Operadora de Cartão',
			acquirer_timeout: 'Tempo de resposta',
			antifraud: 'Antifraude',
			internal_error: 'Erro interno',
		};
		return data[value];
	};

	const statusData = (value) => {
		const data = {
			paid: 'Paga',
			refused: 'Recusada',
			refunded: 'Estornada',
			pending_refund: 'Estorno Pendente',
			waiting_payment: 'Aguardando Pagamento',
			processing: 'Processando',
			chargedback: 'Chargeback',
			authorized: 'Autorizada',
			pending_review: 'Revisão pendente',
		};
		return data[value];
	};

	const tittleOfData = (value) => {
		const tittle = {
			CardBrand: 'Bandeiras',
			Installments: 'Números de parcelas',
			PaymentMethod: 'Formas de pagamento',
			Status: 'Status de transações',
			RefuseReason: 'Motivos de recusa',
		};

		return tittle[value];
	};

	const typeOfData = (type, value) => {
		const data = {
			Bandeiras: capitalizeFirstLetter(String(value)),
			'Números de parcelas': value,
			'Formas de pagamento': paymentData(value),
			'Motivos de recusa': refuseReasonsData(value),
			'Status de transações': statusData(value),
		};
		return data[type];
	};

	return (
		<S.Container
			onClick={() => {
				setState([]);
			}}
			htmlFor={id}
		>
			<S.Row>
				<h4>
					<Icons.Close /> {tittleOfData(id)}
				</h4>
				<p>
					{`${state.map(
						(item) => ` ${typeOfData(tittleOfData(id), item)}`
					)}  `.slice(0, -1)}
				</p>
			</S.Row>
		</S.Container>
	);
}

export default FiltersSubtitle;
