import styled from 'styled-components';
import { colors } from '../../assets';

export const Container = styled.div`
	min-height: 100vh;
	width: 100vw;
	background: radial-gradient(
		90.95% 100% at 66.86% 0%,
		rgba(46, 50, 51, 0.4) 0%,
		rgba(25, 25, 25, 0.4) 100%
	);
	backdrop-filter: blur(100px);
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
`;

export const Card = styled.div`
	padding: 3rem;
	border: 2px solid rgba(43, 66, 70, 0.5);
	display: flex;
	border-radius: 15px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: radial-gradient(
		90.95% 100% at 66.86% 0%,
		rgba(46, 50, 51, 0.4) 0%,
		rgba(25, 25, 25, 0.4) 100%
	);
	backdrop-filter: blur(100px);

	svg.logotipo {
		width: 12.5rem;
		height: 4.06rem;
		margin-bottom: 2.5rem;
	}
`;

export const InviteHeader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 1.88rem;

	h1 {
		color: ${colors.white};
		font-size: 1.25rem;
		text-transform: uppercase;
	}

	svg.logotipo {
		width: 200px;
		height: 46px;
	}
`;

export const Button = styled.button`
	background: none;
`;

export const InviteForm = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28.13rem;

	form,
	button.button-custom {
		width: 100%;
	}

	label {
		font-size: 1.25rem;
		color: ${colors.white};
	}

	#validar-senha {
		width: 100%;

		div {
			width: 100%;

			span {
				width: 100%;
			}
		}
	}

	.container-custom-input {
		margin: 5px 0 15px 0;
	}

	#email {
		background: ${colors.gridBlack};
		font-weight: 600;
		border-radius: 0.625rem;
	}

	button.button-custom {
		color: ${colors.white};
		border: none;
		margin-top: 5rem;
	}
`;

export const TitleDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: space-between;
	align-self: flex-start;
`;

export const Error = styled.p`
	color: ${colors.primaryRed};
	font-size: 1rem;
	line-height: 10px;
`;
