import styled from 'styled-components';
import { colors } from '../../../assets';

export const Overlay = styled.div`
position: fixed;
bottom: 0;
left: 0;
right: 0;
top: 0;
z-index: 5;
`;

export const customStyles = (
background,
width,
simbolicIcon,
transform,
textAlign,
padding,
titleColor,
contentColor,
fontSize
) => ({
wrapper: {
  cursor: simbolicIcon ? 'default' : 'pointer',
  zIndex: 10,
  pointerEvents: simbolicIcon && 'none',
},
container: {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  zIndex: 10,
  padding: padding || 15,
  backgroundColor: background || colors.background,
  color: colors.offWhite,
  width: width || 'auto',
  transform: transform || 'none',
},
content: {
  fontSize: fontSize || '1rem',
  lineHeight: '1.56rem',
  fontWeight: 400,
  width: '100%',
  lineSpacing: '1px',
  textAlign: textAlign || 'justify',
  color: contentColor || colors.offWhite,
},
floater: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
},
arrow: {
  length: 10,
  position: 'absolute',
  spread: 15,
  color: background || colors.background,
},
title: {
  color: titleColor || colors.primaryRed,
  border: 0,
  fontSize: '1rem',
  fontWeight: 700,
  lineSpacing: '3px',
},
});