import React from 'react';

const ArrowEnvironment = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6.42871 13.5706L13.5716 6.42773M13.5716 6.42773H6.42871M13.5716 6.42773V13.5706"
			stroke="#1A1A1A"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default ArrowEnvironment;
