import styled from 'styled-components';
import { colors } from '../../assets';

export const Container = styled.div`
	position: relative;
	width: 100%;

	#scroll-table {
		max-width: 100%;
	}

	@media (max-width: 1280px) {
		width: 99%;
	}

	@media (max-width: 1366px) {
		width: 99%;
	}
`;

export const BodyContent = styled.table`
	border: 0;
	margin: 0;
	padding: 0;

	@media (max-width: 1919px) {
		width: 95%;
	}
	@media (max-width: 1725px) {
		width: 85%;
	}

	@media (max-width: 1368px) {
		width: 75%;
	}
`;

export const ErrorContent = styled.div`
	margin-top: 128px;
	margin-bottom: 50px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${colors.white};
	position: relative;

	svg.attention-outlined-icon {
		width: 8.75rem;
		height: 8.75rem;
		fill: ${colors.background};
		filter: drop-shadow(0px 0px 3px ${colors.yellow});
		margin-bottom: 1.75rem;
	}

	.error-title {
		font-size: 2rem;
		margin-bottom: 1rem;
		text-transform: uppercase;
		font-family: Jost;
		font-weight: 500;
	}

	.error-text {
		font-size: 1.25rem;
		text-align: center;
		color: ${colors.lightGray};
		font-family: IBM Plex Sans;
		font-weight: 400;
	}
	div {
		padding-top: 3rem;
	}
	button#adicionar-usuario {
		width: 18.44rem;
		height: 2.56rem;

		svg.add-person-icon {
			width: 1.12rem;
			height: 1.25rem;
		}
	}
`;

export const ContainerHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 15px;
	padding: 1.56rem;
	margin: 0 auto;
	margin-bottom: 1rem;
	background: ${colors.gridBlack};
	border-radius: 0.9375rem;

	.searchButton {
		font-size: 1rem;
	}

	#pesquisa-acessos {
		border-radius: 0.9375rem;
		padding: 10px;
	}

	/* .select-react {
		width: 10.44rem;
		height: 3.13rem;
	} */
`;

export const FiltersHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const TableMaster = styled.div`
	width: auto;
	margin: 0 auto 50px auto;
	align-items: center;
	justify-content: center;
	position: static;

	.selectDiv {
		display: flex;
		flex-direction: row;
		gap: 0.9375rem;
		padding: 0.625rem;
		width: 18.75rem;
		height: 2.8125rem;
	}
	.selectHeaderDiv {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 0.9375rem;
		padding: 0.625rem;
		width: 18.75rem;
		height: 2.8125rem;
	}

	table {
		font-size: 1rem;
		background: ${colors.gridBlack};
		padding: 1.56rem;
		border-radius: 0.9375rem;
		width: 100%;

		td {
			display: flex;
			align-items: center;
			border-right: 1px solid ${colors.borderTable};
			padding: 0.625rem;
			height: 100%;
		}

		td.center {
			justify-content: center;
		}
	}
`;
export const Table = styled.div`
	font-size: 1rem;
	background: ${colors.gridBlack};
	padding: 1.56rem;
	border-radius: 0.9375rem;
	width: 100%;

	td {
		display: grid;
		grid-template-columns: auto;
		align-items: center;
		border-right: 1px solid ${colors.borderTable};
		padding: 0.625rem;
		height: 100%;
	}

	td.center {
		justify-content: center;
	}
`;

export const Tr = styled.tr`
	min-height: 3.75rem;
	justify-content: center;
	color: ${colors.white};
	background: ${({ index }) =>
		index % 2 === 0 ? colors.gridBlack : colors.rowTable};

	${({ top, bottom }) =>
		top
			? `border-top: 1px solid ${colors.borderTable};`
			: bottom && `border-bottom: 1px solid ${colors.borderTable}`};

	/* borda direita da tabela */
	td:last-child {
		border-right: 1px solid ${colors.borderTable};
	}
`;

export const MoreInfo = styled.div`
	display: flex;
	background: ${({ index }) =>
		index % 2 === 0 ? colors.gridBlack : colors.rowTable};
	border: 1px solid ${colors.borderTable};
	height: 12.5rem;
	padding: 2.5rem 2rem;
	.button-custom {
		font-size: 1rem;
		margin-left: auto;
		height: 3.3125rem;
		width: 12.5rem;
	}
`;

export const Content = styled.div`
	display: flex;
	width: 100%;
	color: ${colors.white};
`;

export const Item = styled.div`
	color: ${colors.lightGray};
	:not(:last-child) {
		margin-bottom: 1.5rem;
	}

	span {
		color: ${colors.offWhite};
	}
`;

export const LeftColumn = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 7.5rem;
`;

export const RightColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ButtonTableContainer = styled.div`
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.5625rem;
	.paginacao {
		margin-right: 1.5625rem;
	}
	button#adicionar-usuario {
		width: 18.44rem;
		height: 2.56rem;

		svg.add-person-icon {
			width: 1.12rem;
			height: 1.25rem;
		}
	}
`;

export const Head = styled.thead`
	tr {
		background: ${colors.background};
		height: 3.75rem;
		margin-bottom: -2px;
		text-align: left;
		height: 3.75rem;
		align-items: center;
		display: grid;
		grid-template-columns: 28.3rem 15.3rem 23.3rem 11.3rem 11.3rem 11.3rem;
		@media (max-width: 1919px) {
			grid-template-columns: 26.19rem 13.94rem 21.63rem 9.63rem 9.53rem 9.53rem;
		}
		@media (max-width: 1725px) {
			grid-template-columns: 25.6rem 14.14rem 21rem 9.93rem 9.93rem 9.93rem;
		}

		@media (max-width: 1368px) {
			grid-template-columns: 24rem 14rem 20rem 10rem 10rem 10rem;
		}

		@media (max-width: 1025px) {
			grid-template-columns: 23rem 10.94rem 18rem 7.63rem 7.53rem 7.53rem;
		}
	}
	td {
		margin-top: -3px;
		color: ${colors.primaryBlue};
	}
`;

export const TBody = styled.tbody`
	tr {
		text-align: left;
		background: ${colors.gridBlack};
		color: ${colors.white};
		display: grid;
		justify-content: center;
		align-items: center;
		grid-template-columns: 28.3rem 15.3rem 23.3rem 11.3rem 11.3rem 11.3rem;
		min-height: 3.75rem;
		align-items: center;
		:nth-child(odd) {
			background: ${colors.gridBlack};
		}
		:nth-child(even) {
			background: ${colors.rowTable};
		}
		@media (max-width: 1919px) {
			grid-template-columns: 26.19rem 13.94rem 21.63rem 9.63rem 9.53rem 9.53rem;
		}
		@media (max-width: 1725px) {
			grid-template-columns: 25.6rem 14.14rem 21rem 9.93rem 9.93rem 9.93rem;
		}

		@media (max-width: 1368px) {
			grid-template-columns: 24rem 14rem 20rem 10rem 10rem 10rem;
		}
		@media (max-width: 1025px) {
			grid-template-columns: 23rem 10.94rem 18rem 7.63rem 7.53rem 7.53rem;
		}
	}

	button.button-custom {
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 28.96px;
		height: 28.96px;
	}
`;
