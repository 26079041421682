import React from 'react';
import { colors } from '../../../assets';

const TransacoesIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="transacoes-icon"
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM6.35275 18.3486C6.35334 18.1468 6.39376 17.9544 6.46656 17.7789C6.69195 17.2354 7.2277 16.8531 7.85274 16.8531H23.1471C23.9755 16.8531 24.6471 17.5246 24.6471 18.3531C24.6471 19.1815 23.9755 19.8531 23.1471 19.8531L11.4741 19.8531L13.0311 21.4101C13.6169 21.9959 13.6169 22.9457 13.0311 23.5314C12.4453 24.1172 11.4956 24.1172 10.9098 23.5314L6.79208 19.4137C6.64827 19.2699 6.53976 19.1042 6.46656 18.9272C6.39376 18.7517 6.35334 18.5593 6.35275 18.3576L6.35275 18.3486ZM24.2113 13.7047C24.3535 13.5616 24.461 13.397 24.5336 13.2214C24.607 13.0445 24.6475 12.8506 24.6475 12.6472C24.6475 12.4438 24.607 12.2499 24.5336 12.073C24.4604 11.8961 24.3519 11.7304 24.2081 11.5865L20.0904 7.46882C19.5046 6.88304 18.5549 6.88304 17.9691 7.46882C17.3833 8.05461 17.3833 9.00436 17.9691 9.59014L19.5261 11.1472L7.85309 11.1472C7.02467 11.1472 6.35309 11.8188 6.35309 12.6472C6.35309 13.4756 7.02467 14.1472 7.85309 14.1472L23.1475 14.1472C23.5601 14.1472 23.9337 13.9806 24.2049 13.711L24.2081 13.7079L24.2113 13.7047Z"
			fill={fill || colors.offWhite}
		/>
	</svg>
);

export default TransacoesIcon;
