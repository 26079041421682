import React from 'react';

const Aura = ({ title, fill, style }) => (
	<svg
		width="26"
		height="16"
		style={style}
		viewBox="0 0 26 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="aura-icon"
	>
		<title>{title || 'Ícone Aura'}</title>
		<rect
			x="-0.000366211"
			width="26"
			height="16"
			rx="1"
			fill={fill || '#0083CB'}
		/>
		<ellipse cx="12.9996" cy="6.5" rx="5" ry="4.5" fill={fill || '#FFDE5D'} />

		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M25.9996 10.1604V15C25.9996 15.5522 25.5519 16 24.9996 16H0.999634C0.447388 16 -0.000366211 15.5522 -0.000366211 15V10.1604C3.49573 8.81348 8.0365 8 12.9996 8C17.9628 8 22.5035 8.81348 25.9996 10.1604Z"
			fill="#FFDE5D"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M-0.000366211 10.6604V8.1604C3.49573 6.81348 8.0365 6 12.9996 6C17.9628 6 22.5035 6.81348 25.9996 8.1604V10.6604C22.5035 9.31348 17.9628 8.5 12.9996 8.5C8.0365 8.5 3.49573 9.31348 -0.000366211 10.6604Z"
			fill="#ED1C24"
		/>
	</svg>
);
export default Aura;
