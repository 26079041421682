import styled from 'styled-components';
import { colors } from '../../../assets';

export const Container = styled.div`
	display: flex;
	align-items: center;

	label {
		font-size: 1.25rem;
		color: ${({ checked, disabled }) =>
			disabled
				? colors.lightGray
				: checked
				? colors.primaryBlue
				: colors.lightGray};
		font-weight: 400;
		line-height: 1.625rem;
		margin-left: 0.9375rem;
		cursor: ${({ id }) => (id ? 'pointer' : 'default')};
		pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
	}

	input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		width: 1.25rem;
		height: 1.25rem;
		cursor: pointer;
		border-radius: 50%;
		border: 1px solid
			${({ checked, disabled }) =>
				disabled
					? colors.lightGray
					: checked
					? colors.primaryBlue
					: colors.lightGray};
		transition: 0.5s all linear;
		position: relative;

		${({ checked, disabled }) =>
			checked && !disabled
				? `
        	::after {
			    content: '';
			    width: calc(1.25rem / 1.75);
			    height: calc(1.25rem / 1.75);
			    background: ${colors.primaryBlue};
			    position: absolute;
			    border-radius: 50%;
			    top: 50%;
			    left: 50%;
			    transform: translate(-50%, -50%);
		    }
        `
				: `
        	border: 1px solid ${colors.lightGray};
        `};

		${({ disabled, checked }) =>
			disabled &&
			checked &&
			`
			::after {
			    content: '';
			    width: calc(1.25rem / 1.75);
			    height: calc(1.25rem / 1.75);
			    background: ${colors.lightGray};
			    position: absolute;
			    border-radius: 50%;
			    top: 50%;
			    left: 50%;
			    transform: translate(-50%, -50%);
		    }
					border: 1px solid ${colors.lightGray};
					`};

		${({ disabled, checked }) =>
			disabled &&
			!checked &&
			`
        	border: 1px solid ${colors.lightGray};
        `};
	}
`;
