import React, { useState } from 'react';
import Floater from 'react-floater';
import * as s from './styled-floater-react';

const FloaterReact = ({
	children,
	title,
	content,
	placement,
	id,
	isOpen,
	hover,
	focus,
	style,
	background,
	width,
	fontSize,
	simbolicIcon,
	transform,
	textAlign,
	padding,
	titleColor,
	contentColor,
	component,
	onClick,
	hideArrow,
	ref,
}) => {
	const [open, setOpen] = useState('');

	return (
		<s.ContainerFloater ref={ref}>
			<Floater
				open={isOpen || open === id}
				title={title || null}
				content={content || null}
				component={component || null}
				placement={placement || 'top'}
				OnClick={onClick}
				hideArrow={hideArrow || false}
				styles={
					style ||
					s.customStyles(
						background,
						width,
						simbolicIcon,
						transform,
						textAlign,
						padding,
						titleColor,
						contentColor,
						fontSize
					)
				}
			>
				<s.WrapperFloater
					onMouseOver={() => hover && setOpen(id)}
					onMouseLeave={() => hover && setOpen('')}
					onFocus={() => focus && setOpen(id)}
					onBlur={() => focus && setOpen('')}
					onClick={() => !hover && setOpen((idAnt) => (idAnt === id ? '' : id))}
				>
					{children || <span>Clique aqui</span>}
				</s.WrapperFloater>
			</Floater>
		</s.ContainerFloater>
	);
};

export default FloaterReact;
