export const customLocaleCalendar = {
	// months list by order
	months: [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro',
	],

	// week days by order
	weekDays: [
		{
			name: 'Domingo', // used for accessibility
			short: 'Dom', // displayed at the top of days' rows
			isWeekend: true, // is it a formal weekend or not?
		},
		{
			name: 'Segunda',
			short: 'Seg',
		},
		{
			name: 'Terça',
			short: 'Ter',
		},
		{
			name: 'Quarta',
			short: 'Qua',
		},
		{
			name: 'Quinta',
			short: 'Qui',
		},
		{
			name: 'Sexta',
			short: 'Sex',
		},
		{
			name: 'Sabado',
			short: 'Sáb',
			isWeekend: true,
		},
	],

	// just play around with this number between 0 and 6
	weekStartingIndex: 0,

	// return a { year: number, month: number, day: number } object
	getToday(gregorainTodayObject) {
		return gregorainTodayObject;
	},

	// return a native JavaScript date here
	toNativeDate(date) {
		return new Date(date.year, date.month - 1, date.day);
	},

	// return a number for date's month length
	getMonthLength(date) {
		return new Date(date.year, date.month, 0).getDate();
	},

	// return a transformed digit to your locale
	transformDigit(digit) {
		return digit;
	},

	// texts in the date picker
	nextMonth: 'Próximo mês',
	previousMonth: 'Mês anterior',
	openMonthSelector: 'Seletor de mês aberto',
	openYearSelector: 'Seletor de ano aberto',
	closeMonthSelector: 'Fechar Seletor de Mês',
	closeYearSelector: 'Fechar seletor de ano',
	defaultPlaceholder: 'Selecionar...',

	// for input range value
	from: 'from',
	to: 'to',

	// used for input value when multi dates are selected
	digitSeparator: ',',

	// if your provide -2 for example, year will be 2 digited
	yearLetterSkip: 0,

	// is your language rtl or ltr?
	isRtl: false,
};

export function adicionarDiasCalendario(dias) {
	const date = new Date();

	const diaHoje = date.getDate();
	const mesHoje = date.getMonth() + 1;
	const anoHoje = date.getFullYear();

	date.setDate(diaHoje + dias);

	const diaFinal = date.getDate();
	const mesFinal = date.getMonth() + 1;
	const anoFinal = date.getFullYear();

	if (dias < 0) {
		return {
			from: { day: diaFinal, month: mesFinal, year: anoFinal },
			to: { day: diaHoje, month: mesHoje, year: anoHoje },
		};
	}
	if (dias === 0) {
		return {
			from: { day: diaHoje, month: mesHoje, year: anoHoje },
			to: { day: diaHoje, month: mesHoje, year: anoHoje },
		};
	}

	return {
		from: { day: diaHoje, month: mesHoje, year: anoHoje },
		to: { day: diaFinal, month: mesFinal, year: anoFinal },
	};
}
