/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Icons } from '../../../../assets/icons';
import { formatarParaReais, renderFlag } from '../../../../utils';
import { LoadingRectangle } from '../../../../components';

import * as S from './styled-card-small';

const CardSmall = (props) => {
	const {
		id,
		div,
		title,
		loading,
		paymentMethod,
		cardBrand,
		cardFirstDigits,
		cardLastDigits,
		cardHolderName,
		grossValue,
		saidas,
		liquidValue,
		mdr,
	} = props;

	const renderCard = (firstDigits, lastDigits) => {
		const formatedFirstDigits = firstDigits.substring(0, 4);
		const formatedFirstDigits2 = firstDigits.substring(4, 6);
		return `${formatedFirstDigits} ${formatedFirstDigits2}** **** ${lastDigits}`;
	};

	const renderTypeOfCard = (tipo) => {
		if (tipo === 'smallcard1' && paymentMethod === 'credit_card') {
			return renderCard(cardFirstDigits, cardLastDigits);
		}
		if (tipo === 'smallcard2') {
			if (grossValue) {
				return `${
					grossValue > 0 ? '+' : grossValue < 0 ? '-' : ''
				} ${formatarParaReais(grossValue / 100, true)}`;
			}
			if (grossValue === 0) {
				return 'R$ 0,00';
			}
		}
		if (tipo === 'smallcard3') {
			return `${saidas > 0 ? '+' : saidas < 0 ? '-' : ''} ${
				saidas === 0
					? formatarParaReais(saidas / 100, true)
					: formatarParaReais((saidas * -1) / 100, true)
			}`;
		}

		if (tipo === 'smallcard4') {
			if (liquidValue) {
				return `${
					liquidValue > 0 ? '+' : liquidValue < 0 ? '-' : ''
				} ${formatarParaReais(liquidValue / 100, true)}`;
			}
			return 'R$ 0,00';
		}
	};

	return (
		<S.Container id={id} div={div}>
			<S.Title>{title}</S.Title>
			<S.Infos>
				{loading ? <LoadingRectangle /> : <h3>{renderTypeOfCard(id)}</h3>}
			</S.Infos>
			<S.CardFooter>
				<S.CardFooterItem>
					{paymentMethod === 'credit_card' && (
						<>
							<span>{loading ? <LoadingRectangle /> : cardHolderName}</span>
							<span>
								{loading ? (
									<LoadingRectangle />
								) : (
									renderFlag(cardBrand) || <Icons.Card />
								)}
							</span>
						</>
					)}
				</S.CardFooterItem>
			</S.CardFooter>
		</S.Container>
	);
};

export default CardSmall;
