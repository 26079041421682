/* eslint-disable prefer-const */
import React from 'react';

import { ButtonCustom, SelectReact, UnderlineInput } from '../../..';
import { colors, Icons } from '../../../../assets';
import { useNewPaymentLink } from '../../../../contexts';
import { formatarParaReais, justNumbers } from '../../../../utils';

import * as s from './styled-add-new-item';

const AddNewItem = ({ index, id }) => {
	const {
		managingItems,
		setManagingItems,
		setIsChecked,
		handleCalculateTotal,
	} = useNewPaymentLink();
	const optionsTypeItem = [{ label: 'Bem físico' }, { label: 'Bem virtual' }];

	// retorna o objeto do item atual do array com todos os itens do contex
	const filterCurrentItemValue = managingItems.filter((item) => item.id === id);
	const filterCurrentItem = filterCurrentItemValue[0];
	const subTotal = filterCurrentItem?.subTotal;

	const handleCalculateSubTotal = () => {
		let data = [...managingItems];
		data[index].subTotal = data[index].unit_price * data[index].quantity;
		setManagingItems(data);
	};

	const hasDuplicateValues = (data) => {
		return (new Set(data)).size !== data.length;
	}

	const validateErrors = (event) => {

		const inputName = event.target.name;
		const inputValue = event.target.value;

		let data = [...managingItems];

		const titleArray = data.map(item => item.title);

		const productIdArray = data.map(item => item.productId);

		if (inputName === 'productId' )
		{
			if(hasDuplicateValues(productIdArray) ){
					data[index].error[event.target.name] = 'O id de produto já foi usado em outro item';
					setManagingItems(data);
			} else if (inputValue.length > 0) {
					data[index].error[event.target.name] = '';
					setManagingItems(data);
			} else {
					data[index].error[event.target.name] = 'Campo obrigatório';
					setManagingItems(data);
			}
		}

		if(inputName === 'title'){

			if(hasDuplicateValues(titleArray)){
					data[index].error[event.target.name] = 'O nome do item já foi usado!';
					setManagingItems(data);
			}
			else if (inputValue.length > 0) {
					data[index].error[event.target.name] = '';
					setManagingItems(data);
			} else {
					data[index].error[event.target.name] = 'Campo obrigatório';
					setManagingItems(data);
			}
		}

		if (inputName === 'quantity') {
			const number = justNumbers(inputValue);
			if (number > 0) {
				data[index].error[event.target.name] = '';
				setManagingItems(data);
			} else {
				data[index].error[event.target.name] = 'Campo obrigatório';
				setManagingItems(data);
			}
		}

		if (inputName === 'unit_price') {
			const number = justNumbers(inputValue);
			if (number >= 100) {
				data[index].error[event.target.name] = '';
				setManagingItems(data);
			} else {
				data[index].error[event.target.name] =
					'Valor não pode ser menor que R$1,00';
				setManagingItems(data);
			}
		}
	};

	const handleFormChange = (event) => {
		// Tenho que mexer aqui para validar se os valores são iguais

		let data = [...managingItems];
		if (event.target.name === 'unit_price') {
			data[index].unit_price = justNumbers(event.target.value);
		} else {
			data[index][event.target.name] = event.target.value;
		}
		validateErrors(event);
		setManagingItems(data);
	};

	const handleFormSelectChange = (event) => {
		let data = [...managingItems];
		if (event.label === 'Bem físico') {
			data[index].tangible = true;

			setManagingItems(data);
		} else {
			data[index].tangible = false;

			setManagingItems(data);
		}
	};

	const removeFormFields = () => {
		let data = [...managingItems];

		if (data.length === 1) {
			setManagingItems([
				{
					id: 1,
					error: {},
					title: '',
					unit_price: 100,
					productId: '',
					quantity: 1,
					tangible: true,
					subTotal: 100,
				},
			]);
			setIsChecked(false);
		} else {
			data.splice(index, 1);
			setManagingItems(data);
		}
	};

 const handleChangeProductValue = (event) => {
	if (/^\d*$/.test(event.target.value)) {
		handleFormChange(event);
		handleCalculateSubTotal();
		handleCalculateTotal();
	}
	}

	return (
		<s.ContainerForm>
			<s.FormGroup>
				<s.Row>
					<s.UnderlineInput>
						<p>Nome do produto *</p>
						<UnderlineInput
							required
							id={id}
							name="title"
							type="text"
							margin-top="1.5rem"
							maxLength={68}
							value={filterCurrentItem?.title}
							placeholder="Digite um nome para este produto"
							onChange={(event) => handleFormChange(event)}
							isInvalid={filterCurrentItem?.error?.title}
							erro={filterCurrentItem?.error?.title}
						/>
					</s.UnderlineInput>
					<s.UnderlineInput>
						<p>Quantidade (somente números) *</p>
						<UnderlineInput
							required
							margin-top="1.5rem"
							value={filterCurrentItem?.quantity}
							placeholder="Digite um preço para este produto"
							name="quantity"
							onChange={(event) => {
								handleChangeProductValue(event) 
							}}
							isInvalid={filterCurrentItem?.error?.quantity}
							erro={filterCurrentItem?.error?.quantity}
							maxLength={16}
						/>
					</s.UnderlineInput>
					<s.SelectContainer>
						<SelectReact
							className="select-type-item"
							outlined
							type="number"
							id="select-type-item"
							width="10.375rem"
							height="2.5rem"						
							value={managingItems[id-1]?.tangible? "Bem físico" : "Bem virtual"}
							options={optionsTypeItem}
							name="type"
							onChange={(event) => handleFormSelectChange(event)}
						/>
					</s.SelectContainer>
					<s.CloseButtonDiv>
						<ButtonCustom
							startIcon={
								<Icons.Close height="12" width="12" fill={colors.lightGray} />
							}
							outlined
							width="auto"
							height="auto"
							disableParsedHover
							onClick={() => removeFormFields()}
							className="close-button"
							theme="gray"
							borderColor="transparent"
						/>
					</s.CloseButtonDiv>
				</s.Row>
				<s.Row>
					<s.UnderlineInput>
						<p>Preço da unidade (a partir de R$ 1,00) *</p>
						<UnderlineInput
							required
							min="1"
							margin-top="1.5rem"
							placeholder="Digite uma quantidade para este produto"
							value={formatarParaReais(filterCurrentItem?.unit_price / 100)}
							name="unit_price"
							onChange={(event) => {
								handleFormChange(event);
								handleCalculateSubTotal();
								handleCalculateTotal();
							}}
							onBlur={(e) => validateErrors(e)}
							isInvalid={filterCurrentItem?.error?.unit_price}
							erro={filterCurrentItem?.error?.unit_price}
							maxLength={16}
						/>
					</s.UnderlineInput>
					<s.UnderlineInput>
						<p>ID do produto *</p>
						<UnderlineInput
							required
							type="text"
							margin-top="1.5rem"
							value={filterCurrentItem?.productId}
							placeholder="Digite o ID do produto (opcional)"
							name="productId"
							onChange={(event) => handleFormChange(event)}
							onBlur={(e) => validateErrors(e)}
							isInvalid={filterCurrentItem?.error?.productId}
							erro={filterCurrentItem?.error?.productId}
							maxLength={48}
						/>
					</s.UnderlineInput>
					<s.SubTotalDiv>
						Subtotal:
						{formatarParaReais(subTotal / 100, true)}
					</s.SubTotalDiv>
				</s.Row>
			</s.FormGroup>
		</s.ContainerForm>
	);
};

export default AddNewItem;
