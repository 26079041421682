import React from 'react';

import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Cell,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';
import { Icons } from '../../../../assets';
import { colors } from '../../../../assets/colors';
import { formatarParaReais, formatNumberUnits } from '../../../../utils';

import * as S from './styled-vertical-bar-chart';

const barColor = (type) => {
	if (type === 'MI') {
		return colors.primaryBlue;
	}
	if (type === 'ME') {
		return '#B963ED';
	}
};

const strokeColor = (type) => {
	if (type === 'MI') {
		return colors.graphs.cyan;
	}
	if (type === 'ME') {
		return colors.graphs.pink;
	}
};

export default function VerticalBarChart({ data }) {
	const renderCustomTooltip = ({ active, payload }) => {
		const valorFormatado = formatarParaReais(
			payload[0]?.payload?.amt / 100,
			true
		);
		const renderQuantityOfTransitions = () => {
			const tipoMercado = payload[0]?.payload?.type;
			if (tipoMercado === 'MI') return payload[0]?.payload?.MI;
			if (tipoMercado === 'ME') return payload[0]?.payload?.ME;
		};

		if (active && payload && payload.length) {
			return (
				<S.TooltipContainer>
					<S.Description>
						Transações {payload[0].payload.type}:{' '}
						<S.Value> {renderQuantityOfTransitions()}</S.Value>
					</S.Description>

					<S.Description>
						Total capturado: <S.Value>{valorFormatado}</S.Value>
					</S.Description>
				</S.TooltipContainer>
			);
		}

		return null;
	};

	return data && data?.length > 0 ? (
		<S.Container>
			<ResponsiveContainer width="100%">
				<BarChart
					data={data}
					margin={{
						top: 0,
						right: 50,
						left: 0,
						bottom: 5,
					}}
					layout="vertical"
					barCategoryGap="20%"
				>
					<CartesianGrid
						strokeDasharray="2 3"
						stroke="rgba(177, 182, 182, 0.5)"
						horizontal={false}
					/>
					<XAxis
						dataKey="amt"
						type="number"
						tickLine={false}
						axisLine={{ stroke: 'rgba(177, 182, 182, 0.5)' }}
						tick={{ fontSize: '0.875rem' }}
						stroke={colors.white}
						tickMargin={5}
						tickFormatter={(value) => formatNumberUnits(value / 100)}
					/>
					<YAxis
						dataKey="type"
						type="category"
						axisLine={false}
						tickLine={false}
						tick={{
							fontSize: '0.875rem',
							borderColor: colors.white,
						}}
						stroke={colors.white}
					/>
					<Tooltip
						separator={' '}
						content={renderCustomTooltip}
						wrapperStyle={{
							color: colors.darkTurquoise,
							backgroundColor: 'white',
							borderRadius: '4px',
							border: '1px solid #A5BFD7',
							padding: '1rem 1.125rem',
							boxShadow: '0px 7px 16px -1px rgba(4, 32, 38, 0.9)',
						}}
					/>
					<Bar dataKey="amt" fillOpacity="0.5" radius={[0, 4, 4, 0]}>
						{data?.map((entry) => (
							<Cell
								key={entry.type}
								stroke={strokeColor(entry.type)}
								fill={barColor(entry.type)}
							/>
						))}
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</S.Container>
	) : (
		<Icons.Placeholder />
	);
}
