import React from 'react';
import { colors } from '../colors';

const AddPersonIcon = ({ title, fill, style }) => (
	<svg
		width="23"
		height="20"
		viewBox="0 0 23 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={style}
		className="acquirer-timeout-icon"
	>
		<title>{title || 'Tempo de resposta excedido'}</title>
		<path
			d="M13.4586 10.0469V10.1476L13.5395 10.2076L15.1906 11.4315L14.7058 12.1059L12.6106 10.5695V5.19318L13.4586 5.19326V10.0469Z"
			fill={fill || colors.lightGray}
			stroke={fill || colors.lightGray}
			strokeWidth="0.4"
		/>
		<path
			d="M10.3205 6.36617C10.3205 3.58974 8.04329 1.3125 5.26686 1.3125C2.49043 1.3125 0.213196 3.58974 0.213196 6.36617C0.213196 9.14259 2.49043 11.4198 5.26686 11.4198C8.04329 11.3885 10.3205 9.14259 10.3205 6.36617ZM4.64289 3.4337H5.89061V7.20851H4.64289V3.4337ZM5.14186 9.4545C5.11074 9.4545 5.0794 9.42338 5.01717 9.42338C4.98605 9.42338 4.95471 9.39226 4.89248 9.36093C4.86136 9.32981 4.83002 9.29847 4.79891 9.29847C4.76779 9.26735 4.73645 9.23602 4.73645 9.2049C4.70534 9.17378 4.70534 9.14245 4.674 9.11133C4.64288 9.08021 4.64288 9.04888 4.64288 8.98664V8.86195V8.73727C4.64288 8.70615 4.674 8.67481 4.674 8.61258C4.674 8.58146 4.70512 8.55012 4.73645 8.48789C4.76757 8.45677 4.76757 8.42543 4.79891 8.39432C4.83002 8.3632 4.86136 8.33186 4.89248 8.33186C4.9236 8.30075 4.95493 8.30075 5.01717 8.26941C5.04828 8.26941 5.07962 8.23829 5.14186 8.23829H5.39145C5.42257 8.23829 5.48502 8.26941 5.51614 8.26941C5.54726 8.30053 5.57859 8.30053 5.60971 8.33186C5.64083 8.36298 5.67216 8.39432 5.70328 8.39432C5.7344 8.42543 5.76573 8.45677 5.76573 8.48789C5.79685 8.519 5.79685 8.55034 5.82819 8.61258C5.8593 8.64369 5.8593 8.67503 5.8593 8.73727V8.86195V8.98664C5.8593 9.01776 5.82819 9.0491 5.82819 9.11133C5.82819 9.14245 5.79707 9.17378 5.76573 9.2049C5.73462 9.23602 5.73461 9.26735 5.70328 9.29847C5.67216 9.32959 5.64083 9.36093 5.60971 9.36093C5.57859 9.39204 5.54726 9.39204 5.51614 9.42338C5.48502 9.4545 5.45369 9.4545 5.39145 9.4545H5.26676C5.23543 9.48583 5.17319 9.48583 5.14186 9.4545Z"
			fill={fill || colors.lightGray}
		/>
		<path
			d="M13.0425 0.850021L13.0423 0.85H13.0346C11.7974 0.85 10.6234 1.10381 9.57575 1.54822L9.34716 1.64519L9.53936 1.80241C9.87147 2.07408 10.1763 2.40863 10.4542 2.7483L10.5188 2.82729L10.616 2.79618C11.3838 2.55046 12.2106 2.39771 13.0346 2.39771C17.2255 2.39771 20.6524 5.82443 20.6524 10.0155C20.6524 14.2056 17.2264 17.602 13.0346 17.602C10.0377 17.602 7.46729 15.859 6.21212 13.3487L6.16467 13.2538L6.0594 13.267C5.81035 13.298 5.53761 13.3283 5.26692 13.3283C5.0778 13.3283 4.90302 13.3279 4.73011 13.2992L4.4611 13.2544L4.56736 13.5056C5.96232 16.8025 9.22802 19.15 13.0348 19.15C18.0777 19.15 22.2004 15.0273 22.2004 9.98441C22.2002 4.94318 18.0813 0.854211 13.0425 0.850021Z"
			fill={fill || colors.lightGray}
			stroke={fill || colors.lightGray}
			strokeWidth="0.3"
		/>
	</svg>
);
export default AddPersonIcon;
