import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { colors } from '../../../assets';

export const Container = styled.div`
	display: flex;
	align-items: center;
	background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
	border: 1px solid
		${({ invalid, disabled }) =>
			disabled ? colors.gray : invalid ? colors.red : colors.primaryBlue};
	border-radius: 0.25rem;
	width: ${({ width }) => width || '11.25rem'};
	height: 3.125rem;
	padding: 0.9375rem;

	svg.calendar-icon {
		height: 1.35rem;
		width: 1.35rem;
		pointer-events: none;
		path {
			fill: ${({ invalid, disabled }) =>
				disabled ? colors.gray : invalid && colors.red};
		}
	}

	input {
		margin-left: 0.3125rem;
		background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
		color: ${({ backgroundColor }) =>
			backgroundColor ? colors.white : colors.lightGray};
		font-size: ${({ fontSize }) => fontSize || '1.25rem'};
		flex: 1;
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;

	p {
		font-family: Jost;
		font-size: 0.75rem;
		margin: 1.2rem 0 0.625rem 1.2rem;
		font-weight: 200;
		letter-spacing: 1px;
	}
`;

export const Overlay = styled.div`
	background-color: rgba(0, 0, 0, 0.05);
	position: fixed;
	inset: 0;
	z-index: 1;
`;

export const InputDays = styled(InputMask)`
	text-align: center;
	font-size: 1.1rem;
	font-family: Jost;
	width: 50%;
	height: 100%;
	background: ${({ invalid }) => invalid && '#fbe5ec'};
	overflow: hidden;

	:focus {
		background: ${({ invalid }) => !invalid && 'rgba(0, 0, 0, 0.05)'};
	}
`;
