import React from 'react';
import { ButtonCustom } from '../../..';

import * as s from './styled-contractor-confirmacao';

const ContractorConfirmacao = ({
	setSteps,
	companyName,
	active,
	roleId,
	cnpj,
	cityRegistry,
	stateRegistry,
	neighborhood,
	state,
	streetNumber,
	city,
	sponsorName,
	zipcode,
	phone,
	email,
	complement,
	street,
	sendEmail,
	contaDv,
	agencia,
	agenciaDv,
	conta,
	bankCode,
	saveContractor,
	type,
	setIsCancelarCriacaoOpen,
}) => {
	return (
		<s.Container>
			<s.Tittle>
				CONFIRMAÇÃO DE ADIÇÃO DO CONTRATANTE
				<p>
					Confirme abaixo os dados informados e finalize a adição desse
					contratante
				</p>
			</s.Tittle>
			<s.Form>
				<s.textRow>
					Dados do Contratante
					<ButtonCustom
						placeholder="Editar"
						width="4.875rem"
						height="2.7rem"
						outlined
						onClick={() => setSteps(1)}
					>
						Editar
					</ButtonCustom>
				</s.textRow>
				<s.InputTextField>
					<div id="select">
						Tipo de usuário
						<p>{roleId.label}</p>
					</div>
					<div id="select">
						Status do contratante
						<p>{active ? 'ATIVO' : 'INATIVO'}</p>
					</div>
				</s.InputTextField>
				<s.InputTextField>
					<div id="select">
						Razão Social
						<p>{companyName}</p>
					</div>
					<div id="select">
						CNPJ
						<p>{cnpj}</p>
					</div>
					<div id="select">
						Inscrição Estadual
						<p>{stateRegistry}</p>
					</div>
					<div id="select">
						Inscrição Municipal
						<p>{cityRegistry}</p>
					</div>
				</s.InputTextField>
			</s.Form>
			<s.Form>
				<s.textRow>
					Contatos
					<ButtonCustom
						placeholder="Editar"
						width="4.875rem"
						height="2.7rem"
						outlined
						onClick={() => setSteps(2)}
					>
						Editar
					</ButtonCustom>
				</s.textRow>

				<s.InputTextField>
					<div id="select">
						CEP
						<p>{zipcode}</p>
					</div>
					<div id="select">
						Endereço
						<p>{street}</p>
					</div>
					<div id="select">
						Número
						<p>{streetNumber}</p>
					</div>
					<div id="select">
						Complemento
						<p>{complement}</p>
					</div>
				</s.InputTextField>
				<s.InputTextField>
					<div id="select">
						Bairro
						<p>{neighborhood}</p>
					</div>
					<div id="select">
						Cidade
						<p>{city}</p>
					</div>
					<div id="select">
						Estado
						<p>{state}</p>
					</div>
				</s.InputTextField>
				<s.InputTextField>
					<div id="select">
						Telefone
						<p>{phone}</p>
					</div>
					<div id="select">
						E-mail
						<p>{email}</p>
					</div>
				</s.InputTextField>
				<s.InputTextField>
					<div id="select">
						Responsável para contato
						<p>{sponsorName}</p>
					</div>
					<div id="select">
						Enviar convite via e-mail
						<p>{sendEmail ? 'Sim' : 'Não'}</p>
					</div>
				</s.InputTextField>
			</s.Form>
			<s.Footer>
				<s.textRow>
					Conta bancária
					<ButtonCustom
						placeholder="Editar"
						width="4.875rem"
						height="2.7rem"
						outlined
						onClick={() => setSteps(3)}
					>
						Editar
					</ButtonCustom>
				</s.textRow>
				<s.InputTextField>
					<div id="select">
						Banco
						<p>{bankCode.label}</p>
					</div>
				</s.InputTextField>
				<s.InputTextField>
					<div id="select">
						Agência
						<p>{agencia}</p>
					</div>
					<div id="select">
						Digito da agência
						<p>{agenciaDv}</p>
					</div>
					<div id="select">
						Conta
						<p>{conta}</p>
					</div>
					<div id="select">
						Digito da conta
						<p>{contaDv}</p>
					</div>
					<div id="select">
						Tipo da conta
						<p>{type.label}</p>
					</div>
				</s.InputTextField>
			</s.Footer>
			<s.ButtonGroup>
				<ButtonCustom
					outlined
					theme="green"
					onClick={() => setIsCancelarCriacaoOpen(true)}
				>
					Cancelar
				</ButtonCustom>
				<div id="step">
					<ButtonCustom theme="green" onClick={() => setSteps(3)}>
						Voltar
					</ButtonCustom>

					<ButtonCustom theme="green" onClick={() => saveContractor()}>
						Adicionar contratante
					</ButtonCustom>
				</div>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default ContractorConfirmacao;
