import React from 'react';
import { colors } from '../../../assets';

const ClientesIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="clientes-icon"
		width="34" 
    height="33" 
    viewBox="0 0 34 33" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title}</title>
    <path 
      d="M13.1331 0.75C9.6903 0.75 6.89935 3.54095 6.89935 6.98377C6.89935 10.4266 9.6903 13.2175 13.1331 13.2175C16.5759 13.2175 19.3669 10.4266 19.3669 6.98377C19.3669 3.54095 16.5759 0.75 13.1331 0.75Z" 
      fill={fill || colors.offWhite}
      />
		<path 
      d="M6.48377 16.5422C3.04095 16.5422 0.25 19.3332 0.25 22.776V24.7513C0.25 26.0034 1.15744 27.071 2.39321 27.2728C7.66053 28.1328 13.0053 28.3559 18.3128 27.9422C18.3128 27.0916 18.3128 27.4064 18.3128 26.9318C17.289 26.1006 14.7955 24.4383 14.7955 22.776C14.7955 22.776 14.7955 21.1136 16.4578 20.2825C17.3228 19.9941 18.0877 19.8058 18.718 19.6828C19.9046 19.4513 20.6136 19.4513 20.6136 19.4513C21.4448 17.789 21.4448 17.789 21.9232 16.9195C21.2556 16.6754 20.5346 16.5422 19.7825 16.5422H19.2158C18.9091 16.5422 18.6044 16.5907 18.3128 16.6859L16.8741 17.1557C14.4433 17.9494 11.823 17.9494 9.39217 17.1557L7.95339 16.6859C7.66185 16.5907 7.35709 16.5422 7.05041 16.5422H6.48377Z" 
      fill={fill || colors.offWhite}
    />
    <path 
      d="M26.0022 16.4349C25.6945 15.855 24.9289 15.855 24.6212 16.4349L22.4545 20.5182C22.3025 20.8047 22.0398 21.0001 21.7404 21.0496L17.6799 21.7203C16.9979 21.8329 16.7587 22.7745 17.2898 23.2558L20.3147 25.9964C20.5947 26.25 20.7264 26.6502 20.6584 27.0408L19.8671 31.585C19.742 32.3034 20.4316 32.8615 21.0233 32.5208L24.8468 30.3192C25.1378 30.1516 25.4856 30.1516 25.7766 30.3192L29.6001 32.5208C30.1918 32.8615 30.8814 32.3034 30.7563 31.585L29.965 27.0408C29.897 26.6502 30.0287 26.25 30.3087 25.9964L33.3336 23.2558C33.8647 22.7746 33.6255 21.8329 32.9435 21.7203L28.883 21.0496C28.5835 21.0001 28.3209 20.8047 28.1689 20.5182L26.0022 16.4349Z" 
      fill={fill || colors.offWhite}
    />
	
	</svg>
);

export default ClientesIcon;
