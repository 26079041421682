import React, { memo } from 'react';
import ReactPaginate from 'react-paginate';
import * as s from './styled-pagination';
import { Icons } from '../../../assets';

const Pagination = ({ paginaAtual, totalPaginas, onClick, disabled, id }) => {
	const Anterior = () => (
		<s.BotaoIcone disabled={disabled || paginaAtual === 0} id="pagina-anterior">
			<Icons.Chevron title="Página anterior" />
		</s.BotaoIcone>
	);

	const Proximo = () => (
		<s.BotaoIcone
			id="pagina-seguinte"
			disabled={disabled || paginaAtual + 1 === totalPaginas}
		>
			<Icons.Chevron title="Página seguinte" />
		</s.BotaoIcone>
	);

	return (
		<s.Paginacao className="paginacao" disabled={disabled} id={id}>
			<ReactPaginate
				previousLabel={<Anterior />}
				nextLabel={<Proximo />}
				pageClassName="page-item"
				pageLinkClassName="page-link"
				previousClassName="page-item"
				previousLinkClassName="page-link previous-link"
				nextClassName="page-item"
				nextLinkClassName="page-link next-link"
				breakLabel="..."
				breakClassName="page-item"
				breakLinkClassName="page-link break"
				pageCount={totalPaginas}
				marginPagesDisplayed={1}
				pageRangeDisplayed={3}
				onPageChange={onClick}
				containerClassName="pagination"
				activeClassName="active"
				forcePage={paginaAtual}
			/>
		</s.Paginacao>
	);
};
export default memo(Pagination);
