import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	grid-area: ${(props) => (props.div ? props.div : 'none')};
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	flex: 1;
	justify-content: center;
	border-radius: 0.9375rem;
	min-height: 95px;
	height: 100%;
	width: 100%;
	gap: 1rem;
`;

export const ContainerContent = styled.div`
	grid-area: ${(props) => (props.div ? props.div : 'none')};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex: 1;
	justify-content: space-between;
	padding: ${({ header }) => (header ? ' 24px 32px 24px 32px ' : '24px')};
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	min-height: 95px;
	height: 100%;
	width: 100%;
`;

export const ButtonsTabs = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	height: 3.13rem;
	background: ${colors.background};
	padding: 1px;
	margin-top: 1rem;
	border: 0;
	gap: 2.5rem;

	@keyframes aparecer {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes slideLeft {
		0% {
			transform: translateX(-40px);
		}
	}

	@keyframes slideRight {
		0% {
			transform: translateX(40px);
		}
	}

	.selected {
		font-weight: 600;
		color: ${colors.primaryBlue};
		border-bottom: 1px solid ${colors.primaryBlue};
		animation: aparecer 0.5s ease-out;
		border-radius: 0;
	}
`;

export const Divisao = styled.div`
	width: 100%;
	height: 2px;
	background-image: linear-gradient(
		to right,
		${colors.darkGray},
		${colors.background},
		${colors.darkGray}
	);
`;

export const Button = styled.button`
	background: none;
	font-size: 1.25rem;
	font-weight: 400;
	letter-spacing: 0.02em;
	position: relative;
	text-align: center;
	text-transform: none;
	color: ${colors.white};
	border-radius: 0.9375rem;

	:hover {
		color: ${colors.primaryBlue};
	}
`;

export const CardsDiv = styled.div`
	width: 100%;
	display: flex;
	flex: 1;
	align-items: stretch;
	flex-wrap: wrap;
	list-style: none;
	margin-top: 5px;
`;

export const Card = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex: 1;
	position: relative;
	padding: 1.5rem;
	margin: 0 10px 20px 10px;
	min-width: 200px;
	border-radius: 15px;
	border: 2px solid
		${({ color }) =>
			color === 'green'
				? colors.green
				: color === 'red'
				? colors.primaryRed
				: colors.violet};

	h3 {
		font-size: 14px;
		font-weight: 500;
		color: ${({ color }) =>
			color === 'green'
				? colors.green
				: color === 'red'
				? colors.primaryRed
				: colors.violet};
	}

	span.valor {
		color: ${colors.white};
		font-size: 2.25rem;
		font-weight: 500;
	}

	:after {
		content: '';
		position: absolute;
		z-index: 1;
		width: 70%;
		height: 70%;
		background: ${({ color }) =>
			color === 'green'
				? colors.green
				: color === 'red'
				? colors.primaryRed
				: colors.violet};
		opacity: 0.2;
		filter: blur(50px);
		color: ${colors.white};
	}
`;

export const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 3;

	.loading-rectangle {
		height: 30px;
		margin-top: 1.25rem;
	}

	h1 {
		margin-top: 24px;
		margin-bottom: 14px;
		font-size: 24px;
		color: #454550;
		font-weight: 700;
	}

	svg.question-icon {
		height: 1.25rem;
		width: 1.25rem;
	}
`;

export const Sinal = styled.span`
	display: inline-block;
	text-align: center;
	margin-top: 10px;
	color: ${({ negativo, neutro }) =>
		negativo ? colors.primaryRed : neutro ? colors.white : colors.green};
	font-weight: 600;

	svg.plus-icon {
		width: 1.88rem;
		height: 1.88rem;
		margin: 0 5px -4px 0;
	}
	svg.minus-icon {
		width: 1.88rem;
		height: 0.35125rem;
		margin: 0 5px 4px 0;
	}

	.cifrao {
		color: ${colors.white};
		font-size: 1.5rem;
		margin-right: 0.625rem;
	}
	.valor {
		font-family: Jost;
		color: ${colors.white};
		font-size: 2.25rem;
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	width: 100%;

	${({ headerTable }) =>
		headerTable &&
		`
	width: 100%;
	margin: 20px 0;
	justify-content: space-between;

	svg.upload-icon {
		height: 1.7rem;
		width: 1.25rem;
	}

`};
`;

export const RowFooter = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	margin: 20px 0;
	justify-content: flex-end;

	div {
		margin: 0;
	}
`;

export const Data = styled.p`
	color: ${colors.white};
	font-size: 1rem;
	font-weight: 300;
`;

export const Right = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	.button-custom {
		width: auto;
		padding: 0.375rem 1.5rem;
		white-space: nowrap;
	}

	.select-react {
		width: 15.31rem;
		height: 3.13rem;

		@media (max-width: 960px) {
			width: calc(15.31rem + 10px);
		}
	}
`;

export const Pagination = styled.div`
	max-width: 22rem;
`;

export const ButtonFilter = styled.button`
	border: none;
	color: ${colors.darkGray};
	margin-left: 10px;
	background: ${colors.primaryBlue};
	border-radius: 0.25rem;
	display: inline-block;
	font-size: 1.1rem;
	font-weight: 600;
	padding: 0.5rem 1rem;
	height: 2.7rem;
	:disabled {
		opacity: 0.6;
	}
`;

export const FilterTabs = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-weight: bold;
	font-size: 16px;
	padding-top: ${({ dataInvalida }) => dataInvalida && '1.2rem'};
	color: ${colors.primaryRed};
	width: 100%;
`;

export const FilterTab = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: auto;
	width: 40%;
	padding: 0.375rem 1rem;
	gap: 7.5rem;

	@media (max-width: 1366px) {
		gap: 1rem;
		align-items: stretch;
	}
`;

export const Options = styled.div`
	width: 10rem;

	ul {
		list-style: none;
	}

	button {
		background: none;
		padding: 0.5rem 1rem;
		font-size: 0.875rem;
		width: 100%;

		:hover {
			background: rgba(0, 0, 0, 0.1);
		}
	}
`;

export const ButtonGroup = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

export const CheckboxGroup = styled.div`
	display: flex;
	flex-direction: column;
	padding-right: 37.5rem;

	h3 {
		font-family: IBM Plex Sans;
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		color: ${colors.white};
		letter-spacing: 0em;
	}
	div {
		display: flex;
		justify-content: flex-start;
		gap: 1rem;
		flex-direction: row;
	}
`;

export const CheckboxTable = styled.div`
	display: flex;
	flex-direction: column;
	h3 {
		font-family: IBM Plex Sans;
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		color: ${colors.white};
		letter-spacing: 0em;
	}
	div {
		display: flex;
		gap: 1rem;
		flex-direction: row;
	}
`;
