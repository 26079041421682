import React from 'react';
import { useHistory } from 'react-router';
import { Icons } from '../../../../assets';
import ContainerModal from '../container-modal';
import { ButtonCustom } from '../../../../components';

import * as s from './styled-modal-cancelar-criacao-recebedor';

const ModalCancelar = ({ isOpen, closeModal }) => {
	const history = useHistory();
	return (
		<ContainerModal isOpen={isOpen} closeModal={closeModal}>
			<s.Content>
				<s.Imagem>
					<Icons.InfoTriangleIcon />
				</s.Imagem>
				<s.Title>CANCELAR CRIAÇÃO DE RECEBEDOR</s.Title>
				<s.Subtitle>
					Tem certeza que deseja cancelar a criação deste recebedor? <br />{' '}
					Todas as informações serão perdidas.
				</s.Subtitle>
				<s.ButtonGroup>
					<ButtonCustom width="15rem" textTransform="none" onClick={closeModal}>
						Não, quero manter
					</ButtonCustom>
					<ButtonCustom
						textTransform="none"
						outlined
						width="15rem"
						theme="alert-white"
						onClick={() => history.push('/recebedores')}
						className="button-custom-cancelar"
					>
						Sim, quero cancelar
					</ButtonCustom>
				</s.ButtonGroup>
			</s.Content>
		</ContainerModal>
	);
};

export default ModalCancelar;
