import React, { useState, createContext, useContext } from 'react';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';
import { ModalConfirmacao } from '../components';
import { useAuth } from '../hooks';
import { deleteAntecipacao } from '../services';

const SidemenuContext = createContext();

export function useSidemenu() {
	return useContext(SidemenuContext);
}

export function SidemenuProvider({ children }) {
	const history = useHistory();
	const { currentUser } = useAuth();
	const idRecebedor = currentUser?.recipientId;
	const antecipacao = localStorage.getItem('anticipation');
	const { anticipationId } =
		antecipacao !== null ? JSON.parse(antecipacao) : '';

	const [loadingDeletarAntecipacao, setLoadingDeletarAntecipacao] =
		useState(false);
	const [modalDeletarAntecipacao, setModalDeletarAntecipacao] = useState(false);

	const handleDeleteAnticipation = (onClick, redirect) => {
		setModalDeletarAntecipacao({ onClick, redirect, open: true });
	};

	function deletarAntecipacao() {
		deleteAntecipacao(idRecebedor, anticipationId)
			.then(() => {
				localStorage.removeItem('anticipation');
				modalDeletarAntecipacao.onClick();
				history.push(modalDeletarAntecipacao.redirect);
			})
			.catch(() =>
				toast.error('Houve um erro ao deletar a antecipação. Tente novamente')
			)
			.finally(() => {
				setLoadingDeletarAntecipacao(false);
				setModalDeletarAntecipacao({ open: false });
			});
	}

	const value = { handleDeleteAnticipation };

	return (
		<SidemenuContext.Provider value={value}>
			<ModalConfirmacao
				isOpen={modalDeletarAntecipacao?.open}
				title="DELETAR ANTECIPAÇÃO"
				text="Atenção: ao sair desta tela a antecipação será deletada e os dados preenchidos serão perdidos."
				question="Deseja sair mesmo assim?"
				onPositivePress={() => {
					setLoadingDeletarAntecipacao(true);
					deletarAntecipacao();
				}}
				negativeButtonLabel="Não quero sair"
				positiveButtonLabel="Sim, quero deletar e sair"
				isLoading={loadingDeletarAntecipacao}
				closeModal={() => setModalDeletarAntecipacao({ open: false })}
			/>
			{children}
		</SidemenuContext.Provider>
	);
}
