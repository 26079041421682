import styled from 'styled-components';
import { colors } from '../../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
`;

export const TooltipContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 10rem;
	font-family: Jost;
	font-size: 0.875rem;
	background-color: ${colors.lightBlack};
	width: auto;
	height: auto;
	border-radius: 0.25rem;
`;

export const Description = styled.p`
	display: flex;
	flex-direction: column;
	line-height: 1.125rem;
	justify-content: flex-start;
	gap: 1rem;
	color: ${colors.offWhite};
	width: 100%;
	height: 100%;
	padding: 1.5rem 1.5rem;
`;

export const Value = styled.span`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
	height: 100%;
	font-weight: 500;
	line-height: 1.125rem;
	color: ${colors.lightGray};
`;
