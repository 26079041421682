import React from 'react';

import { ButtonCustom, SearchField } from '../../../../components';
import { colors } from '../../../../assets/colors';

import * as s from './styles';

const Filters = (props) => {
	const { searchValue, setSearchValue, handleSubmit, page, setPage, loading } =
		props;
	const pagina = 1;
	const isCleanFiltersDisabled = (loading || searchValue === '') && page === 1;

	const isSubmitDisabled =
		loading || searchValue === '' || page < 1 || page > 100;

	const handleCleanFilters = () => {
		setSearchValue('');
		setPage(1);
		handleSubmit();
	};

	const handleSubmitFilters = () => {
		setPage(1);
		handleSubmit(pagina, searchValue);
	};

	return (
		<s.Container>
			<SearchField
				value={searchValue}
				placeholder="Filtre por nome da conta bancária, ID da conta bancária ou ID do recebedor"
				onChange={(e) => setSearchValue(e.target.value)}
				border={`1px solid ${colors.gray}`}
				onKeyPress={(e) => {
					if (e.key === 'Enter') {
						handleSubmit(pagina, searchValue);
						setSearchValue('');
					}
				}}
			/>
			<s.ButtonGroup>
				<ButtonCustom
					outlined
					theme={isCleanFiltersDisabled ? 'gray' : 'green'}
					width="12.5rem"
					disabled={isCleanFiltersDisabled}
					onClick={() => handleCleanFilters()}
					borderRadius="0.9375rem"
				>
					Limpar filtros
				</ButtonCustom>
				<ButtonCustom
					theme={isSubmitDisabled ? 'gray' : 'green'}
					width="12.5rem"
					disabled={isSubmitDisabled}
					onClick={() => {
						handleSubmitFilters();
						setSearchValue('');
					}}
					borderRadius="0.9375rem"
				>
					Filtrar
				</ButtonCustom>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default Filters;
