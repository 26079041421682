import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 2.5rem 1.5rem 2.5rem 1.5rem;
	.loading-rectangle {
		height: 2rem;
		width: calc(100% - 2px);
	}
`;

export const Tittle = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	color: ${colors.white};
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 32px;
	p {
		height: 2rem;
		color: ${colors.lightGray};
		font-family: IBM Plex Sans;
		font-size: 20px;
		line-height: 26px;
		line-height: 100%;
		vertical-align: Top;
		font-weight: 400;
	}
`;

export const Form = styled.div`
	margin-top: 3.125rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 3.5rem;
	border-bottom: 1px solid ${colors.gray};
	div#select {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: ${colors.white};
		p {
			color: ${colors.lightGray};
		}
	}

	.custom-select-input {
		border-bottom: 1px solid ${colors.primaryBlue};
		border-radius: 0;
		height: 100%;
		padding-top: 0.4375rem;
	}
`;

export const Footer = styled.div`
	margin-top: 3.125rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 3.5rem;
	div#select {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: ${colors.white};
	}

	.custom-select-input {
		border-bottom: 1px solid ${colors.primaryBlue};
		border-radius: 0;
		height: 100%;
		padding-top: 0.4375rem;
	}
`;

export const StatusContratante = styled.div`
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: ${colors.white};
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 175px;
	margin-top: 3rem;
	.switch-custom {
		background-color: black;
	}
`;

export const textRow = styled.div`
	display: flex;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-size: 16px;
	line-height: 21px;
	flex-direction: row;
	color: ${colors.white};
	max-width: 250px;
	margin-top: 3rem;
	gap: 2.25rem;
`;
export const InputTextField = styled.div`
	margin-top: 3.125rem;
	display: flex;
	flex-direction: row;
	width: 100%;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: ${colors.white};
	gap: 2rem;
	.underlineinput {
		display: flex;
		flex-direction: column;
	}
`;

export const ButtonGroup = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: 3.5rem;
	justify-content: space-between;
	margin-top: 6.125rem;
	div#step {
		display: flex;
		flex-direction: row;
		gap: 1.5rem;
	}
`;
