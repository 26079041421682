import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getDashboardSummary, getMarketType } from '../../services';
import { useAuth } from '../../hooks';
import {
	CardSmall,
	CardTall,
	CardPayment,
	CardGraphs,
	GridHeader,
	Loader,
} from '../../components';
import { formatarParaReais } from '../../utils';
import { colors, images } from '../../assets';

import * as s from './styled-dashboard';

const Dashboard = () => {
	const { currentUser } = useAuth();
	const recipientId = currentUser?.recipientId;
	const [summary, setSummary] = useState({});
	const totalAmount = summary?.totalAmount && summary?.totalAmount;
	const [selectedFilter, setSelectedFilter] = useState('days-7');
	const [isMarketTypeLoading, setIsMarketTypeLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [marketTypes, setMarketTypes] = useState({});
	const [averageTicket, setAverageTicket] = useState();

	const mi = marketTypes[0]?.averageAmount;
	const me = marketTypes[1]?.averageAmount;

	const intervaloInicial = () => {
		const data = new Date();
		const diaHoje = data.getDate();
		const mesHoje = data.getMonth() + 1;
		const anoHoje = data.getFullYear();
		data.setDate(diaHoje - 7);
		const diaInicio = data.getDate();
		const mesInicio = data.getMonth() + 1;
		const anoInicio = data.getFullYear();
		return {
			from: { day: diaInicio, month: mesInicio, year: anoInicio },
			to: { day: diaHoje, month: mesHoje, year: anoHoje },
		};
	};
	const [intervaloDatas, setIntervaloDatas] = useState(intervaloInicial);

	function dataFormatada() {
		if (intervaloDatas?.day) {
			return {
				from: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
				to: `${intervaloDatas.year}/${intervaloDatas.month}/${intervaloDatas.day}`,
			};
		}
		const dataInicial = intervaloDatas.from;
		const dataFinal = intervaloDatas.to;

		return {
			from: `${dataInicial.year}/${dataInicial.month}/${dataInicial.day}`,
			to: `${dataFinal.year}/${dataFinal.month}/${dataFinal.day}`,
		};
	}

	function datasEstaoCompletas() {
		const dataInicial = intervaloDatas.from;
		const dataFinal = intervaloDatas.to;
		const dataUnica = intervaloDatas.day;

		if (dataUnica) return true;
		if (!dataInicial || !dataFinal) return false;
		if (!dataInicial.day || !dataInicial.month || !dataInicial.year)
			return false;
		if (!dataFinal.day || !dataFinal.month || !dataFinal.year) return false;
		return true;
	}

	const haveBothMarketTypes = () => {
		if (mi !== '0' || me !== '0') {
			return false;
		}
		if (mi === '0' && me === '0' && averageTicket.averageAmount !== 0) {
			return true;
		}
	};

	const getSummary = async () => {
		if (datasEstaoCompletas()) {
			setIsLoading(true);
			await getDashboardSummary(
				recipientId,
				dataFormatada().from,
				dataFormatada().to
			)
				.then((resp) => {
					setSummary(resp);
				})
				.catch(() => {
					setSummary(null);
				});
			await getMarketType(recipientId, dataFormatada().from, dataFormatada().to)
				.then((resp) => {
					const markets = resp.filter((market) => market.type !== 'TOTAL');
					setAverageTicket(resp.filter((market) => market.type === 'TOTAL'));
					setMarketTypes(markets);
				})
				.catch(() => toast.error('Erro ao buscar Tipos de Mercado'))
				.finally(() => {
					setIsMarketTypeLoading(false);
					setIsLoading(false);
				});
		}
	};

	useEffect(() => {
		getSummary();
	}, [intervaloDatas]);

	return (
		<>
			<GridHeader
				loading={isLoading}
				handleSubmit={getSummary}
				intervaloDatas={intervaloDatas}
				setIntervaloDatas={setIntervaloDatas}
				selectedDayRange={intervaloDatas}
				setSelectedDayRange={setIntervaloDatas}
				selected="anterior"
				selectedFilter={selectedFilter}
				setSelectedFilter={setSelectedFilter}
			/>
			{isLoading ? (
				<s.CenterLoader>
					<Loader color={colors.primaryBlue} />
				</s.CenterLoader>
			) : !summary ? (
				<s.NoContentContainer>
					<s.NoContentMessage>
						<s.NoContentTitle>Ooops! Que pena :(</s.NoContentTitle>
						<s.NoContentText>
							Não encontramos registros no período selecionado. <br /> Tente
							novamente selecionando outra data.
						</s.NoContentText>
					</s.NoContentMessage>
					<img
						className="nenhum-registro"
						src={images.nenhumRegistro}
						alt="Nenhum registro"
					/>
				</s.NoContentContainer>
			) : (
				<s.Container
					ticketMedioGrid={haveBothMarketTypes()}
					display={summary ? 'grid' : 'flex'}
				>
					<CardSmall
						id="transactions-number"
						div="div1"
						title="Nº de transações"
						totalElements={summary?.totalElements}
					/>
					{!isMarketTypeLoading && haveBothMarketTypes() ? (
						<CardSmall
							id="average-ticket"
							div="div2"
							title="Ticket médio"
							averageAmount={formatarParaReais(
								Number(averageTicket[0].averageAmount) / 100,
								true
							)}
						/>
					) : (
						<>
							<CardSmall
								id="average-mi"
								div="div2"
								title="Ticket médio - Mercado Interno"
								averageAmount={formatarParaReais(mi / 100, true)}
							/>
							<CardSmall
								id="average-me"
								div="div12"
								title="Ticket médio - Mercado Externo"
								averageAmount={formatarParaReais(me / 100, true)}
							/>
						</>
					)}
					<CardSmall
						id="total-amount"
						div="div3"
						title="Volume Transacionado"
						totalAmount={formatarParaReais(totalAmount / 100, true)}
					/>
					<CardTall
						id="pie-chart"
						div="div4"
						title="Transações por status"
						data={summary?.status}
					/>
					<CardPayment
						id="payment-methods"
						div="div5"
						title="Formas de pagamento"
						data={summary?.paymentMethods}
					/>
					<CardPayment
						id="card-brands"
						div="div6"
						title="Bandeiras mais utilizadas"
						data={summary?.brands}
					/>
					<CardPayment
						id="refuse-reasons"
						div="div7"
						title="Motivos de recusa"
						data={summary?.refuseReason}
					/>
					<CardGraphs
						id="installments-chart"
						div="div8"
						title="Quantidade de parcelas no cartão de crédito"
						data={summary?.installmentsCreditCard}
					/>
					<CardGraphs
						id="volume-chart"
						div="10"
						title="Volume por dia da semana"
						data={summary?.transactionByDay}
					/>
					<CardGraphs
						id="market-types-chart"
						div="11"
						title="Tipos de mercado"
						loading={isMarketTypeLoading}
						data={marketTypes}
					/>
				</s.Container>
			)}
		</>
	);
};

export default Dashboard;
