import React from 'react';

import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	Label,
} from 'recharts';
import { colors } from '../../../../assets/colors';

import * as S from './styled-bar-chart';

export default function BarChartInstallments({ data }) {
	// ordena as parcelas em ordem crescente
	const ascOrder = data?.sort((a, b) => a.type - b.type);
	const xAxisTickFormatter = (value) => `${value}x`;

	const renderCustomTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) {
			return (
				<S.TooltipContainer>
					<S.Description>
						Nº de transações:{' '}
						<S.Value>{`${payload[0]?.payload?.count}`}</S.Value>
					</S.Description>
				</S.TooltipContainer>
			);
		}

		return null;
	};

	return (
		<S.Container>
			<ResponsiveContainer>
				<BarChart
					data={ascOrder}
					margin={{
						top: 10,
						right: 20,
						left: 20,
						bottom: 0,
					}}
					barSize={20}
				>
					<CartesianGrid
						strokeDasharray="2 3"
						vertical={false}
						stroke="rgba(177, 182, 182, 0.5)"
					/>
					<XAxis
						dataKey="type"
						tick={{ fontSize: '0.875rem', color: colors.white }}
						tickFormatter={xAxisTickFormatter}
						tickLine={false}
						axisLine={false}
						stroke={colors.white}
						tickMargin={10}
					/>
					<YAxis
						dataKey="count"
						tick={{ fontSize: '0.875rem', color: colors.white }}
						tickLine={false}
						axisLine={false}
						stroke={colors.white}
					>
						<Label
							angle={-90}
							position="insideLeft"
							fill={colors.white}
							stroke={colors.white}
							strokeWidth={0.5}
							style={{
								fontSize: '0.75rem',
								color: colors.white,
								textAnchor: 'middle',
							}}
						>
							Nº de transações
						</Label>
					</YAxis>
					<Tooltip
						separator={' '}
						content={renderCustomTooltip}
						wrapperStyle={{
							color: colors.darkTurquoise,
							backgroundColor: 'white',
							borderRadius: '4px',
							border: '1px solid #A5BFD7',
							padding: '1rem 1.125rem',
							boxShadow: '0px 7px 16px -1px rgba(4, 32, 38, 0.9)',
						}}
					/>
					<Bar dataKey="count" fill={colors.primaryBlue} name=" " />
				</BarChart>
			</ResponsiveContainer>
		</S.Container>
	);
}
