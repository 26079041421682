import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { validateBr } from 'js-brasil';
import cep from 'cep-promise';
import {
	UnderlineInput,
	ButtonCustom,
	SelectReact,
	CheckboxCustom,
	ModalCancelarEdicao,
	ModalSucessoEdicao,
	ModalFalhaEdicao,
} from '../../components';

import * as s from './styled-contractor';

import {
	getRoleUser,
	getContractorById,
	postUpdateContractor,
	getBanks,
	getBankAccountTypes,
} from '../../services';

import {
	mascaraCNPJ,
	capitalizeFirstLetterSpace,
	justNumbers,
	mascaraTelefone,
	maskCep,
	removeFirstsNumbers,
	validaCNPJ,
	validateEmail,
} from '../../utils';

export default function Contractor() {
	const history = useHistory();
	const routeParams = useParams();
	const { contractorId } = routeParams;

	const [cnpj, setCnpj] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [stateRegistry, setStateRegistry] = useState('');
	const [cityRegistry, setCityRegistry] = useState('');
	const [roleId, setRoleId] = useState('');
	const [active, setActive] = useState(false);

	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [sponsorName, setSponsorName] = useState('');

	const [street, setStreet] = useState('');
	const [streetNumber, setStreetNumber] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [country, setCountry] = useState('Brazil');
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [neighborhood, setNeighborhood] = useState('');
	const [complement, setComplement] = useState('');

	const [bankCode, setBankCode] = useState('');
	const [agencia, setAgencia] = useState('');
	const [agenciaDv, setAgenciaDv] = useState('');
	const [conta, setConta] = useState('');
	const [type, setType] = useState('');
	const [contaDv, setContaDv] = useState('');

	const [optionsBank, setOptionsBank] = useState();
	const [optionsBankType, setOptionsBankType] = useState([]);
	const [optionsRoleType, setOptionsRoleType] = useState();

	const [sendEmail, setSendEmail] = useState(false);
	const [readOnly, setReadOnly] = useState(true);

	const [isSuccess, setIsSuccess] = useState(false);
	const [isFalha, setIsFalha] = useState(false);
	const [isCancelarEdicaoOpen, setIsCancelarEdicaoOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [erros, setErros] = useState({});

	function verifyEmptyInputs() {
		const arrayOfErros = Object.entries(erros);
		if (arrayOfErros.some((item) => item[1] !== '')) {
			return true;
		}
		return false;
	}

	useEffect(() => {
		setIsLoading(true);
		getRoleUser()
			.then((respRole) => {
				setOptionsRoleType(
					respRole.map((item) => {
						if (item.label === 'contractor')
							return { value: item.id, label: 'Contratante' };
						return { value: item.id, label: 'Administrador' };
					})
				);
			})
			.catch(() => toast.error('Erro ao buscar tipos de usuário'));

		getBanks()
			.then((resp) =>
				setOptionsBank(
					resp.map((item) => ({ value: item.code, label: item.name }))
				)
			)
			.catch(() => toast.error('Erro ao buscar bancos'));

		getBankAccountTypes()
			.then((resp) =>
				setOptionsBankType(
					resp.map((item) => ({
						value: item,
						label: capitalizeFirstLetterSpace(item),
					}))
				)
			)
			.catch(() => toast.error(''));
	}, []);

	useEffect(() => {
		if (optionsRoleType && optionsBank) {
			getContractorById(contractorId)
				.then((resp) => {
					setCnpj(resp.cnpj);
					setCompanyName(resp.companyName);
					setStateRegistry(resp.stateRegistry);
					setCityRegistry(resp.cityRegistry);

					setRoleId(
						...optionsRoleType.filter((item) => item.value === resp.roleId)
					);
					setActive(resp.active);

					setPhone(removeFirstsNumbers(resp.contact.phone));
					setEmail(resp.contact.email);
					setSponsorName(resp.contact.sponsorName);

					setStreet(resp.address.street);
					setStreetNumber(resp.address.streetNumber);
					setZipcode(resp.address.zipcode);
					setCountry(resp.address.country);
					setState(resp.address.state);
					setCity(resp.address.city);
					setNeighborhood(resp.address.neighborhood);
					setComplement(resp.address.complement);

					setBankCode(
						...optionsBank.filter(
							(item) => item.value === resp.bankAccount.bankCode
						)
					);
					setAgencia(resp.bankAccount.agencia);
					setAgenciaDv(resp.bankAccount.agenciaDv);
					setConta(resp.bankAccount.conta);
					setType(
						...optionsBankType.filter((item) => {
							if (item.value === resp.bankAccount.type) {
								return item;
							}
							return '';
						})
					);
					setContaDv(resp.bankAccount.contaDv);
					setIsLoading(false);
				})
				.catch(() => {
					toast.error('Erro ao buscar dados do contratante');
					setOptionsBank([]);
					setOptionsBankType([]);
					setOptionsRoleType([]);
					setIsLoading(false);
				});
		}
	}, [optionsRoleType, optionsBank]);

	function readOnlyClick() {
		setReadOnly(!readOnly);
	}

	async function saveContractor() {
		const newDataContractor = {
			cnpj: `${justNumbers(cnpj)}`,
			companyName,
			stateRegistry,
			cityRegistry,
			roleId: Number(roleId.value),
			active,
			contact: {
				phone: `+55${justNumbers(phone)}`,
				email,
				sponsorName,
			},
			address: {
				street,
				streetNumber,
				zipcode,
				country,
				state,
				city,
				neighborhood,
				complement: complement || '',
			},
			bankAccount: {
				bankCode: bankCode.value,
				agencia,
				agenciaDv: agenciaDv || '',
				conta,
				type: type.value,
				contaDv,
			},
		};

		if (verifyEmptyInputs()) {
			toast.error('Alguns campos não foram marcados');
		} else {
			postUpdateContractor(contractorId, newDataContractor, sendEmail)
				.then(() => {
					setIsSuccess(true);
				})
				.catch(() => {
					setIsFalha(true);
				})
				.finally(() => {
					setSendEmail(false);
				});
		}
	}

	async function handleCep(query) {
		try {
			const resp = await cep(query, {
				providers: ['widenet', 'brasilapi'],
			});
			setStreet(resp.street);
			setNeighborhood(resp.neighborhood);
			setCity(resp.city);
			setState(resp.state);
			setErros({ ...erros, cep: '' });
		} catch (err) {
			setErros({ ...erros, cep: 'CEP inválido' });
		}
	}

	function disabledSave() {
		if (
			erros.cnpj ||
			erros.stateRegistry ||
			erros.cep ||
			erros.email ||
			verifyEmptyInputs()
		)
			return true;
		return false;
	}

	return (
        <s.Container>
			<s.Header>
				<ButtonCustom outlined onClick={() => history.push(`/contratantes`)}>
					Voltar
				</ButtonCustom>
				<div>
					{readOnly ? (
						<ButtonCustom onClick={readOnlyClick} disabled={isLoading}>
							Editar dados
						</ButtonCustom>
					) : (
						<>
							<ButtonCustom
								theme="gray"
								outlined
								onClick={() => setIsCancelarEdicaoOpen(true)}
							>
								Cancelar
							</ButtonCustom>
							<ButtonCustom
								id="save"
								disabled={disabledSave()}
								onClick={() => saveContractor()}
							>
								Salvar alterações
							</ButtonCustom>
						</>
					)}
				</div>
			</s.Header>

			<s.Content>
				<s.Section>
					{readOnly && <h2>Dados do contratante</h2>}

					<s.Linegroup>
						<s.Label>
							<s.TituloParagrafo>Tipo de usuário</s.TituloParagrafo>
							{readOnly ? (
								<s.P>{roleId?.label}</s.P>
							) : (
								<SelectReact
									width="15.75rem"
									className="custom-select-input"
									value={roleId?.label}
									options={optionsRoleType}
									onChange={(opt) => setRoleId(opt)}
								/>
							)}
						</s.Label>

						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Status do contratante</s.TituloParagrafo>
									<s.P>{active ? 'Ativo' : 'Inativo'}</s.P>
								</>
							) : (
								<UnderlineInput
									required
									label="Razão social *"
									width="20.5rem"
									id="companyName"
									placeholder="Digite a razão social da empresa"
									value={companyName}
									name="companyName"
									onChange={(e) => {
										setCompanyName(e.target.value);
										if (e.target.value.length < 2) {
											setErros({
												...erros,
												companyName: 'Razão social inválida',
											});
										} else {
											setErros({ ...erros, companyName: '' });
										}
									}}
									maxLength={50}
									erro={erros.companyName}
								/>
							)}
						</s.Label>
					</s.Linegroup>

					<s.Linegroup>
						{readOnly && (
							<s.Label>
								<s.TituloParagrafo>Razão social</s.TituloParagrafo>
								<s.P>{companyName}</s.P>
							</s.Label>
						)}
						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>CNPJ</s.TituloParagrafo>
									<s.P>{mascaraCNPJ(cnpj)}</s.P>
								</>
							) : (
								<UnderlineInput
									required
									label="CNPJ *"
									width="20.5rem"
									id="cnpj"
									placeholder="Digite o CNPJ da empresa"
									value={mascaraCNPJ(cnpj)}
									name="cnpj"
									onChange={(e) => {
										setCnpj(e.target.value);
										if (
											validaCNPJ(e.target.value) &&
											e.target.value.length > 1
										) {
											setErros({ ...erros, cnpj: '' });
										} else {
											setErros({ ...erros, cnpj: 'CNPJ inválido' });
										}
									}}
									maxLength={18}
									isInvalid={erros.cnpj}
									erro={erros.cnpj}
								/>
							)}
						</s.Label>

						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Inscrição estadual</s.TituloParagrafo>
									<s.P>{stateRegistry}</s.P>
								</>
							) : (
								<UnderlineInput
									required
									label="Inscrição estadual *"
									width="21.5rem"
									id="inscricao-estadual"
									placeholder="Digite a inscrição estadual da empresa"
									value={justNumbers(stateRegistry)}
									name="stateRegistry"
									onChange={(e) => {
										const inscEst = e.target.value;
										const validIe = validateBr.inscricaoestadual(inscEst);

										setStateRegistry(inscEst);
										if (validIe && inscEst.length > 1) {
											setErros({ ...erros, stateRegistry: '' });
										} else {
											setErros({
												...erros,
												stateRegistry: 'Inscrição estadual inválida',
											});
										}
									}}
									isInvalid={erros.stateRegistry}
									erro={erros.stateRegistry}
								/>
							)}
						</s.Label>

						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Inscrição municipal</s.TituloParagrafo>
									<s.P>{cityRegistry}</s.P>
								</>
							) : (
								<UnderlineInput
									required
									label="Inscrição municipal *"
									width="21.5rem"
									id="inscricao-municipal"
									placeholder="Digite a inscrição municipal da empresa"
									value={justNumbers(cityRegistry)}
									name="cityRegistry"
									onChange={(e) => {
										setCityRegistry(e.target.value);
										if (e.target.value.length > 1) {
											setErros({ ...erros, cityRegistry: '' });
										} else {
											setErros({
												...erros,
												cityRegistry: 'Inscrição municipal inválida',
											});
										}
									}}
									maxLength={10}
									erro={erros.cityRegistry}
								/>
							)}
						</s.Label>
					</s.Linegroup>
					<s.Linediv />

					{readOnly && <h2>Contatos</h2>}

					<s.Linegroup>
						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>CEP</s.TituloParagrafo>
									<s.P>{maskCep(zipcode)}</s.P>
								</>
							) : (
								<UnderlineInput
									label="CEP *"
									width="20.5rem"
									id="cep"
									placeholder="00000-000"
									value={maskCep(zipcode)}
									name="address.zipcode"
									onChange={(e) => {
										const cepSearch = e.target.value.replace('-', '');
										if (cepSearch.length > 1) {
											if (cepSearch.length > 7) handleCep(cepSearch);
										} else {
											setErros({ ...erros, cep: 'CEP inválido' });
										}
										setZipcode(cepSearch);
									}}
									maxLength={9}
									required
									erro={erros.cep}
									isInvalid={erros.cep}
								/>
							)}
						</s.Label>

						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Endereço</s.TituloParagrafo>
									<s.P>{street}</s.P>
								</>
							) : (
								<UnderlineInput
									required
									label="Endereço *"
									width="43rem"
									id="street"
									placeholder="Digite o endereço completo"
									value={street}
									name="address.street"
									onChange={(e) => {
										setStreet(e.target.value);
										if (e.target.value.length > 1) {
											setErros({ ...erros, street: '' });
										} else {
											setErros({ ...erros, street: 'Endereço inválido' });
										}
									}}
									erro={erros.street}
								/>
							)}
						</s.Label>

						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Número</s.TituloParagrafo>
									<s.P>{streetNumber}</s.P>
								</>
							) : (
								<UnderlineInput
									required
									label="Número *"
									width="10.25rem"
									id="numero"
									placeholder="Digite o  número"
									value={justNumbers(streetNumber)}
									name="address.streetNumber"
									onChange={(e) => {
										setStreetNumber(e.target.value);
										if (e.target.value.length > 1) {
											setErros({ ...erros, streetNumber: '' });
										} else {
											setErros({ ...erros, streetNumber: 'Número inválido' });
										}
									}}
									erro={erros.streetNumber}
									maxLength={8}
								/>
							)}
						</s.Label>

						{readOnly && (
							<s.Label>
								<s.TituloParagrafo>Complemento</s.TituloParagrafo>
								<s.P>{complement}</s.P>
							</s.Label>
						)}
					</s.Linegroup>

					<s.Linegroup>
						{!readOnly && (
							<s.Label>
								<UnderlineInput
									label="Complemento"
									width="12.5rem"
									id="complemento"
									placeholder="Digite o complemento"
									value={complement}
									name="address.complement"
									onChange={(e) => setComplement(e.target.value)}
								/>
							</s.Label>
						)}
						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Bairro</s.TituloParagrafo>
									<s.P>{neighborhood}</s.P>
								</>
							) : (
								<UnderlineInput
									label="Bairro *"
									required
									width="20.5rem"
									id="bairro"
									placeholder="Digite o nome do bairro"
									value={neighborhood}
									name="address.neighborhood"
									onChange={(e) => {
										setNeighborhood(e.target.value);
										if (e.target.value.length > 1) {
											setErros({ ...erros, neighborhood: '' });
										} else {
											setErros({ ...erros, neighborhood: 'Bairro inválido' });
										}
									}}
									erro={erros.neighborhood}
								/>
							)}
						</s.Label>

						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Cidade</s.TituloParagrafo>
									<s.P>{city}</s.P>
								</>
							) : (
								<UnderlineInput
									label="Cidade *"
									required
									width="20.5rem"
									id="cidade"
									placeholder="Digite o nome da cidade"
									value={city.replace(/[0-9]/g, '')}
									name="address.city"
									onChange={(e) => {
										setCity(e.target.value);
										if (e.target.value.length > 1) {
											setErros({ ...erros, city: '' });
										} else {
											setErros({ ...erros, city: 'Cidade inválida' });
										}
									}}
									erro={erros.city}
								/>
							)}
						</s.Label>

						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Estado</s.TituloParagrafo>
									<s.P>{state}</s.P>
								</>
							) : (
								<UnderlineInput
									label="Estado *"
									required
									width="20.5rem"
									id="estado"
									placeholder="Digite o nome do estado"
									value={state.replace(/[0-9]/g, '')}
									name="address.state"
									onChange={(e) => {
										setState(e.target.value);
										if (e.target.value.length > 1) {
											setErros({ ...erros, state: '' });
										} else {
											setErros({ ...erros, state: 'Estado inválido' });
										}
									}}
									erro={erros.state}
								/>
							)}
						</s.Label>
					</s.Linegroup>

					<s.Linegroup>
						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Telefone</s.TituloParagrafo>
									<s.P>{mascaraTelefone(phone)}</s.P>
								</>
							) : (
								<UnderlineInput
									required
									label="Telefone *"
									width="20.5rem"
									id="telefone"
									placeholder="Digite o telefone com DDD"
									value={mascaraTelefone(phone)}
									name="contact.phone"
									onChange={(e) => {
										setPhone(e.target.value);
										if (e.target.value.length > 9) {
											setErros({ ...erros, phone: '' });
										} else {
											setErros({ ...erros, phone: 'Telefone inválido' });
										}
									}}
									erro={erros.phone}
									maxLength={15}
								/>
							)}
						</s.Label>

						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>E-mail</s.TituloParagrafo>
									<s.P>{email}</s.P>
								</>
							) : (
								<UnderlineInput
									required
									label="E-mail *"
									width="20.5rem"
									id="email"
									placeholder="Digite o e-mail da empresa"
									value={email}
									name="contact.email"
									onChange={(e) => {
										setEmail(e.target.value.toLowerCase().trim());
										if (
											!validateEmail(e.target.value) &&
											e.target.value.length > 1
										) {
											setErros({ ...erros, email: '' });
										} else {
											setErros({ ...erros, email: 'E-mail inválido' });
										}
									}}
									erro={erros.email}
									isInvalid={erros.email}
									maxLength={50}
								/>
							)}
						</s.Label>

						{!readOnly && (
							<>
								<s.Label>
									<UnderlineInput
										label="Responsável para contato *"
										required
										width="23rem"
										id="responsavel"
										placeholder="Digite o nome do responsável para contato"
										value={sponsorName}
										name="contact.sponsorName"
										onChange={(e) => {
											setSponsorName(e.target.value);
											if (e.target.value.length > 2) {
												setErros({ ...erros, sponsorName: '' });
											} else {
												setErros({
													...erros,
													sponsorName: 'Nome do responsável inválido',
												});
											}
										}}
										erro={erros.sponsorName}
										maxLength={50}
									/>
								</s.Label>

								<s.Label className="column-bottom">
									<CheckboxCustom
										id="convite-email"
										checked={sendEmail}
										onChange={() => setSendEmail(!sendEmail)}
										readOnly={readOnly}
										text="Enviar convite via e-mail"
										fontSize="1.25rem"
									/>
								</s.Label>
							</>
						)}
					</s.Linegroup>

					{readOnly && (
						<s.Linegroup>
							<s.Label>
								<s.TituloParagrafo>Responsável para contato</s.TituloParagrafo>
								<s.P>{sponsorName}</s.P>
							</s.Label>

							<s.Label>
								<s.TituloParagrafo>Enviar convite via e-mail</s.TituloParagrafo>
								<s.P>{sendEmail ? 'Sim' : 'Não'}</s.P>
							</s.Label>
						</s.Linegroup>
					)}
					<s.Linediv />

					{readOnly && <h2>Conta bancária</h2>}

					<s.Linegroup>
						<s.Label>
							<s.TituloParagrafo>Banco{readOnly ? '' : ' *'}</s.TituloParagrafo>
							{readOnly ? (
								<s.P>{bankCode?.label}</s.P>
							) : (
								<SelectReact
									required
									id="banco"
									width="47.5rem"
									className="custom-select-input"
									value={bankCode}
									options={optionsBank}
									onChange={(opt) => {
										setBankCode(opt);
									}}
								/>
							)}
						</s.Label>

						<s.Label>
							<s.TituloParagrafo>Tipo{readOnly ? '' : ' *'}</s.TituloParagrafo>
							{readOnly ? (
								<s.P>
									{type?.label ? type.label : 'Tipo de conta não informado'}
								</s.P>
							) : (
								<SelectReact
									className="custom-select-input"
									id="banco"
									value={type}
									erro={erros.banktype}
									onChange={(opt) => setType(opt)}
									options={optionsBankType}
									outlined
									width="19.625rem"
								/>
							)}
						</s.Label>
					</s.Linegroup>

					<s.Linegroup>
						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Agência</s.TituloParagrafo>
									<s.P>{agencia}</s.P>
								</>
							) : (
								<UnderlineInput
									required
									label="Agência *"
									width="20.5rem"
									id="agencia"
									placeholder="Digite o número da agência"
									value={justNumbers(agencia)}
									name="bankAccount.agencia"
									onChange={(e) => {
										setAgencia(e.target.value);
										if (e.target.value.length > 1) {
											setErros({ ...erros, agencia: '' });
										} else {
											setErros({
												...erros,
												agencia: 'Número da agência inválido',
											});
										}
									}}
									erro={erros.agencia}
									maxLength={4}
								/>
							)}
						</s.Label>

						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Dígito da agência</s.TituloParagrafo>
									<s.P>{agenciaDv || '-'}</s.P>
								</>
							) : (
								<UnderlineInput
									label="Dígito da agência"
									width="20.5rem"
									id="ag-dv"
									placeholder="Digite o código verificador da agência"
									value={agenciaDv ? justNumbers(agenciaDv) : ''}
									name="bankAccount.agenciaDv"
									onChange={(e) => {
										setAgenciaDv(e.target.value);
									}}
									erro={erros.agenciaDv}
									maxLength={1}
								/>
							)}
						</s.Label>

						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Conta</s.TituloParagrafo>
									<s.P>{conta}</s.P>
								</>
							) : (
								<UnderlineInput
									label="Conta *"
									required
									width="20.5rem"
									id="conta"
									placeholder="Digite o número da conta"
									value={justNumbers(conta)}
									name="banckAccount.conta"
									onChange={(e) => {
										setConta(e.target.value);
										if (e.target.value.length > 1) {
											setErros({ ...erros, conta: '' });
										} else {
											setErros({
												...erros,
												conta: 'Número da conta inválido',
											});
										}
									}}
									erro={erros.conta}
									maxLength={10}
								/>
							)}
						</s.Label>

						<s.Label>
							{readOnly ? (
								<>
									<s.TituloParagrafo>Dígito da conta</s.TituloParagrafo>
									<s.P>{contaDv}</s.P>
								</>
							) : (
								<UnderlineInput
									label="Dígito da conta *"
									required
									width="20.5rem"
									id="dv"
									placeholder="Digite o código verificador da conta"
									value={justNumbers(contaDv)}
									name="bankAccount.contaDv"
									onChange={(e) => {
										setContaDv(e.target.value);
										if (e.target.value.length > 0) {
											setErros({ ...erros, contaDv: '' });
										} else {
											setErros({
												...erros,
												contaDv: 'Código verificador da conta inválido',
											});
										}
									}}
									erro={erros.contaDv}
									maxLength={1}
								/>
							)}
						</s.Label>
					</s.Linegroup>
				</s.Section>
			</s.Content>

			<ModalCancelarEdicao
				isOpen={isCancelarEdicaoOpen}
				closeModal={() => setIsCancelarEdicaoOpen(false)}
				readOnlyClick={readOnlyClick}
			/>
			<ModalSucessoEdicao
				isOpen={isSuccess}
				closeModal={() => setIsSuccess(!isSuccess)}
			/>
			<ModalFalhaEdicao
				isOpen={isFalha}
				closeModal={() => setIsFalha(!isFalha)}
			/>
		</s.Container>
    );
}
