import React from 'react';

import { useNewPaymentLink } from '../../../../contexts';
import { formatarParaReais } from '../../../../utils';
import { ButtonCustom } from '../../../atoms';

import * as s from './styled-new-payment-link-form-confirm';

function ConfirmPayment({ setCurrentStep }) {
	const {
		paymentConfigurationBoleto,
		paymentConfigurationCredit,
		managingItems,
		nameLink,
		expirationTime,
		options,
		setIsCancelOpenCreation,
		value,
		isChecked,
		handleCreatePaymentLink,
	} = useNewPaymentLink();

	return (
		<s.Container>
			<s.Title>CONFIRMAÇÃO DE CRIAÇÃO DE LINK</s.Title>{' '}
			<p>Confirme os dados do link de pagamento para criar o link</p>
			<s.FormGroup>
				<s.TextGroup>
					<s.EditionTitle>
						Detalhes do link
						<ButtonCustom
							className="edition-button"
							outlined
							width={78}
							height={37}
							onClick={() => setCurrentStep(1)}
						>
							Editar
						</ButtonCustom>
					</s.EditionTitle>
					<s.Row>
						<s.TextField>
							<s.TextCustom>Nome do link</s.TextCustom>
							<p>{nameLink}</p>
						</s.TextField>
						<s.TextField>
							<s.TextCustom>Tempo de expiração</s.TextCustom>
							<p>
								expira em {expirationTime} {options}
							</p>
						</s.TextField>
					</s.Row>
				</s.TextGroup>
				{isChecked ? (
					<s.ItensMapGroup>
						<s.EditionTitle>Itens da compra</s.EditionTitle>
						{managingItems.map((linha) => (
							<s.Table key={linha.id}>
								<s.Thead>
									<s.Tr>
										<th>Nome do produto</th>
										<th>ID da Produto</th>
										<th>Quantidade</th>
										<th>Preço da unidade</th>
										<th>Subtotal</th>
									</s.Tr>
								</s.Thead>
								<s.Tbody>
									<s.Tr>
										<td>{linha.title}</td>
										<td>{linha.productId}</td>
										<td>{linha.quantity}</td>
										<td>{formatarParaReais(linha.unit_price / 100, true)}</td>
										<td>{formatarParaReais(linha.subTotal / 100, true)}</td>
									</s.Tr>
								</s.Tbody>
							</s.Table>
						))}
						<p>Valor total: {formatarParaReais(value / 100, true)}</p>
					</s.ItensMapGroup>
				) : (
					<s.ItensMapGroup>
						Valor total: {formatarParaReais(value / 100, true)}
					</s.ItensMapGroup>
				)}
			</s.FormGroup>
			<s.PaymentGroup>
				<s.TextGroup>
					<s.EditionTitle>
						Meios de pagamento
						<ButtonCustom
							className="edition-button"
							outlined
							width={78}
							height={37}
							onClick={() => setCurrentStep(2)}
						>
							Editar
						</ButtonCustom>
					</s.EditionTitle>
					<s.Row>
						{paymentConfigurationCredit.enabled && (
							<s.TextField>
								<s.TextCustom>Cartão de crédito</s.TextCustom>
								<p>
									até {paymentConfigurationCredit.free_installments}x: Sem juros
								</p>
								{paymentConfigurationCredit.free_installments !==
								paymentConfigurationCredit.max_installments ? (
									<p>
										até {paymentConfigurationCredit.max_installments} com juros
										de {paymentConfigurationCredit.interest_rate}% a.m
									</p>
								) : (
									<p>
										Podendo parcelar até{' '}
										{paymentConfigurationCredit.max_installments}x
									</p>
								)}
							</s.TextField>
						)}
						{paymentConfigurationBoleto.enabled && (
							<s.TextField>
								<s.TextCustom>Boleto</s.TextCustom>
								<p>
									Dias para o vencimento:{' '}
									{paymentConfigurationBoleto.expires_in} dias
								</p>
							</s.TextField>
						)}
					</s.Row>
				</s.TextGroup>
			</s.PaymentGroup>
			<s.ButtonGroup>
				<ButtonCustom
					theme="gray"
					outlined
					onClick={() => setIsCancelOpenCreation(true)}
				>
					Cancelar
				</ButtonCustom>
				<div>
					<ButtonCustom
						outlined
						theme="green"
						onClick={() => setCurrentStep(2)}
					>
						Voltar
					</ButtonCustom>

					<ButtonCustom
						theme="green"
						onClick={() => {
							handleCreatePaymentLink();
						}}
					>
						Continuar
					</ButtonCustom>
				</div>
			</s.ButtonGroup>
		</s.Container>
	);
}

export default ConfirmPayment;
