import styled from 'styled-components';
import { colors } from '../../../../assets';

export const WrapperTable = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Table = styled.table`
	font-size: 1rem;
`;

export const Thead = styled.thead`
	color: ${colors.primaryBlue};
	font-weight: bold;
	height: 3.75rem;

	tr {
		color: ${colors.primaryBlue};
		background-color: ${colors.black};

		th {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			border-left: 1px solid ${colors.borderTable};
			height: 100%;
			padding: 10px;
		}

		th:last-child {
			border-right: 1px solid ${colors.borderTable};
		}
	}
`;

export const Tr = styled.tr`
	display: grid;
	grid-template-columns: 7rem 18rem repeat(auto-fit, minmax(8rem, 1fr));
	min-height: 3.75rem;
	justify-content: center;
	color: ${colors.white};
	background: ${({ index }) =>
		index % 2 === 0 ? colors.gridBlack : colors.rowTable};
	cursor: ${({ index }) => (index ? 'pointer' : 'default')};

	${({ top, bottom }) =>
		top
			? `border-top: 1px solid ${colors.borderTable};`
			: bottom && `border-bottom: 1px solid ${colors.borderTable}`};

	/* borda direita da tabela */
	td:last-child {
		border-right: 1px solid ${colors.borderTable};
	}

	${({ clicavel }) =>
		clicavel &&
		`cursor: pointer;
		text-decoration: none;`}
`;

export const Td = styled.td`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: left;
	min-width: ${({ minWidth }) => minWidth || 'none'};
	border-left: 1px solid ${colors.borderTable};
	padding: 10px;
	height: 100%;
	color: ${({ value }) => {
		if (value === 'active') return colors.green;
		if (value === 'inactive') return colors.red;
	}};
	font-weight: 400;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const Tbody = styled.tbody`
	tr:first-child {
		margin-top: -2px;
	}

	height: ${({ loading }) => (loading ? '500px' : 'auto')};

	.loader {
		display: flex;
		height: 500px;
		justify-content: center;
		align-items: center;
	}
`;

export const Data = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	flex-wrap: wrap;

	p.data {
		font-size: 1rem;
		line-height: 0.9375rem;
		margin-top: 0;
		padding: 0;
	}

	p.bold {
		font-weight: 600;
	}

	span {
		height: 0.9375rem;
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	width: 100%;

	p.total {
		color: ${({ sinal }) => (sinal === '+' ? colors.green : colors.primaryRed)};
	}
`;

export const LoadingContainer = styled.div`
	display: grid;
	align-items: flex-start;
	grid-template-columns: repeat(6, 1fr);
	gap: 5px;
	height: 100%;
	width: 100%;
	padding: 0 2px;

	.loading-rectangle {
		height: 40px;
	}
`;

export const ChevronContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: 1rem;
	border: 1px solid ${colors.lightGray};
	border-radius: 5px;
	margin: 0 0.5rem 0 auto;
`;

export const MoreInfo = styled.div`
	display: flex;
	background: ${({ index }) =>
		index % 2 === 0 ? colors.gridBlack : colors.rowTable};
	border: 1px solid ${colors.borderTable};
	height: 12.5rem;
	padding: 2.5rem 2rem;
	.button-custom {
		font-size: 1rem;
		margin-left: auto;
		height: 3.3125rem;
		width: 12.5rem;
	}
`;

export const Content = styled.div`
	display: flex;
	width: 100%;
	color: ${colors.white};
`;

export const LeftColumn = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 7.5rem;
`;

export const RightColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Item = styled.div`
	color: ${colors.lightGray};
	:not(:last-child) {
		margin-bottom: 1.5rem;
	}

	span {
		color: ${colors.offWhite};
	}
`;

export const Tfoot = styled.tfoot`
	display: flex;
	justify-content: flex-end;

	> div {
		margin-top: 2rem;
		margin-bottom: 2.5rem;
	}
`;
