import React from 'react';
import { colors } from '../../colors';

const ErrorAlertIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		width="132"
		height="132"
		viewBox="0 0 132 132"
		fill="none"
		className="error-alert-icon"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || ''}</title>
		<g filter="url(#filter0_d_4372_18344)">
			<path
				d="M27.2183 100.782L105 23"
				stroke={fill || colors.primaryRed}
				strokeWidth="13"
				strokeLinecap="round"
			/>
			<path
				d="M27.2183 23.2182L105 101"
				stroke={fill || colors.primaryRed}
				strokeWidth="13"
				strokeLinecap="round"
			/>
		</g>
		<defs>
			<filter
				id="filter0_d_4372_18344"
				x="0"
				y="-1"
				width="132"
				height="132.5"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="10" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.929412 0 0 0 0 0.388235 0 0 0 0 0.388235 0 0 0 0.5 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_4372_18344"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_4372_18344"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);
export default ErrorAlertIcon;
