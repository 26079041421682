import React from 'react';

const PerfilIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		className="perfil-icon"
		viewBox="0 0 16 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || 'Ícone de Perfil'}</title>
		<path
			d="M2.06168 6.15789C2.06168 2.75698 4.72035 0 8 0V0C11.2796 0 13.9383 2.75698 13.9383 6.15789V6.15789C13.9383 9.55881 11.2796 12.3158 8 12.3158V12.3158C4.72035 12.3158 2.06168 9.55881 2.06168 6.15789V6.15789Z"
			fill={fill || '#FDFDFD'}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.25614 13.3944C2.25113 14.1612 0.745218 15.976 0.449746 18.215L0.317784 19.215C-0.156533 22.8094 2.64073 26 6.26622 26H9.73378C13.3593 26 16.1565 22.8094 15.6822 19.215L15.5503 18.215C15.1564 15.2306 12.6121 13 9.60182 13H9.60179C10.3536 13 11.0764 13.1391 11.7438 13.3944C10.6291 14.0159 9.35423 14.3684 7.99997 14.3684C6.64572 14.3684 5.37087 14.0159 4.25614 13.3944Z"
			fill={fill || '#FDFDFD'}
		/>
	</svg>
);
export default PerfilIcon;
