import React from 'react';
import * as s from './styled-checkbox-custom';

export default function CustomCheckbox({
	id,
	onChange,
	value,
	disabled,
	margin,
	secondary,
	text,
	fontSize,
	checkboxAlign,
	checked,
	TransactionFilters,
	marginBottom,
}) {
	return (
		<s.Container
			fontSize={fontSize}
			checkboxAlign={checkboxAlign}
			checked={checked}
			disabled={disabled}
			className="checkbox-custom"
		>
			<s.ContainerCheckbox
				TransactionFilters={TransactionFilters}
				margin={margin}
				marginBottom={marginBottom}
			>
				<s.Checkbox
					id={id}
					secondary={secondary}
					disabled={disabled}
					type="checkbox"
					value={value}
					checked={checked}
					onChange={onChange}
				/>
			</s.ContainerCheckbox>
			{text && (
				<label checked={checked} onClick={onChange} htmlFor={id}>
					{text}
				</label>
			)}
		</s.Container>
	);
}
