import React from 'react';
import { Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import {
	formatarParaReais,
	translatePaymentMethod,
	translateStatus,
} from '../../../../utils';

Font.register({
	family: 'IBM Plex Sans',
	fonts: [
		{
			src:
				'https://fonts.gstatic.com/s/ibmplexsans/v13/zYXgKVElMYYaJe8bpLHnCwDKtdbUFI5NadY.ttf',
		},
		{
			src:
				'https://fonts.gstatic.com/s/ibmplexsans/v13/zYX9KVElMYYaJe8bpLHnCwDKjQ76MIZmdd_qFmo.ttf',
			fontWeight: 600,
		},
	],
});

const styled = StyleSheet.create({
	page: {
		flexDirection: 'column',
		justifyContent: 'flex-start',
		paddingTop: 20,
		paddingBottom: 35,
		paddingHorizontal: 35,
	},
	table: {
		width: '100%',
		fontSize: 11,
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	section: {
		margin: '10 0 10 0',
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
	},

	article: {
		margin: '10 0 10 0',
		padding: '10px 0 10px 35px',
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
	},
	footer: {
		margin: '10 0 10 0',
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
		color: '#777',
		borderBottom: '1 solid #DDD',
	},
	tittle: {
		fontSize: 12,
		fontFamily: 'IBM Plex Sans',
		fontWeight: 600,
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'center',
		marginBottom: 20,
	},
	subtitle: {
		color: 'rgba(0, 0, 0, 0.60)',
		fontSize: 8,
		fontFamily: 'IBM Plex Sans',
		textAlign: 'left',
		paddingLeft: '8px',
		width: '100%',
		paddingRight: 12,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		borderTop: '1px solid #EDEDED',
		paddingTop: 8,
		paddingBottom: 8,
		gap: 12,
		width: '100%',
	},

	header: {
		borderRight: '1px solid',
	},
	lineInfo: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '10px 0 10px 10px',
	},
	lineInfoBorder: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '10px 0 10px 10px',
		opacity: '0.1',
		borderBottom: '1 solid #DDD',
	},
	textBold: {
		fontWeight: 600,
		fontSize: 11,
		fontFamily: 'IBM Plex Sans',
	},
	row1: {
		textAlign: 'left',
		paddingLeft: '8px',
		width: '32%',
		borderRight: `1px solid #EDEDED`,
		fontWeight: 'bold',
	},
	row2: {
		textAlign: 'left',
		paddingLeft: '8px',
		width: '40%',
		borderRight: `1px solid #EDEDED`,
		fontWeight: 'bold',
	},
	row3: {
		textAlign: 'left',
		paddingLeft: '8px',
		width: '40%',
		borderRight: `1px solid #EDEDED`,
		fontWeight: 'bold',
	},
	row4: {
		textAlign: 'left',
		paddingLeft: '8px',
		width: '40%',
		borderRight: `1px solid #EDEDED`,
		fontWeight: 'bold',
	},
	row5: {
		textAlign: 'left',
		paddingLeft: '8px',
		width: '32%',
		borderRight: `1px solid #EDEDED`,
		fontWeight: 'bold',
	},
	row6: {
		textAlign: 'left',
		paddingLeft: '8px',
		width: '40%',
		borderRight: `1px solid #EDEDED`,
		fontWeight: 'bold',
	},
	row7: {
		textAlign: 'left',
		paddingLeft: '8px',
		width: '40%',
		borderRight: `1px solid #EDEDED`,
		fontWeight: 'bold',
	},
	row8: {
		textAlign: 'left',
		paddingLeft: '8px',
		width: '40%',
		fontWeight: 'bold',
	},
});

const TransacoesPdfTemplate = ({ data }) => {
	const formatData = (value) => {
		const date = new Date(value);

		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
	};

	return (
		<Page size="A4" style={styled.page}>
			<Text style={styled.tittle}>Transações</Text>
			<View style={styled.section}>
				<View style={styled.row}>
					<Text style={styled.row1}>Status</Text>
					<Text style={styled.row2}>Data</Text>
					<Text style={styled.row3}>ID da transação</Text>
					<Text style={styled.row5}>Nome</Text>
					<Text style={styled.row5}>Forma de pagamento</Text>
					<Text style={styled.row6}>Valor</Text>
				</View>
			</View>
			{data.map((row, i) => (
				<View key={i} style={styled.row} wrap={false}>
					<Text style={[styled.subtitle]}>{translateStatus(row.status)}</Text>
					<Text style={[styled.subtitle]}>{formatData(row.date)}</Text>
					<Text style={[styled.subtitle]}>{row.transactionId}</Text>
					<Text style={[styled.subtitle]}>{row.name}</Text>
					<Text style={[styled.subtitle]}>
						{translatePaymentMethod(row.paymentMethod)}
					</Text>
					<Text style={[styled.subtitle]}>
						{formatarParaReais(row.transactionValue / 100, true)}
					</Text>
				</View>
			))}
		</Page>
	);
};

export default TransacoesPdfTemplate;
