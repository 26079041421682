import React from 'react';
import {
	ButtonCustom,
	CheckboxCustom,
	FloaterReact,
	SelectReact,
	UnderlineInput,
} from '../../../atoms';
import DataPurchaseItems from '../purchase-items/purchase-items';
import { useNewPaymentLink } from '../../../../hooks';
import { formatarParaReais,  justNumbers } from '../../../../utils';
import { Icons, colors } from '../../../../assets';

import * as s from './styled-payment-link-infos';

const DadosNewPaymentLink = ({ setCurrentStep }) => {
	const {
		nameLink,
		setNameLink,
		setIsCancelOpenCreation,
		value,
		setValue,
		errors,
		setErrors,
		isChecked,
		setIsChecked,
		options,
		setOptions,
		expirationTime,
		setExpirationTime,
		validateInformations,
		setManagingItems,
	} = useNewPaymentLink();

	const OPTIONS_PERIOD = [{ label: 'Dias' }, { label: 'Horas' }];

	const handleSetExpirationTime = (e) => {
		 const existingDocument = e.length;
		const doc = justNumbers(e);
		if (existingDocument && doc) {
		if (options === 'Dias') {
			if (doc < 1 || doc > 180) {
				setErrors({
					...errors,
					expiration:
						'Seu link precisa durar no mínimo 1 dia e no máximo 180 dias.',
				});
				setExpirationTime(doc);
			} else {
				setErrors({ ...errors, expiration: '' });
				setExpirationTime(doc);
			}
		} else if (doc < 1 || doc > 24) {
				setErrors({
					...errors,
					expiration:
						'Seu link precisa durar no mínimo 1 hora e no máximo 24 horas.',
				});
				setExpirationTime(doc);
			} else {
				setErrors({ ...errors, expiration: '' });
				setExpirationTime(doc);
			}
		}
	};

	const handleSelect = (e) => {
		setOptions(e.label);
	};

	const handleChangeName = (e) => {
		setNameLink(e.target.value);
		if (e.target.value.length > 1) {
			setErrors({
				...errors,
				nameLink: '',
			});
		} else {
			setErrors({
				...errors,
				nameLink: 'Nome obrigatório',
			});
		}
	};

	const handleChangeValue = (event) => {
		const doc = Number(justNumbers(event.target.value));
		setValue(doc);
		if (doc >= 100) {
			setErrors({
				...errors,
				valor: '',
			});
		} else {
			setErrors({
				...errors,
				valor: 'O valor deve ser a partir de R$ 1,00',
			});
		}
	};

	const hoverContent = 
		<s.TooltipDiv>
			<p>
				Ao criar o link de pagamento você tem a opção de incluir itens para o melhor gerenciamento de seus itens.
			</p>
			< br/>
			<p>
				 Caso você <strong>não adicione nenhum item</strong>, por padrão, nosso sistema cria um item vazio para validar o valor de seu link de pagamento junto à instituição financeira.
			</p>
		</s.TooltipDiv>;

	return (
		<s.Container>
			<s.Title>INFORMAÇÕES DO LINK 
				<FloaterReact
					hover
					placement='right-start'
					content={hoverContent}
					background={colors.secondaryDarkGray}
				>
					<Icons.InfoIconOutlined/>
				</FloaterReact>
			</s.Title>
			<s.FormGroup>
				<s.InputGroup>
					<s.InputTextField>
						<s.Underlineinput>
							Nome do Link *
							<UnderlineInput
								required
								type="text"
								width="20.5rem"
								margin-top="3rem"
								value={nameLink}
								placeholder="Digite um nome para este link"
								onChange={(e) => handleChangeName(e)}
								onBlur={(e) => handleChangeName(e)}
								isInvalid={errors.nameLink}
								erro={errors.nameLink}
								maxLength={124}
							/>
						</s.Underlineinput>
					</s.InputTextField>
					<s.InputTextField>
						<s.Underlineinput>
							Tempo de expiração
							<UnderlineInput
								className="custom-input"
								type="number"
								name="expiry.time"
								value={expirationTime}
								id="expiry-time"
								width="20.5rem"
								min="1"
								placeholder="1"
								isInvalid={errors.expiration}
								onChange={(e) =>handleSetExpirationTime(e.target.value)}
								erro={errors.expiration}
								maxLength={3}
							/>
						</s.Underlineinput>
					</s.InputTextField>
					<s.SelectContainer>
						<SelectReact
							className="select-period"
							type="number"
							id="select.period"
							outlined
							width="8.25rem"
							height="2.5rem"
							value={options}
							options={OPTIONS_PERIOD}
							onChange={(opt) => handleSelect(opt)}
						/>
					</s.SelectContainer>
					{!isChecked && (
						<s.InputTextField>
							<s.Underlineinput className="label">
								Valor do link (a partir de R$1,00)
								<UnderlineInput
									className="custom-input"
									id="value-of-link"
									name="value.link"
									width="20.5rem"
									placeholder="0,00"
									isInvalid={errors.valor}
									value={formatarParaReais(value / 100)}
									onChange={(e) => handleChangeValue(e)}
									erro={errors.valor}
									maxLength={16}
								/>
							</s.Underlineinput>
						</s.InputTextField>
					)}					
				</s.InputGroup>
				<CheckboxCustom
					className="checkbox-custom"
					id="add-itens"
					value={isChecked}
					text="Adicionar itens"
					onChange={() => {
						setValue(!isChecked ? 0 : 100);
						setManagingItems([
							{
								id: 1,
								title: isChecked ? ' ' : '',
								unit_price: 0,
								quantity: 1,
								tangible: true,
								productId: isChecked ? ' ' : '',
								error: {
									title: '',
									unit_price: '',
									quantity: '',
									productId: '',
								},
								subTotal: 0,
							}
						]);
						setIsChecked((prev) => !prev);
					}}
					checked={isChecked}
				/>
				{isChecked && <DataPurchaseItems />}
				<s.TotalDiv>
					Valor total:
					{formatarParaReais(value / 100, true)}
				</s.TotalDiv>
			</s.FormGroup>
			<s.ButtonGroup>
				<ButtonCustom
					theme="gray"
					outlined
					onClick={() => setIsCancelOpenCreation(true)}
				>
					Cancelar
				</ButtonCustom>
				<ButtonCustom
					theme="green"
					disabled={validateInformations || false}
					onClick={() => setCurrentStep(2)}
				>
					Continuar
				</ButtonCustom>
			</s.ButtonGroup>
		</s.Container>
	);
};

export default DadosNewPaymentLink;
