import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { ModalCancelarPaymentLink } from '../components';
import { postPaymentLink } from '../services';
import { useAuth } from './auth-context';

const ProviderPaymentLinks = createContext();

export function NewPaymentLinksProvider({ children }) {
	const { currentUser } = useAuth();

	const idRecebedor = currentUser?.recipientId;

	const [nameLink, setNameLink] = useState('');
	const [isCancelOpenCreation, setIsCancelOpenCreation] = useState(false);
	const [options, setOptions] = useState('Dias');
	const [nameError, setNameError] = useState('');
	const [errors, setErrors] = useState({});
	const [isChecked, setIsChecked] = useState(false);
	const [value, setValue] = useState(100);
	const [managingItems, setManagingItems] = useState([
		{
			id: 1,
			title: ' ',
			unit_price: 0,
			quantity: 1,
			tangible: true,
			productId: ' ',
			error: {
				title: '',
				unit_price: '',
				quantity: '',
				productId: '',
			},
			subTotal: 0,
		},
	]);
	const [product, setProduct] = useState('');
	const [typeItem, setTypeItem] = useState('Bem físico');
	const [expirationTime, setExpirationTime] = useState(1);
	const [paymentLinkCreated, setPaymentLinkCreated] = useState({});
	const [currentStep, setCurrentStep] = useState(1);
	const [paymentConfigurationBoleto, setPaymentConfigurationBoleto] = useState({
		enabled: false,
		expires_in: 1,
	});
	const [paymentConfigurationCredit, setPaymentConfigurationCredit] = useState({
		enabled: false,
		free_installments: 1,
		interest_rate: 1,
		max_installments: 1,
	});

	const purchaseItems = managingItems.map((item) => {
		return {
			title: item.title,
			unit_price: item.unit_price,
			quantity: item.quantity,
			tangible: item.tangible,
			id: item.productId,
		};
	});

	const handleCaculateExpirationTime = ()=>{
		if(options === "Dias") return expirationTime * 1440
		return expirationTime * 60
	}

	const paymentLinkData = {
		amount: value,
		name: nameLink,
		items: purchaseItems,
		payment_config: {
			credit_card: paymentConfigurationCredit,
			boleto: paymentConfigurationBoleto,
		},
		expires_in: handleCaculateExpirationTime(),
	};

	const handleCreatePaymentLink = () => {
		postPaymentLink(idRecebedor, paymentLinkData)
			.then((resp) => {
				setPaymentLinkCreated(resp);
				setCurrentStep(4);
			})
			.catch(() => toast.error('Erro ao criar o link de pagamento!'));
	};

	const handleCalculateTotal = () => {
		let sum = 0;
		const subTotals = managingItems.map((item) => item.subTotal);

		subTotals.forEach((item) => {
			sum += item;
		});

		return setValue(sum);
	};

	// este código abaixo serve para validar se todos os campos na tela de informações estão completos
	// e se há erro ou não

	const handleCheckErrorEmptyFields = (object) => {
		const data = object.includes(true);

		return data;
	};
	const handleCheckError = (object) => {
		const data = object.includes(false);

		return data;
	};

	const mapErrorsPrice = handleCheckError(
		managingItems.map((item) => [item.error.unit_price].includes(''))
	);

	const mapErrorsTitle = handleCheckError(
		managingItems.map((item) => [item.error.title].includes('')))

	const mapErrorsProductId = handleCheckError(
		managingItems.map((item) => [item.error.productId].includes('')))

	const mapErrorsQuantity = handleCheckError(
		managingItems.map((item) => [item.error.quantity].includes(''))
	);

	const validateErrorTime = errors.expiration === "" || errors.expiration === undefined

	const validateTitleEmpty =
		isChecked &&
		handleCheckErrorEmptyFields(
			managingItems.map((item) => [item.title].includes('') || [item.title].includes(' '))
		);

	const validateProductIdEmpty =
		isChecked &&
		handleCheckErrorEmptyFields(
			managingItems.map((item) => [item.productId].includes('') || [item.productId].includes(' '))
		);
	const validatePrice =
		isChecked &&
		handleCheckErrorEmptyFields(
			managingItems.map((item) => [item.unit_price].includes(0))
		);
	const validateNamelink = nameLink === '';

	const validateInformations =
		validateNamelink ||
		validateProductIdEmpty ||
		validateTitleEmpty ||
		validatePrice ||
		mapErrorsTitle ||
		mapErrorsPrice ||
		mapErrorsQuantity || 
		mapErrorsProductId ||
		!validateErrorTime;

	const values = {
		nameLink,
		setNameLink,
		isCancelOpenCreation,
		setIsCancelOpenCreation,
		nameError,
		setNameError,
		value,
		setValue,
		errors,
		setErrors,
		isChecked,
		setIsChecked,
		product,
		setProduct,
		typeItem,
		setTypeItem,
		options,
		setOptions,
		expirationTime,
		setExpirationTime,
		managingItems,
		setManagingItems,
		paymentConfigurationBoleto,
		setPaymentConfigurationBoleto,
		paymentConfigurationCredit,
		setPaymentConfigurationCredit,
		validateInformations,
		handleCalculateTotal,
		paymentLinkData,
		handleCreatePaymentLink,
		paymentLinkCreated,
		currentStep,
		setCurrentStep,
	};

	return (
		<ProviderPaymentLinks.Provider value={values}>
			{children}
			<ModalCancelarPaymentLink
				isOpen={isCancelOpenCreation}
				closeModal={() => setIsCancelOpenCreation(false)}
			/>
		</ProviderPaymentLinks.Provider>
	);
}

export function useNewPaymentLink() {
	return useContext(ProviderPaymentLinks);
}
