import React from 'react';
import { CheckboxCustom } from '../../../..';
import { capitalizeFirstLetter } from '../../../../../utils';

import * as S from './styled-dropdown-filter-cardbrand';

const flagsOptions = [
	'Visa',
	'Elo',
	'Mastercard',
	'Aura',
	'Amex',
	'Discover',
	'Hipercard',
	'JCB',
	'Diners',
];

function RenderCheckboxCustom({ state, setState, id, text }) {
	return (
		<CheckboxCustom
			checked={state.includes(id)}
			state={state}
			setState={setState}
			fontSize="1.25rem"
			text={capitalizeFirstLetter(text)}
			checkboxAlign="flex-start"
			onChange={() => {
				if (state.includes(id)) {
					setState(state.filter((item) => item !== id));
				} else {
					setState([...state, id]);
				}
			}}
			TransactionFilters
		/>
	);
}

function DropdownFilterCardbrand({ cardBrand, setCardBrand, innerRef }) {
	return (
		<S.Container ref={innerRef}>
			<S.Column>
				<S.ColumnTitle>Bandeiras</S.ColumnTitle>
				{flagsOptions.map((item) => (
					<RenderCheckboxCustom
						state={cardBrand}
						setState={setCardBrand}
						key={item}
						id={item.toLowerCase()}
						text={item}
					/>
				))}
			</S.Column>
		</S.Container>
	);
}

export default DropdownFilterCardbrand;
