import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	transition: ease-in-out;
`;

export const Modal = styled.div`
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 40rem;
	height: 27.5rem;
	background-color: ${colors.black};
	z-index: 1000;
	box-shadow: 0px 0px 10px 1px ${colors.background};
	border-radius: 0.9375rem;
	padding: 3rem 4.8125rem;
	transition: ease-in-out;

	.label-underline-input {
		color: ${colors.primaryBlue};
	}
`;

export const IconWrapper = styled.div`
	svg {
		cursor: pointer;
		height: 1rem;
		width: 1rem;
		position: absolute;
		z-index: 1;
		right: 3rem;
		top: 3rem;
	}
`;

export const ContainerIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 1rem;
	width: 1rem;
	border-radius: 50%;
	background: ${({ background }) => background};
	position: absolute;
	z-index: 1;
	right: 20px;
	top: 20px;

	svg {
		position: initial;
	}
`;

export const Title = styled.h4`
	width: 100%;
	font-size: 1.375rem;
	font-weight: 500;
	line-height: 2rem;
	justify-self: center;
	text-align: center;
	padding-bottom: 1rem;
	color: ${colors.offWhite};
	text-transform: uppercase;
`;

export const Subtitle = styled.p`
	padding-bottom: 3rem;
	color: ${colors.lightGray};
	font-size: 1.25rem;
	font-height: 400;
	text-align: center;
`;

export const MensagemErro = styled.span`
	color: ${colors.red};
	font-size: 0.9375rem;
	color: ${colors.red};
	margin-top: 0.75rem;
	text-align: left;
`;

export const ButtonGroup = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	margin-top: auto;
`;
