import React from 'react';
import { Loader, TransactionBarChart } from '../../../../components';
import BarChartInstallments from '../bar-chart/bar-chart';
import ThreePieCharts from '../three-pie-charts/three-pie-charts';
import VolumeChart from '../area-chart/area-chart';
import VerticalBarChart from '../vertical-bar-chart/vertical-bar-chart';
import { Icons } from '../../../../assets';
import * as s from './styled-cardgraphs';

const CardGraphs = (props) => {
	const { id, div, title, data, loading, totalElements, totalValue } = props;

	const renderChart = () => {
		if (id === 'transaction-chart')
			return (
				<TransactionBarChart
					data={data}
					totalValue={totalValue}
					totalElements={totalElements}
				/>
			);
		if (id === 'installments-chart')
			return <BarChartInstallments data={data} />;
		if (id === 'three-pie-charts') return <ThreePieCharts data={data} />;
		if (id === 'volume-chart') return <VolumeChart data={data} />;
		if (id === 'market-types-chart') return <VerticalBarChart data={data} />;
		return null;
	};

	return (
		<s.Container id={id} div={div}>
			<s.Title id={id}>
				<h2>{title}</h2>
			</s.Title>
			{!loading ? (
				data && data.length > 0 ? (
					renderChart()
				) : (
					<Icons.Placeholder
						style={{
							transform: 'translateY(-10%) scale(0.75)',
						}}
					/>
				)
			) : (
				<Loader />
			)}
		</s.Container>
	);
};

export default CardGraphs;
