import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 6.6875rem;
	padding: ${({ id }) =>
		id === 'average-ticket'
			? '1.3125rem 1rem 2.3125rem 0.9375rem'
			: '1.3125rem 1rem 1.1875rem 0.9375rem'};
	.loading-rectangle {
		width: 100%;
		height: 1.375rem;
	}
`;

export const Infos = styled.div`
	display: flex;
	flex-direction: ${({ id }) => (id === 'average-ticket' ? 'column' : 'row')};
	align-items: ${({ id }) => id !== 'average-ticket' && 'center'};
	justify-content: ${({ id }) =>
		id === 'total-amount' ? 'flex-start' : 'space-between'};
	justify-content: ${({ id }) => id === 'average-ticket' && 'center'};
	h2 {
		color: ${colors.primaryBlue};
		font-size: 1.375rem;
		font-size: ${({ id }) => id === 'total-amount' && '2rem'};
		font-size: ${({ id }) => id === 'transactions-number' && '2rem'};
		font-style: normal;
		font-weight: 600;
	}

	div {
		display: flex;
	}
`;

export const StartIcon = styled.div`
	display: flex;
	padding-right: 2rem;
	align-items: center;
`;

export const MidContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-right: auto;
	align-items: ${({ id }) => id === 'average-ticket' && 'flex-start'};
`;

export const Title = styled.h1`
	font-size: 1rem;
	font-weight: 400;
	color: ${colors.white};
	white-space: nowrap;
	padding-bottom: 0.875rem;
`;
