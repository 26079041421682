import styled from 'styled-components';
import { colors } from '../../assets';
import { images } from '../../assets/images';

export const Container = styled.div`
	min-height: 100vh;
	width: 100vw;
	background-image: url(${images.background});
	backdrop-filter: blur(100px);
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
`;

export const Card = styled.div`
	padding: 5rem;
	border: 2px solid rgba(43, 66, 70, 0.5);
	display: flex;
	position: relative;
	border-radius: 15px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: radial-gradient(
		90.95% 100% at 66.86% 0%,
		rgba(46, 50, 51, 0.4) 0%,
		rgba(25, 25, 25, 0.4) 100%
	);
	backdrop-filter: blur(100px);

	svg.logotipo {
		width: 12.5rem;
		height: 4.06rem;
		margin-bottom: 2.5rem;
	}
`;

export const Form = styled.form`
	width: 28.13rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	button.button-custom {
		width: 100%;
		height: 3.13rem;
		font-size: 1.5rem;
		border: none;
		margin-top: 3.13rem;
	}
`;

export const FormGroup = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	svg.perfil-icon {
		margin: 0 auto;
		width: 2rem;
		height: 3.13rem;
	}

	h1 {
		font-size: 1.25rem;
		color: ${colors.white};
		text-transform: uppercase;
		font-weight: 600;
		text-align: center;
		margin-bottom: 1.25rem;
		margin-top: 0.625rem;
	}

	#validar-senha {
		width: 100%;

		div {
			width: 100%;

			span {
				width: 100%;
			}
		}
	}
`;

export const Button = styled.button`
	background: none;
`;

export const TitleDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: space-between;
	align-self: flex-start;
`;

export const FormLabel = styled.label`
	line-height: 1.63rem;
	font-size: 1.25rem;
	font-weight: 600;
	color: ${colors.offWhite};
	margin: 1.88rem 0 0.625rem 0;
`;

export const Span = styled.span`
	max-width: 28.13rem;
	font-size: 1.25rem;
	margin-top: 1.88rem;
	text-align: center;
	color: ${colors.primaryRed};

	svg.attention-icon {
		width: 1.13rem;
		height: 1.25rem;
		margin-right: 0.625rem;
	}

	a {
		font-weight: 600;
		color: ${colors.primaryRed};
		margin: 0 5px;
	}
`;

export const Error = styled.p`
	color: ${colors.primaryRed};
	font-size: 1rem;
	line-height: 27px;
`;
