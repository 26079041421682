import React from 'react';
import { Icons } from '../../../assets/icons';
import * as s from './styled-environment-alert';

const { REACT_APP_ENV } = process.env;
const environment = REACT_APP_ENV;

const EnvironmentAlert = () => {
	function renderMessage() {
		if (environment === 'development') {
			return 'desenvolvimento - DEV';
		}
		if (environment === 'homologation') {
			return 'homologação - HML';
		}
	}

	return (
		<s.Container>
			<s.ContainerFlex>
				<s.ContainerShield>
					<Icons.Shield />
				</s.ContainerShield>
				<p className="alert-text">Você está no ambiente de </p>
				<p className="alert-text-strong">{renderMessage()}</p>
			</s.ContainerFlex>
		</s.Container>
	);
};

export default EnvironmentAlert;
