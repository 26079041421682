import styled from 'styled-components';
import { colors } from '../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	flex: 1;
`;

export const Header = styled.div`
	display: flex;
	width: 100%;
	gap: 2.5rem;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
`;

export const DetailsBody = styled.div`
	display: flex;
	width: 100%;
	gap: 2.5rem;
	border-radius: 0.9375rem;
`;

export const FooterList = styled.div`
	display: flex;
	width: 100%;
	gap: 2.5rem;
	justify-content: center;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
`;

export const ItensBody = styled.div`
	display: flex;
	width: 100%;
	gap: 2.5rem;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
`;
export const CenterLoader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	align-self: center;
	height: 100%;
	width: 100%;
`;
