/* eslint-disable import/no-cycle */
import { axiosApp } from '.';

const getContractorById = (id) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/contractors/${id}`) // 21
			.then(({ data }) => resolve(data))
			.catch((error) => {
				reject(error);
			});
	});

const putContractorByStatus = (contractorId, idStatus) =>
	new Promise((resolve, reject) => {
		axiosApp
			.put(`/contractors/status/${contractorId}/${idStatus}`) // 21
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const postContractor = (dataContractor, sendEmail) =>
	// ?sendEmail=${sendEmail}sendEmail , qs.stringify({ sendEmail })
	new Promise((resolve, reject) => {
		axiosApp
			.post(`/contractors?sendEmail=${sendEmail}`, dataContractor)
			.then((data) => resolve({ data }))
			.catch((error) => reject(error));
	});

const getContractors = (page = 1, order = 'asc', filter = 'all') =>
	new Promise((resolve, reject) => {
		// Quando adicionar filtro e ordem, a URL ficaria mais ou menos assim :
		// /contractors?order=${order}&filter=${filter}&page=${page}
		axiosApp
			.get(`/contractors?order=${order}&filter=${filter}&page=${page}`)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getActiveStatus = (filter) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/contractors?filter=${filter}`)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const postUpdateContractor = (contractorId, dataContractor, sendEmail) =>
	new Promise((resolve, reject) => {
		axiosApp
			.put(
				`/contractors/${contractorId}?sendEmail=${sendEmail}`,
				dataContractor
			)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getSearchContractor = (searchValue, page = 1) =>
	// ?sendEmail=${sendEmail}sendEmail , qs.stringify({ sendEmail })
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/contractors/search?term=${searchValue}&page=${page}`)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

export {
	getContractorById,
	postContractor,
	postUpdateContractor,
	putContractorByStatus,
	getContractors,
	getSearchContractor,
	getActiveStatus,
};
