import React from 'react';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';
import {
	Access,
	Accesses,
	Antecipacoes,
	Client,
	Contractor,
	Contractors,
	Dashboard,
	Estorno,
	ForgotPassword,
	Invite,
	Logs,
	Login,
	// NewAccess,
	NewContractor,
	NewPassword,
	NewPaymentLink,
	NewRecipient,
	NotFound,
	PaymentLinks,
	PaymentDetails,
	Recipients,
	RecipientDetails,
	Signup,
	Transactions,
	TransactionsDetails,
} from '../pages';
import { useAuth } from '../hooks';
import {
	NewPaymentLinksProvider,
	RecipientsProvider,
	RecipientInfoProvider,
	AnticipationProvider,
} from '../contexts';
import {
	DeslogadoRoute,
	LogadoRoute,
	AdminRoute,
	// ContratanteRoute,
	AdminRouteWithContext,
	SubContractorRoute,
} from './route-types';

const Routes = ({ setNotFound, location }) => {
	const { currentUser } = useAuth();
	const userType = currentUser?.role;
	const isBrioContractor = currentUser?.contractorId === 'KV9XYmke83Z8Pcmuvbec';
	const canSeeClientsMenu =
		userType === 'admin' || (isBrioContractor && userType !== 'subcontractor');

	return (
		<Switch>
			{/* PÚBLICO - DESLOGADO */}
			<DeslogadoRoute exact path="/login" component={Login} />

			<DeslogadoRoute exact path="/cadastrar" component={Signup} />

			<DeslogadoRoute exact path="/esqueci-senha" component={ForgotPassword} />

			<DeslogadoRoute exact path="/redefinir-senha" component={NewPassword} />

			<DeslogadoRoute exact path="/invite" component={Invite} />

			{/* PÚBLICO - LOGADO */}

			<LogadoRoute exact path="/resumo" component={Dashboard} />

			<LogadoRoute exact path="/transacoes" component={Transactions} />

			{canSeeClientsMenu ? (
				<LogadoRoute exact path="/clientes" component={Client} />
			) : null}

			<LogadoRoute
				path="/transacoes/estorno/:transactionId"
				component={Estorno}
				exact
			/>

			<LogadoRoute
				path="/transacoes/:transactionId"
				component={TransactionsDetails}
				exact
			/>

			<LogadoRoute exact path="/taxas" component={() => <h1>Taxes</h1>} />

			<LogadoRoute exact path="/recebedores" component={Recipients} />

			<LogadoRoute
				exact
				path="/recebedores/adicionar-recebedor"
				component={() => (
					<RecipientsProvider>
						<NewRecipient />
					</RecipientsProvider>
				)}
			/>

			<LogadoRoute
				exact
				path="/recebedores/:recipientId"
				component={() => (
					<RecipientInfoProvider>
						<RecipientDetails />
					</RecipientInfoProvider>
				)}
			/>

			<LogadoRoute
				exact
				path="/recebedores/:recipientId/antecipacoes"
				component={() => (
					<AnticipationProvider>
						<Antecipacoes />
					</AnticipationProvider>
				)}
			/>

			<LogadoRoute exact path="/links-pagamento" component={PaymentLinks} />

			<LogadoRoute
				exact
				path="/links-pagamento/criar-link-pagamento"
				component={() => (
					<NewPaymentLinksProvider>
						<NewPaymentLink />
					</NewPaymentLinksProvider>
				)}
			/>

			<LogadoRoute
				exact
				path="/links-pagamento/:paymentLinkId"
				component={PaymentDetails}
			/>

			<LogadoRoute
				exact
				path="/configuracoes"
				component={() => <h1>Configurações</h1>}
			/>

			{/* SUBCONTRACTOR - LOGADO */}
			<SubContractorRoute exact path="/transacoes" component={Transactions} />

			<SubContractorRoute
				path="/transacoes/estorno/:transactionId"
				component={Estorno}
				exact
			/>

			<SubContractorRoute
				path="/transacoes/:transactionId"
				component={TransactionsDetails}
				exact
			/>

			{/* ADMIN - LOGADO */}
			{AdminRouteWithContext(['/extrato', '/antecipacoes'], location)}
			<AdminRoute exact path="/contratantes" component={Contractors} />

			<AdminRoute
				exact
				path="/contratante/adicionar-contratante"
				component={NewContractor}
			/>
			<AdminRoute
				exact
				path="/contratante/:contractorId"
				component={Contractor}
			/>
			<AdminRoute exact path="/acessos/novo" component={Access} />

			<AdminRoute exact path="/acessos/:userId" component={Access} />

			<AdminRoute exact path="/acessos" component={Accesses} />

			<AdminRoute exact path="/logs" component={Logs} />

			{window.location.pathname === '/' &&
				(userType ? <Redirect to="/resumo" /> : <Redirect to="/login" />)}

			<Route
				path="*"
				component={() => <NotFound setNotFound={setNotFound} />}
			/>
		</Switch>
	);
};
export default withRouter(Routes);
