import React, { useState, useCallback } from 'react';
import {
	PieChart,
	Pie,
	Cell,
	ResponsiveContainer,
	Legend,
	Sector,
	Label,
} from 'recharts';
import { colors } from '../../../../assets/colors';
import { Icons } from '../../../../assets/icons';
import { Loader, FloaterReact } from '../../../../components';
import { translateStatus } from '../../../../utils';

import * as s from './styled-pie-chart';

const statusColor = (type) => {
	if (type === 'chargedback') {
		return colors.graphs.yellow;
	}
	if (type === 'paid') {
		return colors.graphs.green;
	}
	if (type === 'refused') {
		return colors.graphs.red;
	}
	if (type === 'authorized') {
		return colors.graphs.cyan;
	}
	if (type === 'refunded') {
		return colors.graphs.pink;
	}
	if (type === 'pending_refund') {
		return colors.graphs.orange;
	}
	if (type === 'waiting_payment') {
		return colors.graphs.purple;
	}
	if (type === 'processing') {
		return colors.graphs.blue;
	}
	if (type === 'pending_review') {
		return colors.white;
	}
	return colors.graphs.gray;
};

export default function RoundChart({ data, loading }) {
	const [activeIndex, setActiveIndex] = useState(null);

	const totalTransactions = data?.reduce((acc, curr) => {
		return acc + curr.count;
	}, 0);

	const renderLegend = () => {
		const isDataBiggerThanFive = data?.length > 5;

		const firstData = isDataBiggerThanFive ? data.slice(0, 5) : data;
		const otherData = isDataBiggerThanFive ? data.slice(5) : null;

		const OtherItens = () => (
			<s.FloaterWrapper>
				{otherData?.map((item) => (
					<s.FloaterRow item={item} key={item?.type}>
						<s.Item>
							<s.ItemType>{`${translateStatus(item?.type)}:`}&nbsp;</s.ItemType>
						</s.Item>
						<span className="percentage">{`${item?.percentage}%`}</span>
					</s.FloaterRow>
				))}
			</s.FloaterWrapper>
		);

		const ItensList = () => (
			<>
				<s.Ul>
					{firstData?.map((item) => (
						<s.Li status={item.type} key={item.type}>
							<s.ItemLista>
								<span className="title">{translateStatus(item.type)}</span>
								<span className="value">{item.count}</span>
							</s.ItemLista>
						</s.Li>
					))}
					{otherData?.length > 0 && (
						<s.Li>
							<s.ItemLista>
								<span className="outros">Outros</span>
								<FloaterReact hover component={OtherItens} placement="right">
									<Icons.InfoIconOutlined
										fill={colors.white}
										style={{ transform: 'translateY(-10%)' }}
									/>
								</FloaterReact>
							</s.ItemLista>
						</s.Li>
					)}
				</s.Ul>
			</>
		);

		return <ItensList />;
	};
	const onPieEnter = useCallback(
		(_, index) => {
			setActiveIndex(index);
		},
		[setActiveIndex]
	);

	const onPieLeave = useCallback(() => {
		setActiveIndex(null);
	}, [setActiveIndex]);

	const renderActiveShape = (props) => {
		const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
			props;

		return (
			<g>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius - 4}
					outerRadius={outerRadius + 4}
					startAngle={startAngle}
					endAngle={endAngle}
					fill={fill}
				/>
			</g>
		);
	};

	function CustomLabel({ viewBox, value1, value2 }) {
		const { cx, cy } = viewBox;
		return (
			<>
				<text
					x={cx}
					y={cy}
					fill={colors.white}
					fontFamily="Jost"
					className="recharts-text recharts-label"
					textAnchor="middle"
					dominantBaseline="central"
				>
					<tspan
						alignmentBaseline="middle"
						dx="0em"
						dy="-1em"
						fontSize="0.625em"
					>
						{value1}
					</tspan>
				</text>
				<text
					x={cx}
					y={cy + 10}
					fill={colors.white}
					className="recharts-text recharts-label"
					textAnchor="middle"
					dominantBaseline="central"
				>
					<tspan fontSize="1.25rem" fontWeight="Bold">
						{value2}
					</tspan>
				</text>
			</>
		);
	}

	return !loading && data ? (
		<s.Container>
			<ResponsiveContainer width="50%">
				<PieChart>
					<Legend
						align="left"
						verticalAlign="middle"
						layout="vertical"
						content={renderLegend}
						wrapperStyle={{
							marginTop: 15,
							left: 10,
							width: '100%',
							height: '80%',
						}}
					/>
				</PieChart>
			</ResponsiveContainer>
			<ResponsiveContainer width="50%">
				<PieChart>
					<Pie
						activeIndex={activeIndex}
						activeShape={renderActiveShape}
						data={data}
						dataKey="count"
						innerRadius="80%"
						outerRadius="95%"
						cy="50%"
						cx="50%"
						stroke="none"
						onMouseMove={onPieEnter}
						onMouseLeave={onPieLeave}
						animationDuration={500}
					>
						{data.map((entry) => (
							<Cell key={entry.type} fill={statusColor(entry.type)} />
						))}
						<Label
							color={colors.white}
							style={{ stroke: 'white', fill: 'white' }}
							position="center"
							content={
								<CustomLabel
									value1="Total de transações"
									value2={totalTransactions}
								/>
							}
						/>
					</Pie>
				</PieChart>
			</ResponsiveContainer>
		</s.Container>
	) : (
		<Loader style={{ margin: 'auto' }} />
	);
}
