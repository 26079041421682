import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 2.5rem 1.5rem;

	.checkbox-custom {
		display: flex;
		justify-content: flex-start;
		height: fit-content;
		color: ${({ checked }) =>
			checked ? colors.primaryBlue : colors.lightGray};
	}
`;

export const FormGroup = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 4.5rem;
	.underline-input {
		padding: 1rem;
		:focus {
			background-color: transparent;
		}
	}

	.aditional-form {
		display: ${({ checked }) => (checked ? 'flex' : 'none')};
	}

	.custom-select-input {
		border-bottom: 1px solid ${colors.primaryBlue};
		border-radius: 0;
	}
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 3em;
`;

export const Title = styled.h2`
	font-family: Jost;
	font-style: normal;
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 2rem;
	letter-spacing: 0.04em;
	color: ${colors.offWhite};
	justify-content: flex-start;
	text-align: left;
	margin-bottom: 0.5rem;
`;

export const Subtitle = styled.h3`
	color: ${colors.lightGray};
	font-size: 1.25rem;
	font-weight: 400;
`;

export const RequiredText = styled.span`
	color: ${colors.offWhite};
	font-weight: 500;
	font-size: 1.25rem;
	margin-top: 2rem;
`;

export const TitleInput = styled.h4`
	color: ${colors.offWhite};
	font-size: 1rem;
	font-style: normal;
	font-weight: normal;
	padding-bottom: 1rem;
`;

export const RadioGroup = styled.div`
	display: flex;
	gap: 2rem;
	margin-bottom: 2rem;
`;

export const RadioInput = styled.input``;

export const Label = styled.label`
	display: inline-block;
	margin-right: 2.5rem;
	margin-left: 1rem;
	font-size: 1.25rem;
	line-height: 1.625rem;
	color: ${colors.lightGray};
`;

export const InputGroup = styled.div`
	display: flex;
	margin-bottom: 2rem;
`;

export const RowsGroup = styled.div`
	display: flex;
	gap: 2rem;
	flex-direction: column;
	.wrapper-underline-input:first-of-type {
		margin-bottom: 2rem;
	}
`;

export const Row = styled.div`
	display: flex;
	gap: 2rem;
	margin-bottom: 2rem;
`;

export const ButtonGroup = styled.div`
	display: flex;
	gap: 1rem;
	margin-top: ${({ checked }) => (checked ? '6rem' : 'auto')};
	justify-content: space-between;
	button {
		width: 12.5rem;
		height: 3.3125rem;
	}
`;

export const SociosContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 4.5rem;
`;
