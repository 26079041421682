import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 2rem;
	height: 6.3125rem;
	width: 100%;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 0 1.5rem;
`;

export const ButtonGroup = styled.div`
	display: flex;
	gap: 0.9375rem;
	margin-left: 1.5rem;
`;
