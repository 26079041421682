import React from 'react';
import { colors } from '../../colors';

const InfoTriangleIcon = ({ title, fill, style }) => (
	<svg
		style={style}
		width="148"
		height="140"
		viewBox="0 0 148 140"
		fill="none"
		className="info-triangle-icon"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || ''}</title>
		<g filter="url(#filter0_d_4372_18312)">
			<path
				d="M79.0815 92.4932C79.0815 95.4741 76.6651 97.8905 73.6842 97.8905C70.7034 97.8905 68.287 95.4741 68.287 92.4932C68.287 89.5124 70.7034 87.096 73.6842 87.096C76.6651 87.096 79.0815 89.5124 79.0815 92.4932Z"
				fill={fill || colors.red}
			/>
			<path
				d="M73.6842 42.3357C75.9198 42.3357 77.7322 44.148 77.7322 46.3836V73.3699C77.7322 75.6055 75.9198 77.4178 73.6842 77.4178C71.4486 77.4178 69.6363 75.6055 69.6363 73.3699V46.3836C69.6363 44.148 71.4486 42.3357 73.6842 42.3357Z"
				fill={fill || colors.red}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M85.9379 22.4673C80.0442 13.8443 67.324 13.8442 61.4304 22.4672L59.0992 25.878C44.494 47.2469 31.9456 69.9511 21.6222 93.6864L21.1352 94.8059C17.4972 103.17 22.9936 112.668 32.0585 113.681C59.7228 116.773 87.6455 116.773 115.31 113.681C124.375 112.668 129.871 103.17 126.233 94.8059L125.746 93.6864C115.423 69.951 102.874 47.2469 88.2691 25.878L85.9379 22.4673ZM68.1142 27.0355C70.7932 23.116 76.5751 23.116 79.254 27.0355L81.5852 30.4463C95.9019 51.3931 108.202 73.6489 118.322 96.9154L118.809 98.0349C120.274 101.403 118.061 105.227 114.411 105.635C87.3439 108.66 60.0244 108.66 32.9577 105.635C29.3076 105.227 27.0944 101.403 28.5593 98.0349L29.0462 96.9154C39.1658 73.6489 51.4663 51.3931 65.7831 30.4463L68.1142 27.0355Z"
				fill={fill || colors.red}
			/>
		</g>
		<defs>
			<filter
				id="filter0_d_4372_18312"
				x="0"
				y="0"
				width="147.368"
				height="140"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="10" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.929412 0 0 0 0 0.388235 0 0 0 0 0.388235 0 0 0 0.5 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_4372_18312"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_4372_18312"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);
export default InfoTriangleIcon;
