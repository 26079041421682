import styled from 'styled-components';
import { colors } from '../../../../assets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.0625rem;
	background-color: ${colors.darkGray};
	border-radius: 0.9375rem;
`;

export const Method = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px 24px 56px 24px;
`;

export const Saldo = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px 24px 56px 24px;
`;

export const Row = styled.div`
	height: 1px;
	width: 96.5%;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	align-self: center;
	background-color: ${colors.lightGray};
	opacity: 0.5;
	border-radius: 0.3125rem;
`;

export const Tittle = styled.div`
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 32px;
	color: ${colors.white};
	padding-bottom: 2.5rem;
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-family: 'IBM Plex Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: ${colors.white};
	p {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 26px;
		color: ${colors.lightGray};
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: row;
	gap: 6.5rem;
`;
