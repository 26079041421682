import React from 'react';
import { Calendar } from 'norama-react-modern-calendar-datepicker';
import * as s from './styled-calendario';
import { customLocaleCalendar } from '../../../utils';
import { colors } from '../../../assets';

const Calendario = ({
	selectedDayRange,
	setSelectedDayRange,
	minimumDate,
	maximumDate,
	numberOfMonths,
	readOnly,
}) => (
	<s.ContainerCalendario
		numberOfMonths={numberOfMonths || 1}
		readOnly={readOnly}
	>
		<Calendar
			value={selectedDayRange}
			onChange={setSelectedDayRange}
			shouldHighlightWeekends
			minimumDate={minimumDate}
			maximumDate={maximumDate}
			colorPrimary={colors.primaryBlue}
			colorPrimaryLight="rgb(100, 209, 237, 0.4)"
			locale={customLocaleCalendar}
			calendarClassName="calendario"
			numberOfMonths={numberOfMonths}
		/>
	</s.ContainerCalendario>
);

export default Calendario;
