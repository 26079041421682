/* eslint-disable no-else-return */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React from 'react';
import { CheckboxCustom } from '../../../..';
import {
	capitalizeFirstLetter,
	translatePaymentMethod,
	translateStatus,
	translateRefuseReason,
} from '../../../../../utils';

import * as S from './styled-dropdown-filter';

const flagsOptions = [
	'visa',
	'elo',
	'aura',
	'amex',
	'discover',
	'mastercard',
	'hipercard',
	'jcb',
	'diners',
];
const installmentsOptions1 = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
const installmentsOptions2 = [
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
];
const paymentMethodOptions = [
	'pix',
	'boleto',
	'credit_card',
	'debit_card',
	'foreign_credit_card',
];
const refuseReasonOptions = [
	'acquirer',
	'acquirer_timeout',
	'antifraud',
	'internal_error',
];
const statusOptions = [
	'paid',
	'refused',
	'refunded',
	'pending_refund',
	'waiting_payment',
	'processing',
	'chargedback',
	'authorized',
	'pending_review',
];

function RenderCheckboxCustom({ state, setState, id, text }) {
	return (
		<CheckboxCustom
			checked={state.includes(id)}
			state={state}
			setState={setState}
			fontSize="1.25rem"
			text={capitalizeFirstLetter(text)}
			checkboxAlign="flex-start"
			onChange={() => {
				if (state.includes(id)) {
					setState(state.filter((item) => item !== id));
				} else {
					setState([...state, id]);
				}
			}}
		/>
	);
}

function DropdownFilter({
	cardBrand,
	setCardBrand,
	installments,
	setInstallments,
	paymentMethod,
	setPaymentMethod,
	refuseReason,
	setRefuseReason,
	status,
	setStatus,
}) {
	return (
		<S.Container>
			<S.Column>
				<S.ColumnTitle>BANDEIRAS</S.ColumnTitle>
				{flagsOptions.map((item) => (
					<RenderCheckboxCustom
						state={cardBrand}
						setState={setCardBrand}
						key={item}
						id={item}
						text={item}
					/>
				))}
			</S.Column>

			<S.Column>
				<S.ColumnTitle>NÚMERO DE PARCELAS</S.ColumnTitle>

				<S.ColumnParcelas>
					<S.RowParcelas>
						{installmentsOptions1.map((item) => (
							<RenderCheckboxCustom
								state={installments}
								setState={setInstallments}
								key={item}
								id={item}
								text={`${item}x`}
							/>
						))}
					</S.RowParcelas>
					<S.RowParcelas>
						{installmentsOptions2.map((item) => (
							<RenderCheckboxCustom
								state={installments}
								setState={setInstallments}
								key={item}
								id={`${item}`}
								text={`${item}x`}
							/>
						))}
					</S.RowParcelas>
				</S.ColumnParcelas>
			</S.Column>

			<S.Column>
				<S.ColumnTitle>FORMAS DE PAGAMENTO</S.ColumnTitle>

				{paymentMethodOptions.map((item) => (
					<RenderCheckboxCustom
						state={paymentMethod}
						setState={setPaymentMethod}
						key={item}
						id={item}
						text={translatePaymentMethod(item)}
					/>
				))}
			</S.Column>

			<S.Column>
				<S.ColumnTitle>RAZÃO DE RECUSA</S.ColumnTitle>

				{refuseReasonOptions.map((item) => (
					<RenderCheckboxCustom
						state={refuseReason}
						setState={setRefuseReason}
						key={item}
						id={item}
						text={translateRefuseReason(item)}
					/>
				))}
			</S.Column>

			<S.Column>
				<S.ColumnTitle>STATUS DE TRANSAÇÃO</S.ColumnTitle>

				{statusOptions.map((item) => (
					<RenderCheckboxCustom
						state={status}
						setState={setStatus}
						key={item}
						id={item}
						text={translateStatus(item)}
					/>
				))}
			</S.Column>
		</S.Container>
	);
}

export default DropdownFilter;
