import styled from 'styled-components';
import { colors } from '../../../assets/colors';

export const Container = styled.div`
	display: flex;
	height: 2.25rem;
	width: 8.25rem;
	border-radius: 0.5rem;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	background: ${({ isInvalid }) =>
		isInvalid ? colors.primaryRed : colors.primaryBlue};

	.container-custom-input {
		display: flex;
		height: 2.25rem;
		border: none;
		background: transparent;
		justify-content: center;
		width: max-content;
	}

	.custom-input {
		padding: 0;
		justify-content: center;
		color: ${colors.darkGray};
		font-weight: bold;
		text-align: center;
	}
`;

export const Botao = styled.button`
	background-color: ${colors.darkGray};
	border-radius: 0.5rem;
	margin: 0 0.125rem;
	height: 2rem;
	width: 2rem;

	:first-child {
		padding-left: 0.625rem;
		padding-right: 0.7rem;
	}
	:last-child {
		padding-left: 0.7rem;
		padding-right: 0.625rem;
	}

	:disabled {
		background-color: ${colors.gray};
	}

	.chevron-icon {
		colors: ${({ isInvalid }) =>
			isInvalid ? colors.primaryRed : colors.primaryBlue};
	}
`;

export const InputContainer = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
`;
