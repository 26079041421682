import Amex from './amex-icon';
import Aura from './aura-icon';
import Diners from './diners-icon';
import Discover from './discover-icon';
import Elo from './elo-icon';
import Hipercard from './hipercard-icon';
import Jcb from './jcb-icon';
import Mastercard from './mastercard-icon';
import Visa from './visa-icon';

export const CardFlag = {
	Amex,
	Aura,
	Diners,
	Discover,
	Elo,
	Hipercard,
	Jcb,
	Mastercard,
	Visa,
};
