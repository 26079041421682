import React from 'react';

const MasterCard = ({ title, fill, style }) => (
	<svg
		width="26"
		height="16"
		style={style}
		viewBox="0 0 26 16"
		fill={fill || 'none'}
		xmlns="http://www.w3.org/2000/svg"
		className="mastercard-icon"
	>
		<title>{title || 'Ícone Mastercard'}</title>
		<rect x="-0.000366211" width="26" height="16" rx="2" fill="white" />
		<circle cx="16.7497" cy="8.00002" r="6.00002" fill="#FF9F00" />
		<circle cx="9.24965" cy="8.00002" r="6.00002" fill="#FF0015" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.9996 12.6842C14.3713 11.5846 15.2496 9.89498 15.2496 8.00019C15.2496 6.10541 14.3713 4.41576 12.9996 3.31616C11.6279 4.41576 10.7496 6.10541 10.7496 8.00019C10.7496 9.89498 11.6279 11.5846 12.9996 12.6842Z"
			fill="#FF5F00"
		/>
	</svg>
);
export default MasterCard;
