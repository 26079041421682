import React from 'react';

const Boleto = ({ title, fill, style }) => (
	<svg
		style={style}
		className="boleto-icon"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>{title || 'Ícone de boleto'}</title>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19 21.5002H6C4.067 21.5002 2.5 19.9332 2.5 18.0002V4.9434C2.5 3.87584 3.55584 3.19905 4.4849 3.5216C4.61762 3.56768 4.74776 3.63415 4.87186 3.72279L5.0469 3.84783C5.91493 4.46785 7.08891 4.4651 7.95938 3.84334C9.17632 2.9741 10.8237 2.9741 12.0406 3.84334C12.9111 4.4651 14.0851 4.46785 14.9531 3.84783L15.1281 3.72279C16.1209 3.01365 17.5 3.72334 17.5 4.9434V12.5002H21C21.4142 12.5002 21.75 12.836 21.75 13.2502V18.7502C21.75 20.269 20.5188 21.5002 19 21.5002ZM17.75 14.0002V18.7502C17.75 19.4406 18.3096 20.0002 19 20.0002C19.6904 20.0002 20.25 19.4406 20.25 18.7502V14.0002H17.75ZM13.5 9.7502C13.5 9.33599 13.1642 9.0002 12.75 9.0002H6.75C6.33579 9.0002 6 9.33599 6 9.7502C6 10.1644 6.33579 10.5002 6.75 10.5002H12.75C13.1642 10.5002 13.5 10.1644 13.5 9.7502ZM12.5 12.7502C12.5 12.336 12.1642 12.0002 11.75 12.0002H6.75C6.33579 12.0002 6 12.336 6 12.7502C6 13.1644 6.33579 13.5002 6.75 13.5002H11.75C12.1642 13.5002 12.5 13.1644 12.5 12.7502ZM12.75 15.0002C13.1642 15.0002 13.5 15.336 13.5 15.7502C13.5 16.1644 13.1642 16.5002 12.75 16.5002H6.75C6.33579 16.5002 6 16.1644 6 15.7502C6 15.336 6.33579 15.0002 6.75 15.0002H12.75Z"
			fill={fill || '#B1B6B6'}
		/>
	</svg>
);
export default Boleto;
