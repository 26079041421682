import React from 'react';
import * as s from './styled-custom-monetary-value';
import { formatarParaReais } from '../../../utils';

const CustomMonetaryValue = ({ valor, fontSize, filled }) => {
	if (valor) {
		const negativo = valor < 0;
		let valorVisual = valor;

		if (negativo) valorVisual = -1 * valor;

		const valorFormatado = formatarParaReais(valorVisual / 100);

		return (
			<s.Container className="custom-monetary-value">
				<s.Sinal
					negativo={negativo}
					neutro={valor === 0}
					fontSize={fontSize}
					filled={filled}
				>
					{negativo ? (
						<span className="negativo">-</span>
					) : (
						<span className="positivo">+</span>
					)}
					<span className="cifrao">R$</span>
					<span className="valor">{valorFormatado}</span>
				</s.Sinal>
			</s.Container>
		);
	}

	return (
		<s.Container className="custom-monetary-value">
			<s.Sinal neutro fontSize={fontSize}>
				<span className="cifrao">R$</span>
				<span className="valor">0,00</span>
			</s.Sinal>
		</s.Container>
	);
};

export default CustomMonetaryValue;
