import styled from 'styled-components';
import { colors } from '../../../../assets/colors';

export const Container = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	background-color: ${colors.white};
`;

export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 30px;
	margin-bottom: 5px;
`;

export const HeaderColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

export const HeaderColumnTitle = styled.span`
	font-size: 16px;
`;

export const HeaderColumnContent = styled.span`
	font-size: 14px;
	white-space: pre;
	cursor: text;
	font-weight: 700;
	:not(.transaction-id) {
		margin-top: 8px;
	}
	.transaction-id {
		font-size: 24px !important;
		font-weight: 700 !important;
	}
	.value {
		font-size: 24px;
	}
`;

export const DadosCartao = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
`;

export const CartaoTitle = styled.span`
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 30px;
`;

export const CartaoContent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const CartaoContentItem = styled.div`
	display: flex;
	flex-direction: row;
`;

export const CartaoContentItemTitle = styled.span`
	font-size: 14px;
	font-weight: 700;
	margin-right: 5px;
`;

export const CartaoContentItemContent = styled.span`
	font-size: 14px;
	white-space: pre;
`;

export const Divisoria = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	margin-top: 10px;
	margin-bottom: 10px;

	svg rect {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 2px;
	}

	#verde {
		fill: ${colors.green};
		stroke: ${colors.green};
	}

	#roxo {
		fill: ${colors.violet};
		stroke: ${colors.violet};
	}

	#azul {
		fill: ${colors.primaryBlue};
		stroke: ${colors.primaryBlue};
	}
`;

export const ValuesContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: -140px;
	margin-bottom: 30px;
`;

export const ValuesDiv = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
`;

export const ValuesTitle = styled.span`
	font-size: 16px;
	margin-bottom: 5px;
`;

export const Values = styled.span`
	font-size: 16px;
`;

export const ClientContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 15px;
`;

export const Title = styled.span`
	font-size: 16px;
	margin-bottom: 5px;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 5px;
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	width: calc(100% / 3);

	span {
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: 400;
		font-size: 1.125em;
		/* line-height: 32px; */
	}
`;

export const ColTitle = styled.div`
	justify-content: center;
	h5 {
		font-size: 1em;
		font-family: IBM Plex Sans;
		font-style: normal;

		font-weight: 600;
		line-height: 21px;
		:first-of-type {
			margin-right: 50px;
		}
	}
`;
export const ColAddress = styled.div`
	span {
		:first-of-type {
			margin-right: ${({ loading }) => (loading === true ? 0 : '60px')};
		}
	}
`;

export const Col2 = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	width: calc(100% / 2);

	span {
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: 400;
	}
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 15px;
	page-break-inside: avoid;
	h3 {
		font-weight: 600;
		font-size: 16px;
	}
`;

export const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: Row;
	align-items: center;
	align-content: center;
`;

// ====================== estilos tabela recebedores =======================  //

export const MainReceiverContainer = styled.div`
	display: flex;
	grid-column: span 3 / auto;
	flex-direction: column;
	height: 100%;
	page-break-inside: avoid;

	/* 	padding: 18px 10px 37px 42px; */
	> span {
		padding-bottom: 6px;
	}
	.subtitle {
		display: flex;
		flex-direction: row;
		font-size: 1em;
		justify-content: space-between;
		align-items: center;

		font-family: IBM Plex Sans;
		font-style: normal;
		text-align: left;

		.valor-bruto {
			font-family: Jost;
			font-weight: 700;
			font-size: 1.5rem;
			text-align: right;
		}
	}
`;

export const ReceiversContainer = styled.div`
	display: flex;
	grid-column: span 3 / auto;
	flex-direction: column;
	height: 100%;
	padding: 18px 0 0 0;
	> span {
		padding-bottom: 6px;
	}
	.subtitle {
		display: flex;
		flex-direction: row;
		font-size: 1em;
		justify-content: space-between;

		font-family: IBM Plex Sans;
		font-style: normal;
		text-align: left;
		color: ${colors.white};
		h2 {
			font-family: Jost;
			font-style: normal;
			font-weight: 600;
			font-size: 1.375rem;
			line-height: 32px;
			text-align: right;
		}
	}
`;

export const ReceiversTitle = styled.h1`
	display: flex;
	flex-direction: row;
	font-size: 1em;
	padding-bottom: 10px;
	justify-content: space-between;

	font-family: IBM Plex Sans;
	font-style: normal;
	font-weight: 400;

	letter-spacing: 0em;
	text-align: left;
`;

export const Infos = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	h3 {
		font-family: Jost;
		font-style: normal;
		font-weight: 600;
		font-size: 1.375rem;
		text-align: right;
	}

	div {
		display: flex;
	}
`;

export const CardFooter = styled.div`
	display: flex;
	flex: 1;
`;

export const CardFooterItem = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
`;

export const ReceiverContainer = styled.div`
	display: relative;
	flex-direction: column;
	width: 100%;
	margin-top: 10px;
	page-break-inside: avoid;
`;

export const ReceiverHeader = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
	padding: 10px 0;
	page-break-inside: avoid;
`;

export const LeftReceiverHeader = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	span {
		font-size: 1.25rem;
	}

	.title {
		font-family: Jost;
		font-style: normal;
		font-weight: 600;
		font-size: 1em;
		line-height: 23px;
	}
	.status {
		font-size: 1.25rem;
		font-weight: 400;
		align-content: center;
		align-items: center;
		span:first-child {
			margin-right: 0.625rem;
		}
	}
`;

export const RightReceiverHeader = styled.div`
	display: flex;
	flex-direction: column;
	text-align: right;
	justify-content: space-between;
	margin-left: auto;
`;

export const ReceiversRow = styled.div`
	display: flex;
	flex-direction: row;
`;

export const ReceiversCol = styled.div`
	display: flex;
	flex-direction: column;
	text-align: right;
	align-items: flex-end;
	h2 {
		font-family: Jost;
		font-weight: 600;
		font-size: 1.375rem;
	}
	h3 {
		font-weight: bold;
		font-size: 1rem;
	}
	p {
		font-size: 1rem;
		justify-content: right;
		overflow: hidden;
		white-space: nowrap;
	}
	:last-of-type {
		padding-left: 3.125rem;
	}
`;

export const Installments = styled.div`
	width: 100%;
	transform: translateY(-15px);
	/* 	padding: 0 45px 0 23px; */

	table {
		width: 100%;
		text-align: left;
		font-size: 1.25rem;
		th {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		td {
			font-size: 1.2rem !important;
			padding: 0 3px;
		}
		.status-cell {
			font-size: 1.1rem !important;
		}
	}
`;

// ==================== estilos card histórico =================== //

export const MainHistoryContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 18px 0 0 0;

	> span {
		padding-bottom: 6px;
	}
`;

export const HistoryTitle = styled.h3`
	font-size: 16px;
	font-family: IBM Plex Sans;
	font-style: normal;
	font-weight: bold;
	text-align: left;
`;

export const HistoryInfos = styled.div`
	display: flex;
	flex-direction: column-reverse;
	align-items: center;

	h3 {
		font-weight: 600;
		font-size: 1.375em;
	}
	div {
		display: flex;
	}
`;
/* export const ReceiverContainer = styled.div`
	display: flex;
	display: relative;
	width: 100%;
	flex-direction: column;
	align-items: center;
	padding-bottom: 20px;
`; */
export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	padding: 5px 0 10px 15px;
	width: 100%;
	:last-child {
		padding-bottom: 0;
	}
`;

export const Index = styled.div`
	display: flex-start;
	flex-direction: column;
	position: relative;
	margin-right: 15px;
	font-family: Jost;
	font-style: normal;
	font-weight: 600;
	font-size: 1.125em;
	line-height: 26px;
`;

export const OperationContent = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	.title {
		font-family: Jost;
		font-style: normal;
		font-weight: 600;
		font-size: 1.125em;
	}
	.content {
		font-family: Work Sans;
		font-style: normal;
		font-weight: 500;
		font-size: 1em;
		letter-spacing: -0.02em;
		padding-bottom: 2px;
		:last-child {
			padding-bottom: 0;
		}
	}
`;
