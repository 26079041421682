import React from 'react';
import { Icons } from '../../../../assets';
import ContainerModal from '../container-modal';
import { ButtonCustom } from '../../..';

import * as s from './styled-modal-falha-edicao-contratante';

const ModalFalhaEdicao = ({ isOpen, closeModal, iconColor }) => {
	return (
		<ContainerModal
			isOpen={isOpen}
			closeModal={closeModal}
			iconColor={iconColor}
		>
			<s.Content>
				<s.Imagem>
					<Icons.ErrorAlertIcon />
				</s.Imagem>
				<s.Title>ERRO AO ATUALIZAR CONTRATANTE</s.Title>
				<s.Subtitle>Tente realizar as alterações em outro momento</s.Subtitle>
				<s.ButtonGroup>
					<ButtonCustom
						width="15rem"
						theme="alert-white"
						outlined
						textTransform="none"
						className="button-custom-cancelar"
						onClick={closeModal}
					>
						Sair
					</ButtonCustom>
				</s.ButtonGroup>
			</s.Content>
		</ContainerModal>
	);
};

export default ModalFalhaEdicao;
