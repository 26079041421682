import styled from 'styled-components';
import { colors } from '../../../../assets';

export const PrimeiroPasso = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.9375rem;
`;

export const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.9375rem;
	min-height: 600px;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: ${({ second }) => (second ? 'calc(40% - 0.9375rem)' : '60%')};
	min-width: ${({ second }) => (second ? '350px' : '60%')};
	flex: 1;
	background: ${colors.darkGray};
	border-radius: 0.9375rem;
	padding: 1.875rem 1.25rem;

	label:not(.label-radio-button, .label-underline-input) {
		display: flex;
		align-items: center;
		color: ${colors.offWhite};
		font-size: 1rem;
		font-weight: 400;
		margin-bottom: 15px;
	}

	label:not(:first-child, .label-radio-button, .label-underline-input) {
		margin-top: 30px;
	}

	svg.info-icon-outlined {
		width: 1.5rem;
		height: 1.5rem;
		margin-left: 5px;
	}

	span#erro {
		font-size: 0.75rem;
		color: ${colors.red};
		margin-top: 5px;
	}

	.wrapper-underline-input {
		max-width: 14.375rem;
	}

	.button-custom {
		margin-top: 50px;
	}

	hr {
		background: ${colors.gray};
		width: 100%;
		height: 1px;
		margin: 30px 0;
	}

	p#mensagem-rodape {
		font-size: 0.875rem;
		color: ${colors.lightGray};
		font-weight: 400;
	}

	.loading-rectangle:is(#valor-bruto, #total-taxas, #valor-antecipacao) {
		height: 3rem;
	}
	#valor-bruto {
		width: 17.1875rem;
	}
	#total-taxas {
		width: 12.5rem;
	}
	#valor-antecipacao {
		width: 15.625rem;
	}
	.loading-rectangle:is(#antecipacao, #transferencia) {
		height: 1.5rem;
	}
`;

export const Line = styled.div`
	display: flex;
	margin-top: ${({ marginTop }) => marginTop && '30px'};

	.radio-button:first-child .label-radio-button {
		margin-right: 30px;
	}
`;

export const Group = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 40px;

	p {
		display: flex;
		align-items: center;
		color: ${colors.lightGray};
		font-size: 1rem;
		font-weight: 400;
	}

	p:first-child {
		margin-right: 70px;
	}

	span {
		color: ${colors.primaryBlue};
		font-size: 1rem;
		font-weight: 400;
		margin-left: 10px;
	}
`;

export const ButtonsTabs = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 8.875rem;
	height: 2.1875rem;
	background: ${colors.gray};
	border: 1px solid ${colors.gray};
	border-radius: 0.5rem;
	padding: 1px;
	box-shadow: inset 0px 0px 7px rgba(0, 0, 0, 0.6);

	${({ selected }) =>
		selected === 'start'
			? '#inicio { margin-left: -2px; }'
			: '#final { margin-right: -2px; }'};
`;

export const Button = styled.button`
	background: none;
	font-size: 0.875rem;
	font-weight: 600;
	letter-spacing: 0.02em;
	padding: 0 0.625rem;
	position: relative;
	text-align: center;
	text-transform: none;
	color: ${colors.lightGray};
	border-radius: 0.5rem;
	width: 50%;

	:hover {
		color: ${({ selected }) => !selected && colors.primaryBlue};
	}

	${({ selected }) =>
		selected &&
		`
		font-weight: 600;
		color: ${colors.background};
		background: ${colors.primaryBlue};
		border: 1px solid ${colors.darkTurquoise};
		animation: aparecer 0.4s ease-out;
		backdrop-filter: blur(10px);
		height: 2.1875rem;
		`};

	:disabled {
		opacity: 0.3;
	}
`;

export const Table = styled.div`
	width: 20.9375rem;
	border: 1px solid ${colors.gray};
	border-radius: 15px;
	margin-top: 30px;
`;

export const Row = styled.div`
	display: ${({ buttons }) => (buttons ? 'flex' : 'grid')};
	grid-template-columns: ${({ buttons }) => !buttons && '45% 55%'};
	padding: ${({ buttons }) => !buttons && '0.9375rem'};
	border-bottom: ${({ first }) =>
		first ? `1px solid ${colors.gray}` : 'none'};
	flex: 1;

	label {
		font-weight: 400;
		margin: 0 !important;
	}

	.button-custom {
		margin-top: 30px;
		align-self: flex-end;
	}
	.button-custom:first-child {
		margin-right: 20px;
	}
`;
