import React from 'react';
import ReactDom from 'react-dom';
import { ButtonCustom } from '../../../';
import { UnderlineInput } from '../../../atoms';
import { Icons, colors } from '../../../../assets';

import * as s from './styled-modal-confirmacao-senha';

const ModalConfirmacaoSenha = (props) => {
	const {
		onClickOutside,
		open,
		onClose,
		title,
		onSubmit,
		senha,
		setSenha,
		isLoading,
		erroSenha,
		onKeyPress,
	} = props;

	if (!open) return null;

	return ReactDom.createPortal(
		<>
			<s.Overlay onClick={onClickOutside ? onClose : null} />
			<s.Modal>
				<s.IconWrapper onClick={onClose}>
					<Icons.Close fill={colors.primaryBlue} title="Fechar" />
				</s.IconWrapper>
				<s.Title>{title}</s.Title>
				<s.Subtitle>Digite sua senha para confirmar</s.Subtitle>
				<UnderlineInput
					type="password"
					label="Confirme sua senha"
					placeholder="Senha"
					value={senha}
					onChange={(e) => setSenha(e.target.value)}
					erro={erroSenha}
				/>
				<s.MensagemErro>{erroSenha && erroSenha}</s.MensagemErro>
				<s.ButtonGroup>
					<ButtonCustom
						isLoading={isLoading}
						disabled={senha.length < 6}
						onClick={onSubmit}
						onKeyPress={onKeyPress}
					>
						Confirmar
					</ButtonCustom>
				</s.ButtonGroup>
			</s.Modal>
		</>,
		document.getElementById('portal')
	);
};

export default ModalConfirmacaoSenha;
