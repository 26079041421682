import React from 'react';

function IconME({ style, title }) {
	return (
		<svg
			style={style}
			width="56"
			height="56"
			viewBox="0 0 56 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>{title || 'Ícone de ticket médio mercado externo'}</title>
			<rect width="56" height="56" rx="28" fill="#E2F9FF" />
			<path
				d="M33 19.9158C33 18.8621 32.1 18 31 18C29.9 18 29 18.8621 29 19.9158V35.2419C29 36.2955 29.9 37.1576 31 37.1576C32.1 37.1576 33 36.2955 33 35.2419V19.9158Z"
				fill="#63D2ED"
			/>
			<path
				d="M19 19.9158C19 18.8621 18.1 18 17 18C15.9 18 15 18.8621 15 19.9158V35.2419C15 36.2955 15.9 37.1576 17 37.1576C18.1 37.1576 19 36.2955 19 35.2419V19.9158Z"
				fill="#63D2ED"
			/>
			<path
				d="M24.1333 21.748H23.8667C22.84 21.748 22 22.6101 22 23.6638V35.2426C22 36.2963 22.84 37.1584 23.8667 37.1584H24.1333C25.16 37.1584 26 36.2963 26 35.2426V23.6638C26 22.6101 25.16 21.748 24.1333 21.748Z"
				fill="#63D2ED"
			/>
			<path
				d="M44.2104 20C44.2104 21.1 43.3484 22 42.2947 22L37.9158 22C36.8621 22 36 21.1 36 20C36 18.9 36.8621 18 37.9158 18L42.2947 18C43.3484 18 44.2104 18.9 44.2104 20Z"
				fill="#63D2ED"
			/>
			<path
				d="M44.2104 35C44.2104 36.1 43.3484 37 42.2947 37L37.9158 37C36.8621 37 36 36.1 36 35C36 33.9 36.8621 33 37.9158 33L42.2947 33C43.3484 33 44.2104 33.9 44.2104 35Z"
				fill="#63D2ED"
			/>
			<path
				d="M41.2104 27C41.2104 28.1 40.3484 29 39.2947 29L37.9158 29C36.8621 29 36 28.1 36 27C36 25.9 36.8621 25 37.9158 25L39.2947 25C40.3484 25 41.2104 25.9 41.2104 27Z"
				fill="#63D2ED"
			/>
		</svg>
	);
}

export default IconME;
