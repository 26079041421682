import { axiosApp } from '.';

const getFee = (idRecebedor, transactionsData) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(
				`/dashboard/payables/mdr?recipientId=${idRecebedor}&transactions=${transactionsData}`
			)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getBalance = (idRecebedor) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/dashboard/balance?recipientId=${idRecebedor}`)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

function getPayableTransaction(
	recipientId,
	initialDate,
	finalDate,
	page,
	elements,
	cardBrand = '',
	installments = '',
	paymentMethod = '',
	refuseReason = '',
	status = '',
	search = ''
) {
	return new Promise((resolve, reject) => {
		axiosApp
			.get(
				`/dashboard/payables/transactions?recipientId=${recipientId}&initialDate=${initialDate}&finalDate=${finalDate}&page=${page}&elements=${elements}&cardBrand=${cardBrand}&installments=${installments}&paymentMethod=${paymentMethod}&refuseReason=${refuseReason}&status=${status}&search=${search}`
			)
			.then(({ data }) => resolve(data))
			.catch((error) => {
				reject(error);
			});
	});
}

const getAnticipations = (
	idRecebedor,
	initialDate,
	finalDate,
	status = 'approved'
) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(
				`/dashboard/antecipations?recipientId=${idRecebedor}&status=${status}&initialDate=${initialDate}&finalDate=${finalDate}`
			)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getExtract = (
	idRecebedor,
	initialDate,
	finalDate,
	page = 1,
	elements = 15,
	marketType = ''
) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(
				`dashboard/payables/statements?recipientId=${idRecebedor}&initialDate=${initialDate}&finalDate=${finalDate}&page=${page}&elements=${elements}&marketType=${marketType}`
			)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => reject(error));
	});

const getTransactionDetails = (id, recipientId) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(`/dashboard/payables/transactions/${id}?recipientId=${recipientId}`)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getDashboardSummary = (recipientId, initialDate, finalDate) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(
				`/dashboard/summary?recipientId=${recipientId}&initialDate=${initialDate}&finalDate=${finalDate}`
			)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getMarketType = (recipientId, initialDate, finalDate) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(
				`/dashboard/summary/markets?recipientId=${recipientId}&initialDate=${initialDate}&finalDate=${finalDate}`
			)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getTransactionsLogs = (
	dataInicial,
	dataFinal,
	tipo = '',
	name = '',
	email = ''
) =>
	new Promise((resolve, reject) => {
		axiosApp
			.get(
				`/dashboard/transactions/history?dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipo=${tipo}&name=${name}&email=${email}`
			)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

const getTransactionsOrders = (recipientId, ordersIds) =>
	new Promise((resolve, reject) => {
		axiosApp
			.post(
				`/dashboard/payables/transactions/orders?recipientId=${recipientId}`,
				ordersIds
			)
			.then(({ data }) => resolve(data))
			.catch((error) => reject(error));
	});

export {
	getBalance,
	getPayableTransaction,
	getAnticipations,
	getExtract,
	getFee,
	getMarketType,
	getTransactionDetails,
	getTransactionsLogs,
	getTransactionsOrders,
	getDashboardSummary,
};
