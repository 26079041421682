import React from 'react';
import { CheckboxCustom } from '../../../../atoms';
import { capitalizeFirstLetter } from '../../../../../utils';

import * as S from './styled-dropdown-filter-installments';

const installmentsOptions = new Array(18).fill(null).map((option, index) => {
	return {
		label: `${index + 1}x`,
		value: index + 1,
	};
});

function RenderCheckboxCustom({ state, setState, id, text }) {
	return (
		<CheckboxCustom
			checked={state.includes(id)}
			state={state}
			setState={setState}
			fontSize="1.25rem"
			text={capitalizeFirstLetter(text)}
			checkboxAlign="flex-start"
			onChange={() => {
				if (state.includes(id)) {
					setState(state.filter((item) => item !== id));
				} else {
					setState([...state, id]);
				}
			}}
			TransactionFilters
		/>
	);
}

function DropdownFilterInstallments({
	installments,
	setInstallments,
	innerRef,
}) {
	return (
		<S.Container ref={innerRef}>
			<S.ColumnTitle>Número de Parcelas</S.ColumnTitle>
			<S.Row>
				<S.Column>
					{installmentsOptions.map(
						(item) =>
							item.value < 10 && (
								<RenderCheckboxCustom
									state={installments}
									setState={setInstallments}
									key={item.label}
									id={item.value}
									text={item.label}
								/>
							)
					)}
				</S.Column>
				<S.Divisor />
				<S.Column>
					{installmentsOptions.map(
						(item) =>
							item.value >= 10 && (
								<RenderCheckboxCustom
									state={installments}
									setState={setInstallments}
									key={item.label}
									id={item.value}
									text={item.label}
								/>
							)
					)}
				</S.Column>
			</S.Row>
		</S.Container>
	);
}

export default DropdownFilterInstallments;
