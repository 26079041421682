/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';
import DatePicker, { utils } from 'react-modern-calendar-datepicker';
import {
	Container,
	ButtonsTabs,
	Button,
	CardsDiv,
	Card,
	ButtonFilter,
	FilterTabs,
} from './styles';
import Table from './table/table';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

const InfosTable = (props) => {
	const [selectedDayRange, setSelectedDayRange] = useState({
		from: null,
		to: null,
	});

	const myCustomLocale = {
		// months list by order
		months: [
			'Janeiro',
			'Fevereiro',
			'Março',
			'Abril',
			'Maio',
			'Junho',
			'Julho',
			'Agosto',
			'Setembro',
			'Outubro',
			'Novembro',
			'Dezembro',
		],

		// week days by order
		weekDays: [
			{
				name: 'Domingo', // used for accessibility
				short: 'S', // displayed at the top of days' rows
				isWeekend: true, // is it a formal weekend or not?
			},
			{
				name: 'Segunda',
				short: 'M',
			},
			{
				name: 'Terça',
				short: 'T',
			},
			{
				name: 'Quarta',
				short: 'W',
			},
			{
				name: 'Quinta',
				short: 'T',
			},
			{
				name: 'Sexta',
				short: 'F',
			},
			{
				name: 'Sabado',
				short: 'S',
				isWeekend: true,
			},
		],

		// just play around with this number between 0 and 6
		weekStartingIndex: 0,

		// return a { year: number, month: number, day: number } object
		getToday(gregorainTodayObject) {
			return gregorainTodayObject;
		},

		// return a native JavaScript date here
		toNativeDate(date) {
			return new Date(date.year, date.month - 1, date.day);
		},

		// return a number for date's month length
		getMonthLength(date) {
			return new Date(date.year, date.month, 0).getDate();
		},

		// return a transformed digit to your locale
		transformDigit(digit) {
			return digit;
		},

		// texts in the date picker
		nextMonth: 'Próximo mês',
		previousMonth: 'Mês anterior',
		openMonthSelector: 'Seletor de mês aberto',
		openYearSelector: 'Seletor de ano aberto',
		closeMonthSelector: 'Fechar Seletor de Mês',
		closeYearSelector: 'Fechar seletor de ano',
		defaultPlaceholder: 'Selecionar...',

		// for input range value
		from: 'from',
		to: 'to',

		// used for input value when multi dates are selected
		digitSeparator: ',',

		// if your provide -2 for example, year will be 2 digited
		yearLetterSkip: 0,

		// is your language rtl or ltr?
		isRtl: false,
	};

	const renderCustomInput = ({ ref }) => (
		<input
			readOnly
			ref={ref}
			placeholder="Selecione os dias"
			value={
				selectedDayRange.from && selectedDayRange.to
					? `${`${selectedDayRange.from.day}/${selectedDayRange.from.month}/${selectedDayRange.from.year}`} - ${`${selectedDayRange.to.day}/${selectedDayRange.to.month}/${selectedDayRange.to.year}`} `
					: ''
			}
			style={{
				textAlign: 'center',
				padding: '0.4rem 0.4rem',
				fontSize: '0.9rem',
				border: '1px solid #9c88ff',
				outline: 'none',
			}}
			className="my-custom-input-class"
		/>
	);

	return (
		<Container {...props}>
			<ButtonsTabs>
				<Button>Anteriores</Button>
				<Button>A receber</Button>
			</ButtonsTabs>
			<FilterTabs>
				<DatePicker
					value={selectedDayRange}
					onChange={setSelectedDayRange}
					shouldHighlightWeekends
					maximumDate={utils().getToday()}
					renderInput={renderCustomInput}
					colorPrimary="#9c88ff"
					colorPrimaryLight="rgb(156,136,255, 0.4)"
					locale={myCustomLocale}
				/>
				<ButtonFilter>Filtrar</ButtonFilter>
			</FilterTabs>
			<CardsDiv>
				<Card color="#65a300">
					<h2>Total de entradas</h2>
					<h1>R$0,00</h1>
				</Card>
				<Card color="#df285f">
					<h2>Total de saídas</h2>
					<h1>R$0,00</h1>
				</Card>
				<Card color="#df285f">
					<h2>Taxas Pagar.me</h2>
					<h1>R$0,00</h1>
				</Card>
				<Card color="#4079bb">
					<h2>Total líquido</h2>
					<h1>R$0,00</h1>
				</Card>
				<Table />
			</CardsDiv>
		</Container>
	);
};

export default InfosTable;
